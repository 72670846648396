import { useContext, useEffect, useState, useRef } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMaskedCCNum, getMetadata, isEmpty, isValidExpiryDate } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { BG_ERROR_CODE, CARD, REGEX } from "../../../utils/constants";
import metadataa from "../../stack/metadata.json";
import { PaymentClient, Env } from "@backoffice/fast-payments-client-js-sdk";
import generateUniqueId from "../generateUniqueId";
import Popup from "reactjs-popup";
import ChargeOrderHoldPopup from "../file-claim/chargeOrderHoldPopup";
import logger from "../../../logger.service";
import { reschedulePickUpDeliveryOnFeeWaiver } from "../reschedule-pickup-delivery-logic";

function CreditCardDetails() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  // const metadata = workflowState["config"]?.metadata["CreditCardDetails"];
  const homeplusscript = workflowState["config"]?.scripts["CreditCardDetails"];
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);
  let metadata = metadataa["CreditCardDetails"];
  let btnCancel = findById(metadata, "btnCancel");
  let btnConfirm = findById(metadata, "btnConfirm");
  let menu = findById(metadata, "menu");
  let paymentPage = findById(metadata, "paymentPage");

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [cardIcon, setCardIcon] = useState();
  const [bgError, setBGError] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  const contentStyle = {
    padding: "15px",
    width: "345px",
    height: "265px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    borderRadius: "8px",
    borderColor: "",
    textColor: "",
  };

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const ProcessPaymentResponse = fileClaimDetails?.ProcessPaymentResponse;
  const agreement = workflowState.FindAgreementResponse?.Agreements?.Agreement[0];
  const replacementOption = fileClaimDetails?.selectedReplacementOption;
  const isOverageFlow = fileClaimDetails?.isOverageFlow;
  const isTVRepairWithOverage = fileClaimDetails?.isTVRepairWithOverage;
  const billingAddress = agreement?.BillingAddress;
  const billingAddressNew = workflowState.FindAgreementResponse?.Agreements?.Agreement[0]?.Address;
  const ServiceFeeResponse = fileClaimDetails?.ServiceFeeResponse;

  let paymentUI = useRef(null)
  let billingInformation = {
    address: {
      address1: billingAddressNew?.Address1,
      address2: billingAddressNew?.Address2,
      city: billingAddressNew?.City,
      stateOrProvince: billingAddressNew?.StateProvinceCode,
      postalCode: billingAddressNew?.PostalCode
    }
  }
  const payNow = () => {
    paymentUI.current.continue();
  };


  useEffect(() => {
    let uniqueId = generateUniqueId().replace(/-/g, "").toUpperCase();
    // setUniqueId(uniqueId);
    callPCITokenApi(uniqueId);
  }, []);

  useEffect(() => {
    if (fileClaimDetails?.PCITokenResponse) {
      setUniqueId(fileClaimDetails?.PCITokenResponse?.getSecurityTokenResponse?.token);
    }
  }, [fileClaimDetails?.PCITokenResponse]);

  const callPCITokenApi = (uniqueId) => {
    showLoader(true);

    let SecurityTokenParameters = {
      SecurityTokenParameters: {
        ReferenceId: uniqueId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        CustomerCaseNumber: ServiceRequestResponse?.CustomerCaseNumber,
        BillingProgramIdBGBT: "SH_DEVICE_CARE_BT-HORIZON-WEB-APAC",
        IsBGBT: true,
        AuthorizationAmount: isOverageFlow
          ? replacementOption?.OverageAmount
          : ServiceFeeResponse?.TotalAmount,
      },
    };

    API[ActionTypes.GET_PCI_TOKEN](SecurityTokenParameters)
      .then((data) => {
        showLoader(false);

        updateState(
          {
            ...workflowState,
            FileClaimDetails: {
              // ...fileClaimDetails,
              PCITokenResponse: data.data.getSecurityTokenResponse,
            },
          },
          ["FileClaimDetails"]
        );
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({
          type: ActionTypes.GET_PCI_TOKEN,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const successFunc = (resp) => {
    const additionalData = resp?.additionalData;
    const contactName = resp?.billingInformation?.contact;
 
    if (resp?.paymentMethod === "creditCard") {
      callAuthPaymentApi(additionalData, contactName);
    } 
    else {
      callPaypalPaymentApi();
    }
  };

  const errorFunc = async (resp) => {
    await updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          PCITokenResponse: undefined,
        },
      },
      ["FileClaimDetails"]
    );
    console.log(resp, "error");
    await setUniqueId(undefined);
    let uniqueId = generateUniqueId().replace(/-/g, "").toUpperCase();
    await setUniqueId(uniqueId);
    await callPCITokenApi(uniqueId);

  };


  const callAuthPaymentApi = async (additionalData, contactName) => {
    showLoader(false);
    const ChargeOrder = {
      PaymentMethodType: "CRE",
      ChargeOrderStatus: "PREAUTH", 
      AddressId: billingAddress?.AddressId,
      AdditionalChargeAuth: "false",
      ChargeOrderCardBrand: additionalData?.cardBrand,
      ChargeOrderCardType: "CRE",
      CardCheckNumber: 123,
      CardHolderFirstName: name,
      CardHolderLastName: "",
      OverageAmount: isOverageFlow
        ? replacementOption?.OverageAmount
        : undefined,
      ccDetails: getCCDetails()
    }

    reschedulePickUpDeliveryOnFeeWaiver(
      fileClaimDetails,
      setOpenPopup,
      workflowState,
      showLoader,
      updateState,
      completeCurrentStep,
      refreshStateOnLogout,
      currentStep,
      ChargeOrder,
      handleBGError,
      uniqueId
    );
  };

  const callPaypalPaymentApi = async (additionalData, contactName) => {
    showLoader(false);
    const ChargeOrder = {
      PaymentMethodType: "PYPL",
      ChargeOrderStatus: "PREAUTH", 
      AddressId: billingAddress?.AddressId,
      AdditionalChargeAuth: "false",
      ChargeOrderCardBrand: 'PaypalAccount', 
      ChargeOrderCardType: "PYPL",
      CardCheckNumber: 123,
      CardHolderFirstName: name,
      CardHolderLastName: "",
      OverageAmount: isOverageFlow
        ? replacementOption?.OverageAmount
        : undefined,
      ccDetails: getCCDetails()
    }
    reschedulePickUpDeliveryOnFeeWaiver(
      fileClaimDetails,
      setOpenPopup,
      workflowState,
      showLoader,
      updateState,
      completeCurrentStep,
      refreshStateOnLogout,
      currentStep,
      ChargeOrder,
      handleBGError,
      uniqueId
    );
  };

  const getCCDetails = () => {
    let ccformask = getMaskedCCNum(cardNumber);
    let ccmask = ccformask
      .replace(/(\w{4})/g, "$1 ")
      .replace(/(^\s+|\s+$)/, "");

    return {
      ccmask,
      name,
      cardType,
      cvv,
    };
  };

  const handleClearClick = () => {
    setName("");
    setCardNumber("");
    setExpiryDate("");
    setCvv("");
  };

  useEffect(() => {
    handleCardType();
    if (cardNumber.length === 0) {
      setCardIcon("");
    }
  }, [cardNumber]);

  useEffect(() => {
    if (ProcessPaymentResponse?.ProcessPaymentResults?.Result)
      handleBGError(ProcessPaymentResponse?.ProcessPaymentResults?.Result);
  }, [workflowState?.FileClaimDetails?.ProcessPaymentResponse]);

  const handleCardType = () => {
    switch (cardType) {
      case CARD.VISA:
        return setCardIcon("Visa");
      case CARD.AMEX:
        return setCardIcon("Amex");
      case CARD.MASTER:
        return setCardIcon("MasterCard");
      default:
        return undefined;
    }
  };

  const handleBGError = (error) => {
    let ProcessPaymentResult = error?.details?.code;
    const errorMessage = JSON.stringify(
      error?.response?.data || error?.message
    );
    handleClearClick();
    if(errorMessage === "Name on card contains illegal characters or has illegal format"){
      setBGError(errorMessage);
    } else if (typeof ProcessPaymentResult === "string") {
      switch (ProcessPaymentResult) {
        case BG_ERROR_CODE.BG_1002:
          setBGError(homeplusscript.BG_ERROR_1002);
          break;
        case BG_ERROR_CODE.BG_1015:
          setBGError(homeplusscript.BG_ERROR_1015);
          break;
        case BG_ERROR_CODE.BG_1000:
          setBGError(homeplusscript.BG_ERROR_1000);
          break;
        default:
          setBGError(homeplusscript.BG_ERROR_GENERIC);
          break;
      }
    } else if (
      typeof ProcessPaymentResult === "object" &&
      ProcessPaymentResult !== null
    ) {
      if (ProcessPaymentResult?.Type !== "Success") {
        setBGError(ProcessPaymentResult?.Message);
      } else 
        setBGError(homeplusscript.BG_ERROR_GENERIC);
    } else 
      setBGError(homeplusscript.BG_ERROR_GENERIC);
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label:
      isOverageFlow && !isTVRepairWithOverage
        ? homeplusscript.btnProcessPayment
        : homeplusscript.btnConfirm,
    onClick: (e) => payNow(),
    enabled: true,
  };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  paymentPage.args = {
    ...paymentPage?.args,
    handlePaymentSuccess: (response) => successFunc(response),
    handlePaymentFailure: (response) => errorFunc(response),
    paymentUI: paymentUI,
    billingInformation: billingInformation,
    token: uniqueId,
    other: uniqueId ? "block mt-5" : "hidden",
  }

  return (
    <>
      <Popup
        contentStyle={contentStyle}
        open={openPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <ChargeOrderHoldPopup setOpenPopup={setOpenPopup} />
        </div>
      </Popup>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
}

export default CreditCardDetails;
