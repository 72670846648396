import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Text } from "../../text/text";
import { BillingGatewayRegion, BillingGatewayService, Environment, PaymentUI, Locale } from '@backoffice/fast-payments-client-js-sdk/microui';
import { WorkflowContext } from "../../workflow/workflow-context";

const PaymentPage = ({
  token,
  handlePaymentSuccess,
  handlePaymentFailure,
  paymentUI,
  billingInformation,
  className,
  other,
  ...restProps
}) => {

  const billingGatewayService = new BillingGatewayService({
    environment: process.env.REACT_APP_BG_TRANSACTION_ENV === 'PROD' ? Environment.PROD : Environment.QA,
    region: BillingGatewayRegion.APAC,
    applicationId: "sh_device_care",
    billingProgramId: 'SH_DEVICE_CARE_BT-HORIZON-WEB-APAC',
  });

  return (
    <div 
    className={`${other}`}>
      {token && (
        <PaymentUI
          key={token}
          applicationSession={token}
          billingGatewayService={billingGatewayService}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFailure={handlePaymentFailure}
          ref={paymentUI}
          hideContinueButton={true}
          billingInformation={billingInformation}
          locale={Locale.enSG}
        />
      )}
    </div>
  );
};

PaymentPage.propTypes = {
  token: PropTypes.string,
  billingInformation: PropTypes.string,
  handlePaymentSuccess: PropTypes.func,
  handlePaymentFailure: PropTypes.func,
  paymentUI: PropTypes.func,
};


export default PaymentPage;