import React from "react";
import { Routes, Route, Navigate } from "react-router";
import ErrorBoundary from "../error-boundary/error-boundary";
import { WorkflowProvider } from "./workflow-context";

const Workflow = ({
  getNextStep,
  initialStep,
  initialization,
  children,
  showLoader,
  isLoaded,
}) => {
  return (
    <WorkflowProvider
      getNextStep={getNextStep}
      initialization={initialization}
      initialStep={initialStep}
      showLoader={showLoader}
    >
      {isLoaded && (
        <ErrorBoundary>
          <Routes>
            {children}
            <Route path="/" element={<Navigate to={initialStep} />} />
          </Routes>
        </ErrorBoundary>
      )}
    </WorkflowProvider>
  );
};

export default Workflow;
