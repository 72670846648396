import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getFormmatedDate, getMetadata } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { useEffect } from "react";
import { DEVICE_CATEGORY, FULFILMENT_OPTION } from "../../../utils/constants";
import { callProcessPaymentApi, callSubmitOrderApi } from "../api-helper";
import logger from "../../../logger.service";

function ClaimNotes() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ClaimNotes']
  const homeplusscript = workflowState['config']?.scripts["ClaimNotes"];

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const ServiceOrderResponse = fileClaimDetails?.ServiceOrderResponse;
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const ChargeOrderResponse = fileClaimDetails?.ChargeOrderResponse;

  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const userAddress = agreement?.Address;

  const ServiceOrderId = ServiceOrderResponse?.ServiceOrderId;

  const deviceCategory =
    fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryName;

  const isRepair =
        fileClaimDetails?.selectedDevice?.FulfillmentMethodType ===
        FULFILMENT_OPTION.REPAIR;

  const [noteList, setNoteList] = useState([homeplusscript.notes]);
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");
  let btnContinue = findById(metadata, "btnContinue");
  let notes = findById(metadata, "notes");
  const isBraintree = workflowState["config"]?.isBraintree
  const isPayPal = isBraintree?.method?.paypal;
  const isGpay = isBraintree?.method?.gpay;

  const paymentMethod = workflowState["BraintreeDetails"]?.paymentMethod || fileClaimDetails?.ChargeOrderResponse?.PaymentMethodType
  const cardBrand = fileClaimDetails
  const chargeOrderDetails = fileClaimDetails?.ChargeOrderResponse

  const getContactDetails = () =>{
  const ContactPoints = workflowState?.GetContactDetailsResponse?.ContactPointResults?.ContactPoints?.ContactPoint;
    return ContactPoints?.filter(element => element.ContactPointType === "MOBILE")?.[0]?.PhoneNumber;
  }

  const callContactDetailsApi = () => {
    showLoader(true);
    const contactDetailsParameter = {
      clientaccountid: agreement?.ClientAccount?.ClientAccountId,
    }
  
    API[ActionTypes.GET_CONTACT_POINT_REQUEST](contactDetailsParameter)
    .then((data) => {
      showLoader(false);
      updateState({
        ...workflowState,
        GetContactDetailsResponse: data?.data,
      });
    })
    .catch((error) => {
      showLoader(false);
      console.log(error);
      updateState({
        ...workflowState,
        [currentStep]: {
          ...workflowState[currentStep],
        },
      });
    })
   }

  useEffect(() => {
    callContactDetailsApi();
    if (isRepair) {
      const isTV = deviceCategory === DEVICE_CATEGORY.TV;
      const isTablet = deviceCategory === DEVICE_CATEGORY.Tablet;


      const {key, index} = isTV ? {key : "notes", index : 1} : isTablet ? {key : "notesTablet", index : 3} : {key : "notesLaptop", index: 2};
      const notes = JSON.parse(JSON.stringify(homeplusscript[key]));

      const note = notes[index];

      note.type = homeplusscript[key][index].type
        .replace("{'date'}", fileClaimDetails?.onsiteDate)
        .replace("{'time'}", fileClaimDetails?.onsiteTimeSlot) ;

      setNoteList(notes);
    } else {
      setNoteList(homeplusscript.notesReplacement);
    }
  }, []);

  const onContinueClick = () => {
    callUpdateShippingApi();
  };

  const callUpdateShippingApi = () => {
    showLoader(true);
    const UpdateShippingOrderParameters = {
      UpdateShippingOrderParameters: {
        EntityUpdateOptions: {
          ReturnEntity: true,
        },
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ShippingOrder: {
          ShippingMethodType: "Same Day",
          CarrierNumber: "SGINTCAR",
          SignatureRequired: true,
          RequestedShipmentDate: isRepair
          ? fileClaimDetails?.onsiteDate
          : undefined, // for replacement we are not passing this field
          ExpectedDeliveryDate: isRepair
          ? fileClaimDetails?.onsiteDate
          : undefined, // for replacement we are not passing this field
          DeliveryWindow: isRepair ? fileClaimDetails?.onsiteTimeSlot : undefined, // for replacement we are not passing this field
          NationalId: agreement?.IdentificationNumber.substr(
            agreement?.IdentificationNumber.length - 4
          ),
          Remark: "",
          AlternateContactNumber: fileClaimDetails?.AlternateContactNumber || getContactDetails(),
          ReceiverName: agreement?.Customers?.Customer[0]?.FullName,
          ReceiverAddress: {
            LocationType: "Residential",
            BusinessName: agreement?.Customers?.Customer[0]?.FullName,
            Standardized: true,
            IsOverridden: false,
            StandardizedSourceName: "QAS",
            BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
            IsScrubbed: true,
            ...userAddress,
          },
        },
        InboundShippingOrder: isRepair ?  {
          ShippingMethodType: "Same Day",
          CarrierNumber: "SGINTCAR",
          SignatureRequired: true,
          RequestedShipmentDate:  undefined,
          ExpectedDeliveryDate: undefined,// getFormmatedDate(onsiteDate, "yyyy-mm-dd", "-"),
          DeliveryWindow: undefined,
          NationalId: agreement?.IdentificationNumber.substr(
            agreement?.IdentificationNumber.length - 4
          ),
          Remark: "",
          AlternateContactNumber: fileClaimDetails?.AlternateContactNumber || getContactDetails(),
          ReceiverName: agreement?.Customers?.Customer[0]?.FullName,
          ReceiverAddress: {
            LocationType: "Residential",
            BusinessName: agreement?.Customers?.Customer[0]?.FullName,
            Standardized: true,
            IsOverridden: false,
            StandardizedSourceName: "QAS",
            BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
            IsScrubbed: true,
            ...userAddress,
          },
        } : undefined,
      },
    };

    API[ActionTypes.UPDATE_SHIPPING_ORDER](
      UpdateShippingOrderParameters,
      ServiceOrderId
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            ShippingOrderResponse: data.data.ShippingOrderUpdateResults,
          },
        }, ["FileClaimDetails"]);
        callProcessPayment();
        // callCreateRepairRequestApi();
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.UPDATE_SHIPPING_ORDER, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callCreateRepairRequestApi = () => {
    showLoader(true);

    const CreateRepairRequestParams = {
      CreateRepairRequestParams: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        RepairRequestType: "HomePlus",
        ServiceOrderId: ServiceOrderResponse.ServiceOrderId,
        AppointmentDate: isRepair
          ? getFormmatedDate(fileClaimDetails?.onsiteDate, "yyyy-mm-dd", "-")
          : getFormmatedDate(new Date().toDateString(), "yyyy-mm-dd", "-"),
      },
    };

    API[ActionTypes.CREATE_REPAIR_REQUEST](CreateRepairRequestParams)
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            RepairRequestResponse : data.data.CreateRepairRequestResponse
          },
        }, ["FileClaimDetails"]);

        // callProcessPayment();
        callSubmitOrder();
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_REPAIR_REQUEST, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callProcessPayment = () => {
    showLoader(true);
    const ProcessPaymentParameters = {
      ProcessPaymentParameters: {
        ChargeOrderId: ChargeOrderResponse?.ChargeOrderId,
        ServiceOrderId: ServiceOrderId,
        ApplicationId: workflowState["config"]?.ApplicationId,
        ClientId: workflowState["config"]?.ClientId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderNumber:
          ServiceOrderResponse?.ServiceOrderNumber?.toString(),
        AuthorizeAdditionalCharges: false,
        ValidateAddress: false,
        ValidateCardSecurityCode: false,
        IpAddressForFraud: workflowState["config"]?.SourceIpAddress || "",
        FulfillmentOption: "PUR",
        PaymentInformation: {
          PaymentMethodType: isBraintree ? paymentMethod : chargeOrderDetails?.CardType,
          CardType: chargeOrderDetails?.CardType,
          CardBrand: chargeOrderDetails?.CardBrand,
          CardCheckNumber: isBraintree ? "0": fileClaimDetails?.ccDetails?.cvv,
          AccountHolder: {
            FirstName: agreement.Customers?.Customer[0]?.FirstName,
            LastName: agreement.Customers?.Customer[0]?.LastName,
            CompanyName: "",
            IdentificationType: "NATIONALID",
            IdentificationNumber: agreement?.IdentificationNumber,
          },
        },
        Address: userAddress,
        ContactDetails: {
          phone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          workPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          cellPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          email: workflowState["login"]?.email,
        },
        AutoCompleteId: ChargeOrderResponse?.AutoCompleteId
      },
    };

    let totalAmount = workflowState["BraintreeDetails"]?.ServiceFeeResponse?.TotalAmount || fileClaimDetails?.ChargeOrderResponse?.TotalAmount

    if(isBraintree){
      ProcessPaymentParameters.ProcessPaymentParameters.BrainTreeDetails = {
        IsBrainTree: true,
        Amount: totalAmount.toString(),
        Maid: isBraintree?.maid,
        ThreeDSecureAuthenticationId: fileClaimDetails?.verifyCardResponse?.threeDSecureAuthenticationId,
        ProductId: fileClaimDetails?.verifyCardResponse?.productId,
        Prepaid: fileClaimDetails?.verifyCardResponse?.prepaid,
        BillingAddress: {
          GivenName: agreement.Customers?.Customer[0]?.FirstName,
          Surname: agreement.Customers?.Customer[0]?.LastName,
          PhoneNumber: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          streetAddress: userAddress?.Address1,
          ExtendedAddress: userAddress?.Address2,
          Locality: userAddress?.City,
          Region: "SG", // ISO-3166-2 code
          PostalCode: userAddress?.PostalCode,
          CountryCodeAlpha2: "SG",
        },
      };
    }

    callProcessPaymentApi(
      ProcessPaymentParameters,
      ServiceOrderId,
      ChargeOrderResponse?.ChargeOrderId,
      onApiSuccess,
      onApiFailure
    );
  };

  const callSubmitOrder = () => {
    showLoader(true);

    const SubmitServiceOrderParameters = {
      SubmitServiceOrderParameters: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceOrderId,
      },
    };

    callSubmitOrderApi(
      SubmitServiceOrderParameters,
      ServiceOrderId,
      onApiSuccess,
      onApiFailure
    );
  };

  const onApiSuccess = (type, data) => {
    showLoader(false);

    if (type === ActionTypes.PROCESS_PAYMENT) {
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ProcessPaymentResponse: data,
        },
      }, ["FileClaimDetails"]);
      const success = ["BG-0" , "BG-703"]
      if(!success.includes(data?.ProcessPaymentResults?.Result?.Code)){
        if (isBraintree) {
          return completeCurrentStep(currentStep, {
            braintree: true,
          });
        } else {
          return completeCurrentStep(currentStep, {
            GoBackCreditCard: true,
          });
        }
      } else {
        callCreateRepairRequestApi();
      }

    } else if (type === ActionTypes.SUBMIT_ORDER) {
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          SubmitOrderResponse: data,
        },
      }, ["FileClaimDetails"]);
      return completeCurrentStep(currentStep, {
        Continue: true,
      });
    }
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type, error, state: workflowState});
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    onClick: (e) => onContinueClick(),
  };
  
 btnCancel.args = {
  ...btnCancel.args,
  enabled: true,
  onClick: (e) => setCancelClaimPopup(true)
}

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  notes.args = {
    ...notes.args,
    options: noteList,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ClaimNotes;
