import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import {
  findById,
} from "./helper";
import { Expandable } from "../expandable/expandable";
import {EXPANDABLE_TYPE} from "../../utils/constants"
import { Config } from "./config";

function FAQ() {
 
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);
  
  const metadata = workflowState['config']?.metadata['FAQ']
  const homeplusscript = workflowState['config']?.scripts["FAQ"];

  let menu = findById(metadata, "menu");

    let securityAdvisor = Config.service.securityAdvisor;
  
  const onLinkClick = (redirectPage) => {
    let url;
    try {
      url = new URL(redirectPage);
      window.open(redirectPage)
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      if(redirectPage === "security-advisor") window.open(securityAdvisor);
      else refreshStateOnLogout(workflowState, redirectPage)
    }
  }
   
  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
  };
  
  const FaqData = [
    {
      title: "About SmartSupport Home",
      items: [
        {
          subTitle: "What is SmartSupport Home?",
          answer: (onClick) => {
            return (
              <>
                <div class="mb-2 text-justify">
                  SmartSupport Home offers a comprehensive tech support and
                  protection for your home devices with the essential features.
                  You and your Family Member(s) can enjoy the following:
                </div>

                <ul class="list-disc list-inside">
                  <li>
                    <b>Device Cover^ : </b> up to $5,000 coverage for Breakdown
                    of your existing and new Eligible Devices for one single
                    monthly premium, a coverage provided under a group Policy
                    underwritten by Liberty Insurance Pte Ltd (Liberty)
                    <br />
                  </li>
                  <div class="my-3">
                    In addition to Device Cover, SmartSupport Home also comes
                    bundled with:
                    <br />
                  </div>
                  <li class="my-2">
                    <b>Security Advisor : </b>Safeguard your personal
                    information by taking preventative actions against online
                    data breaches.
                  </li>
                  <li>
                    <b>Tech Support : </b> Resolve all your home device queries
                    with our Home Specialists whether it is device setup,
                    troubleshooting support or Security Advisor related matters.
                  </li>
                </ul>
                <div class="text-xs mt-4">
                  ^ $20.18/month (inclusive of GST) is charged for Device Cover,
                  which is also the premium that Liberty charges Starhub for
                  each customer that is covered under the Policy. Liberty bears
                  no liability for Tech Support and Security Advisor services.
                </div>
                <div class="text-xs mt-2">
                Please refer to the {" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick("https://smartsupporthome.asurion.com/")
                    }
                    class="underline"
                  >
                    Coverage Summary
                  </a>{" "}
                  and{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick(
                        "https://www.starhub.com/smartsupporthome-tnc"
                      )
                    }
                    class="underline"
                  >
                    SmartSupport Home Terms & Conditions
                  </a>{" "}
                  for details.
                </div>
              </>
            );
          },
        },
        {
          subTitle: "What do I get with SmartSupport Home?",
          answer: () => {
            return (
              <>
                <div class="mb-2">Below is a summary of the SmartSupport Home benefits.</div>
                <table class="border-collapse border border-slate-500">
                  <tbody>
                    <tr class="border-collapse border border-slate-500">
                      <td colspan="2" class="px-1">
                        SmartSupport Home Benefits Table
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500 w-auto lg:w-[150px] px-1">
                        <b>Device Cover ^ </b>
                      </td>
                      <td class="border-collapse border border-slate-500">
                        <ul class="list-disc list-inside pl-2">
                          <li>
                            Breakdown coverage for the following categories of
                            the Eligible Devices.
                            <ol
                              style={{ listStyleType: "upper-alpha" }}
                              class="list-inside pl-2"
                            >
                              <li class="my-2">
                               &nbsp; Desktop Computer, Laptop, Tablet, Television,
                                Soundbar{" "}
                              </li>
                              <li class="mb-2">
                              &nbsp; Keyboard, Mouse, Monitor, Webcam, Router,
                                Printer
                              </li>
                            </ol>
                          </li>
                          <li>
                            Door to door pick up and return service via our
                            authorised partners. If your Covered Device is
                            beyond repair, a Replacement Device may be offered
                            to you.
                          </li>
                          <li class="my-2">
                            Aggregate Claim Limit of up to $5,000 per rolling 12
                            months.
                          </li>
                          <li class="mb-2">
                            The Per Claim Limit Per Covered Device is set out
                            for the following:
                            <ol
                              style={{ listStyleType: "upper-alpha" }}
                              class="list-inside pl-2"
                            >
                              <li class="my-2">
                              &nbsp; Capped at $1,500 per device.A deductible of $190
                                (or $210 for Apple devices) per Covered Device
                                applies
                              </li>
                              <li class="mb-2">
                                &nbsp; Capped at $600 per device.A deductible of $30
                                per Covered Device applies
                              </li>
                            </ol>
                          </li>
                          <li>
                            This coverage starts 31 days from the Start Date
                            from application approval.{" "}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-collapse border border-slate-500 px-1">
                        <b>Tech Support</b>
                      </td>
                      <td class="border-collapse border border-slate-500">
                        <ul class="list-disc list-inside pl-2">
                          <li>
                            Unlimited support for the Insured plus up to 4
                            Family Members{" "}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-collapse border border-slate-500 px-1">
                        <b>Security Advisor</b>
                      </td>
                      <td class="border-collapse border border-slate-500">
                        <ul class="list-disc list-inside pl-2">
                          <li class="mb-2">
                            Access for the Insured plus up to 4 Family Members
                          </li>
                          <li>
                            Registered emails must be nominated by the Insured.
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-xs mt-4 text-justify">
                  ^ Limitations and exclusions apply, including Aggregate Claim
                  Limit and Per Claim Limit Per Covered Device. If it is
                  determined that the Covered Device is not suitable for repair
                  or replacement, an electronic wallet credit will be issued for
                  the Reimbursement Value. Please refer to the{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick("https://smartsupporthome.asurion.com/")
                    }
                    class="underline"
                  >
                    Coverage Summary
                  </a>{" "}
                  and{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick(
                        "https://www.starhub.com/smartsupporthome-tnc"
                      )
                    }
                    class="underline"
                  >
                    SmartSupport Home Terms & Conditions
                  </a>{" "}
                  for details.
                </div>
              </>
            );
          },
        },
        {
          subTitle: "What is Device Cover?",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  Device Cover provides coverage for Breakdown, which means the
                  mechanical or electrical failure caused by defects in
                  materials and/or workmanship, of the Covered Devices during
                  the Coverage Period. Device Cover supports your Covered
                  Devices with a door to door pick up and return service via our
                  authorised partners. If your Covered Device is beyond repair,
                  a Replacement Device may be offered to you.
                </div>
                <div class="mt-4 text-justify text-xs">
                   Limitations and exclusions apply, including Aggregate Claim
                  Limit and Per Claim Limit Per Covered Device. If it is
                  determined that the Covered Device is not suitable for repair
                  or replacement, an electronic wallet credit will be issued for
                  the Reimbursement Value. Please refer to the{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick("https://smartsupporthome.asurion.com/")
                    }
                    class="underline"
                  >
                    Coverage Summary
                  </a>{" "}
                  and{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick(
                        "https://www.starhub.com/smartsupporthome-tnc"
                      )
                    }
                    class="underline"
                  >
                    SmartSupport Home Terms & Conditions
                  </a>{" "}
                  for details.
                </div>
              </>
            );
          },
        },
        {
          subTitle:
            "What is the difference between SmartSupport Home and Extended Warranty? ",
          answer: () => {
            return (
              <>
                <table class="border-collapse border border-slate-500">
                  <thead>
                    <tr>
                      <th class="border-collapse border border-slate-500  px-1 w-auto lg:w-[120px]"></th>
                      <th class="border-collapse border border-slate-500  px-1">
                        SmartSupport Home<sup>1</sup>
                      </th>
                      <th class="border-collapse border border-slate-500  px-1">
                        Extended Warranty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        What does it cover?
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        One single monthly premium $20.18 for unlimited no. of
                        Eligible Devices for Breakdown insurance<sup>2</sup>; covers the
                        repair costs or, if unrepairable, a Replacement Device
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Mostly repair service. May or may not offer replacement
                        One warranty per device{" "}
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        What is covered?
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Existing and new Eligible Devices
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        New devices only
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        When can you apply?
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Enrolment anytime Eligibility applies
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        At the point of device purchase
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        Is there a minimum term?
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        No minimum term, Monthly premium charged
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Full term fees (1, 2, 3 or 4 years) charged
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        How is it supported?
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Complimentary pick-up and delivery service
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Walk-in. No pick-up and delivery service
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td class="border-collapse border border-slate-500  px-1">
                        Add-ons
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        Tech Support Security Advisor<sup>3</sup>
                      </td>
                      <td class="border-collapse border border-slate-500  px-1">
                        None{" "}
                      </td>
                    </tr>
                    <tr class="border-collapse border border-slate-500">
                      <td colspan="3" class="px-1 text-xs">
                        <div class="mt-2">
                        <sup>1.</sup> Limitations and exclusions apply, including Aggregate
                          Claim Limit and Per Claim Limit. If it is determined
                          that the Covered Device is not suitable for repair or
                          replacement, an electronic wallet credit will be
                          issued for the Reimbursement Value.
                        </div>
                        <div class="my-2">
                        <sup>2.</sup> $20.18/month (inclusive of GST) is charged for Device
                          Cover, which is also the premium that Liberty charges
                          StarHub for each customer that is covered under the
                          Policy
                        </div>
                        <div class="mb-2">
                        <sup>3.</sup> Liberty bears no liability for the Tech Support and
                          Security Advisor services.
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            );
          },
        },
        {
          subTitle: "How do I get assistance or submit feedback?",
          answer: () => {
            return (
              <>
                <div>You can reach our Home Specialists for</div>
                <ul class="list-disc list-inside pl-2">
                  <li class="my-2">
                    General inquiries and claims matters : 9am to 6pm, Mon – Fri
                  </li>
                  <li class="mb-2">
                    Tech Support and Security Advisor related matters : 9am – 6pm
                    daily.{" "}
                  </li>
                </ul>
                <div>
                  If you are a non SmartSupport Home customer, you may use the
                  Contact Us form which can be found{" "}
                  <a
                    class="underline"
                    href={"javascript:void(0)"}
                    onClick={() => onLinkClick("https://smartsupporthome.asurion.com/")}
                  >
                    here.
                  </a>
                </div>
              </>
            );
          },
        },
      ],
    },
    {
      title: "Security Advisor and Tech Support",
      items: [
        {
          subTitle: "What is Security Advisor and how do I access it?  ",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  SmartSupport Home also comes bundled with Security Advisor to
                  help you detect and take preventive actions against online
                  data breaches via Security Scan and Security Assessment. You
                  can get personalised support from our Home Specialist anytime
                  from 9am to 6pm daily to assist you with Security Advisor
                  related queries.
                  </div>
                  <div class="mt-2">
                   It will be activated upon the commencement of
                  your Coverage. Simply use the registered email address to{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => onLinkClick("security-advisor")}
                    class="underline"
                  >
                    login to Security Advisor.
                  </a>
                </div>
              </>
            );
          },
        },
        {
          subTitle: "What is Tech Support? ",
          answer: () => {
            return (
              <div class="text-justify">
                SmartSupport Home also comes bundled with Tech Support to give
                you instant access anytime to Home Specialists to assist you on
                device setup and troubleshoot device issues you may have.
              </div>
            );
          },
        },
        {
          subTitle:
            "How do I register other Family Members for Security Advisor and Tech Support?  ",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  You can register Security Advisor for up to 4 additional
                  Family Members. Just login into your Security Advisor account
                  to register the email addresses of your Family Members which
                  will be used for Security Advisor scans. The registered Family
                  Member can access to both Security Advisor and Tech Support
                  using the registered email address.
                </div>
                <div class="mt-2">
                  Connect with our Home Specialists from 9am to 6pm daily via
                  chat support{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() =>
                      onLinkClick("https://smartsupporthome.asurion.com/")
                    }
                    class="underline"
                  >
                    here.
                  </a>
                </div>
              </>
            );
          },
        },
      ],
    },
    {
      title: "Enrolment",
      items: [
        {
          subTitle:
            "Who is eligible for SmartSupport Home and can I apply anytime? ",
          answer: () => {
            return (
              <>
                <div>You can apply for SmartSupport Home if you are</div>
                <ul class="list-disc list-inside pl-2 text-justify">
                  <li class="my-2">
                    a Singapore citizen, permanent resident or foreigner with
                    work pass;
                  </li>
                  <li class="mb-2">are at least 18 years old;</li>
                  <li class="mb-2">
                    and have an active Data Service from StarHub (ie personal
                    StarHub mobile or broadband subscription) with a registered
                    residential address, provided that your enrolment with
                    SmartSupport Home has not been terminated in the past 12
                    months.
                  </li>
                </ul>
              </>
            );
          },
        },
        {
          subTitle:
            "Can I apply for more than one active SmartSupport Home account?",
          answer: () => {
            return (
              <div class="text-justify">
                Application for SmartSupport Home is limited to one active
                SmartSupport Home registration per eligible StarHub subscriber.
              </div>
            );
          },
        },
        {
          subTitle:
            "I have just applied for SmartSupport Home. How will I know if my application is successful? ",
          answer: () => {
            return (
              <div class="text-justify">
                You will receive an SMS notification as well as via your
                registered email with notice of Coverage commencement together
                with the Terms and Conditions and Insured Schedule if your
                application is successful.
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Subscription",
      items: [
        {
          subTitle: "Can I choose how long I would like to be covered?",
          answer: () => {
            return (
              <div class="text-justify">
                Coverage is on a pay-as-you-go basis. You will be covered by
                SmartSupport Home as long as you have an active monthly
                subscription to this service.
              </div>
            );
          },
        },
        {
          subTitle: "How will I be billed for SmartSupport Home?  ",
          answer: () => {
            return (
              <>
                {" "}
                SmartSupport Home will be billed and payable through your
                StarHub account.
              </>
            );
          },
        },
        {
          subTitle: "What is the price of SmartSupport Home? ",
          answer: () => {
            return (
              <div class="text-justify">
                You will be charged at $20.18/month (inclusive of GST) for
                SmartSupport Home, which is also the premium that Liberty
                charges StarHub for each customer that is covered under the
                Policy for Device Cover.
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Device Eligibility",
      items: [
        {
          subTitle:
            "Do I need to provide proof of purchase or ownership for the Eligible Devices? ",
          answer: () => {
            return (
              <div class="text-justify">
                You may be asked to provide supporting document on the purchase
                price and/or ownership. We encourage you to keep the receipts or
                register all of your Eligible Device as Covered Devices with the
                receipts for a smooth claim experience.
              </div>
            );
          },
        },
        {
          subTitle: "How do I know if my device is eligible for coverage?",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  To be eligible, your device must not be an Excluded Device.
                  Excluded Devices are devices which:
                </div>
                <ul class="list-disc list-inside pl-2 text-justify">
                  <li class="my-2">do not have WiFi and/or Bluetooth connectivity;</li>
                  <li class="mb-2">are brands which are not launched in Singapore; </li>
                  <li class="mb-2">were not purchased in Singapore; </li>
                  <li class="mb-2">
                    do not have a serial number or have an altered serial
                    number;{" "}
                  </li>
                  <li class="mb-2">
                    have reached its end of life or support as determined by the
                    original equipment manufacturer;{" "}
                  </li>
                  <li class="mb-2">are Specific Excluded Devices; </li>
                  <li class="mb-2">are used for commercial purposes. </li>
                </ul>
                <div class="mt-4 text-justify">
                  Please note that mobile phones are not eligible for coverage
                  as they are Specific Excluded Devices.
                </div>
              </>
            );
          },
        }
      ],
    },
    {
      title: "Device registration",
      items: [
        {
          subTitle:
            "Do I have to register the Eligible Device at the start of coverage?",
          answer: () => {
            return (
              <div class="text-justify">
                It is not mandatory to do so. If you have recently purchased
                your Eligible Device, you can register the device as a Covered
                Device and upload the purchase receipt for the device to enjoy a
                smooth, hassle-free claim experience.
              </div>
            );
          },
        },
        {
          subTitle:
            "I just purchased a new device. Can I add that into my device coverage?  ",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  Yes, there is no limit to the number of Eligible Devices which
                  can be covered. You can register your device as Covered Device
                  anytime via the{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => onLinkClick("select-device")}
                    class="underline"
                  >
                    portal{" "}
                  </a>{" "}
                  as long it is an Eligible Device.
                </div>
              </>
            );
          },
        },
      ],
    },
    {
      title: "File A Claim",
      items: [
        {
          subTitle: "How do I file a claim? ",
          answer: () => {
            return (
              <>
                <div>
                  You can file your claim via the online{" "}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => onLinkClick("select-device-file-claim")}
                    class="underline"
                  >
                    {" "}
                    portal{" "}
                  </a>{" "}
                  in 3 easy steps:
                </div>
                <ol class="list-decimal list-inside pl-2">
                  <li class="my-2">
                    Register your Eligible Device If your device is already
                    registered, simply select the Covered Device{" "}
                  </li>
                  <li class="mb-2">Schedule for pickup of your Covered Device </li>
                  <li class="mb-2">Provide payment details for deductible </li>
                </ol>
                <div class="mt-4">
                  Do note that you can only file a claim 31 days from your Start
                  Date.
                </div>
              </>
            );
          },
        },
        {
          subTitle: "How long does it take for my claim to be processed? ",
          answer: () => {
            return (
              <div class="text-justify">
                It will take 1 business day to assess your claim admissibility
                and upon confirmation of the acceptance of the resolution, it
                may take up to 10 business days to process your claim. You will
                receive email notification on your claim status.{" "}
              </div>
            );
          },
        },
        {
          subTitle: "Is there a limit on the number of claims I can file? ",
          answer: () => {
            return (
              <div class="text-justify">
                There is no limit on the number of claims you can file. However,
                an Aggregate Claim Limit of $5,000 applies per rolling 12
                months. Depending on the groups of Covered Device, a Per Claim
                Limit per Covered Device of $600 or $1,500 is also applicable.{" "}
              </div>
            );
          },
        },
        {
          subTitle: "How do I track the status of my claim?",
          answer: () => {
            return (
              <div class="text-justify">
                You may login to the SmartSupport Home claim portal to track the
                status of your claim. You will also receive email and/or SMS
                updates during the claim process. If you did not receive any
                email update, please check the spam folder in your email
                account.
              </div>
            );
          },
        },
        {
          subTitle: "I have filed my claim, so what do I do next?",
          answer: () => {
            return (
              <>
                <div class="text-justify">
                  You will receive updates on your claim status within 2
                  business day and your device will be picked up as scheduled.
                  Depending on the type of Covered Device, you may be asked to{" "}
                </div>
                <ol class="list-decimal list-inside pl-2">
                  <li class="my-2">Disable any personal lock and security feature </li>
                  <li class="mb-2">
                    Delete all data from the device and perform a factory reset{" "}
                  </li>
                </ol>
                <div class="mt-4 text-justify">
                  It is important that you complete the steps above prior to the
                  arrival of the courier The courier solely performs a pick-up
                  service and will not be able to accommodate for additional
                  waiting time if the device is not ready.
                </div>
              </>
            );
          },
        },
      ],
    },
    {
      title: "Delivery",
      items: [
        {
          subTitle: "Do I need to be home for pick-up and delivery of device?",
          answer: () => {
            return (
              <>
                Yes. You will need to provide verification of your identity in
                person during the device pickup or delivery.{" "}
              </>
            );
          },
        },
        {
          subTitle: "Can I reschedule my pick-up or return service?",
          answer: () => {
            return (
              <>
                You can reschedule your pick-up or return timing if it is made
                one business day before the original schedule. Simply{" "}
                <a
                  href={"javascript:void(0)"}
                  onClick={() => onLinkClick("my-claim-for-repair")}
                  class="underline"
                >
                  click here{" "}
                </a>{" "}
                to do so.{" "}
              </>
            );
          },
        },
      ],
    },
    {
      title: "Termination",
      items: [
        {
          subTitle: "Can I terminate SmartSupport Home anytime?",
          answer: () => {
            return (
              <>
                You can cancel the Coverage at any time. Just send us a message
                on{" "}
                <a
                  class="underline"
                  href={"javascript:void(0)"}
                  onClick={() =>
                    onLinkClick("https://www.facebook.com/StarHub/")
                  }
                >
                  Facebook
                </a>
                &nbsp; or &nbsp;
                <a
                  class="underline"
                  href={"javascript:void(0)"}
                  onClick={() =>
                    onLinkClick("https://www.starhub.com/wa-chatbot")
                  }
                >
                  WhatsApp.
                </a>
                &nbsp; 
                <div class="mt-2">For more details, please refer to StarHub Consumer
                General Terms and Conditions.</div>
              </>
            );
          },
        },
        {
          subTitle:
            "I have terminated my SmartSupport Home previously.  Can I apply for   SmartSupport Home again? ",
          answer: () => {
            return (
              <div class="text-justify">
                We are sorry to see you go. Do note that upon termination of
                your SmartSupport Home Coverage, you will not be able to apply
                again within the next 12 months.{" "}
              </div>
            );
          },
        },
      ],
    },
  ];
  
  return (
    <>
    <Stack orientation="vertical" metadata={metadata} />
    <div>
      {FaqData &&
        FaqData.map((record) => {
          const { items, title } = record;
          return (
             <div className="m-4 lg:w-2/3 md:justify-center lg:mx-auto">
             <div className="w-full lg:w-2/3 md:justify-center lg:mx-auto font-LatoBold">
              <Expandable title={title} expandableType ={EXPANDABLE_TYPE.QUESTION_LISTING} item={items}/>
             </div>
            </div>
          );
        })}
    </div>
    </>
  );
}
export default FAQ;
