import React, { useEffect } from "react";
import { Text } from "../text/text";
import PropTypes from "prop-types";
import { getFormmatedDate } from "../enrollment-workflow/helper";
import Icon from "../icon/icon";
import { useState } from "react";
import Table from "../table/table";

export const ClaimHistoryItem = ({
  itemMainDivClasses,
  itemClasses,
  itemTextClasses,
  separatorClasses,
  item,
  mainIconClasses,
  type,
  ...props
}) => {
  const getSeparator = () => {
    return <hr className={separatorClasses}></hr>;
  };

  const [showClaimSummary, setShowClaimSummary] = useState(false);
  const toggleClaimSummary = () => {
    setShowClaimSummary(!showClaimSummary);
  };
  
  return (
    <div className={itemMainDivClasses}>
      <div className={itemClasses} onClick={toggleClaimSummary}>
        <Text
          content={item?.date ? getFormmatedDate(item?.date, "dd MMM yyyy", " ") : ""}
          other={itemTextClasses}
        ></Text>

        <Text
          content={item?.claimNo}
          other={itemTextClasses}
        ></Text>

        <Text
          content={item?.limitUsed}
          other={itemTextClasses}
        ></Text>
        
        {type && <Text
          content={item?.claimStatus}
          other={itemTextClasses}
        ></Text>}

        <span className={mainIconClasses} onClick={toggleClaimSummary}>
          {!type ? !showClaimSummary ? (
            <Icon icon="Plus"></Icon>
          ) : (
            <Icon icon="Minus"></Icon>
          ) : ""}

          {type ? !showClaimSummary ? (
            <Icon icon="IconExpand" className="fill-primary" fill={"primary"}></Icon>
          ) : (
            <Icon icon="IconCollapse" className="fill-primary" fill={"primary"}></Icon>
          ) : ""}

        </span>
      </div>
      {showClaimSummary && 
        <Table
          {...{
            tableDivClasses: "py-4 overflow-auto w-full px-4",
            tableTitleClasses:
              "text-default text-white bg-primary py-3 px-6 w-full",
            tableClasses: "text-xl w-full",
            trClasses: "",
            thClasses:
              "p-1 text-left text-sm font-normal w-1/2",
            tdClasses:
              "p-1 text-left text-sm font-LatoBold w-1/2",
            tableHeaders: item?.tableHeaders,
            tableData: item?.tableData,
            dataType:"claimHistory"
          }}
        />
      }
      {getSeparator()}
    </div>
  );
};

ClaimHistoryItem.propTypes = {
  itemMainDivClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  itemTextClasses: PropTypes.string,
  separatorClasses: PropTypes.string,
};

ClaimHistoryItem.defaultProps = {
  itemMainDivClasses: "flex flex-col w-full bg-light",
  itemClasses: "flex flex-row p-2 pr-1 relative",
  itemTextClasses: "w-1/3 text-xs lg:text-sm",
  separatorClasses: "w-full justify-end border-secondaryS1",
  mainIconClasses:"inline-block cursor-pointer absolute right-2",
};
