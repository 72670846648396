import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata, isEmpty } from "./helper";
import Popup from "reactjs-popup";
import CancelClaimPopup from "./../enrollment-workflow/file-claim/cancelclaim-popup";

function ConfirmClaimCancellationPopup(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const { setConfirmClaimCancellationPopup } = props;
  const metadata = workflowState['config']?.metadata['ConfirmClaimCancellationPopup']
  const homeplusscript = workflowState['config']?.scripts["ConfirmClaimCancellationPopup"];

  const [isExpanded, setIsExpanded] = useState(true);
  // const [openNoProceedPopup, setNoProceedPopup] = useState(false);
  const [openCancelClaimPopup, setClaimPopup] = useState(false);

  const contentStyle = {
    padding: "20px",
    width: "400px",
    height: "300px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    border: "2px groove ",
    borderRadius: "5px",
    borderColor: "",
    textColor: "",
  };

  const onMenuItemClick = (redirect) => {
    updateState({
      ...workflowState,
      FileClaimDetails: {},
      RegDeviceDetails: {},
      OpenChatWidget: redirect === "chat",
    });

    refreshStateOnLogout(workflowState, redirect);
  };

  let textHeader = findById(metadata, "titleHeader");
  let btnNo = findById(metadata, "btnNo");
  let btnYes = findById(metadata, "btnYes");
  let icon = findById(metadata, "icon");

  const open = (isExpanded) => {
    setConfirmClaimCancellationPopup(false);
    setIsExpanded(!isExpanded);
  };

  const showPopup = () => {
    setClaimPopup(!openCancelClaimPopup);
  };

  textHeader.args = { ...textHeader.args, content: homeplusscript.titleHeader };

  const onYesClick = () => {
    showPopup();
  };
  const onNoClick = () => {
    return completeCurrentStep(currentStep, {
      onNo: true,
    });
  };

  btnYes.args = {
    ...btnYes.args,
    label: homeplusscript.btnYes,
    enabled: true,
    onClick: (e) => onYesClick(e),
  };

  btnNo.args = {
    ...btnNo.args,
    label: homeplusscript.btnNo,
    enabled: true,
    onClick: (e) => open(isExpanded),
  };

  icon.args = {
    ...icon.args,
    onClick: () => open(isExpanded),
  };
  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />
      <Popup
        contentStyle={contentStyle}
        open={openCancelClaimPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <CancelClaimPopup
          openCancelClaimPopup={openCancelClaimPopup}
          setClaimPopup={setClaimPopup}
          onMenuItemClick={onMenuItemClick}
        />
      </Popup>
    </>
  );
}
export default ConfirmClaimCancellationPopup;
