import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";

export const Note = ({
  note,
  itemClasses,
  itemIconClasses,
  itemTextColorClasses,
  ...props
}) => {
  return (
    <div>
      <div className={itemClasses}>
        <div>
          <Icon className={itemIconClasses} icon={note.icon || "TV"}></Icon>
        </div>
        <Text colorClasses={itemTextColorClasses} content={note.type}></Text>
      </div>
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.object,
  itemClasses: PropTypes.string,
  itemIconClasses: PropTypes.string,
};

Note.defaultProps = {
  onClick: undefined,
  note: { icon: "TV", type: "TV", itemCount: "2" },
  itemClasses: "flex flex-row items-start",
  itemIconClasses: "mr-2 mb-2",
  itemTextColorClasses: "text-neutral",
};
