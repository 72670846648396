import axios from "axios";

export const doRequest = async (url, method, data) => {
    const params = {
        method: method,
        url: url,
        headers: {
          'asurion-channel': process.env.REACT_APP_ENQUIRY_CHANNEL,
          'asurion-client': process.env.REACT_APP_ENQUIRY_CLIENT,
          'asurion-enduser': process.env.REACT_APP_ENQUIRY_ENDUSER,
          'asurion-lineofbusiness': process.env.REACT_APP_ENQUIRY_LINEOFBUSINESS,
          'asurion-region': process.env.REACT_APP_ENQUIRY_REGION,
          'x-api-key': process.env.REACT_APP_ENQUIRY_APIKEY
        },
    }
    let result = {}

    if (method !== 'get') {
        params.data = data;
      }

    return new Promise(resolve => {
        axios(params)
          .then(response => {
            result.data = response?.data;
            result.status = response?.status;
            result.statusText = response?.statusText;
  
            resolve(result);
          })
          .catch(e => {
            if (e && e.response) {
              result.data = e.response.data;
              result.status = e.response.status;
  
              resolve(result);
            } else {
              console.log('Error in your request. ', e);
              resolve(e);
            }
          });
      });
}