import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

const ProvideDeviceOwner = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideDeviceOwner']
  const homeplusscript = workflowState['config']?.scripts["ProvideDeviceOwner"];

  const [ownerName, setOwnerName] = useState("");
  const [ownerRelation, setOwnerRelation] = useState("");
  const [selectedOwnerOption, setSelectedOwnerOption] = useState({});
  const [isDeclarationSelected, setDeclarationSelected] = useState(false);
  const [displayProp, setDisplayProp] = useState("hidden");
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtDeviceOwnerInfo = findById(metadata, "txtDeviceOwnerInfo");
  let deviceOwner = findById(metadata, "deviceOwner");
  let inputName = findById(metadata, "inputName");
  let inputRelation = findById(metadata, "inputRelation");
  let menuLabel = findById(metadata, "menu");
  let selfDeclaration = findById(metadata, "selfDeclaration");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  const optionList = [
    { value: "Yes", label: "Yes" },
    { value: "Others", label: "Others, please describe" },
  ];

  const selfDeclarations = [
    {
      value: homeplusscript.txtDeclaration,
      label: homeplusscript.txtDeclaration,
    },
  ];

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const onContinueClick = (owner) => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        selectedOwnerOption: owner,
        ownerName: ownerName?.trim(),
        ownerRelation: ownerRelation?.trim(),
        isOtherOwnerSelected: isEmpty(owner),
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      provideInsuranceDetails: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const onOwnerOptionSelect = (deviceOwner) => {
    setSelectedOwnerOption({
      ...selectedOwnerOption,
      ...deviceOwner,
    });

    const showOtherOwnerDetailsBox = deviceOwner.value === "Others";

    if(showOtherOwnerDetailsBox) setDisplayProp("block")
    else setDisplayProp("hidden")
      
    // inputName.args = {
    //   ...inputName.args,
    //   other: showOtherOwnerDetailsBox ? "hidden" : "hidden",
    // };
    // inputRelation.args = {
    //   ...inputRelation.args,
    //   other: displayProp,
    // };

    // selfDeclaration.args = {
    //   ...selfDeclaration.args,
    //   displayClasses: displayProp,
    // };

    if (!showOtherOwnerDetailsBox) onContinueClick(deviceOwner);
  };

  const onSelect = (item, event) => {
    console.log(`checkbox checked ${event.currentTarget.checked}`);
    setDeclarationSelected(event.currentTarget.checked);
  };

  inputName.args = {
    ...inputName.args,
    label: homeplusscript.inputNameLabel,
    placeHolder: homeplusscript.inputNamePlaceHolder,
    value: ownerName,
    other:displayProp,
    onChange: setOwnerName,
  };

  inputRelation.args = {
    ...inputRelation.args,
    label: homeplusscript.inputRelationLabel,
    placeHolder: homeplusscript.inputRelationPlaceHolder,
    value: ownerRelation,
    other: displayProp,
    onChange: setOwnerRelation,
  };

  deviceOwner.args = {
    ...deviceOwner.args,
    deviceItems: optionList,
    value: selectedOwnerOption?.value,
    onClick: onOwnerOptionSelect,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled:
      isDeclarationSelected && ownerName?.trim() && ownerRelation?.trim(),
      // isDeclarationSelected && ownerRelation?.trim(),
    displayClasses:
      selectedOwnerOption?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtDeviceOwnerInfo.args = {
    ...txtDeviceOwnerInfo.args,
    content: homeplusscript.labelDeviceOwnerInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };
  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }


  selfDeclaration.args = {
    ...selfDeclaration.args,
    options: selfDeclarations,
    displayClasses: displayProp,
    onSelect: onSelect,
  };

  menuLabel.args = {
    ...menuLabel.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedOwnerOption?.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
};

export default ProvideDeviceOwner;
