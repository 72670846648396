import React from "react";
import PropTypes from "prop-types";

export const RadioButton = ({
  fontClasses,
  dimensionClasses,
  paddingClasses,
  marginClasses,
  colorClasses,
  displayClasses,
  name,
  value,
  onChange,
  label,
  ...props
}) => {
  return (
    <div
      className={`${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses} ${displayClasses}`}
    >
      <div className="flex flex-row font-LatoRegular">
        <div className="flex flex-row m-2 pr-2">
          <input type="radio" name={name} value={value} onChange={onChange} />
        </div>
        <div className="text-center p-1">{label}</div>
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  /**
   * Name of the Radio Button Group
   */
  name: PropTypes.string,
  /**
   * Value of the selected radio button option
   */
  value: PropTypes.string,
  /**
   * onChange event handler for on Selection of Option
   */
  onChange: PropTypes.func,
  label: PropTypes.string,
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  displayClasses: PropTypes.string,
};

RadioButton.defaultProps = {
};
