import {useContext} from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "./helper";
// import ActionTypes from "../../ActionTypes";

function ValidEmail() {
 const [currentStep, completeCurrentStep, workflowState, updateState] = 
 useContext(WorkflowContext);

 let emailId = workflowState?.LoginDetails?.userEmail;
 console.log(emailId)

 const metadata = workflowState['config']?.metadata['ValidEmail']
 const homeplusscript = workflowState['config']?.scripts["ValidEmail"];

let btnLogin = findById(metadata, "btnLogin");

const showLogin = () => {
 return completeCurrentStep(currentStep, {
     Login: true,
 });
};

let textHeaderHomePlus = findById(metadata, "textHeaderHomePlus");
 textHeaderHomePlus.args = {
   ...textHeaderHomePlus.args,
   content: homeplusscript.textHeaderHomePlus,
 };

let validemailText = findById(metadata, "validEmail_Text");
validemailText.args = { ...validemailText.args, content: homeplusscript.validEmail_Text };


let emailIdText = findById(metadata, "emailId_Text");
emailIdText.args = { ...emailIdText.args, content: emailId };

btnLogin.args = {
 ...btnLogin.args,
 label: homeplusscript.btnLogin_label,
  enabled: emailId,
  onClick: (e) => showLogin(),
};
 return <Stack orientation="vertical" metadata={metadata} />; 
}
export default ValidEmail;