import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";

export const DeviceCategory = ({
  deviceCategory,
  onDeviceCategorySelect,
  itemClasses,
  itemBorderClasses,
  itemIconClasses,
  itemSelectedClasses,
  value,
  itemSelectedTextColorClasses,
  itemTextColorClasses,
  itemSubTextSelectedColorClasses,
  itemSubTextColorClasses,
  ...props
}) => {
  return (
    <div
      className={`relative ${
        value?.type === deviceCategory.type
          ? itemSelectedClasses
          : itemBorderClasses
      }`}
      onClick={() => onDeviceCategorySelect(deviceCategory)}
    >
      <div className={itemClasses}>
        <Icon
          className={itemIconClasses}
          icon={deviceCategory.icon || "TV"}
        ></Icon>
        <Text
          colorClasses={`${
            value?.type === deviceCategory.type
              ? itemSelectedTextColorClasses
              : itemTextColorClasses
          }`}
          content={deviceCategory.type}
        ></Text>
      </div>
    </div>
  );
};

DeviceCategory.propTypes = {
  deviceCategory: PropTypes.object,
  onClick: PropTypes.func,
  itemClasses: PropTypes.string,
  itemBorderClasses: PropTypes.string,
  itemIconClasses: PropTypes.string,
  itemSelectedClasses: PropTypes.string,
};

DeviceCategory.defaultProps = {
  onClick: undefined,
  deviceCategory: { icon: "TV", type: "TV", itemCount: "2" },
  itemClasses: "flex flex-row items-start",
  itemBorderClasses: "cursor-pointer border border-neutralN8 rounded-md p-3",
  itemIconClasses: "mr-2 mb-2",
  itemSelectedClasses:
    "cursor-pointer border border-primary rounded-md p-3 bg-light",
  itemSelectedTextColorClasses: "text-link",
  itemTextColorClasses: "text-neutral",
  itemSubTextSelectedColorClasses: "text-link",
  itemSubTextColorClasses: "text-neutralN4",
};
