import { useContext, useState, useEffect } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectBluetooth() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectBluetooth']
  const homeplusscript = workflowState['config']?.scripts["SelectBluetooth"];

  const [selectedBluetoothOption, setSelectedBluetoothOption] = useState([]);

  let bluetoothOptions = findById(metadata, "bluetoothOptions");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menu = findById(metadata,"menu");
  let titleText = findById(metadata, "txtTitle");
  let txtBluetoothInfo = findById(metadata, "txtBluetoothInfo");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const bluetoothOptionsList = [];
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;


  useEffect(() => {
    if(isRouter){
      showBluetoothOption(["Yes","No"]);
    } else if (!isEmpty(regDeviceDetails?.selectedBrandDetails)){
      showBluetoothOption(regDeviceDetails.selectedBrandDetails.In_built_bluetooth);
    }

    // now get distinct list of all brand
    else{
      showBluetoothOption(regDeviceDetails?.distinctBuiltInBluetoothList);
    } 
  }, []);

  const showBluetoothOption = (options) => {
    console.log("Connectivity Types ; ",options);
    let bluetoothOptionList = [];
    options.forEach((type) => {
      if (!isEmpty(type)) {
        let deviceType = {
          value: type,
          label: type,
        };
        bluetoothOptionList.push(deviceType);
      }
    });

    setSelectedBluetoothOption(bluetoothOptionList);
  };

  // regDeviceDetails.selectedBrandDetails.In_built_bluetooth.forEach((type) => {
  //   if (!isEmpty(type)) {
  //     let deviceType = {
  //       value: type,
  //       label: type,
  //     };
  //     bluetoothOptionsList.push(deviceType);
  //   }
  // });

  const onContinueClick = (selectedOption) => {
    if(isRouter){
      updateState({
        ...workflowState,
        RegDeviceDetails: {
          // ...regDeviceDetails,
          acquiredFromCarrier: selectedOption.value,
        },
      }, ["RegDeviceDetails"]);
    } else {
      updateState({
        ...workflowState,
        RegDeviceDetails: {
          // ...regDeviceDetails,
          hasBluetooth: selectedOption.value,
        },
      }, ["RegDeviceDetails"]);
    }
    return completeCurrentStep(currentStep, {
      askPuchaseReceipt: isKeyboard || isMouse || (isRouter && (selectedOption.value === "No" ? true : false )),
      deviceNotRegister: (isRouter && (selectedOption.value === "Yes"? true : false ))
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBackConnectivity: true,
    });
  };

  const goToDashboard = () => {
    // clear all device reg data
    updateState({
     ...workflowState,
     RegDeviceDetails: {},
   });
   return completeCurrentStep(currentStep, {
     goToDashboard: true,
   });
 };

  const onBluetoothOptionSelect = (bluetoothOption) => {
    setSelectedBluetoothOption({
      ...selectedBluetoothOption,
      ...bluetoothOption,
    });

    onContinueClick(bluetoothOption);
  };

  bluetoothOptions.args = {
    ...bluetoothOptions.args,
    deviceItems: selectedBluetoothOption,
    value: selectedBluetoothOption?.value,
    onClick: onBluetoothOptionSelect,
  };

  titleText.args = {
    ...titleText.args,
    content: isRouter ? homeplusscript.titleRouter : homeplusscript.title.replace(
      "{'deviceCategory'}",selectedCategory.toLowerCase()),
  };

  txtBluetoothInfo.args = {
    ...txtBluetoothInfo.args,
    content: isRouter ? homeplusscript.routerInfo : homeplusscript.otherInfo.replace(
      "{'deviceCategory'}",selectedCategory.toLowerCase()),
  };
  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }
  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectBluetooth;
