import React from "react";
import PropTypes from "prop-types";
import "@react-pdf-viewer/core/lib/styles/index.css";

import iconUpload from "../../icons/ic_upload";
import { Image } from "../image/image";
import { Text } from "../text/text";
import { Tooltip } from "../tooltip/tooltip";
import IconTrash from "../../icons/ic_trash";
import PDF from "../preview-pdf/preview-pdf";

export const FileUploader = ({
  label,
  id,
  enabled,
  handleChange,
  selectedFile,
  removeFile,
  spacing,
  isButton,
  progress,
  showTooltip,
  pdfFileContent,
  labelUploadBtn,
  labelMaxUploadSize,
  allowedExtensions,
  ...props
}) => {

  const isUploadCompleted = progress === 100
  const trimLongFileName = selectedFile?.name?.substring(0, Math.min(32,selectedFile?.name?.length))
  const progressLabel = isUploadCompleted ? `${progress}% completed` : `Uploading ${progress}%`

  return (
    <div className="lg:w-1/2 lg:mx-auto">
      <div className={`mt-4 flex flex-row`}>
        {showTooltip ? (
          <Tooltip showLabel={true} labelText={label} />
        ) : (
          label && <label>{label}</label>
        )}
      </div>

      <div className="relative">
        <div className="flex flex-col items-center mt-2 border cursor-pointer border-primary">
          <label
            className={`cursor-pointer ${
              !enabled ? "opacity-50 pointer-events-none" : ""
            } 
          ${"text-neutralN4 bg-white w-220 "} ${spacing} flex flex-row items-center py-1 mx-auto rounded-lg px-4`}
          >
            <div>
              <div className="text-center">
                <Image className="inline-block" image={iconUpload} />
                <Text
                  other={"text-sm ml-2 text-neutralN4"}
                  content={labelUploadBtn}
                ></Text>
              </div>

              <Text
                other={"text-xs text-neutralN3"}
                content={labelMaxUploadSize}
              ></Text>
            </div>
            <input
              id="fileInput"
              accept={allowedExtensions}
              type="file"
              className="hidden"
              enabled={enabled}
              label=""
              onChange={(e) => handleChange(e)}
            />
          </label>
        </div>
        {selectedFile && progress > 0 && (
          <div className="absolute top-0 flex flex-row items-center justify-center w-full h-full mt-auto bg-white border border-primary">
            <div
              className="absolute left-0 z-10 h-full bg-primary"
              style={{
                width: `${progress}%`,
              }}
            ></div>
            <div className="z-20 text-secondary">{progressLabel}</div>
          </div>
        )}
      </div>
      <div>
        {selectedFile ? (
          selectedFile.type === "application/pdf" ? (
            pdfFileContent &&
            isUploadCompleted && <PDF base64String={pdfFileContent} />
          ) : (
            isUploadCompleted && (
              <div className="mt-2 overflow-auto max-h-80">
                <img
                src={URL.createObjectURL(selectedFile)}
                alt="receipt"
              />
              </div>
            )
          )
        ) : (
          <></>
        )}
      </div>

      {selectedFile && isUploadCompleted && (
        <div className="flex flex-row justify-between my-2">
          <Text content={selectedFile?.name.length > 32 ? `${trimLongFileName}...` : selectedFile?.name}></Text>
          <IconTrash
            className={"cursor-pointer"}
            onClick={() => {
              // console.log(document.querySelector('#fileInput').value);
              document.querySelector("#fileInput").value = null;
              removeFile();
            }}
          />
        </div>
      )}
    </div>
  );
};

FileUploader.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  removeFile: PropTypes.func,
  selectedFile: PropTypes.string,
  isButton: PropTypes.bool,
  progress: PropTypes.number,
  showTooltip: PropTypes.bool,
  allowedExtensions: PropTypes.string
};

FileUploader.defaultProps = {
  enabled: true,
  label: "",
  labelUploadBtn: "Take a photo or upload receipt",
  labelMaxUploadSize: "Maximum 30MB with file format: .jpg, .png, or .pdf",
  id: "Upload",
  handleChange: undefined,
  selectedFile: undefined,
  removeFile: undefined,
  isButton: false,
  showTooltip: false,
  allowedExtensions: ".jpg, .png, .pdf"
};
