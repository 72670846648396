import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function ProvideIssueDetails() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["ProvideIssueDetails"];
  const homeplusscript =
    workflowState["config"]?.scripts["ProvideIssueDetails"];

  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  const [issueDetails, setIssueDetails] = useState("");
  const [showLengthError, setShowLengthError] = useState(true);

  let textAreaIssueDetails = findById(metadata, "textAreaIssueDetails");
  let btnContinue = findById(metadata, "btnContinue");
  let btnCancel = findById(metadata, "btnCancel");
  let txtIssueDetailsInfo = findById(metadata, "txtIssueDetailsInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");
  let textLengthError = findById(metadata, "textLengthError");

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const onContinueClick = () => {
    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          issueDetails: issueDetails?.trim(),
        },
      },
      ["FileClaimDetails"]
    );

    return completeCurrentStep(currentStep, {
      triage: true,
    });
  };

  const issueDetailsLengthCheck = (value) => {
    setIssueDetails(value);
    if (value.length < 15) {
      setShowLengthError(true);
    } else if (value.length > 300) {
      setShowLengthError(true);
    } else {
      setShowLengthError(false);
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title,
  };

  textAreaIssueDetails.args = {
    ...textAreaIssueDetails.args,
    label: homeplusscript.textAreaIssueDetailsLabel,
    placeHolder: homeplusscript.textAreaIssueDetailsPlaceHolder,
    value: issueDetails,
    onChange: issueDetailsLengthCheck,
  };

  textLengthError.args = {
    ...textLengthError.args,
    content:
      issueDetails?.trim().length < 15
        ? homeplusscript.errorMinLength
        : homeplusscript.errorMaxLength,
    displayClasses: showLengthError ? "block" : "hidden",
  };

  txtIssueDetailsInfo.args = {
    ...txtIssueDetailsInfo.args,
    content: homeplusscript.issueInfo,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: !showLengthError,
    onClick: (e) => onContinueClick(),
  };
  console.log(cancelClaimPopup);
  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvideIssueDetails;
