import React from "react";
import logger from "../../logger.service";
import ServiceUnavailable from "../enrollment-workflow/ServiceUnavailable";
import { WorkflowContext } from "../workflow/workflow-context";

class ErrorBoundary extends React.Component {
  static contextType = WorkflowContext;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const [, , workflowState] = this.context;
    logger({ error, state: workflowState });
  }

  render() {
    if (this.state.hasError) {
      return <ServiceUnavailable />
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
