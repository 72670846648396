import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "./helper";
import { useState } from "react";

function PolicyAcceptance() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["PolicyAcceptance"];
  const homeplusscript = workflowState["config"]?.scripts["PolicyAcceptance"];

  let selfDeclaration = findById(metadata, "selfDeclaration");
  let btnProceed = findById(metadata, "btnProceed");
  let txtPara = findById(metadata, "txtPara");

  const Declarations = [
    {
      value: "data protection & privacy policy",
      label:
        "I have read and understood <a href='https://www.libertyinsurance.com.sg/data-protection-policy' target='_blank' class='underline'>Liberty’s Data Protection Policy</a> and <a href='https://www.asurion.com.sg/eng/privacy-policy/' target='_blank' class='underline'>Asurion’s Privacy Policy</a> (the Policies), and consent to the collection, use and disclosure of my personal data by Liberty and Asurion for the purposes set out in the Policies",
    },
  ];

  const [selected, setSelected] = useState(false);

  const onSelect = (item, event) => {
    setSelected(event.currentTarget.checked);
  };

  const onProceedClick = () => {
    updateState(
      {
        ...workflowState,
        PolicyAcceptance: {
          callFindAgreementApi: false,
        },
      },
      ["PolicyAcceptance"]
    );

    return completeCurrentStep(currentStep, {
      OnAcceptance: true,
    });
  };

  txtPara.args = {
    ...txtPara.args,
    content: homeplusscript.txtPara,
    onSelect: onSelect,
  };
  selfDeclaration.args = {
    ...selfDeclaration.args,
    options: Declarations,
    onSelect: onSelect,
  };

  btnProceed.args = {
    ...btnProceed.args,
    label: homeplusscript.btnProceed,
    enabled: selected,
    onClick: (e) => onProceedClick(),
  };
  return <Stack orientation="vertical" metadata={metadata} />;
}
export default PolicyAcceptance;
