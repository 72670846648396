import { createContext } from "react";

export const MetadataContext = createContext();

const { Provider } = MetadataContext;

export const MetadataProvider = ({ children, controlLocator }) => {
  const renderItem = (controlLocator) => (item) =>
    controlLocator[item.type]
      ? controlLocator[item.type](item.args)
      : undefined;

  return (
    <Provider value={{ renderItem: renderItem(controlLocator) }}>
      {children}
    </Provider>
  );
};

