import ActionTypes from "../../ActionTypes";
import logger from "../../logger.service";
import API from "../../services";
import {
  FULFILMENT_OPTION,
  FULFILMENT_OPTION_OVERAGE,
  REPAIR_STATUS,
} from "../../utils/constants";
import {
  callProcessOveragePaymentApi,
  callProcessPaymentApi,
  callUpdateShippingApi,
} from "./api-helper";
import generateUniqueId from "./generateUniqueId";

let fileClaimDetails;
let setOpenPopup;
let workflowState;
let updateState;
let completeCurrentStep;
let showLoader;
let refreshStateOnLogout;
let currentStep;
let chargeOrder;
let handleBGError;

let RepairRequestResponse;
let ServiceOrderResponse;
let ServiceRequestResponse;
let ProcessPaymentResponse;
let agreement;
let ChargeOrderResponse = {};

let replacementOption;
let isOverageFlow;
let isTVRepairWithOverage;
let isReschedulePickupDelivery;
let isReschedulePickup;
let isRescheduleDelivery;

let isRepair;

let fullName;
let nationalId;
let billingAddress;
let userAddress;

let ServiceOrderId;

let uniqueId;

let verifyCardResponse;

let ServiceFeeResponse;

let paymentMethodResp;

export const reschedulePickUpDeliveryOnFeeWaiver = (
  _fileClaimDetails,
  _setOpenPopup,
  _workflowState,
  _showLoader,
  _updateState,
  _completeCurrentStep,
  _refreshStateOnLogout,
  _currentStep,
  _chargeOrder,
  _handleBGError,
  _uniqueId,
  _response,
  _paymentMethodResp
) => {
  fileClaimDetails = _fileClaimDetails;
  setOpenPopup = _setOpenPopup;
  workflowState = _workflowState;
  updateState = _updateState;
  completeCurrentStep = _completeCurrentStep;
  showLoader = _showLoader;
  refreshStateOnLogout = _refreshStateOnLogout;
  currentStep = _currentStep;
  chargeOrder = _chargeOrder;
  handleBGError = _handleBGError;
  verifyCardResponse = _response;
  paymentMethodResp = _paymentMethodResp;

  RepairRequestResponse = fileClaimDetails?.RepairRequestResponse;
  ServiceOrderResponse = fileClaimDetails?.ServiceOrderResponse;
  ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  ProcessPaymentResponse = fileClaimDetails?.ProcessPaymentResponse;
  agreement = workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  replacementOption = fileClaimDetails?.selectedReplacementOption;
  isOverageFlow = fileClaimDetails?.isOverageFlow;
  isTVRepairWithOverage = fileClaimDetails?.isTVRepairWithOverage;
  isReschedulePickupDelivery =
    RepairRequestResponse?.RepairStatus === REPAIR_STATUS.PICKUP_FAILED ||
    RepairRequestResponse?.RepairStatus === REPAIR_STATUS.DELIVERY_FAILED;
  isReschedulePickup =
    RepairRequestResponse?.RepairStatus === REPAIR_STATUS.PICKUP_FAILED;
  isRescheduleDelivery =
    RepairRequestResponse?.RepairStatus === REPAIR_STATUS.DELIVERY_FAILED;

  isRepair =
    fileClaimDetails?.selectedDevice?.FulfillmentMethodType ===
    FULFILMENT_OPTION.REPAIR;

  fullName = agreement?.Customers?.Customer?.[0]?.FullName;
  nationalId = agreement?.IdentificationNumber.substr(
    agreement?.IdentificationNumber.length - 4
  );

  billingAddress = agreement?.BillingAddress;
  userAddress = agreement?.Address;
  ServiceOrderId = ServiceOrderResponse?.ServiceOrderId;
  uniqueId = _uniqueId
    ? _uniqueId
    : generateUniqueId().replace(/-/g, "").toUpperCase();
  ServiceFeeResponse = fileClaimDetails?.ServiceFeeResponse;
  // first call Charge Order with COD
  callChargeOrderApi(setOpenPopup);
};

const callChargeOrderApi = (setOpenPopup) => {
  showLoader(true);
  const isBraintree = workflowState["config"]?.isBraintree;
  const CreateChargeOrderParameters = {
    CreateChargeOrderParameters: {
      InteractionLineId:
        workflowState["CreateInteractionResponse"]?.InteractionLine
          ?.InteractionLineId,
      ServiceRequestId:
        ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
      AssetCategoryId:
        fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryId,
      AgreementId: agreement?.AgreementId,
      ClientId: workflowState["config"]?.ClientId,
      ClientChannelId: workflowState["config"]?.ClientChannelId,
      ClientAccountId: agreement.ClientAccount.ClientAccountId,
      AssetMakeId: fileClaimDetails?.selectedDevice?.Make?.MakeId,
      Transactionid: uniqueId,
      MobileNumber: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
      ChargeOrder: chargeOrder
        ? chargeOrder
        : {
            PaymentMethodType: "COD",
            ChargeOrderStatus: "PREAUTH",
            AddressId: billingAddress?.AddressId,
            AdditionalChargeAuth: "false",
            ServiceOrderId: ServiceOrderId,
          },
      ServiceOrderId: ServiceOrderId,
      IsRedeliveryFee: isReschedulePickupDelivery ? true : false,
    },
  };

  if (isBraintree) {
    console.log(verifyCardResponse, "-------------------verifyCardResponse");
    CreateChargeOrderParameters.CreateChargeOrderParameters.IsBrainTree = true;
    CreateChargeOrderParameters.CreateChargeOrderParameters.BraintreeDetails = {
      Token: paymentMethodResp?.Token,
      BraintreeTransactionDetailId:
        paymentMethodResp?.BraintreeTransactionDetailId,
      PayerId: paymentMethodResp?.PayerId,
      UniqueNumberIdentifier: paymentMethodResp?.UniqueNumberIdentifier,
    };
  }

  let actionTypes = isBraintree
    ? ActionTypes.BT_CREATE_CHARGE_ORDER
    : ActionTypes.CREATE_CHARGE_ORDER;
  API[actionTypes](CreateChargeOrderParameters, ServiceOrderId)
    .then((data) => {
      showLoader(false);

      const ChargeOrder = data?.data?.CreateChargeOrderResults?.ChargeOrder;
      ChargeOrderResponse = ChargeOrder;

      updateState(
        {
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            ccDetails: chargeOrder?.ccDetails,
            ChargeOrderResponse,
            PCITokenResponse: undefined,
          },
        },
        ["FileClaimDetails"]
      );

      const chargeOrderHold = data?.data?.CreateChargeOrderResults.Holds;

      const isHold = (holds) => {
        // return holds.some(hold => hold.HoldType === 'SRVAL' && hold.HoldStatus === "OPEN");
        // not allowing to move ahead for any hold
        return holds.some((hold) => hold.HoldStatus === "OPEN");
      };

      if (
        setOpenPopup &&
        chargeOrderHold?.length > 0 &&
        isHold(chargeOrderHold)
      ) {
        setOpenPopup(true);
        return;
      }
      if (isTVRepairWithOverage) {
        // for TV with overage we will allow user to select delivery details
        return completeCurrentStep(currentStep, {
          selectDeliverySchedule: true,
        });
      } else if (isOverageFlow || isReschedulePickupDelivery) {
        callProcessPayment();
      } else {
        return completeCurrentStep(currentStep, {
          ScheduleOnsiteDate: isRepair,
          ShowTermsPage: !isRepair,
        });
      }
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      showLoader(false);
      logger({
        type: ActionTypes.CREATE_CHARGE_ORDER,
        error,
        state: workflowState,
      });
      refreshStateOnLogout(workflowState, "service-unavailable");
      updateState({
        ...workflowState,
        [currentStep]: {
          ...workflowState[currentStep],
        },
      });
    });
};

const callProcessPayment = () => {
  const isBraintree = workflowState["config"]?.isBraintree;
  const paymentMethod = chargeOrder?.PaymentMethodType;
  let paymentMethodType =
    chargeOrder?.PaymentMethodType === "CRE"
      ? "CRE"
      : chargeOrder?.PaymentMethodType === "PYPL"
      ? "PYPL"
      : "COD";

  showLoader(true);
  const ProcessPaymentParameters = {
    ProcessPaymentParameters: {
      ChargeOrderId: ChargeOrderResponse?.ChargeOrderId,
      ServiceOrderId: ServiceOrderId,
      ApplicationId: workflowState["config"]?.ApplicationId,
      ClientId: workflowState["config"]?.ClientId,
      InteractionLineId:
        workflowState["CreateInteractionResponse"]?.InteractionLine
          ?.InteractionLineId,
      ServiceRequestId:
        ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      ServiceOrderNumber: ServiceOrderResponse?.ServiceOrderNumber?.toString(),
      AuthorizeAdditionalCharges: false,
      ValidateAddress: false,
      ValidateCardSecurityCode: false,
      IpAddressForFraud: workflowState["config"]?.SourceIpAddress,
      FulfillmentOption: "PUR",
      PaymentInformation: {
        PaymentMethodType: paymentMethodType,
        CardType:
          paymentMethod === "CRE" ? "CRE" : paymentMethod === "PYPL" ? "PYPL" : "",
        CardBrand:
          paymentMethod === "CRE"
            ? chargeOrder?.ChargeOrderCardBrand
            : paymentMethod === "PYPL"
            ? chargeOrder?.ChargeOrderCardBrand
            : "",
        CardCheckNumber:
          paymentMethod === "CRE" || paymentMethod === "PYPL"
            ? chargeOrder?.CardCheckNumber?.toString()
            : "",
        AccountHolder: {
          FirstName: agreement.Customers?.Customer[0]?.FirstName,
          LastName: agreement.Customers?.Customer[0]?.LastName,
          CompanyName: "",
          IdentificationType: "NATIONALID",
          IdentificationNumber: agreement?.IdentificationNumber,
        },
      },
      Address: userAddress,
      ContactDetails: {
        phone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
        workPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
        cellPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
        email: workflowState["login"]?.email,
      },
      AutoCompleteId: ChargeOrderResponse?.AutoCompleteId,
    },
  };
  
  if (isBraintree) {
    ProcessPaymentParameters.ProcessPaymentParameters.BrainTreeDetails = {
      IsBrainTree: true,
      Amount: isOverageFlow
        ? replacementOption?.OverageAmount?.toString()
        : workflowState["BraintreeDetails"]?.ServiceFeeResponse?.TotalAmount,
      Maid: isBraintree?.maid,
      ThreeDSecureAuthenticationId:
        fileClaimDetails?.verifyCardResponse?.threeDSecureAuthenticationId,
      ProductId: fileClaimDetails?.verifyCardResponse?.productId,
      Prepaid: fileClaimDetails?.verifyCardResponse?.prepaid,
      BillingAddress: {
        GivenName: agreement.Customers?.Customer[0]?.FirstName,
        Surname: agreement.Customers?.Customer[0]?.LastName,
        PhoneNumber: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
        streetAddress: userAddress?.Address1,
        ExtendedAddress: userAddress?.Address2,
        Locality: userAddress?.City,
        Region: "SG", // ISO-3166-2 code
        PostalCode: userAddress?.PostalCode,
        CountryCodeAlpha2: "SG",
      },
    };
  }

  callProcessPaymentApi(
    ProcessPaymentParameters,
    ServiceOrderId,
    ChargeOrderResponse?.ChargeOrderId,
    onApiSuccess,
    onApiFailure
  );
};

const callProcessOveragePayment = () => {
  showLoader(true);

  const ProcessOveragePaymentParameters = {
    ProcessOveragePaymentParameters: {
      CustomerDecision: isReschedulePickupDelivery
        ? "RE-DELIVERY FEE"
        : replacementOption?.FulfillmentOption,
      RepairRequestId: RepairRequestResponse?.RepairRequestId,
      CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
      ServiceRequestId:
        ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      ServiceOrderId,
      AssetCatalogId: replacementOption?.AssetCatalogId,
      UpdatedBy: agreement?.Customers?.Customer[0]?.FullName,
      ServiceOrderLines:
        replacementOption?.FulfillmentOption?.toUpperCase() ===
          FULFILMENT_OPTION_OVERAGE.REPAIR_OVERAGE || isReschedulePickupDelivery
          ? undefined
          : {
              ServiceOrderLine: [
                {
                  ServiceOrderLineType: replacementOption?.AssetCategoryName,
                  Quantity: 1,
                  AssetCatalogId: replacementOption?.AssetCatalogId,
                  VendorItemId: replacementOption?.ItemId,
                  Priority: "MNDTRY",
                  VenderItemType: replacementOption?.AssetCategoryName,
                  VendorData: {
                    IsLikeForLike: true,
                    IsSimCard: false,
                    IsInStock: true,
                  },
                },
              ],
            },
      RepairStatus: isReschedulePickup
        ? REPAIR_STATUS.PICKUP_RESCHEDULED
        : isRescheduleDelivery
        ? REPAIR_STATUS.DELIVERY_RESCHEDULED
        : undefined,
    },
  };

  callProcessOveragePaymentApi(
    ProcessOveragePaymentParameters,
    onApiSuccess,
    onApiFailure
  );
};

const callUpdateShipping = () => {
  showLoader(true);
  const UpdateShippingOrderParameters = {
    UpdateShippingOrderParameters: {
      EntityUpdateOptions: {
        ReturnEntity: true,
      },
      InteractionLineId:
        workflowState["CreateInteractionResponse"]?.InteractionLine
          ?.InteractionLineId,
      ServiceRequestId:
        ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      InboundShippingOrder: isRepair
        ? {
            ShippingMethodType: "Same Day",
            CarrierNumber: "SGINTCAR",
            SignatureRequired: true,
            RequestedShipmentDate: isRescheduleDelivery
              ? fileClaimDetails?.deliveryDate
              : undefined,
            ExpectedDeliveryDate: isRescheduleDelivery
              ? fileClaimDetails?.deliveryDate
              : undefined,
            DeliveryWindow: isRescheduleDelivery
              ? fileClaimDetails?.deliveryTimeSlot
              : undefined,
            NationalId: nationalId,
            Remark: "",
            AlternateContactNumber: "",
            ReceiverName: fullName,
            ReceiverAddress: {
              LocationType: "Residential",
              BusinessName: fullName,
              Standardized: true,
              IsOverridden: false,
              StandardizedSourceName: "QAS",
              BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
              IsScrubbed: true,
              ...userAddress,
            },
          }
        : undefined, // for replacement we are not passing this field
      ShippingOrder: {
        ShippingMethodType: "Same Day",
        CarrierNumber: "SGINTCAR",
        SignatureRequired: true,
        RequestedShipmentDate:
          isRepair && isReschedulePickup
            ? fileClaimDetails?.onsiteDate
            : isRepair && isRescheduleDelivery
            ? fileClaimDetails?.ShippingOrderResponse?.ShippingOrder
                ?.ShipmentDate // for repair and delivery pass existing pick up date
            : fileClaimDetails?.deliveryDate,
        ExpectedDeliveryDate:
          isRepair && isReschedulePickup
            ? fileClaimDetails?.onsiteDate
            : isRepair && isRescheduleDelivery
            ? fileClaimDetails?.ShippingOrderResponse?.ShippingOrder
                ?.ShipmentDate // for repair and delivery pass existing pick up date
            : fileClaimDetails?.deliveryDate,
        DeliveryWindow:
          isRepair && isReschedulePickup
            ? fileClaimDetails?.onsiteTimeSlot
            : isRepair && isRescheduleDelivery
            ? fileClaimDetails?.ShippingOrderResponse?.ShippingOrder
                ?.DeliveryWindow // for repair and delivery pass existing pick up slot
            : fileClaimDetails?.deliveryTimeSlot,
        NationalId: nationalId,
        Remark: "",
        AlternateContactNumber: "",
        ReceiverName: fullName,
        ReceiverAddress: {
          LocationType: "Residential",
          BusinessName: fullName,
          Standardized: true,
          IsOverridden: false,
          StandardizedSourceName: "QAS",
          BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
          IsScrubbed: true,
          ...userAddress,
        },
      },
    },
  };

  callUpdateShippingApi(
    UpdateShippingOrderParameters,
    ServiceOrderId,
    onApiSuccess,
    onApiFailure
  );
};

const onApiSuccess = (type, data) => {
  showLoader(false);
  if (type === ActionTypes.UPDATE_REPAIR_REQUEST) {
    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          UpdateRepairRequestResponse: data,
        },
      },
      ["FileClaimDetails"]
    );
    callProcessPayment();
  } else if (type === ActionTypes.PROCESS_PAYMENT) {
    const success = ["BG-0", "BG-703"];
    if (
      handleBGError &&
      !success.includes(data?.ProcessPaymentResults?.Result?.Code)
    ) {
      handleBGError(data?.ProcessPaymentResults?.Result);
    } else {
      callProcessOveragePayment();
    }
  } else if (type === ActionTypes.PROCESS_OVERAGE_PAYMENT) {
    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ProcessOveragePaymentResponse: data,
        },
      },
      ["FileClaimDetails"]
    );

    if (isReschedulePickupDelivery) callUpdateShipping();
    else
      return completeCurrentStep(currentStep, {
        showOverageNotes: true,
      });
  } else if (type === ActionTypes.UPDATE_SHIPPING_ORDER) {
    showLoader(false);
    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ShippingOrderResponse: data.ShippingOrderUpdateResults,
        },
      },
      ["FileClaimDetails"]
    );

    return completeCurrentStep(currentStep, {
      showOverageNotes: true,
    });
  }
};

const onApiFailure = (type, error) => {
  showLoader(false);
  logger({ type, error, state: workflowState });
  refreshStateOnLogout(workflowState, "service-unavailable");
  updateState({
    ...workflowState,
    [currentStep]: {
      ...workflowState[currentStep],
    },
  });
};
