import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../../enrollment-workflow/helper";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";

import logger from "../../../logger.service";

function SelectDevice() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [deviceCategories, setDeviceCategories] = useState([]);

  const metadata = workflowState['config']?.metadata['SelectDevice']
  const homeplusscript = workflowState['config']?.scripts["SelectDevice"];

  let FindAgreementResponse = workflowState["FindAgreementResponse"];

  const onContinueClick = (category) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        selectedCategory: category,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      selectInventory: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

  useEffect(() => {
    showLoader(true);
    API[ActionTypes.GET_CATEGORY_LIST](
      FindAgreementResponse?.Agreements?.Agreement[0].ClientOffer.ClientOfferId
    )
      .then((data) => {
        showLoader(false);
        let deviceCategories = data?.data?.GetAssetCategoryResponse;
        deviceCategories.sort((a, b) => {
          let x = a.CategoryName.toLowerCase();
          let y = b.CategoryName.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });

        updateState({
          ...workflowState,
          deviceCategories: deviceCategories,
        });

        // display device categories
        if (deviceCategories && deviceCategories.length > 0)
          displayDeviceCategories(deviceCategories);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_CATEGORY_LIST, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  }, []);

  const displayDeviceCategories = (categories) => {
    let categoryList = [];
    categories.forEach((category) => {
      if (!isEmpty(category)) {
        let deviceCategory = {
          key: category.AssetCategoryId,
          icon: category.CategoryName,
          type: category.CategoryName,
        };

        categoryList.push(deviceCategory);
      }
    });
    setDeviceCategories(categoryList);
  };

  const onCategorySelect = (category) => {
    setSelectedCategory({
      ...selectedCategory,
      ...category,
    });

    onContinueClick(category);
  };

  let listCategories = findById(metadata, "registeredDevices");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menu = findById(metadata,"menu");

  listCategories.args = {
    ...listCategories.args,
    options: deviceCategories,
    value: selectedCategory,
    onListItemSelect: onCategorySelect,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"]
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDevice;
