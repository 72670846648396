import { makeStyles } from '@material-ui/core/styles';

export const LoaderStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  loader: {
    margin: '0 auto',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  loaderText: {
    float: 'left',
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));
