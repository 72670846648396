import React from "react";
import PropTypes from "prop-types";
import { Text } from "../text/text";

export const PolicyInfoItem = ({
  label,
  value,
  labelClasses,
  valueClasses,
  others,
  ...props
}) => {
  return (
    <div className={`px-2 py-1 flex flex-col items-center ${others}`}>
      <Text other={valueClasses} content={value}></Text>
      <Text other={labelClasses} content={label}></Text>
    </div>
  );
};

PolicyInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  valueClasses: PropTypes.string,
  labelClasses: PropTypes.string,
};

PolicyInfoItem.defaultProps = {
  label: "",
  value: "",
};
