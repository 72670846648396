import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";


function SelectDeviceModel() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceModel']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceModel"];

  const [otherModel, setOtherModel] = useState("");
  const [deviceModelList, setDeviceModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let deviceModels = findById(metadata, "deviceModels");
  let inputOtherModel = findById(metadata, "inputOtherModel");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDeviceModelInfo = findById(metadata, "txtDeviceModelInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const getTabletKey = () => {
    return regDeviceDetails?.selectedBrand.toUpperCase() === "APPLE" ? "iPad" : "Tablet"
  }

  const onContinueClick = (deviceModel) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedDeviceModel: deviceModel?.value || otherModel?.trim(),
        isOtherDeviceModelSelected: isEmpty(deviceModel)
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      askPuchaseReceipt: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceModels(
        regDeviceDetails.selectedBrandDetails.Model
      );
    // now get distinct list of all brand
    else showDeviceModels(regDeviceDetails?.distinctModelList);
  }, []);

  const showDeviceModels = (deviceModels = []) => {
    let modelList = [];
    deviceModels.forEach((model) => {
      if (!isEmpty(model)) {
        let deviceModels = {
          value: model,
          label: model,
        };
        modelList.push(deviceModels);
      }
    });

    // add others at last
    modelList.push({ value: "Others", label: "Others" });

    setDeviceModelList(modelList);
  };

  const onModelSelect = (model) => {
    hideShowInputOtherCapacity(model);
    setSelectedModel({ ...selectedModel, ...model });

    if (model.value !== "Others") onContinueClick(model);
    else setDisplayProp("block")
  };

  const hideShowInputOtherCapacity = (model) => {
    // clear input value
    setOtherModel("");
    // inputOtherModel.args = {
    //   ...inputOtherModel.args,
    //   other: model.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: model.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: model.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      getTabletKey()
    ),
  };

  deviceModels.args = {
    ...deviceModels.args,
    deviceItems: deviceModelList,
    value: selectedModel?.value,
    onClick: onModelSelect,
  };

  inputOtherModel.args = {
    ...inputOtherModel.args,
    label: homeplusscript.labelOtherModel,
    placeHolder: homeplusscript.placeHolderOtherModel,
    value: otherModel,
    other: displayProp,
    onChange: setOtherModel,
  };

  txtDeviceModelInfo.args = {
    ...txtDeviceModelInfo.args,
    content: homeplusscript.deviceModelInfo.replace(
      "{'deviceCategory'}",
      getTabletKey()
    ),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherModel?.trim(),
    displayClasses: selectedModel?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceModel;
