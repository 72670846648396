import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getFormmatedDate, isEmpty } from "../enrollment-workflow/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";

import {
  FULFILMENT_OPTION,
  GROUP_A_DEVICES,
  GROUP_B_DEVICES,
  GTM_EVENT,
  POLICY_STATUS,
  REPAIR_STATUS,
} from "../../utils/constants";
import { logGTMEvent } from "./api-helper";
import logger from "../../logger.service";
import Popup from "reactjs-popup";
import CarrierContractType from "./carrierContractTypePopup";
import Campaign from "./campaignPopup";

export const Dashboard = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const [policyInfo, setPolicyInfo] = useState({});
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState({});
  const [groupADeviceCategories, setGroupADeviceCategories] = useState([]);
  const [groupBDeviceCategories, setGroupBDeviceCategories] = useState([]);

  const [registeredDevices, setRegisteredDevices] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [isAgreementSuspended, setAgreementSuspended] = useState(false);
  const [findAgreementChat, setFindAgreementChat] = useState({});
  const [completedClaims, setCompletedClaims] = useState([]);
  const [isClaimHistoryLoading, setIsClaimHistoryLoading] = useState(false);
  const [openClaimStatus, setOpenClaimStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openCampaignPopup, setOpenCampaignPopup] = useState(false);

  const metadata = workflowState["config"]?.metadata["Dashboard"];
  const homeplusscript = workflowState["config"]?.scripts["Dashboard"];

  const repairRequestStatusMatrix = homeplusscript["repairRequestStatusMatrix"];
  const statusMatrix = homeplusscript["statusMatrix"];
  const claimDescriptionMatrix = homeplusscript["claimDescriptionMatrix"];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  useEffect(() => {
    // showLoader(true);
    setIsClaimHistoryLoading(true);
    if (!workflowState.token) securityAdvisorToken();
    let callFindAgreementApi =
      workflowState["PolicyAcceptance"]?.callFindAgreementApi;

    const agreement =
      workflowState["FindAgreementResponse"]?.Agreements?.Agreement?.[0];

    if (agreement) loadDashboard(agreement, false);

    if (
      workflowState["PolicyAcceptance"]?.hasOwnProperty(
        "callFindAgreementApi"
      ) &&
      !callFindAgreementApi
    ) {
      updateState(
        {
          ...workflowState,
          PolicyAcceptance: {
            callFindAgreementApi: true,
          },
        },
        ["PolicyAcceptance"]
      );

      let CarrierContractType = agreement?.CarrierContractType;
      if (agreement && !CarrierContractType) setOpenPopup(true);
    } else {
      // showLoader(true);
      const FindAgreementsParameters = {
        FindAgreementsParameters: {
          InteractionLineId:
            workflowState["CreateInteractionResponse"]?.InteractionLine
              ?.InteractionLineId,
          ClientId: workflowState["config"]?.ClientId,
          ClientChannelId: workflowState["config"]?.ClientChannelId,
          EmailAddressSearch: {
            EmailAddress: workflowState["login"]?.email,
            // EmailAddress: "saket.bhole1@gmail.com",
          },
          // MobileDeviceNumberSearch: {
          //   MobileDeviceNumber: "9923052598",
          // },
        },
      };

      API[ActionTypes.FIND_AGREEMENT_REQUEST](FindAgreementsParameters)
        .then((data) => {
          console.log("Find Agreement Data : ", data);
          // showLoader(false);
          updateState(
            {
              ...workflowState,
              FindAgreementResponse: data.data.FindAgreementsResults,
            },
            ["FindAgreementResponse"]
          );

          // set result data
          const results = data.data.FindAgreementsResults;
          const agreement = results.Agreements.Agreement[0];

          // load Dashboard
          loadDashboard(agreement, true);

          // check if policy is accepted
          let CarrierContractType = agreement?.CarrierContractType;
          // if (agreement && !CarrierContractType) setOpenPopup(true);
          if (agreement && !CarrierContractType) {
            return completeCurrentStep(currentStep, {
              OnPolicy: true,
            });
          }
        })
        .catch((error) => {
          console.log(`error is ${error}`);
          showLoader(false);
          refreshStateOnLogout(workflowState, "service-unavailable");
          logger({
            type: ActionTypes.FIND_AGREEMENT_REQUEST,
            error,
            state: workflowState,
          });
          updateState({
            ...workflowState,
            [currentStep]: {
              ...workflowState[currentStep],
            },
          });
        });
    }
  }, []);

  const loadDashboard = (agreement, callClaimApi) => {
    //  if (agreement) {
    //     dashboardContainer.args = {
    //       ...dashboardContainer.args,
    //       other: "block",
    //     };
    //   }
    const contactPointsData =
      agreement &&
      agreement.ContactPoints &&
      agreement.ContactPoints.ContactPoint
        ? agreement.ContactPoints.ContactPoint
        : [];
    let ChatMdn, ChatEmailAddress;
    for (let i = 0; i < contactPointsData.length; i++) {
      const data = contactPointsData[i];
      if (data && data.ContactPointType.toUpperCase() === "MOBILE") {
        ChatMdn = data.PhoneNumber;
      } else if (data && data.ContactPointType.toUpperCase() === "EMAIL") {
        ChatEmailAddress = data.EmailAddress;
      }
    }

    setFindAgreementChat({
      AgreementId: agreement.AgreementId,
      ClientAccountId: agreement.ClientAccount.ClientAccountId,
      ChatMdn,
      ChatEmailAddress,
      ...agreement,
    });

    sessionStorage.setItem(
      "fullname",
      agreement?.Customers?.Customer?.[0].FullName
    );

    if (agreement) {
      // set policy details
      displayPolicyInformation(agreement);
      if (callClaimApi) {
        callClaimDetailsApi(agreement);
      }
    }

    // log policy number event
    logGTMEvent({
      event: GTM_EVENT.POLICY_NO_EVENT,
      policyNo: agreement?.ContractId,
    });

    if (agreement?.AgreementStatus === POLICY_STATUS.SUSPND)
      setAgreementSuspended(true);

    // set devices
    const assets = agreement?.Assets?.Asset;
    if (assets && assets.length > 0) {
      setAssetList(assets);
      displayGroupADeviceCategories(assets);
      displayGroupBDeviceCategories(assets);
    }
    // else {
    //   setGroupADeviceCategories([]);
    // };
  };

  const callClaimDetailsApi = (agreement) => {
    setIsClaimHistoryLoading(true);
    API[ActionTypes.GET_CLAIM_DETAILS](
      agreement?.ClientAccount?.ClientAccountId,
      agreement?.AgreementId
    )
      .then((data) => {
        updateState(
          {
            ...workflowState,
            MyClaimResponse: data?.data?.CustomerCaseResults?.CustomerCases,
          },
          ["MyClaimResponse"]
        );

        setIsClaimHistoryLoading(false);
        let CustomerCases = data?.data?.CustomerCaseResults?.CustomerCases;
        prepareClaimHstoryData(CustomerCases, agreement);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        setIsClaimHistoryLoading(false);
        logger({
          type: ActionTypes.GET_CLAIM_DETAILS,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
      });
  };

  const prepareClaimHstoryData = (CustomerCases, agreement) => {
    const myOpenClaim = CustomerCases?.[0];

    if (myOpenClaim?.CustomerCaseStatus === "WORKING") {
      setOpenClaimStatus(getResumeClaimStatus(myOpenClaim));
    }

    const myCompletedClaims = CustomerCases?.filter(
      (claim) => claim?.CustomerCaseStatus === "CMPLTD"
    );

    let claimHistory = [];

    myCompletedClaims.forEach((claim) => {
      let claimData = {
        date: claim?.CustomerCaseStatus?.toLowerCase()?.includes(
          homeplusscript?.labelCmpltd
        )
          ? getClaimDate(
              claim?.ServiceRequests?.[0]?.ShippingOrderInbound?.ShippingDate ||
                claim?.ServiceRequests?.[0]?.ShippingOrderOutbound?.ShippingDate
            )
          : undefined,
        claimNo: claim?.CustomerCaseNumber,
        limitUsed: claim?.UsedClaimLimit ? `$ ${claim?.UsedClaimLimit}` : "",
        tableHeaders: getClaimDetailsTableHeaders(claim),
        tableData: getClaimDetailsTableData(claim, agreement),
      };
      claimHistory.push(claimData);
    });
    setCompletedClaims(claimHistory);
  };

  const getClaimDetailsTableHeaders = (customerCase) => {
    let FulfillmentMethodType =
      customerCase?.ServiceRequests?.[0]?.FulfillmentMethodType ||
      customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.FulfillmentMethodType;

    return [
      {
        key: "replacementDevice",
        text: homeplusscript?.labelReplaceDevice,
        hideForNoAction:
          isEmpty(
            customerCase?.ServiceRequests?.[0]?.ReplacedDevice?.AssetCatalog
              ?.Name
          ) && FulfillmentMethodType !== FULFILMENT_OPTION.REPLACMENT
            ? true
            : false,
      },
      {
        key: "repairDevice",
        text: homeplusscript?.labelCoveredDevice,
        hideForNoAction:
          FulfillmentMethodType !== FULFILMENT_OPTION.REPAIR ||
          isEmpty(
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog
              ?.Name
          )
            ? true
            : false,
      },
      {
        key: "claimNumber",
        text: homeplusscript?.labelClaimNumber,
        hideForNoAction: false,
      },
      {
        key: "resolutionOffer",
        text: homeplusscript?.labelResolutionOffer,
        hideForNoAction: false,
      },
      {
        key: "claimStatus",
        text: homeplusscript?.labelClaimStatus,
        statusTextColorClasses:
          getClaimStatus(customerCase) === "Completed"
            ? "text-primary"
            : "text-accentA3",
        hideForNoAction: false,
      },
      // {
      //   key: "resolutionDate",
      //   text: homeplusscript?.labelResolutionDate,
      //   hideForNoAction: customerCase?.CustomerCaseStatus?.toLowerCase()?.includes("cmpltd") ? false : true,
      // },
      {
        key: "overagePaid",
        text: homeplusscript?.labelOveragePaid,
        hideForNoAction: isEmpty(getOverageAmount(customerCase)) ? true : false,
      },
      // {
      //   key: "availableClaimLimit",
      //   text: homeplusscript?.labelAvailableClaimLimit,
      //   hideForNoAction: customerCase?.ResumeStatus?.split(" ")?.join("")?.toLowerCase() === "awaitingcustomerselection" ? false : true,
      // },
    ];
  };

  const getClaimDetailsTableData = (customerCase, agreement) => {
    let FulfillmentMethodType =
      customerCase?.ServiceRequests?.[0]?.FulfillmentMethodType ||
      customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.FulfillmentMethodType;
    return [
      {
        replacementDevice:
          customerCase?.ServiceRequests?.[0]?.ReplacedDevice?.AssetCatalog
            ?.Name,
        repairDevice:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog
            ?.Name,
        claimNumber: customerCase?.CustomerCaseNumber,
        claimStatus: getClaimStatus(customerCase),
        resolutionOffer:
          FulfillmentMethodType === FULFILMENT_OPTION.REPLACMENT
            ? homeplusscript?.labelReplacement
            : FulfillmentMethodType,
        overagePaid: getOverageAmount(customerCase),
      },
    ];
  };

  const getClaimStatus = (customerCase) => {
    let claimStatus = customerCase?.CustomerCaseStatus;
    let resumeClaimstatus = customerCase?.ResumeStatus;
    let repairClaimStatus = customerCase?.RepairStatus;

    if (claimStatus?.toLowerCase()?.includes(homeplusscript?.labelCncl))
      return homeplusscript?.labelCanceled;
    if (claimStatus?.toLowerCase()?.includes(homeplusscript?.labelCmpltd))
      return homeplusscript?.labelCompleted;
    if (repairClaimStatus)
      if (
        repairClaimStatus?.toLowerCase() ===
        homeplusscript?.labelCompleted?.toLowerCase()
      )
        return homeplusscript?.labelCompleted;
    if (
      customerCase?.ServiceRequests?.[0]?.ServiceRequestStatus?.toLowerCase() ===
      homeplusscript?.labelHold
    )
      return repairRequestStatusMatrix?.hold;
    if (!resumeClaimstatus || repairClaimStatus) {
      let repairRequestStatusMatrixKey = repairClaimStatus
        ?.split(" ")
        ?.join("")
        ?.toLowerCase();
      return (
        repairRequestStatusMatrix[repairRequestStatusMatrixKey] ||
        repairRequestStatusMatrix?.other
      );
    } else
      return (
        repairRequestStatusMatrix[
          customerCase?.ResumeStatus?.split(" ")?.join("")?.toLowerCase()
        ] || repairRequestStatusMatrix?.other
      );
  };

  const getOverageAmount = (customerCase) => {
    let ChargeOrder = customerCase?.ServiceRequests?.[0]?.ChargeOrder;
    let overageAmount = undefined;
    ChargeOrder.forEach((item) => {
      if (item?.ChargeOrderType === "OVG") {
        overageAmount = "$" + item?.TotalAmount;
      }
    });
    return overageAmount;
  };

  const getClaimDate = (claimDate) => {
    if (claimDate) {
      let formattedDate = getFormmatedDate(claimDate, "dd MMM yyyy", " ");
      return formattedDate;
    }
  };

  const getResumeClaimStatus = (customerCase) => {
    let resumeStatus = customerCase?.ResumeStatus;
    let repairClaimStatus = customerCase?.RepairStatus;
    let statusKey = "";
    if (repairClaimStatus === REPAIR_STATUS.DEVICE_MISMATCH || repairClaimStatus === REPAIR_STATUS.PICKUP_FAILED || repairClaimStatus === REPAIR_STATUS.DELIVERY_FAILED) {
      statusKey = repairClaimStatus?.split(" ")?.join("")?.toLowerCase();
    } else if (resumeStatus) {
      statusKey = resumeStatus?.split(" ")?.join("")?.toLowerCase();
    }

    // if (
    //   process.env.REACT_APP_IS_VIDEO_DECLARATION_AVAILABLE === "FALSE" &&
    //   (statusKey === "awaitingpaymentvideodeclaration" ||
    //     statusKey === "awaitingshippingaddressvideodeclaration")
    // )
    //   return "";

    return statusMatrix[statusKey] ? claimDescriptionMatrix[statusKey] : "";
  };

  const getIpAddress = async () => {
    return API[ActionTypes.GET_IP_ADD]()
      .then((response) => response?.data?.ip || "110.164.222.226")
      .catch(() => "110.164.222.226");
  };

  const securityAdvisorToken = async () => {
    // showLoader(true)
    const [ipAddress] = await Promise.all([getIpAddress()]);
    const getTokenRequest = {
      getTokenRequest: {
        email: workflowState["login"]?.email,
        ua: navigator.userAgent,
        ip: ipAddress || "110.164.222.226",
        client: "Starhub HomePlus",
      },
    };
    API[ActionTypes.GET_SECURITY_ADVISOR_TOKEN_REQUEST](getTokenRequest)
      .then((data) => {
        //  showLoader(false)
        sessionStorage.setItem("token", data.data.token);
        updateState(
          {
            ...workflowState,
            token: data?.data?.token,
          },
          ["token"]
        );
      })
      .catch((error) => {
        // showLoader(false)
        console.log("SecurityAdvisorError:", error);
      });
  };

  const displayPolicyInformation = (agreement) => {
    let policyInformation = {
      policyNumber: "",
      startDate: "",
      status: "",
      totalLimit: "",
      claimed: "",
    };

    policyInformation.policyNumber = agreement.ContractId.replace(
      /^(.{3})(.{3})(.*)$/,
      "$1 $2 $3"
    );
    policyInformation.startDate = agreement.AgreementPurchase.PurchaseDate;
    policyInformation.status =
      agreement.AgreementStatus === POLICY_STATUS.ACTV
        ? homeplusscript.labelActive
        : agreement.AgreementStatus === POLICY_STATUS.SUSPND
        ? homeplusscript.labelSuspended
        : agreement.AgreementStatus;
    policyInformation.totalLimit = homeplusscript.labelTotalLimit.replace(
      "{'total'}",
      formatter.format(agreement.TotalLimit)
    );
    policyInformation.remainingLimit =
      homeplusscript.labelRemainingLimit.replace(
        "{'remaining'}",
        formatter.format(agreement.RemainingBenefit)
      );
    policyInformation.claimed = homeplusscript.labelClaimed.replace(
      "{'claimed'}",
      getClaimedAmount(agreement)
    );
    policyInformation.totalLimitInfo = homeplusscript.labelTotalLimitInfo;

    setPolicyInfo(policyInformation);
  };

  const getClaimedAmount = (agreement) => {
    return formatter.format(
      parseInt(agreement.TotalLimit) - agreement.RemainingBenefit
    );
  };

  const displayGroupADeviceCategories = (assets) => {
    let enrolledDevices = [];
    GROUP_A_DEVICES.forEach((category) => {
      let deviceCateogry = {
        key: assets.find(
          (item) => item.AssetCategory.AssetCategoryName === category.type
        )?.AssetCategory?.AssetCategoryId,
        icon: category?.icon,
        type: category?.type,
        itemCount: `${
          assets.filter(
            (item) => item.AssetCategory.AssetCategoryName === category.type
          ).length
        } device(s)`,
      };
      enrolledDevices.push(deviceCateogry);
    });
    setGroupADeviceCategories(enrolledDevices);
  };

  const displayGroupBDeviceCategories = (assets) => {
    let enrolledDevices = [];
    GROUP_B_DEVICES.forEach((category) => {
      let deviceCateogry = {
        key: assets.find(
          (item) => item.AssetCategory.AssetCategoryName === category.type
        )?.AssetCategory?.AssetCategoryId,
        icon: category?.icon,
        type: category?.type,
        itemCount: `${
          assets.filter(
            (item) => item.AssetCategory.AssetCategoryName === category.type
          ).length
        } device(s)`,
      };
      enrolledDevices.push(deviceCateogry);
    });
    setGroupBDeviceCategories(enrolledDevices);
  };

  const onDeviceCategorySelect = (category) => {
    if (!isAgreementSuspended) {
      setSelectedDeviceCategory({
        ...selectedDeviceCategory,
        ...category,
      });

      if (category?.key) onFileClaimClick(category);

      // displayRegDevices(category);
    }
  };

  const displayRegDevices = (category) => {
    const devices = assetList.filter(
      (item) => item.AssetCategory.AssetCategoryName === category.type
    );

    let registeredDevicesList = [];

    devices.forEach((asset) => {
      if (!isEmpty(asset.AssetCatalog)) {
        let registeredDevice = {
          key: asset.AssetCatalog.AssetCatalogId,
          icon: category.value,
          type: asset.AssetCatalog.AssetCatalogName,
          itemCount: `Added on : ${getFormmatedDate(
            asset?.CreatedDate,
            "dd MMM yyyy"
          )}`,
        };

        registeredDevicesList.push(registeredDevice);
      }
    });

    setRegisteredDevices(registeredDevicesList);
  };

  const onDeviceRegClick = () => {
    return completeCurrentStep(currentStep, {
      addDevice: true,
    });
  };

  const onFileClaimClick = (category) => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        selectedCategory: category?.type,
      },
    });

    return completeCurrentStep(currentStep, {
      fileClaim: true,
    });
  };

  const goToMyClaim = () => {
    if (!isAgreementSuspended)
      return completeCurrentStep(currentStep, {
        goToMyClaim: true,
      });
  };

  const showCampaignPopUp = () => {
    setOpenCampaignPopup(true);
  }

  let dashboardContainer = findById(metadata, "dashboardContainer");
  let noDeviceContainer = findById(metadata, "noDeviceContainer");
  let enrolledDevicesGroupA = findById(metadata, "enrolledDevices");
  let enrolledDevicesGroupB = findById(metadata, "enrolledDevicesB");
  let listDevices = findById(metadata, "registeredDevices");
  let plyInfoText = findById(metadata, "policyInfo");
  let enrolledDeviceText = findById(metadata, "enrollDevice");
  let dashBoardMenu = findById(metadata, "menu");
  let addDevice = findById(metadata, "addDevice");
  let txtNoDevice = findById(metadata, "txtNoDevice");
  let btnDeviceReg = findById(metadata, "btnDeviceReg");

  let policyNumber = findById(metadata, "policyNumber");
  let policyStartDate = findById(metadata, "policyStartDate");
  let policyStatus = findById(metadata, "policyStatus");
  let txtFileClaimInfo = findById(metadata, "txtFileClaimInfo");
  let txtPolicySuspended = findById(metadata, "txtPolicySuspended");
  let expandable = findById(metadata, "expandable");
  let btnChatWidget = findById(metadata, "btnChatWidget");
  let expandableClaimCoverage = findById(metadata, "expandableClaimCoverage");
  let expandableClaimHistory = findById(metadata, "expandableClaimHistory");
  let txtGroupA = findById(metadata, "txtGroupA");
  let txtGroupB = findById(metadata, "txtGroupB");
  let containerChatWidget = findById(metadata, "containerChatWidget");

  policyNumber.args = {
    ...policyNumber.args,
    label: homeplusscript.policyNumber_label,
    value: policyInfo?.policyNumber,
  };

  policyStartDate.args = {
    ...policyStartDate.args,
    label: homeplusscript.policyStartDate_label,
    value:
      policyInfo?.startDate &&
      getFormmatedDate(policyInfo?.startDate, "dd MMM yyyy"),
  };

  policyStatus.args = {
    ...policyStatus.args,
    label: homeplusscript.policyStatus_label,
    value: policyInfo?.status,
  };

  noDeviceContainer.args = {
    ...noDeviceContainer.args,
    other:
      groupADeviceCategories && groupADeviceCategories.length > 0
        ? "hidden"
        : "block",
    enabled: true,
    onClick: (e) => onDeviceRegClick(),
  };

  plyInfoText.args = {
    ...plyInfoText.args,
    content: homeplusscript.policyInfo,
  };

  enrolledDeviceText.args = {
    ...enrolledDeviceText.args,
    content: homeplusscript.enrollDevice,
  };

  dashBoardMenu.args = {
    ...dashBoardMenu.args,
    iconSubHeader: homeplusscript.menu_iconSubHeader,
    labelSubHeader: homeplusscript.menu_labelSubHeader,
    showMenuIcon: !isEmpty(findAgreementChat) ? true : false,
  };
  txtFileClaimInfo.args = {
    ...txtFileClaimInfo.args,
    displayClasses: groupADeviceCategories.length ? "block" : "hidden",
    content: homeplusscript.txtFileClaimInfo,
  };

  enrolledDevicesGroupA.args = {
    ...enrolledDevicesGroupA.args,
    options: groupADeviceCategories,
    value: selectedDeviceCategory,
    onListItemSelect: onDeviceCategorySelect,
    others: groupADeviceCategories.length ? "block" : "hidden",
  };

  enrolledDevicesGroupB.args = {
    ...enrolledDevicesGroupB.args,
    options: groupBDeviceCategories,
    value: selectedDeviceCategory,
    onListItemSelect: onDeviceCategorySelect,
    others: groupBDeviceCategories.length ? "block" : "hidden",
  };

  listDevices.args = {
    ...listDevices.args,
    options: registeredDevices,
    others: registeredDevices.length ? "block" : "hidden",
  };

  addDevice.args = {
    ...addDevice.args,
    label: homeplusscript.addDevice_label,
    displayClasses:
      groupADeviceCategories && groupADeviceCategories.length > 0
        ? "block"
        : "hidden",
    enabled: !isAgreementSuspended,
    onClick: (e) => onDeviceRegClick(),
  };

  txtNoDevice.args = {
    ...txtNoDevice.args,
    content: homeplusscript.txtNoDevice,
  };

  btnDeviceReg.args = {
    ...btnDeviceReg.args,
    label: homeplusscript.btnDeviceReg,
    enabled: !isAgreementSuspended,
    onClick: (e) => onDeviceRegClick(),
  };

  txtPolicySuspended.args = {
    ...txtPolicySuspended.args,
    content: isAgreementSuspended
      ? homeplusscript.txtPolicySuspended
      : openClaimStatus,
    displayClasses:
      isAgreementSuspended || openClaimStatus ? "block" : "hidden",
    onClick: (e) => goToMyClaim(),
  };

  expandable.args = {
    ...expandable.args,
    title: homeplusscript.labelAggregateClaimLimit,
    item: policyInfo,
    expanded: isDesktop ? true : false,
  };

  expandableClaimCoverage.args = {
    ...expandableClaimCoverage.args,
    title: homeplusscript.labelClaimsCoverage,
    expanded: isDesktop ? true : false,
  };

  expandableClaimHistory.args = {
    ...expandableClaimHistory.args,
    title: homeplusscript.labelClaimsHistorySummary,
    item: completedClaims,
    isLoading: isClaimHistoryLoading,
  };

  btnChatWidget.args = {
    ...btnChatWidget.args,
    findAgreementData: findAgreementChat,
    workflowState,
    refreshStateOnLogout,
    updateState,
  };

  txtGroupA.args = {
    ...txtGroupA.args,
    content: homeplusscript.labelGroupA,
    displayClasses:
      groupADeviceCategories && groupADeviceCategories.length > 0
        ? "block"
        : "hidden",
  };

  txtGroupB.args = {
    ...txtGroupB.args,
    content: homeplusscript.labelGroupB,
    displayClasses:
      groupADeviceCategories && groupADeviceCategories.length > 0
        ? "block"
        : "hidden",
  };

  containerChatWidget.args = {
    ...containerChatWidget.args,
    displayClasses: !isEmpty(findAgreementChat) ? "block" : "hidden",
  };

  const contentStyle = {
    padding: "15px",
    width: "400px",
    height: "300",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    borderRadius: "8px",
    borderColor: "",
    textColor: "",
  };

  return (
    <>
      <Popup
        contentStyle={contentStyle}
        open={openPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <CarrierContractType 
            setOpenPopup={setOpenPopup}
            showCampaignPopUp={(e)=> showCampaignPopUp()} />
        </div>
      </Popup>
      <Popup
        contentStyle={contentStyle}
        open={openCampaignPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <Campaign 
            setOpenCampaignPopup={setOpenCampaignPopup} 
            onDeviceRegClick={(e) => onDeviceRegClick()} />
        </div>
      </Popup>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
};

export default Dashboard;
