import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

export const TextArea = ({
  id,
  name,
  rows,
  cols,
  onChange,
  placeholder,
  value,
  Mandatory,
  label,
  enabled,
  marginClasses,
  other,
  maxlength,
  showLengthErrror,
  ...props
}) => {
  const [contentLength, setContentLength] = useState();
  return (
    <div className={`${marginClasses} ${other}`}>
      <label htmlFor={name} className="font-LatoRegular text-neutral #3D3D3B">
        {label}
        {Mandatory && <span className="text-red-600 ml-1">*</span>}
      </label>
      <textarea
        className="border border-neutralN8 flex flex-col p-2 outline-none rounded w-full mt-1"
        id={id}
        value={value}
        name={name}
        rows={rows}
        cols={cols}
        disabled={!enabled}
        onChange={(e) => {onChange(e.target.value); setContentLength(e.target.value.length);}}
        placeholder={placeholder}
        maxLength={maxlength}
      >
        {value}
      </textarea>
      {
        (showLengthErrror && contentLength > 300) && <div><span class='text-accentA5'>Faults or symptoms must be of 300 character</span></div>
      }
    </div>
  );
};

TextArea.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  Mandatory: PropTypes.bool,
  label: PropTypes.string,
  other: PropTypes.string,
  maxlength: PropTypes.string,
};

TextArea.defaultProps = {
  enabled: true,
  placeholder: "",
  Value: "",
  Mandatory: false,
  onChange: undefined,
  label: "Textarea",
  other: "",
  rows: "3",
  maxlength: "",
  showLengthErrror: false,
};
