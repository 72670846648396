import * as React from "react";

function ChatIconSvg(
  props
) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.46 91.45L17.11 75.91H6.72L2.5 17.54L71.69 31.77L68.88 75.9H36.13L18.46 91.44V91.45Z" fill="#1CD4B0"/>
        <path opacity="0.4" d="M75.54 26.77C75.52 27.07 75.27 27.31 74.96 27.31C74.68 27.31 74.45 27.09 74.45 26.81C74.45 26.79 74.45 26.78 74.45 26.76C74.47 26.46 74.72 26.22 75.03 26.22C75.31 26.22 75.54 26.44 75.54 26.72C75.54 26.74 75.54 26.75 75.54 26.77Z" fill="white"/>
        <path d="M63.07 15.65C60.87 18.17 59.6 25.9 61.23 29.7C62.86 33.5 43.94 37.31 59.55 43.48C75.16 49.66 87.65 42.49 83.13 36.1C78.61 29.71 83.62 19.05 78.39 16.37C73.66 13.93 67.49 10.6 63.08 15.65H63.07Z" fill="black"/>
        <path d="M58.4 46.76C57.57 49.58 56.7 52.4 55.79 55.22C54.88 58.04 53.93 60.82 52.93 63.62L52.55 64.67L52.36 65.2L52.3 65.36L52.2 65.6C52.14 65.75 52.05 65.92 51.97 66.08C51.58 66.8 50.99 67.39 50.27 67.8C49.62 68.15 48.9 68.34 48.16 68.36C47.13 68.36 46.1 68.16 45.14 67.77C44.36 67.45 43.6 67.08 42.87 66.65C40.23 65.03 37.81 63.07 35.67 60.83L37.41 58.78C37.99 59.15 38.62 59.55 39.22 59.93C39.82 60.31 40.46 60.67 41.07 61.03C42.27 61.73 43.51 62.36 44.78 62.93C45.36 63.19 45.96 63.4 46.57 63.56C46.99 63.7 47.44 63.75 47.88 63.71C47.99 63.71 47.97 63.65 47.88 63.68C47.79 63.71 47.69 63.82 47.68 63.81C47.67 63.8 47.68 63.81 47.68 63.79V63.77L47.71 63.67L47.86 63.15L48.17 62.1L50.63 53.64C51.47 50.82 52.33 48 53.21 45.18L58.39 46.78L58.4 46.76Z" fill="#FFC3BD"/>
        <path d="M37.29 58.69L32.61 56.96L34.47 61.9C34.47 61.9 37.77 62.38 39.05 60.64L38.01 59.24C37.83 58.99 37.58 58.8 37.29 58.68V58.69Z" fill="#FFC3BD"/>
        <path d="M28.48 58.4L30.26 62.45L34.48 61.9L32.62 56.95L28.48 58.4Z" fill="#FFC3BD"/>
        <path d="M54.99 39.47C52.85 40.01 51.37 48.82 51.37 48.82L57.27 52.85C57.27 52.85 62.91 43.97 61.28 41.62C59.55 39.15 58.12 38.68 55 39.48L54.99 39.47Z" fill="#1ED760"/>
        <path d="M54.99 39.47C52.85 40.01 51.37 48.82 51.37 48.82L57.27 52.85C57.27 52.85 62.91 43.97 61.28 41.62C59.55 39.15 58.12 38.68 55 39.48L54.99 39.47Z" fill="#685BC7"/>
        <path d="M54.79 42.67L59.37 43.73L59.81 48.21C59.03 49.79 58.18 51.33 57.25 52.83L53.67 50.41C53.83 48.34 54.21 44.08 54.8 42.67H54.79Z" fill="#250E62"/>
        <path d="M54.99 39.47C54.99 39.47 52.51 40.34 57.47 70.8H78.55C78.2 62.22 78.19 56.93 82.29 39.32C79.34 38.71 76.35 38.32 73.34 38.14C69.79 37.97 66.9 37.85 63.77 38.14C59.66 38.51 55 39.47 55 39.47H54.99Z" fill="#1ED760"/>
        <path d="M54.99 39.47C54.99 39.47 52.51 40.34 57.47 70.8H78.55C78.2 62.22 78.19 56.93 82.29 39.32C79.34 38.71 76.35 38.32 73.34 38.14C69.79 37.97 66.9 37.85 63.77 38.14C59.66 38.51 55 39.47 55 39.47H54.99Z" fill="#685BC7"/>
        <path d="M77.32 47.49L80.04 49.81C79.46 52.89 79.07 55.45 78.83 57.74C78.31 55.45 77.01 49.24 77.32 47.49Z" fill="#250E62"/>
        <path d="M72.38 27.36C71.78 30.44 71.17 36.08 73.33 38.14C73.33 38.14 71.32 42.75 65.98 42.75C60.64 42.75 63.75 38.14 63.75 38.14C67.18 37.33 67.1 34.77 66.5 32.38L72.37 27.36H72.38Z" fill="#FFC3BD"/>
        <path d="M72.65 36.99C72.14 38.02 70.51 40.36 65.94 41.2C65.81 41.22 65.68 41.2 65.57 41.13C64.72 40.53 64.18 39.27 64.56 37.81C64.63 37.54 64.48 37.27 64.21 37.2C64.1 37.17 63.99 37.18 63.89 37.22C63.54 37.37 63.2 37.54 62.88 37.75C62.81 37.8 62.75 37.86 62.71 37.94C62.38 38.57 61.02 41.64 65.11 43.54C65.18 43.57 65.24 43.58 65.32 43.59C67.74 43.64 71.96 43.16 74.63 38.36C74.73 38.18 74.71 37.96 74.59 37.8C74.23 37.41 73.81 37.07 73.35 36.81C73.12 36.66 72.82 36.73 72.67 36.96C72.67 36.97 72.65 36.99 72.64 37.01L72.65 36.99Z" fill="#1ED760"/>
        <path d="M73.77 49.66L68.33 49.5C68.11 49.5 67.93 49.32 67.92 49.1C67.92 49.08 67.92 49.07 67.92 49.05L67.97 48.38C68 48.15 68.2 47.97 68.44 47.98L73.88 48.14C74.1 48.14 74.28 48.32 74.29 48.54C74.29 48.56 74.29 48.57 74.29 48.59L74.24 49.26C74.21 49.49 74.01 49.67 73.77 49.66Z" fill="#1ED760"/>
        <path d="M69.99 29.41L66.51 32.38C66.66 32.94 66.76 33.51 66.81 34.08C68.12 33.89 69.92 32.45 70.06 31.08C70.14 30.53 70.11 29.96 69.99 29.42V29.41Z" fill="#ED847E"/>
        <path d="M76.44 21.53C75.47 26.59 75.17 28.75 72.25 31.04C67.87 34.48 61.86 32.18 61.31 26.93C60.81 22.21 62.48 14.73 67.75 13.34C71.5 12.34 75.35 14.58 76.34 18.33C76.62 19.38 76.65 20.48 76.43 21.54L76.44 21.53Z" fill="#FFC3BD"/>
        <path d="M73.56 17.79C72.93 20.96 72.78 25.16 75.69 25.16C78.6 25.16 80.87 20.22 78.36 16.35C75.85 12.48 73.93 15.92 73.56 17.79Z" fill="black"/>
        <path d="M78.58 18.18C78.58 21.63 74.24 19.92 72.32 18.44C70.4 16.96 70.31 17.12 66.75 17.11C63.19 17.1 62.48 16.86 61.43 15.16C60.38 13.46 60.48 12.34 62.84 12.7C65.2 13.06 63.22 10.64 64.98 9.30001C66.74 7.96001 68.83 8.3 71.24 11.36C73.65 14.42 78.58 12.21 78.58 18.18Z" fill="black"/>
        <path d="M77.2986 26.0373C77.7554 24.4792 77.3094 22.9767 76.3025 22.6815C75.2955 22.3863 74.1089 23.4101 73.6521 24.9682C73.1953 26.5264 73.6413 28.0288 74.6482 28.324C75.6552 28.6192 76.8418 27.5954 77.2986 26.0373Z" fill="white"/>
        <path d="M69.25 29.93C68.32 29.93 67.39 29.74 66.53 29.37C66.45 29.34 66.42 29.25 66.45 29.18C66.48 29.1 66.57 29.07 66.65 29.1C68.28 29.8 70.13 29.81 71.78 29.13C73.38 28.48 74.64 27.21 75.29 25.61C76.5 22.15 77.73 17.84 76.3 15.14C75.72 14.08 74.7 13.32 73.52 13.07C73.44 13.05 73.39 12.97 73.41 12.88C73.43 12.8 73.51 12.75 73.6 12.77C74.86 13.04 75.95 13.85 76.57 14.98C78.05 17.76 76.82 22.16 75.57 25.69C74.89 27.37 73.56 28.7 71.88 29.39C71.05 29.74 70.15 29.92 69.25 29.93Z" fill="white"/>
        <path d="M77.16 26.05C76.8 27.26 75.98 28.11 75.32 27.92C74.66 27.73 74.42 26.58 74.78 25.36C75.14 24.14 75.96 23.3 76.62 23.49C77.28 23.68 77.52 24.83 77.16 26.05Z" fill="#1ED760"/>
        <path d="M64.7806 28.7915L64.7616 28.8897C64.7083 29.1663 64.8892 29.4338 65.1658 29.4871L66.7466 29.7921C67.0232 29.8455 67.2906 29.6645 67.344 29.388L67.3629 29.2898C67.4163 29.0132 67.2354 28.7457 66.9588 28.6924L65.3779 28.3874C65.1014 28.334 64.8339 28.515 64.7806 28.7915Z" fill="white"/>
        <path d="M64.89 21.95C64.89 21.95 63.96 23.65 63.07 24.41C63.55 24.99 64.52 24.88 64.52 24.88L64.89 21.95Z" fill="#ED847E"/>
        <path d="M65.78 27.05H65.82C66.73 27.12 67.64 26.85 68.36 26.28C68.39 26.25 68.39 26.2 68.36 26.17C68.33 26.14 68.28 26.14 68.25 26.17C67.56 26.71 66.7 26.97 65.83 26.91C65.79 26.91 65.75 26.93 65.74 26.97C65.74 26.97 65.75 27.04 65.78 27.06V27.05Z" fill="black"/>
        <path d="M70.38 19.96C70.38 19.96 70.48 19.99 70.53 19.96C70.6 19.91 70.62 19.82 70.58 19.74C70.19 19.09 69.51 18.66 68.75 18.6C68.66 18.6 68.59 18.67 68.59 18.76C68.59 18.85 68.66 18.91 68.74 18.92C69.39 18.98 69.98 19.35 70.31 19.92C70.33 19.94 70.35 19.96 70.38 19.97V19.96Z" fill="#3D3D3B"/>
        <path d="M68.08 22.02C68.01 22.42 67.73 22.73 67.46 22.69C67.19 22.65 67.03 22.29 67.1 21.85C67.17 21.41 67.45 21.14 67.72 21.18C67.99 21.22 68.15 21.6 68.08 22.02Z" fill="black"/>
        <path d="M63.38 21.23C63.31 21.63 63.03 21.94 62.76 21.9C62.49 21.86 62.33 21.48 62.4 21.06C62.47 20.64 62.75 20.34 63.02 20.39C63.29 20.44 63.45 20.81 63.38 21.23Z" fill="black"/>
        <path d="M63.14 20.43L62.19 19.97C62.19 19.97 62.57 20.84 63.14 20.43Z" fill="black"/>
        <path d="M67.83 21.23L66.88 20.78C66.88 20.78 67.26 21.65 67.83 21.23Z" fill="black"/>
        <path d="M61.79 18.42C61.79 18.42 61.9 18.45 61.95 18.42C62.45 18 63.13 17.85 63.77 18.02C63.85 18.05 63.94 18.01 63.97 17.92C64 17.83 63.96 17.75 63.87 17.72C63.14 17.51 62.35 17.67 61.76 18.15C61.69 18.2 61.68 18.29 61.72 18.36C61.72 18.36 61.75 18.41 61.78 18.42H61.79Z" fill="#3D3D3B"/>
        <path d="M56.67 75.93C56.67 75.93 56.61 73.05 57.48 70.8H78.56C78.56 70.8 80.46 73.86 80.95 75.93H56.68H56.67Z" fill="#685BC7"/>
        <path d="M78.87 69.57L79.04 71.45C79.12 71.6 78.94 71.75 78.7 71.75H57.36C57.17 71.75 57.01 71.66 57 71.54L56.81 69.65C56.81 69.52 56.96 69.41 57.17 69.41H78.51C78.65 69.39 78.79 69.46 78.88 69.57H78.87Z" fill="#1ED760"/>
        <path d="M78.87 69.57L79.04 71.45C79.12 71.6 78.94 71.75 78.7 71.75H57.36C57.17 71.75 57.01 71.66 57 71.54L56.81 69.65C56.81 69.52 56.96 69.41 57.17 69.41H78.51C78.65 69.39 78.79 69.46 78.88 69.57H78.87Z" fill="#FAFAFA"/>
        <path d="M76 71.95H76.57C76.69 71.95 76.77 71.89 76.76 71.82L76.49 69.37C76.49 69.3 76.38 69.24 76.27 69.24H75.69C75.57 69.24 75.49 69.3 75.5 69.37L75.77 71.82C75.79 71.89 75.89 71.95 76 71.95Z" fill="#1ED760"/>
        <path d="M58.97 71.95H59.54C59.65 71.95 59.74 71.89 59.73 71.82L59.46 69.37C59.46 69.3 59.36 69.24 59.24 69.24H58.67C58.56 69.24 58.47 69.3 58.48 69.37L58.75 71.82C58.75 71.89 58.86 71.95 58.97 71.95Z" fill="#1ED760"/>
        <path d="M67.48 71.95H68.04C68.15 71.95 68.24 71.89 68.23 71.82L67.96 69.37C67.96 69.3 67.85 69.24 67.74 69.24H67.17C67.06 69.24 66.97 69.3 66.98 69.37L67.25 71.82C67.27 71.89 67.37 71.95 67.49 71.95H67.48Z" fill="#1ED760"/>
        <path d="M84.09 45.29C85.02 48.3 85.91 51.32 86.76 54.36L88.04 58.89L88.68 61.14L88.84 61.7L88.92 61.98L88.94 62.04L88.88 61.94C88.78 61.79 88.65 61.67 88.51 61.56C88.19 61.33 87.82 61.2 87.43 61.18C87.14 61.17 86.84 61.22 86.58 61.35C86.29 61.51 86.32 61.54 86.34 61.52C86.41 61.46 86.47 61.39 86.53 61.32C86.72 61.08 86.89 60.83 87.05 60.58C87.4 60 87.75 59.35 88.08 58.68C88.76 57.34 89.38 55.93 90 54.5C90.62 53.07 91.21 51.63 91.81 50.18C92.41 48.73 92.99 47.26 93.59 45.82L96.16 46.58C95.49 49.72 94.71 52.8 93.74 55.88C93.26 57.42 92.74 58.95 92.13 60.49C91.82 61.26 91.48 62.04 91.07 62.84C90.85 63.27 90.6 63.69 90.33 64.1C90.16 64.35 89.97 64.59 89.77 64.82C89.44 65.19 89.05 65.49 88.6 65.71C88.16 65.93 87.67 66.03 87.17 66.01C86.59 65.98 86.03 65.77 85.56 65.42C85.26 65.2 85.01 64.92 84.8 64.61C84.67 64.41 84.55 64.2 84.46 63.99C84.42 63.9 84.38 63.81 84.36 63.73L84.28 63.53L84.18 63.24L83.97 62.67C83.7 61.92 83.42 61.16 83.16 60.41C82.64 58.9 82.13 57.4 81.65 55.89C80.66 52.87 79.74 49.84 78.89 46.79L84.09 45.28V45.29Z" fill="#FFC3BD"/>
        <path d="M82.28 39.32C84.39 39.96 87.63 48.25 87.63 48.25L79.44 54.11C79.44 54.11 75.76 47.02 76.64 44.29C77.57 41.45 79.88 38.58 82.28 39.32Z" fill="#1ED760"/>
        <path d="M82.28 39.32C84.39 39.96 87.63 48.25 87.63 48.25L79.44 54.11C79.44 54.11 75.76 47.02 76.64 44.29C77.57 41.45 79.88 38.58 82.28 39.32Z" fill="#685BC7"/>
        <path d="M93.69 46.92L92.57 41.97L97.24 44.43C97.24 44.43 97.3 47.76 95.41 48.81L93.69 46.91V46.92Z" fill="#FFC3BD"/>
        <path d="M94.52 38.04L97.5 40.15L97.23 44.43L92.56 41.97L94.52 38.04Z" fill="#FFC3BD"/>
        <path d="M18.39 38.26H16.86L12.86 32.99C12.77 32.87 12.42 32.4 11.82 31.59H11.79C11.81 31.83 11.82 32.31 11.82 33.02V38.26H10.14V29.03H11.81L15.68 34.09C16.18 34.77 16.52 35.25 16.7 35.52H16.73C16.71 34.87 16.7 34.35 16.7 33.97V29.04H18.39V38.27V38.26Z" fill="white"/>
        <path d="M25.94 35.4H21.7C21.72 35.91 21.9 36.32 22.24 36.63C22.58 36.93 23.03 37.09 23.58 37.09C24.13 37.09 24.71 36.94 25.25 36.64L25.75 37.79C25.09 38.2 24.31 38.41 23.41 38.41C22.28 38.41 21.43 38.08 20.87 37.42C20.3 36.76 20.02 35.94 20.02 34.96C20.02 33.98 20.29 33.11 20.82 32.45C21.35 31.8 22.08 31.47 23 31.47C23.92 31.47 24.65 31.79 25.16 32.42C25.68 33.05 25.94 33.92 25.94 35.02V35.41V35.4ZM21.68 34.24H24.24C24.24 33.82 24.12 33.47 23.9 33.18C23.69 32.89 23.37 32.75 22.96 32.75C22.55 32.75 22.26 32.89 22.03 33.18C21.8 33.47 21.68 33.82 21.67 34.24H21.68Z" fill="white"/>
        <path d="M32.84 35.4H28.6C28.62 35.91 28.8 36.32 29.14 36.63C29.48 36.93 29.93 37.09 30.48 37.09C31.03 37.09 31.61 36.94 32.15 36.64L32.65 37.79C31.99 38.2 31.21 38.41 30.31 38.41C29.18 38.41 28.33 38.08 27.77 37.42C27.2 36.76 26.92 35.94 26.92 34.96C26.92 33.98 27.19 33.11 27.72 32.45C28.25 31.8 28.98 31.47 29.9 31.47C30.82 31.47 31.55 31.79 32.06 32.42C32.58 33.05 32.84 33.92 32.84 35.02V35.41V35.4ZM28.58 34.24H31.14C31.14 33.82 31.02 33.47 30.8 33.18C30.59 32.89 30.27 32.75 29.86 32.75C29.45 32.75 29.16 32.89 28.93 33.18C28.7 33.47 28.58 33.82 28.57 34.24H28.58Z" fill="white"/>
        <path d="M37.57 38.4C36.46 38.4 35.55 38.1 34.85 37.5C34.15 36.9 33.8 36.07 33.8 35.01C33.8 33.95 34.14 33.11 34.82 32.45C35.5 31.79 36.37 31.46 37.43 31.46C37.77 31.46 38.06 31.49 38.33 31.54V28.33H40.01V37.89C39.34 38.22 38.53 38.38 37.57 38.38V38.4ZM37.54 37.01C37.87 37.01 38.13 36.97 38.32 36.9V32.94C38.02 32.88 37.75 32.84 37.52 32.84C36.92 32.84 36.44 33.03 36.06 33.41C35.69 33.79 35.5 34.32 35.5 34.98C35.5 35.64 35.68 36.11 36.05 36.47C36.42 36.83 36.91 37.01 37.53 37.01H37.54Z" fill="white"/>
        <path d="M9.88 53.43V43.52H11.55V47.54C12.26 46.94 12.99 46.64 13.75 46.64C14.36 46.64 14.86 46.82 15.26 47.19C15.66 47.56 15.86 48.18 15.86 49.05V53.43H14.19V49.56C14.19 49.09 14.12 48.73 13.97 48.48C13.82 48.23 13.56 48.09 13.16 48.09C12.64 48.09 12.1 48.32 11.54 48.79V53.43H9.87H9.88Z" fill="white"/>
        <path d="M23.05 50.57H18.81C18.83 51.08 19.01 51.49 19.35 51.8C19.69 52.1 20.14 52.26 20.69 52.26C21.24 52.26 21.82 52.11 22.36 51.81L22.86 52.96C22.2 53.37 21.42 53.58 20.52 53.58C19.39 53.58 18.54 53.25 17.98 52.59C17.41 51.93 17.13 51.11 17.13 50.13C17.13 49.15 17.4 48.28 17.93 47.62C18.46 46.97 19.19 46.64 20.11 46.64C21.03 46.64 21.76 46.96 22.27 47.59C22.79 48.22 23.05 49.09 23.05 50.19V50.58V50.57ZM18.79 49.41H21.35C21.35 48.99 21.23 48.64 21.01 48.35C20.8 48.06 20.48 47.92 20.07 47.92C19.66 47.92 19.37 48.06 19.14 48.35C18.91 48.64 18.79 48.99 18.78 49.41H18.79Z" fill="white"/>
        <path d="M24.38 51.93V43.51H26.05V51.48C26.05 51.88 26.21 52.08 26.54 52.08C26.68 52.08 26.82 52.05 26.96 52L27.28 53.29C26.91 53.45 26.5 53.53 26.05 53.53C24.94 53.53 24.38 53 24.38 51.93Z" fill="white"/>
        <path d="M28.29 56.75V47.06C29.1 46.78 29.94 46.64 30.8 46.64C31.97 46.64 32.86 46.95 33.49 47.56C34.11 48.18 34.43 49.02 34.43 50.09C34.43 51.16 34.11 51.95 33.48 52.6C32.85 53.25 32.01 53.57 30.99 53.57C30.64 53.57 30.3 53.52 29.97 53.42V56.75H28.3H28.29ZM29.96 52.02C30.26 52.13 30.56 52.19 30.86 52.19C31.47 52.19 31.93 52.01 32.25 51.64C32.57 51.27 32.73 50.76 32.73 50.11C32.73 49.42 32.55 48.9 32.2 48.55C31.85 48.2 31.37 48.02 30.75 48.02C30.5 48.02 30.24 48.06 29.97 48.13V52.02H29.96Z" fill="white"/>
        <path d="M36.85 50.74C36.56 50.39 36.42 50 36.42 49.56C36.42 49.25 36.51 48.97 36.69 48.71C36.87 48.45 37.08 48.23 37.33 48.04C37.58 47.85 37.83 47.68 38.08 47.51C38.33 47.34 38.55 47.15 38.72 46.96C38.89 46.77 38.99 46.56 38.99 46.34C38.99 46.08 38.9 45.88 38.73 45.74C38.56 45.6 38.32 45.53 38.02 45.53C37.42 45.53 36.83 45.79 36.24 46.31L35.48 45.23C36.35 44.48 37.29 44.11 38.29 44.11C39.1 44.11 39.73 44.3 40.17 44.68C40.61 45.06 40.84 45.55 40.84 46.15C40.84 46.51 40.74 46.83 40.55 47.14C40.36 47.44 40.13 47.7 39.86 47.9C39.59 48.11 39.32 48.3 39.05 48.48C38.78 48.66 38.55 48.85 38.36 49.05C38.17 49.25 38.07 49.45 38.07 49.65C38.07 49.85 38.13 50.04 38.27 50.21L36.86 50.73L36.85 50.74ZM38.3 53.28C38.12 53.47 37.9 53.56 37.63 53.56C37.36 53.56 37.15 53.47 36.97 53.28C36.79 53.09 36.7 52.86 36.7 52.59C36.7 52.32 36.79 52.09 36.97 51.91C37.15 51.73 37.37 51.64 37.63 51.64C37.89 51.64 38.11 51.73 38.3 51.91C38.49 52.09 38.57 52.32 38.57 52.59C38.57 52.86 38.48 53.09 38.3 53.28Z" fill="white"/>
        </svg>
  );
}

export default ChatIconSvg;