import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../button/button";
import { Text } from "../text/text";

export const OptionPage = ({
  itemTitleClasses,
  mainDivClasses,
  borderClasses,
  itemTextClasses,
  itemSubTextClasses,
  itemClasses,
  colorClasses,
  dimensionClasses,
  option,
  onClick,
  isDesktop,
  ...props
}) => {


  return (
    <div className={mainDivClasses}>
      <div className={`${borderClasses}`}>
        <div className={`${itemTitleClasses}`}>
          <span>{option.FulfillmentOption}</span>
        </div>
        <div className={`${itemClasses}`}>
          <div className={`${itemTextClasses}`}>{option.labelDevice}</div>
          <div className="mb-2">{option.AssetCatalogName}</div>
          <div className={`${itemTextClasses}`}>{option.labelModel}</div>
          <div className="">{option.AssetModel}</div>
          <div className="flex flex-row mt-2">
            <div className={`${itemTextClasses}`}>{option.labelFee}</div>
            <div className={`${itemSubTextClasses}`}>
              {`$ ${option.OverageAmount}`}
            </div>
          </div>
        </div>
        <Button
          colorClasses={colorClasses}
          onClick={() => {
            console.log("button clicked");
            onClick(option);
          }}
          enabled={true}
          dimensionClasses={dimensionClasses}
          label={option.labelBtn}
        />
      </div>
    </div>
  );
};

OptionPage.propTypes = {
  itemTextClasses: PropTypes.string,
  itemSubTextClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  itemTitleClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  option: PropTypes.object,
  onClick: PropTypes.func,
};

OptionPage.defaultProps = {
  mainDivClasses: "flex flex-col items-center mt-2",
  borderClasses:
    "my-5 w-72 lg:w-auto bg-white border rounded shadow-sm border-primary",
  itemTitleClasses: "flex justify-center mt-2 md:mt-4 text-xl font-LatoBold lg:mx-4",
  itemTextClasses: "text-neutralN4 text-sm",
  itemSubTextClasses: "font-bold text-primary ml-2",
  itemClasses: "flex flex-col mt-8 mx-5",
  colorClasses:
    "bg-primary focus:bg-secondaryS5 hover:bg-secondaryS5 active:bg-additional text-neutral active:shadow-lg transition duration-150 ease-in-out",
  dimensionClasses: "w-full mt-2 rounded-none",
  onClick: undefined,
  option: {},
};
