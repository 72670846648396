import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { findById } from "../enrollment-workflow/helper";
import { Stack } from "../stack/stack";

export const ClaimLimit = ({ policyInfo, ...props }) => {
  const metadata = props.metadata;

  let txtUsedBalance = findById(metadata, "txtUsedBalance");
  let txtTotal = findById(metadata, "txtTotal");
  let txtRemaining = findById(metadata, "txtRemaining");
  let txtTotalLimitInfo = findById(metadata, "labelTotalLimitInfo");

  txtUsedBalance.args = {
    ...txtUsedBalance.args,
    content: policyInfo?.claimed,
  };

  txtTotal.args = {
    ...txtTotal.args,
    content: policyInfo?.totalLimit,
  };

  txtRemaining.args = {
    ...txtRemaining.args,
    content: policyInfo?.remainingLimit,
  };

  txtTotalLimitInfo.args = {
    ...txtTotalLimitInfo.args,
    content: policyInfo?.totalLimitInfo,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
};
