import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";

export const EnrolledDevice = ({
  enrolledDevice,
  onDeviceSelect,
  itemClasses,
  itemBorderClasses,
  itemIconClasses,
  itemSelectedClasses,
  value,
  itemSelectedTextColorClasses,
  itemTextColorClasses,
  itemSubTextSelectedColorClasses,
  itemSubTextColorClasses,
  ...props
}) => {
  return (
    <div
      className={`${
        value?.type === enrolledDevice.type
          ? itemSelectedClasses
          : itemBorderClasses
      }`}
      onClick={() => onDeviceSelect(enrolledDevice)}
    >
      <div className={itemClasses}>
        <Icon
          className={itemIconClasses}
          icon={enrolledDevice.icon || "TV"}
        ></Icon>
        <Text
          colorClasses={`${
            value?.type === enrolledDevice.type
              ? itemSelectedTextColorClasses
              : itemTextColorClasses
          }`}
          content={enrolledDevice.type}
        ></Text>
      </div>
      {enrolledDevice.itemCount && (
        <div>
          <Text
            colorClasses={`${
              value?.type === enrolledDevice.type
                ? itemSubTextSelectedColorClasses
                : itemSubTextColorClasses
            }`}
            size={"sm"}
            content={enrolledDevice.itemCount}
          ></Text>
        </div>
      )}
    </div>
  );
};

EnrolledDevice.propTypes = {
  enrolledDevice: PropTypes.object,
  onClick: PropTypes.func,
  itemClasses: PropTypes.string,
  itemBorderClasses: PropTypes.string,
  itemIconClasses: PropTypes.string,
  itemSelectedClasses: PropTypes.string,
};

EnrolledDevice.defaultProps = {
  onClick: undefined,
  enrolledDevice: { icon: "TV", type: "TV", itemCount: "2" },
  itemClasses: "flex flex-row items-start",
  itemBorderClasses: "cursor-pointer border border-neutralN8 rounded-md p-3",
  itemIconClasses: "mr-2 mb-2",
  itemSelectedClasses:
    "cursor-pointer border border-primary rounded-md p-3 bg-light",
  itemSelectedTextColorClasses: "text-link",
  itemTextColorClasses: "text-neutral",
  itemSubTextSelectedColorClasses: "text-link",
  itemSubTextColorClasses: "text-neutralN4",
};
