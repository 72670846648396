import { Amplify } from 'aws-amplify'

import { Config } from './config'

function initAmplifyConfig() {
 
  Amplify.configure({
    // aws_appsync_graphqlEndpoint: chatConf.AppSync.EndPoint,
    // aws_appsync_region: chatConf.AppSync.Region,
    Analytics: {
      disabled: true, // OPTIONAL - disable Analytics if true
    },
    Auth: {
      //oauth: oauth,
      //identityPoolId: config.cognito.identityPoolId,
      region: Config.cognito.region,
      userPoolId: Config.cognito.userPoolId,
      userPoolWebClientId: Config.cognito.userPoolWebClientId,
      storage: sessionStorage
    },
    // ApiKey: chatConf.Twilio.ApiGatewayKey,
  })
}

export default initAmplifyConfig
