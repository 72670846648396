/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    width="21"
     height="20" 
     viewBox="0 0 21 20" 
     fill="none" 
     xmlns="http://www.w3.org/2000/svg"
  >
<path d="M12.3688 0.625C13.0188 0.7375 13.4688 1.15 13.925 1.5875C14.9188 2.54375 15.925 3.4875 16.9313 4.43125C17.4188 4.8875 17.65 5.4375 17.65 6.10625C17.65 9.8375 17.65 13.5625 17.65 17.2938C17.65 18.3 17.0438 19.0875 16.1 19.3188C15.9313 19.3625 15.75 19.375 15.575 19.375C12.2 19.375 8.82502 19.375 5.45002 19.375C4.23752 19.375 3.36877 18.5 3.36877 17.2812C3.36877 12.4375 3.37502 7.59375 3.36877 2.74375C3.36877 1.5375 4.23752 0.75 5.08752 0.65C5.10627 0.65 5.11877 0.63125 5.13127 0.625H12.3813H12.3688ZM11.9313 1.725H11.7188C9.63752 1.725 7.55627 1.725 5.47502 1.725C4.83127 1.725 4.46252 2.09375 4.46252 2.73125C4.46252 7.575 4.46252 12.425 4.46252 17.2687C4.46252 17.9062 4.83752 18.275 5.47502 18.275C8.82502 18.275 12.175 18.275 15.5313 18.275C16.1813 18.275 16.55 17.9062 16.55 17.25C16.55 13.6063 16.55 9.9625 16.55 6.31875V6.1125H16.325C15.3313 6.1125 14.3375 6.1125 13.3375 6.1125C12.4563 6.1125 11.9313 5.5875 11.9313 4.7C11.9313 3.78125 11.9313 2.85625 11.9313 1.9375V1.725ZM15.95 5.01875L13.0313 2.25625C13.0313 3.11875 13.0313 3.925 13.0313 4.7375C13.0313 4.96875 13.0813 5.01875 13.3125 5.01875C14.1313 5.01875 14.9438 5.01875 15.7625 5.01875H15.95Z" fill="#1ED760"/>
<path d="M11.9313 1.72501V1.93751C11.9313 2.85626 11.9313 3.78126 11.9313 4.70001C11.9313 5.58751 12.4563 6.11251 13.3375 6.11251C14.3313 6.11251 15.325 6.11251 16.325 6.11251H16.55V6.31876C16.55 9.96251 16.55 13.6063 16.55 17.25C16.55 17.9 16.1813 18.275 15.5313 18.275C12.1813 18.275 8.83127 18.275 5.47502 18.275C4.83127 18.275 4.46252 17.9063 4.46252 17.2688C4.46252 12.425 4.46252 7.57501 4.46252 2.73126C4.46252 2.09376 4.83752 1.72501 5.47502 1.72501C7.55627 1.72501 9.63752 1.72501 11.7188 1.72501H11.9313ZM10.3938 9.05001C10.5938 9.05001 10.7938 9.05001 11 9.05001C12.125 9.05001 13.2438 9.05001 14.3688 9.05001C14.625 9.05001 14.8188 8.95001 14.925 8.71251C15.0938 8.33751 14.8125 7.95001 14.3688 7.95001C12.1313 7.95001 9.89377 7.95001 7.65627 7.95001C7.23752 7.95001 6.81252 7.95001 6.39377 7.95001C6.14377 7.95001 5.95627 8.06251 5.86252 8.29376C5.70627 8.66251 5.98127 9.05001 6.41252 9.05001C7.74377 9.05001 9.06877 9.05001 10.4 9.05001H10.3938ZM10.3938 11.9813H10.925C12.0688 11.9813 13.2188 11.9813 14.3625 11.9813C14.6188 11.9813 14.8125 11.8813 14.9188 11.6438C15.0875 11.2688 14.8063 10.8813 14.3625 10.8813C12.1625 10.8813 9.95627 10.8813 7.75627 10.8813C7.30002 10.8813 6.84377 10.8813 6.38127 10.8813C6.13127 10.8813 5.94377 10.9938 5.84377 11.225C5.68752 11.5938 5.96252 11.9813 6.39377 11.9813C7.72502 11.9813 9.05002 11.9813 10.3813 11.9813H10.3938ZM7.68752 14.9125C8.10627 14.9125 8.53127 14.9125 8.95002 14.9125C9.31877 14.9125 9.57502 14.6875 9.57502 14.3688C9.57502 14.05 9.31877 13.8188 8.95627 13.8188C8.11252 13.8188 7.26877 13.8188 6.43127 13.8188C6.06252 13.8188 5.81252 14.0438 5.81252 14.375C5.81252 14.6938 6.06252 14.9188 6.41877 14.9188C6.83752 14.9188 7.26252 14.9188 7.68127 14.9188L7.68752 14.9125Z" fill="white"/>
<path d="M15.9438 5.01876H15.7563C14.9375 5.01876 14.125 5.01876 13.3063 5.01876C13.075 5.01876 13.025 4.96876 13.025 4.73751C13.025 3.92501 13.025 3.11876 13.025 2.25626L15.9438 5.01876Z" fill="#E8FBEF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3938 9.04998H10.3875H6.40627C5.97502 9.04998 5.70002 8.66873 5.85627 8.29373C5.95627 8.06248 6.13752 7.94998 6.38752 7.94998H7.65002H14.3625C14.8 7.94998 15.0875 8.33748 14.9188 8.71248C14.8125 8.94998 14.6188 9.04998 14.3625 9.04998H10.9938H10.3938ZM10.3938 11.9749H6.40627C5.97502 11.9749 5.70002 11.5937 5.85627 11.2187C5.95627 10.9874 6.13752 10.8749 6.39377 10.8749H7.76877H14.375C14.8125 10.8749 15.1 11.2624 14.9313 11.6374C14.825 11.8749 14.6313 11.9749 14.375 11.9749H10.9375H10.4063H10.3938ZM6.42498 14.9063H7.68748L7.69373 14.9001H8.95623C9.32498 14.9001 9.58123 14.6813 9.58123 14.3563C9.58748 14.0376 9.33123 13.8063 8.96248 13.8063H6.43748C6.06873 13.8063 5.81873 14.0376 5.81873 14.3626C5.81873 14.6813 6.06873 14.9063 6.42498 14.9063Z" fill="#1ED760"/>
</svg>
);

export default icon;
