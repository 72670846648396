import * as React from "react";
import { hasGetUserMedia } from "./video-utils";

export default class Webcam extends React.Component {
  static defaultProps = {
    audio: false,
    forceScreenshotSourceSize: false,
    imageSmoothing: true,
    mirrored: true,
    onUserMedia: () => undefined,
    onUserMediaError: () => undefined,
    screenshotFormat: "image/webp",
    screenshotQuality: 0.92,
  }

  canvas = null

  ctx = null

  requestUserMediaId = 0

  unmounted = false

  constructor(props) {
    super(props)
    this.state = {
      hasUserMedia: false
    }
  }

  componentDidMount() {
    const { state, props } = this
    this.unmounted = false

    console.log('componentDidMount');

    if (!hasGetUserMedia()) {
      props.onUserMediaError("getUserMedia not supported")
      console.log('componentDidMount : getUserMedia not supported');

      return
    }

    if (!state.hasUserMedia) {
      this.requestUserMedia()
    }

    if (props.children && typeof props.children != "function") {
      console.warn("children must be a function")
      console.log('componentDidMount : children must be a function');
    }
  }

  componentDidUpdate(nextProps) {
    const { props } = this

    console.log('componentDidUpdate');

    if (!hasGetUserMedia()) {
      props.onUserMediaError("getUserMedia not supported")
      console.log('getUserMedia not supported');

      return
    }

    const audioConstraintsChanged =
      JSON.stringify(nextProps.audioConstraints) !==
      JSON.stringify(props.audioConstraints)
    const videoConstraintsChanged =
      JSON.stringify(nextProps.videoConstraints) !==
      JSON.stringify(props.videoConstraints)
    const minScreenshotWidthChanged =
      nextProps.minScreenshotWidth !== props.minScreenshotWidth
    const minScreenshotHeightChanged =
      nextProps.minScreenshotHeight !== props.minScreenshotHeight
    if (
      videoConstraintsChanged ||
      minScreenshotWidthChanged ||
      minScreenshotHeightChanged
    ) {
      this.canvas = null
      this.ctx = null
    }
    if (audioConstraintsChanged || videoConstraintsChanged) {
      console.log('stopAndCleanup from componentDidUpdate');
      this.stopAndCleanup()
      this.requestUserMedia()
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');

    console.log('stopAndCleanup from componentWillUnmount');

    this.unmounted = true
    this.stopAndCleanup()
  }

  static stopMediaStream(stream) {
    console.log('stopMediaStream');
    console.log(' stream at stopMediaStream', stream);
    if (stream) {
      console.log("inside stream");
      console.log("inside stream stream.getVideoTracks ?? stream.getVideoTracks ??", stream.getVideoTracks, stream.getAudioTracks);
      if (stream.getVideoTracks && stream.getAudioTracks) {
        console.log("stream has audio video tracks");
        stream.getVideoTracks().map(track => {
          console.log("video track", track);
          stream.removeTrack(track)
          track.stop()
        })
        stream.getAudioTracks().map(track => {
          console.log("audio track", track);
          stream.removeTrack(track)
          track.stop()
        })
      } else {
        console.log("calling stream.stop function");
        stream.stop()
      }
    }
  }

  stopAndCleanup() {
    const { state } = this

    console.log('stopAndCleanup');
    console.log('hasUserMedia ', state.hasUserMedia);
    
    if (state.hasUserMedia) {
      Webcam.stopMediaStream(this.stream)

      if (state.src) {
        window.URL.revokeObjectURL(state.src)
      }
    }
  }

  getScreenshot(screenshotDimensions) {
    const { state, props } = this

    if (!state.hasUserMedia) return null

    const canvas = this.getCanvas(screenshotDimensions)
    return (
      canvas &&
      canvas.toDataURL(props.screenshotFormat, props.screenshotQuality)
    )
  }

  getCanvas(screenshotDimensions) {
    const { state, props } = this

    if (!this.video) {
      console.log('getCanvas this.video', !this.video);
      return null
    }

    if (!state.hasUserMedia || !this.video.videoHeight) { 
      console.log('getCanvas !state.hasUserMedia || !this.video.videoHeight', !state.hasUserMedia + "  |  " + !this.video.videoHeight);

      return null 
    }

    if (!this.ctx) {
      let canvasWidth = this.video.videoWidth
      let canvasHeight = this.video.videoHeight
      if (!this.props.forceScreenshotSourceSize) {
        const aspectRatio = canvasWidth / canvasHeight

        canvasWidth = props.minScreenshotWidth || this.video.clientWidth
        canvasHeight = canvasWidth / aspectRatio

        if (
          props.minScreenshotHeight &&
          canvasHeight < props.minScreenshotHeight
        ) {
          canvasHeight = props.minScreenshotHeight
          canvasWidth = canvasHeight * aspectRatio
        }
      }

      this.canvas = document.createElement("canvas")
      this.canvas.width = screenshotDimensions?.width || canvasWidth
      this.canvas.height = screenshotDimensions?.height || canvasHeight
      this.ctx = this.canvas.getContext("2d")
    }

    const { ctx, canvas } = this

    if (ctx && canvas) {
      // mirror the screenshot
      if (props.mirrored) {
        ctx.translate(canvas.width, 0)
        ctx.scale(-1, 1)
      }

      ctx.imageSmoothingEnabled = props.imageSmoothing
      ctx.drawImage(
        this.video,
        0,
        0,
        screenshotDimensions?.width || canvas.width,
        screenshotDimensions?.height || canvas.height
      )

      // invert mirroring
      if (props.mirrored) {
        ctx.scale(-1, 1)
        ctx.translate(-canvas.width, 0)
      }
    }

    console.log('getCanvas return canvas', canvas);

    return canvas
  }

  requestUserMedia() {
    const { props } = this

    const sourceSelected = (audioConstraints, videoConstraints) => {
      // const constraints = {
      //   video: typeof videoConstraints !== "undefined" ? videoConstraints : true
      // }

      // if (props.audio) {
      //   constraints.audio =
      //     typeof audioConstraints !== "undefined" ? audioConstraints : true
      // }

      this.requestUserMediaId++
      const myRequestUserMediaId = this.requestUserMediaId

      navigator.mediaDevices
        // .getUserMedia(constraints)
        .getUserMedia({
          video: true,
          audio: true
        })
        .then(stream => {

          console.log("streams are", stream);

          if (
            this.unmounted ||
            myRequestUserMediaId !== this.requestUserMediaId
          ) {
            Webcam.stopMediaStream(stream)
          } else {
            this.handleUserMedia(null, stream)
          }
        })
        .catch(e => {
          console.log("error before handle user media", e);
          this.handleUserMedia(e)
        })
    }

    // if ("mediaDevices" in navigator) {
      // sourceSelected(props.audioConstraints, props.videoConstraints)
      sourceSelected();
    // } else {
    //   const optionalSource = id => ({
    //     optional: [{ sourceId: id }]
    //   })

    //   const constraintToSourceId = constraint => {
    //     const { deviceId } = constraint

    //     if (typeof deviceId === "string") {
    //       return deviceId
    //     }

    //     if (Array.isArray(deviceId) && deviceId.length > 0) {
    //       return deviceId[0]
    //     }

    //     if (typeof deviceId === "object" && deviceId.ideal) {
    //       return deviceId.ideal
    //     }

    //     return null
    //   }

      // // @ts-ignore: deprecated api
      // MediaStreamTrack.getSources(sources => {
      //   let audioSource = null
      //   let videoSource = null

      //   sources.forEach(source => {
      //     if (source.kind === "audio") {
      //       audioSource = source.id
      //     } else if (source.kind === "video") {
      //       videoSource = source.id
      //     }
      //   })

      //   const audioSourceId = constraintToSourceId(props.audioConstraints)
      //   if (audioSourceId) {
      //     audioSource = audioSourceId
      //   }

      //   const videoSourceId = constraintToSourceId(props.videoConstraints)
      //   if (videoSourceId) {
      //     videoSource = videoSourceId
      //   }

        // sourceSelected(optionalSource(audioSource), optionalSource(videoSource))
      // })
    // }
  }

  handleUserMedia(err, stream) {
    const { props } = this

    console.log('handleUserMedia');

    if (err || !stream) {
      this.setState({ hasUserMedia: false })
      props.onUserMediaError(err)

      console.log('handleUserMedia onUserMediaError', err);

      return
    }

    this.stream = stream

    console.log("has Video", this.video);

    try {
      if (this.video) {
        this.video.srcObject = stream
      }
      console.log("Video SRC Object", this.video.srcObject);
      console.log('setting handleUserMedia True');
      this.setState({ hasUserMedia: true })
    } catch (error) {
      console.log('handleUserMedia catch', error);
      this.setState({
        hasUserMedia: true,
        src: window.URL.createObjectURL(stream)
      })
    }

    console.log('handleUserMedia before onUserMedia', stream);

    props.onUserMedia(stream)
  }

  render() {
    const { state, props } = this

    const {
      audio,
      forceScreenshotSourceSize,
      onUserMedia,
      onUserMediaError,
      screenshotFormat,
      screenshotQuality,
      minScreenshotWidth,
      minScreenshotHeight,
      audioConstraints,
      videoConstraints,
      imageSmoothing,
      mirrored,
      style = {},
      children,
      ...rest
    } = props

    const videoStyle = mirrored
      ? { ...style, transform: `${style.transform || ""} scaleX(-1)` }
      : style

    const childrenProps = {
      getScreenshot: this.getScreenshot.bind(this)
    }

    return (
      <>
        <video
          autoPlay
          src={state.src}
          muted={!audio}
          playsInline
          ref={ref => {
            this.video = ref
          }}
          style={videoStyle}
          {...rest}
        />
        {/* {children && children(childrenProps)} */}
      </>
    )
  }
}
