/* eslint-disable jsx-a11y/anchor-is-valid */
import FaqDevice from "./assets/FaqDevice";
import FaqFileClaim from "./assets/FaqFileClaim";

export const FAQ = {
  DP: [
    {
      label: "File A Claim",
      icon: <FaqFileClaim style={{ marginRight: '13px' }} />,
      items: [
        {
          question: "How do I file a claim?",
          answer: (onClick) => {
            return (
              <>
                You can file your claim via the <a onClick={(e) => { onClick(e, 'dashboard') }}>online portal</a> in 3 easy
                steps:
                <div>
                  <div>
                    <p>1. Select the Covered Device. </p>
                    <ul>
                      <li>
                        If the device is not yet registered, you can do so before
                        you start your claim
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p>2. Schedule for pickup of your Covered Device </p>
                  </div>
                  <div>
                    <p>3. Provide payment details for deductible </p>
                    <ul>
                      <li>
                        Do note that you can only file a claim 31 days from your
                        Start Date.
                      </li>
                    </ul>
                  </div>

                </div>
              </>
            )
          },
        },
        {
          question: "How long does it take for my claim to be processed? ",
          answer: (onClick) => {
            return (
              <>
                It will take 1 business day to process your claim admissibility
                and upon confirmation of the acceptance of the resolution, it may
                take up to 10 business days to process your claim. You will
                receive email and/or SMS notification on your claim status.
              </>
            )
          },
        },
        {
          question: "Is there a limit on the number of claims I can file?",
          answer: (onClick) => {
            return (
              <>
                There is no limit on the number of claims you can file. However,
                an Aggregate Claim Limit of $5,000 applies per rolling 12 months
                depending on the category of Covered Device and Per Claim Limit is
                $600 or $1,500 is also applicable.
              </>
            )
          },
        },
        {
          question: "How do I track the status of my claim?",
          answer: (onClick) => {
            return (
              <>
                You may login to the  <a onClick={(e) => { onClick(e, 'my-claim-for-repair') }}>claim portal</a> to track the status of your
                claim. You will also receive email and/or SMS updates during the
                claim process. If you did not receive any email update, please
                check the spam folder in your email account.
              </>
            )
          },
        },
        {
          question: "I have filed my claim, so what do I do next?",
          answer: (onClick) => {
            return (
              <>

                You will receive updates on your claim status within the next
                business day and if your device will be picked-up as scheduled.
                Depending on the type of Covered Device, you may be asked to
                <div>
                  <div>1. Disable any personal lock and security feature</div>
                  <div>
                    2. Delete all data from the device and perform a factory reset
                    It is important that you complete the steps above prior to the
                    arrival of the courier. Our courier will not be able to wait
                    for you to do the above.
                  </div>
                </div>
              </>
            )
          },
        },
        {
          question: "Do I need to be home for pick-up and delivery of device?",
          answer: (onClick) => {
            return (
              <>
                You will need to provide verification of your identity in person
                during the device pickup or delivery.
              </>
            )
          },
        },
        {
          question: "Can I reschedule my pick-up or return service?",
          answer: (onClick) => {
            return (
              <>
                You can reschedule your pick-up or return timing if it is made one
                business day before the original schedule. Simply click <a onClick={(e) => { onClick(e, 'my-claim-for-repair') }}>here</a> to do
                so.
              </>
            )
          },
        },
      ],
    },
    {
      label: "Device Eligibility",
      icon: <FaqDevice style={{ marginRight: '5px' }} />,
      items: [
        {
          question:
            "Do I have to register the Eligible Device at the start of coverage?",
          answer: (onClick) => {
            return (
              <>
                It is not mandatory to do so. If you have recently purchased your
                device, you can register the device as a Covered Device and upload
                the purchase receipt for the device to enjoy an even smoother,
                hassle-free claim experience.
              </>
            )
          },
        },
        {
          question:
            "Do I need to provide proof of purchase or ownership for the Eligible Devices?",
          answer: (onClick) => {
            return (
              <>
                You may be asked to provide supporting document on the purchase
                price and/or ownership. We encourage you to keep the receipts or
                register all of your Eligible Device as a Covered Devices with the
                receipts for an even better claim experience.
              </>
            )
          },
        },
        {
          question: "How do I know if my device is eligible for coverage?",
          answer: (onClick) => {
            return (
              <>
                To be eligible, your device must fulfil the following criteria:
                <ul>
                  <li>Internet or wireless connectivity. </li>
                  <li>
                    Device brands must be launched or purchased in Singapore
                  </li>
                  <li>Must have an unaltered serial number </li>
                  <li>
                    Devices must not be within Specific Excluded Devices;
                    <ul>
                      <li>(i) End-of-Life products,</li>
                      <li>(ii) customized </li>
                      <li>(iii) built-into furniture </li>
                      <li>(iv) peripherals or</li>
                      <li>
                        (v) accessories unless otherwise stated. Please refer to
                        Policy wording for more details.
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            )
          },
        },
        {
          question:
            "I just purchased a new device. Can I add that into my device coverage?",
          answer: (onClick) => {
            return (
              <>
                Yes, there is no limit to the number of Eligible Devices which can
                be covered. You can register your device as Covered Device anytime
                via the <a onClick={(e) => { onClick(e, 'select-device') }}>portal</a> as long it is an Eligible Device.
              </>
            )
          },
        },
      ],
    },
  ],
  SOLUTO: [
    {
      question: "What is Security Advisor and how do I access it?",
      answer: (onClick) => {
        return (
          <>
            <p>
              Security Advisor helps you take preventive actions to detect data
              breaches and keep your personal information safe with Security Scan
              and Security Assessment. You can get personalised support from our
              Home Specialist anytime from 9am to 6pm daily to assist you with
              Security Advisor related queries.
            </p>
            <p>
              It will be activated upon the commencement of your Coverage. Simply
              use the registered email address to login to Security Advisor.
            </p>
          </>
        )
      },
    },
    {
      question: "How do I register other family members for Security Advisor?",
      answer: (onClick) => {
        return (
          <>
            You can register Security Advisor for up to 4 family members. Just
            login into your Security Advisor account to register their email
            addresses.
          </>
        )
      },
    },
    {
      question: "What is Tech Support?",
      answer: (onClick) => {
        return (
          <>
            Premier Tech Support gives you instant access anytime to Home
            Specialists to assist you on device setup and troubleshoot device
            issues you may have.
          </>
        )
      },
    },
  ],
};
