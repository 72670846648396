import React from "react";
import PropTypes from "prop-types";
import { Button } from "../button/button";
import { Tooltip } from "../tooltip/tooltip";
import { TextArea } from "../textarea/textarea";

export const TriageQuestion = ({
  itemTitleClasses,
  borderClasses,
  colorClasses,
  dimensionClasses,
  triageQuestion,
  onClick,
  paddingClasses,
  showTooltip,
  mainDivClasses,
  label,
  value,
  itemSelectedClasses,
  itemColorClasses,
  setOtherAnswer,
  itemClass,
  ...props
}) => {
  return (
    <>
      <div className={`${mainDivClasses}`}>
        <div
          className={`${
            triageQuestion?.QuestionType !== "Symptoms_Others"
              ? borderClasses
              : "w-full"
          }`}
        >
          <div className={`${itemTitleClasses}`}>
            {showTooltip ? (
              <Tooltip showLabel={true} labelText={triageQuestion.Question} />
            ) : (
              <label>{triageQuestion.Question}</label>
            )}
          </div>
          <div className={itemClass}>
            {triageQuestion?.answers?.length >= 0 &&
              triageQuestion?.answers.map((item) => {
                return (
                  <div key={item.value}>
                    <Button
                      enabled={true}
                      label={item.label}
                      onClick={(e) => {
                        onClick(triageQuestion, item, false);
                      }}
                      colorClasses={
                        item.isClick ? itemSelectedClasses : itemColorClasses
                      }
                      dimensionClasses={dimensionClasses}
                      paddingClasses={paddingClasses}
                    />
                  </div>
                );
              })}
            {triageQuestion?.QuestionType === "Symptoms_Others" && (
              <div className="w-full">
                <TextArea
                  label={""}
                  onChange={setOtherAnswer}
                  fontClasses="text-sm"
                  showLengthErrror={true}
                  maxlength={50}
                ></TextArea>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

TriageQuestion.propTypes = {
  colorClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  itemTitleClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  triageQues: PropTypes.object,
  onClick: PropTypes.func,
  itemSelectedClasses: PropTypes.string,
  setOtherAnswer: PropTypes.func,
};

TriageQuestion.defaultProps = {
  mainDivClasses: "flex justify-center my-2 mx-1",
  borderClasses:
    "w-full h-auto bg-white border rounded shadow-sm border-neutralN8 text-center",
  itemTitleClasses: "flex justify-center m-4",
  dimensionClasses: "rounded mx-2.5",
  paddingClasses: "px-7 py-2",
  onClick: undefined,
  triageQuestion: {},
  itemClass: "flex justify-center my-4",
  itemSelectedClasses: "bg-primary text-link border border-primary border-solid",
  itemColorClasses: "text-neutral border border-primary border-solid",
  setOtherAnswer: undefined,
};
