import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceRAM() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceRAM']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceRAM"];

  const [otherRAM, setOtherRAM] = useState("");
  const [deviceRAMList, setDeviceRAMList] = useState([]);
  const [selectedRAM, setSelectedRAM] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let deviceRAMs = findById(metadata, "deviceRAMs");
  let inputOtherRAM = findById(metadata, "inputOtherRAM");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDisplayRAMInfo = findById(metadata, "txtDisplayRAMInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;

  const onContinueClick = (RAM) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedRAM: RAM?.value || otherRAM?.trim(),
        isOtherRAMSelected: isEmpty(RAM)
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectStorageCapacity: true
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceRAMs(
        regDeviceDetails.selectedBrandDetails.RAM
      );
    // now get distinct list of all brand
    else showDeviceRAMs(regDeviceDetails?.distinctRAMList);
  }, []);

  const showDeviceRAMs = (deviceRAMs= []) => {
    let RAMList = [];
    deviceRAMs.forEach((RAM) => {
      if (!isEmpty(RAM)) {
        let deviceRAM = {
          value: RAM,
          label: RAM,
          // using below properties for custom sorting
          unit: RAM.match(/[a-zA-Z]+|[0-9]+/g)[1],
          size: RAM.match(/[a-zA-Z]+|[0-9]+/g)[0]
        };
        RAMList.push(deviceRAM);
      }
    });

    const sortedRAMList = RAMList.sort((a, b) =>
      a.size - b.size
    );

    // add others at last
    sortedRAMList.push({ value: "Others", label: "Others" });

    setDeviceRAMList(sortedRAMList);
  };

  const onRAMSelect = (RAM) => {
    hideShowInputOtherRAM(RAM);
    setSelectedRAM({ ...selectedRAM, ...RAM });

    if (RAM.value !== "Others") onContinueClick(RAM);
    else setDisplayProp("block")
  };

  const hideShowInputOtherRAM = (RAM) => {
    // clear input value
    setOtherRAM("");
    // inputOtherRAM.args = {
    //   ...inputOtherRAM.args,
    //   other: RAM.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: RAM.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: RAM.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  deviceRAMs.args = {
    ...deviceRAMs.args,
    deviceItems: deviceRAMList,
    value: selectedRAM?.value,
    onClick: onRAMSelect,
  };

  inputOtherRAM.args = {
    ...inputOtherRAM.args,
    label: homeplusscript.labelOtherRAM,
    placeHolder: homeplusscript.placeholderOtherRAM,
    value: otherRAM,
    other: displayProp,
    onChange: setOtherRAM,
  };

  const getTitleInfo = () => {
    if(isDesktop) return homeplusscript.deviceDekstopRAMInfo;
   else
     return homeplusscript.deviceLaptopRAMInfo;
  }

  txtDisplayRAMInfo.args = {
    ...txtDisplayRAMInfo.args,
    content: getTitleInfo()
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherRAM?.trim(),
    displayClasses: selectedRAM?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceRAM;
