import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import "../../style.css"

function MobileNumber(props) {
  const {
    value,
    label,
    placeHolder,
    marginClasses,
    fontClasses,
    displayClasses,
    disabled,
    onChange,
    inputDivClasses,
    subDiv,
    dimensionClasses,
    paddingClasses,
    showErrror,
    errorMessage,
    containerClass,
    phoneInput,
  } = props;

  let inpStyle = {
    width: "100%",
    backgroundColor: "#c7c7c5",
    color: "#3D3D3B",
    borderColor: "#c7c7c5",
  };
  const [contentLength, setContentLength] = useState();
  const [blur, setBlur] = useState(false);

  return (
    <div
      className={`${inputDivClasses} ${marginClasses}  ${dimensionClasses}  ${paddingClasses}`}
    >
      <div className={`${subDiv}`}>
        <div>
          <label className={`${fontClasses}`} dangerouslySetInnerHTML={{ __html: label }}/>
        </div>
      </div>
      <div className={phoneInput}>
        <PhoneInput
          country={"sg"}
          onlyCountries={["sg"]}
          disabled={disabled}
          disableDropdown={true}
          // disableCountryCode={true}
          countryCodeEditable={false}
          value={value}
          containerClass={containerClass}
          inputClasses={displayClasses}
          dropdownClass="w-[50px]"
          dropdownStyle={{ width: "500px" }}
          inputStyle={disabled ? inpStyle : { width: "100%" }}
          onChange={(value, country, e, formattedValue) => {
            onChange(value, country, e, formattedValue);
            setContentLength(value.length);
          }}
          onBlur={() => setBlur(true)}
          onFocus={() => setBlur(false)}
        />
        {blur && showErrror && contentLength < 10 && contentLength !== 2 && (
          <div>
            <span class="text-accentA5 text-sm">{errorMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
}

MobileNumber.propTypes = {
  /**
   * paddingClasses: Classes to manage padding of the control
   */
  paddingClasses: PropTypes.string,
  /**
   * marginClasses: Classes to manage margin of the control
   */
  marginClasses: PropTypes.string,
  /**
   * displayClasses: Classes to manage display of the control
   */
};

MobileNumber.defaultProps = {
  paddingClasses: "",
  marginClasses: "",
  fontClasses: "text-base",
  inputDivClasses: "flex flex-col",
  subDiv: "flex flex-row justify-between",
  phoneInput: "mt-1 md:mt-0 mb-2",
};

export default MobileNumber;