import React from "react";

const icon = ({ onClick, isSelected, className }) => (
  <svg
    onClick={onClick}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 8H3" stroke="#1ED760" stroke-miterlimit="10" />
  </svg>
);

export default icon;
