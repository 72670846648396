import React, { useState } from "react";
import { Image } from "../image/image";
import PropTypes from "prop-types";

import IconAsurionMobilePlus from "../../icons/ic_starhub";
import otpMailIcon from "../../icons/ic_otp_mail";
import Menu from "../../icons/ic_menu";
import Union from "../../icons/ic_union";
import Subheader from "../../icons/ic_subheader";
import TV from "../../icons/ic_tv";
import Tablet from "../../icons/ic_tablet";
import Printer from "../../icons/ic_printer";
import Speaker from "../../icons/ic_speaker";
import Router from "../../icons/ic_router";
import Laptop from "../../icons/ic_laptop";
import UnionImg from "../../icons/ic_union_img";
import Claim from "../../icons/ic_claim";
import DeviceReg from "../../icons/ic_device_reg";
import FileClaim from "../../icons/ic_file_claim";
import Webcam from "../../icons/ic_webcam";
import Profile from "../../icons/ic_profile";
import Spinner from "../../icons/ic_spinner";
import ChatBig from "../../icons/ic_chat_big";
import AddDevice from "../../icons/ic_add_device";
import Desktop from "../../icons/ic_desktop";
import Mouse from "../../icons/ic_mouse";
import Monitor from "../../icons/ic_monitor";
import Keyboard from "../../icons/ic_keyborad";
import Transaction from "../../icons/ic_transaction";
import Notification from "../../icons/ic_notification";
import Chat from "../../icons/ic_chat";
import SecurityAdvisor from "../../icons/ic_security_advisor";
import FAQ from "../../icons/ic_faq";
import Logout from "../../icons/ic_logout";
import Collapse from "../../icons/ic_collapse";
import Expand from "../../icons/ic_expand";
import MyClaim from "../../icons/ic_my_claim";
import Calender from "../../icons/ic_calendar";
import Email from "../../icons/ic_email";
import IconExpand from "../../icons/ic_expand";
import IconCollapse from "../../icons/ic_collapse";
import Truste from "../../icons/ic_truste";
import Amex from "../../icons/ic_amex";
import MasterCard from "../../icons/ic_mastercard";
import Visa from "../../icons/ic_visa";
import Delivery from "../../icons/ic_delivery";
import Pickup from "../../icons/ic_pickup";
import Reset from "../../icons/ic_reset";
import Documents from "../../icons/ic_documents";
import InfoPurple from "../../icons/ic_infopurple";
import Processing from "../../icons/ic_processing";
import ContactUs from "../../icons/ic_contactus";
import ContactUsPage from "../../icons/ic_contactus2";
import IconClose from "../../icons/ic_close"
import Home from "../../icons/ic_home";
import Plus from "../../icons/ic_plus";
import Minus from "../../icons/ic_minus";
import Folder from "../../icons/ic_folder";
import File from "../../icons/ic_file";
import Sim from "../../icons/ic_sim";
import MobileHeaderText from "../../icons/ic_mobile_smartSupportHome";
import VideoDec from "../../icons/ic_video_dec";
import LoginImg from "../../icons/ic_login_img";
import LandingImgMobile from "../../icons/ic_landingPageMobile";

const ICONMAP = {
  mobileplus: IconAsurionMobilePlus,
  otpMail: otpMailIcon,
  Menu: Menu,
  Union: Union,
  Subheader: Subheader,
  TV: TV,
  Tablet: Tablet,
  Printer: Printer,
  Soundbar: Speaker,
  Router: Router,
  Laptop: Laptop,
  UnionImg: UnionImg,
  Claim: Claim,
  DeviceReg: DeviceReg,
  FileClaim: FileClaim,
  Webcam: Webcam,
  Profile: Profile,
  Spinner: Spinner,
  ChatBig: ChatBig,
  Transaction: Transaction,
  Notification: Notification,
  Chat: Chat,
  SecurityAdvisor: SecurityAdvisor,
  FAQ: FAQ,
  Logout: Logout,
  AddDevice: AddDevice,
  Mouse: Mouse,
  Monitor: Monitor,
  Desktop: Desktop,
  Keyboard: Keyboard,
  Collapse: Collapse,
  Expand: Expand,
  MyClaim: MyClaim,
  Calender: Calender,
  Email: Email,
  IconExpand: IconExpand,
  IconCollapse: IconCollapse,
  Truste: Truste,
  Visa: Visa,
  Amex: Amex,
  MasterCard: MasterCard,
  Delivery: Delivery,
  Pickup: Pickup,
  Reset: Reset,
  Documents: Documents,
  InfoPurple:InfoPurple,
  Processing: Processing,
  ContactUs: ContactUs,
  ContactUsPage:ContactUsPage,
  Home: Home,
  IconClose: IconClose,
  Plus: Plus,
  Minus: Minus,
  Folder: Folder,
  File: File,
  Sim: Sim,
  MobileHeaderText: MobileHeaderText,
  VideoDec: VideoDec,
  LoginImg: LoginImg,
  LandingImgMobile: LandingImgMobile,
};

export const Icon = ({
  icon,
  type,
  url,
  altIcon,
  property,
  initialValue,
  className,
  marginClasses,
  onClick,
  fill,
  onChange = () => {},
  displayClasses
}) => {
  const [value, setValue] = useState(initialValue ?? true);
  if (type === "hyperlink") {
    return (
      <a
        onClick={() => window.open(url, "_blank")}
        className="text-purple-500 cursor-pointer"
      >
        <Image image={ICONMAP[icon]} className={className} />
      </a>
    );
  }
  if (type === "toggle-button") {
    return (
      <div
        onClick={() => {
          setValue((v) => !v);
          onChange({ [property]: !value });
        }}
      >
        {" "}
        <Image
          image={value ? ICONMAP[icon] : ICONMAP[altIcon]}
          className={`${className} cursor-pointer`} fill={fill}
        />
      </div>
    );
  }
  return (
    <Image image={ICONMAP[icon]} className={`${className} ${marginClasses} ${displayClasses}`} onClick={onClick} fill={fill} />
  );
};

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(ICONMAP)),
  type: PropTypes.oneOf(["hyperlink", "button", "toggle-button"]),
  url: PropTypes.string,
  altIcon: PropTypes.oneOf(Object.keys(ICONMAP)),
};

export default Icon;
