import React from 'react'
import PropTypes from 'prop-types'

export const Button = ({
  enabled,
  label,
  onClick,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  style,
  displayClasses,
  id,
  ...props
}) => {
  return (
    <button
      id={id}
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      type='button'
      style={style}
      disabled={!enabled}
      className={`focus:outline-none ${fontClasses} ${paddingClasses} ${marginClasses} ${dimensionClasses} ${borderClasses} ${colorClasses} ${displayClasses}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  /**
   * enable: Indicates if button is enabled or disabled
   */
  enabled: PropTypes.bool,
  /**
   * label: Text to be displayed on button
   */
  label: PropTypes.string,
  /**
   * onClick: On Click event handler for button
   */
  onClick: PropTypes.func,
  /**
   * fontClasses: Classes to manage fonts of the control
   */
  fontClasses: PropTypes.string,
  /**
   * dimensionClasses: Classes to manage dimensions of the control
   */
  dimensionClasses: PropTypes.string,
  /**
   * colorClasses: Classes to manage foreground and background colors of the control
   */
  colorClasses: PropTypes.string,
  /**
   * borderClasses: Classes to manage border of the control
   */
  borderClasses: PropTypes.string,
  /**
   * paddingClasses: Classes to manage padding of the control
   */
  paddingClasses: PropTypes.string,
  /**
   * marginClasses: Classes to manage margin of the control
   */
  marginClasses: PropTypes.string
}

Button.defaultProps = {
  enabled: false,
  label: 'Button Label',
  onClick: undefined,
  fontClasses: 'text-base',
  dimensionClasses: '',
  colorClasses:
    'bg-primary disabled:bg-neutralN9 focus:bg-secondaryS5 hover:bg-secondaryS5 active:bg-additional text-neutral',
  borderClasses: 'rounded-md',
  paddingClasses: 'p-1 py-2 px-3',
  marginClasses: '',
  displayClasses: ''
}
