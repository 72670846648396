import { useState, useContext, useEffect } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getFormmatedDate, getMetadata, isEmpty } from "../helper";
import ActionTypes from "../../../ActionTypes";
import {
  callUpdateRepairRequestApi,
  callGetShippingMethodsApi,
  callUpdateShippingApi,
  callProcessOveragePaymentApi,
  callProcessPaymentApi,
} from "../api-helper";
import {
  FULFILMENT_OPTION,
  FULFILMENT_OPTION_OVERAGE,
} from "../../../utils/constants";
import logger from "../../../logger.service";
import { reschedulePickUpDeliveryOnFeeWaiver } from "../reschedule-pickup-delivery-logic";

function ScheduleDelivery() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ScheduleDelivery']
  const homeplusscript = workflowState['config']?.scripts["ScheduleDelivery"];

  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState("");
  const [dates, setDates] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);

  let txtTitle = findById(metadata, "txtTitle");
  let inputDeliveryAdd = findById(metadata, "inputDeliveryAdd");
  let btnGoBack = findById(metadata, "btnGoBack");
  let btnNext = findById(metadata, "btnNext");
  let dateSelection = findById(metadata, "deliveryDate");
  let timeSlotSelection = findById(metadata, "deliveryTimeSlot");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];
  const fullName = agreement?.Customers?.Customer?.[0]?.FullName;
  const nationalId = agreement?.IdentificationNumber.substr(
    agreement?.IdentificationNumber.length - 4
  );
  const address1 = agreement?.Address?.Address1;
  const address2 = agreement?.Address?.Address2;
  const address3 = agreement?.Address?.Address3;
  const city = agreement?.Address.City;
  const postalCode = agreement?.Address.PostalCode;
  const addressjoin = { address1, address2, city, postalCode };
  const fulladdress = Object.values(addressjoin).join(", ");

  const [addressDetails, setAddress] = useState(fulladdress);
  const isBraintree = workflowState["config"]?.isBraintree
  const isPayPal = isBraintree?.method?.paypal;
  const isGpay = isBraintree?.method?.gpay;

  const RepairRequestResponse = fileClaimDetails?.RepairRequestResponse;
  const ServiceOrderResponse = fileClaimDetails?.ServiceOrderResponse;
  const ChargeOrderResponse = fileClaimDetails?.ChargeOrderResponse;
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const ShippingOrderResponse = fileClaimDetails?.ShippingOrderResponse;
  const replacementOption = fileClaimDetails?.selectedReplacementOption;

  const ServiceOrderId = ServiceOrderResponse?.ServiceOrderId;
  const isRepair =
    fileClaimDetails?.selectedDevice?.FulfillmentMethodType ===
    FULFILMENT_OPTION.REPAIR;
  const userAddress = agreement?.Address;

  const isTVRepairWithOverage = fileClaimDetails?.isTVRepairWithOverage;

  const paymentMethod = workflowState["BraintreeDetails"]?.paymentMethod

  useEffect(() => {
    callGetShippingMethods();
  }, []);

  useEffect(() => {
    if (fileClaimDetails?.ShippingMethodResponse && !isEmpty(deliveryDate))
      loadTimeSlots(fileClaimDetails?.ShippingMethodResponse, deliveryDate);
  }, [deliveryDate]);

  const loadDates = (ShippingMethodResponse) => {
    const dateList = ShippingMethodResponse?.map(({ deliveryDate }) => {
      return { value: deliveryDate, label: deliveryDate };
    });

    setDates(dateList);
    setDeliveryDate(dateList[0].value);
    loadTimeSlots(ShippingMethodResponse, dateList[0].value);
  };
  const onDateSelect = (date) => {
    setDeliveryDate(date?.value);
  };

  const loadTimeSlots = (dateList, date) => {
    const timeList = dateList?.filter((item) => item.deliveryDate === date);

    const timeSlotes = timeList?.[0]?.deliverySlot.map((item, index) => {
      const slots = { value: item, label: item };
      return slots;
    });

    setTimeSlots(timeSlotes);
    setDeliveryTimeSlot(timeSlotes?.[0].value);
  };
  const onTimeSlotSelect = (timeSlot) => {
    setDeliveryTimeSlot(timeSlot?.value);
  };

  //-------------------------API Calls-----------------------------------//
  const callGetShippingMethods = () => {
    showLoader(true);

    let LookupShippingMethodsParameters = {
      LookupShippingMethodsParameters: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ReceiverAddress: userAddress,
        // Mode: isTVRepairWithOverage ? "pickup" : "delivery",
        Mode: "delivery",
      },
    };
    callGetShippingMethodsApi(
      LookupShippingMethodsParameters,
      onApiSuccess,
      onApiFailure
    );
  };

  const callUpdateShipping = () => {
    showLoader(true);
    const UpdateShippingOrderParameters = {
      UpdateShippingOrderParameters: {
        EntityUpdateOptions: {
          ReturnEntity: true,
        },
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        InboundShippingOrder: isRepair
          ? {
              ShippingMethodType: "Same Day",
              CarrierNumber: "SGINTCAR",
              SignatureRequired: true,
              RequestedShipmentDate: deliveryDate,
              ExpectedDeliveryDate: deliveryDate,
              DeliveryWindow: deliveryTimeSlot,
              NationalId: nationalId,
              Remark: "",
              AlternateContactNumber: "",
              ReceiverName: fullName,
              ReceiverAddress: {
                LocationType: "Residential",
                BusinessName: fullName,
                Standardized: true,
                IsOverridden: false,
                StandardizedSourceName: "QAS",
                BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
                IsScrubbed: true,
                ...userAddress,
              },
            }
          : undefined, // for replacement we are not passing this field
        ShippingOrder: {
          ShippingMethodType: "Same Day",
          CarrierNumber: "SGINTCAR",
          SignatureRequired: true,
          RequestedShipmentDate: isRepair
            ? ShippingOrderResponse?.ShippingOrder?.ShipmentDate
            : deliveryDate, // for repair we are passing exiting data from get claim api
          ExpectedDeliveryDate: isRepair
            ? ShippingOrderResponse?.ShippingOrder?.ExpectedDeliveryDate
            : deliveryDate, // for repair we are passing exiting data from get claim api
          DeliveryWindow: isRepair
            ? ShippingOrderResponse?.ShippingOrder?.DeliveryWindow
            : deliveryTimeSlot, // for repair we are passing exiting data from get claim api
          NationalId: nationalId,
          Remark: "",
          AlternateContactNumber: "",
          ReceiverName: fullName,
          ReceiverAddress: {
            LocationType: "Residential",
            BusinessName: fullName,
            Standardized: true,
            IsOverridden: false,
            StandardizedSourceName: "QAS",
            BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
            IsScrubbed: true,
            ...userAddress,
          },
        },
      },
    };

    callUpdateShippingApi(
      UpdateShippingOrderParameters,
      ServiceOrderId,
      onApiSuccess,
      onApiFailure
    );
  };

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        deliveryDate,
        deliveryTimeSlot,
      },
    }, ["FileClaimDetails"]);

    if (fileClaimDetails?.RepairRequestResponse?.RepairStatus === "DELIVERY FAILED")
      if(!fileClaimDetails?.ServiceRequestResponse?.ServiceRequest?.IsDeliveryWaive)
        // get payment as no waiver 
        if(isBraintree) {
          return completeCurrentStep(currentStep, {
            braintree: true,
          });
        } else {
          return completeCurrentStep(currentStep, {
            GoBackCreditCard: true,
          });
        }
      else {
        // call charge order as COD with 0 fee and update shipping order api
        reschedulePickUpDeliveryOnFeeWaiver(
          fileClaimDetails,
          undefined,
          workflowState,
          showLoader,
          updateState,
          completeCurrentStep,
          refreshStateOnLogout,
          currentStep
        );
      }
    else
      callUpdateShipping();
  };

  const callUpdateRepairRequest = () => {
    showLoader(true);
    const UpdateRepairRequestParameter = {
      UpdateRepairRequestParameter: {
        RepairRequestId: RepairRequestResponse?.RepairRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderId,
        RepairRequestType: "HomePlus",
        RepairStatus: "DELIVERY SCHEDULE REQUESTED",
        UpdatedBy: agreement?.ContactPoints?.ContactPoint[1]?.EmailAddress,
        UpdateAction: "HOMEPLUSNEWUPDATEACTION",
        AspDetails: {
          StoreRepEmailId:
            agreement?.ContactPoints?.ContactPoint[1]?.EmailAddress,
        },
      },
    };
    console.log(
      "UpdateRepairRequestParameter : ",
      UpdateRepairRequestParameter
    );
    callUpdateRepairRequestApi(
      UpdateRepairRequestParameter,
      onApiSuccess,
      onApiFailure
    );
  };

  const callProcessPayment = () => {
    showLoader(true);
    const ProcessPaymentParameters = {
      ProcessPaymentParameters: {
        ChargeOrderId: ChargeOrderResponse?.ChargeOrderId,
        ServiceOrderId: ServiceOrderId,
        ApplicationId: workflowState["config"]?.ApplicationId,
        ClientId: workflowState["config"]?.ClientId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderNumber:
          ServiceOrderResponse?.ServiceOrderNumber?.toString(),
        AuthorizeAdditionalCharges: false,
        ValidateAddress: false,
        ValidateCardSecurityCode: false,
        IpAddressForFraud: workflowState["config"]?.SourceIpAddress || "",
        FulfillmentOption: "PUR",
        PaymentInformation: {
          PaymentMethodType: isBraintree? paymentMethod : "CRE",
          CardType: "CREDIT",
          CardBrand: fileClaimDetails?.ccDetails?.cardType,
          CardCheckNumber: fileClaimDetails?.ccDetails?.cvv,
          AccountHolder: {
            FirstName: agreement.Customers?.Customer[0]?.FirstName,
            LastName: agreement.Customers?.Customer[0]?.LastName,
            CompanyName: "",
            IdentificationType: "NATIONALID",
            IdentificationNumber: agreement?.IdentificationNumber,
          },
        },
        Address: userAddress,
        ContactDetails: {
          phone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          workPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          cellPhone: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          email: workflowState["login"]?.email,
        },
        AutoCompleteId: ChargeOrderResponse?.AutoCompleteId,
      },
    };

    if(isBraintree) {
      ProcessPaymentParameters.ProcessPaymentParameters.BrainTreeDetails = {
        IsBrainTree: true,
        Amount: workflowState["BraintreeDetails"]?.ServiceFeeResponse?.TotalAmount,
        Maid: isBraintree?.maid,
        ThreeDSecureAuthenticationId: fileClaimDetails?.verifyCardResponse?.threeDSecureAuthenticationId,
        ProductId: fileClaimDetails?.verifyCardResponse?.productId,
        Prepaid: fileClaimDetails?.verifyCardResponse?.prepaid,
        BillingAddress: {
          GivenName: agreement.Customers?.Customer[0]?.FirstName,
          Surname: agreement.Customers?.Customer[0]?.LastName,
          PhoneNumber: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
          streetAddress: userAddress?.Address1,
          ExtendedAddress: userAddress?.Address2,
          Locality: userAddress?.City,
          Region: "SG", // ISO-3166-2 code
          PostalCode: userAddress?.PostalCode,
          CountryCodeAlpha2: "SG",
        },
      };
    }
    
    callProcessPaymentApi(
      ProcessPaymentParameters,
      ServiceOrderId,
      ChargeOrderResponse?.ChargeOrderId,
      onApiSuccess,
      onApiFailure
    );
  };

  const callProcessOveragePayment = () => {
    showLoader(true);

    const ProcessOveragePaymentParameters = {
      ProcessOveragePaymentParameters: {
        CustomerDecision: replacementOption?.FulfillmentOption,
        RepairRequestId: RepairRequestResponse?.RepairRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderId,
        AssetCatalogId: replacementOption?.AssetCatalogId,
        UpdatedBy: agreement?.Customers?.Customer[0]?.FullName,
        ServiceOrderLines:
          replacementOption?.FulfillmentOption?.toUpperCase() ===
          FULFILMENT_OPTION_OVERAGE.REPAIR_OVERAGE
            ? undefined
            : {
                ServiceOrderLine: [
                  {
                    ServiceOrderLineType: replacementOption?.AssetCategoryName,
                    Quantity: 1,
                    AssetCatalogId: replacementOption?.AssetCatalogId,
                    VendorItemId: replacementOption?.ItemId,
                    Priority: "MNDTRY",
                    VenderItemType: replacementOption?.AssetCategoryName,
                    VendorData: {
                      IsLikeForLike: true,
                      IsSimCard: false,
                      IsInStock: true,
                    },
                  },
                ],
              },
      },
    };

    callProcessOveragePaymentApi(
      ProcessOveragePaymentParameters,
      onApiSuccess,
      onApiFailure
    );
  };

  const onApiSuccess = (type, data) => {
    showLoader(false);
    if (type === ActionTypes.UPDATE_REPAIR_REQUEST) {
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          UpdateRepairRequestResponse: data,
        },
      }, ["FileClaimDetails"]);
      return completeCurrentStep(currentStep, {
        GoBack: true,
      });
    } else if (type === ActionTypes.GET_SHIPPING_METHODS) {
      showLoader(false);
      let ShippingMethodResponse = data.ShippingMethodResults;
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ShippingMethodResponse,
        },
      }, ["FileClaimDetails"]);
      loadDates(ShippingMethodResponse);
    } else if (type === ActionTypes.UPDATE_SHIPPING_ORDER) {
      showLoader(false);
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ShippingOrderResponse: data.ShippingOrderUpdateResults,
        },
      }, ["FileClaimDetails"]);
      if (isTVRepairWithOverage) callProcessPayment();
      else callUpdateRepairRequest();
    } else if (type === ActionTypes.PROCESS_PAYMENT) {
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ProcessPaymentResponse: data,
        },
      }, ["FileClaimDetails"]);
      const success = ["BG-0" , "BG-703"]
      if(!success.includes(data?.ProcessPaymentResults?.Result?.Code)){
        if(isBraintree) {
          return completeCurrentStep(currentStep, {
            braintree: true,
          });
        } else {
          return completeCurrentStep(currentStep, {
            GoBackCreditCard: true,
          });
        }
      } else {
        callProcessOveragePayment();
      }

    } else if (type === ActionTypes.PROCESS_OVERAGE_PAYMENT) {
      updateState({
        ...workflowState,
        FileClaimDetails: {
          // ...fileClaimDetails,
          ProcessOveragePaymentResponse: data,
        },
      }, ["FileClaimDetails"]);
      return completeCurrentStep(currentStep, {
        showOverageNotes: true,
      });
    }
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    console.log("Api failure : ", type);
    logger({ type, error, state: workflowState});
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  const onGoBackClick = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {},
    });
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: isTVRepairWithOverage ? homeplusscript.txtOnsiteTitle : homeplusscript.txtTitle
  };

  dateSelection.args = {
    ...dateSelection.args,
    label: isTVRepairWithOverage ? homeplusscript.labelOnsiteDate : homeplusscript.labelDeliveryDate,
    options: dates,
    value: deliveryDate,
    onSelect: onDateSelect,
  };

  timeSlotSelection.args = {
    ...timeSlotSelection.args,
    label: homeplusscript.labelTimeSlot,
    options: timeslots,
    value: deliveryTimeSlot,
    onSelect: onTimeSlotSelect,
  };

  inputDeliveryAdd.args = {
    ...inputDeliveryAdd.args,
    label: homeplusscript.labelDeliveryAddress,
    value: addressDetails,
    enabled: false,
    onChange: setAddress,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
    displayClasses: isTVRepairWithOverage ? "hidden" : "block"
  };

  btnNext.args = {
    ...btnNext.args,
    label: isTVRepairWithOverage ? homeplusscript.btnContinue : homeplusscript.btnNext,
    onClick: (e) => onContinueClick(),
    enabled: deliveryDate && deliveryTimeSlot,
    dimensionClasses: isTVRepairWithOverage ? "w-auto" : "w-28",
  };

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: isTVRepairWithOverage  ? "flex justify-center" : "flex justify-between lg:justify-center"
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ScheduleDelivery;
