import React from "react";
import PropTypes from "prop-types";
// import { Image } from '../image/image';

export const Grid = ({
  orientation,
  listStyle,
  options,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  itemClasses,
  image,
  ...props
}) => {
  const _listStyle = listStyle ? `list-${listStyle}` : image ? "" : "list-disc";

  return (
    <div
      className={` text-center ${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses} grid grid-cols-3 gap-4`}
    >
      {/* <div className="col-span-full bg-slate-200 flex flex-row justify-between"> */}
        <div className="">01</div>
        <div className="">02</div>
        <div className="">03</div>
      {/* </div>
      <div className="bg-gray-200">05</div>
      <div className="bg-slate-200">05</div> */}
    </div>
  );
};

Grid.propTypes = {
  /**
   * Orientation describes how list items will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  listStyle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  image: PropTypes.func,
};

Grid.defaultProps = {
  orientation: "vertical",
  marginClasses: "mb-5",
  fontClasses: "text-sm",
  image: undefined,
};
