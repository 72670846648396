import { makeStyles } from '@material-ui/styles';
import clsx from "clsx";
import React from 'react'

export const MessageLoader = ({ color, backgroundColor, isAgent }) => {
  const classes = MessageLoaderStyles();  

  return (
    <div className={classes.root}
        style={{
            backgroundColor: backgroundColor,
            float: isAgent ? 'left' : 'right',
        }}>
        <div style={{backgroundColor: color}}
        className={clsx({
            [classes.dot]: true,
            [classes.left]: true
        })}></div>
        <div style={{backgroundColor: color}}
        className={clsx({
            [classes.dot]: true,
            [classes.center]: true
        })}></div>
        <div style={{backgroundColor: color}}
        className={clsx({
            [classes.dot]: true,
            [classes.right]: true
        })}></div>
    </div>
  )
};

const MessageLoaderStyles = makeStyles((theme) => ({
    root: {
      borderRadius: '16px',
      padding: '15px',
      borderBottomLeftRadius: 0,
      lineHeight: '22px',
      display: 'inline-flex',
      gap: '5px',
    },
    dot: {
        height: '8px',
        width: '8px',
        opacity: '0.3',
        borderRadius: '50%',
        position: 'relative',
        WebkitAnimation: '$dots .6s ease-in-out infinite',
        animation: '$dots .8s ease-in-out infinite'
    },
    left: {
        WebkitAnimationDelay: '0.1s',
    },
    center: {
        WebkitAnimationDelay: '0.2s',
    },
    right: {
        WebkitAnimationDelay: '0.4s',
    },
    '@-webkit-keyframes dots': {
        '0%': {
          opacity: 1,
        }
    },
    '@keyframes dots': {
        '0%': {
          opacity: 1,
        }
    }
}))

