import * as React from "react";

function MessageIconSvg(
  props
) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 9.86667C2.5 5.8 5.8 2.5 9.86667 2.5C13.925 2.5 17.225 5.8 17.2333 9.86667C17.2417 11.5417 16.6667 13.1667 15.6167 14.4667L16.7 17.0167C16.7417 17.125 16.7333 17.25 16.6667 17.35C16.6 17.45 16.4917 17.5083 16.375 17.5083H16.3167L12.3333 16.8083C11.5417 17.0917 10.7083 17.2333 9.86667 17.2333C5.8 17.2333 2.5 13.9333 2.5 9.86667ZM14.9417 14.175C15.4167 13.6167 15.8 12.9833 16.075 12.2917H16.0667C16.375 11.5167 16.525 10.6917 16.525 9.86667C16.525 6.19167 13.5417 3.20833 9.86667 3.20833C6.19167 3.20833 3.21667 6.2 3.21667 9.875C3.21667 13.55 6.2 16.5333 9.875 16.5333C10.6583 16.5333 11.4417 16.3917 12.1833 16.1167C12.225 16.0917 12.2667 16.0917 12.3083 16.0917H12.3667L15.8 16.6917L14.8833 14.5417C14.8333 14.4167 14.8583 14.275 14.9417 14.175ZM7.36654 10C7.36654 10.4602 6.99344 10.8333 6.5332 10.8333C6.07297 10.8333 5.69987 10.4602 5.69987 10C5.69987 9.53976 6.07297 9.16667 6.5332 9.16667C6.99344 9.16667 7.36654 9.53976 7.36654 10ZM10.6999 10C10.6999 10.4602 10.3268 10.8333 9.86654 10.8333C9.4063 10.8333 9.0332 10.4602 9.0332 10C9.0332 9.53976 9.4063 9.16667 9.86654 9.16667C10.3268 9.16667 10.6999 9.53976 10.6999 10ZM13.1999 10.8333C13.6601 10.8333 14.0332 10.4602 14.0332 10C14.0332 9.53976 13.6601 9.16667 13.1999 9.16667C12.7396 9.16667 12.3665 9.53976 12.3665 10C12.3665 10.4602 12.7396 10.8333 13.1999 10.8333Z" fill="#0F6B30"/>
        </svg>
    );
  }

export default MessageIconSvg
