import React from "react";
import PropTypes from "prop-types";
import { Button } from "../button/button";
import { Tooltip } from "../tooltip/tooltip";
import { containsObject } from "../enrollment-workflow/helper";

export const DeviceItems = ({
  label,
  deviceItems,
  onClick,
  value,
  showTooltip,
  isMultiSelect,
  onSelect,
  onRemove,
  mainDivClasses,
  mainDivDisplayClasses,
  itemDivClasses,
  itemClass,
  itemBorderClasses,
  itemColorClasses,
  itemPaddingClasses,
  itemSelectedClasses,
  itemFontClasses,
  labelClasses,
  itemDimensionClasses,
  ...props
}) => {

  const onItemClick = (item) => {
    if (isMultiSelect) {
      // now check if item is there already
      if (containsObject(item, value)) {
        onRemove(item);
      } else onSelect(item);
    } else onClick(item);
  };

  return (
    <div className={`${mainDivClasses} ${mainDivDisplayClasses}`}>
      <div className={`${labelClasses}`}>
        {showTooltip ? (
          <Tooltip showLabel={true} labelText={label} />
        ) : (
          <label>{label}</label>
        )}
      </div>

      <div className={itemDivClasses}>
        {deviceItems?.length >= 0  && deviceItems.map((item) => {
          return (
            <div
              key={item.value}
              className={`${itemClass} ${
                (isMultiSelect && containsObject(item, value)) ||
                (!isMultiSelect && item.value === value)
                  ? itemSelectedClasses
                  : undefined
              }`}
            >
              <Button
                enabled={true}
                label={item.label}
                borderClasses={itemBorderClasses}
                paddingClasses={itemPaddingClasses}
                fontClasses= {itemFontClasses}
                colorClasses={
                  (isMultiSelect && containsObject(item, value)) ||
                  (!isMultiSelect && item.value === value)
                    ? itemSelectedClasses
                    : itemColorClasses
                }
                dimensionClasses={itemDimensionClasses}
                onClick={() => {
                  onItemClick(item);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

DeviceItems.propTypes = {
  onClick: PropTypes.func,
  deviceItems: PropTypes.arrayOf(PropTypes.object),
  showTooltip: PropTypes.bool,
  itemClass: PropTypes.string,
  itemSelectedClasses: PropTypes.string,
  itemBorderClasses: PropTypes.string,
  itemPaddingClasses: PropTypes.string,
  itemColorClasses: PropTypes.string,
  itemFontClasses: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

DeviceItems.defaultProps = {
  onClick: undefined,
  deviceItems: [],
  showTooltip: false,
  mainDivClasses: "flex justify-center mt-4",
  itemDivClasses: "flex flex-wrap lg:w-4/5 justify-center",
  itemClass: "mt-1 mr-2 flex flex-col items-center border",
  itemBorderClasses: "",
  itemPaddingClasses: "p-2",
  itemColorClasses: "text-neutral",
  itemFontClasses: "",
  itemSelectedClasses: "bg-light text-link",
  isMultiSelect: false,
  onSelect: undefined,
  onRemove: undefined,
};
