export const POLICY_STATUS = {
  ACTV: "ACTV",
  SUSPND: "SUSPND",
};

export const INCIDENT_PATH_TYPE = {
  START_SERVICE_REQUEST: "StartServiceRequest",
  RESUME_SERVICE_REQUEST: "ResumeServiceRequest",
  WAIT_FOR_HOLD: "WaitForHoldResolution",
  NO_ACTION_REQUIRED: "NoActionRequired",
  NOT_ELIGIBLE: "NOTELIGIBLE",
};

export const INCEDENT_TYPES = {
  MALFUNCTION: "Malfunction",
  NON_TECHNICAL_ISSUE: "Non Technical Issue",
  LIQUID_EXPOSURE: "Liquid Exposure",
  SCRATCHED_AND_DENTED: "Scratched and Dented",
  CATASTROPHIC_DAMAGE: "Catastrophic Damage",
  LOSS_STOLEN: "Loss/Stolen",
};

export const REGEX = {
  Visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  Amex: /^(?:3[47][0-9]{13})$/,
  MasterCard: /^(?:5[1-5][0-9]{14})$/,
  Text: /[^\u0E00-\u0E7Fa-zA-Z' ]|^'|'$|''/g,
  CCName: /^[_.’'\p{L}\p{Mn}\p{Pd}\s]{1,100}$/su, // bg v3 regex
};

export const CARD = {
  VISA: "VISA",
  MASTER: "MASTER",
  AMEX: "AMEX",
};

export const DEVICE_CATEGORY = {
  TV: "TV",
  Monitor: "Monitor",
  Laptop: "Laptop",
  Desktop: "Desktop",
  Tablet: "Tablet",
  Soundbar: "Soundbar",
  Keyboard: "Keyboard",
  Mouse: "Mouse",
  Printer: "Printer",
  Router: "Router",
  Webcam: "Webcam",
};

export const EXPANDABLE_TYPE = {
  DEVICE_LISTING: "DEVICE_LISTING",
  CLAIM_LIMIT: "CLAIM_LIMIT",
  QUESTION_LISTING: "QUESTION_LISTING",
  CLAIM_COVERAGE: "CLAIM_COVERAGE",
  CLAIM_HISTORY: "CLAIM_HISTORY",
};

export const FULFILMENT_OPTION = {
  REPAIR: "REPAIR",
  REPLACMENT: "ADVEXCH",
};

export const FULFILMENT_OPTION_OVERAGE = {
  REPAIR_OVERAGE: "REPAIR WITH OVERAGE",
  REPLACMENT_OVERAGE: "REPLACEMENT WITH OVERAGE",
  REPLACMENT: "REPLACEMENT",
};

export const REPAIR_STATUS = {
  DEVICE_MISMATCH_ACKNOWLEDGE: "DEVICE MISMATCH ACKNOWLEDGED",
  DEVICE_MISMATCH: "DEVICE MISMATCH",
  MISMATCH_REJECTED: "REPAIR REJECTED",
  PICKUP_FAILED: "PICKUP FAILED",
  DELIVERY_FAILED: "DELIVERY FAILED",
  PICKUP_RESCHEDULED: "PICKUP RESCHEDULED",
  DELIVERY_RESCHEDULED: "DELIVERY RESCHEDULED",
}

/**
 * This min.js version should be equal to pdfjs-dist installed in package.json
 * Otherwise version mismatch warning would be thrown and pdf won't show preview
 */
export const WORKER_URL_PDF =
  "https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js";

export const BG_ERROR_CODE = {
  BG_1002: "BG-1002",
  BG_1015: "BG-1015",
  BG_1000: "BG-1000",
};

export const GTM_EVENT = {
  LOGIN_EVENT: "HomePlus_DL_Event Login",
  ERROR_EVENT: "HomePlus_DL_Event Error",
  POLICY_NO_EVENT: "HomePlus_DL_Event Policy No",
  CLAIM_NO_EVENT: "HomePlus_DL_Event Claim No",
};

export const GROUP_A_DEVICES = [
  {
    key: "",
    icon: "Desktop",
    type: "Desktop",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Laptop",
    type: "Laptop",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Soundbar",
    type: "Soundbar",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Tablet",
    type: "Tablet",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "TV",
    type: "TV",
    itemCount: "0 device",
  },
];

export const GROUP_B_DEVICES = [
  {
    key: "",
    icon: "Keyboard",
    type: "Keyboard",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Monitor",
    type: "Monitor",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Mouse",
    type: "Mouse",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Printer",
    type: "Printer",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Router",
    type: "Router",
    itemCount: "0 device",
  },
  {
    key: "",
    icon: "Webcam",
    type: "Webcam",
    itemCount: "0 device",
  }
];
