import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../../enrollment-workflow/helper";

function RegisterDevice() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['RegisterDevice']
  const homeplusscript = workflowState['config']?.scripts["RegisterDevice"];

  const onDeviceRegClick = () => {
    return completeCurrentStep(currentStep, {
      selectDevice: true,
    });
  };

  let btnDeviceReg = findById(metadata, "btnDeviceReg");
  let menu = findById(metadata,"menu");

  btnDeviceReg.args = {
    ...btnDeviceReg.args,
    label: homeplusscript.btnDeviceReg,
    onClick: (e) => onDeviceRegClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"]
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default RegisterDevice;
