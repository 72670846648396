import { useContext, useState, useEffect } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty, containsObject, getContactNumber } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import logger from "../../../logger.service";

function OverageCancelClaim(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['OverageCancelClaim']
  const homeplusscript = workflowState['config']?.scripts["OverageCancelClaim"];

  let FileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = FileClaimDetails.ServiceRequestResponse;
  const ServiceFeeResponse = FileClaimDetails.ServiceFeeResponse;
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];
  let loginDetails = workflowState["login"];
  let incidentResponse = FileClaimDetails.AssetDetails;

  useEffect(() => {
    displayOptions(options);
  }, []);

  const options = workflowState.ReplacementMatrixResults;

  const displayOptions = (options) => {
    let optionList = [];
    options?.forEach((ReplacementMatrixResults) => {
      if (!isEmpty(ReplacementMatrixResults?.FulfillmentOption)) {
        let option = {
          DeviceName: ReplacementMatrixResults?.AssetCatalogName,
          Amount: ReplacementMatrixResults?.OverageAmount,
          DeviceCategory: ReplacementMatrixResults?.AssetCategoryName,
          FulfillmentOption: ReplacementMatrixResults?.FulfillmentOption,
          DeviceModel: ReplacementMatrixResults?.AssetModel,
        };
        if (!containsObject(option, optionList, "FulfillmentOption"))
          optionList.push(option);
      }
    });
    setOptions(optionList);
  };

  const [option, setOptions] = useState([]);
  let customerCaseNumber =
    ServiceRequestResponse?.CustomerCaseNumber.toString();

  const getSelectedAttributes = (separator) => {
    let inquiryDescription = `${
      !isEmpty(claimReason) ? "Reason: " + claimReason : ""
    }${
      !isEmpty(customerCaseNumber)
        ? "\n CustomerCase_Number: " + customerCaseNumber
        : ""
    }${!isEmpty(option) ? "\n FullFillmentOptions offered" : ""}${
      !isEmpty(option)
        ? option.map((item, i) => {
            return (
              "\n FullFillmentOption: " +
              item.FulfillmentOption +
              separator +
              "OverageAmount: " +
              item.Amount +
              separator +
              "DeviceCategory: " +
              item.DeviceCategory +
              separator +
              "DeviceName: " +
              item.DeviceName +
              separator +
              "DeviceModel: " +
              item.DeviceModel
            );
          })
        : ""
    }`;
    return inquiryDescription.charAt(inquiryDescription.length - 1) ===
      separator
      ? inquiryDescription.substring(0, inquiryDescription.length - 1)
      : inquiryDescription;
  };

  const [claimReason, setClaimReason] = useState("");

  let textHeader = findById(metadata, "txtTitle");
  let btnSubmit = findById(metadata, "btnSubmit");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menu = findById(metadata, "menu");
  let textAreaCancelReason = findById(metadata, "textAreaCancelReason");
  let textAreaLimit = findById(metadata, "textAreaLimit");

  textHeader.args = { ...textHeader.args, content: homeplusscript.txtTitle };

  const createCancelInquiry = () => {
    const assets = agreement?.Assets?.Asset[0];
    const contact = getContactNumber(agreement?.ContactPoints?.ContactPoint);
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: contact,
        FirstName: agreement.Customers?.Customer[0]?.FirstName,
        LastName: agreement.Customers?.Customer[0]?.LastName,
        ContactNumber: contact,
        ClientName: workflowState["config"]?.ClientName,
        InquiryChannel: "ONLINE",
        InquiryType: "Claims Processing",
        InquirySubType: "Claims Resolution Related",
        InquiryDescription: getSelectedAttributes("|", false),
        CallDriverCategory: "",
        CallDriverSubCategory: "",
        ClientId: workflowState["config"]?.ClientId,
        Email: loginDetails?.email,
        DepartmentType: "Care",
        Priority: "High",
        ProgramName: agreement.ClientOffer.ClientOfferName,
        AgreementId: agreement.AgreementId,
        ClientAccountId: agreement.ClientAccount.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
        AssetId: incidentResponse?.AssetId,
        UpdateDeviceDetails: {
          AssetCatalogId: "",
          IMEI: "",
          ClientAssetSkuNumber: "",
        },
      },
    };

    API[ActionTypes.CREATE_ENQUIRY](CreateInquiryParams)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          enquiryResponse: data,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_ENQUIRY, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const onMenuItemClick = (redirect) => {
    refreshStateOnLogout(workflowState, redirect);
  };

  const goToDashboard = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        claimReason,
      },
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const onSubmitClick = () => {
    createCancelInquiry();
    showLoader(false);
    return completeCurrentStep(currentStep, {
      DontCancel: true,
    });
  };

  btnSubmit.args = {
    ...btnSubmit.args,
    label: homeplusscript.btnSubmit,
    enabled: claimReason,
    onClick: (e) => onSubmitClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
    onClose: (e) => goToDashboard(),
    onMenuItemClick: (e) => onMenuItemClick(e),
  };

  textAreaCancelReason.args = {
    ...textAreaCancelReason.args,
    label: homeplusscript.textAreaCancelReasonLabel,
    placeHolder: homeplusscript.textAreaCancelReasonPlaceHolder,
    value: claimReason,
    onChange: setClaimReason,
  };
  textAreaLimit.args = {
    ...textAreaLimit.args,
    content: `<div>${claimReason ? claimReason.length : 0}/150</div>`,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default OverageCancelClaim;
