import axios from "axios";

import API from "../services";
import types from "../ActionTypes";

const API_KEY = process.env.REACT_APP_SLS_API_KEY; 

function dataURItoBlob(
  dataURI,
  contentType = null,
  shouldConvertToBin = false
) {
  var binary;
  if (shouldConvertToBin) binary = atob(dataURI.split(",")[1]);
  else binary = atob(dataURI);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  if (contentType)
    return new Blob([new Uint8Array(array)], { type: contentType });
  return new Blob([new Uint8Array(array)]);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      resolve(reader.result.split("base64,")[1]);
    };
  });
}

export async function getSignedRequestForUpload(agreementId) {
  return new Promise((resolve, reject) => {
    const payload = {
      FileUploadRequest: {
        AgreementId: agreementId
      },
    };
    API[types.GET_SIGNED_URL_FROM_S3](payload).then(({ data = {} }) => {
      resolve(data?.url)
    });
  });
}

export async function getSignedURLForDownload(payload) {
  return new Promise((resolve, reject) => {
    API[types.GET_DOWNLOAD_URL_FROM_S3](payload).then(({ data = {} }) => {
      resolve(data);
    });
  });
}

export async function uploadFileBySignedUrl(url, type, file, handleProgress, formData) {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url,
      headers: {
        "x-api-key": API_KEY,
        Accept: "application/json",
        "Content-Type": type,
      },
      data: file,
      onUploadProgress: handleProgress,
      formData: formData,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function uploadFileToBucket(
  file,
  signedUrl,
  handleProgress,
  callback
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { pathname } = new URL(signedUrl);
      const [fileName, ...path] = pathname.split("/").reverse()
      const callbackPayload = {
        fileName,
        path: path?.reverse()?.join('/')
      }
      const base64Obj = await getBase64(file);
      const uploadedFile = await uploadFileBySignedUrl(
        signedUrl,
        file.type,
        dataURItoBlob(base64Obj),
        (progressEvent) => handleProgress(progressEvent, file.size)
      );

      if (
        uploadedFile &&
        uploadedFile.status === 200 &&
        uploadedFile.statusText === "OK"
      ) {
        resolve(uploadedFile);
        callback(callbackPayload);
      } else {
        callback(null);
        reject(uploadedFile.statusText);
      }
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
