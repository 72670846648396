import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import {
  containsObject,
  findById,
  getFormmatedDate,
  getMetadata,
  isEmpty,
} from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { DEVICE_CATEGORY, EXPANDABLE_TYPE, INCIDENT_PATH_TYPE } from "../../../utils/constants";
import { callCancelClaimApi, callCreateInteractionApi } from "../api-helper";
import logger from "../../../logger.service";

function SelectDeviceFileClaim() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedDevice, setSelectedDevice] = useState({});
  const [deviceCategories, setRegisteredDeviceCategories] = useState([]);
  const [registeredDevices, setRegisteredDevice] = useState([]);
  const [incidentPathType, setIncidentPathType] = useState(false);

  const metadata = workflowState['config']?.metadata['SelectDeviceFileClaim']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceFileClaim"];

  let listCategories = findById(metadata, "registeredDeviceCategories");
  let listDevices = findById(metadata, "registeredDevices");
  let txtTitle = findById(metadata, "txtTitle");
  let txtAddDevice = findById(metadata, "linkAddDevice");
  let menuLabel = findById(metadata, "menu");

  const FindAgreementResponse = workflowState["FindAgreementResponse"];
  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
  const assets = agreement?.Assets?.Asset;

  let fileClaimDetails = workflowState["FileClaimDetails"];

  // trrigers on Click of File Claim Button
  const onContinueClick = (device) => {
        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            selectedCategory: device?.AssetCategory?.AssetCategoryName,
            selectedDevice: device,
            AssetDetails: {
              AssetCatalogId: device.key,
              AssetId: device.AssetId,
            },
          },
        }, ["FileClaimDetails"]);

        return completeCurrentStep(currentStep, {
          showIncidentTypes: true,
        });
  };

  useEffect(() => {
    displayRegisteredDeviceCategories(assets);
    callDetermineIncident();
    // callCreateInteraction();
  }, []);

  //get call on pagege load
  const callDetermineIncident = () => {
    showLoader(true);
    const DetermineIncidentPaths = {
      DetermineIncidentPaths: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ClientId: workflowState["config"]?.ClientId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        AgreementId: agreement?.AgreementId,
        CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
      },
    };

    API[ActionTypes.DETERMINE_INCIDENT_PATH](DetermineIncidentPaths)
      .then((data) => {
        console.log("determine incident Data : ", data);

        const determineResponse = data.data.IncidentPaths;

        showLoader(false);
        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            DetermineIncidentResponse: determineResponse,
          }
        }, ["FileClaimDetails"]);

        setIncidentPathType(determineResponse[0].Type)

        // if (determineResponse[0].Type !== "StartServiceRequest") {
          // cancelExistingSR(
          //   determineResponse[0]?.ServiceRequest?.ServiceRequestId
          // );
          // setIncidentPathType(true);
        // }
        // else if (
        //   determineResponse.length > 1 &&
        //   determineResponse[1]?.Type === "CheckServiceRequestStatus"
        // ) {
        //   // cancelExistingSR(
        //   //   determineResponse[1]?.ServiceRequest?.ServiceRequestId
        //   // );
        //   setIncidentPathType(true);
        // }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.DETERMINE_INCIDENT_PATH, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const cancelExistingSR = (ServiceRequestId) => {
    showLoader(true);
    const CancelServiceRequestParams = {
      CancelServiceRequestParams: {
        ServiceRequestId: ServiceRequestId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        NoteText: "test",
        Operation: "Cancel",
        Reason: "Cancelled by TLC",
        SubReason: "REPAIRREJECTED",
        CancelEntities: "ALL",
        RequestedBy: "Customer",
        ReturnEntity: true,
      },
    };

    callCancelClaimApi(
      ServiceRequestId,
      CancelServiceRequestParams,
      onApiSuccess,
      onApiFailure
    );

  };

  const callCreateInteraction = () => {
    showLoader(true);
    const CreateInteraction = {
      CreateInteraction: {
        Interaction: {
          ChannelName: "SH_DEVICE_CARE-WEB",
          ClientId: workflowState["config"]?.ClientId,
          ClientChannelId: workflowState["config"]?.ClientChannelId,
          InteractionStatus: "INPRGRS",
          InteractionLine: {
            InteractionLineReason: "STRTSR",
            Language: "en-US",
          },
        },
      },
    };

    callCreateInteractionApi(
      CreateInteraction,
      onApiSuccess,
      onApiFailure
    );

  }

  const onApiSuccess = (type, data) => {
    showLoader(false);
    if (type === ActionTypes.CANCEL_SERVICE_REQUEST) {
      callDetermineIncident();
    } else if (type === ActionTypes.CREATE_INTERACTION){
      updateState({
        ...workflowState,
        FileClaimDetails: {
          CreateInteractionResponse: data,
        },
      }, ["FileClaimDetails"]);
      callDetermineIncident(data?.Interaction?.InteractionLine?.InteractionLineId);
    }
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type, error, state: workflowState});
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  const displayRegisteredDeviceCategories = (assets) => {
    let categoryList = [];
    assets?.forEach((asset) => {
      if (!isEmpty(asset.AssetCategory)) {
        let device = {
          key: asset.AssetCategory.AssetCategoryId,
          icon: asset.AssetCategory.AssetCategoryName,
          type: asset.AssetCategory.AssetCategoryName,
        };

        if (fileClaimDetails?.selectedCategory === device.type) {
          setSelectedCategory(device);
          displayRegDevices(device);
        }

        if (!containsObject(device, categoryList, "type"))
          categoryList.push(device);
      }
    });
    setRegisteredDeviceCategories(categoryList);
  };

  const displayRegDevices = (category) => {
    const devices = assets.filter(
      (item) => item.AssetCategory.AssetCategoryName === category.type
    );

    let registeredDevicesList = [];

    devices.forEach((asset) => {
      if (!isEmpty(asset.AssetCatalog)) {
        let registeredDevice = {
          key: asset.AssetCatalog.AssetCatalogId,
          icon: category.type,
          expandableType: EXPANDABLE_TYPE.DEVICE_LISTING,
          type: asset.AssetCatalog.AssetCatalogName,
          itemCount: `Added on : ${getFormmatedDate(
            asset?.CreatedDate,
            "dd MMM yyyy"
          )}`,
          btnLabel: homeplusscript.btnFileClaim,
          tableHeaders: getDeviceTableHeaders(asset),
          tableData: getDeviceTableData(asset),
          ...asset,
        };

        registeredDevicesList.push(registeredDevice);
      }
    });
    setRegisteredDevice(registeredDevicesList);
  };

  const getDeviceTableHeaders = (asset) => {
    const categoryName = asset.AssetCategory.AssetCategoryName;
    const isTV = categoryName === DEVICE_CATEGORY.TV;
    const isSoundbar = categoryName === DEVICE_CATEGORY.Soundbar;
    const isLaptopDesktop =
      categoryName === DEVICE_CATEGORY.Laptop ||
      categoryName === DEVICE_CATEGORY.Desktop;
    const isLaptop = categoryName === DEVICE_CATEGORY.Laptop;
    const isTablet = categoryName === DEVICE_CATEGORY.Tablet;
    const isWebcam = categoryName === DEVICE_CATEGORY.Webcam;
    const isKeyboard = categoryName === DEVICE_CATEGORY.Keyboard;
    const isPrinter = categoryName === DEVICE_CATEGORY.Printer;
    const isMouse = categoryName === DEVICE_CATEGORY.Mouse;
    const isRouter = categoryName === DEVICE_CATEGORY.Router;
    const isMonitor = categoryName === DEVICE_CATEGORY.Monitor;
    const deviceTableHeaders = [
      {
        key: "brand",
        text: homeplusscript.labelBrand,
        hideForNoAction: false,
      },
      {
        key: "serialNo",
        text: homeplusscript.labelSerialNo,
        hideForNoAction: isEmpty(asset?.IMEI) ? true : false,
      },
      {
        key: "modelNo",
        text: homeplusscript.labelModelNo,
        hideForNoAction: isEmpty(asset?.ClientAssetSkuNumber) ? true : false,
      },
      {
        key: "isBuiltIn",
        text: homeplusscript.labelIsBuiltIn,
        hideForNoAction: !isSoundbar ? true : false,
      },
      {
        key: "keyboardType",
        text: homeplusscript.labelKeyboardType,
        hideForNoAction: !isKeyboard ? true : false,
      },
      {
        key: "bluetooth",
        text: homeplusscript.labelHasBluetooth,
        hideForNoAction: !isMouse && !isKeyboard ? true : false,
      },
      {
        key: "deviceSpeed",
        text: homeplusscript.labelDeviceSpeed,
        hideForNoAction: !isRouter ? true : false,
      },
      {
        key: "acquiredFromCarrier",
        text: homeplusscript.labelAcquiredFromCarrier,
        hideForNoAction: !isRouter ? true : false,
      },
      {
        key: "processor",
        text: homeplusscript.labelProcessor,
        hideForNoAction: !isLaptopDesktop ? true : false,
      },
      {
        key: "RAM",
        text: homeplusscript.labelRAM,
        hideForNoAction: !isLaptopDesktop ? true : false,
      },
      // {
      //   key: "displayTechnologies",
      //   text: homeplusscript.labelDisplayTechnologies,
      //   hideForNoAction: isEmpty(asset?.selectedTech) ? true : false,
      // },
      {
        key: "technology",
        text: homeplusscript.labelTechnology,
        hideForNoAction: !isPrinter ? true : false,
      },
      {
        key: "function",
        text: homeplusscript.labelFunction,
        hideForNoAction: !isPrinter ? true : false,
      },
      {
        key: "deviceResolution",
        text: homeplusscript.labelResolution,
        hideForNoAction: !isTV && !isMonitor && !isWebcam ? true : false,
      },
      {
        key: "deviceFrameRate",
        text: homeplusscript.labelFrameRate,
        hideForNoAction: !isWebcam ? true : false,
      },
      {
        key: "screenSize",
        text: homeplusscript.labelScreenSize,
        hideForNoAction: !isTV && !isMonitor ? true : false,
      },
      {
        key: "connectivity",
        text: homeplusscript.labelConnectivity,
        hideForNoAction: !isSoundbar && !isTablet && !isWebcam ? true : false,
      },
      {
        key: "storageCapacity",
        text: homeplusscript.labelStorageCapacity,
        hideForNoAction: !isLaptopDesktop && !isTablet ? true : false,
      },
      {
        key: "storageType",
        text: homeplusscript.labelStorageType,
        hideForNoAction: !isLaptopDesktop ? true : false,
      },
      {
        key: "googleTV",
        text: homeplusscript.labelGoogleTV,
        hideForNoAction: !isTV ? true : false,
      },
    ];

    return deviceTableHeaders;
  };

  const getDeviceTableData = (asset) => {
    const masterKeys = {
      'Resolution': 'deviceResolution',
      'Display_tech': 'deviceResolution',
      'Display_size': 'screenSize',
      'Connectivity': 'connectivity',
      'In_built_bluetooth': 'bluetooth',
      'Storage_Type': 'storageType',
      'Storage_Size': 'storageCapacity',
      'RAM': 'RAM',
      'Processor': 'processor',
      'Wireless': 'connectivity',
      'Google_TV': "googleTV",
      'Printing_Techonolgy': 'technology',
      'Function': 'function',
      'Bandwidth': 'deviceSpeed',
      'Frame_Rate': 'deviceFrameRate',
      'Capacity': 'storageCapacity',
      'Type': 'keyboardType',
      'SerialNo':"serialNo"
    }
  
    const record = asset?.AssetAttributes?.split("|").reduce(
      (acc, category) => {
        const [key, value] = category.split("=");
        const recordKey = masterKeys[key];
        if (recordKey) {
          acc[recordKey] = value;
        }
        return acc;
      },
      {}
    );

    return [
      {
        brand: asset?.Make?.Name,
        modelNo: asset?.ClientAssetSkuNumber,
        serialNo: asset?.IMEI,
        acquiredFromCarrier: "No",
        isBuiltIn: "No",
        ... record
      }
    ]
  };


  const onCategorySelect = (category) => {
    setSelectedCategory({
      ...selectedCategory,
      ...category,
    });

    displayRegDevices(category);
  };

  const onDeviceSelect = (device) => {
    setSelectedDevice({
      ...selectedDevice,
      ...device,
    });

    onContinueClick(device);
  };

  const onResumeClick = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {} 
    });

    return completeCurrentStep(currentStep, {
      showMyClaim: true,
    });
};

  const onAddDeviceClick = () => {
    return completeCurrentStep(currentStep, {
      addDevice: true,
    });
  };

  const getClaimStartDate = () => {

    if(incidentPathType === INCIDENT_PATH_TYPE.NOT_ELIGIBLE) {
      let date = new Date(agreement?.StartDate);
      date.setDate(date.getDate() + 31);

      return getFormmatedDate(date.toDateString(), "dd MMM yyyy", " ")
    }

    return "";
  }

  menuLabel.args = {
    ...menuLabel.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"]
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  listCategories.args = {
    ...listCategories.args,
    options: deviceCategories,
    value: selectedCategory,
    onListItemSelect: onCategorySelect,
  };

  listDevices.args = {
    ...listDevices.args,
    options: registeredDevices,
    value: selectedDevice,
    onListItemSelect: onDeviceSelect,
    incidentPathType,
    linkText: homeplusscript.labelResumeClaim,
    notEligibleText: homeplusscript.labelNotEligibleText.replace(
      "[date]",
      getClaimStartDate()
    ),
    onResumeClick: onResumeClick,
  };

  txtAddDevice.args = {
    ...txtAddDevice.args,
    label: homeplusscript.txtAddDevice,
    onClick : onAddDeviceClick
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceFileClaim;
