import * as React from "react";

const FaqDevice = (props) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.75 4.35s.03-.067.037-.097c.196-.75.788-1.223 1.56-1.253H17.776c.863 0 1.5.48 1.68 1.26.038.165.038.338.038.503V8.498h2.61c.72 0 1.147.434 1.147 1.147v10.11c0 .728-.427 1.155-1.163 1.155H14.79c-.742 0-1.162-.427-1.17-1.177v-1.268H6.225c-.345 0-.502-.105-.495-.323 0-.21.157-.307.487-.307H9.78V15.9H2.723c-1.17 0-1.56-.263-1.973-1.343V4.35Zm12.877 9.158V9.713c0-.81.405-1.223 1.208-1.223h4.02V4.732c0-.72-.383-1.095-1.095-1.095H2.49c-.72 0-1.095.376-1.095 1.095v8.776h12.232Zm.653 4.732h8.31v-7.372h-8.31v7.372Zm-.675-2.992v-1.073H1.417c-.075.615.36 1.072 1.02 1.072h11.168Zm.66 3.652v.788c0 .45.12.57.57.57h7.2c.023 0 .046.002.07.004.056.004.11.009.148-.012l.049-.03c.116-.07.259-.157.265-.255.028-.256.023-.512.019-.77a16.86 16.86 0 0 1-.003-.295h-8.318Zm8.34-9.195v.473h-8.318c0-.086-.002-.171-.005-.256a3.971 3.971 0 0 1 .005-.487c.015-.18.158-.277.33-.308h7.418c.45 0 .57.12.57.578Zm-12.15 6.188v1.904h3.158v-1.905h-3.158Zm7.972 4.02h-.592c-.203 0-.33-.128-.338-.3-.007-.188.12-.323.33-.33.275-.006.546-.004.819-.002l.411.002c.203 0 .323.134.33.307 0 .188-.135.308-.345.323h-.615Zm.345-10.246a.34.34 0 0 0-.337-.33c-.188 0-.33.158-.33.353a.34.34 0 0 0 .337.33c.188 0 .33-.158.33-.353Z"
      fill="#0F6B30"
    />
  </svg>
);

export default FaqDevice;
