import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import Sidebar from "./Sidebar";
import MenuIcon from "../../icons/ic_menu";
import { Text } from "../text/text";
import Icon from "../icon/icon";
import IconClose from "../../icons/ic_close";

import "./menu.css";
import { WorkflowContext } from "../workflow/workflow-context";
import ActionTypes from "../../ActionTypes";
import API from "../../services";
import ConfirmClaimCancellationPopup from "../enrollment-workflow/ConfirmClaimCancellationPopup";
import { updateScrollAction } from "../enrollment-workflow/helper";

export const Menu = (props) => {
  const {
    state,
    showSubHeader,
    iconSubHeader,
    labelSubHeader,
    classesSubHeader,
    showCloseIcon,
    showMenuIcon,
    redirect,
    showCancelClaimPopUp,
    onPopUpClose,
    // onClose,
    // onMenuItemClick
  } = props;

  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const [openMenuPopUp, setOpenMenuPopup] = useState(false);
  // const [openCancelClaimPopup, setClaimPopup] = useState(showCancelClaimPopUp);

  useEffect(() => {
    if (!showCancelClaimPopUp) onPopUpClose(false);
  }, [showCancelClaimPopUp]);

  const contentStyleForMenu = {
    borderRadius: "16px",
    margin: "",
    position: "unset",
    background: "black",
  };

  const contentStyle = {
    padding: "15px",
    width: "400px",
    height: "300px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    border: "2px groove ",
    borderRadius: "16px",
    borderColor: "",
    textColor: "",
  };

  const showMenu = () => {
    setOpenMenuPopup(!openMenuPopUp);
    
    updateScrollAction('hidden')
  };
  // const showPopup = () => {setClaimPopup(!openCancelClaimPopup);};

  const onMenuItemClick = (redirect) => {

    updateScrollAction('scroll')

    if(!redirect) {
      return
    }
    
    setOpenMenuPopup(false);
    updateState({
      ...workflowState,
      FileClaimDetails: {},
      RegDeviceDetails: {},
      OpenChatWidget: redirect === "chat",
    });

    if (redirect === "login") {
      sessionStorage.clear();
      logout();
    }

    refreshStateOnLogout(workflowState, redirect);
  };

  const logout = () => {
    API[ActionTypes.SIGNOUT_REQUEST]()
      .then((data) => {
        showLoader(false);
      })
      .catch((error) => {
        showLoader(false);
        updateState({
          ...workflowState,
          SignoutError: error,
          [currentStep]: {
            ...workflowState[currentStep],
            SignoutError: true,
            loggedIn: false,
          },
        });
      });
  };

  const onClose = () => {
    onMenuItemClick(redirect);
  };

  return (
    <>
      <div className="flex flex-row justify-between mt-3 mb-[13px] items-center">
        <Icon
          className="cursor-pointer"
          icon= {"MobileHeaderText"}
          onClick={(e) => onMenuItemClick(redirect)}
        />
        {showMenuIcon && (
          <MenuIcon
            className="right-0 cursor-pointer "
            onClick={() => showMenu()}
          />
        )}
      </div>
      {showSubHeader && (
        <div className="flex flex-row justify-between my-2 mb-3">
          <div className="flex flex-row">
            <Icon
              className={`self-center ${
                iconSubHeader === "Profile" ? "stroke-primary" : "fill-primary"
              } `}
              icon={iconSubHeader}
            ></Icon>
            <Text content={labelSubHeader} other={classesSubHeader}></Text>
          </div>
          {showCloseIcon && (
            <div className="pt-[6px]">
              <IconClose className={"cursor-pointer"} onClick={onClose} />
            </div>
          )}
        </div>
      )}
      <Popup
        contentStyle={contentStyleForMenu}
        open={openMenuPopUp}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="absolute right-0 flex flex-col items-center justify-center h-full md:w-auto lg:w-2/4 lg:h-auto 2xl:pl-10">
          <Sidebar
            state={state}
            openMenuPopUp={openMenuPopUp}
            setOpenMenuPopup={setOpenMenuPopup}
            onMenuItemClick={(e) => onMenuItemClick(e)}
          />
        </div>
      </Popup>
      <Popup
        contentStyle={contentStyle}
        open={showCancelClaimPopUp}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <ConfirmClaimCancellationPopup
            openConfirmClaimCancellationPopup={showCancelClaimPopUp}
            setConfirmClaimCancellationPopup={onPopUpClose}
          />
        </div>
      </Popup>
    </>
  );
};

Menu.propTypes = {
  /**
   * Orientation describes how list items will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  contentStyle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  image: PropTypes.func,
  showSubHeader: PropTypes.bool,
  iconSubHeader: PropTypes.string,
  labelSubHeader: PropTypes.string,
  classesSubHeader: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
  onMenuItemClick: PropTypes.func,
};

Menu.defaultProps = {
  orientation: "vertical",
  marginClasses: "mb-5",
  fontClasses: "text-sm",
  image: undefined,
  showSubHeader: false,
  showCloseIcon: true,
  showCancelClaimPopUp: false,
  onPopUpClose: () => {},
};
