import React from 'react'

const MyClaim = ({ isSelected, className, onClick }) => (
  <svg className={className}
   onClick={onClick}
   width="24"
   height="24"
   viewBox="0 0 33 32"
   fill="none" 
   xmlns="http://www.w3.org/2000/svg">

    <path fillRule="evenodd" 
    clipRule="evenodd"
    //  fill-rule="evenodd"
      // clip-rule="evenodd"
     d="M5.38889 4.84847H16.5V9.21211H20.9444V12.4848H23.1667V7.66957L18.0711 2.66666H5.38889C4.16333 2.66666 3.16667 3.64629 3.16667 4.84847V24.4848C3.16667 25.6881 4.16333 26.6667 5.38889 26.6667H13.1667V24.4848H5.38889V4.84847ZM7.16667 12H16.5V14.6667H7.16667V12ZM7.16667 17.3333H13.8333V20H7.16667V17.3333ZM26.1179 14.9975L29.5025 18.3822C29.9436 18.8233 29.9436 19.5364 29.5025 19.9764L21.6051 27.8739C21.4606 28.0195 21.2801 28.1221 21.0816 28.1718L16.5688 29.3C16.1964 29.3925 15.7858 29.2921 15.497 29.0022C15.2172 28.7224 15.1043 28.3162 15.2002 27.9315L16.3284 23.4186C16.3781 23.2211 16.4808 23.0395 16.6252 22.8951L24.5226 14.9975C24.9637 14.5564 25.6767 14.5564 26.1179 14.9975ZM17.8459 26.6543L20.2309 26.0586L23.725 22.5645L21.9357 20.7751L18.4416 24.2693L17.8459 26.6543ZM23.5309 19.1798L25.3202 20.9692L27.1096 19.1798L25.3202 17.3905L23.5309 19.1798Z" 
     fill={isSelected ? 'white' : '#9C9C9C'} 

     />


  </svg>

)

export default MyClaim