import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getContactNumber, getMetadata, isEmpty } from "../helper";
import { callCancelClaimApi } from "../api-helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import logger from "../../../logger.service";

function NoProceedPopup(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);
  const { setNoProceedPopup } = props;
  let FileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = FileClaimDetails.ServiceRequestResponse;
  const ServiceFeeResponse = FileClaimDetails.ChargeOrderResponse;
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];
  let loginDetails = workflowState["login"];
  let incidentResponse = FileClaimDetails.AssetDetails;

  const metadata = workflowState['config']?.metadata['NoProceedPopup']
  const homeplusscript = workflowState['config']?.scripts["NoProceedPopup"];

  const [isExpanded, setIsExpanded] = useState(true);

  let textHeader = findById(metadata, "titleHeader");
  let btnConfirm = findById(metadata, "btnConfirm");
  let btnGoBack = findById(metadata, "btnGoBack");

  const open = (isExpanded) => {
    setNoProceedPopup(false);
    setIsExpanded(!isExpanded);
  };

  textHeader.args = { ...textHeader.args, content: homeplusscript.titleHeader };

  const onConfirmClick = () => {
    cancelExistingSR(ServiceRequestResponse?.ServiceRequest?.ServiceRequestId);
  };

  const cancelExistingSR = (ServiceRequestId) => {
    showLoader(true);
    const CancelServiceRequestParams = {
      CancelServiceRequestParams: {
        ServiceRequestId: ServiceRequestId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        NoteText: "cancel",
        Operation: "Cancel",
        Reason: "Wish Not to Proceed",
        SubReason: "REPAIRREJECTED",
        CancelEntities: "ALL",
        RequestedBy: "Customer",
        ReturnEntity: true,
      },
    };

    callCancelClaimApi(
      ServiceRequestId,
      CancelServiceRequestParams,
      onCancelApiSuccess,
      onApiFailure
    );
  };

  const onCancelApiSuccess = (reason) => {
    showLoader(false);
    return completeCurrentStep(currentStep, {
      goToCancelMsg: true,
    });
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type, error, state: workflowState});
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label: homeplusscript.btnConfirm,
    enabled: true,
    onClick: (e) => onConfirmClick(e),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    enabled: true,
    onClick: () => open(isExpanded),
  };
  return <Stack orientation="vertical" metadata={metadata} />;
}
export default NoProceedPopup;