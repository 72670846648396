import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Stack } from "../stack/stack";
import { Button } from "../button/button";
import { EXPANDABLE_TYPE, INCIDENT_PATH_TYPE } from "../../utils/constants";
import Table from "../table/table";
import { ClaimLimit } from "../claim-limit/claim-limit";
import { ToggleQuestion } from "./questionList";
import { Text } from "../text/text";
import { ClaimCoverage } from "../claim-coverage/claim-coverage";
import { ClaimHistory } from "../claim-history/claim-history";

export const Expandable = ({
  expanded,
  setIsOpen,
  item,
  title,
  itemIconClasses,
  mainDivClasses,
  titleClasses,
  btnDisplayClasses,
  buttonDivClasses,
  btnFontClasses,
  onDeviceSelect,
  borderClass,
  toggleQue,
  incidentPathType,
  linkText,
  notEligibleText,
  onResumeClick,
  index,
  subIconClasses,
  isLoading,
  type,
  itemMainDivClasses,
  historyMainDivClasses,
  mainIconClasses,
  ...props
}) => {
  
  // const metadata=metadataa["ClaimForRepair"]
  // console.log(props.metadata)
  
  const [isOpen, setIsOpenn] = useState(index === 0 || expanded);
  const toggleExpanded = () => {
   if(!type) setIsOpenn(!isOpen);
   else setIsOpen(isOpen)
  };


  useEffect(() => {
    setIsOpenn(index === 0 || expanded);
  }, [expanded]);

  return (
    type && item.length < 1 ? "" :
    <div className={mainDivClasses}>
     <div
        className="flex flex-row items-center justify-between cursor-pointer"
        onClick={toggleExpanded}
      >
        <div className="flex flex-row">
         {!item?.expandableType === EXPANDABLE_TYPE.QUESTION_LISTING && <Icon className={itemIconClasses} icon={item?.icon || "TV"}></Icon>}
          <span className={titleClasses}>{title || item?.type}</span>
        </div>
        <span
          className={subIconClasses}
          onClick={toggleExpanded}
        >
          {!type ? !isOpen ? (
            <Icon
              className="fill-primary"
              icon="IconExpand" fill={"primary"}
            ></Icon>
          ) : (
            <Icon className="fill-primary" icon="IconCollapse" fill={"primary"}></Icon>
          ) : ""}
        </span>
      </div>

      {isOpen && (
        <>
          {item?.expandableType === EXPANDABLE_TYPE.DEVICE_LISTING && (
            <div>
            <Table
              {...{
                tableDivClasses: "my-4 overflow-auto w-full",
                tableTitleClasses:
                  "text-default text-white bg-primary py-3 px-6 w-full",
                tableClasses: "text-xl w-full",
                trClasses: "",
                thClasses:
                  "p-1 text-left border border-neutralN8 border-l-0 text-sm font-normal w-1/2",
                tdClasses:
                  "p-1 text-left border border-neutralN8 border-r-0 text-sm font-LatoBold w-1/2",
                tableHeaders: item?.tableHeaders,
                tableData: item?.tableData,
              }}
            />
            {incidentPathType === INCIDENT_PATH_TYPE.START_SERVICE_REQUEST &&
              <div className={buttonDivClasses}>
                <Button
                  displayClasses={btnDisplayClasses}
                  fontClasses={btnFontClasses}
                  label={item?.btnLabel}
                  enabled={true}
                  onClick={() => onDeviceSelect(item)}
                />
              </div>
            }
            {(incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST || incidentPathType === INCIDENT_PATH_TYPE.WAIT_FOR_HOLD ) &&
              <div className="mx-2 text-center">
                <Text
                  content = {linkText}
                  onLinkClick={() => onResumeClick(item)}
                />
              </div>
            }
            {incidentPathType === INCIDENT_PATH_TYPE.NOT_ELIGIBLE &&
              <div className="mx-2 text-center">
                <Text
                  content = {notEligibleText}
                />
              </div>
            }
            
            </div>
          )}
          {props?.expandableType === EXPANDABLE_TYPE.CLAIM_LIMIT && (
            <ClaimLimit policyInfo={item} metadata={props.metadata}/>
          )}
          {props?.expandableType === EXPANDABLE_TYPE.CLAIM_COVERAGE && (
            <ClaimCoverage/>
          )}
          {props?.expandableType === EXPANDABLE_TYPE.CLAIM_HISTORY && (
            <ClaimHistory myClaims={item} isLoading={isLoading} type={type} itemMainDivClasses={itemMainDivClasses} historyMainDivClasses={historyMainDivClasses} mainIconClasses={mainIconClasses}/>
          )}
          {props?.expandableType === EXPANDABLE_TYPE.QUESTION_LISTING && (
            <>
            <hr className={`${borderClass} border-solid`}/>
            {item.map(({subTitle,answer})=>{
              return <ToggleQuestion question={subTitle} answer={answer}/>
            })}
            </>
          )}
        </>
      )}
    </div>
  );
};

Expandable.propTypes = {
  /**
   * Is the control expand or collapse
   */
  expanded: PropTypes.bool,
  setIsOpen: PropTypes.func,
  item: PropTypes.object,
};

Expandable.defaultProps = {
  expanded: false,
  setIsOpen: undefined,
  item: {},
  titleClasses: "text-lg font-semibold text-neutralN2",
  mainDivClasses:
    "flex flex-col h-auto w-full p-3 bg-white border rounded shadow-sm border-neutralN8 cusror-pointer",
  buttonDivClasses: "flex justify-center text-sm leading-5 text-justify",
  btnFontClasses: "text-sm font-medium",
  borderClass:"mt-1 border-2 w-14 border-primary mb-5",
  subIconClasses:"inline-block mx-2 cursor-pointer"
};
