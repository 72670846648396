import { useContext, useState, useEffect } from "react";
import { Stack } from "../stack/stack";
import { findById, isEmpty, getFormmatedDate, getFormatedTime } from "./helper";
import { WorkflowContext } from "../workflow/workflow-context";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { DEVICE_CATEGORY, FULFILMENT_OPTION, REPAIR_STATUS } from "../../utils/constants";
import logger from "../../logger.service";
import Popup from "reactjs-popup";
import DeviceMismatchPopup from "./my-claim/deviceMismatchPopup";

function MyClaimForRepair() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["MyClaimForRepair"];
  const homeplusscript = workflowState["config"]?.scripts["MyClaimForRepair"];

  let FindAgreementResponse = workflowState["FindAgreementResponse"];
  const myClaimResponse = workflowState["MyClaimResponse"];
  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
  const isLaptopDesktop = 
  myClaimResponse &&  (myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Laptop || myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Desktop);
  const isDesktopp = myClaimResponse &&  myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Desktop;

  let expandableTitle = findById(metadata, "expandableTitle");
  let menu = findById(metadata, "menu");
  let claimRepair = findById(metadata, "claimRepair");
  let expandableClaimHistory = findById(metadata, "expandableClaimHistory");

  const [claimData, setClaimData] = useState({});
  const [claimDetailList, setClaimDetailList] = useState([]);
  const [claimHistory, setClaimHistory] = useState([]);
  const [isClaimHistoryLoading, setIsClaimHistoryLoading] = useState(false);
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 600px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  const statusMatrix = homeplusscript["statusMatrix"];
  const claimDescriptionMatrix = homeplusscript["claimDescriptionMatrix"];
  const repairRequestStatusMatrix = homeplusscript["repairRequestStatusMatrix"];

  const createFileClaim = (customerCase) => {
    return {
      fromMyClaimScreen: true,
      selectedCategory:
        customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Family,
      selectedDevice: {
        key: "",
        icon: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Family,
        expandableType: "",
        type: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Name?.split(
          "_"
        )?.join(" "),
        itemCount: "",
        btnLabel: "File a Claim",
        tableHeaders: [],
        tableData: [],
        AssetId: customerCase?.AssetID,
        AssetStatus: "",
        AssetInstance: "",
        StartDate: customerCase?.CreatedDate,
        CreatedDate: customerCase?.CreatedDate,
        MobileDeviceNumber:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.MDN,
        EquipmentActivationDate: "",
        AssetAttributes:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetAttributes,
        IMEI: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.IMEI,
        ClientAssetSkuNumber:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.ClientSKU,
        FulfillmentMethodType:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice
            ?.FulfillmentMethodType ||
          customerCase?.ServiceRequests?.[0]?.FulfillmentMethodType,
        AssetCatalog: {
          AssetCatalogId:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog
              ?.AssetCatalogId,
          AssetCatalogName:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog
              ?.Name,
        },
        Make: {
          MakeId:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.MakeId,
          Name: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.Name,
        },
        Model: {
          ModelId:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.ModelId,
          Name: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Name,
        },
        AssetCategory: {
          AssetCategoryId:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCategory
              ?.AssetCategoryId,
          AssetCategoryName:
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCategory
              ?.AssetCategoryName,
        },
      },
      AssetDetails: {
        AssetCatalogId:
          customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog
            ?.AssetCatalogId,
        AssetId: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetId,
      },
      incidentType: customerCase?.ServiceRequests?.[0]?.IncidentType,
      lossDescription: customerCase?.ServiceRequests?.[0]?.IncidentType,
      issueDetails: customerCase?.ServiceRequests?.[0]?.FailureDescriptiveText,
      ServiceRequestResponse: {
        CustomerCaseId: customerCase?.CustomerCaseId,
        CustomerCaseNumber: customerCase?.CustomerCaseNumber,
        CustomerCaseStatus: customerCase?.CustomerCaseStatus,
        ServiceRequest: {
          ServiceRequestId:
            customerCase?.ServiceRequests?.[0]?.ServiceRequestId,
          ServiceRequestNumber:
            customerCase?.ServiceRequests?.[0]?.ServiceRequestNumber,
          ServiceRequestStatus:
            customerCase?.ServiceRequests?.[0]?.ServiceRequestStatus,
          FulfillmentMethodType:
            customerCase?.ServiceRequests?.[0]?.FulfillmentMethodType ||
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice
              ?.FulfillmentMethodType,
          IsPickupWaive:
            customerCase?.ServiceRequests?.[0]?.IsPickupWaive,
          IsDeliveryWaive:
          customerCase?.ServiceRequests?.[0]?.IsDeliveryWaive
        },
      },
      ServiceOrderResponse: {
        ServiceOrderId:
          customerCase?.ServiceRequests?.[0]?.ServiceOrder?.ServiceOrderId,
        ServiceOrderNumber:
          customerCase?.ServiceRequests?.[0]?.ServiceOrder?.ServiceOrderNumber,
      },
      ccDetails: {
        cardType:
          customerCase?.ServiceRequests?.[0]?.ChargeOrder?.[0]
            ?.ChargeOrderCardBrand, //cardBrand
        cvv: customerCase?.ServiceRequests?.[0]?.ChargeOrder?.[0]
          ?.CardCheckNumber, //cardCheckNumber
      },
      RepairRequestResponse: customerCase?.ServiceRequests?.[0]?.RepairRequest,
      ChargeOrderResponse: customerCase?.ServiceRequests?.[0]?.ChargeOrder?.[0],
      ShippingOrderResponse: {
        ShippingOrder: customerCase?.ServiceRequests?.[0]?.ShippingOrder,
      },
      ProcessIncidentResponse: {
        Holds: customerCase?.holds,
      },
    };
  };

  const getClaimStatus = (customerCase) => {
    let claimStatus = customerCase?.CustomerCaseStatus;
    let resumeClaimstatus = customerCase?.ResumeStatus;
    let repairClaimStatus = customerCase?.RepairStatus;
    // console.log(claimStatus,resumeClaimstatus,repairClaimStatus);
    if (claimStatus?.toLowerCase()?.includes(homeplusscript?.labelCncl))
      return homeplusscript?.labelCanceled;
    if (claimStatus?.toLowerCase()?.includes(homeplusscript?.labelCmpltd))
      return homeplusscript?.labelCompleted;
    if (repairClaimStatus)
      if (
        repairClaimStatus?.toLowerCase() ===
        homeplusscript?.labelCompleted?.toLowerCase()
      )
        return homeplusscript?.labelCompleted;
    if (
      resumeClaimstatus === "Awaiting Payment Video Declaration" ||
      resumeClaimstatus === "Awaiting Shipping Address Video Declaration"
    )
      return repairRequestStatusMatrix?.[
        resumeClaimstatus?.split(" ")?.join("")?.toLowerCase()
      ];
    if (
      customerCase?.ServiceRequests?.[0]?.ServiceRequestStatus?.toLowerCase() ===
      homeplusscript?.labelHold
    )
      return repairRequestStatusMatrix?.hold;
    if (!resumeClaimstatus || repairClaimStatus) {
      let repairRequestStatusMatrixKey = repairClaimStatus
        ?.split(" ")
        ?.join("")
        ?.toLowerCase();
      return (
        repairRequestStatusMatrix[repairRequestStatusMatrixKey] ||
        repairRequestStatusMatrix?.other
      );
    } else
      return (
        repairRequestStatusMatrix[
          customerCase?.ResumeStatus?.split(" ")?.join("")?.toLowerCase()
        ] || repairRequestStatusMatrix?.other
      );
  };

  const getClaimDescription = (customerCase) => {
    let repairClaimStatus = customerCase?.RepairStatus;
    let resumeClaimstatus = customerCase?.ResumeStatus;

    if (
      resumeClaimstatus === "Awaiting Payment Video Declaration" ||
      resumeClaimstatus === "Awaiting Shipping Address Video Declaration"
    )
      return claimDescriptionMatrix?.[
        resumeClaimstatus?.split(" ")?.join("")?.toLowerCase()
      ];
    if (
      customerCase?.ServiceRequests?.[0]?.ServiceRequestStatus?.toLowerCase() ===
      "hold"
    )
      return claimDescriptionMatrix?.hold;
    if (repairClaimStatus) {
      return (
        claimDescriptionMatrix[
          repairClaimStatus?.split(" ")?.join("")?.toLowerCase()
        ] || claimDescriptionMatrix?.other
      );
    } else {
      return (
        claimDescriptionMatrix[
          customerCase?.ResumeStatus?.split(" ")?.join("")?.toLowerCase()
        ] || claimDescriptionMatrix?.other
      );
    }
  };

  const getOverageAmount = (customerCase) => {
    let ChargeOrder = customerCase?.ServiceRequests?.[0]?.ChargeOrder;
    let overageAmount = undefined;
    ChargeOrder.forEach((item) => {
      if (item?.ChargeOrderType === "OVG") {
        overageAmount = "$" + item?.TotalAmount;
      }
    });
    return overageAmount;
  };

  const getClaimDateDetails = (claimDate, newLine = false) => {
    if (claimDate) {
      let formattedDate = `${getFormmatedDate(claimDate, "dd MMM yyyy", " ")} ${
        newLine ? "<br/>" : ""
      } ${getFormatedTime(claimDate, true, "hh:mm:A", ":")}`;
      return formattedDate;
    }
  };

  const getClaimDetailsTableHeaders = (customerCase) => {
    const scheduleDateTime = [
      "deliveryschedulerequested",
      "scheduled",
      "deliveryscheduled",
    ];
    return [
      {
        key: "claimNumber",
        text: homeplusscript?.labelClaimNumber,
        hideForNoAction: false,
      },
      {
        key: "submissionDate",
        text: "Submission Date",
        hideForNoAction: false,
      },
      {
        key: "resolutionOffer",
        text: homeplusscript?.labelResolutionOffer,
        hideForNoAction: false,
      },
      {
        key: "resolutionDate",
        text: homeplusscript?.labelResolutionDate,
        hideForNoAction:
          customerCase?.CustomerCaseStatus?.toLowerCase()?.includes("cmpltd")
            ? false
            : true,
      },
      {
        key: "cancellationDate",
        text: homeplusscript?.labelCancellationDate,
        hideForNoAction:
          customerCase?.CustomerCaseStatus?.toLowerCase()?.includes("cncl")
            ? false
            : true,
      },
      {
        key: "replacementDevice",
        text: homeplusscript?.labelReplacementDevice,
        hideForNoAction: isEmpty(
          customerCase?.ServiceRequests?.[0]?.ReplacedDevice?.AssetCatalog?.Name
        )
          ? true
          : false,
      },
      {
        key: "overagePaid",
        text: homeplusscript?.labelOveragePaid,
        hideForNoAction: isEmpty(getOverageAmount(customerCase)) ? true : false,
      },
      {
        key: "scheduleDate",
        text: homeplusscript?.labelScheduleDate,
        // hideForNoAction: customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase() ==="deliveryschedulerequested" || customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase() ==="scheduled" ? false : true,
        hideForNoAction: scheduleDateTime.includes(
          customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase()
        )
          ? false
          : true,
      },
      {
        key: "scheduleTime",
        text: homeplusscript?.labelScheduleTime,
        // hideForNoAction: customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase() ==="deliveryschedulerequested" || customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase() ==="scheduled" ? false : true,
        hideForNoAction: scheduleDateTime.includes(
          customerCase?.RepairStatus?.split(" ")?.join("")?.toLowerCase()
        )
          ? false
          : true,
      },
      {
        key: "claimStatus",
        text: homeplusscript?.labelClaimStatus,
        statusTextColorClasses:
          getClaimStatus(customerCase) === "Completed"
            ? "text-primary"
            : "text-accentA3",
        hideForNoAction: false,
      },
      {
        key: "availableClaimLimit",
        text: homeplusscript?.labelAvailableClaimLimit,
        hideForNoAction:
          customerCase?.ResumeStatus?.split(" ")?.join("")?.toLowerCase() ===
          "awaitingcustomerselection"
            ? false
            : true,
      },
    ];
  };

  const getClaimDetailsTableData = (customerCase) => {
    let FulfillmentMethodType =
      customerCase?.ServiceRequests?.[0]?.FulfillmentMethodType ||
      customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.FulfillmentMethodType;
    const isRepair = FulfillmentMethodType === FULFILMENT_OPTION.REPAIR;
    return [
      {
        claimNumber: customerCase?.CustomerCaseNumber,
        submissionDate: getClaimDateDetails(customerCase?.CreatedDate, true),
        resolutionOffer:
          FulfillmentMethodType === FULFILMENT_OPTION.REPLACMENT
            ? homeplusscript?.labelReplacement
            : FulfillmentMethodType,
        claimStatus: getClaimStatus(customerCase),
        resolutionDate:
          customerCase?.CustomerCaseStatus?.toLowerCase()?.includes(
            homeplusscript?.labelCmpltd
          )
            ? getClaimDateDetails(
                customerCase?.ServiceRequests?.[0]?.ShippingOrderInbound
                  ?.ShippingDate ||
                  customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound
                    ?.ShippingDate
              )
            : undefined,
        cancellationDate:
          customerCase?.CustomerCaseStatus?.toLowerCase()?.includes(
            homeplusscript?.labelCncl
          )
            ? getClaimDateDetails(customerCase?.CustomerCaseCancellationDate)
            : undefined,
        replacementDevice:
          customerCase?.ServiceRequests?.[0]?.ReplacedDevice?.AssetCatalog
            ?.Name,
        overagePaid: getOverageAmount(customerCase),
        // scheduleDate: isRepair ? getClaimDateDetails(customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound?.ShippingDate) : getClaimDateDetails(customerCase?.ServiceRequests?.[0]?.ShippingOrderInbound?.ShippingDate || customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound?.ShippingDate),
        scheduleDate: getClaimDateDetails(
          customerCase?.ServiceRequests?.[0]?.ShippingOrderInbound
            ?.ShippingDate ||
            customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound
              ?.ShippingDate
        ),
        // scheduleTime: isRepair ? customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound?.DeliveryWindow : customerCase?.ServiceRequests?.[0]?.ShippingOrderInbound?.DeliveryWindow || customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound?.DeliveryWindow,
        scheduleTime:
          customerCase?.ServiceRequests?.[0]?.ShippingOrderInbound
            ?.DeliveryWindow ||
          customerCase?.ServiceRequests?.[0]?.ShippingOrderOutbound
            ?.DeliveryWindow,
        availableClaimLimit:
          "$" +
          Math.min(
            customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.ClaimLimit,
            agreement?.RemainingBenefit
          ),
      },
    ];
  };

  const getClaimDetails = (customerCase) => {
    let resumeStatus = customerCase?.ResumeStatus;
    let serviceRequestStatus = customerCase?.ServiceRequestStatus;
    let repairClaimStatus = customerCase?.RepairStatus;
    let statusKey = "";
    if(repairClaimStatus === REPAIR_STATUS.DEVICE_MISMATCH || repairClaimStatus === REPAIR_STATUS.PICKUP_FAILED || repairClaimStatus === REPAIR_STATUS.DELIVERY_FAILED){
      statusKey = repairClaimStatus?.split(" ")?.join("")?.toLowerCase();
    } else if (resumeStatus) {
      statusKey = resumeStatus?.split(" ")?.join("")?.toLowerCase();
    } else {
      statusKey = serviceRequestStatus?.split(" ")?.join("")?.toLowerCase();
    }
    // console.log("Resume :",customerCase?.ResumeStatus," Repair : ",customerCase?.RepairStatus," serviceRequest : ", customerCase?.ServiceRequestStatus);
    return (
      {
        icon: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Family || "TV",
        // title: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.Model?.Name || " ",
        title: customerCase?.ServiceRequests?.[0]?.EnrolledDevice?.AssetCatalog?.Name || " ",
        claimDetailsTableHeaders : getClaimDetailsTableHeaders(customerCase),         
        claimDetailsTableData : getClaimDetailsTableData(customerCase),
        description: getClaimDescription(customerCase),
        btnDisplayClasses: statusMatrix[statusKey] ? "block text-sm" : "hidden",
        // btnLabel: process.env.REACT_APP_IS_VIDEO_DECLARATION_AVAILABLE === "FALSE" && (statusKey === "awaitingpaymentvideodeclaration" || statusKey === "awaitingshippingaddressvideodeclaration") ? statusMatrix["hold"] : statusMatrix[statusKey],
        btnLabel: statusMatrix[statusKey],
        FileClaimDetails: createFileClaim(customerCase),
        showExpandableView:
          getClaimStatus(customerCase) === homeplusscript?.labelCanceled ||
          getClaimStatus(customerCase) === homeplusscript.labelCompleted
            ? false
            : true,
        expanded:
          getClaimStatus(customerCase) === homeplusscript?.labelCanceled ||
          getClaimStatus(customerCase) === homeplusscript.labelCompleted
            ? false
            : true,
        claimStatus: customerCase?.ResumeStatus || customerCase?.ServiceRequestStatus || "Not applicable",
        repairStatus: repairClaimStatus
      }
    );
  }

  const callClaimDetailsApi = async (ClientAccountId, AgreementId) => {
    API[ActionTypes.GET_CLAIM_DETAILS](ClientAccountId, AgreementId)
      .then((data) => {
        showLoader(false);
        setIsClaimHistoryLoading(false);
        let CustomerCases = data?.data?.CustomerCaseResults?.CustomerCases;
        let claimDetailList = [];
        updateState(
          {
            ...workflowState,
            MyClaimResponse: CustomerCases,
          },
          ["MyClaimResponse"]
        );

        CustomerCases.forEach((customerCase) => {
          let ClaimDetail = getClaimDetails(customerCase);
          claimDetailList.push(ClaimDetail);
        });

        const lastCustomerCase = CustomerCases?.[0];

        if (lastCustomerCase?.CustomerCaseStatus?.toLowerCase() === "working") {
          claimDetailList.length = 1;

          // for repair status pickup/delivery failed call service fee api
          if (
            (lastCustomerCase?.RepairStatus === REPAIR_STATUS.PICKUP_FAILED &&
              !lastCustomerCase?.ServiceRequests?.[0]?.IsPickupWaive) ||
            (lastCustomerCase?.RepairStatus === REPAIR_STATUS.DELIVERY_FAILED &&
              !lastCustomerCase?.ServiceRequests?.[0]?.IsDeliveryWaive)
          ) {
            // call service fee api here
            const serviceRequest = lastCustomerCase?.ServiceRequests?.[0];
            callServiceFeeApi(
              serviceRequest?.ServiceRequestId,
              serviceRequest?.EnrolledDevice?.AssetCategory?.AssetCategoryId,
              serviceRequest?.EnrolledDevice?.Make?.MakeId,
              true,
              claimDetailList
            );
          } else
             setClaimDetailList(claimDetailList);
        } else claimDetailList.length = 0;

        let AssetModel =
          CustomerCases &&
          CustomerCases[0]?.ServiceRequests[0]?.EnrolledDevice?.Model?.Name?.split(
            "_"
          );

        let assetModelSrNo =
          CustomerCases &&
          CustomerCases[0]?.ServiceRequests[0]?.EnrolledDevice?.IMEI;

        let assetModelModelNo =
          CustomerCases &&
          (CustomerCases[0]?.ServiceRequests[0]?.EnrolledDevice?.ClientSKU ||
            "");

        //  device mismatch Details

        let mismatchAssetModel =
          CustomerCases &&
          (CustomerCases[0]?.ServiceRequests[0]?.MismatchAssetModel?.split(
            "_"
          ) ||
            []);

        let misMatchSrNo =
          CustomerCases &&
          CustomerCases[0]?.ServiceRequests[0]?.MismatchSerialNumber;

        let misMatchModelNo =
          CustomerCases &&
          CustomerCases[0]?.ServiceRequests[0]?.MismatchModelNo;

        !isEmpty(assetModelSrNo) &&
          !isEmpty(misMatchSrNo) &&
          AssetModel.push(assetModelSrNo);

        !isEmpty(misMatchModelNo) && AssetModel.push(assetModelModelNo);

        !isEmpty(misMatchSrNo) && mismatchAssetModel.push(misMatchSrNo);
        !isEmpty(misMatchModelNo) && mismatchAssetModel.push(misMatchModelNo);

        console.log("AssetModel:", AssetModel);
        console.log("mismatchAssetModel:", mismatchAssetModel);
        !isEmpty(mismatchAssetModel) &&
          compareAssetDetails(AssetModel, mismatchAssetModel);
        prepareClaimHistoryData(CustomerCases);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        setIsClaimHistoryLoading(false);
        logger({
          type: ActionTypes.GET_CLAIM_DETAILS,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
      });
  };
  const getClaimDate = (claimDate) => {
    if (claimDate) {
      let formattedDate = getFormmatedDate(claimDate, "dd MMM yyyy", " ");
      return formattedDate;
    }
  };
  const prepareClaimHistoryData = (CustomerCases) => {
    // CustomerCases[0]?.CustomerCaseStatus?.toLowerCase() === "working" && CustomerCases.shift()
    let claimHistoryData;
    if (CustomerCases[0]?.CustomerCaseStatus?.toLowerCase() === "working") {
      claimHistoryData = CustomerCases?.slice(1);
    } else {
      claimHistoryData = CustomerCases;
    }
    let claimList = [];
    claimHistoryData.forEach((claim) => {
      console.log("date:", claim?.CustomerCaseStatus);
      let claimData = {
        date:
          getClaimDate(claim?.CustomerCaseCancellationDate) ||
          getClaimDateDetails(
            claim?.ServiceRequests?.[0]?.ShippingOrderInbound?.ShippingDate ||
              claim?.ServiceRequests?.[0]?.ShippingOrderOutbound?.ShippingDate
          ),
        claimNo: claim?.CustomerCaseNumber,
        limitUsed: claim?.UsedClaimLimit ? `$ ${claim?.UsedClaimLimit}` : "",
        claimStatus: getClaimStatus(claim),
        tableHeaders: getClaimDetailsTableHeaders(claim),
        tableData: getClaimDetailsTableData(claim, agreement),
      };
      claimList.push(claimData);
    });
    setClaimHistory(claimList);
  };

  useEffect(() => {
    showLoader(true);
    setIsClaimHistoryLoading(true);
    const ClientAccountId = agreement?.ClientAccount?.ClientAccountId;
    // 1C4D0D88EBD411ECBEFC06D55EEA719D  - 1C4D13C9EBD411ECBEFC06D55EEA719D
    callClaimDetailsApi(ClientAccountId, agreement?.AgreementId);
  }, []);

  const onResumeClaimClick = (val) => {
    // callDetermineIncident();

    let claimSatus = val.claimStatus.split(" ").join("").toLowerCase();
    let repairStatus = val?.repairStatus?.split(" ")?.join("")?.toLowerCase();
    updateState({
      ...workflowState,
      FileClaimDetails: val.FileClaimDetails,
    });
    if (claimSatus === "awaitingincidentdetails") {
      return completeCurrentStep(currentStep, {
        showIncidentDetails: true,
      });
    } else if (claimSatus === "awaitingtriagedetails") {
      return completeCurrentStep(currentStep, {
        // showTriageQuestion: true,
        showIssueDetails: true,
      });
    } else if (claimSatus === "awaitingshippingaddress") {
      return completeCurrentStep(currentStep, {
        showConfirmAddress: true,
      });
    } else if (claimSatus === "awaitingpickupschedule" || repairStatus === "pickupfailed") {
      return completeCurrentStep(currentStep, {
        showScheduleOnsiteDate: true,
      });
    } else if (claimSatus === "awaitingcustomerselection") {
      return completeCurrentStep(currentStep, {
        showReplacementOption: true,
      });
    } else if (claimSatus === "awaitingdeliveryschedule" || repairStatus === "deliveryfailed") {
      return completeCurrentStep(currentStep, {
        goToScheduleDelivery: true,
      });
    } else if (claimSatus === "awaitingpayment") {
      return completeCurrentStep(currentStep, {
        ShowTermsPage: true,
      });
    } else if (
      claimSatus === "hold" ||
      claimSatus === "awaitingshippingaddressholdresolution" ||
      claimSatus === "awaitingpaymentholdresolution"
    ) {
      setCancelClaimPopup(true);
    } else if (
      /*process.env.REACT_APP_IS_VIDEO_DECLARATION_AVAILABLE === "TRUE" &&*/ claimSatus ===
        "awaitingpaymentvideodeclaration" ||
      claimSatus === "awaitingshippingaddressvideodeclaration"
    ) {
      return completeCurrentStep(currentStep, {
        askVideoDeclaration: true,
      });
    } /*else if (process.env.REACT_APP_IS_VIDEO_DECLARATION_AVAILABLE === "FALSE" && (claimSatus === "awaitingpaymentvideodeclaration" || claimSatus === "awaitingshippingaddressvideodeclaration")) {
      setCancelClaimPopup(true);
    }*/ else if (claimSatus === "devicemismatch") {
      setOpenPopup(true);
    }
  };

  const onMenuItemClick = (redirect) => {
    refreshStateOnLogout(workflowState, redirect);
  };

  const goToDashboard = () => {
    // clear all device reg data
    updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  const onBtnClick = (val) => {
    setClaimData({ ...claimData, ...val });
    onResumeClaimClick(val);
  };

  const compareAssetDetails = (AssetModel, mismatchAssetModel) => {
    let keys = [
      "Make",
      "Category",
      ...(isLaptopDesktop ? ["Processor"] : ["Model"]),
      ...(isLaptopDesktop ? ["RAM"] : ["Capacity"]),
      ...(isLaptopDesktop ? ["Storage Size"] : ["Connectivity"]),
      ...(isLaptopDesktop ? ["Storage Type"] : []),
      ...(isDesktopp ? [] : ["Serial No"]),
      "Model No",
    ];

    let assetModelObj = {};
    let mismatchDataObj = {};
    let DeviceMismatchPopupData = [];

    for (var i = 0; i < keys.length; i++) {
      assetModelObj[keys[i]] = AssetModel[i];
      mismatchDataObj[keys[i]] = mismatchAssetModel[i];
    }
    let difference = Object.keys(assetModelObj).filter(
      (k) =>
        assetModelObj[k]?.toLowerCase()?.replace(/\s/g, "") !==
        mismatchDataObj[k]?.toLowerCase()?.replace(/\s/g, "")
    );
    console.log("difference:", difference);
    difference.forEach((val) => {
      return DeviceMismatchPopupData.push({
        spec: val,
        providedSpec: assetModelObj[val],
        actualSpec: mismatchDataObj[val],
      });
    });
    updateState(
      {
        ...workflowState,
        deviceMismatchPopupData: DeviceMismatchPopupData,
      },
      ["deviceMismatchPopupData"]
    );
    console.log("data", DeviceMismatchPopupData);
    console.log("assetModelObj", assetModelObj);
    console.log("mismatchDataObj", mismatchDataObj);
  };

  const callServiceFeeApi = (ServiceRequestId, AssetCategoryId, MakeId, IsRedeliveryFee, claimDetailList) => {
    showLoader(true);

    API[ActionTypes.GET_SERVICE_FEE](
      ServiceRequestId,
      AssetCategoryId,
      MakeId,
      IsRedeliveryFee
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            ServiceFeeResponse: data.data.ServiceFee,
          },
        }, ["FileClaimDetails"]);

        updateState({
          ...workflowState,
          BraintreeDetails: {
            ServiceFeeResponse: data.data.ServiceFee,
          },
        }, ["BraintreeDetails"]);

        // set fee 
        let claims = claimDetailList.map((claimDetail, index) => {
          if(index === 0){
            let newDesc = claimDetail.description.replace("0", data?.data?.ServiceFee?.TotalAmount);
            claimDetail.description = newDesc;
          }
          return claimDetail;
        })

        setClaimDetailList(claims);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_SERVICE_FEE, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  claimRepair.args = {
    ...claimRepair.args,
    options: claimDetailList,
    value: claimData?.value,
    onClick: onBtnClick,
    isLoading: isClaimHistoryLoading,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    onClose: (e) => goToDashboard(),
    onMenuItemClick: (e) => onMenuItemClick(e),
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  expandableClaimHistory.args = {
    ...expandableClaimHistory.args,
    item: claimHistory,
    type: "myClaimForRepair",
  };

  const contentStyle = {
    padding: "15px",
    width: "600px",
    height: isDesktop ? "500px" : "auto",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    borderRadius: "8px",
    borderColor: "",
    textColor: "",
  };

  return (
    <>
      <Popup
        contentStyle={contentStyle}
        open={openPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <DeviceMismatchPopup
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          setCancelClaimPopup={setCancelClaimPopup}
          setClaimDetailList={setClaimDetailList}
          setIsClaimHistoryLoading={setIsClaimHistoryLoading}
          getClaimDetails={getClaimDetails}
          prepareClaimHistoryData={prepareClaimHistoryData}
        />
      </Popup>
      <Stack orientation="vertical" metadata={metadata} />;
    </>
  );
}

export default MyClaimForRepair;
