import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text } from "../text/text";
import Icon from "../icon/icon";

export const PolicyDocuments = ({
  subLabelClasses,
  mainDivClass,
  icon,
  subIconClasses,
  fontClass,
  item,
  labelFontClasses,
  subDivClasses,
  borderClasses,
  label,
  iconHeader,
  expanded,
  ...props
}) => {
  
  const handleClick = () => {
   if(item.documentType === "groupPolicy") window.open("coverage-summary.html")
   if(item.documentType === "InsSchedule") window.open(item?.url)
 } 
 
  return (
    <>
    <div className={mainDivClass} onClick={(e)=>handleClick()}>
      <span className={subLabelClasses}>
      <Icon icon={icon} className={subIconClasses}></Icon>
     <span className={subDivClasses}>
      <span className="capitalize"><Text content={item.label} other={labelFontClasses}></Text></span>
      <Text content={item.fileDetails} other={fontClass}></Text>
     </span>
      </span>
     <Text content={item.date} other={`mt-2.5 ${fontClass}`}></Text>
    </div>
    <span className={borderClasses}></span>
    </>
  );
};

PolicyDocuments.propTypes = {
  txtlabel: PropTypes.string,
  icon: PropTypes.string,
  labelClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  item: PropTypes.object
};

PolicyDocuments.defaultProps = {
    icon:"File",
    subLabelClasses:"flex",
    subIconClasses:"my-2.5",
    mainDivClass:"flex justify-between",
    fontClass:"text-xs font-LatoRegular text-neutralN5",
    labelFontClasses:"mb-0.5",
    subDivClasses:"flex flex-col mx-4",
    borderClasses:"border w-full border-neutralN8 my-2.5",
    item:{},
    label: "Policy",
    iconHeader:"Folder"
};
