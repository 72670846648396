// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
/constructor/i.test(window.HTMLElement) ||
(function (p) {
  return p.toString() === "[object SafariRemoteNotification]";
})(
  !window["safari"] ||
    (typeof safari !== "undefined" && window["safari"].pushNotification)
);

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
const isChrome =
!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
const browser = {
isSafari,
isIE,
isEdge,
isChrome,
isEdgeChromium,
};


const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform || navigator.userAgentData.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "MACOS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "IOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "WINDOWS";
    } else if (/Android/.test(userAgent)) {
      os = "ANDROID";
    } else if (!os && /Linux/.test(platform)) {
      os = "LINUX";
    }

    return os;
  };

  export const getMIMEType = () => {
    const OS = getOS();
    console.log("OS:",OS)
    if (["MACOS"].includes(OS) && !browser.isSafari) {
      return "video/webm";
    } else if (["MACOS", "IOS"].includes(OS)) {
      return "video/mp4";
    } else {
      return "video/webm";
    }
  };

  export const getVideoFileExt = () => {
    const OS = getOS();
    console.log("OS:",OS)
    if (["MACOS"].includes(OS) && !browser.isSafari) {
      return "webm";
    } else if (["MACOS", "IOS"].includes(OS)) {
      return "mp4";
    } else {
      return "webm";
    }
  };

  // polyfill based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
(function polyfillGetUserMedia() {
  if (typeof window === 'undefined') {
    return;
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function(constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        console.log('getUserMedia is not implemented in this browser');
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function(resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
})();

export function hasGetUserMedia() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}