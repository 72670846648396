import React from "react";
import PropTypes from "prop-types";
import InfoIcon from  "../../icons/ic_info_blue";
import { Text } from "../text/text";

const sizemap = {
  xs: "xs",
  sm: "sm",
  md: "base",
  lg: "lg",
  xl: "xl",
  "3xl": "3xl",
  "2xl": "2xl",
  "5xl": "5xl",
};

const stylemap = {
  highlighted: "font-bold text-primary",
  supressed: "text-white",
  normal: "text-neutral",
  semibold: "font-semibold",
  bold: "font-bold",
  error: "text-xs text-red-600",
};

export const Info = ({
  style,
  size,
  wrap,
  content,
  colorClasses,
  justification,
  marginClasses,
  paddingClasses,
  displayClasses,
  other,
  onClick,
  borderClasses,
  infoDivClasses,
  showTooltip,
  dimensionClasses,
  iconClasses,
  ...props
}) => {
  let fontSize = `text-${sizemap[size]}`;
  return (
    <>
      <div
        className={`${infoDivClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses} ${displayClasses} ${other}`}
      >
        <div className={iconClasses}>
          <InfoIcon/>
        </div>
        <Text marginClasses={"ml-2"} colorClasses={colorClasses} content={content} other={fontSize}></Text>
      </div>
    </>
  );
};

Info.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf([
    "highlighted",
    "supressed",
    "normal",
    "bold",
    "error",
  ]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "5xl"]),

  /**
   * Text contents
   */
  content: PropTypes.string.isRequired,
};

Info.defaultProps = {
  style: "normal",
  size: "sm",
  content: "This is text content",
  infoDivClasses: "flex flex-row",
  colorClasses: "bg-accentA8 text-secondaryS4",
  showTooltip: true,
  borderClasses:
    "border border-neutralN8 focus:outline-none rounded-md focus:border-primary focus:shadow-sm",
  paddingClasses: "p-2",
  other: "text-xs md:text-sm",
  marginClasses: "mt-2",
  content: `Provide the purchase price of your device.`,
  iconClasses:"mt-0.5"
};
