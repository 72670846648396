import React from 'react'
import PropTypes from 'prop-types'

const sizemap = {
  xs: 'xs',
  sm: 'sm',
  md: 'base',
  lg: 'lg',
  '2xl': '2xl'
}
export const Link = ({
  onClick,
  enabled,
  showUnderline,
  label,
  size,
  color,
  marginClasses,
  justification,
  other,
  ...props
}) => {
  const underline = showUnderline ? 'underline' : 'no-underline'
  const fontSize = `text-${sizemap[size]}`
  const cursor = enabled
    ? 'cursor-pointer'
    : 'pointer-events-none cursor-not-allowed'
  return (
    <span
      className={`${underline} ${fontSize} ${cursor} ${color} ${marginClasses} ${justification} ${other}`}
      disabled={!enabled}
      onClick={onClick}
    >
      {label}
    </span>
  )
}

Link.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  showUnderline: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', '2xl']),
  color: PropTypes.string
}

Link.defaultProps = {
  enabled: true,
  showUnderline: true,
  onClick: undefined,
  label: 'link',
  size: 'md',
  color: 'text-accent'
}
