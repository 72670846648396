import React, {useContext, useState } from "react";
import { Text } from "../text/text";
import Icon from "../icon/icon";
import IconClose from "../../icons/ic_close";
import {Config} from "../enrollment-workflow/config";
import { WorkflowContext } from "../workflow/workflow-context";
import { updateScrollAction } from "../enrollment-workflow/helper";
import ActionTypes from "../../ActionTypes";
import API from "../../services";

const Sidebar = (props) => {
  const {
    className,
    openMenuPopUp = false,
    setOpenMenuPopup,
    onMenuItemClick
  } = props;
  
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const [isExpanded, setIsExpanded] = useState(true);

  const isMobile = window.innerWidth < 1279;
   
  const open = (isExpanded) => {
    console.log(`close clicked`);
    // if (isMobile) {
    setOpenMenuPopup(false);
    // }
    setIsExpanded(!isExpanded);
 
    updateScrollAction('scroll')

  };

  const getNotificationCount = () => {
    const myClaimResponse = workflowState?.["MyClaimResponse"]
    const claimStatus = getResumeClaimStatus(myClaimResponse?.[0])

    return claimStatus ? "1" : ""
  }

  const getResumeClaimStatus = (customerCase) => {

    const homeplusscript = workflowState["config"]?.scripts["Dashboard"];

    const statusMatrix = homeplusscript["statusMatrix"];
    const claimDescriptionMatrix = homeplusscript["claimDescriptionMatrix"];

    let resumeStatus = customerCase?.ResumeStatus;
    let statusKey = "";
    if (resumeStatus) {
      statusKey = resumeStatus?.split(" ")?.join("")?.toLowerCase();
    }

    return statusMatrix[statusKey] ? claimDescriptionMatrix[statusKey] : "";
  };

  const getIpAddress = async () => {
    return API[ActionTypes.GET_IP_ADD]()
      .then((response) => response?.data?.ip || "110.164.222.226")
      .catch(() => "110.164.222.226");
     }

  const securityAdvisorToken = async () => {
    // close menu
    open(isExpanded);
    showLoader(true)
    const [ipAddress] = await Promise.all([getIpAddress()]);
    const getTokenRequest = {
      getTokenRequest: {
        email: workflowState["login"]?.email,
        ua: navigator.userAgent,
        ip: ipAddress || "110.164.222.226",
        client: "Starhub HomePlus",
      },
    };
    API[ActionTypes.GET_SECURITY_ADVISOR_TOKEN_REQUEST](getTokenRequest)
      .then((data) => {
         showLoader(false)
        sessionStorage.setItem(
          "token",
          data.data.token
        );
        updateState({
          ...workflowState,
          token: data?.data?.token,
        },["token"]);

        // redirect now to security advisor
        window.open(Config.service.securityAdvisor)
      })
      .catch((error) => {
        showLoader(false)
        console.log("SecurityAdvisorError:", error);
      });
  };

  const menuItemIconStyle = "self-center mr-2 fill-neutral group-hover:fill-link group-active:bg-light";
  const menuList = [
    {
      icon : "Union",
      label:"Dashboard",
      redirect : "dashboard",
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "Chat",
      label:"Tech Support",
      redirect : "chat",
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "SecurityAdvisor",
      label:"Security Advisor",
      // redirect:Config.service.securityAdvisor,
      // onClick : (e)=>window.open(`${Config.service.securityAdvisor}/?jwt=${workflowState.token}`)
      onClick : (e)=>{!workflowState.token ? securityAdvisorToken() : window.open(`${Config.service.securityAdvisor}`)}
    },
    {
      icon : "Transaction",
      label:"My Claims",
      redirect : "my-claim-for-repair",
      notification: getNotificationCount(),
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "Profile",
      label:"My Profile",
      redirect : "myprofile",
      className : 'self-center mr-2 stroke-neutral group-hover:stroke-link group-active:bg-light',
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "Documents",
      label:"My Documents",
      redirect : "mydocuments",
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "FAQ",
      label:"FAQ",
      redirect : "faq",
      onClick : e => {onMenuItemClick(e);}
    },
    {
      icon : "Logout",
      label:"Logout",
      redirect : "login",
      onClick : e => {onMenuItemClick(e);}
    },
  ]

  return (
    <div
      className={`${className} flex flex-col h-full shadow-colorbox rounded-r-none bg-white my-auto xl:max-h-650 xl:overflow-y-auto lg:rounded-b-2xl`}
    >
      <div className="flex flex-col justify-center w-56 px-4 py-5 xl:w-220 2xl:w-170">
        <div className="right-0 flex flex-row items-center justify-between mb-4">
          <Text content="Menu" size="lg"></Text>
          <IconClose className={'cursor-pointer'} onClick={() => open(isExpanded)} />
        </div>

        {
          menuList.map((item)=>{
            return (
              <li className={`group flex mb-4 pl-2 py-2 cursor-pointer hover:bg-light active:bg-light`}  title={item.label} onClick = {e => item?.onClick(item?.redirect)}>
              <Icon className={item.className ? item.className : menuItemIconStyle} icon={item.icon} ></Icon>
                <span className="w-full font-normal group-hover:text-link group-active:text-link">{item.label}</span>
                <div className={item.notification ? "block" : "hidden"}>
                  <span className="bg-primary text-white text-center h-auto p-0.5 mr-2 text-xs">{"1"}</span>
                </div>
              </li>
            )
          })
        }
      </div> 
    </div>
  );
};

export default Sidebar;
