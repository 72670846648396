/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
<svg  className={className} viewBox="0 0 151 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M110.36 21.36C108.71 20.325 103.97 21.36 104.735 26.55C105.515 31.74 99.4101 30.405 100.745 36.45C102.08 42.495 112.88 51.45 119.54 45.36C126.2 39.27 120.59 36.585 120.71 29.37C120.8 25.08 118.85 17.385 110.36 21.375V21.36Z" fill="black"/>
<path d="M120.155 45.09C120.155 45.09 120.095 45.09 120.08 45.06C120.035 45.015 120.035 44.955 120.08 44.91C124.175 40.83 123.23 38.355 122.03 35.22C121.37 33.495 120.62 31.515 120.545 28.965C120.485 26.205 119.48 22.44 116.855 20.895C114.995 19.8 112.61 19.98 109.76 21.465C109.7 21.495 109.64 21.465 109.61 21.42C109.58 21.375 109.61 21.3 109.655 21.27C112.58 19.755 115.025 19.56 116.96 20.715C119.66 22.32 120.695 26.16 120.77 28.965C120.83 31.485 121.58 33.435 122.225 35.145C123.455 38.355 124.415 40.905 120.215 45.09C120.2 45.105 120.17 45.12 120.14 45.105L120.155 45.09Z" fill="black"/>
<path d="M121.115 44.5051C121.115 44.5051 121.19 44.6101 121.235 44.6701L121.415 44.9101C121.535 45.0751 121.67 45.2251 121.82 45.3901C122.105 45.7201 122.42 46.0201 122.75 46.3201C123.425 46.9351 124.145 47.4901 124.91 47.9701C125.69 48.4651 126.5 48.9151 127.34 49.3051C127.775 49.5151 128.21 49.6801 128.63 49.8751C128.705 49.9201 128.63 49.8751 128.57 49.9051C128.51 49.9351 128.465 49.9651 128.51 49.9501C128.72 49.7701 128.885 49.5451 129.005 49.2901C129.2 48.9151 129.365 48.5101 129.5 48.1051C129.785 47.2051 130.025 46.2901 130.205 45.3451C130.4 44.4001 130.55 43.4251 130.685 42.4351C130.82 41.4451 130.94 40.4551 131.045 39.4801H132.8C132.95 41.5501 132.935 43.6201 132.755 45.6901C132.68 46.7551 132.515 47.8201 132.26 48.8701C132.125 49.4401 131.945 49.9951 131.72 50.5351C131.45 51.2251 131.03 51.8401 130.49 52.3351C130.085 52.6801 129.605 52.9201 129.08 53.0101C128.57 53.1001 128.03 53.0551 127.535 52.8751C127.025 52.6951 126.515 52.5451 126.02 52.3351C125 51.9301 124.01 51.4651 123.065 50.9101C122.105 50.3401 121.205 49.6801 120.35 48.9601C119.915 48.5851 119.525 48.1801 119.135 47.7451C118.94 47.5351 118.745 47.3101 118.565 47.0551L118.295 46.6801C118.205 46.5301 118.13 46.4401 117.995 46.2001L121.085 44.5051H121.115Z" fill="#FFC3BD"/>
<path d="M120.515 42.9C124.55 45.495 124.235 47.07 124.235 47.07L121.7 50.76C121.7 50.76 115.22 48.165 114.815 46.125C114.395 43.995 118.565 41.655 120.53 42.9H120.515Z" fill="#263238"/>
<path d="M120.515 42.9C124.55 45.495 124.235 47.07 124.235 47.07L121.7 50.76C121.7 50.76 115.22 48.165 114.815 46.125C114.395 43.995 118.565 41.655 120.53 42.9H120.515Z" fill="#500878"/>
<path d="M124.475 46.71C124.625 46.815 124.67 47.04 124.565 47.19L121.97 50.91C121.865 51.06 121.655 51.105 121.49 51C121.34 50.88 121.295 50.67 121.4 50.505L123.995 46.785C124.1 46.635 124.325 46.59 124.475 46.695V46.71Z" fill="#E63888"/>
<path d="M131.315 40.335L129.305 37.65L133.16 36.66C133.16 36.66 133.94 39.045 132.665 40.8L131.315 40.335Z" fill="#FFC3BD"/>
<path d="M129.425 34.3651L132.62 33.8251L133.16 36.6601L129.32 37.6501L129.425 34.3651Z" fill="#FFC3BD"/>
<path d="M96.8301 138.78L100.49 139.125L103.115 130.515L99.4401 130.155L96.8301 138.78Z" fill="#FFC3BD"/>
<path d="M73.5051 126.03L76.2201 128.58L82.1001 122.13L79.3701 119.58L73.5051 126.03Z" fill="#FFC3BD"/>
<path d="M77.48 127.365L75.44 123.69C75.365 123.555 75.215 123.495 75.08 123.555L71.93 124.755C71.63 124.875 71.48 125.235 71.6 125.535C71.6 125.565 71.63 125.595 71.645 125.61C72.38 126.885 72.8 127.455 73.7 129.075C74.255 130.065 75.335 132.36 76.1 133.725C76.865 135.09 78.26 134.415 78.065 133.77C77.195 130.89 77.36 129.3 77.6 128.025C77.645 127.8 77.6 127.56 77.495 127.35L77.48 127.365Z" fill="#500878"/>
<path d="M100.925 138.015H96.4701C96.3051 138.015 96.1701 138.12 96.1251 138.285L95.1501 141.81C95.0751 142.11 95.2401 142.41 95.5401 142.5C95.5851 142.5 95.6451 142.515 95.6901 142.515H105.935C107.555 142.515 107.9 140.865 107.225 140.73C104.195 140.07 102.47 139.14 101.54 138.27C101.375 138.105 101.135 138.03 100.91 138.03L100.925 138.015Z" fill="#500878"/>
<path d="M120.515 42.9001C120.515 42.9001 121.985 43.9051 112.67 64.1401L98 60.0751C99.905 54.1651 100.925 50.4901 101.465 37.4551C103.64 37.6051 105.8 37.9051 107.93 38.3551C110.195 38.8051 112.415 39.4351 114.59 40.2001C116.615 40.9951 118.595 41.8951 120.53 42.9001H120.515Z" fill="#263238"/>
<path d="M120.515 42.9001C120.515 42.9001 121.985 43.9051 112.67 64.1401L98 60.0751C99.905 54.1651 100.925 50.4901 101.465 37.4551C103.64 37.6051 105.8 37.9051 107.93 38.3551C110.195 38.8051 112.415 39.4351 114.59 40.2001C116.615 40.9951 118.595 41.8951 120.53 42.9001H120.515Z" fill="#500878"/>
<path d="M110.66 31.05C110.48 33.315 109.82 37.35 107.93 38.37C107.93 38.37 106.835 42.12 111.605 43.5C115.655 44.67 114.59 40.215 114.59 40.215C112.355 38.985 113.72 36.795 114.155 34.35L110.66 31.05Z" fill="#FFC3BD"/>
<path d="M110.645 31.05C110.51 32.865 110.06 35.835 108.89 37.455C110.24 37.59 112.355 37.185 112.97 36.165C113.285 35.445 113.525 34.71 113.72 33.96L110.645 31.065V31.05Z" fill="#ED847E"/>
<path d="M116.6 27.7651C116.6 31.4851 116.66 33.0601 114.905 35.0701C112.25 38.1001 107.69 37.2751 106.58 33.6151C105.59 30.3301 105.77 24.8101 109.31 23.1151C111.83 21.9001 114.86 22.9501 116.075 25.4701C116.42 26.1751 116.585 26.9551 116.585 27.7501L116.6 27.7651Z" fill="#FFC3BD"/>
<path d="M108.92 25.7101C111.005 28.6351 118.04 32.6851 118.505 28.7701C118.97 24.8551 115.385 20.9851 111.83 21.3751C108.275 21.7651 107.135 23.2201 108.92 25.7101Z" fill="black"/>
<path d="M118.13 31.2451C117.815 31.8901 117.245 32.3851 116.57 32.6101C115.67 32.9101 115.175 32.1151 115.385 31.2301C115.58 30.4351 116.285 29.3251 117.215 29.4151C117.875 29.4901 118.37 30.0751 118.295 30.7501C118.28 30.9151 118.22 31.0951 118.13 31.2301V31.2451Z" fill="#FFC3BD"/>
<path d="M112.67 64.1551C112.67 64.1551 117.89 85.3051 114.47 101.31C112.175 112.02 103.895 133.68 103.895 133.68L97.415 133.515C97.415 133.515 103.685 110.79 104.975 101.865C107.195 86.5801 101.915 61.1851 101.915 61.1851L112.67 64.1551Z" fill="#250E62"/>
<path opacity="0.2" d="M106.85 67.1851L103.61 72.2851C104.585 78.3301 105.185 84.4351 105.44 90.5551C110 82.4101 107.855 71.5051 106.835 67.1851H106.85Z" fill="black"/>
<path d="M105.065 133.995L96.8301 133.8L97.3851 130.86L105.665 131.49L105.065 133.995Z" fill="#685BC7"/>
<path d="M109.46 63.3001C109.46 63.3001 106.43 86.4301 99.9201 99.5701C94.6551 110.19 80.5851 125.295 80.5851 125.295L76.9851 121.59C76.9851 121.59 86.4951 105.165 90.3801 98.1151C99.9801 80.8201 91.4751 65.3251 97.9851 60.0751L109.445 63.3001H109.46Z" fill="#250E62"/>
<path d="M80.9451 125.79L76.3101 121.26L77.8551 119.175L82.9101 124.395L80.9451 125.79Z" fill="#685BC7"/>
<path d="M98.0301 59.16L97.0101 60.3C96.9351 60.39 97.0101 60.525 97.1901 60.57L112.58 64.83C112.715 64.86 112.835 64.83 112.865 64.755L113.36 63.465C113.39 63.375 113.3 63.27 113.15 63.225L98.3001 59.115C98.2101 59.085 98.1051 59.115 98.0301 59.175V59.16Z" fill="#1ED760"/>
<path d="M98.0301 59.16L97.0101 60.3C96.9351 60.39 97.0101 60.525 97.1901 60.57L112.58 64.83C112.715 64.86 112.835 64.83 112.865 64.755L113.36 63.465C113.39 63.375 113.3 63.27 113.15 63.225L98.3001 59.115C98.2101 59.085 98.1051 59.115 98.0301 59.175V59.16Z" fill="#E63888"/>
<path d="M99.56 61.3801L99.155 61.2751C99.08 61.2601 99.035 61.2001 99.05 61.1401L99.71 59.4901C99.71 59.4901 99.815 59.4151 99.89 59.4451L100.295 59.5501C100.37 59.5501 100.43 59.6251 100.4 59.6851L99.74 61.3351C99.74 61.3351 99.62 61.4101 99.56 61.3801Z" fill="#1ED760"/>
<path d="M111.41 64.6501L111.005 64.5451C110.93 64.5301 110.87 64.4701 110.9 64.4101L111.56 62.7601C111.56 62.7601 111.68 62.6851 111.74 62.7151L112.145 62.8201C112.22 62.8351 112.265 62.8951 112.25 62.9401L111.59 64.5751C111.59 64.5751 111.515 64.6651 111.41 64.6351V64.6501Z" fill="#C800A1"/>
<path d="M105.485 63.0151L105.08 62.9101C105.005 62.9101 104.945 62.8351 104.975 62.7901L105.635 61.1401C105.635 61.1401 105.74 61.0651 105.815 61.0951L106.22 61.2001C106.295 61.2001 106.355 61.2751 106.325 61.3351L105.665 62.9851C105.665 62.9851 105.56 63.0601 105.485 63.0301V63.0151Z" fill="#1ED760"/>
<path d="M111.035 29.1451C111.035 29.4451 110.9 29.7001 110.705 29.7151C110.51 29.7301 110.33 29.4901 110.315 29.1901C110.3 28.8901 110.45 28.6351 110.645 28.6201C110.84 28.6051 111.02 28.8451 111.035 29.1451Z" fill="black"/>
<path d="M107.615 29.34C107.615 29.64 107.48 29.895 107.285 29.91C107.09 29.925 106.925 29.685 106.895 29.385C106.865 29.085 107.03 28.83 107.225 28.815C107.42 28.8 107.615 29.04 107.615 29.34Z" fill="black"/>
<path d="M107.315 28.815L106.58 28.65C106.58 28.65 106.985 29.19 107.315 28.815Z" fill="black"/>
<path d="M108.56 29.8051C108.335 30.5101 108.005 31.1701 107.6 31.8001C107.93 32.0101 108.32 32.0551 108.68 31.9351L108.56 29.8051Z" fill="#ED847E"/>
<path d="M110.36 32.955C110.18 33 110 33.03 109.805 33.03C109.76 33.03 109.73 33.015 109.73 32.97C109.73 32.97 109.76 32.88 109.805 32.88C110.57 32.85 111.275 32.46 111.71 31.83C111.74 31.8 111.785 31.8 111.815 31.83C111.845 31.845 111.845 31.89 111.83 31.92C111.485 32.445 110.96 32.82 110.36 32.97V32.955Z" fill="black"/>
<path d="M112.16 26.925C112.16 26.925 112.055 26.925 112.025 26.88C111.71 26.55 111.245 26.385 110.78 26.445C110.705 26.445 110.63 26.415 110.6 26.34C110.585 26.265 110.63 26.175 110.705 26.16C111.26 26.07 111.83 26.265 112.22 26.655C112.28 26.715 112.28 26.805 112.22 26.865C112.205 26.88 112.175 26.895 112.145 26.91L112.16 26.925Z" fill="#3D3D3B"/>
<path d="M106.145 27.3751C106.145 27.3751 106.04 27.4051 105.995 27.3751C105.935 27.3301 105.92 27.2401 105.965 27.1651C106.295 26.7001 106.82 26.4301 107.375 26.4301C107.45 26.4301 107.525 26.5051 107.51 26.5801C107.51 26.6551 107.435 26.7301 107.36 26.7151C106.895 26.7151 106.46 26.9551 106.205 27.3301C106.19 27.3301 106.175 27.3601 106.145 27.3601V27.3751Z" fill="#3D3D3B"/>
<path d="M101.81 43.0351C101.435 43.6051 101.09 44.0701 100.715 44.5501C100.34 45.0301 99.9501 45.4951 99.5601 45.9451C98.7651 46.8451 97.9101 47.7001 97.0251 48.5101C96.1401 49.3351 95.1951 50.1001 94.2051 50.7901C93.7101 51.1501 93.2001 51.4651 92.6901 51.7951L91.8951 52.2601L91.4901 52.4851C91.3401 52.5601 91.1601 52.6651 90.9651 52.7401C90.1701 53.1001 89.2701 53.2201 88.4001 53.0701C87.5751 52.9201 86.8101 52.5451 86.1801 52.0201C85.6401 51.5701 85.1751 51.0451 84.7701 50.4751C84.4101 49.9501 84.0801 49.3951 83.7951 48.8251C82.7751 46.6501 82.0551 44.3401 81.6951 41.9551L83.4201 41.5501C83.7801 42.5851 84.1851 43.6501 84.6351 44.6401C85.0551 45.6451 85.5651 46.6051 86.1351 47.5201C86.6151 48.3601 87.2451 49.0801 88.0101 49.6801C88.2651 49.8751 88.5651 49.9951 88.8801 50.0251C89.1501 50.0251 89.4351 49.9651 89.6601 49.8301C89.7501 49.7851 89.8251 49.7401 89.9001 49.6801L90.2301 49.4701L90.9051 49.0351C91.3401 48.7201 91.7751 48.4201 92.1951 48.0751C93.0501 47.4151 93.8601 46.6951 94.6401 45.9451C95.4351 45.1951 96.1701 44.4001 96.8901 43.5751C97.2501 43.1701 97.5951 42.7501 97.9401 42.3301C98.2851 41.9101 98.6151 41.4601 98.8851 41.0701L101.825 43.0051L101.81 43.0351Z" fill="#FFC3BD"/>
<path d="M83.735 43.1401L84.11 39.1051L80.48 40.5901C80.48 40.5901 80.915 42.6151 82.295 43.3501L83.72 43.1401H83.735Z" fill="#FFC3BD"/>
<path d="M82.9549 36.9301L80.1499 36.3151L80.4949 40.5901L84.1249 39.1051L82.9549 36.9301Z" fill="#FFC3BD"/>
<path d="M101.465 37.4551C99.575 37.4551 95.03 44.6701 95.03 44.6701L98.24 48.0001C98.24 48.0001 104.285 43.9201 104.525 41.5201C104.735 39.4501 103.865 37.4851 101.465 37.4701V37.4551Z" fill="#263238"/>
<path d="M101.465 37.4551C99.575 37.4551 95.03 44.6701 95.03 44.6701L98.24 48.0001C98.24 48.0001 104.285 43.9201 104.525 41.5201C104.735 39.4501 103.865 37.4851 101.465 37.4701V37.4551Z" fill="#500878"/>
<path d="M98.5401 48.165C98.4051 48.3 98.1801 48.3 98.0451 48.165L94.8051 44.985C94.6701 44.85 94.6701 44.625 94.8051 44.49C94.9401 44.355 95.1651 44.355 95.3001 44.49L98.5401 47.67C98.6751 47.805 98.6751 48.03 98.5401 48.165Z" fill="#E63888"/>
<path d="M110.735 28.6201L110 28.4551C110 28.4551 110.405 28.9951 110.735 28.6201Z" fill="black"/>
<path d="M72.0145 7.61723L23.1055 11.8876C19.7879 12.1773 17.3332 15.1016 17.6229 18.4193L27.8076 135.065C28.0973 138.383 31.0216 140.838 34.3392 140.548L83.2482 136.278C86.5658 135.988 89.0205 133.064 88.7308 129.746L78.5461 13.0999C78.2564 9.78222 75.3321 7.32756 72.0145 7.61723Z" fill="black"/>
<path d="M21.29 52.875C22.235 52.785 22.925 51.96 22.85 51.015L21.5 35.64C21.41 34.695 20.585 34.005 19.64 34.08C18.695 34.17 18.005 34.995 18.08 35.94L19.43 51.315C19.52 52.26 20.345 52.95 21.29 52.875Z" fill="black"/>
<path d="M80.5569 28.7021L79.9591 28.7543C79.2576 28.8156 78.7386 29.4339 78.7999 30.1354L79.6205 39.5346C79.6818 40.2361 80.3001 40.7552 81.0016 40.6939L81.5993 40.6417C82.3008 40.5805 82.8198 39.9621 82.7586 39.2607L81.9379 29.8614C81.8767 29.1599 81.2584 28.6409 80.5569 28.7021Z" fill="#3D3D3B"/>
<path d="M81.2291 44.4852L80.6166 44.5403C79.9152 44.6034 79.3978 45.2231 79.4609 45.9244L80.3102 55.3663C80.3733 56.0676 80.993 56.585 81.6943 56.5219L82.3068 56.4668C83.0082 56.4038 83.5256 55.7841 83.4625 55.0827L82.6132 45.6409C82.5501 44.9395 81.9304 44.4221 81.2291 44.4852Z" fill="#263238"/>
<path d="M73.0528 7.52751L24.1439 11.7979C20.8262 12.0876 18.3715 15.0119 18.6612 18.3295L28.8459 134.976C29.1356 138.293 32.0599 140.748 35.3776 140.458L84.2865 136.188C87.6042 135.898 90.0588 132.974 89.7691 129.656L79.5844 13.0102C79.2948 9.6925 76.3704 7.23784 73.0528 7.52751Z" fill="#3D3D3B"/>
<path d="M30.56 133.185L87.74 128.19C88.355 128.13 88.82 127.59 88.775 126.96L79.085 16.0351C79.025 15.4201 78.485 14.9551 77.855 15.0001L20.66 20.0101C20.045 20.0701 19.58 20.6101 19.64 21.2401L29.33 132.18C29.375 132.795 29.93 133.26 30.545 133.2L30.56 133.185Z" fill="#F5F5F5"/>
<path d="M54.9327 11.9948L42.7092 13.0621C42.4286 13.0866 42.221 13.3339 42.2455 13.6145L42.2899 14.1226C42.3144 14.4032 42.5617 14.6108 42.8423 14.5863L55.0658 13.519C55.3464 13.4945 55.554 13.2472 55.5295 12.9666L55.4852 12.4585C55.4607 12.1779 55.2133 11.9703 54.9327 11.9948Z" fill="black"/>
<path d="M43.5498 56.4301H103.04C106.25 56.4301 108.86 59.0401 108.875 62.2651V91.4401C108.875 94.6651 106.265 97.2601 103.04 97.2751H82.4598L73.2948 106.44L64.1298 97.2751H43.5498C40.3248 97.2751 37.7298 94.6651 37.7148 91.4401V62.2651C37.7148 59.0401 40.3398 56.4451 43.5498 56.4301Z" fill="#1ED760"/>
<path d="M43.5498 56.4301H103.04C106.25 56.4301 108.86 59.0401 108.875 62.2651V91.4401C108.875 94.6651 106.265 97.2601 103.04 97.2751H82.4598L73.2948 106.44L64.1298 97.2751H43.5498C40.3248 97.2751 37.7298 94.6651 37.7148 91.4401V62.2651C37.7148 59.0401 40.3398 56.4451 43.5498 56.4301Z" fill="#1ED760"/>
<path d="M47.3599 66.54V87.165C47.3599 88.83 48.6799 90.195 50.3449 90.21H96.2449C97.9099 90.18 99.2449 88.83 99.2299 87.165V66.54C99.2299 66.15 99.1549 65.76 99.0199 65.4C98.5699 64.26 97.4749 63.495 96.2599 63.495H50.3599C49.1299 63.495 48.0349 64.26 47.5999 65.4C47.4499 65.76 47.3749 66.15 47.3749 66.54H47.3599Z" fill="white"/>
<path d="M47.585 65.4L69.77 75.06C72.02 76.035 74.57 76.035 76.82 75.06L99.005 65.4C98.555 64.26 97.46 63.495 96.245 63.495H50.345C49.115 63.495 48.02 64.26 47.585 65.4Z" fill="#EDEDED"/>
<path d="M42.3049 67.89C47.2009 67.89 51.1699 63.921 51.1699 59.025C51.1699 54.129 47.2009 50.16 42.3049 50.16C37.4089 50.16 33.4399 54.129 33.4399 59.025C33.4399 63.921 37.4089 67.89 42.3049 67.89Z" fill="#E63888"/>
<path d="M44.0149 64.425H41.9449V56.64C41.1799 57.345 40.2649 57.885 39.2749 58.2V56.325C39.9199 56.085 40.5049 55.755 41.0299 55.32C41.6149 54.885 42.0649 54.285 42.3199 53.595H43.9999V64.425H44.0149Z" fill="white"/>
</svg>
)
export default icon