import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, isEmpty } from "../helper";


function SelectDeviceTechnology() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceFrameRate']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceFrameRate"];

  const [otherFrameRate, setOtherFrameRate] = useState("");
  const [displayTechnologyList, setDisplayTechnologyList] = useState([]);
  const [selectedTech, setSelectedTech] = useState({});

  let displayFrameRate = findById(metadata, "displayFrameRate");
  let inputOtherFrameRate = findById(metadata, "inputOtherFrameRate");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDisplayFrameRateInfo = findById(metadata, "txtDisplayFrameRateInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const onContinueClick = (tech) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedFrameRate: otherFrameRate?.trim() || tech.value,
        isOtherTechSelected: isEmpty(tech),
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectConnectivityType: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const goToDashboard = () => {
    // clear all device reg data
    updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    console.log("Device Details :   ", regDeviceDetails?.selectedBrandDetails);
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDisplayTechnologies(regDeviceDetails.selectedBrandDetails.Frame_Rate);
    // now get distinct list of all brand
    else showDisplayTechnologies(regDeviceDetails?.distinctFrameList);
  }, []);
  const showDisplayTechnologies = (displayTech = []) => {
    let technologyList = [];
    displayTech.forEach((tech) => {
      if (!isEmpty(tech)) {
        let deviceBrandTech = {
          value: tech,
          label: tech,
          // using below property for custom sorting
          frameRate: tech.match(/[a-zA-Z]+|[0-9]+/g)[0]
        };
        technologyList.push(deviceBrandTech);
      }
    });

    const sortedTechnologyList = technologyList.sort((a, b) =>
      a.frameRate - b.frameRate
    );

    // add others at last
    // technologyList.push({ value: "Others", label: "Others" });

    setDisplayTechnologyList(sortedTechnologyList);
  };

  const onTechnologySelect = (tech) => {
    hideShowInputOtherTech(tech);
    setSelectedTech({ ...selectedTech, ...tech });

    if (tech.value !== "Others") onContinueClick(tech);
  };

  const hideShowInputOtherTech = (tech) => {
    // clear input value
    setOtherFrameRate("");
    inputOtherFrameRate.args = {
      ...inputOtherFrameRate.args,
      other: tech.value === "Others" ? "block" : "hidden",
    };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: tech.value === "Others" ? "block" : "hidden",
    };
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  displayFrameRate.args = {
    ...displayFrameRate.args,
    deviceItems: displayTechnologyList,
    value: selectedTech?.value,
    onClick: onTechnologySelect,
  };

  inputOtherFrameRate.args = {
    ...inputOtherFrameRate.args,
    label: homeplusscript["labelOtherTech"],
    placeHolder: homeplusscript["labelOtherTech"],
    value: otherFrameRate,
    onChange: setOtherFrameRate,
  };

  txtDisplayFrameRateInfo.args = {
    ...txtDisplayFrameRateInfo.args,
    content: homeplusscript.otherTechInfo.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherFrameRate?.trim(),
    displayClasses: selectedTech?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    onClose: (e) => goToDashboard(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceTechnology;
