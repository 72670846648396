import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "./helper";

function Campaign(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const { setOpenCampaignPopup, onDeviceRegClick } = props;
  const metadata = workflowState["config"]?.metadata["CampaignPopup"];
  const homeplusscript = workflowState["config"]?.scripts["CampaignPopup"];

  const [isExpanded, setIsExpanded] = useState(true);

  let textContent = findById(metadata, "contentText");
  let btnRegister = findById(metadata, "btnRegister");

  textContent.args = {
    ...textContent.args,
    content: homeplusscript.contentText,
  };

  const open = (isExpanded) => {
    setOpenCampaignPopup(false);
    setIsExpanded(!isExpanded);
  };

  const onContinueClick = () => {
    onDeviceRegClick();
    open(isExpanded);
  };

  btnRegister.args = {
    ...btnRegister.args,
    label: homeplusscript.btnRegister,
    enabled: true,
    onClick: (e) => onContinueClick(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default Campaign;
