/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.21 17.1902H3.79C3.35 17.1902 3 16.8302 3 16.4002V6.04023C3 5.60023 3.36 5.25023 3.79 5.25023H7.71C7.89 5.25023 8.04 5.40023 8.04 5.58023C8.04 5.76023 7.89 5.91023 7.71 5.91023H3.79C3.71 5.91023 3.65 5.97023 3.65 6.05023V16.4102C3.65 16.4902 3.71 16.5502 3.79 16.5502H20.21C20.29 16.5502 20.35 16.4902 20.35 16.4102V6.04023C20.35 5.96023 20.29 5.90023 20.21 5.90023H16.29C16.11 5.90023 15.96 5.75023 15.96 5.57023C15.96 5.39023 16.11 5.24023 16.29 5.24023H20.21C20.65 5.24023 21 5.60023 21 6.03023V16.3902C21 16.8302 20.64 17.1802 20.21 17.1802V17.1902Z"
      fill="#575757"
    />
    <path
      d="M19.2699 16.0604H4.72992C4.41992 16.0604 4.16992 15.8104 4.16992 15.5004V6.93035C4.16992 6.62035 4.41992 6.37035 4.72992 6.37035H7.21992C7.39992 6.37035 7.54992 6.52035 7.54992 6.70035C7.54992 6.88035 7.39992 7.03035 7.21992 7.03035H4.81992V15.4104H19.1699V7.02035H16.7699C16.5899 7.02035 16.4399 6.87035 16.4399 6.69035C16.4399 6.51035 16.5899 6.36035 16.7699 6.36035H19.2599C19.5699 6.36035 19.8199 6.61035 19.8199 6.92035V15.5004C19.8199 15.8104 19.5699 16.0604 19.2599 16.0604H19.2699Z"
      fill="#575757"
    />
    <path
      d="M14.0704 19.4101H9.94043C9.11043 19.4101 8.44043 18.7401 8.44043 17.9101C8.44043 17.7301 8.59043 17.5801 8.77043 17.5801C8.95043 17.5801 9.10043 17.7301 9.10043 17.9101C9.10043 18.3801 9.48043 18.7601 9.95043 18.7601H14.0804C14.5504 18.7601 14.9304 18.3801 14.9304 17.9101C14.9304 17.7301 15.0804 17.5801 15.2604 17.5801C15.4404 17.5801 15.5904 17.7301 15.5904 17.9101C15.5904 18.7401 14.9204 19.4101 14.0904 19.4101H14.0704Z"
      fill="#575757"
    />
    <path
      d="M19.27 20.3498H4.73C3.77 20.3498 3 19.5698 3 18.6198V17.9198C3 17.7398 3.15 17.5898 3.33 17.5898H8.77C8.95 17.5898 9.1 17.7398 9.1 17.9198C9.1 18.3898 9.48 18.7698 9.95 18.7698H14.08C14.55 18.7698 14.93 18.3898 14.93 17.9198C14.93 17.7398 15.08 17.5898 15.26 17.5898H20.7C20.88 17.5898 21.03 17.7398 21.03 17.9198V18.6198C21.03 19.5798 20.25 20.3498 19.3 20.3498H19.27ZM3.65 18.2398V18.6198C3.65 19.2198 4.14 19.6998 4.73 19.6998H19.27C19.87 19.6998 20.35 19.2198 20.35 18.6198V18.2398H15.53C15.38 18.9098 14.78 19.4098 14.07 19.4098H9.94C9.23 19.4098 8.63 18.9098 8.48 18.2398H3.65Z"
      fill="#575757"
    />
    <path
      d="M12.0002 13.5399C11.9402 13.5399 11.8802 13.5199 11.8302 13.4899L8.37023 11.0899C8.29023 11.0299 8.24023 10.9399 8.24023 10.8399V3.94994C8.24023 3.84994 8.29023 3.75994 8.37023 3.69994C8.45023 3.63994 8.55023 3.62994 8.65023 3.65994L10.5702 4.34994L11.0502 3.86994C11.1102 3.80994 11.1802 3.77994 11.2602 3.77994H12.7502C12.8302 3.77994 12.9102 3.80994 12.9602 3.86994L13.4402 4.34994L15.3602 3.65994C15.4502 3.62994 15.5602 3.63994 15.6402 3.69994C15.7202 3.75994 15.7702 3.84994 15.7702 3.94994V10.8299C15.7702 10.9299 15.7202 11.0199 15.6402 11.0799L12.1802 13.4799C12.1302 13.5199 12.0702 13.5299 12.0102 13.5299L12.0002 13.5399ZM8.84023 10.6699L12.0002 12.8599L15.1602 10.6699V4.37994L13.4602 4.98994C13.3502 5.02994 13.2302 4.99994 13.1402 4.91994L12.6202 4.39994H11.3802L10.8602 4.91994C10.7802 4.99994 10.6502 5.02994 10.5402 4.98994L8.84023 4.37994V10.6699Z"
      fill="#575757"
    />
    <path
      d="M11.9998 10.5895C10.8098 10.5895 9.83984 9.61953 9.83984 8.42953C9.83984 7.23953 10.8098 6.26953 11.9998 6.26953C13.1898 6.26953 14.1598 7.23953 14.1598 8.42953C14.1598 9.61953 13.1898 10.5895 11.9998 10.5895ZM11.9998 6.86953C11.1398 6.86953 10.4398 7.56953 10.4398 8.42953C10.4398 9.28953 11.1398 9.98953 11.9998 9.98953C12.8598 9.98953 13.5598 9.28953 13.5598 8.42953C13.5598 7.56953 12.8598 6.86953 11.9998 6.86953Z"
      fill="#575757"
    />
    <path
      d="M12.5895 7.98016L11.7795 8.79016L11.3695 8.38016C11.2895 8.30016 11.1595 8.30016 11.0795 8.38016C10.9995 8.46016 10.9995 8.59016 11.0795 8.67016L11.6495 9.24016C11.7195 9.31016 11.8395 9.31016 11.9095 9.24016L12.0795 9.07016L12.8895 8.26016C12.9695 8.18016 12.9695 8.05016 12.8895 7.97016C12.8095 7.89016 12.6795 7.89016 12.5995 7.97016L12.5895 7.98016Z"
      fill="#575757"
    />
  </svg>
);

export default icon;
