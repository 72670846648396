import React from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import { InfoBoxWidget } from "./widgets/InfoBoxWidget";
import { Colors } from "./entities/Colors";
import { withinOperatingHours } from "./utils";
import { ChatType } from "./service/ChatService";

export const EnquiryOption = ({ chatType, onBackMenu, onStartChat, onSubmitEnquiry }) => {
  const classes = EnquiryOptionStyles();
  
  let open, closed, openDays;
  if (chatType === ChatType.SOLUTO) {
    open = process.env.REACT_APP_SOLUTO_BUSINESS_OPEN_HOUR && process.env.REACT_APP_SOLUTO_BUSINESS_OPEN_HOUR <= 12 ? process.env.REACT_APP_SOLUTO_BUSINESS_OPEN_HOUR : (process.env.REACT_APP_SOLUTO_BUSINESS_OPEN_HOUR - 12)
    closed = process.env.REACT_APP_SOLUTO_BUSINESS_CLOSED_HOUR && process.env.REACT_APP_SOLUTO_BUSINESS_CLOSED_HOUR <= 12 ? process.env.REACT_APP_SOLUTO_BUSINESS_CLOSED_HOUR : (process.env.REACT_APP_SOLUTO_BUSINESS_CLOSED_HOUR - 12)
    openDays = 'daily'
  } else if (chatType === ChatType.DP) {
    open = process.env.REACT_APP_DP_BUSINESS_OPEN_HOUR && process.env.REACT_APP_DP_BUSINESS_OPEN_HOUR <= 12 ? process.env.REACT_APP_DP_BUSINESS_OPEN_HOUR : (process.env.REACT_APP_DP_BUSINESS_OPEN_HOUR - 12)
    closed = process.env.REACT_APP_DP_BUSINESS_CLOSED_HOUR && process.env.REACT_APP_DP_BUSINESS_CLOSED_HOUR <= 12 ? process.env.REACT_APP_DP_BUSINESS_CLOSED_HOUR : (process.env.REACT_APP_DP_BUSINESS_CLOSED_HOUR - 12)
    openDays = 'Mon - Fri'
  }

  return (
    <div className={classes.enquiryOptionRoot}>
      <Typography variant={"body1"}>
        Sorry, we understand that you couldn't find the information you need.
      </Typography>
      <div className={classes.optionContainer}>
        <Typography variant={"body1"}>
          How would you like to proceed further?
        </Typography>
        <Button
          variant="contained"
          color={"primary"}
          // style={{ marginRight: "35px", width: "120px" }}
          // style={{ marginRight: "30px", width: "120px" }}
          onClick={() => {onStartChat()}}
          disabled={!withinOperatingHours(chatType)}
        >
          Chat with us
        </Button>
        <Button 
          variant="contained" 
          color={"primary"}
          onClick={() => {onSubmitEnquiry()}}>
          Submit an enquiry
        </Button>
      </div>
      <InfoBoxWidget
        message={
        `Our Home Specialists are online from ${open}am – ${closed}pm, ${openDays}. You may leave a message and our agents will get back to you as soon as they are available.`
        }
      />
      <div className={classes.backToMain}>
        <Button variant="text" onClick={() => {onBackMenu()}} className={classes.backLink}>Back to main menu</Button>
      </div>
    </div>
  );
};

const EnquiryOptionStyles = makeStyles((theme) => ({
  enquiryOptionRoot: {
    float: "left",
    width: "100%",
    height: "100%",
    padding: "20px 20px 10px 20px",
    // maxWidth: "390px",
    // overflow: "scroll",
    overflow: "auto",
    textAlign: "center",

    "&> p": {
      textAlign: "left",
    },
  },
  optionContainer: {
    marginTop: "30px",
    marginBottom: "25px",

    "&> p": {
      textAlign: "center",
    },
    "&> button": {
      color: Colors.BLACK,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      width: "120px",
      minWidth: "50px",
      height: "44px",
      marginTop: "15px",
      borderRadius: "4px",
    },
    "&> button + button": {
      marginLeft: '10px',
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '30px',
      }
    },
    
  },
  backToMain: {
    marginTop: "15px",
  },

  backLink: {
    color: Colors.PRIMARY,

    '&:hover': {
        backgroundColor: 'transparent'
    }
  }
}));
