import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function ChargeOrderHoldPopup(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ChargeOrderHoldPopup']
  const homeplusscript = workflowState['config']?.scripts["ChargeOrderHoldPopup"];
  
  const { setOpenPopup } = props;
  let textContent = findById(metadata, "contentText");
  let btnCancel = findById(metadata, "btnCancel");

  const CustomerCaseNumber = workflowState?.FileClaimDetails?.ServiceRequestResponse?.CustomerCaseNumber;
  textContent.args = {
    ...textContent.args,
    content: homeplusscript.contentText.replace("{'Claim Number'}", CustomerCaseNumber)
  };

  const goToDashboard = () => {
    // clear all device reg data
    setOpenPopup(false)
    updateState({
     ...workflowState,
     RegDeviceDetails: {},
     FileClaimDetails: {}
   });
   return completeCurrentStep(currentStep, {
     goToDashboard: true,
   });
 };
 
  btnCancel.args = {
    ...btnCancel.args,
     label: "Ok",
    onClick: (e) => goToDashboard(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ChargeOrderHoldPopup;



