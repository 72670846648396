import axios from 'axios'
import types from './ActionTypes'
import { Auth } from 'aws-amplify'
import { AuthService } from './auth.service'
import { Config } from './components/enrollment-workflow/config'
const auth = new AuthService()

// const chatbotApiUrl = process.env.REACT_APP_CHATBOT_API_URL

const http = axios.create({
  baseURL: '/',
  responseType: 'json',
})

http.interceptors.request.use(
  async (config) => {
    const fullName = sessionStorage.getItem('fullname');

    let user
    try {
      user = await Auth.currentAuthenticatedUser()
    } catch (error) {
      console.log('Auth Error : ', error)
    }

    let headers = {
      Accept: 'application/json',
      'Asurion-channel': 'online',
      'Asurion-enduser': fullName || 'online',
      'Asurion-lineofbusiness': 'MOBILITY',
      'Asurion-region': 'APAC-SEA',
      'Content-Type': 'application/json',
      'x-api-key': Config.service.apiKey,
      // 'Asurion-client': 'connected_home',
      'Asurion-client': 'SH_DEVICE_CARE',
      'Accept-Language': 'en-US',
      Authorization: user?.signInUserSession?.idToken?.jwtToken,
      "Access-Token": user?.signInUserSession?.accessToken?.jwtToken,
    }
    config.headers = { ...config.headers, ...headers }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const API = {}

async function signIn(username, password) {
  return await Auth.signIn(username, password)
}

async function signOut() {
  return await Auth.signOut()
}

API[types.INTIALIZE_API_REQUEST] = (language) => http.get(`registration/api/initialize/v2?language=${language}`)
API[types.CREATE_INTERACTION] = (data) => http.post('/claim/api/interaction', data)

API[types.LOGIN_REQUEST] = (data) => {
  return signIn(data.username, data.password)
}
API[types.OTP_REQUEST] = (email) => {
  return auth.signIn(email)
}
API[types.SIGNOUT_REQUEST] = () => {
  return auth.signOut()
}
API[types.VERIFY_LOGIN_OTP] = (otp) => {
  return auth.answerCustomChallenge(otp)
}

API[types.FIND_AGREEMENT_REQUEST] = (data) => http.post(`incidentinitiation/api/findagreements`, data)

API[types.GET_CATEGORY_LIST] = (ClientOfferId) =>
  http.get(`devicemanagement/api/getcategorylist/${ClientOfferId}`)
API[types.GET_INVENTORY] = (ClientOfferId) =>
  http.get(`devicemanagement/api/getinventorydata/${ClientOfferId}`)
API[types.DEVICE_REGISTRATION] = (data) => http.post(`devicemanagement/api/deviceregistration`, data)
API[types.CREATE_ENQUIRY] = (data) => http.post(`claim/api/v2/inquiry`, data)
 API[types.CREATE_ENQUIRY_V1] = (data) => http.post(`claim/api/inquiry`, data)
API[types.SET_RECOVER_EMAIL] = (data) => http.post('/incidentinitiation/api/verifycustomer', data)
API[types.DETERMINE_INCIDENT_PATH] = (data) => http.post('/claim/api/determineincidentpath', data)
API[types.CREATE_SERVICE_REQUEST] = (data) => http.post('/claim/api/startservicerequest', data)
API[types.CANCEL_SERVICE_REQUEST] = (ServiceRequestId, data) => http.post(`/claim/api/cancelservicerequest/${ServiceRequestId}`, data)
API[types.RESUME_SERVICE_REQUEST] = (ServiceRequestId, data) => http.post(`/claim/api/resumeservicerequest/${ServiceRequestId}`, data)
API[types.GET_TRIAGE_QUESTIONS] = (data) => http.post('/incidentidentification/api/triagequestions', data)
API[types.SUBMIT_TRIAGE_QUESTIONS] = (data) => http.post('/incidentidentification/api/submittriage', data)
API[types.PROCESS_INCIDENT] = (data) => http.post('/claim/api/processincident', data)
API[types.GET_FULFILMENT_OPTIONS] = (ServiceRequestId) => http.get(`/claim/api/fulfillmentoptions?servicerequestid=${ServiceRequestId}`)
API[types.SET_FULFILMENT_OPTIONS] = (ServiceRequestId, data) => http.post(`/claim/api/fulfillmentoptions?servicerequestid=${ServiceRequestId}`, data)
API[types.CREATE_SERVICE_ORDER] = (data) => http.post('/claim/api/serviceorder', data)
API[types.CREATE_REPAIR_REQUEST] = (data) => http.post('/claim/api/repairrequest/create', data)
API[types.UPDATE_REPAIR_REQUEST] = (data) => http.post('/claim/api/updaterepairrequest', data)
API[types.GET_SERVICE_FEE] = (serviceRequestId, assetCategoryId, assetMakeId, IsRedeliveryFee = false) => http.get(`/claim/api/servicefees?servicerequestid=${serviceRequestId}&assetCategoryId=${assetCategoryId}&assetMakeId=${assetMakeId}&IsRedeliveryFee=${IsRedeliveryFee}`)
API[types.GET_SECURITY_TOKEN_REQUEST] = (data) => http.post(`/registration/api/payment/token/v2`, data)
API[types.GET_PCI_TOKEN] = (data) => http.post(`/claim/api/pcitoken`, data)
API[types.CREATE_CHARGE_ORDER] = (data, serviceOrderId) => http.post(`/claim/api/v2/chargeorder/${serviceOrderId}`, data)
API[types.PROCESS_PAYMENT] = (data, serviceOrderId, chargeOrderId) => http.post(`/claim/api/v3/processpayment/${serviceOrderId}/${chargeOrderId}`, data)
API[types.GET_SHIPPING_METHODS] = (data) => http.post(`/claim/api/shippingmethodsv2`, data)
API[types.UPDATE_SHIPPING_ORDER] = (data, serviceOrderId) => http.put(`/claim/api/shippingorder/${serviceOrderId}`, data)
API[types.SUBMIT_ORDER] = (data, serviceOrderId) => http.post(`/claim/api/submitorder/${serviceOrderId}`, data)
API[types.GET_SERIAL_NUMBER_EXISTENCE] = (serialnumber) => http.get(`/devicemanagement/api/verifyimeistatus/${serialnumber}`);
API[types.CREATE_CONTACT] = (data) => http.post(`/claim/api/contactpoints`, data)
API[types.GET_CLAIM_DETAILS] = (ClientAccountId, AgreementId) => http.get(`/claim/api/details/${ClientAccountId}?agreementId=${AgreementId}`);
API[types.GET_REPLACEMENT_OPTIONS] = (data) => http.post(`/claim/api/getreplacementmatrix`, data);
API[types.GET_SECURITY_ADVISOR_TOKEN_REQUEST] = (data) => http.post(`/claim/api/securityadvisertoken`, data);
API[types.PROCESS_OVERAGE_PAYMENT] = (data) => http.post(`/claim/api/processoveragepayment`, data);
API[types.GET_FAQ_CONTENT] = (data) => axios.get(`https://cdn.contentful.com/spaces/p98nobc8lq7v/environments/master/entries/?select=fields&access_token=XS817Xf04zFzJPB2eG3erG-g8WOPilRJLYsCjZmjcDU&content_type=homeFaqCategory&include=4`)
API[types.GET_INSURED_SCHEDULE_DOCUMENT] = (policyNumber, AgreementId) => http.get(`claim/api/document/getrequireddocuments?policynumber=${policyNumber}&filesize=true&agreementId=${AgreementId}`)
API[types.GET_CONTACT_POINT_REQUEST] = (data) => http.get(`claim/api/contactpoints/${data.clientaccountid}`);
API[types.UPDATE_CARRIER_TYPE] = (data) => http.put(`/claim/api/agreementcontract`, data);
API[types.GET_IP_ADD] = (data) => axios.get("https://api64.ipify.org/?format=json");
API[types.GET_CLIENT_TOKEN] = (maid) => http.get(`/claim/braintree/api/getclienttoken/${maid}`)
API[types.BT_CREATE_CHARGE_ORDER] = (data, serviceOrderId) => http.post(`/claim/braintree/api/chargeorder/${serviceOrderId}`, data)
API[types.BT_STORE_VERIFY_CARD] = (data) => http.post(`/claim/braintree/api/storeverifiedcard`, data)
API[types.BT_CREATE_PAYMENT_METHOD] = (data, serviceOrderId) => http.post(`/claim/braintree/api/createpaymentmethod/${serviceOrderId}`, data)
API[types.BT_CREATE_NEW_NONCE] = (token, customercaseid) => http.get(`/claim/braintree/api/createnewnonce/${token}/${customercaseid}`)

// S3 related APIs

API[types.GET_SIGNED_URL_FROM_S3] = (data) => http.post(`/claim/api/filesystem/upload/v3 `, data);
API[types.GET_DOWNLOAD_URL_FROM_S3] = (data) => http.post(`/claim/api/filesystem/download/v2`, data);

API[types.LOG_TO_DYNAMO_DB] = (data) => http.post(`/api/v1/logger/`, data);

API[types.UPLOAD_VIDEO_DOC] = (data) => http.post(`/claim/api/updatereferencedocument`, data);

export default API
