/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.88 3.97021C3.09 3.52021 3.5475 3.13771 3.9975 3.03271C4.095 3.00271 7.575 2.99521 12.09 3.00271H20.0025L20.2875 3.13771C20.6475 3.31021 20.925 3.58021 21.0975 3.94771L21.2325 4.2327L21.255 10.3302L21.2775 16.4277L22.26 18.0627L23.2425 19.6977L23.2125 19.9452C23.1525 20.5002 22.785 21.0027 22.2675 21.2502L21.9825 21.3852H2.0175L1.7475 21.2652C1.23 21.0252 0.8475 20.5002 0.78 19.9452L0.75 19.6977L1.7325 18.0627L2.715 16.4277L2.7375 10.3302L2.76 4.2327L2.88 3.97021ZM20.445 4.12021C20.37 4.01521 20.2275 3.87271 20.1225 3.79771L20.115 3.80521L19.9275 3.66271H12.0675C5.37 3.65521 4.1775 3.66271 4.0275 3.71521C3.81 3.79771 3.5025 4.12021 3.4425 4.33771C3.4125 4.4502 3.3975 6.2802 3.3975 10.3302V16.1577H20.595V10.2327L20.58 4.30771L20.445 4.12021ZM22.3125 19.4277L21.5325 18.1227H21.54L20.76 16.8177H3.24L2.46 18.1227L1.68 19.4277L6.84 19.4427H17.1525L22.3125 19.4277ZM8.0475 20.7627V20.4327H8.04V20.1027H4.77C1.845 20.1027 1.5 20.1102 1.5 20.1702C1.5 20.2902 1.905 20.6502 2.1 20.7102C2.2425 20.7477 3.0225 20.7627 5.1675 20.7627H8.0475ZM15.2925 20.7627V20.4327V20.1027H8.7075V20.7627H15.2925ZM21.8925 20.7102C22.0875 20.6502 22.4925 20.2902 22.4925 20.1702H22.485C22.485 20.1102 22.14 20.1027 19.215 20.1027H15.945V20.7627H18.825C20.97 20.7627 21.7575 20.7477 21.8925 20.7102ZM4.05029 4.51018L4.16279 4.40518L4.26779 4.29268H19.7403L19.8453 4.40518L19.9578 4.51018V15.2727L19.8453 15.3777L19.7403 15.4902H16.1478H16.1477C12.5928 15.4902 12.5553 15.4902 12.4578 15.4002C12.3978 15.3402 12.3603 15.2577 12.3603 15.1602C12.3603 15.0627 12.3978 14.9802 12.4578 14.9202C12.5553 14.8302 12.5928 14.8302 15.9228 14.8302H19.2903V4.96018H4.71029V14.8302H8.08529C11.4153 14.8302 11.4528 14.8302 11.5503 14.9202C11.6853 15.0402 11.6853 15.2802 11.5503 15.4002C11.4528 15.4902 11.4153 15.4902 7.86041 15.4902H7.86029H4.26779L4.16279 15.3777L4.05029 15.2727V4.51018Z"
      fill="#1ED760"
    />
  </svg>
);

export default icon;
