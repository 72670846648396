import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function ProvidePurchasePrice() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvidePurchasePrice']
  const homeplusscript = workflowState['config']?.scripts["ProvidePurchasePrice"];

  const [purchasedPrice, setPurchasedPrice] = useState("");
  const [formattedPrice, setFormattedPrice]=useState("")
  const [priceError, setPriceError]=useState("")

  let titleText = findById(metadata, "txtTitle");
  let inputPurchasedPrice = findById(metadata, "inputPurchasedPrice");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menu = findById(metadata,"menu");
  let txtPurchasedPriceInfo = findById(metadata, "txtPurchasedPriceInfo");
  let txtPriceError = findById(metadata, "txtPriceError");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  let re=(/^\d{0,8}[.]?\d{1,2}$/);
  // let re=/^(\d{0,8})?(,?\d{3})*(\.\d{1,2})?$/;
  const onContinueClick = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        purchasedPrice:formattedPrice,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      showDeviceRegSummary: true,
    });
  };
  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };


  useEffect(() => {
    const val = parseFloat(purchasedPrice).toLocaleString().split(".")[0] + "."
    + Number(purchasedPrice).toFixed(2).split(".")[1];
    setFormattedPrice(val)
  },[purchasedPrice])
  
  const handlePurchasedPrice=(price)=>{
    setPurchasedPrice(price)
    if(isNaN(price)){
      setPriceError(homeplusscript.txtPriceError)
    }else if(price === ""){
      setPriceError("")
    }else if(price <= 0){
      setPriceError(homeplusscript.txtPriceError)
    }else if(!re.test(price)){
      setPriceError(homeplusscript.txtPriceError)
    } else{
      setPriceError("")
    }
  }
  
  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title
  };

  inputPurchasedPrice.args = {
    ...inputPurchasedPrice.args,
    label: homeplusscript.labelPurchasedPrice,
    placeHolder: homeplusscript.labelPurchasedPrice,
    value: purchasedPrice,
    onChange: handlePurchasedPrice,
  };

  txtPriceError.args = {
    ...txtPriceError.args,
    content : priceError,
  }
  txtPurchasedPriceInfo.args = {
    ...txtPurchasedPriceInfo.args,
    content : homeplusscript.labelPurchasePriceInfo,
  }

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled:re.test(purchasedPrice) && purchasedPrice!=0? true : false,
    onClick: (e) => onContinueClick(),
  };
  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvidePurchasePrice;
