/**
 * Service for configuring the UI to conform to the brand theme
 */

import { createTheme } from "@material-ui/core";
import { Colors } from "../entities/Colors";

/**
 * Creates and returns a Material UI theme that can be
 * passed to the ThemeProvider component
 *
 * @param {object} brand - Partner-specific UI config
 * @param {number} scaleFactor - Optional device scale factor
 */
export const buildMuiTheme = (brand, scaleFactor = 1) => {
  return createTheme({
    palette: {
      primary: {
        main: brand.primaryColor,
        contrastText: brand.primaryContrastTextColor,
      },
    },
    typography: {
      fontFamily: `"${brand.font}", sans-serif`,
      htmlFontSize: 16 * scaleFactor,
    },
    spacing: 8 * scaleFactor,
    overrides: {
      MuiGrid: {
        root: {
          fontFamily: `"${brand.font}", sans-serif`,
        },
      },
      MuiAccordion: {
        root: {
          margin: 0,
          marginBottom: '10px',
          border: `1px solid ${Colors.PRIMARY_CONTRAST_TEXT}`,
          '-webkit-box-shadow': 'none',
          '-moz-box-shadow': 'none',
          boxShadow: 'none',
          
          '&:before': {
            display: 'none'
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 400,

          '&.Mui-expanded': {
            '& .MuiAccordionSummary-content': {
              fontWeight: 700
            }
          }
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "1rem",
          backgroundColor: brand.primaryColor,
          color: "#fff",
        },
      },
      MuiChip: {
        root: {
          "&:hover": {
            backgroundColor: brand.primaryColor,
            color: brand.primaryContrastTextColor,
          },
        },
      },
      MuiSelect: {
        root: {
          padding: "8px",
          margin: 0,
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 8 * scaleFactor,
        },
      },
      MuiLink: {
        root: {
          color: Colors.PRIMARY
        },
      },
      MuiCircularProgress: {
        root: {
          color: brand.primaryColor,
        },
      },
      MuiInputBase: {
        root: {
          padding: "6px 15px",
          backgroundColor: "#F5F5F5",
          borderRadius: "100px",
          fontFamily: "unset",
        },
      },
      // MuiOutlinedInput: {
        // notchedOutline: {
        //   '& fieldset:hover': {
        //     borderColor: 'red'
        //   },
        //   borderColor: 'pink'
        // }
      // },
      MuiFilledInput: {
        root: {
          "&:hover": {
            backgroundColor: Colors.SECONDARY,
            boxShadow: `0px 0px 0px 3px ${Colors.LIGHT_BLUE}`,
          },
        }
      },
      MuiButton: {
        root: {
          minWidth: 240 * scaleFactor,
          borderRadius: '4px',
          textTransform: "none",
          backgroundColor: brand.button.primaryColor,
          color: brand.button.primaryTextColor,

          "&:hover": {
            backgroundColor: brand.primaryColor,
            color: brand.button.primaryContrastTextColor,
          },
        },
        containedPrimary: {
          boxShadow: "none",
          color: Colors.SECONDARY_DARK_GREEN,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: Colors.SECONDARY
          },
          "&:focus": {
            backgroundColor: Colors.SECONDARY,
            boxShadow: `0px 0px 0px 3px ${Colors.LIGHT_BLUE}`,
          },
          "&:active": {
            boxShadow: "none",
            backgroundColor: Colors.DARK_BLUE,
            color: Colors.SECONDARY_DARK_GREEN
          },
          "@media (hover: none)": {
            '&:hover': {
              boxShadow: "none !important",
              backgroundColor: `${Colors.SECONDARY} !important`
            }
          },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: Colors.SECONDARY
          },
          },
        },
        outlined: {
          color: Colors.SECONDARY_DARK_GREEN,
          "&:hover": {
            backgroundColor: Colors.SECONDARY
          }
        },
        containedSizeLarge: {
          paddingTop: 10 * scaleFactor,
          paddingBottom: 10 * scaleFactor,
        },
        outlinedSizeLarge: {
          paddingTop: 9 * scaleFactor,
          paddingBottom: 9 * scaleFactor,
        },
        fullWidth: {
          maxWidth: 320 * scaleFactor,
          marginTop: 8 * scaleFactor,
          marginBottom: 8 * scaleFactor,
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.97)",
          opacity: "0.8 !important",
        },
      },
    },
  });
};
