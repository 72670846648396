import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceStorageType() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceStorageType']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceStorageType"];

  const [otherStorageType, setOtherStorageType] = useState("");
  const [deviceStorageTypeList, setDeviceStorageTypeList] = useState([]);
  const [selectedStorageType, setSelectedStorageType] = useState({});

  let storageTypes = findById(metadata, "storageTypes");
  let inputOtherStorageType = findById(metadata, "inputOtherStorageType");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtStorageTypeInfo = findById(metadata, "txtStorageTypeInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");
 
  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;

  const onContinueClick = (storageType) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedStorageType: otherStorageType?.trim() || storageType.value,
        isOtherStorageTypeSelected: isEmpty(storageType)
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      askPuchaseReceipt: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceStorageTypes(
        regDeviceDetails.selectedBrandDetails.Storage_Type
      );
    // now get distinct list of all brand
    else showDeviceStorageTypes(regDeviceDetails?.distinctStorageTypeList);
  }, []);

  const showDeviceStorageTypes = (deviceStorageTypes = []) => {
    let storageList = [];
    deviceStorageTypes.forEach((storageType) => {
      if (!isEmpty(storageType)) {
        let deviceStorageType = {
          value: storageType,
          label: storageType,
        };
        storageList.push(deviceStorageType);
      }
    });

    // add others at last
    // no need to storage type
    // storageList.push({ value: "Others", label: "Others" });

    setDeviceStorageTypeList(storageList);
  };

  const onStorageTypeSelect = (storageType) => {
    hideShowInputOtherStorageType(storageType);
    setSelectedStorageType({ ...selectedStorageType, ...storageType });

    if (storageType.value !== "Others") onContinueClick(storageType);
  };

  const hideShowInputOtherStorageType = (storageType) => {
    // clear input value
    setOtherStorageType("");
    inputOtherStorageType.args = {
      ...inputOtherStorageType.args,
      other: storageType.value === "Others" ? "block" : "hidden",
    };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: storageType.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: storageType.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  storageTypes.args = {
    ...storageTypes.args,
    deviceItems: deviceStorageTypeList,
    value: selectedStorageType?.value,
    onClick: onStorageTypeSelect,
  };

  inputOtherStorageType.args = {
    ...inputOtherStorageType.args,
    label: homeplusscript.labelOtherStorageType,
    placeHolder: homeplusscript.placeHolderOtherStorageType,
    value: otherStorageType,
    onChange: setOtherStorageType,
  };

  const getTitleInfo = () => {
    if(isDesktop) return homeplusscript.deviceStorageTypeInfoDekstop;
   else
     return homeplusscript.deviceStorageTypeInfoLaptop;
  }
 txtStorageTypeInfo.args = {
    ...txtStorageTypeInfo.args,
    content: getTitleInfo()
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherStorageType?.trim(),
    displayClasses: selectedStorageType?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceStorageType;
