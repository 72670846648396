import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function TriageError() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['TriageError']
  const homeplusscript = workflowState['config']?.scripts["TriageError"];

  let titleText = findById(metadata, "txtTitle");
  let btnContinue = findById(metadata, "btnContinue");
  let menu = findById(metadata, "menu");

  const goToDashboard = () => {
    // clear all claim file data
    updateState({
      ...workflowState,
      FileClaimDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"]
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    onClick: (e) => goToDashboard(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default TriageError;
