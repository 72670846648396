import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById } from "./helper";

function EnrollDevice() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['EnrollDevice']
  const homeplusscript = workflowState['config']?.scripts["EnrollDevice"];

  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({
    value: "Keyboard",
    label: "Keyboard",
  });

  const setSelectedDevice = (device) => {
    setSelectedDeviceDetails({
      ...selectedDeviceDetails,
      ...device,
    });
  };

  const [selectedDeviceMake, setSelectedDeviceMake] = useState({
    value: "Keyboard",
    label: "Keyboard",
  });

  const setDeviceMake = (deviceMake) => {
    setSelectedDeviceMake({
      ...selectedDeviceDetails,
      ...deviceMake,
    });
  };

  const technologies = [
    { value: "QLED", label: "QLED" },
    { value: "UHD", label: "UHD" },
    { value: "8K", label: "8K" },
    { value: "4K", label: "4K" },
    { value: "LED", label: "LED" },
  ];

  const curvatores = [
    { value: "Flat", label: "Flat" },
    { value: "Curved", label: "Curved" },
  ];

  const deviceScreenSizes = [
    { value: "15”", label: "15”" },
    { value: "19”", label: "19”" },
    { value: "22”", label: "22”" },
    { value: "26”", label: "26”" },
    { value: "32”", label: "32”" },
    { value: "37”", label: "37”" },
    { value: "40”", label: "40”" },
    { value: "42”", label: "42”" },
    { value: "47”", label: "47”" },
    { value: "50”", label: "50”" },
    { value: "55”", label: "55”" },
    { value: "62”", label: "62”" },
    { value: "65”", label: "65”" },
    { value: "70”", label: "70”" },
  ];

  const [selectedTechnology, setSelectedTechnologies] = useState({
    value: "8K",
    label: "8K",
  });

  const setSelectedTechnology = (technology) => {
    debugger;
    setSelectedTechnologies({
      ...selectedTechnology,
      ...technology,
    });
  };

  const [selectedCurvature, setSelectedCurvatures] = useState({
    value: "Curved",
    label: "Curved",
  });

  const setSelectedCurvature = (curvature) => {
    setSelectedCurvatures({
      ...selectedCurvature,
      ...curvature,
    });
  };

  const [selectedSize, setSelectedSizes] = useState({
    value: "22”",
    label: "22”",
  });

  const setSelectedSize = (size) => {
    setSelectedSizes({
      ...selectedSize,
      ...size,
    });
  };

  useEffect(() => {
    console.log(
      "selected device details",
      selectedDeviceDetails.value,
      selectedDeviceDetails.label
    );
  }, [selectedDeviceDetails]);

  useEffect(() => {
    console.log("selected device technologies", setSelectedTechnologies.value);
  }, [selectedTechnology]);

  let deviceSelection = findById(metadata, "deviceSelection");
  let deviceMake = findById(metadata, "deviceMake");
  let btnContinue = findById(metadata, "btnContinue");
  let deviceTechnologies = findById(metadata, "DeviceTechnologies");
  let curvature = findById(metadata, "curvature");
  let screenSizes = findById(metadata, "screenSize");

  let pdtIdentifyText = findById(metadata,"pdtIdentify");

  let enrollDeviceMenu = findById(metadata,"menu");
  let inputModelNumber = findById(metadata,"inputModelNumber");
  let inputDeviceName = findById(metadata,"inputDeviceName");


  enrollDeviceMenu.args = {
    ...enrollDeviceMenu.args,
    iconSubHeader: homeplusscript.menu_iconSubHeader,
    labelSubHeader: homeplusscript.menu_labelSubHeader
  };

  inputDeviceName.args = {
    ...inputDeviceName.args,
    label: homeplusscript.inputDeviceName_label,
    placeHolder: homeplusscript.inputDeviceName_placeHolder
  };

  inputModelNumber.args = {
    ...inputModelNumber.args,
    label: homeplusscript.inputModelNumber_label,
    placeHolder: homeplusscript.inputModelNumber_placeHolder
  };

  pdtIdentifyText.args = {
    ...pdtIdentifyText.args, 
    content:homeplusscript.pdtIdentify
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue_label,
    enabled: selectedDeviceDetails.value,
    // onClick: (e) => onClick(),
  };

  deviceTechnologies.args = {
    ...deviceTechnologies.args,
    label: homeplusscript.DeviceTechnologies_label,
    deviceItems: technologies,
    value: selectedTechnology?.value,
    onClick: setSelectedTechnology,
  };

  curvature.args = {
    ...curvature.args,
    label: homeplusscript.curvature_label,
    deviceItems: curvatores,
    value: selectedCurvature?.value,
    onClick: setSelectedCurvature,
  };

  screenSizes.args = {
    ...screenSizes.args,
    label: homeplusscript.screenSize_label,
    deviceItems: deviceScreenSizes,
    value: selectedSize?.value,
    onClick: setSelectedSize,
  };

  const devices = [
    { value: "Soundbar", label: "Soundbar" },
    { value: "Tablet", label: "Tablet" },
    { value: "Television", label: "Television" },
    { value: "Webcam", label: "Webcam" },
    { value: "Laptop", label: "Laptop" },
    { value: "Printer", label: "Printer" },
    { value: "Router", label: "Router" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "Mouse", label: "Mouse" },
    { value: "Monitors", label: "Monitors" },
    { value: "Desktop", label: "Desktop" },
  ];

  deviceSelection.args = {
    ...deviceSelection.args,
    label:homeplusscript.deviceSelection_label,
    options: devices,
    value: selectedDeviceDetails.value,
    onSelect: setSelectedDevice,
  };

  const deviceMakes = [
    { value: "Soundbar", label: "Soundbar" },
    { value: "Tablet", label: "Tablet" },
    { value: "Television", label: "Television" },
    { value: "Webcam", label: "Webcam" },
    { value: "Laptop", label: "Laptop" },
    { value: "Printer", label: "Printer" },
    { value: "Router", label: "Router" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "Mouse", label: "Mouse" },
    { value: "Monitors", label: "Monitors" },
    { value: "Desktop", label: "Desktop" },
  ];

  deviceMake.args = {
    ...deviceMake.args,
    label: homeplusscript.deviceMake_label,
    options: deviceMakes,
    value: selectedDeviceMake.value,
    onSelect: setDeviceMake,
  };

  

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default EnrollDevice;
