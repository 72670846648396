import { useContext, useState, useEffect } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function IsDeviceBuiltIn() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectKeyboardType']
  const homeplusscript = workflowState['config']?.scripts["SelectKeyboardType"];

  const [keyboardType, setKeyboardType] = useState([]);
  const [selectedKeyboardTypeOption, setSelectedKeyboardTypeOption] = useState(
    {}
  );

  let isMechanicalNonMechanical = findById(
    metadata,
    "isMechanicalNonMechanical"
  );
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtTypeInfo = findById(metadata, "txtTypeInfo");
  let menu = findById(metadata, "menu");
  let txtTitle = findById(metadata, "txtTitle");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;

  useEffect(() => {
    if (isKeyboard) {
      let keyboardTypesList = [];
      const keyboardTypes = !isEmpty(regDeviceDetails?.selectedBrandDetails)
        ? regDeviceDetails.selectedBrandDetails.Type
        : regDeviceDetails?.distinctKeyboardTypeList;
      keyboardTypes.forEach((type) => {
        if (!isEmpty(type)) {
          let deviceType = {
            value: type,
            label: type,
          };
          keyboardTypesList.push(deviceType);
        }
      });
      setKeyboardType(keyboardTypesList);
    }
  }, []);

  const onContinueClick = (typeOption) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        keyboardType: typeOption?.value,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
       connectivityType: isKeyboard,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const keyboardTypeSelection = (typeOption) => {
    setSelectedKeyboardTypeOption({
      ...selectedKeyboardTypeOption,
      ...typeOption,
    });

    onContinueClick(typeOption)

  };

  isMechanicalNonMechanical.args = {
    ...isMechanicalNonMechanical.args,
    deviceItems: keyboardType,
    value: selectedKeyboardTypeOption?.value,
    mainDivDisplayClasses:
      regDeviceDetails?.selectedCategory.type.toUpperCase() ===
      DEVICE_CATEGORY.TV
        ? "hidden"
        : "block",
    onClick: keyboardTypeSelection,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtTypeInfo.args = {
    ...txtTypeInfo.args,
    content: homeplusscript.deviceTypeInfo,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default IsDeviceBuiltIn;
