import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata, isEmpty } from "./helper";
import Popup from "reactjs-popup";
import NoProceedPopup from "./my-claim/NoProceedPopup";

function WishNotProceedPopup(props) {
    const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

    const  {setWishNotProceedPopup} = props;
    const metadata = workflowState['config']?.metadata['WishNotProceedPopup']
    const homeplusscript = workflowState['config']?.scripts["WishNotProceedPopup"];
    const [isExpanded, setIsExpanded] = useState(true);
    const [openNoProceedPopup, setNoProceedPopup] = useState(false);

    const contentStyle = {
        padding: "20px",
        width: "345px",
        height: "314px",
        margin: "auto",
        background: "white",
        position: "relative",
        bottom: "0",
        border: "2px groove ",
        borderRadius: "5px",
        borderColor: "",
        textColor: "",
      };
    

let textHeader = findById(metadata, "titleHeader");
let btnNo = findById(metadata, "btnNo");
  let btnYes = findById(metadata, "btnYes");
  let icon = findById(metadata, "icon")

    const open = (isExpanded) => {
      setWishNotProceedPopup(false);
        setIsExpanded(!isExpanded);
      };

      const showPopup = () => {
          setNoProceedPopup(!openNoProceedPopup);
        };

    textHeader.args = { ...textHeader.args, content: homeplusscript.titleHeader };
    
    const onYesClick = () => {
      showPopup()
      };
     const onNoClick = () => {
      return completeCurrentStep(currentStep, {
      onNo: true,
      });
        
      };

    btnYes.args = {
        ...btnYes.args,
        label: homeplusscript.btnYes,
        enabled: true,
        onClick: (e) => onYesClick(e),
      };
    
      btnNo.args = {
        ...btnNo.args,
        label: homeplusscript.btnNo,
        enabled: true,
         onClick: (e) => onNoClick(),
      };

       icon.args = {
         ...icon.args,
         onClick:  () => open(isExpanded),
       };
      return(
      <>
      <Stack orientation="vertical" metadata={metadata} />
      <Popup
        contentStyle={contentStyle}
        open={openNoProceedPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <NoProceedPopup
          openNoProceedPopup={openNoProceedPopup}
          setNoProceedPopup={setNoProceedPopup}
        />
      </Popup>
    </>)
}
export default WishNotProceedPopup