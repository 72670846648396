import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata, isEmpty } from "../enrollment-workflow/helper";
import API from "../../services";
import logger from "../../logger.service";
import ActionTypes from "../../ActionTypes";
import ValidEmail from "./validemail";

const ContactUs = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ContactUs']
  const homeplusscript = workflowState['config']?.scripts["ContactUs"];

  let ContactUsDetails = workflowState["ContactUsDetails"];

  const [username, setUserName] = useState("");
  const [mobileNumber, setMobileNumber] = useState({});
  const [insuredReferenceNo, setInsuredReferenceNo] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [inquiryDescription, setinquiryDescription] = useState("");
  const [isDeclarationSelected, setDeclarationSelected] = useState(false);
  const [isRequiredMobile, setisRequiredMobile] = useState(true);
  const [showMDNLengthError, setShowMDNLengthError] = useState(false);
  const [mobileNumberLengthCheck, setMobileNumberLengthCheck] = useState(0);
  const [showInquiryDescriptionError, setInquiryDescriptionError] =
    useState(false);
  const [showSalutionError, setShowSalutionError] =
    useState(false);
    const [showFullNameError, setShowFullNameError] =
    useState(false);
    const [showInquiryTypeError, setShowInquiryTypeError] =
    useState(false);
  const [selectedSalution, setSelectedSalution] = useState({
    value: "",
    label: "Select",
  });
  const [selectedInquiryReason, setReason] = useState({
    value: "",
    label: "Select",
  });

  const salution = [
    { value: "Select", label: "Select" },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Miss.", label: "Miss." },
    { value: "Ms.", label: "Ms." },
    { value: "Mdm.", label: "Mdm." },
    { value: "Dr.", label: "Dr." },
  ];

  const inquiryreason = [
    { value: "Select", label: "Select" },
    { value: "Policy Termination", label: "Policy Termination" },
    { value: "Product related", label: "Product related" },
    { value: "Policy related", label: "Policy related" },
    {
      value: "Device registration related",
      label: "Device registration related",
    },
    { value: "Claims processing", label: "Claims processing" },
    { value: "Claims Fulfillment", label: "Claims Fulfillment" },
    {
      value: "Premiums / Deductible / Overage",
      label: "Premiums / Deductible / Overage",
    },
    {
      value: "Tech Support / Security Advisor",
      label: "Tech Support / Security Advisor",
    },
    { value: "VOC / Complaint", label: "Feedback" },
    { value: "Others", label: "Others" },
  ];

  const setSelectedSalutions = (salution) => {
    setSelectedSalution({
      ...selectedSalution,
      ...salution,
    });
  };

  const setSelectedInquiryReason = (inquiryreason) => {
    setReason({
      ...selectedInquiryReason,
      ...inquiryreason,
    });
  };

  const onMobileNumberChange = (value, country, e, formattedValue) => {
    setMobileNumber({
      value: value,
      country: country,
      formattedValue: formattedValue,
    });
    setMobileNumberLengthCheck(value.length);
  };

  const handleUserName = (value) => {
    let isAlpha = /^[a-zA-Z ]*$/;
    if (isAlpha.test(value)) {
      setUserName(value);
    }
  };

  const handleInsuredReference = (value) => {
    let isAlpha = /^[a-zA-Z0-9]*$/;
    if (isAlpha.test(value) && value.length <= 14) {
      setInsuredReferenceNo(value);
    }
  };
  const handleError = (input) => {
    setEmail(input);
  };
  let regUserName = findById(metadata, "registerUserName");
  // let nationalID = findById(metadata, "nationalID");
  let inputMobileNumber = findById(metadata, "inputMobileNumber");
  let inputEmail = findById(metadata, "inputEmail");
  let textAreaLimit = findById(metadata, "textAreaLimit");
  let invalidEmailText = findById(metadata, "invalidEmail");
  let contactNumberLength = findById(metadata, "contactNumberLength");
  let inquiryDescriptionError = findById(metadata, "inquiryDescriptionError");
  let insuredReferenceNumber = findById(metadata, "insuredReferenceNumber");
  let menu = findById(metadata, "menu");
  let btnSubmit = findById(metadata, "btnSubmit");
  let btnCancel = findById(metadata, "btnCancel");
  let salutionSelection = findById(metadata, "salutionSelection");
  let reasonForInquiry = findById(metadata, "reasonForInquiry");
  let textAreaInquiryDetails = findById(metadata, "textAreaInquiryDetails");
  let txtTitle = findById(metadata, "txtTitle");
  let selfDeclaration = findById(metadata, "selfDeclaration");
  let requiredMobileNumberText = findById(metadata, "requiredMobileNumber");
  let inquiryTypeError = findById(metadata, "inquiryTypeError");
  let SalutionError = findById(metadata, "SalutionError");
  let FullNameError = findById(metadata, "FullNameError");

  let contactDetails = workflowState["ContactUsDetails"];
  const selfDeclarations = [
    {
      value: `By submitting this form, you agree to Liberty Insurance Pte Ltd (Liberty) and NEW Asurion Singapore Pte Ltd (Asurion) appointed as Liberty's claims handler, collecting, using, disclosing, storing, transferring and hosting your personal data in accordance with<a class="underline text-green-900" href="https://www.libertyinsurance.com.sg/data-protection-policy" class='text-green'> Liberty's Data Protection Policy</a> and <a class="underline text-green-900" href=" https://www.asurion.com.sg/eng/privacy-policy/"> Asurion's Privacy Policy</a>.`,
      label: `By submitting this form, you agree to Liberty Insurance Pte Ltd (Liberty) and NEW Asurion Singapore Pte Ltd (Asurion) appointed as Liberty's claims handler, collecting, using, disclosing, storing, transferring and hosting your personal data in accordance with<a class="underline text-green-900" href="https://www.libertyinsurance.com.sg/data-protection-policy" class='text-green'> Liberty's Data Protection Policy</a> and <a class="underline text-green-900" href=" https://www.asurion.com.sg/eng/privacy-policy/"> Asurion's Privacy Policy</a>.`,
    },
  ];

  const onSelect = (item, event) => {
    setDeclarationSelected(event.currentTarget.checked);
  };

  const getSelectedAttributes = (separator) => {
    let inquiryDescriptions = `${
      !isEmpty(insuredReferenceNo)
        ? "Insured_Reference_Number: " + insuredReferenceNo + separator
        : ""
    }${
      !isEmpty(inquiryDescription)
        ? "Inquiry Description: " + inquiryDescription + separator
        : ""
    }`;
    return inquiryDescriptions.charAt(inquiryDescriptions.length - 1) ===
      separator
      ? inquiryDescriptions.substring(0, inquiryDescriptions.length - 1)
      : inquiryDescriptions;
  };
  const createContactUsInquiry = () => {
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: mobileNumber?.value.slice(2),
        FirstName: username,
        LastName: "",
        ContactNumber: mobileNumber?.value.slice(2),
        ClientName: workflowState["config"]?.ClientName,
        InquiryChannel: "ONLINE",
        InquiryType: selectedInquiryReason?.value,
        // InquirySubType: "",
        InquiryDescription: getSelectedAttributes("|", false),
        ClientId: workflowState["config"]?.ClientId,
        Email: email,
        DepartmentType: "Care",
        Priority: "Medium",
        ProgramName: "HomePlus",
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        PolicyNumber: insuredReferenceNo,
      },
    };

    API[ActionTypes.CREATE_ENQUIRY_V1](CreateInquiryParams)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          enquiryResponse: data,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_ENQUIRY_V1, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };
 
  const onSubmit = () => {
    let vaildEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emailtest = vaildEmailRegex.test(email);

    if (vaildEmailRegex.test(email)) {
      console.log("Valid Email Address");
      setIsValidEmail(true);
    } else {
      showLoader(false);
      setIsValidEmail(false);
      console.log("Invalid Email Address");
    }

    if (!isEmpty(mobileNumber)) {
      setisRequiredMobile(true);
      if (mobileNumberLengthCheck < 10) {
        setShowMDNLengthError(true);
      } else {
        setShowMDNLengthError(false);
      }
    } else {
      showLoader(false);
      setisRequiredMobile(false);
    }

    if (inquiryDescription.length < 15) {
      setInquiryDescriptionError(true);
    } else {
      setInquiryDescriptionError(false);
    }


    if (selectedInquiryReason.label === "Select") {
      setShowInquiryTypeError(true);
    } else {
      setShowInquiryTypeError(false);
    }

    if (selectedSalution.label === "Select") {
      setShowSalutionError(true);
    } else {
      setShowSalutionError(false);
    }
    if (isEmpty(username)) {
      setShowFullNameError(true);
    } else {
      setShowFullNameError(false);
    }

    if (
      mobileNumberLengthCheck === 10 &&
      emailtest === true &&
      inquiryDescription.length >= 15 &&
      selectedInquiryReason.label !== "Select" &&
      !isEmpty(username) &&
      selectedSalution.label !== "Select"
    ) {
      
      updateState({
        ...workflowState,
        ContactUsDetails: {
          username,
          mobileNumber,
          insuredReferenceNo,
          email,
          selectedSalution,
          inquiryDescription,
          selectedInquiryReason,
          isDeclarationSelected,
        },
      });
      createContactUsInquiry();
      return completeCurrentStep(currentStep, {
        OnSubmit: true,
      });
    } else {
    }
  };

  const onGoBack = () => {
    return completeCurrentStep(currentStep, {
      OnGoBack: true,
    });
  };

  invalidEmailText.args = {
    ...invalidEmailText.args,
    displayClasses: isValidEmail ? "hidden" : "block",
  };
  contactNumberLength.args = {
    ...contactNumberLength.args,
    displayClasses: showMDNLengthError ? "block" : "hidden",
  };

  inquiryDescriptionError.args = {
    ...inquiryDescriptionError.args,
    displayClasses: showInquiryDescriptionError ? "block" : "hidden",
  };
  inquiryTypeError.args = {
    ...inquiryTypeError.args,
    displayClasses: showInquiryTypeError ? "block" : "hidden",
  };
  
  SalutionError.args = {
    ...SalutionError.args,
    displayClasses: showSalutionError ? "block" : "hidden",
  };

  FullNameError.args = {
    ...FullNameError.args,
    displayClasses: showFullNameError ? "block" : "hidden",
  };

  regUserName.args = {
    ...regUserName.args,
    label: homeplusscript.labelFullName,
    value: username,
    onChange: handleUserName,
  };

  insuredReferenceNumber.args = {
    ...insuredReferenceNumber.args,
    value: insuredReferenceNo,
    onChange: handleInsuredReference,
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
  };

  inputEmail.args = {
    ...inputEmail.args,
    label: homeplusscript.inputEmail_label,
    placeHolder: homeplusscript.inputEmail_placeHolder,
    value: email,
    onChange: handleError,
  };

  inputMobileNumber.args = {
    ...inputMobileNumber.args,
    label: homeplusscript.inputMobile_label,
    placeHolder: homeplusscript.inputMobile_placeHolder,
    value: mobileNumber.value,
    onChange: onMobileNumberChange,
  };
  requiredMobileNumberText.args = {
    ...requiredMobileNumberText.args,
    displayClasses: isRequiredMobile ? "hidden" : "block",
  };

  salutionSelection.args = {
    ...salutionSelection.args,
    label: homeplusscript.salutionSelection_label,
    placeHolder: homeplusscript.Salution_placeholder,
    options: salution,
    value: selectedSalution.value,
    onSelect: setSelectedSalutions,
  };

  reasonForInquiry.args = {
    ...reasonForInquiry.args,
    label: homeplusscript.reasonForInquiry_label,
    options: inquiryreason,
    value: selectedInquiryReason.value,
    onSelect: setSelectedInquiryReason,
  };
  textAreaInquiryDetails.args = {
    ...textAreaInquiryDetails.args,
    label: homeplusscript.txtArea_label,
    placeHolder: homeplusscript.textAreaInquiryDetailsPlaceHolder,
    value: inquiryDescription,
    onChange: setinquiryDescription,
  };
  selfDeclaration.args = {
    ...selfDeclaration.args,
    options: selfDeclarations,
    onSelect: onSelect,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
  };

  btnSubmit.args = {
    ...btnSubmit.args,
    label: homeplusscript.btnSubmit,
    enabled: isDeclarationSelected === true,
    onClick: (e) => onSubmit(),
  };

  btnCancel.args = {
    ...btnCancel.args,
    label: homeplusscript.btnCancel,
    enabled: true,
    onClick: () => onGoBack(),
  };
  textAreaLimit.args = {
    ...textAreaLimit.args,
    content: `<div>${
      inquiryDescription ? inquiryDescription.length : 0
    }/2000</div>`,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
};
export default ContactUs;
