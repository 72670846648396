import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";

function ServiceUnavailable() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ServiceUnavailable']
  const homeplusscript = workflowState['config']?.scripts["ServiceUnavailable"];

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ServiceUnavailable;
