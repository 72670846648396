import { Button } from "./components/button/button";
import { Input } from "./components/input/input";
import { Text } from "./components/text/text";
import { Stack } from "./components/stack/stack";
import { List } from "./components/list/list";
import { Grid } from "./components/grid/grid";
import { Menu } from "./components/menu/menu";
import { Image } from "./components/image/image";
import { Icon } from "./components/icon/icon";
import { Otp } from "./components/otp/otp";
import { Link } from "./components/link/link";
import { ToggleSwitch } from "./components/toggle-switch/toggle-switch";
import { PolicyInfoItem } from "./components/policy-information/policy-info-item";
import { Select } from "./components/select/select";
import { DeviceItems } from "./components/device-items/device-items";
import { FileUploader } from "./components/file-uploader/file-uploader";
import { AsyncSelect } from "./components/async-select/async-select";
import { DeviceIssue } from "./components/device-issue/device-issue";
import { TextArea } from "./components/textarea/textarea";
import { Checkbox } from "./components/checkbox/checkbox";
import { Footer } from "./components/footer/Footer";
import Timer from "./components/timer/timer";
import Table from "./components/table/table";
import { Expandable} from "./components/expandable/expandable";
import { ExpandableInfo } from "./components/expandable/expandableinfo";
import { Calender } from "./components/calender/calender";
import MobileNumber from "./components/mobile-number/MobileNumber";
import { ClaimForRepair } from "./components/claim-for-repair/claim-for-repair";
import { Carousel } from "./components/carousel/carousel";
import { OptionPage } from "./components/options/option-pages";
import { Info } from "./components/info/info";
import { TriageQuestion } from "./components/triage-question/triage-question";
import { CardNumberInput } from "./components/cardNumber-input/cardNumber-input";
import {ToggleQuestion} from "./components/expandable/questionList";
import {PolicyHeader} from "./components/myDoc-policy/policyHeader"
import {PolicyDocuments} from "./components/myDoc-policy/policyDocuments"
import { ChatWidgetv2 } from "./components/chat/ChatWidgetv2";
import { WebCam } from "./components/video-player/web-cam";
import { VideoPlayer } from "./components/video-player/video-player";
import { RadioButton } from "./components/radiobutton/radiobutton";
import { ExpiryDate } from "./components/expiryDate/expiryDate";
import { CVV } from "./components/cvv/cvv";
import { CardNumber } from "./components/cardNumber/cardNumber";
import {CardHolderName } from "./components/card-holder-name/cardHolderName"
import { Paypal } from "./components/paypal/button";
import { Gpay } from "./components/gpay/button";
import PaymentPage from "./components/enrollment-workflow/file-claim/paymentpage"; 

export const ControlLocator = {
  div: (args) => <div {...args}></div>,
  Input: (args) => <Input {...args} />,
  Text: (args) => <Text {...args} />,
  Button: (args) => <Button {...args} />,
  Stack: (args) => <Stack {...args} />,
  List: (args) => <List {...args} />,
  Grid: (args) => <Grid {...args} />,
  Menu: (args) => <Menu {...args} />,
  Image: (args) => <Image {...args} />,
  Icon: (args) => <Icon {...args} />,
  Otp: (args) => <Otp {...args} />,
  Link: (args) => <Link {...args} />,
  ToggleSwitch: (args) => <ToggleSwitch {...args} />,
  PolicyInfoItem: (args) => <PolicyInfoItem {...args} />,
  Select: (args) => <Select {...args} />,
  AsyncSelect: (args) => <AsyncSelect {...args} />,
  DeviceItems: (args) => <DeviceItems {...args} />,
  FileUploader: (args) => <FileUploader {...args} />,
  DeviceIssue: (args) => <DeviceIssue {...args} />,
  TextArea: (args) => <TextArea {...args} />,
  Checkbox: (args) => <Checkbox {...args} />,
  Footer: (args) => <Footer {...args} />,
  Timer: (args) => <Timer {...args} />,
  Table: (args) => <Table {...args} />,
  Expandable: (args) => <Expandable {...args} />,
  Calender: (args) => <Calender {...args} />,
  MobileNumber: (args) => <MobileNumber {...args} />,
  ClaimForRepair: (args) => <ClaimForRepair {...args} />,
  Carousel:(args) => <Carousel {...args} />,
  OptionPage:(args) => <OptionPage {...args} />,
  Info:(args) => <Info {...args} />,
  TriageQuestion:(args) => <TriageQuestion {...args} />,
  ExpandableInfo: (args) => <ExpandableInfo {...args} />,
  CardNumberInput:(args)=><CardNumberInput {...args}/>,
  CardNumber:(args)=><CardNumber {...args}/>,
  ExpiryDate:(args)=><ExpiryDate {...args}/>,
  CVV:(args)=><CVV {...args}/>,
  CardHolderName:(args)=><CardHolderName {...args}/>,
  Paypal:(args)=><Paypal {...args}/>,
  Gpay:(args)=><Gpay {...args}/>,
  ToggleQuestion:(args)=><ToggleQuestion {...args}/>,
  Chat: (args) => <ChatWidgetv2 {...args} />,
  PolicyHeader: (args) => <PolicyHeader {...args} />,
  PolicyDocuments: (args) => <PolicyDocuments {...args} />,
  WebCam: (args) => <WebCam {...args} />,
  VideoPlayer: (args) => <VideoPlayer {...args} />,
  RadioButton: (args) => <RadioButton {...args} />,
  PaymentPage: (args) => <PaymentPage {...args} />
};
