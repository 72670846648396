import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

export const VideoPlayer = ({ src, showPlayer, className, ...props }) => {

  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  return (
    <div className={`text-center ${showPlayer ? "block" : "hidden"}`}>
      <video
        className={className}
        style={{
          height: isDesktop ? "320px" : "auto",
          objectFit: "cover",
          // borderRadius: "5px",
          margin: "auto",
          width: "480px",
          // marginTop: "20px",
          // marginBottom: "20px"
        }}
        controls
        autoPlay
        src={src}
        playsInline
        preload="none"
      />
    </div>
  );
};

VideoPlayer.propTypes = {};

VideoPlayer.defaultProps = {};
