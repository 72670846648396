/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.9575 23.25C4.87957 23.2087 4.79953 23.1703 4.71954 23.1318C4.53778 23.0445 4.35624 22.9573 4.2 22.8375C3.9225 22.62 3.78 22.2975 3.765 21.9375C3.75 21.7125 3.75 21.4875 3.75 21.255V2.43C3.75 2.2425 3.75 2.0475 3.795 1.86C3.9525 1.2075 4.5225 0.75 5.205 0.75H8.85H18.45C19.185 0.75 19.7625 1.1775 19.92 1.86C19.965 2.04 19.965 2.235 19.965 2.43V21.4725C19.965 22.5225 19.725 22.875 18.7575 23.25H4.9575ZM4.455 11.9775V21.645C4.455 22.2825 4.725 22.5525 5.37 22.5525H18.3525H18.5475C18.9225 22.53 19.2075 22.2675 19.2525 21.8925C19.26 21.795 19.26 21.705 19.26 21.6075V2.4075V2.2125C19.2375 1.755 18.9375 1.4625 18.48 1.4625H5.235C4.9875 1.4625 4.7475 1.5375 4.6275 1.7475C4.5225 1.9275 4.4625 2.16 4.4625 2.3625C4.4575 4.50221 4.45917 6.63859 4.46083 8.77608V8.77611C4.46167 9.84528 4.4625 10.9147 4.4625 11.985L4.455 11.9775ZM11.8723 19.86C12.4273 19.86 12.8698 20.3175 12.8698 20.8725C12.8698 21.42 12.4123 21.8775 11.8573 21.8775C11.3098 21.8775 10.8523 21.42 10.8523 20.8725C10.8523 20.3025 11.3023 19.86 11.8723 19.86ZM12.1648 20.865C12.1648 20.7 12.0298 20.565 11.8573 20.565C11.6848 20.565 11.5498 20.7 11.5498 20.865C11.5498 21.03 11.6923 21.1725 11.8573 21.1725C12.0223 21.1725 12.1648 21.03 12.1648 20.865ZM11.8722 2.81997C12.0672 2.81997 12.2097 2.66247 12.2097 2.46747C12.2022 2.27997 12.0372 2.12247 11.8497 2.12247C11.6622 2.12997 11.5122 2.29497 11.5122 2.48247C11.5197 2.67747 11.6772 2.81997 11.8722 2.81997Z" fill="#1ED760" />
    </svg>

)

export default icon