import { useContext, useEffect, useState } from "react";
import { Stack } from "../../stack/stack";
import { findById } from "../helper";
import { WorkflowContext } from "../../workflow/workflow-context";

function VideoDeclaration() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['VideoDeclaration']
  const homeplusscript = workflowState['config']?.scripts["VideoDeclaration"];

  const txtTitle = findById(metadata, "txtTitle");
  const txtSubTitle = findById(metadata, "txtSubTitle");
  const expandableInfo = findById(metadata, "expandableInfo");
  const txtInfo = findById(metadata, "txtInfo");
  const btnRecord = findById(metadata, "btnRecord");
  const webCam = findById(metadata, "webCam");
  const videoPlayer = findById(metadata, "player");
  const logo = findById(metadata, "logo");

  const btnRetake = findById(metadata, "btnRetake");
  const btnContinue = findById(metadata, "btnContinue");
  const btnContainer = findById(metadata, "btnContainer");

  const [capturing, setCapturing] = useState("INIT");
  const [src, setSrc] = useState(null);
  const [blob, setBlob] = useState(null);

  const startCapture = () => {
    setCapturing("START");
  };

  const stopCapture = () => {
    setCapturing("STOP");
  };

  const toggleRecordButton = () => {
    if (capturing === "INIT" || capturing === "STOP") {
      startCapture();
    } else stopCapture();
  };

  const showDeclarationMessage = () => {

    updateState({
      ...workflowState,
      FileClaimDetails: {
        VideoDeclaration: {
          VideoBlob : blob
        }
      },
    }, ["FileClaimDetails"]);

    return completeCurrentStep(currentStep, {
      showMessage: true,
    });
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
    displayClasses: capturing === "INIT" ? "block" : "hidden",
  };

  txtSubTitle.args = {
    ...txtSubTitle.args,
    content: homeplusscript.txtSubTitle,
    displayClasses: capturing === "INIT" ? "block" : "hidden",
  };

  expandableInfo.args = {
    ...expandableInfo.args,
    content: homeplusscript.expandableInfo,
    displayClasses: capturing === "INIT" || capturing === "START" ? "block" : "hidden",
    expanded: capturing === "START" ? true : false,
    marginClasses: 
      capturing === "INIT" ? expandableInfo.args.marginClassesInit : expandableInfo.args.marginClassesStart 
  };

  txtInfo.args = {
    ...txtInfo.args,
    content: homeplusscript.txtInfo,
    displayClasses: capturing === "INIT" || capturing === "STOP" ? "block" : "hidden",
    marginClasses: 
      capturing === "INIT" ? txtInfo.args.marginClassesInit : txtInfo.args.marginClassesStop 
  };

  btnRecord.args = {
    ...btnRecord.args,
    label:
      capturing === "INIT" ? homeplusscript.btnRecord : homeplusscript.btnStop,
    displayClasses:
      capturing === "INIT" || capturing === "START" ? "block" : "hidden",
    colorClasses: 
      capturing === "START" ? btnRecord.args.colorClassesStopBtn : btnRecord.args.colorClassesRecordBtn,
    onClick: () => toggleRecordButton(),
    dimensionClasses: 
      capturing === "INIT" ? btnRecord.args.dimensionClassesRecordBtn : btnRecord.args.dimensionClassesStopBtn
  };

  btnRetake.args = {
    ...btnRetake.args,
    label: homeplusscript.btnRetake,
    onClick: () => toggleRecordButton(),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    onClick: () => showDeclarationMessage(),
  };

  btnContainer.args = {
    ...btnContainer.args,
    displayClasses: capturing === "STOP" ? "block" : "hidden",
  };

  logo.args = {
    ...logo.args,
    displayClasses: capturing === "INIT" ? "block" : "hidden",
  };

  webCam.args = {
    ...webCam.args,
    showWebcam: capturing === "START" ? true : false,
    capture: capturing,
    setCapturing,
    setSrc,
    setBlob
  };

  videoPlayer.args = {
    ...videoPlayer.args,
    showPlayer: capturing === "STOP" ? true : false,
    src,
  };

  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />;
    </>
  );
}
export default VideoDeclaration;
