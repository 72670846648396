import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import initAmplifyConfig from "../enrollment-workflow/amplify-config";

const WorkflowContext = createContext({})


const { Provider } = WorkflowContext

const useWorkflow = (initialStep) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [workflowState, setWorkflowState] = useState({});
  const navigate = useNavigate();

  const completeCurrentStep = (getNextStep, step, data) => {
    let newWFState = { [step]: data };
    console.log("workflowState", newWFState)

    // setWorkflowState(s => ({ ...s, ...newWFState }))
    updateState(newWFState, [step])
    console.log('Workflow', workflowState?.config?.workflow, data.workflow)
    let nextStep = getNextStep(workflowState?.config?.workflow || data.workflow, newWFState, step);
    setCurrentStep(nextStep);
    navigate(nextStep);
  };

  const mergeState = (currentState, modifiedState, keysToUpdate) => {
    if(keysToUpdate && keysToUpdate.length > 0){
      // merge only the keys
      let newState = {...currentState}
      keysToUpdate.forEach(key => {
        // console.log("updating keys", key, modifiedState[key], newState[key], typeof(modifiedState[key]), typeof(modifiedState[key]) === "object");
        if(typeof(modifiedState[key]) === "object"){
          Object.assign(newState, {[key]:{...(newState[key] || {}), ...modifiedState[key]}})
          // console.log("modified state", newState);
        }else{
          Object.assign(newState, {[key] : modifiedState[key]})
        }
      });
      console.log("modified state", newState);

      return newState;
    }
    const finalState = {...currentState, ...modifiedState}
    console.log("modified state", finalState);

    return finalState;
  }

  const updateState = (state, keys) => {
    setWorkflowState(s => mergeState(s, state, keys))
  }

  const refreshStateOnLogout = (state, redirectPage) => {
    // this is getting called on logout button
    console.log("ButtonCLicked : ", redirectPage);

    if (redirectPage !== "chat") {
      navigate("/"+ redirectPage)
    }
    
    if(redirectPage === "login"){
      let workflow = {}
      workflow.config = state["config"]
      setWorkflowState(workflow);
      setCurrentStep('login');
    }
    else if (redirectPage === "myprofile"){
      setWorkflowState(state);
      setCurrentStep('myprofile');
    }
    else if (redirectPage === "dashboard"){
      state["RegDeviceDetails"] = {}
      state["FileClaimDetails"] = {}
      setWorkflowState(state);
      setCurrentStep(redirectPage);
    }
    else if (redirectPage === "my-claim-for-repair"){
      setWorkflowState(state);
      setCurrentStep(redirectPage);
    }
    else if (redirectPage === "select-device"){
      setWorkflowState(state);
      setCurrentStep('select-device');
    }
    else if (redirectPage === "homepage"){
      setWorkflowState(state);
      setCurrentStep('homepage');
    }

  }

  return [currentStep, completeCurrentStep, workflowState, updateState, refreshStateOnLogout]
}


const WorkflowProvider = ({ getNextStep, initialStep, initialization, children, showLoader }) => {
  initAmplifyConfig()

  const [currentStep, completeCurrentStep, workflowState, updateState, refreshStateOnLogout] = useWorkflow(initialStep)
  useEffect(() => {
    initialization(workflowState, updateState);
  }, [])
  return <Provider value={[currentStep, (step, data) => completeCurrentStep(getNextStep, step, data), workflowState, updateState, showLoader, refreshStateOnLogout]}>{children}</Provider>
}

export { WorkflowContext, WorkflowProvider }