import * as React from "react"

const InfoIconSvg = (props) => (
  <svg
    width={props.width || 16}
    height={props.height || 26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 21A8 8 0 1 0 8 5a8 8 0 0 0 0 16Z" fill="#685BC7" />
    <path
      d="M9.95 16.54V18H6.04v-1.46H7v-3.39h-.96v-1.48h2.97v4.87h.94Zm-.81-7.43c0 .32-.11.59-.33.8-.22.21-.48.32-.78.32-.3 0-.57-.11-.79-.33-.22-.22-.33-.48-.33-.79 0-.31.11-.56.33-.78.22-.22.48-.33.79-.33.31 0 .56.11.78.33.22.22.33.48.33.78Z"
      fill="#fff"
    />
  </svg>
)

export default InfoIconSvg
