import moment from "moment";
import { ChatType } from "./service/ChatService";

export const isMobileWidth = () => {
  return window.innerWidth < 600
}

export const parseAttributeFromString = (html, attr) => {
  let elem = document.createElement('p');
  elem.innerHTML = html;
  elem.setAttribute('id', 'elem-temp');
  let a = elem.getElementsByTagName('a');

  let attrValue = '';
  if (a.length > 0) {
    attrValue = a[0].getAttribute(attr);
  }

  elem.remove();
  return attrValue;
};

export const reverseString = (str) => {
  let splitString = str.split('');
  let reverseArray = splitString.reverse();
  let reverse = reverseArray.join('');

  return reverse;
};

export const convertToTime = (dateStr) => moment(dateStr).format("HH:mm:ss A");

/**
 * Checks if the current chat is inside the business hours
 * @param {ChatType} chatType 
 * @returns boolean
 */
export const withinOperatingHours = (chatType = ChatType.SOLUTO) => {
  let open, closed, days;
  if (chatType === ChatType.SOLUTO) {
    open = process.env.REACT_APP_SOLUTO_BUSINESS_OPEN_HOUR;
    closed = process.env.REACT_APP_SOLUTO_BUSINESS_CLOSED_HOUR;
    days = process.env.REACT_APP_SOLUTO_BUSINESS_DAYS.split(',')
  } else if (chatType === ChatType.DP) {
    open = process.env.REACT_APP_DP_BUSINESS_OPEN_HOUR;
    closed = process.env.REACT_APP_DP_BUSINESS_CLOSED_HOUR;
    days = process.env.REACT_APP_DP_BUSINESS_DAYS.split(',')
  }

  const marketOpen = open * 60 // minutes 9AM
  const marketClosed = closed * 60  // minutes 9PM
  let now = new Date();
  // let now = new Date('September 3, 2022 @ 5:00:00 pm')
  let day = now.getDay().toString();
  let currentTime = now.getHours() * 60 + now.getMinutes(); // Minutes since Midnight

  if (currentTime > marketOpen && currentTime < marketClosed && days.indexOf(day) >= 0) {
    return true
  }
}


