import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import Loader from './Loader';
// import Loader from './Loader';

// export interface Props {
//   show: boolean;
//   content: any;
//   loaderText?: string;
//   onClose?: Function;
//   onClickYes?: Function;
//   onClickNo?: Function;
//   disableEscapeKeyDown?: boolean;
//   disableBackdropClick?: boolean;
//   showActions?: boolean;
//   title?: string;
//   yesLabel?: string;
//   noLabel?: string;
// }

export const ConfirmDialog = ({
  show,
  content,
  loaderText = 'loading...',
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  showActions = true,
  title = 'Confirm',
  yesLabel = 'Yes',
  noLabel = 'No',
  onClickYes = () => {},
  onClickNo = () => {},
  onClose = () => {},
}) => {
  const classes = useStyles();

  const confirmDialog = useRef();

  useEffect(() => {
    return () => {
      // unmount
    };
  }, []);

  return (
    <Dialog
      className={classes.root}
      ref={confirmDialog}
      open={show}
      onClose={(event, reason) => {
        console.log(event);

        if (disableBackdropClick && reason === 'backdropClick') {
          return false;
        }

        if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
          return false;
        }

        if (typeof onClose === 'function') {
          onClose();
        }
        return false;
      }}
      // disableEscapeKeyDown={disableEscapeKeyDown}
      // disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      {showActions ? (
        <DialogActions className={classes.actions}>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClickYes(e);
            }}
            variant={'outlined'}
            color={'default'}
            className={classes.buttons}
          >
            Yes
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClickNo(e);
            }}
            variant={'contained'}
            color={'primary'}
            className={classes.buttons}
          >
            No
          </Button>
        </DialogActions>
      ) : (
        <Loader loadingText={'Deleting...'} rootClass={classes.loader} />
      )}
    </Dialog>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    float: 'right',
    width: 'auto',
  },
  buttons: {
    width: theme.spacing(12),
    minWidth: theme.spacing(12),
  },
  loader: {
    padding: '0 !important',
    margin: '0 !important',
    marginBottom: theme.spacing(1),
  },
}));

export default ConfirmDialog;
