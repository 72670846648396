import Workflow from "../workflow/workflow";
import { Route } from "react-router";
import Login from "./login";
import EmailOTP from "./email-otp";
import Dashboard from "./Dashboard";
import ClaimSummary from "./file-claim/claim-summary";
import CancelClaimMsgs from "./my-claim/cancelclaim-msgs";
import DontCancelClaimMsgs from "./my-claim/dontcancelclaim-msg";

import { useEffect, useContext, useState } from "react";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import DeviceAdditionalInfo from "./DeviceAdditionalInfo";
import DeviceRegistration from "./DeviceRegistration";
import EnrollDevice from "./EnrollDevice";
import ForgotEmail from "./ForgotEmail";
import ValidEmail from "./validemail";
import Loader from "../loader/loader";
import MyProfile from "./MyProfile";
import ContactUs from "./contact-us";
import RegisterDevice from "./device-registration/register-device";
import SelectDevice from "./device-registration/select-device";
import SelectDeviceBrand from "./device-registration/select-device-brand";
import SelectDeviceTechnology from "./device-registration/select-device-technology";
import SelectDeviceScreenSize from "./device-registration/select-device-screen-size";
import ProvideDeviceModelNumber from "./device-registration/provide-device-model-number";
import AskPurchaseReceipt from "./device-registration/ask-purchase-receipt";
import ProvidePurchaseDate from "./device-registration/provide-purchase-date";
import ProvidePurchasePrice from "./device-registration/provide-purchase-price";
import UploadPurchaseReceipt from "./device-registration/upload-purchase-receipt";
import SelectGoogleTv from "./device-registration/select-google-tv";
import DeviceRegSummary from "./device-registration/device-reg-summary";
import IsDeviceBuiltIn from "./device-registration/provide-device-built-in";
import SelectDeviceConnectivityType from "./device-registration/select-device-connectivity";
import SelectDeviceFileClaim from "./file-claim/select-device-file-claim";
import SelectIncidentType from "./file-claim/select-incident-type";
import ProvideIssueDetails from "./file-claim/provide-issue-details";
import ProvideIssuePlace from "./file-claim/provide-issue-place";
import ProvideIssueObservedBy from "./file-claim/provide-issue-observed-by";
import SelectDeviceStorageCapacity from "./device-registration/select-device-storage-capacity";
import ProvideDeviceSerialNumber from "./device-registration/provide-device-serial-number";
import SelectDeviceModel from "./device-registration/select-device-model";
import CantRegisterDevice from "./device-registration/cant-reg-device";
import SelectDeviceStorageType from "./device-registration/select-device-storage-type";
import SelectDeviceProcessor from "./device-registration/select-device-processor";
import SelectDeviceRAM from "./device-registration/select-device-ram";
import SelectDeviceResolution from "./device-registration/select-device-resolution";
import SelectKeyboardType from "./device-registration/select-keyboard-type";
import SelectBluetooth from "./device-registration/select-bluetooth";
import SelectDeviceFunction from "./device-registration/select-device-function";
import SelectDeviceFrameRate from "./device-registration/select-device-frame-rate";
import HomePage from "./HomePage";
import ProvideIssueOccurenceDate from "./file-claim/provide-issue-occurence-date";
import ProvideDeviceOwner from "./file-claim/provide-device-owner";
import ProvideDeviceInsuranceDetails from "./file-claim/provide-device-insurance-details";
import ConfirmAddress from "./file-claim/confirm-address";
import Triage from "./file-claim/triage";
import MyClaimForRepair from "./MyClaimForRepair";
import CreditCardDetails from "./my-claim/creditCard-details";
import ScheduleOnsiteDate from "./file-claim/schedule-onsite-date";
import ClaimMessage from "./file-claim/claim-message";
import ClaimNotes from "./file-claim/claim-notes";
import ScheduleDelivery from "./my-claim/schedule-delivery";
import TriageError from "./file-claim/triage-error";
import OverageCancelClaim from "./my-claim/overage-cancel-claim";
import ReplacementOptions from "./my-claim/replacement-options";
import ReplacementOffer from "./my-claim/replacement-offer";
import OverageNotes from "./my-claim/overage-note";
import ContactUsNotes from "./contactus-thanks";
import ServiceUnavailable from "./ServiceUnavailable";
import FAQ from "./FAQ";
import MyDocuments from "./myDocuments";
import VideoDeclaration from "./file-claim/video-declaration";
import VideoDeclarationMessage from "./file-claim/video-declaration-message";
import PolicyAcceptance from "./PolicyAcceptance";
import BrainTree from "./my-claim/braintree";

function EnrollmentWorkflow() {
  const [loading, setLoading] = useState(false);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const matchCondition = (state, step) => {
    if (step.condition) {
      return step.condition
        .map((condition) => {
          let source = condition.src
            .split(".")
            .reduce((agr, item) => agr[item], state);
          if (condition.operator === "equal") {
            return condition.value === source;
          }
          return false;
        })
        .reduce((agr, item) => agr && item);
    }
    return true;
  };

  const getNextStep = (workflow, state, step) => {
    /*workflow = [
      {
        step: "homepage",
        next: [
          {
            step: "login",
            condition: [
              {
                operator: "equal",
                src: "homepage.Login",
                value: true
              }
            ]
          },
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "homepage.OnContact",
                value: true
              }
            ]
          }
        ],
      },
      {
        step: "login",
        next: [
          {
            step: "email-otp",
            condition: [
              {
                operator: "equal",
                src: "login.loggedIn",
                value: true,
              },
            ],
          },
          {
            step: "forgot-email",
            condition: [
              {
                operator: "equal",
                src: "login.forgotEmail",
                value: true,
              },
            ],
          },
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "login.OnContact",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "forgot-email",
        next: [
          {
            step: "login",
            condition: [
              {
                operator: "equal",
                src: "forgot-email.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "validemail",
            condition: [
              {
                operator: "equal",
                src: "forgot-email.ValidEmail",
                value: true,
              },
            ],
          },
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "forgot-email.OnContact",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "validemail",
        next: [
          {
            step: "login",
            condition: [
              {
                operator: "equal",
                src: "validemail.Login",
                value: true,
              },
            ],
          },

        ]
      },
      {
        step: "email-otp",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "email-otp.verifiedOtp",
                value: true,
              },
            ],
          },
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "email-otp.OnContact",
                value: true,
              },
            ],
          },
          {
            step: "policy-acceptance",
            condition: [
              {
                operator: "equal",
                src: "email-otp.OnPolicy",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "policy-acceptance",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "policy-acceptance.OnAcceptance",
                value: true,
              },
            ],
          },
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "policy-acceptance.OnContact",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "dashboard",
        next: [
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "dashboard.addDevice",
                value: true,
              },
            ],
          },
          {
            step: "select-device-file-claim",
            condition: [
              {
                operator: "equal",
                src: "dashboard.fileClaim",
                value: true,
              },
            ],
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "dashboard.goToMyClaim",
                value: true,
              },
            ],
          },
          {
            step: "policy-acceptance",
            condition: [
              {
                operator: "equal",
                src: "dashboard.OnPolicy",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "reg-device",
        next: [
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "reg-device.selectDevice",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "reg-device.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device",
        next: [
          {
            step: "select-device-brand",
            condition: [
              {
                operator: "equal",
                src: "select-device.selectInventory",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-brand",
        next: [
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-brand.provideModelNumber",
                value: true,
              },
            ],
          },
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "select-device-brand.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-brand.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-device-model-number",
        next: [
          {
            step: "select-device-technology",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.selectTechnology",
                value: true,
              },
            ],
          },

          {
            step: "select-device-brand",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-serial-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.provideSerialNumber",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-isbuiltin",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.isDeviceBuiltIn",
                value: true,
              },
            ],
          },
          {
            step: "select-device-resolution",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.selectResolution",
                value: true,
              },
            ],
          },
          {
            step: "select-device-resolution",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.selectWebcamRes",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-keyboardtype",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.isTypeBluetooth",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.selectConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "select-device-processor",
            condition: [
              {
                operator: "equal",
                src: "provide-device-model-number.selectProcessor",
                value: true,
              },
            ],
          },
        ],
      },

      {
        step: "select-device-resolution",
        next: [
          {
            step: "select-device-screen-size",
            condition: [
              {
                operator: "equal",
                src: "select-device-resolution.selectScreenSize",
                value: true,
              },
            ],
          },
          {
            step: "select-device-frame-rate",
            condition: [
              {
                operator: "equal",
                src: "select-device-resolution.selectFrameRate",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-resolution.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-resolution.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },

      {
        step: "provide-device-serial-number",
        next: [
          {
            step: "select-device-processor",
            condition: [
              {
                operator: "equal",
                src: "provide-device-serial-number.selectProcessor",
                value: true,
              },
            ],
          },
          {
            step: "select-device-storage-capacity",
            condition: [
              {
                operator: "equal",
                src: "provide-device-serial-number.selectStorageCapacity",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-serial-number.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "provide-device-serial-number.GoBackToConnectivity",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-processor",
        next: [
          {
            step: "select-device-ram",
            condition: [
              {
                operator: "equal",
                src: "select-device-processor.selectRAM",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-serial-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-processor.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-processor.GoBackModelNumber",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-ram",
        next: [
          {
            step: "select-device-storage-capacity",
            condition: [
              {
                operator: "equal",
                src: "select-device-ram.selectStorageCapacity",
                value: true,
              },
            ],
          },
          {
            step: "select-device-processor",
            condition: [
              {
                operator: "equal",
                src: "select-device-ram.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-ram.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-storage-capacity",
        next: [
          {
            step: "select-device-storage-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-capacity.selectStorageType",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-capacity.selectConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "select-device-ram",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-capacity.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-serial-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-capacity.GoBackSerialNumber",
                value: true,
              },
            ],
          },
          {
            step: "select-device-model",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-capacity.selectDeviceModel",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-storage-type",
        next: [
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-type.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "select-device-storage-capacity",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-type.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-storage-type.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-technology",
        next: [
          {
            step: "select-device-screen-size",
            condition: [
              {
                operator: "equal",
                src: "select-device-technology.selectScreenSize",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "select-device-technology.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-technology.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "select-device-function",
            condition: [
              {
                operator: "equal",
                src: "select-device-technology.selectDeviceFunction",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-screen-size",
        next: [
          {
            step: "select-google-tv",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.selectGoogleTv",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.deviceConnectivity",
                value: true,
              },
            ],
          },
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "select-device-technology",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "select-device-resolution",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.GoBackResolution",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-screen-size.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-google-tv",
        next: [
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "select-google-tv.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "select-device-screen-size",
            condition: [
              {
                operator: "equal",
                src: "select-google-tv.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-google-tv.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "ask-purchase-receipt",
        next: [
          {
            step: "upload-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.uploadPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "provide-purchase-date",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.providePurchaseDate",
                value: true,
              },
            ],
          },
          {
            step: "select-google-tv",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackConnectivity",
                value: true,
              },
            ],
          },
          {
            step: "select-device-screen-size",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackScreenSize",
                value: true,
              },
            ],
          },
          {
            step: "select-device-storage-type",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackStorageType",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "select-bluetooth",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackBluetooth",
                value: true,
              },
            ],
          },
          {
            step: "select-device-model",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackDeviceModel",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.GoBackConnectivity",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "ask-purchase-receipt.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "upload-purchase-receipt",
        next: [
          {
            step: "provide-purchase-date",
            condition: [
              {
                operator: "equal",
                src: "upload-purchase-receipt.providePurchaseDate",
                value: true,
              },
            ],
          },
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "upload-purchase-receipt.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "upload-purchase-receipt.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-purchase-date",
        next: [
          {
            step: "provide-purchase-price",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-date.providePurchasePrice",
                value: true,
              },
            ],
          },
          {
            step: "upload-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-date.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-date.GoBackAskReceipt",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-date.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-purchase-price",
        next: [
          {
            step: "show-reg-device-summary",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-price.showDeviceRegSummary",
                value: true,
              },
            ],
          },
          {
            step: "provide-purchase-date",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-price.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-purchase-price.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "show-reg-device-summary",
        next: [
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "show-reg-device-summary.addAnotherDevice",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "show-reg-device-summary.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "select-device-brand",
            condition: [
              {
                operator: "equal",
                src: "show-reg-device-summary.editDeviceDetails",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-file-claim",
        next: [
          {
            step: "select-incident-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-file-claim.showIncidentTypes",
                value: true,
              },
            ],
          },
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "select-device-file-claim.addDevice",
                value: true,
              },
            ],
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "select-device-file-claim.showMyClaim",
                value: true,
              },
            ],
          }
        ],
      },
      {
        step: "select-incident-type",
        next: [
          {
            step: "provide-issue-details",
            condition: [
              {
                operator: "equal",
                src: "select-incident-type.provideIssueDetails",
                value: true,
              },
            ],
          },
          {
            step: "select-device-file-claim",
            condition: [
              {
                operator: "equal",
                src: "select-incident-type.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "claim-message",
            condition: [
              {
                operator: "equal",
                src: "select-incident-type.showClaimMessage",
                value: true,
              },
            ],
          },
          {
            step: "show-triage-error",
            condition: [
              {
                operator: "equal",
                src: "select-incident-type.showTriageError",
                value: true
              }
            ]
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "select-incident-type.GoBackMyClaim",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-issue-details",
        next: [
          {
            step: "triage",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-details.triage",
                value: true,
              },
            ],
          },
          {
            step: "select-incident-type",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-details.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-details.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-details.GoBackMyClaim",
                value: true,
              },
            ],
          }
        ],
      },
      {
        step: "triage",
        next: [
          {
            step: "provide-issue-occurence-date",
            condition: [
              {
                operator: "equal",
                src: "triage.provideIssueOccurenceDate",
                value: true,
              },
            ],
          },
          {
            step: "show-triage-error",
            condition: [
              {
                operator: "equal",
                src: "triage.showTriageError",
                value: true,
              },
            ],
          },
          {
            step: "provide-issue-details",
            condition: [
              {
                operator: "equal",
                src: "triage.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "triage.GoBackMyClaim",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "triage.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "show-triage-error",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "show-triage-error.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-issue-occurence-date",
        next: [
          {
            step: "provide-issue-place",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-occurence-date.provideIssuePlace",
                value: true,
              },
            ],
          },
          {
            step: "triage",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-occurence-date.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-occurence-date.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-issue-place",
        next: [
          {
            step: "provide-issue-observed-by",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-place.provideIssueObservedBy",
                value: true,
              },
            ],
          },
          {
            step: "provide-issue-occurence-date",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-place.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-place.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-issue-observed-by",
        next: [
          {
            step: "provide-device-owner",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-observed-by.provideDeviceOwner",
                value: true,
              },
            ],
          },
          {
            step: "provide-issue-place",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-observed-by.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-issue-observed-by.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-device-owner",
        next: [
          {
            step: "provide-device-insurance-details",
            condition: [
              {
                operator: "equal",
                src: "provide-device-owner.provideInsuranceDetails",
                value: true,
              },
            ],
          },
          {
            step: "provide-issue-observed-by",
            condition: [
              {
                operator: "equal",
                src: "provide-device-owner.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-owner.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-device-insurance-details",
        next: [
          {
            step: "provide-device-owner",
            condition: [
              {
                operator: "equal",
                src: "provide-device-insurance-details.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "confirm-address",
            condition: [
              {
                operator: "equal",
                src: "provide-device-insurance-details.Continue",
                value: true,
              },
            ],
          },
          {
            step: "claim-message",
            condition: [
              {
                operator: "equal",
                src: "provide-device-insurance-details.showClaimMessage",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-insurance-details.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "show-triage-error",
            condition: [
              {
                operator: "equal",
                src: "provide-device-insurance-details.showTriageError",
                value: true
              }
            ]
          }
        ],
      },
      {
        step: "contactnumberpopup",
        next: [
          {
            step: "claim-summary",
            condition: [
              {
                operator: "equal",
                src: "contactnumberpopup.Continue",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "NoProceedPopup",
        next: [
          {
            step: "dontcancelclaim-msg",
            condition: [
              {
                operator: "equal",
                src: "NoProceedPopup.DontCancel",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "wishProceedPopup",
        next: [
          {
            step: "overage-cancel-claim",
            condition: [
              {
                operator: "equal",
                src: "wishProceedPopup.onNo",
                value: true,
              },
            ],
          },
                ]
      },
      {
        step: "cancelclaim-msgs",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "cancelclaim-msgs.dashboard",
                value: true,
              },
            ],
          },

        ]
      },
      {
        step: "dontcancelclaim-msg",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "dontcancelclaim-msg.dashboard",
                value: true,
              },
            ],
          },

        ]
      },
      {
        step: "contactus-thanks",
        next: [
          {
            step: "homepage",
            condition: [
              {
                operator: "equal",
                src: "contactus-thanks.goToHomePage",
                value: true,
              },
            ],
          },

        ]
      },
      {
        step: "overage-cancel-claim",
        next: [
          {
            step: "dontcancelclaim-msg",
            condition: [
              {
                operator: "equal",
                src: "overage-cancel-claim.DontCancel",
                value: true,
              }
            ]
          },
          {
            step: "show-replacement-options",
            condition: [
              {
                operator: "equal",
                src: "overage-cancel-claim.GoBack",
                value: true,
              },
            ],
          }
        ]
      },
      {
        step: "confirm-address",
        next: [
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "confirm-address.ShowClaimSummary",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "confirm-address.goToDashboard",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "show-claim-summary",
        next: [
          {
            step: "credit-card-details",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.Confirm",
                value: true,
              },
            ],
          },
          {
            step: "braintree-payment",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.isBraintree",
                value: true,
              },
            ],
          },
          {
            step: "confirm-address",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "show-replacement-options",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.GoBackReplacementOptions",
                value: true,
              },
            ],
          },
          {
            step: "show-overage-notes",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.showOverageNotes",
                value: true,
              }
            ]
          },
          {
            step: "video-declaration",
            condition: [
              {
                operator: "equal",
                src: "show-claim-summary.askVideoDeclaration",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "braintree-payment",
        next: [
          {
            step: "schedule-onsite-date",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.ScheduleOnsiteDate",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "show-notes",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.ShowTermsPage",
                value: true,
              },
            ],
          },
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "claim-message",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.showClaimMessage",
                value: true,
              },
            ],
          },
          {
            step: "show-overage-notes",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.showOverageNotes",
                value: true,
              }
            ]
          },
          {
            step: "schedule-delivery",
            condition: [
              {
                operator: "equal",
                src: "braintree-payment.selectDeliverySchedule",
                value: true
              }
            ]
          }
        ]
      },
      {
        step: "credit-card-details",
        next: [
          {
            step: "schedule-onsite-date",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.ScheduleOnsiteDate",
                value: true,
              },
            ],
          },
          {
            step: "show-notes",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.ShowTermsPage",
                value: true,
              },
            ],
          },
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "claim-message",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.showClaimMessage",
                value: true,
              },
            ],
          },
          {
            step: "show-overage-notes",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.showOverageNotes",
                value: true,
              }
            ]
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "schedule-delivery",
            condition: [
              {
                operator: "equal",
                src: "credit-card-details.selectDeliverySchedule",
                value: true
              }
            ]
          }
        ]
      },
      {
        step: "schedule-onsite-date",
        next: [
          {
            step: "show-notes",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.Continue",
                value: true,
              },
            ],
          },
          {
            step: "credit-card-details",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "braintree-payment",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.braintree",
                value: true,
              },
            ],
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.GoBackMyClaim",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "show-overage-notes",
            condition: [
              {
                operator: "equal",
                src: "schedule-onsite-date.showOverageNotes",
                value: true,
              }
            ]
          },
        ]
      },
      {
        step: "show-notes",
        next: [
          {
            step: "claim-message",
            condition: [
              {
                operator: "equal",
                src: "show-notes.Continue",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "show-notes.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "credit-card-details",
            condition: [
              {
                operator: "equal",
                src: "show-notes.GoBackCreditCard",
                value: true,
              },
            ],
          },
          {
            step: "braintree-payment",
            condition: [
              {
                operator: "equal",
                src: "show-notes.braintree",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "claim-message",
        next: [
          {
            step: "contact-us",
            condition: [
              {
                operator: "equal",
                src: "claim-message.contactUs",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "claim-message.goToDashboard",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "provide-device-isbuiltin",
        next: [
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "provide-device-isbuiltin.selectConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "cant-register-device",
            condition: [
              {
                operator: "equal",
                src: "provide-device-isbuiltin.deviceNotRegister",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-isbuiltin.GoBackModelNumber",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-isbuiltin.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-device-connectivity-type",
        next: [
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-isbuiltin",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "cant-register-device",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.deviceNotRegister",
                value: true,
              },
            ],
          },
          {
            step: "select-device-storage-capacity",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackCapacity",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.goToDashboard",
                value: true,
              },
            ],
          },
          {
            step: "select-bluetooth",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.hasBluetooth",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-keyboardtype",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackKeyboard",
                value: true,
              },
            ],
          },
          {
            step: "select-device-function",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackFunction",
                value: true,
              },
            ],
          },
          {
            step: "select-device-frame-rate",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackFrame",
                value: true,
              },
            ],
          },
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackModelNumber",
                value: true,
              },
            ],
          },
          {
            step: "select-device-screen-size",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.GoBackScreenSize",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-serial-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-connectivity-type.provideSerialNumber",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "cant-register-device",
        next: [
          {
            step: "select-device",
            condition: [
              {
                operator: "equal",
                src: "cant-register-device.addAnotherDevice",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "cant-register-device.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-model",
        next: [
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "select-device-model.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "select-device-storage-capacity",
            condition: [
              {
                operator: "equal",
                src: "select-device-model.GoBack",
                value: true,
              },
            ],
          }
        ],
      },
      {
        step: "select-device-function",
        next: [
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-function.selectConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "select-device-technology",
            condition: [
              {
                operator: "equal",
                src: "select-device-function.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-model.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "provide-device-keyboardtype",
        next: [
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "provide-device-keyboardtype.connectivityType",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-model-number",
            condition: [
              {
                operator: "equal",
                src: "provide-device-keyboardtype.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "provide-device-keyboardtype.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-bluetooth",
        next: [
          {
            step: "ask-purchase-receipt",
            condition: [
              {
                operator: "equal",
                src: "select-bluetooth.askPuchaseReceipt",
                value: true,
              },
            ],
          },
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "select-bluetooth.GoBackConnectivity",
                value: true,
              },
            ],
          },
          {
            step: "cant-register-device",
            condition: [
              {
                operator: "equal",
                src: "select-bluetooth.deviceNotRegister",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-bluetooth.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "select-device-frame-rate",
        next: [
          {
            step: "provide-device-connectivity-type",
            condition: [
              {
                operator: "equal",
                src: "select-device-frame-rate.selectConnectivityType",
                value: true,
              },
            ],
          },
          {
            step: "select-device-resolution",
            condition: [
              {
                operator: "equal",
                src: "select-device-frame-rate.GoBack",
                value: true,
              },
            ],
          },
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "select-device-frame-rate.goToDashboard",
                value: true,
              },
            ],
          },
        ],
      },
      {
        step: "myprofile",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "myprofile.goToDashboard",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "my-claim-for-repair",
        next: [
          {
            step: "schedule-delivery",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.goToScheduleDelivery",
                value: true,
              }
            ]
          },
          {
            step: "show-replacement-options",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showReplacementOption",
                value: true,
              },
            ]
          },
          {
            step: "triage",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showTriageQuestion",
                value: true,
              },
            ]
          },
          {
            step: "select-incident-type",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showIncidentDetails",
                value: true,
              },
            ]
          },
          {
            step: "confirm-address",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showConfirmAddress",
                value: true,
              },
            ]
          },
          {
            step: "schedule-onsite-date",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showScheduleOnsiteDate",
                value: true,
              },
            ]
          },
          {
            step: "show-notes",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.ShowTermsPage",
                value: true,
              },
            ]
          },
          {
            step: "provide-issue-details",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.showIssueDetails",
                value: true,
              },
            ]
          },
          {
            step: "video-declaration",
            condition: [
              {
                operator: "equal",
                src: "my-claim-for-repair.askVideoDeclaration",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "show-replacement-options",
        next: [
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-options.showOverageSummary",
                value: true,
              }
            ]
          },
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-options.GoBack",
                value: true,
              }
            ]
          },
          {
            step: "overage-cancel-claim",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-options.onNo",
                value: true,
              }
            ]
          },
          {
            step: "cancelclaim-msgs",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-options.goToCancelMsg",
                value: true,
              },
            ],
          }
        ]
      },
      {
        step: "show-replacement-overage-offer",
        next: [
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-overage-offer.showOverageSummary",
                value: true,
              }
            ]
          },
          {
            step: "show-replacement-options",
            condition: [
              {
                operator: "equal",
                src: "show-replacement-overage-offer.GoBack",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "show-overage-notes",
        next: [
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "show-overage-notes.goBack",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "contact-us",
        next: [
          {
            step: "contactus-thanks",
            condition: [
              {
                operator: "equal",
                src: "contact-us.OnSubmit",
                value: true,
              },
            ],
          },
          {
            step: "homepage",
            condition: [
              {
                operator: "equal",
                src: "contact-us.OnGoBack",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "schedule-delivery",
        next: [
          {
            step: "my-claim-for-repair",
            condition: [
              {
                operator: "equal",
                src: "schedule-delivery.GoBack",
                value: true,
              }
            ]
          },
          {
            step: "show-overage-notes",
            condition: [
              {
                operator: "equal",
                src: "schedule-delivery.showOverageNotes",
                value: true,
              }
            ]
          },
          {
            step: "credit-card-details",
            condition: [
              {
                operator: "equal",
                src: "schedule-delivery.GoBackCreditCard",
                value: true,
              },
            ],
          },
          {
            step: "braintree-payment",
            condition: [
              {
                operator: "equal",
                src: "schedule-delivery.braintree",
                value: true,
              },
            ],
          },
        ]
      },
      {
        step: "video-declaration",
        next: [
          {
            step: "show-claim-summary",
            condition: [
              {
                operator: "equal",
                src: "video-declaration.GoBack",
                value: true,
              }
            ]
          },
          {
            step: "video-declaration-message",
            condition: [
              {
                operator: "equal",
                src: "video-declaration.showMessage",
                value: true,
              }
            ]
          }
        ]
      },
      {
        step: "video-declaration-message",
        next: [
          {
            step: "dashboard",
            condition: [
              {
                operator: "equal",
                src: "video-declaration-message.goToDashboard",
                value: true,
              },
            ],
          },
        ]
      }
    ];*/
    console.log(
      "-------------------------------------------------------------------"
    );
    console.log("State & Step in enrollement-workflow : ", state, step);
    let statesMap =
      workflow &&
      workflow.reduce((agr, step) => ((agr[step.step] = step), agr), {});
    let currentStep = statesMap && statesMap[step];
    console.log("Current Step : ", step, currentStep);
    if (currentStep.next.length === 1) {
      return currentStep.next[0].step;
    }
    let nextStep = currentStep.next.find((step) =>
      matchCondition(state, step)
    )?.step;
    return nextStep;
  };

  const initialization = (state, setState) => {
    showLoader(true);
    API[ActionTypes.INTIALIZE_API_REQUEST]("en-US").then((data) => {
      setState({ ...state, config: data.data.InitializeResponse });
      setIsConfigLoaded(true);
      showLoader(false);
    });
  };

  const showLoader = (showLoader) => {
    setLoading(showLoader);
  };

  return (
    <>
      {loading && <Loader />}
      <Workflow
        initialStep="homepage"
        initialization={initialization}
        getNextStep={getNextStep}
        showLoader={showLoader}
        isLoaded={isConfigLoaded}
      >
        <Route path="homepage" element={<HomePage />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-email" element={<ForgotEmail />} />
        <Route path="email-otp" element={<EmailOTP />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="show-claim-summary" element={<ClaimSummary />} />
        <Route path="claim-message" element={<ClaimMessage />} />
        <Route path="deviceReg" element={<DeviceRegistration />} />
        <Route path="reg-device" element={<RegisterDevice />} />
        <Route path="select-device" element={<SelectDevice />} />
        <Route path="select-device-brand" element={<SelectDeviceBrand />} />
        <Route
          path="provide-device-model-number"
          element={<ProvideDeviceModelNumber />}
        />
        <Route
          path="provide-device-serial-number"
          element={<ProvideDeviceSerialNumber />}
        />
        <Route
          path="select-device-technology"
          element={<SelectDeviceTechnology />}
        />
        <Route
          path="select-device-resolution"
          element={<SelectDeviceResolution />}
        />
        <Route
          path="select-device-storage-capacity"
          element={<SelectDeviceStorageCapacity />}
        />
        <Route
          path="select-device-storage-type"
          element={<SelectDeviceStorageType />}
        />
        <Route
          path="select-device-processor"
          element={<SelectDeviceProcessor />}
        />
        <Route path="select-device-ram" element={<SelectDeviceRAM />} />

        <Route path="select-device-model" element={<SelectDeviceModel />} />
        <Route
          path="select-device-screen-size"
          element={<SelectDeviceScreenSize />}
        />
        <Route path="select-google-tv" element={<SelectGoogleTv />} />
        <Route path="ask-purchase-receipt" element={<AskPurchaseReceipt />} />
        <Route
          path="upload-purchase-receipt"
          element={<UploadPurchaseReceipt />}
        />
        <Route path="provide-purchase-date" element={<ProvidePurchaseDate />} />
        <Route
          path="provide-purchase-price"
          element={<ProvidePurchasePrice />}
        />

        <Route path="provide-device-isbuiltin" element={<IsDeviceBuiltIn />} />
        <Route
          path="provide-device-connectivity-type"
          element={<SelectDeviceConnectivityType />}
        />

        <Route
          path="provide-device-keyboardtype"
          element={<SelectKeyboardType />}
        />
        <Route path="select-bluetooth" element={<SelectBluetooth />} />

        <Route path="cant-register-device" element={<CantRegisterDevice />} />
        <Route
          path="select-device-function"
          element={<SelectDeviceFunction />}
        />

        <Route path="show-reg-device-summary" element={<DeviceRegSummary />} />
        <Route
          path="select-device-file-claim"
          element={<SelectDeviceFileClaim />}
        />
        <Route path="select-incident-type" element={<SelectIncidentType />} />
        <Route path="provide-issue-details" element={<ProvideIssueDetails />} />
        <Route path="provide-issue-place" element={<ProvideIssuePlace />} />
        <Route
          path="provide-issue-occurence-date"
          element={<ProvideIssueOccurenceDate />}
        />
        <Route
          path="provide-issue-observed-by"
          element={<ProvideIssueObservedBy />}
        />
        <Route path="provide-device-owner" element={<ProvideDeviceOwner />} />
        <Route
          path="provide-device-insurance-details"
          element={<ProvideDeviceInsuranceDetails />}
        />
        <Route path="triage" element={<Triage />} />
        <Route path="show-triage-error" element={<TriageError />} />
        <Route
          path="select-device-frame-rate"
          element={<SelectDeviceFrameRate />}
        />

        <Route path="enrollDevice" element={<EnrollDevice />} />
        <Route path="deviceAdditionalInfo" element={<DeviceAdditionalInfo />} />
        <Route path="myprofile" element={<MyProfile />} />
        <Route path="validemail" element={<ValidEmail />} />
        <Route path="confirm-address" element={<ConfirmAddress />} />
        <Route path="my-claim-for-repair" element={<MyClaimForRepair />} />
        <Route path="credit-card-details" element={<CreditCardDetails />} />
        <Route path="schedule-onsite-date" element={<ScheduleOnsiteDate />} />
        <Route path="schedule-delivery" element={<ScheduleDelivery />} />
        <Route path="show-notes" element={<ClaimNotes />} />
        <Route path="overage-cancel-claim" element={<OverageCancelClaim />} />
        <Route
          path="show-replacement-options"
          element={<ReplacementOptions />}
        />
        <Route
          path="show-replacement-overage-offer"
          element={<ReplacementOffer />}
        />
        <Route path="cancelclaim-msgs" element={<CancelClaimMsgs />} />
        <Route path="dontcancelclaim-msg" element={<DontCancelClaimMsgs />} />
        <Route path="contactus-thanks" element={<ContactUsNotes />} />
        <Route path="show-overage-notes" element={<OverageNotes />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="service-unavailable" element={<ServiceUnavailable />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="mydocuments" element={<MyDocuments />} />
        <Route path="video-declaration" element={<VideoDeclaration />} />
        <Route
          path="video-declaration-message"
          element={<VideoDeclarationMessage />}
        />
        <Route path="policy-acceptance" element={<PolicyAcceptance />} />
        <Route path="braintree-payment" element={<BrainTree />} />
        <Route path="*" element={<h3>Not matching route found</h3>} />
      </Workflow>
    </>
  );
}

export default EnrollmentWorkflow;
