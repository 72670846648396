/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.179 11.7995C9.99493 12.7975 8.45902 13.4 6.78069 13.4C3.03582 13.4 0 10.4003 0 6.7C0 2.99969 3.03582 0 6.78069 0C8.45902 0 9.99493 0.602498 11.179 1.60054C12.363 0.602498 13.8989 0 15.5773 0C19.3221 0 22.358 2.99969 22.358 6.7C22.358 10.4003 19.3221 13.4 15.5773 13.4C13.8989 13.4 12.363 12.7975 11.179 11.7995Z"
      fill="#ED0006"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.179 11.7995C12.6369 10.5706 13.5614 8.74195 13.5614 6.7C13.5614 4.65804 12.6369 2.82944 11.179 1.60054C12.363 0.602497 13.8989 0 15.5772 0C19.3221 0 22.3579 2.99969 22.3579 6.7C22.3579 10.4003 19.3221 13.4 15.5772 13.4C13.8989 13.4 12.363 12.7975 11.179 11.7995Z"
      fill="#F9A000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.179 11.7985C12.6369 10.5696 13.5614 8.74099 13.5614 6.69905C13.5614 4.6571 12.6369 2.82851 11.179 1.59961C9.72109 2.82851 8.79663 4.6571 8.79663 6.69905C8.79663 8.74099 9.72109 10.5696 11.179 11.7985Z"
      fill="#FF5E00"
    />
  </svg>
);

export default icon;
