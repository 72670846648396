/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick, fill }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
        fill="#685BC7"
      />
      <path
        d="M10.45 11.54V13H6.54004V11.54H7.50004V8.15H6.54004V6.67H9.51004V11.54H10.45ZM9.64004 4.11C9.64004 4.43 9.53004 4.7 9.31004 4.91C9.09004 5.12 8.83004 5.23 8.53004 5.23C8.23004 5.23 7.96004 5.12 7.74004 4.9C7.52004 4.68 7.41004 4.42 7.41004 4.11C7.41004 3.8 7.52004 3.55 7.74004 3.33C7.96004 3.11 8.22004 3 8.53004 3C8.84004 3 9.09004 3.11 9.31004 3.33C9.53004 3.55 9.64004 3.81 9.64004 4.11Z"
        fill="white"
      />
    </svg>
  );
};
icon.defaultProps = {
  fill: "#03F182",
};

export default icon;
