import { useContext, useState } from "react";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "./../helper";
import { WorkflowContext } from "../../workflow/workflow-context";
import { REPAIR_STATUS } from "../../../utils/constants";

function OverageNotes() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['OverageNotes']
  const homeplusscript = workflowState['config']?.scripts["OverageNotes"];

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const isReschedulePickupDelivery =
    fileClaimDetails?.RepairRequestResponse?.RepairStatus === REPAIR_STATUS.PICKUP_FAILED ||
    fileClaimDetails?.RepairRequestResponse?.RepairStatus === REPAIR_STATUS.DELIVERY_FAILED;

  let txtTitle = findById(metadata, "overageSuccessNote");
  let btnGoBack = findById(metadata, "btnGoBack");

  const onGoBackClick = () => {

    updateState({
      ...workflowState,
      FileClaimDetails: {},
    });

    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: isReschedulePickupDelivery ? homeplusscript.txtRedeliverySuccessNote : homeplusscript.txtOverageSuccessNote,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default OverageNotes;
