import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceConnectivityType() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;
  const isWebcam=selectedCategory=== DEVICE_CATEGORY.Webcam;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;

  const metadata = workflowState['config']?.metadata[
    isPrinter ? "SelectPrinterConnectivityType" : "SelectDeviceConnectivityType"]

  const homeplusscript = workflowState['config']?.scripts[
    isPrinter ? "SelectPrinterConnectivityType" : "SelectDeviceConnectivityType"];

  const [otherType, setOtherType] = useState("");
  const [displayConnectivityType, setDisplayConnectivityType] = useState([]);
  const [selectedType, setSelectedType] = useState({});

  let displayConnectivityTypesOptions = findById(metadata, "displayConnectivityTypesOptions");
  //   let inputotherType = findById(metadata, "inputOtherType");
  let btnContinue = findById(metadata, "btnContinue");
  let txtDisplayConnectivityInfo = findById(metadata, "txtDisplayConnectivityInfo");
  let btnGoBack = findById(metadata,"btnGoBack");
  let menu = findById(metadata,"menu");
  let txtDisplayConnectivityInfoNormal = findById(metadata, "txtDisplayConnectivityInfoNormal")
  let buttonsContainer = findById(metadata, "buttonsContainer");

  const getInfoTitle = () => {
    if (isRouter) return homeplusscript.routerConnectivityInfo;
    else if(isSoundbar) return homeplusscript.SoundbarConnectivityInfo;
    else if(isKeyboard) return homeplusscript.KeyboardConnectivityInfo;
    else if(isWebcam) return homeplusscript.WebcamConnectivityInfo;
    else if (isTablet) return homeplusscript.otherConnectivityInfoTablet;
    else if (isMonitor) return homeplusscript.MonitorConnectivityInfo;
    else if (isPrinter) return homeplusscript.PrinterConnectivityInfo;
    else if(isMouse) return homeplusscript.MouseConnectivityInfo;
    else return homeplusscript.otherConnectivityInfo.replace(
      "{'deviceCategory'}",
      selectedCategory
    );
  };

  const getTabletKey = () => {
    return regDeviceDetails?.selectedBrand === "Apple" ? "iPad" : "Tablet"
  }
  const onContinueClick = (selectedOption) => {
    if(isRouter){
      updateState({
        ...workflowState,
        RegDeviceDetails: {
          // ...regDeviceDetails,
          deviceSpeed: otherType?.trim() || selectedOption.value,
        },
      }, ["RegDeviceDetails"]);
    }
    else {
      updateState({
        ...workflowState,
        RegDeviceDetails: {
          // ...regDeviceDetails,
          selectedConnectivity: otherType?.trim() || selectedOption.value,
          hasWifiConnect : isPrinter && selectedOption.value === homeplusscript.labelHasWiFi ? true : false
        },
      }, ["RegDeviceDetails"]);
    }

    return completeCurrentStep(currentStep, {
      askPuchaseReceipt:
        isSoundbar ||
        isMonitor ||
        (isPrinter && selectedOption.value === homeplusscript.labelHasWiFi)
        || isWebcam,
      provideSerialNumber: isTablet,
      // deviceNotRegister : (isKeyboard|| isMouse) && (selectedOption.value === "Wired" ? true : false),
      // hasBluetooth : isRouter || ((isKeyboard || isMouse) && (selectedOption.value === "Wireless" ? true : false)),
      deviceNotRegister:
        isKeyboard && selectedOption.value === "Wired"
          ? true
          : isPrinter && selectedOption.value === homeplusscript.labelNoWiFi
          ? true
          : isMouse && selectedOption.value === "Wired" ? true : false,
      hasBluetooth:  isRouter || ((isKeyboard || isMouse) && (selectedOption.value === "Wireless" ? true : false)),
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: isSoundbar,
      GoBackKeyboard: isKeyboard,
      GoBackScreenSize: isMonitor,
      GoBackFunction: isPrinter,
      GoBackModelNumber: isMouse || isRouter || isTablet,
      GoBackFrame:isWebcam
    });
  };

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if(isRouter){
       if (isEmpty(regDeviceDetails?.selectedBrandDetails)){
        showConnectivityType(regDeviceDetails?.distinctBandwidthList)
      } else {
      showConnectivityType(regDeviceDetails.selectedBrandDetails.Bandwidth)
      }
    }else if (isKeyboard || isMouse || isMonitor){
      showConnectivityType(["Wired","Wireless"]);
    }
    else if (isPrinter)
      showConnectivityType([
        homeplusscript.labelHasWiFi,
        homeplusscript.labelNoWiFi,
      ]);
    else if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showConnectivityType(regDeviceDetails.selectedBrandDetails.Connectivity);
      

    // now get distinct list of all brand
    else showConnectivityType(regDeviceDetails?.distinctConnectivityList);
  }, []);

  const showConnectivityType = (connectivityType) => {
    let connectivityTypeList = [];
  if (isMonitor)  {
    connectivityType?.forEach((type) => {
      if (!isEmpty(type)) {
        let deviceBrandTech = {
         value: type==="Wired"?"No":"Yes",
          label: type,
        };
        connectivityTypeList.push(deviceBrandTech);
      }
    });
  }else{
    connectivityType?.forEach((type) => {
      if (!isEmpty(type)) {
        let deviceBrandTech = {
         value: type,
          label: type,
        };
        connectivityTypeList.push(deviceBrandTech);
      }
    });

  }

    setDisplayConnectivityType(connectivityTypeList);
  };

  const onTypeSelect = (type) => {
    hideShowInputOtherType(type);
    setSelectedType({ ...selectedType, ...type });

    if (type.value !== "Others") onContinueClick(type);
  };

  const hideShowInputOtherType = (type) => {
    // clear input value
    setOtherType("");
    // inputotherType.args = {
    //   ...inputotherType.args,
    //   other: tech.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: type.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: type.value === "Others"  ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  let titleText = findById(metadata, "txtTitle");

  titleText.args = {
    ...titleText.args,
    content: isRouter? homeplusscript.titleRouter  : homeplusscript.title.replace(
      "{'deviceCategory'}",
      isTablet ? getTabletKey() : selectedCategory?.toLowerCase()
    ),
  };

  displayConnectivityTypesOptions.args = {
    ...displayConnectivityTypesOptions.args,
    deviceItems: displayConnectivityType,
    value: selectedType?.value,
    onClick: onTypeSelect,
  };

  //   inputotherType.args = {
  //     ...inputotherType.args,
  //     label: homeplusscript["labelotherType"],
  //     placeHolder: homeplusscript["labelotherType"],
  //     value: otherType,
  //     onChange: setOtherType,
  //     other:
  //       isEmpty(regDeviceDetails?.selectedBrandDetails) ||
  //       otherType.value === "Others"
  //         ? "block"
  //         : inputotherType.args.other,
  //   };

  txtDisplayConnectivityInfo.args = {
    ...txtDisplayConnectivityInfo.args,
    content: getInfoTitle(),
    displayClasses: (isSoundbar || isKeyboard || isWebcam || isMonitor || isPrinter ||isRouter || isMouse) ? 'hidden' : 'block',
  };

  txtDisplayConnectivityInfoNormal.args = {
    ...txtDisplayConnectivityInfoNormal.args,
    content:getInfoTitle(),
    displayClasses: (isSoundbar || isKeyboard  || isWebcam || isMonitor ||isPrinter ||isRouter  ||isMouse) ? 'block' : 'hidden',
};

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherType?.trim(),
    displayClasses:
      isEmpty(regDeviceDetails?.selectedBrandDetails) ||
      selectedType?.value === "Others"
        ? "block"
        : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceConnectivityType;
