import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { Tooltip } from "../tooltip/tooltip";

export const AsyncSelect = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: "1px",
      margin: 20,
    }),
  };


  return (
    <div>
      <div className={`mt-4 flex flex-row`}>
        <Tooltip showLabel={true} labelText={props.label} />
      </div>
      <AsyncPaginate
        className="mt-2"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          marginTop: "20px",
          colors: {
            ...theme.colors,
            primary25: "#1CD4B0",
            primary: "#1ED760",
          },
        })}
        {...props}
      />
    </div>
  );
};

AsyncSelect.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
};

AsyncSelect.defaultProps = {
  enabled: true,
};
