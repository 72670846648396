import ActionTypes from "./ActionTypes";
import { logGTMEvent } from "./components/enrollment-workflow/api-helper";
import API from "./services";
import { GTM_EVENT } from "./utils/constants";

const logger = ({ type, error, state }) => {
  console.log({ error });

  if (process.env.REACT_APP_IS_LOCAL_ENVIORNMENT === "FALSE") {
    const agreement = state.FindAgreementResponse?.Agreements?.Agreement[0];
    const agreementId = agreement?.AgreementId;

    const errorMessage = JSON.stringify(
      error?.response?.data || error?.message
    );

    const params = {
      message: JSON.stringify(error?.stack || error),
      level: "error",
      category: type ? "API Log" : "UI Log",
      agreementRequestId: agreementId,
      errorAPI: "",
      errorId: "",
      status: type,
      errorCode: error?.response?.status,
      errorMessage,
      customer: agreement?.Customers?.Customer[0]?.FullName,
      clientId: state.config?.ClientId,
      clientName: state.config?.ClientName,
    };

    const loggerParams = {
      LoggerRequest: params,
    };

    API[ActionTypes.LOG_TO_DYNAMO_DB](loggerParams).then((data) => {
      console.log({ data });
    });

    logGTMEvent({
      event: GTM_EVENT.ERROR_EVENT,
      errorType: type || "UI Error",
      errorMessage: JSON.stringify(error?.response?.data || error?.message),
      errorCode: error?.response?.status,
    });
  }
};

export default logger;
