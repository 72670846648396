import { useContext, useEffect, useState } from "react";
import { Stack } from "../../stack/stack";
import { findById } from "../helper";
import { WorkflowContext } from "../../workflow/workflow-context";
import {
  getSignedRequestForUpload,
  getSignedURLForDownload,
  uploadFileBySignedUrl,
} from "../../../utils/upload-utils";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";
import logger from "../../../logger.service";
import Popup from "reactjs-popup";
import ChargeOrderHoldPopup from "./chargeOrderHoldPopup";
import { getMIMEType, getVideoFileExt } from "../../video-player/video-utils";

function VideoDeclarationMessage() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["VideoDeclarationMessage"];
  const homeplusscript =
    workflowState["config"]?.scripts["VideoDeclarationMessage"];

  const [progress, setProgress] = useState(0);
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const contentStyle = {
    padding: "15px",
    width: "345px",
    height: "265px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    borderRadius: "8px",
    borderColor: "",
    textColor: "",
  };

  const txtTitle = findById(metadata, "txtTitle");
  const txtSubText = findById(metadata, "txtSubText");
  const btnSubmit = findById(metadata, "btnSubmit");
  const withdrawClaim = findById(metadata, "withdrawClaim");
  let menu = findById(metadata, "menu");

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const CustomerCaseNumber = ServiceRequestResponse?.CustomerCaseNumber;
  const blob = fileClaimDetails?.VideoDeclaration?.VideoBlob;

  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const incidentResponse = fileClaimDetails?.ProcessIncidentResponse;

  const handleProgress = ({ loaded }, totalSize) => {
    console.log({ loaded, totalSize });
    const percentage = Number.parseInt((loaded / totalSize) * 100);
    setProgress(percentage);
  };

  const handleSuccess = ({ path }) => {
    setProgress(100);
  };

  const submitVideoDeclaration = async () => {
    showLoader(true);

    const signedUrl = await getSignedRequestForUpload(agreement?.AgreementId);

    setProgress(1);
    const formData = new FormData();
    const fileExt = getVideoFileExt();
    const videoFileName = `${CustomerCaseNumber}_video_declaration.${fileExt}`;
    var file = new File([blob], videoFileName);
    formData.append(
      "blob",
      blob,
      videoFileName
    );

    // uploadToS3(signedUrl, file, formData)
    const uploadedFile = await uploadFileBySignedUrl(
      signedUrl,
      getMIMEType(),
      file,
      handleProgress,
      formData
    );

    if (
      uploadedFile &&
      uploadedFile.status === 200 &&
      uploadedFile.statusText === "OK"
    ) {
      // video uploaded successfully now call inquiry api

      const fileName = signedUrl.split("?")?.[0]?.split("/")?.pop();
      const extension = file?.name?.split(".").pop();

      const params = {
        FileDownloadRequest: {
          AgreementId: agreement?.AgreementId,
          FileName: `${fileName}.${extension}`,
        },
      };
      const data = await getSignedURLForDownload(params);
      const { bucketName, url } = data;
      const documents = {
        BucketName: bucketName,
        Path: `${fileName}.${extension}`,
        Type: getMIMEType(),
        Status: "uploaded",
      };

      // call upload reference doc api

      callUpdateReferenceDocApi(documents);
    } else {
      // video upload error
      showLoader(false);
    }
  };

  const callUpdateReferenceDocApi = (document) => {
    const ReferenceDocumentParameters = {
      ReferenceDocumentParameters: {
        ClientAccountId: agreement?.ClientAccount?.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        AgreementId: agreement?.AgreementId,
        ReferenceId: getVideoHoldId(incidentResponse?.Holds),
        ReferenceNumber: CustomerCaseNumber.toString(),
        ClientAccountDocCategory: "Video Hold",
        CreatedBy: agreement?.Customers?.Customer?.[0]?.FullName,
        ReferenceType: "HLD",
        Documents: [document],
        ServiceRequestId: ServiceRequestResponse?.ServiceRequest?.ServiceRequestId
      },
    };

    API[ActionTypes.UPLOAD_VIDEO_DOC](ReferenceDocumentParameters)
      .then((data) => {
        showLoader(false);

        updateState(
          {
            ...workflowState,
            UploadReferenceDocumentResponse: data?.data,
          },
          ["UploadReferenceDocumentResponse"]
        );

        setOpenPopup(true);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({
          type: ActionTypes.UPLOAD_VIDEO_DOC,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
      });
  };

  const getVideoHoldId = (holds) => {
    const videoHold = holds.find(
      (hold) => hold.HoldType === "VIDEO" && hold.HoldStatus === "OPEN"
    );
    return videoHold?.HoldId || holds?.[0]?.HoldId;
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
  };

  txtSubText.args = {
    ...txtSubText.args,
    content: homeplusscript.txtSubText,
  };

  btnSubmit.args = {
    ...btnSubmit.args,
    label: homeplusscript.btnSubmit,
    onClick: () => submitVideoDeclaration(),
  };

  withdrawClaim.args = {
    ...withdrawClaim.args,
    content: homeplusscript.withdrawClaim,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  return (
    <>
      <Popup
        contentStyle={contentStyle}
        open={openPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <ChargeOrderHoldPopup setOpenPopup={setOpenPopup} />
        </div>
      </Popup>
      <Stack orientation="vertical" metadata={metadata} />;
    </>
  );
}
export default VideoDeclarationMessage;
