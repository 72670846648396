import React, { useState } from "react";
import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Colors } from "./entities/Colors";

import CallbackPhoneSvg from "./assets/CallbackPhoneSvg";
import CallbackThankYouSvg from "./assets/CallbackThankYouSvg";
import WillCallbackSvg from "./assets/WillCallbackSvg";
import PhoneInput from "react-phone-input-2";
import { CloseChatBtn } from "./widgets/CloseChatBtn";

export const CallbackOption = ({
  fullName,
  showThankYou,
  showWillContact,
  showForm,
  onSubmit,
  onYesNo,
  onClose,
}) => {
  const classes = CallbackOptionStyles();

  const [contactName, setContactName] = useState(fullName || '');
  const [contactNumber, setContactNumber] = useState();
  const [errorName, setErrorName] = useState();
  const [errorNumber, setErrorNumber] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.callbackRoot}>
      {!showThankYou && !showForm && !showWillContact && (
        <div className={classes.roundedBox}>
          <div className={classes.callbackIcon}>
            <CallbackPhoneSvg />
            <div>
              Would you like us to give you a call regarding your feedback?{" "}
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onYesNo("no");
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onYesNo("yes");
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
      {showThankYou && !showForm && !showWillContact && (
        <div className={classes.thankYou}>
          <div>Thank you for your feedback!</div>
          <div>
            If there's anything else we can help you with, please visit our{" "}
            <a href={process.env.REACT_APP_HOMEPLUS_HOME_URL}>
              StarHub SmartHome Support website
            </a>
            <CallbackThankYouSvg />
            <CloseChatBtn
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      )}
      {!showThankYou && !showForm && showWillContact && (
        <div className={classes.willContact}>
          <div>
            A member of our team will contact you to help you with your concern.
          </div>
          <div>
            <WillCallbackSvg />
          </div>
          <div>
            <CloseChatBtn
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      )}
      {!showThankYou && showForm && !showWillContact && (
        <div className={classes.showForm}>
          <div>Please enter your contact name and contact number</div>
          <form autoComplete="off">
            <Typography>Contact Name</Typography>
            <TextField
              variant="filled"
              required
              name="fullName"
              defaultValue={contactName}
              onChange={(e) => {
                const { value } = e.target;
                setContactName(value);
              }}
              error={errorName}
              fullWidth
              className={clsx({
                [classes.fieldInput]: true,
                [classes.fieldWarning]: errorName,
              })}
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errorName && (
              <Typography variant="caption" className={classes.error}>
                {errorName}
              </Typography>
            )}
            <div className={classes.seperator}></div>
            <Typography>Contact Number</Typography>

            <PhoneInput
              country={"sg"}
              onlyCountries={["sg"]}
              disableDropdown={true}
              countryCodeEditable={false}
              value={contactNumber}
              dropdownStyle={{ width: "100px" }}
              onChange={(val, country, e, formattedValue) => {
                setContactNumber(val);
              }}
              inputClass={clsx({
                [classes.phoneInput]: true,
                [classes.fieldWarning]: errorNumber,
              })}
              style={{ width: "100%" }}
              //   className={clsx({
              //     [classes.fieldInput]: true,
              //     [classes.fieldWarning]: errorNumber,
              //   })}
            />

            {errorNumber && (
              <Typography variant="caption" className={classes.error}>
                {errorNumber}
              </Typography>
            )}
            <div className={classes.btnContainer}>
              <Button
                color="primary"
                variant="contained"
                disabled={loading}
                className={classes.submitBtn}
                onClick={() => {
                  setErrorName(null);
                  setErrorNumber(null);
                  let err = 0;
                  if (!contactName) {
                    setErrorName("Contact name is required");
                    err++;
                  } else {
                    setErrorName(null);
                  }

                  if (!contactNumber) {
                    setErrorNumber("Contact number is required");
                    err++;
                  }

                  if (contactNumber && contactNumber.length <= 3) {
                    setErrorNumber("Contact number is required");
                    err++;
                  }

                  if (contactNumber && contactNumber.length > 3) {
                    setErrorNumber(null);
                  }

                  if (err && err > 0) {
                    return;
                  } else {
                    setLoading(true);
                    onSubmit({ name: contactName, number: contactNumber });
                  }
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const CallbackOptionStyles = makeStyles((theme) => ({
  callbackRoot: {
    marginTop: "20px",
    // padding: 'unset',
    padding: '15px'
  },
  fieldInput: {
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      background: "transparent",
      border: `1px solid ${Colors.DARK_GRAY}`,
      padding: "14px 10px",

      "&:hover": {
        borderColor: Colors.ACCENT_BLUE,
        border: `1px solid ${Colors.ACCENT_BLUE}`,
      },
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
  },
  phoneInput: {
    height: "49px !important",
    width: "100% !important",
    padding: "14px 10px",
  },
  fieldWarning: {
    "& .MuiInputBase-root": {
      border: "1px solid #f44336",
    },
  },
  roundedBox: {
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.LIGHT_GRAY_2}`,
    borderRadius: "8px",
    textAlign: "center",
    padding: "15px",

    "& svg": {
      width: "60px",
      margin: "0 auto",
    },

    "& div": {
      maxWidth: "330px",
      margin: "15px 0",
    },

    "& button": {
      minWidth: "80px",
      minHeight: "40px",
      borderRadius: "4px",
      color: Colors.BLACK,
      marginRight: "50px",
    },

    "& button:last-child": {
      marginRight: 0,
    },
  },
  thankYou: {
    "& div": {
      // maxWidth: "315px",
      // margin: "15px 0",
      maxWidth: "100%",
      margin: "15px 15px",
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "center",

      "& a": {
        color: Colors.PRIMARY,
      },
    },

    "& div:first-child": {
      width: "100%",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 700,
    },

    "& svg": {
      width: "220px",
      margin: "0 auto",
    },

    "& button": {
      marginTop: "50px",
      minWidth: "114px",
      minHeight: "44px",
      borderRadius: "4px",
      color: Colors.BLACK,
    },
  },
  showForm: {
    "& > div, & > form": {
      maxWidth: "315px",
      margin: "15px 0",
      fontSize: "16px",
      fontWeight: 400,
    },
  },
  seperator: {
    width: "100%",
    height: "20px",
  },
  submitBtn: {
    marginTop: "20px",
    minWidth: "90px",
    minHeight: "44px",
    borderRadius: "4px",
    color: Colors.BLACK,
  },
  btnContainer: {
    width: "100%",
    textAlign: "center",
    float: "left",
  },
  error: {
    color: "red",
  },
  willContact: {
    "& div": {
      maxWidth: "315px",
      margin: "15px 0",
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "center",

      "& a": {
        color: Colors.PRIMARY,
      },
    },

    "& svg": {
      width: "100%",
      margin: "0 auto",
      marginTop: "30px",
      marginBottom: "60px",
    },

    "& button": {
      minWidth: "114px",
      minHeight: "40px",
      borderRadius: "4px",
      color: Colors.BLACK,
      margin: "0 auto",
    },
  },
}));
