/* eslint-disable global-require */
import React from "react";
import Icon from "../icon/icon";

import "./loader.css";

const Loader = () => (
  <div className="loaderOverlay">
    <div className="loader">
      <Icon icon="Spinner"></Icon>
    </div>
  </div>
);

export default Loader;
