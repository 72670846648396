import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Colors } from "../entities/Colors";

export const CloseChatBtn = ({ onClick, inlineStyle }) => {
  const classes = ComponentStyles();

  return (
    <Button
      className={classes.closeChatBtn}
      color={"primary"}
      variant={"contained"}
      onClick={onClick}
      style={inlineStyle}
    >
      Close chat
    </Button>
  );
};

const ComponentStyles = makeStyles((theme) => ({
  closeChatBtn: {
    backgroundColor: Colors.PRIMARY_CONTRAST_TEXT,
    width: "114px",
    height: "44px",
    minWidth: "unset",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  },
}));
