import React from "react";

const icon = ({ onClick, isSelected, className }) => (
  <svg
    onClick={onClick}
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.375 7H2.625" stroke="#1ED760" stroke-miterlimit="10" />
    <path d="M7 2.625V11.375" stroke="#1ED760" stroke-miterlimit="10" />
  </svg>
);

export default icon;
