import React from "react";
import PropTypes from "prop-types";
import { Button } from "../button/button";
import { TextArea } from "../textarea/textarea";

export const TriageQuestionDesktop = ({
  itemTitleClasses,
  borderClasses,
  colorClasses,
  dimensionClasses,
  triageQuestion,
  onClick,
  paddingClasses,
  showTooltip,
  mainDivClasses,
  label,
  value,
  itemSelectedClasses,
  itemColorClasses,
  setOtherAnswer,
  itemClass,
  ...props
}) => {
  console.log("Triage Question Desktop : ", triageQuestion);
  return (
    <>
      {triageQuestion?.map((triagequestion, index) => {
        return (
          <div
            className={`${
              triagequestion?.QuestionType !== "Symptoms_Others"
                ? "flex flex-row justify-between"
                : "flex flex-col justify-between"
            } ${itemTitleClasses}`}
          >
            <div className="my-auto">
              <label>{triagequestion.Question}</label>
            </div>
            <div className="flex flex-row my-auto">
              {triagequestion?.answers?.length >= 0 &&
                triagequestion?.answers.map((item) => {
                  return (
                    <div key={item.value}>
                      <Button
                        enabled={true}
                        label={item.label}
                        onClick={(e) => {
                          onClick(triagequestion, item, false);
                        }}
                        colorClasses={
                          item.isClick ? itemSelectedClasses : itemColorClasses
                        }
                        dimensionClasses={dimensionClasses}
                        paddingClasses={paddingClasses}
                      />
                    </div>
                  );
                })}
              {triagequestion?.QuestionType === "Symptoms_Others" && (
                <div className="w-full">
                  <TextArea
                    label={""}
                    onChange={setOtherAnswer}
                    fontClasses="text-sm"
                    showLengthErrror={true}
                    maxlength={"50"}
                  ></TextArea>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

TriageQuestionDesktop.propTypes = {
  colorClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  itemTitleClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  triageQues: PropTypes.object,
  onClick: PropTypes.func,
  itemSelectedClasses: PropTypes.string,
  setOtherAnswer: PropTypes.func,
};

TriageQuestionDesktop.defaultProps = {
  mainDivClasses: "flex justify-center my-2 mx-1",
  borderClasses: "w-full h-auto bg-white border rounded shadow-sm border-neutralN8 text-center",
  itemTitleClasses: "flex justify-center m-4",
  dimensionClasses: "rounded mx-2.5",
  paddingClasses: "px-7 py-2",
  onClick: undefined,
  triageQuestion: {},
  itemClass: "flex justify-center my-4",
  itemSelectedClasses: "bg-primary text-link border border-primary border-solid",
  itemColorClasses: "text-neutral border border-primary border-solid",
  setOtherAnswer: undefined,
};
