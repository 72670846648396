import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";


function SelectDeviceFunction() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const metadata = workflowState['config']?.metadata['SelectDeviceFunction']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceFunction"];

  const [otherFunction, setOtherFunction] = useState("");
  const [deviceFunctionList, setDeviceFunctionList] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let deviceFunctions = findById(metadata, "deviceFunctions");
  let inputOtherFunction = findById(metadata, "inputOtherFunction");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtFunctionInfo = findById(metadata, "txtFunctionInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  const onContinueClick = (deviceFunction) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedFunction: deviceFunction?.value || otherFunction?.trim(),
        isOtherFunctionSelected: isEmpty(deviceFunction),
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectConnectivityType : true
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceFunctions(regDeviceDetails.selectedBrandDetails.Function);
    // now get distinct list of all brand
    else showDeviceFunctions(regDeviceDetails?.distinctFunctionList);
  }, []);

  const showDeviceFunctions = (deviceFunctions = []) => {
    let functionList = [];
    deviceFunctions.forEach((functionName) => {
      if (!isEmpty(functionName)) {
        let deviceFunction = {
          value: functionName,
          label: functionName,
          // using below property for custom sorting
          function: functionName === "None" ? "none" : functionName.match(/&/g) ? functionName.match(/&/g).length > 1 ? "multiple" : "twice" : "single" 
        };
        functionList.push(deviceFunction);
      }
    });

    const functions = {
      none: 100,
      single: 200,
      twice: 300,
      multiple: 400
    };

    const sortedFunctionList = functionList.sort((a, b) =>
      functions[a.function] - functions[b.function]
    );

    // add others at last
    sortedFunctionList.push({ value: "Others", label: "Others" });

    setDeviceFunctionList(sortedFunctionList);
  };

  const onFunctionSelect = (deviceFunction) => {
    hideShowInputOtherFunction(deviceFunction);
    setSelectedFunction({ ...selectedFunction, ...deviceFunction });

    if (deviceFunction.value !== "Others") onContinueClick(deviceFunction);
    else setDisplayProp("block")
  };

  const hideShowInputOtherFunction = (deviceFunction) => {
    // clear input value
    setOtherFunction("");
    // inputOtherFunction.args = {
    //   ...inputOtherFunction.args,
    //   other: deviceFunction.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: deviceFunction.value === "Others" ? "block" : "hidden",
    };
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title,
  };

  deviceFunctions.args = {
    ...deviceFunctions.args,
    deviceItems: deviceFunctionList,
    value: selectedFunction?.value,
    onClick: onFunctionSelect,
  };

  inputOtherFunction.args = {
    ...inputOtherFunction.args,
    label: homeplusscript.labelOtherFunction,
    placeHolder: homeplusscript.placeholderOtherFunction,
    value: otherFunction,
    other: displayProp,
    onChange: setOtherFunction,
  };

  txtFunctionInfo.args = {
    ...txtFunctionInfo.args,
    content: homeplusscript.otherFunctionInfo
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherFunction?.trim(),
    displayClasses: selectedFunction?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedFunction?.value === "Others"  ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceFunction;