import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import {
  findById,
  getMetadata,
  isEmpty as isEmptyArray,
} from "../../enrollment-workflow/helper";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";

import { DEVICE_CATEGORY } from "../../../utils/constants";
import logger from "../../../logger.service";

function SelectDeviceBrand() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  let FindAgreementResponse = workflowState["FindAgreementResponse"];

  const metadata = workflowState['config']?.metadata['SelectDeviceBrand']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceBrand"];

  const [selectedBrand, setSelectedBrand] = useState({});
  const [otherBrand, setOtherBrand] = useState("");
  const [deviceBrandList, setDeviceBrandList] = useState([]);
  const [distinctTechList, setDistinctTech] = useState([]);
  const [distinctPrinterTechList, setDistinctPrinterTech] = useState([]);
  const [distinctSizeList, setDistinctSize] = useState([]);
  const [distinctProcessorList, setDistinctProcessor] = useState([]);
  const [distinctCapacityList, setDistinctCapacity] = useState([]);
  const [distinctRAMList, setDistinctRAM] = useState([]);
  const [distinctStorageTypeList, setDistinctStorageType] = useState([]);
  const [distinctConnectivityList, setDistinctConnectivity] = useState([]);
  const [distinctModelList, setDistinctModel] = useState([]);
  const [distinctResolutionList, setDistinctResolution] = useState([]);
  const [distinctKeyboardTypeList, setDistinctKeyboardTypeList] = useState([]);
  const [distinctBuiltInBluetoothList, setBuiltInBluetoothList] = useState([]);
  const [distinctBandwidthList, setDistinctBandwidthList] = useState([]);
  const [displayProp, setDisplayProp] = useState("hidden");

  // const [selectedBrand, setSelectedBrand] = useState({});
  const [distinctFunctionList, setDistinctFunction] = useState([]);
  const [distinctFrameList, setDistinctFrame] = useState([]);

  let deviceBrands = findById(metadata, "deviceBrands");
  let inputOtherBrand = findById(metadata, "inputOtherBrand");
  let btnContinue = findById(metadata, "btnContinue");
  let titleText = findById(metadata, "txtTitle");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menu = findById(metadata, "menu");
  let txtInfoSelectBrand = findById(metadata, "txtInfoSelectBrand")
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isTV = selectedCategory === DEVICE_CATEGORY.TV;
  const isLaptopDesktop =
    selectedCategory === DEVICE_CATEGORY.Laptop ||
    selectedCategory === DEVICE_CATEGORY.Desktop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;
  const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;
  const isWebcam = selectedCategory === DEVICE_CATEGORY.Webcam;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;

  const onContinueClick = (brand) => {
    // regDeviceDetails = {
    //   ...regDeviceDetails,
    //   // save selected brand details
    //   selectedBrandDetails: otherBrand
    //     ? {}
    //     : deviceBrandList.find((item) => item.value === brand?.value),
    //   // save other brand if any
    //   selectedBrand: brand?.value || otherBrand?.trim(),
    //   // save distinct tech and size list
    //   distinctTechList,
    //   distinctSizeList,
    //   distinctProcessorList,
    //   distinctCapacityList,
    //   distinctRAMList,
    //   distinctStorageTypeList,
    //   distinctConnectivityList,
    //   distinctModelList,
    //   distinctResolutionList,
    //   distinctFrameList,
    //   distinctKeyboardTypeList,
    //   distinctBuiltInBluetoothList,
    //   distinctPrinterTechList,
    //   distinctFunctionList,
    //   distinctBandwidthList,
    // };

    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedBrandDetails: otherBrand
        ? {}
        : deviceBrandList.find((item) => item.value === brand?.value),
      // save other brand if any
      selectedBrand: brand?.value || otherBrand?.trim(),
      // save distinct tech and size list
      distinctTechList,
      distinctSizeList,
      distinctProcessorList,
      distinctCapacityList,
      distinctRAMList,
      distinctStorageTypeList,
      distinctConnectivityList,
      distinctModelList,
      distinctResolutionList,
      distinctFrameList,
      distinctKeyboardTypeList,
      distinctBuiltInBluetoothList,
      distinctPrinterTechList,
      distinctFunctionList,
      distinctBandwidthList,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      // for next step
      provideModelNumber: true,
    });
  };

  const clearRegDeviceData = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
  };

  const onGoBackClick = () => {
    // clear all device reg data
    clearRegDeviceData();
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  useEffect(() => {
    showLoader(true);
    API[ActionTypes.GET_INVENTORY](
      FindAgreementResponse?.Agreements?.Agreement[0]?.ClientOffer
        ?.ClientOfferId
    )
      .then((data) => {
        showLoader(false);

        let inventoryData = data?.data?.InventoryData;
        updateState({
          ...workflowState,
          inventoryData: inventoryData,
        });

        // display device categories
        if (inventoryData && inventoryData.length > 0)
          displayDeviceBrand(inventoryData);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_INVENTORY, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  }, []);

  const displayDeviceBrand = (inventoryData) => {
    let brands = inventoryData.filter(
      (item) =>
        item.AssetCategoryName === regDeviceDetails?.selectedCategory.type
    );

    let brandList = [];
    let techList = [];
    let sizeList = [];
    let processorList = [];
    let capacityList = [];
    let RAMList = [];
    let storageTypeList = [];
    let modelList = [];
    let connectivityList = [];
    let resolutionList = [];
    let frameRateList = [];
    let keyboardTypeList = [];
    let builtInBluetoothList = [];
    let printerTechList = [];
    let functionList = [];
    let bandwidthList = [];

   brands.forEach((brand) => {
      if (!isEmptyArray(brand)) {
        brand = {
          ...brand,
          value: brand.AssetMakeName,
          label: brand.AssetMakeName,
        };
        brandList.push(brand);
        // get distinct display tech and size for TV
        if (isTV) {
          techList.push(...brand?.Display_tech);
          sizeList.push(...brand?.Display_size);
        } else if (isLaptopDesktop) {
          !isEmptyArray(brand?.Processor) &&
            processorList.push(...brand?.Processor);
          !isEmptyArray(brand?.Storage_Size) &&
            capacityList.push(...brand?.Storage_Size);
          !isEmptyArray(brand?.RAM) && RAMList.push(...brand?.RAM);
          !isEmptyArray(brand?.Storage_Type) &&
            storageTypeList.push(...brand?.Storage_Type);
        } else if (isTablet) {
          !isEmptyArray(brand?.Capacity) &&
            capacityList.push(...brand?.Capacity);
          !isEmptyArray(brand?.Connectivity) &&
            connectivityList.push(...brand?.Connectivity);
          !isEmptyArray(brand?.Model) && modelList.push(...brand?.Model);
        } else if (isMonitor) {
          !isEmptyArray(brand?.Resolution) &&
            sizeList.push(...brand?.Display_size);
          resolutionList.push(...brand?.Resolution);
        } else if (isWebcam) {
          frameRateList.push(...brand?.Frame_Rate);
          connectivityList.push(...brand?.Connectivity);
          resolutionList.push(...brand?.Resolution);
        } else if (isSoundbar) {
          connectivityList.push(...brand?.Connectivity);
        } else if (isKeyboard) {
          keyboardTypeList.push(...brand.Type);
          builtInBluetoothList.push(...brand.In_built_bluetooth);
        } else if (isPrinter) {
          printerTechList.push(...brand?.Printing_Techonolgy);
          functionList.push(...brand?.Function);
        } else if (isMouse) {
          builtInBluetoothList.push(...brand.In_built_bluetooth);
        } else if (isRouter) {
          bandwidthList.push(...brand.Bandwidth);
        }
      }
    });

    // save distinct tech and size list for TV
    // this will used to displat when brand is selected as Other
    if (isTV) {
      setDistinctSize(Array.from(new Set(sizeList)));
      setDistinctTech(Array.from(new Set(techList)));
    } else if (isLaptopDesktop) {
      setDistinctProcessor(Array.from(new Set(processorList)));
      setDistinctCapacity(Array.from(new Set(capacityList)));
      setDistinctRAM(Array.from(new Set(RAMList)));
      setDistinctStorageType(Array.from(new Set(storageTypeList)));
    } else if (isTablet) {
      setDistinctConnectivity(Array.from(new Set(connectivityList)));
      setDistinctCapacity(Array.from(new Set(capacityList)));
      setDistinctModel(Array.from(new Set(modelList)));
    } else if (isMonitor) {
      setDistinctSize(Array.from(new Set(sizeList)));
      setDistinctResolution(Array.from(new Set(resolutionList)));
    } else if (isSoundbar) {
      setDistinctConnectivity(Array.from(new Set(connectivityList)));
    } else if (isKeyboard) {
      setDistinctKeyboardTypeList(Array.from(new Set(keyboardTypeList)));
      setBuiltInBluetoothList(Array.from(new Set(builtInBluetoothList)));
    } else if (isPrinter) {
      setDistinctPrinterTech(Array.from(new Set(printerTechList)));
      setDistinctFunction(Array.from(new Set(functionList)));
    } else if (isMouse) {
      setBuiltInBluetoothList(Array.from(new Set(builtInBluetoothList)));
    } else if (isRouter) {
      setDistinctBandwidthList(Array.from(new Set(bandwidthList)));
    } else if (isWebcam) {
      setDistinctConnectivity(Array.from(new Set(connectivityList)));
      setDistinctFrame(Array.from(new Set(frameRateList)));
      setDistinctResolution(Array.from(new Set(resolutionList)));
    }
    const sortedBrandList = brandList.sort((a, b) =>
      a.AssetMakeName.toLowerCase() > b.AssetMakeName.toLowerCase() ? 1 : -1
    );

    //   const capitalize=(arr) =>{
    //     for (var i = 0; i < arr.length; i++) {
    //         var first = arr[i].AssetMakeName;
    //         arr[i].AssetMakeName = first.slice(0,1).toUpperCase() + first.slice(1).toLowerCase();
    //     }
    //     return arr;
    // }
    // const sortedTitleCaseBrandList=capitalize(sortedBrandList)
    // add others at last
    sortedBrandList.push({ value: "Others", label: "Others" });
    setDeviceBrandList(sortedBrandList);
  };

  const onBrandSelect = (brand) => {
    hideShowInputOtherBrand(brand);
    setSelectedBrand({ ...selectedBrand, ...brand });

    if (brand.value !== "Others") onContinueClick(brand);
    else setDisplayProp("block");
  };
   const getInfoTitle = () => {
    if (selectedBrand?.value === "Others") return homeplusscript.SelectOtherBrandInfo;
     else return homeplusscript.otherBrandInfo;
   }

  const hideShowInputOtherBrand = (brand) => {
    // clear input value
    setOtherBrand("");
    // inputOtherBrand.args = {
    //   ...inputOtherBrand.args,
    //   other: brand.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: brand.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: brand.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  deviceBrands.args = {
    ...deviceBrands.args,
    deviceItems: deviceBrandList,
    value: selectedBrand?.value,
    onClick: onBrandSelect,
  };

  inputOtherBrand.args = {
    ...inputOtherBrand.args,
    label: homeplusscript["labelOtherBrand"],
    placeHolder: homeplusscript["labelOtherBrand"],
    value: otherBrand,
    other: displayProp,
    onChange: setOtherBrand,
  };

   txtInfoSelectBrand.args = {
     ...txtInfoSelectBrand.args,
     content:getInfoTitle()
   }
  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled:
      selectedBrand?.value === "Others" ? otherBrand?.trim() : selectedBrand?.value,
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"]
  };

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceBrand;
