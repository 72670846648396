import { useState } from "react";
import { Stack } from "../../stack/stack";
import { findById } from "./../helper";
import { useContext } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";

function ReplacementOffer() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ReplacementOffer']
  const homeplusscript = workflowState['config']?.scripts["ReplacementOffer"];

  let txtTitle = findById(metadata, "txtTitle");
  let offerSummary = findById(metadata, "offerSummary");
  let btnGoback = findById(metadata, "btnGoBack");
  let btnNext = findById(metadata, "btnNext");

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const replacementOption = fileClaimDetails?.selectedReplacementOption;

  const offerSummaryTableHeaders = [
    {
      key: "deviceCategory",
      text: homeplusscript.labelDeviceCategory,
      hideForNoAction: false,
    },
    {
      key: "brand",
      text: homeplusscript.labelMakeBrand,
      hideForNoAction: false,
    },
    {
      key: "modelNo",
      text: homeplusscript.labelModelNo,
      hideForNoAction: false,
    },
    {
      key: "technologies",
      text: homeplusscript.labelTechnology,
      hideForNoAction: false,
    },
    {
      key: "fee",
      text: homeplusscript.labelFee,
      hideForNoAction: false,
    },
  ];

  const offerSummaryTableData = [
    {
      deviceCategory: replacementOption?.AssetModel?.split("_")[0],
      brand: replacementOption?.AssetModel?.split("_")[1],
      modelNo: replacementOption?.AssetModel,
      technologies: replacementOption?.AssetModel,
      fee: `$ ${replacementOption?.OverageAmount}`,
    },
  ];

  const onNextClick = () => {

    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        isOverageFlow : true,
      },
    }, ["FileClaimDetails"]);

    return completeCurrentStep(currentStep, {
      showOverageSummary: true,
    });
  }

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle.replace(
      "{'offer_selected'}",
      replacementOption?.FulfillmentOption
    ),
  };

  offerSummary.args = {
    ...offerSummary.args,
    tableHeaders: offerSummaryTableHeaders,
    tableData: offerSummaryTableData,
  };

  btnNext.args = {
    ...btnNext.args,
    label: homeplusscript.btnNext,
    onClick: (e) => onNextClick(),
  };

  btnGoback.args = {
    ...btnGoback.args,
    label: homeplusscript.btnGoback,
    onClick: (e) => onGoBackClick(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ReplacementOffer;
