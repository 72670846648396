import { useContext, useEffect } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getContactEmail, getContactNumber, getMetadata } from "../enrollment-workflow/helper";

const MyProfile = () => {
  // const contesxtWorkFlow = useContext(WorkflowContext);

  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['MyProfile']
  const homeplusscript = workflowState['config']?.scripts["MyProfile"];

  if (workflowState.FindAgreementResponse.Agreements.Agreement.length) {
    const userProfile = workflowState.FindAgreementResponse.Agreements.Agreement[0];
    let userAddress = userProfile.Address;
    const contactNumber = getContactNumber(userProfile?.ContactPoints?.ContactPoint);
    const contactEmailAddress = getContactEmail(userProfile?.ContactPoints?.ContactPoint);

    let regUserName = findById(metadata, "registerUserName");
    // let nationalID = findById(metadata, "nationalID");
    let mobileNumber = findById(metadata, "mobileNumber");
    let emailAddress = findById(metadata, "emailAddress");
    let residentialAddress = findById(metadata, "residentialAddress");
    let menu = findById(metadata,"menu");


    console.log(regUserName);

    regUserName.args = {
      ...regUserName.args,
      value: userProfile.Customers.Customer[0].FullName,
    };
    // nationalID.args = {
    //   ...nationalID.args,
    //   value: userProfile.Customers.Customer[0].CustomerNumber,
    // };
    mobileNumber.args = {
      ...mobileNumber.args,
      value: "65" + String(contactNumber),
    };

    emailAddress.args = {
      ...emailAddress.args,
      value: contactEmailAddress,
    };

    residentialAddress.args = {
      ...residentialAddress.args,
      value: `${userAddress.Address1}, ${userAddress.Address2} ${userAddress.Address3}, ${userAddress.City}, ${userAddress.PostalCode}`,
    };
    
    menu.args = {
      ...menu.args,
      labelSubHeader : homeplusscript["menuLabelSubHeader"],
    }

    return <Stack orientation="vertical" metadata={metadata} />;
  }

  return <h1>No Data found</h1>;
};
export default MyProfile;
