import { Box, Button, makeStyles, TextareaAutosize, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { Colors } from './entities/Colors';

export const AddtlCommentWidget = ({surveyQuestion, onSubmitHandler}) => {
const commentTexts = {
    commentTitle: 'Do you have any comments you would like to provide on StarHub SmartHome Support?',
    commentPlaceholder: 'Type your additional comments here...'
}

const classes = CommentStyles();
const [comment, setComment] = useState("");
const [textAreaCount, setTextAreaCount] = useState(150);

  return (
    <Box className={classes.additionalCommentRoot}>
        <Typography align='left' className={classes.commentTitle}>{surveyQuestion}</Typography>
        <Typography align='left'>Comment</Typography>
        
        <TextareaAutosize
            className={classes.comment} 
            maxRows={4}
            style={{ outline: 'none' }}
            onChange={e => {
            setTextAreaCount(e.target.value.length); 
            setComment(e.target.value)}}
            maxLength={150}
            placeholder={commentTexts.commentPlaceholder}
        />
        
        <div className={classes.commentDetails}>
            <div></div>
            <Typography variant='subtitle2' className={classes.commentCount}>{textAreaCount}/150</Typography>
        </div>
        
        <Button
            variant="contained"
            color={'primary'}
            disabled={!comment}
            onClick={() => onSubmitHandler(comment)}
        >Submit</Button>
    </Box>
  )
}
const CommentStyles = makeStyles((theme) => ({
    additionalCommentRoot: {
        // maxWidth: '330px',
        maxWidth: '100%',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '15px 0px',
        padding: '15px',
        
        '& p:first-child': {
            paddingBottom: '19px'
        },
        '& p': {
            lineHeight: '22px',
            paddingBottom: '4px',
            width: '100%'
        },
        '& button': {
            minWidth: '88px',
            borderRadius: '4px',
            width: '88px',
            height: '44px',
            fontWeight: 500,
            marginTop: '8px', 
        }
    },
    comment: {
        height: '100px',
        minHeight: '100px',
        width: '100%',
        borderRadius: '4px',
        padding: '8px 12px 4px 12px',
        
        border: `1px solid ${Colors.DARK_GRAY}`,
        '&: focus': {
          outline: 'none'
        },
    },
    commentTitle: {
        width: '100%',
        color: Colors.BLACK_NEUTRAL,
    },
    commentDetails: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '4px',
        width: '100%',
    },
    commentCount: {
        color: Colors.GRAY_NEUTRAL,
        textAlign: 'right',
        marginTop: '-4px',
        fontWeight: 400
    }
}));