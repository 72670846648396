/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M20 5H4V7H20V5Z" fill="#575757" />
    <path d="M20 10.999H4V12.999H20V10.999Z" fill="#575757" />
    <path d="M20 16.999H4V18.999H20V16.999Z" fill="#575757" />
  </svg>
);

export default icon;
