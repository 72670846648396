import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import { callUpdateRepairRequestApi } from "../api-helper";
import ActionTypes from "../../../ActionTypes";
import logger from "../../../logger.service";
import API from "../../../services";
import { DEVICE_CATEGORY, REPAIR_STATUS } from "../../../utils/constants";

function DeviceMismatchPopup(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const {
    setOpenPopup,
    setClaimDetailList,
    setIsClaimHistoryLoading,
    getClaimDetails,
    prepareClaimHistoryData,
  } = props;

    const metadata = workflowState['config']?.metadata['DeviceMismatchPopup']
    const homeplusscript = workflowState['config']?.scripts["DeviceMismatchPopup"];

  const [isExpanded, setIsExpanded] = useState(true);
  const deviceMismatchPopupData = workflowState?.["deviceMismatchPopupData"];
  const myClaimResponse = workflowState?.["MyClaimResponse"];
  const CreateInteractionResponse =
    workflowState?.["CreateInteractionResponse"];
  const assetDetails =
    myClaimResponse[0]?.ServiceRequests[0]?.MismatchAssetModel?.split("_");
  let deviceMismatchPopupArray =
    deviceMismatchPopupData &&
    Object.keys(deviceMismatchPopupData).map((key) => {
      return deviceMismatchPopupData[key];
    });
  console.log("deviceMismatchPopupArray", deviceMismatchPopupArray);
  const serialNumber =
    myClaimResponse[0]?.ServiceRequests[0]?.MismatchSerialNumber;
  console.log("serialNumber:", serialNumber);
  let fileClaimDetails = workflowState["FileClaimDetails"];
  const RepairRequestResponse = fileClaimDetails?.RepairRequestResponse;
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const ServiceOrderResponse = fileClaimDetails?.ServiceOrderResponse;
  const ServiceOrderId = ServiceOrderResponse?.ServiceOrderId;
  const agreement =
    workflowState?.FindAgreementResponse?.Agreements?.Agreement[0];
  const ClientAccountId = agreement?.ClientAccount?.ClientAccountId;
  const isLaptopDesktop =
    myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Laptop ||
    myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Desktop;
  const isTablet =
    myClaimResponse[0]?.AssetCategoryName === DEVICE_CATEGORY.Tablet;

  const open = (isExpanded) => {
    setOpenPopup(false);
    setIsExpanded(!isExpanded);
  };

  const callUpdateRepairRequest = async (repairStatus) => {
    showLoader(true);
    const UpdateRepairRequestParameter = {
      UpdateRepairRequestParameter: {
        RepairRequestId: RepairRequestResponse?.RepairRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderId,
        RepairRequestType: "HomePlus",
        RepairStatus: repairStatus,
        UpdatedBy: agreement?.ContactPoints?.ContactPoint[1]?.EmailAddress,
        UpdateAction: "HOMEPLUSNEWUPDATEACTION",
        AspDetails: {
          StoreRepEmailId:
            agreement?.ContactPoints?.ContactPoint[1]?.EmailAddress,
        },
        AgreementId: agreement?.AgreementId,
        ClientId: workflowState["config"]?.ClientId,
        ClientAccountId: agreement?.ClientAccount?.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        CaseNumber: myClaimResponse[0]?.CustomerCaseNumber,
        Category: myClaimResponse[0]?.AssetCategoryName,
        Make: assetDetails && assetDetails[0],
        SerialNumber: serialNumber,
        ModelNo: myClaimResponse[0]?.ServiceRequests[0]?.MismatchModelNo
      },
    };
    console.log("UpdateRepairRequestParameter:", UpdateRepairRequestParameter);
    callUpdateRepairRequestApi(
      UpdateRepairRequestParameter,
      onApiSuccess,
      onApiFailure
    );
  };

  const onApiSuccess = (type, data) => {
    // showLoader(false);
    if (type === ActionTypes.UPDATE_REPAIR_REQUEST) {
      updateState(
        {
          ...workflowState,
          FileClaimDetails: {
            UpdateRepairRequestResponse: data,
          },
        },
        ["FileClaimDetails"]
      );
    }
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type, error, state: workflowState });
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  const callDeviceRegistrationApi = async () => {
    showLoader(true);
    const DeviceRegistrationParameters = {
      DeviceRegistrationParameters: {
        isInquiryResolve: true,
        AgreementId: agreement?.AgreementId,
        User: agreement?.Customers.Customer[0].FullName,
        AssetModelKey: assetDetails?.join("_"),
        ModelNo: myClaimResponse[0]?.ServiceRequests[0]?.MismatchModelNo,
        DeviceDetails: {
          Category: myClaimResponse && myClaimResponse[0]?.AssetCategoryName,
          Make: assetDetails && assetDetails[0],
          Model: isTablet ? assetDetails && assetDetails[2] : undefined,
          Capacity: isTablet ? assetDetails[3] : undefined,
          Connectivity: isTablet ? assetDetails[4] : undefined,
          Processor: isLaptopDesktop ? assetDetails[2] : undefined,
          RAM: isLaptopDesktop ? assetDetails[3] : undefined,
          Storage_Size: isLaptopDesktop ? assetDetails[4] : undefined,
          Storage_Type: isLaptopDesktop ? assetDetails[5] : undefined,
        },
        ClientId: workflowState["config"]?.ClientId,
        ClientAccountId: agreement.ClientAccount.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        InteractionLineId:
          CreateInteractionResponse?.InteractionLine?.InteractionLineId,
        Documents: [],
        SerialNumber: serialNumber,
        PurchaseDate: "",
        PurchasePrice: "",
        isDevicemismatch: true,
        AssetId: myClaimResponse && myClaimResponse[0]?.AssetID,
        ServiceRequestId:
          myClaimResponse &&
          myClaimResponse[0]?.ServiceRequests[0]?.ServiceRequestId,
        CustomerCaseId: myClaimResponse && myClaimResponse[0]?.CustomerCaseId,
      },
    };
    console.log("DeviceRegistrationParameters:", DeviceRegistrationParameters);
    API[ActionTypes.DEVICE_REGISTRATION](DeviceRegistrationParameters)
      .then((data) => {
        // showLoader(false)
        updateState({
          ...workflowState,
          RegDeviceResponse: data,
        });
        callClaimDetailsApi(ClientAccountId, agreement?.AgreementId);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        logger({
          type: ActionTypes.DEVICE_REGISTRATION,
          error,
          state: workflowState,
        });
        showLoader(false);
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callClaimDetailsApi = (ClientAccountId, AgreementId) => {
    showLoader(true);
    API[ActionTypes.GET_CLAIM_DETAILS](ClientAccountId, AgreementId)
      .then((data) => {
        showLoader(false);
        setIsClaimHistoryLoading(false);
        let CustomerCases = data?.data?.CustomerCaseResults?.CustomerCases;
        let claimDetailList = [];

        updateState(
          {
            ...workflowState,
            MyClaimResponse: CustomerCases,
          },
          ["MyClaimResponse"]
        );

        CustomerCases.forEach((customerCase) => {
          let ClaimDetail = getClaimDetails(customerCase);
          claimDetailList.push(ClaimDetail);
        });
        setClaimDetailList(claimDetailList);

        console.log(
          "statyus:",
          CustomerCases[0]?.CustomerCaseStatus?.toLowerCase()
        );
        if (CustomerCases[0]?.CustomerCaseStatus?.toLowerCase() === "working") {
          claimDetailList.length = 1;
        } else claimDetailList.length = 0;

        prepareClaimHistoryData(CustomerCases);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({
          type: ActionTypes.GET_CLAIM_DETAILS,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
      });
  };

  const cancelBtnClick = async (isExpanded, status) => {
    await callUpdateRepairRequest(status);
    setOpenPopup(false);
    setIsExpanded(!isExpanded);
    callClaimDetailsApi(ClientAccountId, agreement?.AgreementId);
  };

  const onConfirmClick = async (isExpanded, status) => {
    await callUpdateRepairRequest(status);
    setOpenPopup(false);
    setIsExpanded(!isExpanded);
    callDeviceRegistrationApi();
  };

  const deviceDetailsTableHeaders = (data) => {
    let x = [];
    data?.map((val) => {
      return x.push({ key: val.spec, text: val.spec, hideForNoAction: false });
    });
    return x;
  };

  const deviceTableData = (data) => {
    let y = [];
    data?.map((val, i) => {
      return y.push({
        [val.spec]: `<del> ${val.providedSpec} </del> <span class='text-red-600 pl-1'>${val.actualSpec}</span> `,
      });
    });
    return [Object.assign({}, ...y)];
  };

  let txtPara1 = findById(metadata, "txtPara1");
  let txtPara2 = findById(metadata, "txtPara2");
  let txtNote = findById(metadata, "txtNote");
  let btnCancel = findById(metadata, "btnCancel");
  let btnConfirm = findById(metadata, "btnConfirm");
  let CloseIcon = findById(metadata, "CloseIcon");
  let deviceDetails = findById(metadata, "deviceDetails");

  txtPara1.args = {
    ...txtPara1.args,
    content: homeplusscript.txtPara1,
  };

  txtPara2.args = {
    ...txtPara2.args,
    content: homeplusscript.txtPara2,
  };

  txtNote.args = {
    ...txtNote.args,
    content: homeplusscript.txtNote,
  };

  btnCancel.args = {
    ...btnCancel.args,
    label: homeplusscript.btnCancel,
    onClick: (e) => cancelBtnClick(isExpanded, REPAIR_STATUS.MISMATCH_REJECTED),
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label: homeplusscript.btnConfirm,
    onClick: (e) => onConfirmClick(isExpanded, REPAIR_STATUS.DEVICE_MISMATCH_ACKNOWLEDGE),
  };

  CloseIcon.args = {
    ...CloseIcon.args,
    onClick: () => open(isExpanded),
  };

  deviceDetails.args = {
    ...deviceDetails.args,
    tableHeaders: deviceDetailsTableHeaders(deviceMismatchPopupArray),
    tableData: deviceTableData(deviceMismatchPopupArray),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default DeviceMismatchPopup;
