import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceStorageCapacity() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceStorageCapacity']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceStorageCapacity"];

  const [otherCapacity, setOtherCapacity] = useState("");
  const [deviceStorageList, setDeviceStorageList] = useState([]);
  const [selectedCapacity, setSelectedCapacity] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden")

  let deviceStorageCapacity = findById(metadata, "deviceStorageCapacity");
  let inputOtherCapacity = findById(metadata, "inputOtherCapacity");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDeviceStorageInfo = findById(metadata, "txtDeviceStorageInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop ;
  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;

  const getInfoTitle = () => {
    if (isDesktop) return homeplusscript.deviceCapacityInfoDesktop;
    else if (isLaptop) return homeplusscript.deviceCapacityInfoLaptop;
    else if (isTablet) return homeplusscript.deviceCapacityInfoTablet.replace("{'key'}", getTabletKey());
    else return homeplusscript.deviceCapacityInfo;
  };

  const getTabletKey = () => {
    return regDeviceDetails?.selectedBrand === "Apple" ? "iPad" : "Tablet"
  }

  const onContinueClick = (capacity) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedCapacity: capacity?.value || otherCapacity?.trim(),
        isOtherCapacitySelected: isEmpty(capacity)
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectStorageType: isLaptop || isDesktop,
      selectDeviceModel: isTablet,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: isLaptop || isDesktop,
      GoBackSerialNumber: isTablet,
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceStorageCapacity(
        isLaptop || isDesktop ? 
        regDeviceDetails.selectedBrandDetails.Storage_Size :
        regDeviceDetails.selectedBrandDetails.Capacity
      );
    // now get distinct list of all brand
    else showDeviceStorageCapacity(regDeviceDetails?.distinctCapacityList);
  }, []);

  const showDeviceStorageCapacity = (storageCapacity = []) => {
    let capacityList = [];
    storageCapacity.forEach((capacity) => {
      if (!isEmpty(capacity)) {
        let deviceStorageCapacity = {
          value: capacity,
          label: capacity,
          // using below properties for custom sorting
          unit: capacity.match(/[a-zA-Z]+|[0-9]+/g)[1],
          size: capacity.match(/[a-zA-Z]+|[0-9]+/g)[0]
        };
        capacityList.push(deviceStorageCapacity);
      }
    });

    const sizes = {
      GB: 100,
      TB: 200,
    };

    const sortedCapacityList = capacityList.sort((a, b) =>
      a.size - b.size
    ).sort((a, b) =>
      sizes[a.unit] - sizes[b.unit]
    );

    // add others at last
    sortedCapacityList.push({ value: "Others", label: "Others" });

    setDeviceStorageList(capacityList);
  };

  const onCapacitySelect = (capacity) => {
    hideShowInputOtherCapacity(capacity);
    setSelectedCapacity({ ...selectedCapacity, ...capacity });

    if (capacity.value !== "Others") onContinueClick(capacity);
    else setDisplayProp("block")
  };

  const hideShowInputOtherCapacity = (capacity) => {
    // clear input value
    setOtherCapacity("");
    // inputOtherCapacity.args = {
    //   ...inputOtherCapacity.args,
    //   other: capacity.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: capacity.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: capacity.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      isTablet ? getTabletKey() : regDeviceDetails?.selectedCategory?.type
    ),
  };

  deviceStorageCapacity.args = {
    ...deviceStorageCapacity.args,
    deviceItems: deviceStorageList,
    value: selectedCapacity?.value,
    onClick: onCapacitySelect,
  };

  inputOtherCapacity.args = {
    ...inputOtherCapacity.args,
    label: homeplusscript.labelOtherCapacity,
    placeHolder: homeplusscript.placeHolderOtherCapacity,
    value: otherCapacity,
    other: displayProp,
    onChange: setOtherCapacity,
  };

  txtDeviceStorageInfo.args = {
    ...txtDeviceStorageInfo.args,
    content: getInfoTitle(),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherCapacity?.trim(),
    displayClasses: selectedCapacity?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceStorageCapacity;
