/**
 * Entity pertaining to the app configuration values.
 */

 import { entity } from 'simpler-state';
 import '../font.css';
 
 export const appConfig = entity({
   name: 'Security Advisor',
   theme: {
     primaryColor: '#1ED760',
     primaryContrastTextColor: '#FFF',
     font: 'LatoRegular',
     button: {
       primaryColor: '#FFF',
       altColor: '#000',
       primaryTextColor: '#000',
       altTextColor: '#FFF',
     },
   },
 });
 