import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

function CancelClaimMsgs(props) {
    const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout,] =
    useContext(WorkflowContext);

    const metadata = workflowState['config']?.metadata['CancelClaimMsgs']
    const homeplusscript = workflowState['config']?.scripts["CancelClaimMsgs"];
    let CancelClaim = workflowState["show-replacement-options"];
   
    let cancelClaimHeader = findById(metadata, "txtTitle");
    let doNotCancelClaimHeader = findById(metadata, "txtTitle1");
    let menu = findById(metadata, "menu");
    let btnExit = findById(metadata, "btnExit");


    cancelClaimHeader.args = { ...cancelClaimHeader.args, content: homeplusscript.txtTitle };
  
     
 const onMenuItemClick = (redirect) => {
        refreshStateOnLogout(workflowState, redirect);
      };

    const goToDashboard = () => {
        // clear all file claim data
        updateState({
          ...workflowState, 
          FileClaimDetails: {},
        });
        return completeCurrentStep(currentStep, {
          goToDashboard: true,
        });
      };

    menu.args = {
        ...menu.args,
        labelSubHeader: homeplusscript["menu_labelSubHeader"],
        onClose: (e) => goToDashboard(),
        onMenuItemClick: (e) => onMenuItemClick(e),
      };

    btnExit.args = {
        ...btnExit.args,
        label: homeplusscript.btnExit,
         enabled: true,
         onClick: (e) => goToDashboard(),
      };
      
 return (
        <>
          <Stack orientation="vertical" metadata={metadata} />
        </>
      );
}
export default CancelClaimMsgs;