/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

const calculateDisplay = (timer) => {
  const dt = new Date();
  if (timer <= dt) {
    return "00:00";
  }
  let seconds = Math.round(timer.getSeconds() - dt.getSeconds());
  let minutes = Math.round(timer.getMinutes() - dt.getMinutes());
  if (seconds < 0) {
    seconds = 60 + seconds;
    minutes = minutes - 1;
  }
  if (minutes < 0) {
    minutes = 60 + minutes;
  }
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const Timer = ({ classes, state, parentCallback, subDivClasses }) => {
  const [display, setDisplay] = useState("02:00");
  useEffect(() => {
    if (state.Timer >= new Date()) {
      const timer = setInterval(() => {
        setDisplay(calculateDisplay(state.Timer));
        parentCallback(calculateDisplay(state.Timer));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [state.Timer, display]);
  return (
    <div className={classes}>
      <span
        className={`${
          display === "00:00" ? subDivClasses ? subDivClasses :"text-accentA5 font-medium" : ""
        }`}
      >
        {display}
      </span>
    </div>
  );
};

export default Timer;
