import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";
import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceScreenSize() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceScreenSize']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceScreenSize"];

  const [otherSize, setOtherSize] = useState("");
  const [screenSizeList, setScreenSizeList] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden")

  let screenSizes = findById(metadata, "screenSizes");
  let inputOtherSize = findById(metadata, "inputOtherSize");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtSizeInfo = findById(metadata, "txtSizeInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;

  const onContinueClick = (size) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedScreenSize: size?.value || otherSize?.trim(),
        isOtherSizeSelected: isEmpty(size),
      },
    }, ["RegDeviceDetails"]);

    const isGoogleTv =
      regDeviceDetails?.selectedBrandDetails?.Google_TV?.length >= 2;
    const isMonitor = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.Monitor;

    return completeCurrentStep(currentStep, {
      askPuchaseReceipt: !isGoogleTv,
      selectGoogleTv: isGoogleTv,
      deviceConnectivity:isMonitor,
    });
  };

  const onGoBackClick = () => {
    const isMonitor = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.Monitor;
    const isTV = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.TV;
    return completeCurrentStep(currentStep, {
      GoBack: isTV,
      GoBackResolution: isMonitor,
    });
  };

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      displayDeviceScreenSizes(
        regDeviceDetails?.selectedBrandDetails?.Display_size
      );
    // now get distinct list of all brand
    else displayDeviceScreenSizes(regDeviceDetails?.distinctSizeList);
  }, []);

  const displayDeviceScreenSizes = (sizes) => {
    let screenSizeList = [];
    sizes?.forEach((size) => {
      if (!isEmpty(size)) {
        let screenSize = {
          value: size,
          label: `${size}"`,
        };
        screenSizeList.push(screenSize);
      }
    });

    // add others at last
    screenSizeList.push({ value: "Others", label: "Others" });

    setScreenSizeList(screenSizeList);
  };

  const onSizeSelect = (size) => {
    hideShowInputOtherSize(size);
    setSelectedSize({ ...selectedSize, ...size });

    if (size.value !== "Others"){
      onContinueClick(size);
    } else{
      setDisplayProp("block")
    }
  };
  const getInfoSize = () => {
    const isMonitor = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.Monitor;
     if(isMonitor) return homeplusscript.otherSizeInfoMonitor;
    else return homeplusscript.otherSizeInfo.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type)
    }

  const hideShowInputOtherSize = (size) => {
    // set other size
    setOtherSize("");
    // inputOtherSize.args = {
    //   ...inputOtherSize.args,
    //   other: displayProp,
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: size.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: size.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  screenSizes.args = {
    ...screenSizes.args,
    deviceItems: screenSizeList,
    value: selectedSize?.value,
    onClick: onSizeSelect,
  };

  inputOtherSize.args = {
    ...inputOtherSize.args,
    label: homeplusscript["labelScreenSize"],
    placeHolder: homeplusscript["labelScreenSize"],
    value: otherSize,
    other: displayProp,
    onChange: setOtherSize,
  };

  txtSizeInfo.args = {
    ...txtSizeInfo.args,
    content: getInfoSize()
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherSize?.trim(),
    displayClasses: selectedSize?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceScreenSize;
