import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { DEVICE_CATEGORY, REPAIR_STATUS } from "../../../utils/constants";
import { useEffect } from "react";
import logger from "../../../logger.service";
import { reschedulePickUpDeliveryOnFeeWaiver } from "../reschedule-pickup-delivery-logic";

function ScheduleOnsiteDate() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ScheduleOnsiteDate']
  const homeplusscript = workflowState['config']?.scripts["ScheduleOnsiteDate"];

  const [onsiteDate, setOnsiteDate] = useState("");
  const [onsiteTimeSlot, setOnsiteTimeSlot] = useState("");
  const [dates, setDates] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  // let inputOnsiteDate = findById(metadata, "inputOnsiteDate");
  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtOnsiteDateInfo = findById(metadata, "txtOnsiteDateInfo");
  let menu = findById(metadata, "menu");
  let dateSelection = findById(metadata, "onsiteDate");
  let timeSlotSelection = findById(metadata, "onsiteTime");

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const userAddress = agreement?.Address;

  const isBraintree = workflowState["config"]?.isBraintree

  const deviceCategory =
    fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryName;

  useEffect(() => {
    callGetShippingMethodsApi();
  }, []);

  useEffect(() => {
    if (fileClaimDetails?.ShippingMethodResponse && !isEmpty(onsiteDate))
      loadTimeSlots(fileClaimDetails?.ShippingMethodResponse, onsiteDate);
  }, [onsiteDate]);

  const callGetShippingMethodsApi = () => {
    showLoader(true);

    let LookupShippingMethodsParameters = {
      LookupShippingMethodsParameters: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ReceiverAddress: userAddress,
        Mode: "pickup",
      },
    };

    API[ActionTypes.GET_SHIPPING_METHODS](LookupShippingMethodsParameters)
      .then((data) => {
        showLoader(false);

        const ShippingMethodResponse = data.data.ShippingMethodResults;

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            ShippingMethodResponse,
          },
        }, ["FileClaimDetails"]);

        loadDates(ShippingMethodResponse);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_SHIPPING_METHODS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const loadDates = (ShippingMethodResponse) => {
    const dateList = ShippingMethodResponse?.map(({ pickUpDate }) => {
      return { value: pickUpDate, label: pickUpDate };
    });

    setDates(dateList);
    setOnsiteDate(dateList[0].value);
    // loadTimeSlots(ShippingMethodResponse, dateList[0].value)
  };

  const loadTimeSlots = (dateList, date) => {
    const timeList = dateList?.filter((item) => item.pickUpDate === date);

    const timeSlotes = timeList?.[0]?.pickUpSlot.map((item, index) => {
      const slots = { value: item, label: item };
      return slots;
    });

    setTimeSlots(timeSlotes);
    setOnsiteTimeSlot(timeSlotes?.[0].value);
  };

  const onContinueClick = () => {

    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        onsiteDate,
        onsiteTimeSlot,
      },
    }, ["FileClaimDetails"]);

    if (fileClaimDetails?.RepairRequestResponse?.RepairStatus === REPAIR_STATUS.PICKUP_FAILED){
      if(!fileClaimDetails?.ServiceRequestResponse?.ServiceRequest?.IsPickupWaive)
        // get payment as no waiver 
        if (isBraintree) {
          return completeCurrentStep(currentStep, {
            braintree: true,
          });
        } else {
          return completeCurrentStep(currentStep, {
            GoBack: true,
          });
        }
      else {
        // call charge order as COD with 0 fee and update shipping order api
        reschedulePickUpDeliveryOnFeeWaiver(
          fileClaimDetails,
          undefined,
          workflowState,
          showLoader,
          updateState,
          completeCurrentStep,
          refreshStateOnLogout,
          currentStep
        );
      }
    } else
      return completeCurrentStep(currentStep, {
        Continue: true,
      });
  };

  const onGoBackClick = () => {
    if(fileClaimDetails.fromMyClaimScreen){
      updateState({
        ...workflowState,
        FileClaimDetails: {},
      });
      return completeCurrentStep(currentStep, {
        GoBackMyClaim: true,
      });
    }else {
      return completeCurrentStep(currentStep, {
        GoBack: true,
      });
    }
  };

  const onDateSelect = (date) => {
    setOnsiteDate(date?.value);
  };

  const onTimeSlotSelect = (timeSlot) => {
    setOnsiteTimeSlot(timeSlot?.value);
  };

  // inputOnsiteDate.args = {
  //   ...inputOnsiteDate.args,
  //   label:
  //     deviceCategory === DEVICE_CATEGORY.TV
  //       ? homeplusscript.labelOnsiteDate
  //       : homeplusscript.labelPickupDate,
  //   placeHolder:
  //     deviceCategory === DEVICE_CATEGORY.TV
  //       ? homeplusscript.labelOnsiteDate
  //       : homeplusscript.labelPickupDate,
  //   value: onsiteDate,
  //   onChange: setOnsiteDate,
  // };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: onsiteDate && onsiteTimeSlot,
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content:
      deviceCategory === DEVICE_CATEGORY.TV
        ? homeplusscript.title
        : homeplusscript.titlePickup,
  };

  txtOnsiteDateInfo.args = {
    ...txtOnsiteDateInfo.args,
    content:
      deviceCategory === DEVICE_CATEGORY.TV
        ? homeplusscript.labelOnsiteDateInfo
        : homeplusscript.labelPickupDateInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }  

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  dateSelection.args = {
    ...dateSelection.args,
    label: deviceCategory === DEVICE_CATEGORY.TV
    ? homeplusscript.labelOnsiteDate
    : homeplusscript.labelPickupDate,
    options: dates,
    value: onsiteDate,
    onSelect: onDateSelect,
  };

  timeSlotSelection.args = {
    ...timeSlotSelection.args,
    label: deviceCategory === DEVICE_CATEGORY.TV
    ? homeplusscript.labelOnsiteTimeSlot
    : homeplusscript.labelPickupTimeSlot,
    options: timeslots,
    value: onsiteTimeSlot,
    onSelect: onTimeSlotSelect,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ScheduleOnsiteDate;
