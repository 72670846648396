import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "../enrollment-workflow/helper";

function ContactUsNotes(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ContactUsNotes']
  const homeplusscript = workflowState['config']?.scripts["ContactUsNotes"];

  let cancelClaimHeader = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");
  let btnExit = findById(metadata, "btnExit");

  cancelClaimHeader.args = {
    ...cancelClaimHeader.args,
    content: homeplusscript.txtTitle,
  };

  const onMenuItemClick = (redirect) => {
    refreshStateOnLogout(workflowState, redirect);
  };

  const goToHomePage = () => {
    // clear all file claim data
    
    return completeCurrentStep(currentStep, {
      goToHomePage: true,
    });
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
    onClose: (e) => goToHomePage(),
    onMenuItemClick: (e) => onMenuItemClick(e),
  };

  btnExit.args = {
    ...btnExit.args,
    label: homeplusscript.btnExit,
    enabled: true,
    onClick: (e) => goToHomePage(),
  };
  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
}
export default ContactUsNotes;
