import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import metadataa from "../../stack/metadata.json";

import { findById, getMetadata } from "../helper";

import {
  callCreateClient,
  callCreatePaypal,
  callCreateGpay,
} from "./braintreeWeb";

import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { BG_ERROR_CODE, CARD, REGEX } from "../../../utils/constants";

import Popup from "reactjs-popup";
import ChargeOrderHoldPopup from "../file-claim/chargeOrderHoldPopup";
import logger from "../../../logger.service";
import { reschedulePickUpDeliveryOnFeeWaiver } from "../reschedule-pickup-delivery-logic";

function CreditCardDetails() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["BraintreeDetails"];
  // const metadata = getMetadata(metadataa, "BraintreeDetails");
  const homeplusscript = workflowState["config"]?.scripts["CreditCardDetails"];
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);


  let btnCancel = findById(metadata, "btnCancel");
  let ccardNumber = findById(metadata, "ccardNumber");
  let inputCardHolder = findById(metadata, "inputCardHolder");
  let txtTitle = findById(metadata, "txtTitle");
  let inputExpiry = findById(metadata, "inputExpiry");
  let inputCvv = findById(metadata, "inputCvv");
  let btnConfirm = findById(metadata, "btnConfirm");
  let btnPaypal = findById(metadata, "btnPaypal");
  let menu = findById(metadata, "menu");
  let orLine = findById(metadata, "orLine");

  let txtCardNumberError = findById(metadata, "txtCardNumberError");
  let txtCardNameError = findById(metadata, "txtCardNameError");
  let txtExpiryDateError = findById(metadata, "txtExpiryDateError");
  let txtCvvError = findById(metadata, "txtCvvError");
  let txtBGError = findById(metadata, "txtBGError");
  const [bgError, setBGError] = useState("");

  // const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [cardIcon, setCardIcon] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const contentStyle = {
    padding: "15px",
    width: "345px",
    height: "265px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    borderRadius: "8px",
    borderColor: "",
    textColor: "",
  };

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const replacementOption = fileClaimDetails?.selectedReplacementOption;
  const isOverageFlow = fileClaimDetails?.isOverageFlow;
  const isTVRepairWithOverage = fileClaimDetails?.isTVRepairWithOverage;
  const ServiceFeeResponse = fileClaimDetails.ServiceFeeResponse;
  const BraintreeDetails =
    workflowState["BraintreeDetails"]?.ServiceFeeResponse;
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const userAddress = agreement?.Address;
  const ServiceOrderId = fileClaimDetails?.ServiceOrderResponse?.ServiceOrderId;
  const billingAddress = agreement?.BillingAddress;

  const isBraintree = workflowState["config"]?.isBraintree;
  const is3DS = isBraintree?.method?.creditCard?.is3DS;
  const maid = isBraintree?.maid;
  const isPayPal = isBraintree?.method?.paypal;
  const isGpay = isBraintree?.method?.gpay;

  //Braintree
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState("");
  const [createThreeDSecure, setCreateThreeDSecure] = useState("");
  const [validCVV, setValidCVV] = useState("");
  const [validCardNumber, setValidCardNumber] = useState("");
  const [validName, setValidName] = useState("");
  const [validExpDate, setValidExpDate] = useState("");
  const [inValidCVV, setInValidCVV] = useState("");
  const [inValidCardNumber, setInValidCardNumber] = useState("");
  const [inValidName, setInValidName] = useState("");
  const [inValidExpDate, setInValidExpDate] = useState("");

  let btnEnabled;

  let allValidFields = validCVV && validCardNumber && validExpDate && validName;

  const onCCSuccess = (
    hostedFieldsInstance,
    threeDSecureInstance,
    deviceData
  ) => {
    showLoader(false);
    setHostedFieldsInstance(hostedFieldsInstance);
    setCreateThreeDSecure(threeDSecureInstance);
    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          deviceData: deviceData?.deviceData,
        },
      },
      ["FileClaimDetails"]
    );
    updateState(
      {
        ...workflowState,
        BraintreeDetails: {
          deviceData: deviceData?.deviceData,
          paymentMethod: 'CRE'
        },
      },
      ["BraintreeDetails"]
    );
  };

  const onPayPalSuccess = (response, deviceData) => {
    let paymentMethod = 'PYPL'
    setPaymentMethod("PYPL");
    updateState(
      {
        ...workflowState,
        BraintreeDetails: {
          paymentMethod: "PYPL",
        },
      },
      ["BraintreeDetails"]
    );
    return callCreatePaymentMethod(response, paymentMethod, deviceData?.deviceData)
  };

  const chargeOrderRequest = (
    response,
    paymentMethod,
    getCCDetails,
    paymentMethodResp,
    deviceData
  ) => {
    let ChargeOrder = {
      PaymentMethodType: paymentMethod,
      ChargeOrderStatus: "PREAUTH",
      AddressId: billingAddress?.AddressId,
      AdditionalChargeAuth: "false",
      ChargeOrderCardBrand:
        paymentMethod === "CRE" ? response?.details?.cardType : response?.type,
      ChargeOrderCardType: "CREDIT",
      CardCheckNumber: parseInt("0"),
      CardHolderFirstName:
        response?.details?.cardholderName?.split(" ")[0] ||
        response?.details?.firstName,
      CardHolderLastName:
        response?.details?.cardholderName?.split(" ")[1] ||
        response?.details?.lastName,
      OverageAmount: isOverageFlow
        ? replacementOption?.OverageAmount
        : undefined,
      ccDetails: paymentMethod === "CRE" ? getCCDetails() : undefined,
      DeviceData: deviceData,
    };

    return reschedulePickUpDeliveryOnFeeWaiver(
      fileClaimDetails,
      setOpenPopup,
      workflowState,
      showLoader,
      updateState,
      completeCurrentStep,
      refreshStateOnLogout,
      currentStep,
      ChargeOrder,
      handleBGError,
      uniqueId,
      response,
      paymentMethodResp
    );
  };


  useEffect(() => {
    callClientToken();
  }, []);

  const callClientToken = () => {
    showLoader(true);
    API[ActionTypes.GET_CLIENT_TOKEN](maid)
      .then(async (data) => {
        updateState(
          {
            ...workflowState,
            FileClaimDetails: {
              ClientTokenResponse: data.data.clientToken,
            },
          },
          ["FileClaimDetails"]
        );
        showLoader(true);
        await createClient(data.data.clientToken);
        if (isPayPal) {
          await createPaypal(data.data.clientToken);
        }
        // if (isGpay) {
        //   await createGpay(data.data.clientToken);
        // }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({
          type: ActionTypes.GET_CLIENT_TOKEN,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const createClient = (clientTokenResponse) => {
    return callCreateClient(
      clientTokenResponse,
      onCCSuccess,
      showLoader,
      setValidCardNumber,
      setValidName,
      setValidExpDate,
      setValidCVV,
      setInValidCardNumber,
      setInValidName,
      setInValidExpDate,
      setInValidCVV,
      setBGError
    );
  };

  const createPaypal = (clientTokenResponse) => {
    return callCreatePaypal(
      clientTokenResponse,
      onPayPalSuccess,
      workflowState
    );
  };

  const createGpay = (clientTokenResponse) => {
    return callCreateGpay(clientTokenResponse, onGpaySuccess);
  };

  const onGpaySuccess = (response) => {
    setPaymentMethod("GPAY");
    let paymentMethod = "GPAY";
    updateState(
      {
        ...workflowState,
        BraintreeDetails: {
          paymentMethod: paymentMethod,
        },
      },
      ["BraintreeDetails"]
    );
  };

  let threeDSecureParameters = {
    amount: isOverageFlow
      ? replacementOption?.OverageAmount
      : ServiceFeeResponse?.TotalAmount || BraintreeDetails?.TotalAmount, // check with team
    email: workflowState["login"]?.email,
    billingAddress: {
      givenName: agreement.Customers?.Customer[0]?.FirstName, // ASCII-printable characters required, else will throw a validation error
      surname: agreement.Customers?.Customer[0]?.LastName, // ASCII-printable characters required, else will throw a validation error
      phoneNumber: String(
        agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
      ),
      streetAddress: billingAddress?.AddressLine1,
      extendedAddress: billingAddress?.AddressLine2,
      locality: billingAddress?.CityName,
      region: "SG", // ISO-3166-2 code
      postalCode: billingAddress?.PostalCode,
      countryCodeAlpha2: "SG",
    },
    additionalInformation: {
      workPhoneNumber: String(
        agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
      ),
      shippingGivenName: agreement.Customers?.Customer[0]?.FirstName,
      shippingSurname: agreement.Customers?.Customer[0]?.LastName,
      shippingPhone: String(
        agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
      ),
      shippingAddress: {
        streetAddress: billingAddress?.AddressLine1,
        extendedAddress: billingAddress?.AddressLine2,
        locality: billingAddress?.CityName,
        region: "SG", // ISO-3166-2 code
        postalCode: billingAddress?.PostalCode,
        countryCodeAlpha2: "SG",
      },
    },
    onLookupComplete: function (data, next) {
      showLoader(false);
      next();
    },
  };
  const handleBGError = () => {
    return false;
  };
  const submit = () => {
    showLoader(true);
    let deviceData = fileClaimDetails?.deviceData
    let paymentMethod = 'CRE'
    hostedFieldsInstance
      .tokenize()
      .then((tokenizeResponse) => {
        if (tokenizeResponse?.binData?.prepaid === "Yes") {
          setBGError(homeplusscript.PREPAID_CARD_ERROR);
          showLoader(false);
        } else if (is3DS) {
          return callCreatePaymentMethod(tokenizeResponse, paymentMethod, deviceData);
        } else {
          updateState(
            {
              ...workflowState,
              FileClaimDetails: {
                verifyCardResponse: {
                  nonce: tokenizeResponse.nonce,
                  bin: tokenizeResponse.details.bin,
                },
              },
            },
            ["FileClaimDetails"]
          );
          return next(tokenizeResponse, undefined, paymentMethod, deviceData);
        }
      })
      .catch((err) => {
        showLoader(false);
        console.log(err, "Tokenize Error");
      });

  };
  const callCreatePaymentMethod = (verifyCardResponse, paymentMethod, deviceData) => {
    const createPaymentMethodParameters = {
      CreatePaymentMethodParameters: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        AgreementId: agreement?.AgreementId,
        ServiceOrderId: ServiceOrderId,
        CreateCustomerRequest: {
          Amount: fileClaimDetails?.ServiceFeeResponse?.TotalAmount,
          Nonce: verifyCardResponse?.nonce,
          Bin: verifyCardResponse?.details?.bin,
          Email: workflowState["login"]?.email,
          Billing: {
            GivenName: agreement.Customers?.Customer[0]?.FirstName,
            SurName: agreement.Customers?.Customer[0]?.LastName,
            PhoneNumber: String(
              agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            streetAddress: userAddress?.Address1,
            ExtendedAddress: userAddress?.Address2,
            Locality: userAddress?.City,
            Region: "SG", // ISO-3166-2 code
            PostalCode: userAddress?.PostalCode,
            CountryCodeAlpha2: "SG",
          },
          AdditionalInformation: {
            WorkPhoneNumber: String(
              agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            ShippingGivenName: agreement.Customers?.Customer[0]?.FirstName,
            ShippingSurname: agreement.Customers?.Customer[0]?.LastName,
            ShippingPhone: String(
              agreement?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            ShippingAddress: {
              StreetAddress: userAddress?.Address1,
              ExtendedAddress: userAddress?.Address2,
              Locality: userAddress?.City,
              Region: "SG", // ISO-3166-2 code
              PostalCode: userAddress?.PostalCode,
              CountryCodeAlpha2: "SG",
            },
          },
        },
      },
    };
    showLoader(true);
    API[ActionTypes.BT_CREATE_PAYMENT_METHOD](
      createPaymentMethodParameters,
      ServiceOrderId
    ).then((data) => {
      if (paymentMethod === "CRE") {
        return callNewNonce(data?.data?.CreatePaymentMethodResults, paymentMethod, deviceData);
      }
      else{
        return next(verifyCardResponse, data?.data?.CreatePaymentMethodResults, paymentMethod, deviceData);
      }
    }).catch(err => {
      showLoader(false)
      setBGError(homeplusscript.PREPAID_CARD_ERROR);
    })
  };

  const callNewNonce = (paymentMethodResp, paymentMethod, deviceData) => {
    API[ActionTypes.BT_CREATE_NEW_NONCE](
      paymentMethodResp?.Token,
      ServiceRequestResponse?.CustomerCaseId
    ).then((newNonce) => {
      threeDSecureParameters.nonce =
        newNonce?.data?.paymentMethodNonce?.nonce;
      threeDSecureParameters.bin =
        newNonce?.data?.paymentMethodNonce?.details?.bin;
      createThreeDSecure
        .verifyCard(threeDSecureParameters)
        .then((verifyCardResponse) => {
          updateState(
            {
              ...workflowState,
              FileClaimDetails: {
                verifyCardResponse: {
                  nonce: newNonce?.data?.paymentMethodNonce?.nonce,
                  bin: newNonce?.data?.paymentMethodNonce?.details.bin,
                  threeDSecureAuthenticationId:
                    verifyCardResponse?.threeDSecureInfo
                      ?.threeDSecureAuthenticationId,
                  productId:
                    newNonce?.data?.paymentMethodNonce?.binData?.productId,
                  prepaid:
                    newNonce?.data?.paymentMethodNonce?.binData?.prepaid,
                },
              },
            },
            ["FileClaimDetails"]
          );

          let storeVerifyCardRequest = {
            StoreVerifiedCardRequest: {
              AgreementId: agreement?.AgreementId,
              CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
              ServiceRequestId:
                ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
              VerifiedCardDetails: verifyCardResponse,
            },
          };

          API[ActionTypes.BT_STORE_VERIFY_CARD](storeVerifyCardRequest).then(
            () => {
              return true;
            }
          );

          if (
            verifyCardResponse?.threeDSecureInfo?.status ===
            "challenge_required"
          ) {
            showLoader(false);
            setBGError(homeplusscript.LIABILITY_SHIFTED_ERROR);
          } else if (!verifyCardResponse?.liabilityShifted) {
            setBGError(homeplusscript.LIABILITY_SHIFTED_ERROR);
          } else if (verifyCardResponse?.liabilityShifted) {
            return next(verifyCardResponse, paymentMethodResp, paymentMethod, deviceData);
          } else {
            setBGError(homeplusscript.BG_ERROR_GENERIC);
            updateState({
              ...workflowState,
              [currentStep]: {
                ...workflowState[currentStep],
              },
            });
          }
        })
        .catch((err) => {
          showLoader(false);
          console.log(err, "Verifycard error");
        });
    });
  };

  const next = (response, paymentMethodResp, paymentMethod, deviceData) => {
    const getCCDetails = () => {
      let ccmask = response?.details?.lastFour;
      const name = response?.details?.cardholderName?.split(" ")[0];
      let cardType = response?.details?.cardType;
      let cvv = "";
      return {
        ccmask,
        name,
        cardType,
        cvv,
      };
    };

    chargeOrderRequest(
      response,
      paymentMethod,
      getCCDetails,
      paymentMethodResp,
      deviceData
    );
  };

  ccardNumber.args = {
    ...ccardNumber.args,
    label: homeplusscript.inputCardNumber_label,
    value: cardNumber,
    image: cardIcon,
  };

  inputCardHolder.args = {
    ...inputCardHolder.args,
    label: homeplusscript.inputCardHolder_label,
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
  };

  inputExpiry.args = {
    ...inputExpiry.args,
    label: homeplusscript.labelExpiry,
    placeHolder: homeplusscript.placeholderExpiry,
    value: expiryDate,
  };

  inputCvv.args = {
    ...inputCvv.args,
    label: homeplusscript.labelCVV,
    value: cvv,
    maxlength: cardType === CARD.AMEX ? 4 : 3,
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label:
      isOverageFlow && !isTVRepairWithOverage
        ? homeplusscript.btnProcessPayment
        : homeplusscript.btnConfirm,
    onClick: (e) => submit(),
    enabled: allValidFields ? true : false,
  };

  btnPaypal.args = {
    ...btnPaypal.args,
    label: "Paypal",
    onClick: (e) => submit(),
    enabled: "",
  };

  orLine.args = {
    ...orLine.args,
    displayClasses: isPayPal ? "block" :"hidden"
  };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true),
  };
  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  txtCardNumberError.args = {
    ...txtCardNumberError.args,
    content: inValidCardNumber ? homeplusscript.cardNumberError : "",
  };

  txtCardNameError.args = {
    ...txtCardNameError.args,
    content: inValidName ? homeplusscript.nameError : "",
  };

  txtExpiryDateError.args = {
    ...txtExpiryDateError.args,
    content: inValidExpDate ? homeplusscript.expiryDateError : "",
  };

  txtCvvError.args = {
    ...txtCvvError.args,
    content: inValidCVV ? homeplusscript.cvvError : "",
  };

  txtBGError.args = {
    ...txtBGError.args,
    content: bgError,
    displayClasses: bgError ? "block" : "hidden",
  };

  return (
    <>
      <Popup
        contentStyle={contentStyle}
        open={openPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <div className="md:w-full">
          <ChargeOrderHoldPopup setOpenPopup={setOpenPopup} />
        </div>
      </Popup>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
}

export default CreditCardDetails;
