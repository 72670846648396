import * as React from "react";

function ChatAlertIconSvg(
  props
) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.96 91.8953L16.61 76.3554H6.22L2 17.9854L71.19 32.2154L68.38 76.3454H35.63L17.96 91.8854V91.8953Z" fill="#DC3545"/>
            <path d="M32.5799 51.5855C32.1899 51.9855 31.7099 52.1855 31.1499 52.1855C30.5899 52.1855 30.1199 51.9855 29.7399 51.5855C29.3599 51.1855 29.1699 50.6955 29.1699 50.1155C29.1699 49.5355 29.3599 49.0455 29.7399 48.6655C30.1199 48.2755 30.5899 48.0755 31.1499 48.0755C31.7099 48.0755 32.1899 48.2755 32.5799 48.6655C32.9699 49.0555 33.1599 49.5355 33.1599 50.1155C33.1599 50.6955 32.9599 51.1855 32.5799 51.5855ZM30.0999 46.0955C29.7799 44.4155 29.5799 42.8455 29.4999 41.3855C29.4199 39.9255 29.3799 37.3155 29.3799 33.5555V32.1455H32.9499V33.5855C32.9499 37.3455 32.8999 39.9555 32.8099 41.4155C32.7199 42.8755 32.5099 44.4355 32.1899 46.0955H30.0899H30.0999Z" fill="white"/>
            <path opacity="0.4" d="M75.04 27.215C75.02 27.515 74.77 27.755 74.46 27.755C74.18 27.755 73.95 27.535 73.95 27.255C73.95 27.235 73.95 27.225 73.95 27.205C73.97 26.905 74.22 26.665 74.53 26.665C74.81 26.665 75.04 26.885 75.04 27.165C75.04 27.185 75.04 27.195 75.04 27.215Z" fill="white"/>
            <path d="M62.5699 16.0955C60.3699 18.6155 59.0999 26.3455 60.7299 30.1455C62.3599 33.9455 43.4399 37.7555 59.0499 43.9255C74.6599 50.1055 87.1499 42.9355 82.6299 36.5455C78.1099 30.1455 83.1199 19.4955 77.8899 16.8155C73.1599 14.3755 66.9899 11.0455 62.5799 16.0955H62.5699Z" fill="black"/>
            <path d="M57.8999 47.206C57.0699 50.026 56.1999 52.846 55.2899 55.666C54.3799 58.486 53.4299 61.266 52.4299 64.066L52.0499 65.116L51.8599 65.646L51.7999 65.806L51.6999 66.046C51.6399 66.196 51.5499 66.366 51.4699 66.526C51.0799 67.246 50.4899 67.836 49.7699 68.246C49.1199 68.596 48.3999 68.786 47.6599 68.806C46.6299 68.806 45.5999 68.606 44.6399 68.216C43.8599 67.896 43.0999 67.526 42.3699 67.096C39.7299 65.476 37.3099 63.516 35.1699 61.276L36.9099 59.226C37.4899 59.596 38.1199 59.996 38.7199 60.376C39.3199 60.756 39.9599 61.116 40.5699 61.476C41.7699 62.176 43.0099 62.806 44.2799 63.376C44.8599 63.636 45.4599 63.846 46.0699 64.006C46.4899 64.146 46.9399 64.196 47.3799 64.156C47.4899 64.156 47.4699 64.096 47.3799 64.126C47.2899 64.156 47.1899 64.266 47.1799 64.256C47.1699 64.246 47.1799 64.256 47.1799 64.236V64.216L47.2099 64.116L47.3599 63.596L47.6699 62.546L50.1299 54.086C50.9699 51.266 51.8299 48.446 52.7099 45.626L57.8899 47.226L57.8999 47.206Z" fill="#FFC3BD"/>
            <path d="M36.7899 59.1353L32.1099 57.4053L33.9699 62.3453C33.9699 62.3453 37.2699 62.8253 38.5499 61.0853L37.5099 59.6853C37.3299 59.4353 37.0799 59.2453 36.7899 59.1253V59.1353Z" fill="#FFC3BD"/>
            <path d="M27.98 58.8455L29.76 62.8955L33.98 62.3455L32.12 57.3955L27.98 58.8455Z" fill="#FFC3BD"/>
            <path d="M54.4899 39.9152C52.3499 40.4552 50.8699 49.2652 50.8699 49.2652L56.7699 53.2952C56.7699 53.2952 62.4099 44.4152 60.7799 42.0652C59.0499 39.5952 57.6199 39.1252 54.4999 39.9252L54.4899 39.9152Z" fill="#1ED760"/>
            <path d="M54.4899 39.9152C52.3499 40.4552 50.8699 49.2652 50.8699 49.2652L56.7699 53.2952C56.7699 53.2952 62.4099 44.4152 60.7799 42.0652C59.0499 39.5952 57.6199 39.1252 54.4999 39.9252L54.4899 39.9152Z" fill="#685BC7"/>
            <path d="M54.2899 43.1152L58.8699 44.1752L59.3099 48.6552C58.5299 50.2352 57.6799 51.7752 56.7499 53.2752L53.1699 50.8552C53.3299 48.7852 53.7099 44.5252 54.2999 43.1152H54.2899Z" fill="#250E62"/>
            <path d="M54.4899 39.9155C54.4899 39.9155 52.0099 40.7855 56.9699 71.2455H78.0499C77.6999 62.6655 77.6899 57.3755 81.7899 39.7655C78.8399 39.1555 75.8499 38.7655 72.8399 38.5855C69.2899 38.4155 66.3999 38.2955 63.2699 38.5855C59.1599 38.9555 54.4999 39.9155 54.4999 39.9155H54.4899Z" fill="#1ED760"/>
            <path d="M54.4899 39.9155C54.4899 39.9155 52.0099 40.7855 56.9699 71.2455H78.0499C77.6999 62.6655 77.6899 57.3755 81.7899 39.7655C78.8399 39.1555 75.8499 38.7655 72.8399 38.5855C69.2899 38.4155 66.3999 38.2955 63.2699 38.5855C59.1599 38.9555 54.4999 39.9155 54.4999 39.9155H54.4899Z" fill="#685BC7"/>
            <path d="M76.8199 47.9355L79.5399 50.2555C78.9599 53.3355 78.5699 55.8955 78.3299 58.1855C77.8099 55.8955 76.5099 49.6855 76.8199 47.9355Z" fill="#250E62"/>
            <path d="M71.88 27.8057C71.28 30.8857 70.67 36.5257 72.83 38.5857C72.83 38.5857 70.82 43.1957 65.48 43.1957C60.14 43.1957 63.25 38.5857 63.25 38.5857C66.68 37.7757 66.6 35.2157 66 32.8257L71.87 27.8057H71.88Z" fill="#FFC3BD"/>
            <path d="M72.15 37.435C71.64 38.465 70.01 40.805 65.44 41.645C65.31 41.665 65.18 41.645 65.07 41.575C64.22 40.975 63.68 39.715 64.06 38.255C64.13 37.985 63.98 37.715 63.71 37.645C63.6 37.615 63.49 37.625 63.39 37.665C63.04 37.815 62.7 37.985 62.38 38.195C62.31 38.245 62.25 38.305 62.21 38.385C61.88 39.015 60.52 42.085 64.61 43.985C64.68 44.015 64.74 44.025 64.82 44.035C67.24 44.085 71.46 43.605 74.13 38.805C74.23 38.625 74.21 38.405 74.09 38.245C73.73 37.855 73.31 37.515 72.85 37.255C72.62 37.105 72.32 37.175 72.17 37.405C72.17 37.415 72.15 37.435 72.14 37.455L72.15 37.435Z" fill="#1ED760"/>
            <path d="M73.2699 50.1052L67.8299 49.9452C67.6099 49.9452 67.4299 49.7652 67.4199 49.5452C67.4199 49.5252 67.4199 49.5152 67.4199 49.4952L67.4699 48.8252C67.4999 48.5952 67.6999 48.4152 67.9399 48.4252L73.3799 48.5852C73.5999 48.5852 73.7799 48.7652 73.7899 48.9852C73.7899 49.0052 73.7899 49.0152 73.7899 49.0352L73.7399 49.7052C73.7099 49.9352 73.5099 50.1152 73.2699 50.1052Z" fill="#1ED760"/>
            <path d="M69.49 29.8555L66.01 32.8255C66.16 33.3855 66.26 33.9555 66.31 34.5255C67.62 34.3355 69.42 32.8955 69.56 31.5255C69.64 30.9755 69.61 30.4055 69.49 29.8655V29.8555Z" fill="#ED847E"/>
            <path d="M75.9399 21.9751C74.9699 27.0351 74.6699 29.1951 71.7499 31.4851C67.3699 34.9251 61.3599 32.6251 60.8099 27.3751C60.3099 22.6551 61.9799 15.1751 67.2499 13.7851C70.9999 12.7851 74.8499 15.0251 75.8399 18.7751C76.1199 19.8251 76.1499 20.9251 75.9299 21.9851L75.9399 21.9751Z" fill="#FFC3BD"/>
            <path d="M73.06 18.2355C72.43 21.4055 72.2801 25.6055 75.1901 25.6055C78.1001 25.6055 80.3701 20.6655 77.8601 16.7955C75.3601 12.9255 73.43 16.3655 73.06 18.2355Z" fill="black"/>
            <path d="M78.08 18.6255C78.08 22.0755 73.74 20.3655 71.82 18.8855C69.9 17.4055 69.81 17.5655 66.25 17.5555C62.69 17.5555 61.98 17.3055 60.93 15.6055C59.88 13.9055 59.98 12.7855 62.34 13.1455C64.7 13.5055 62.72 11.0855 64.48 9.7455C66.24 8.4055 68.33 8.7455 70.74 11.8055C73.15 14.8655 78.08 12.6555 78.08 18.6255Z" fill="black"/>
            <path d="M76.7986 26.4832C77.2554 24.925 76.8094 23.4226 75.8025 23.1274C74.7955 22.8322 73.6089 23.856 73.1521 25.4141C72.6953 26.9722 73.1413 28.4747 74.1482 28.7699C75.1552 29.0651 76.3418 28.0413 76.7986 26.4832Z" fill="white"/>
            <path d="M68.7499 30.3753C67.8199 30.3753 66.8899 30.1853 66.0299 29.8153C65.9499 29.7853 65.9199 29.6953 65.9499 29.6253C65.9799 29.5453 66.0699 29.5153 66.1499 29.5453C67.7799 30.2453 69.6299 30.2553 71.2799 29.5753C72.8799 28.9253 74.1399 27.6553 74.7899 26.0553C75.9999 22.5953 77.2299 18.2853 75.7999 15.5853C75.2199 14.5253 74.1999 13.7653 73.0199 13.5153C72.9399 13.4953 72.8899 13.4153 72.9099 13.3253C72.9299 13.2453 73.0099 13.1953 73.0999 13.2153C74.3599 13.4853 75.4499 14.2953 76.0699 15.4253C77.5499 18.2053 76.3199 22.6053 75.0699 26.1353C74.3899 27.8153 73.0599 29.1453 71.3799 29.8353C70.5499 30.1853 69.6499 30.3653 68.7499 30.3753Z" fill="white"/>
            <path d="M76.66 26.495C76.3 27.705 75.48 28.555 74.82 28.365C74.16 28.175 73.92 27.025 74.28 25.805C74.64 24.585 75.46 23.745 76.12 23.935C76.78 24.125 77.02 25.275 76.66 26.495Z" fill="#1ED760"/>
            <path d="M64.2806 29.237L64.2617 29.3352C64.2083 29.6117 64.3892 29.8792 64.6658 29.9326L66.2467 30.2375C66.5232 30.2909 66.7907 30.11 66.844 29.8334L66.863 29.7352C66.9163 29.4586 66.7354 29.1912 66.4588 29.1378L64.878 28.8328C64.6014 28.7795 64.334 28.9604 64.2806 29.237Z" fill="white"/>
            <path d="M64.3901 22.3955C64.3901 22.3955 63.4601 24.0955 62.5701 24.8555C63.0501 25.4355 64.0201 25.3255 64.0201 25.3255L64.3901 22.3955Z" fill="#ED847E"/>
            <path d="M65.28 27.4953H65.32C66.23 27.5653 67.14 27.2953 67.86 26.7253C67.89 26.6953 67.89 26.6453 67.86 26.6153C67.83 26.5853 67.78 26.5853 67.75 26.6153C67.06 27.1553 66.2 27.4153 65.33 27.3553C65.29 27.3553 65.25 27.3753 65.24 27.4153C65.24 27.4153 65.25 27.4853 65.28 27.5053V27.4953Z" fill="black"/>
            <path d="M69.8801 20.4059C69.8801 20.4059 69.9801 20.4359 70.0301 20.4059C70.1001 20.3559 70.1201 20.2659 70.0801 20.1859C69.6901 19.5359 69.0101 19.1059 68.2501 19.0459C68.1601 19.0459 68.0901 19.1159 68.0901 19.2059C68.0901 19.2959 68.1601 19.3559 68.2401 19.3659C68.8901 19.4259 69.4801 19.7959 69.8101 20.3659C69.8301 20.3859 69.8501 20.4059 69.8801 20.4159V20.4059Z" fill="#3D3D3B"/>
            <path d="M67.5799 22.466C67.5099 22.866 67.2299 23.176 66.9599 23.136C66.6899 23.096 66.5299 22.736 66.5999 22.296C66.6699 21.856 66.9499 21.586 67.2199 21.626C67.4899 21.666 67.6499 22.046 67.5799 22.466Z" fill="black"/>
            <path d="M62.88 21.6755C62.81 22.0755 62.53 22.3855 62.26 22.3455C61.99 22.3055 61.83 21.9255 61.9 21.5055C61.97 21.0855 62.25 20.7855 62.52 20.8355C62.79 20.8855 62.95 21.2555 62.88 21.6755Z" fill="black"/>
            <path d="M62.6399 20.875L61.6899 20.415C61.6899 20.415 62.0699 21.285 62.6399 20.875Z" fill="black"/>
            <path d="M67.3299 21.6756L66.3799 21.2256C66.3799 21.2256 66.7599 22.0956 67.3299 21.6756Z" fill="black"/>
            <path d="M61.2899 18.8658C61.2899 18.8658 61.3999 18.8958 61.4499 18.8658C61.9499 18.4458 62.6299 18.2958 63.2699 18.4658C63.3499 18.4958 63.4399 18.4558 63.4699 18.3658C63.4999 18.2758 63.4599 18.1958 63.3699 18.1658C62.6399 17.9558 61.8499 18.1158 61.2599 18.5958C61.1899 18.6458 61.1799 18.7358 61.2199 18.8058C61.2199 18.8058 61.2499 18.8558 61.2799 18.8658H61.2899Z" fill="#3D3D3B"/>
            <path d="M56.1701 76.3751C56.1701 76.3751 56.1101 73.4951 56.9801 71.2451H78.0601C78.0601 71.2451 79.9601 74.3051 80.4501 76.3751H56.1801H56.1701Z" fill="#685BC7"/>
            <path d="M78.3701 70.0159L78.5401 71.8959C78.6201 72.0459 78.4401 72.1959 78.2001 72.1959H56.8601C56.6701 72.1959 56.5101 72.1059 56.5001 71.9859L56.3101 70.0959C56.3101 69.9659 56.4601 69.8559 56.6701 69.8559H78.0101C78.1501 69.8359 78.2901 69.9059 78.3801 70.0159H78.3701Z" fill="#1ED760"/>
            <path d="M78.3701 70.0159L78.5401 71.8959C78.6201 72.0459 78.4401 72.1959 78.2001 72.1959H56.8601C56.6701 72.1959 56.5101 72.1059 56.5001 71.9859L56.3101 70.0959C56.3101 69.9659 56.4601 69.8559 56.6701 69.8559H78.0101C78.1501 69.8359 78.2901 69.9059 78.3801 70.0159H78.3701Z" fill="#FAFAFA"/>
            <path d="M75.4999 72.3955H76.0699C76.1899 72.3955 76.2699 72.3355 76.2599 72.2655L75.9899 69.8156C75.9899 69.7456 75.8799 69.6855 75.7699 69.6855H75.1899C75.0699 69.6855 74.9899 69.7456 74.9999 69.8156L75.2699 72.2655C75.2899 72.3355 75.3899 72.3955 75.4999 72.3955Z" fill="#1ED760"/>
            <path d="M58.47 72.3955H59.04C59.15 72.3955 59.24 72.3355 59.23 72.2655L58.96 69.8156C58.96 69.7456 58.86 69.6855 58.74 69.6855H58.17C58.06 69.6855 57.97 69.7456 57.98 69.8156L58.25 72.2655C58.25 72.3355 58.36 72.3955 58.47 72.3955Z" fill="#1ED760"/>
            <path d="M66.98 72.3955H67.54C67.65 72.3955 67.74 72.3355 67.73 72.2655L67.46 69.8156C67.46 69.7456 67.35 69.6855 67.24 69.6855H66.67C66.56 69.6855 66.47 69.7456 66.48 69.8156L66.75 72.2655C66.77 72.3355 66.87 72.3955 66.99 72.3955H66.98Z" fill="#1ED760"/>
            <path d="M83.5899 45.7356C84.5199 48.7456 85.4099 51.7656 86.2599 54.8056L87.5399 59.3356L88.1799 61.5856L88.3399 62.1456L88.4199 62.4256L88.4399 62.4856L88.3799 62.3856C88.2799 62.2356 88.1499 62.1156 88.0099 62.0056C87.6899 61.7756 87.3199 61.6456 86.9299 61.6256C86.6399 61.6156 86.3399 61.6656 86.0799 61.7956C85.7899 61.9556 85.8199 61.9856 85.8399 61.9656C85.9099 61.9056 85.9699 61.8356 86.0299 61.7656C86.2199 61.5256 86.3899 61.2756 86.5499 61.0256C86.8999 60.4456 87.2499 59.7956 87.5799 59.1256C88.2599 57.7856 88.8799 56.3756 89.4999 54.9456C90.1199 53.5156 90.7099 52.0756 91.3099 50.6256C91.9099 49.1756 92.4899 47.7056 93.0899 46.2656L95.6599 47.0256C94.9899 50.1656 94.2099 53.2456 93.2399 56.3256C92.7599 57.8656 92.2399 59.3956 91.6299 60.9356C91.3199 61.7056 90.9799 62.4856 90.5699 63.2856C90.3499 63.7156 90.0999 64.1356 89.8299 64.5456C89.6599 64.7956 89.4699 65.0356 89.2699 65.2656C88.9399 65.6356 88.5499 65.9356 88.0999 66.1556C87.6599 66.3756 87.1699 66.4756 86.6699 66.4556C86.0899 66.4256 85.5299 66.2156 85.0599 65.8656C84.7599 65.6456 84.5099 65.3656 84.2999 65.0556C84.1699 64.8556 84.0499 64.6456 83.9599 64.4356C83.9199 64.3456 83.8799 64.2556 83.8599 64.1756L83.7799 63.9756L83.6799 63.6856L83.4699 63.1156C83.1999 62.3656 82.9199 61.6056 82.6599 60.8556C82.1399 59.3456 81.6299 57.8456 81.1499 56.3356C80.1599 53.3156 79.2399 50.2856 78.3899 47.2356L83.5899 45.7256V45.7356Z" fill="#FFC3BD"/>
            <path d="M81.7799 39.7655C83.8899 40.4055 87.1299 48.6955 87.1299 48.6955L78.9399 54.5555C78.9399 54.5555 75.2599 47.4655 76.1399 44.7355C77.0699 41.8955 79.3799 39.0255 81.7799 39.7655Z" fill="#1ED760"/>
            <path d="M81.7799 39.7655C83.8899 40.4055 87.1299 48.6955 87.1299 48.6955L78.9399 54.5555C78.9399 54.5555 75.2599 47.4655 76.1399 44.7355C77.0699 41.8955 79.3799 39.0255 81.7799 39.7655Z" fill="#685BC7"/>
            <path d="M93.1901 47.365L92.0701 42.415L96.7401 44.875C96.7401 44.875 96.8001 48.205 94.9101 49.255L93.1901 47.355V47.365Z" fill="#FFC3BD"/>
            <path d="M94.0201 38.4854L97.0001 40.5954L96.7301 44.8754L92.0601 42.4154L94.0201 38.4854Z" fill="#FFC3BD"/>
        </svg>
        
    );
  }

export default ChatAlertIconSvg

