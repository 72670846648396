import ActionTypes from "../../ActionTypes";
import API from "../../services";

export const callSubmitTraigeApi = (
  SubmitTriageParameters,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.SUBMIT_TRIAGE_QUESTIONS](SubmitTriageParameters)
    .then((data) => {
      const cancelReason =
        SubmitTriageParameters?.SubmitTriageParameters?.Triage[0].Que;
      onApiSuccess(cancelReason);
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.SUBMIT_TRIAGE_QUESTIONS, error);
    });
};

export const callCancelClaimApi = (
  ServiceRequestId,
  CancelServiceRequestParams,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.CANCEL_SERVICE_REQUEST](
    ServiceRequestId,
    CancelServiceRequestParams
  )
    .then((data) => {
      onApiSuccess(ActionTypes.CANCEL_SERVICE_REQUEST, data);
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.CANCEL_SERVICE_REQUEST, error);
    });
};

export const callUpdateRepairRequestApi = (
  UpdateRepairRequestParameter,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.UPDATE_REPAIR_REQUEST](UpdateRepairRequestParameter)
    .then((data) => {
      onApiSuccess(ActionTypes.UPDATE_REPAIR_REQUEST, data.data);
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.UPDATE_REPAIR_REQUEST, error);
    });
};

export const callProcessPaymentApi = (
  ProcessPaymentParameters,
  ServiceOrderId,
  ChargeOrderId,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.PROCESS_PAYMENT](
    ProcessPaymentParameters,
    ServiceOrderId,
    ChargeOrderId
  )
    .then((data) => {
      onApiSuccess(ActionTypes.PROCESS_PAYMENT, data.data);
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.PROCESS_PAYMENT, error);
    });
};

export const callSubmitOrderApi = (
  SubmitServiceOrderParameters,
  ServiceOrderId,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.SUBMIT_ORDER](SubmitServiceOrderParameters, ServiceOrderId)
    .then((data) => {
      onApiSuccess(
        ActionTypes.SUBMIT_ORDER,
        data.data.SubmitServiceOrderResults
      );
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.SUBMIT_ORDER, error);
    });
};

export const callProcessOveragePaymentApi = (
  ProcessOveragePaymentParameters,
  onApiSuccess,
  onApiFailure
) => {
  API[ActionTypes.PROCESS_OVERAGE_PAYMENT](ProcessOveragePaymentParameters)
    .then((data) => {
      onApiSuccess(
        ActionTypes.PROCESS_OVERAGE_PAYMENT,
        data.data
      );
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.PROCESS_OVERAGE_PAYMENT, error);
    });
};

export const callGetShippingMethodsApi = (
  LookupShippingMethodsParameters,
  onApiSuccess,
  onApiFailure
) => {

  API[ActionTypes.GET_SHIPPING_METHODS](LookupShippingMethodsParameters)
  .then((data) => {
    onApiSuccess(
      ActionTypes.GET_SHIPPING_METHODS,
      data.data
    );

  })
  .catch((error) => {
    console.log(`error is ${error}`);
    onApiFailure(ActionTypes.GET_SHIPPING_METHODS, error);
  });
};

export const callUpdateShippingApi = (
  UpdateShippingOrderParameters,
  ServiceOrderId,
  onApiSuccess,
  onApiFailure
) => {

  API[ActionTypes.UPDATE_SHIPPING_ORDER](
    UpdateShippingOrderParameters,
    ServiceOrderId
  )
    .then((data) => {
      onApiSuccess(
        ActionTypes.UPDATE_SHIPPING_ORDER,
        data.data
      );
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.UPDATE_SHIPPING_ORDER, error);
    });

}

export const callCreateInteractionApi = (
  CreateInteraction,
  onApiSuccess,
  onApiFailure
) => {

  API[ActionTypes.CREATE_INTERACTION](
    CreateInteraction,
  )
    .then((data) => {
      onApiSuccess(
        ActionTypes.CREATE_INTERACTION,
        data.data
      );
    })
    .catch((error) => {
      console.log(`error is ${error}`);
      onApiFailure(ActionTypes.CREATE_INTERACTION, error);
    });

}

export const logGTMEvent = (event) => {
  window.dataLayer.push(event);
}
