import React from 'react';
import PropTypes from 'prop-types';

export const Image = ({image, ...props}) => {
  let ChildImage = image;
  return <ChildImage {...props}/>
}

Image.propTypes = {
    /**
     * Is this the principal call to action on the page?
     */
    image: PropTypes.func,
  };
  
  Image.defaultProps = {
    image: undefined
  };
