import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-mobile-datepicker";
import "./calender.css";
import CalenderIcon from "../../icons/ic_calendar";
import { Tooltip } from "../tooltip/tooltip";
import { getFormmatedDate } from "../enrollment-workflow/helper";
import DesktopDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { data } from "autoprefixer";
export const Calender = ({
  enabled,
  label,
  placeHolder,
  value,
  type,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  displayClasses,
  onChange,
  onKeyUp,
  onClick,
  maxlength,
  tabIndex,
  showTooltip,
  other,
  hidePastDate,
  hideFutureDate,
  iconColor,
  subDiv,
  inputDivClasses,
  pastDaysToAllow,
  ...props
}) => {
  const [time, setTime] = useState();
  const [formatDate, setFormatDate] = useState();
  const [isOpen, setIsOpen] = useState();
  const [lockPastDate, setLockPastDate] = useState(hidePastDate);
  const [lockFutureDate, setLockFutureDate] = useState(hideFutureDate);
  const [startDate, setStartDate] = useState(new Date());
  let currDate = new Date();
  currDate.setDate(currDate.getDate() - pastDaysToAllow);

  const formattedDate = currDate.toISOString().split("T")[0];

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCancel = (time) => {
    setIsOpen(false);
  };

  const handleSelect = (time) => {
    const data = getFormmatedDate(time, "dd M yyyy");

    setTime(time);
    onChange(data);
    setFormatDate(data);
    setIsOpen(false);
  };
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 640px)").matches
  );
const handleDate=(data)=>{
  const val = getFormmatedDate(data, "dd M yyyy");
  onChange(val);
  setFormatDate(val)
}
  useEffect(() => {
    window
      .matchMedia("(min-width: 640px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const monthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const dateConfig = {
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: "Mon",
      step: 1,
    },
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div
        className={`${inputDivClasses} ${fontClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses} ${other}`}
      >
        <div className={subDiv}>
          {showTooltip ? (
            <Tooltip showLabel={true} labelText={label} />
          ) : (
            <label>{label}</label>
          )}
        </div>
        <div
          onClick={props.onClick}
          id="inputDiv"
          className="flex p-2 mt-1 border rounded-md lg:w-1/2 border-neutralN8 focus-within:outline-none focus-within:border-primary focus:shadow-sm"
        >
         <input
              type={"text"}
              className={"border-0 outline-none ml-3 w-full pointer-events-none"}
              value={formatDate}
              onClick={(e) => onClick(e.preventDefault())}
            />
          <i onClick={props.onClick}>
            <CalenderIcon />
          </i>
        </div>
      </div>
    );
  });

  return (
    <>
      {!matches && (
        <div
          className={`flex flex-col ${fontClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses} ${other}`}
        >
          {showTooltip ? (
            <Tooltip showLabel={true} labelText={label} />
          ) : (
            <label>{label}</label>
          )}
          <div
            onClick={handleClick}
            id="inputDiv"
            className="flex p-2 mt-1 border rounded-md lg:w-1/2 lg:p-4 border-neutralN8 focus-within:outline-none focus-within:border-primary focus:shadow-sm"
          >
            <input
              type={"text"}
              className={"border-0 outline-none ml-3 w-full pointer-events-none"}
              value={formatDate}
              onClick={(e) => onClick(e.preventDefault())}
            />
            <i className="">
              <CalenderIcon  />
            </i>
          </div>
          <DatePicker
            value={time}
            isOpen={isOpen}
            onSelect={handleSelect}
            onCancel={handleCancel}
            theme={"android"}
            confirmText={"Next"}
            cancelText={"Cancel"}
            dateConfig={dateConfig}
            headerFormat={"Select date"}
            min={lockPastDate ? new Date(formattedDate) : undefined}
            max={lockFutureDate ? new Date() : undefined}
          />
        </div>
      )}

      {matches && (
        <DesktopDatePicker
          showPopperArrow={false}
          selected={startDate}
          onChange={handleDate}
          customInput={<CustomInput />}
          disabledKeyboardNavigation
          minDate={lockPastDate ? new Date(formattedDate) : undefined}
          maxDate={lockFutureDate ? new Date() : undefined}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
    </>
  );
};

Calender.propTypes = {
  enabled: PropTypes.bool,

  label: PropTypes.string,

  placeHolder: PropTypes.string,

  value: PropTypes.string,

  type: PropTypes.string,

  fontClasses: PropTypes.string,

  dimensionClasses: PropTypes.string,

  colorClasses: PropTypes.string,

  borderClasses: PropTypes.string,

  paddingClasses: PropTypes.string,

  marginClasses: PropTypes.string,

  displayClasses: PropTypes.string,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,

  maxlength: PropTypes.string,

  tabIndex: PropTypes.string,

  onClick: PropTypes.func,

  onKeyUp: PropTypes.func,

  showTooltip: PropTypes.bool,
  hidePastDate: PropTypes.bool,
  hideFutureDate: PropTypes.bool,

  other: PropTypes.string,
};

Calender.defaultProps = {
  enabled: true,
  label: "label",
  value: "",
  type: "date",
  fontClasses: "text-base",
  dimensionClasses: "",
  colorClasses: "",
  borderClasses:
    "border border-neutralN8 focus-within:outline-none rounded-md focus-within:border-primary focus-within:shadow-sm",
  paddingClasses: "",
  marginClasses: "mb-5",
  displayClasses: "",
  onChange: undefined,
  onClick: undefined,
  onKeyUp: undefined,
  maxlength: "",
  tabIndex: "",
  showTooltip: false,
  hidePastDate: false,
  hideFutureDate: true,
  other: "",
  iconColor:"#1ED760",
  inputDivClasses: "flex flex-col",
  pastDaysToAllow: 0
};
