import React from 'react'

const icon = ({ onClick, isSelected, className,fill }) => (
 <svg onClick={onClick} className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 0.585937L0.292893 6.29304L1.70711 7.70726L6 3.41436L10.2929 7.70726L11.7071 6.29304L6 0.585937Z" fill={fill}/>
  </svg>
)
icon.defaultProps={
  fill:"black"
}
export default icon