import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

function ProvideContactNumber(props) {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const { setContactPopup, saveAlternateNumber } = props;

  const metadata = workflowState['config']?.metadata['ProvideContactDetails']
  const homeplusscript = workflowState['config']?.scripts["ProvideContactDetails"];

  const [isExpanded, setIsExpanded] = useState(true);
  const [mobileNumber, setMobileNumber] = useState({});
  const [showMDNLengthError, setShowMDNLengthError] = useState(false);
  const checkContactLength = () => {
    if (!isEmpty(mobileNumber)) {
      let checkOutput = mobileNumber.value.length === 10 ? true : false;
      return checkOutput;
    }
    return false;
  };

  let textHeader = findById(metadata, "titleHeader");
  let inputMobileNumber = findById(metadata, "inputMobileNumber");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");

  const open = (isExpanded) => {
    setContactPopup(false);
    setIsExpanded(!isExpanded);
  };

  const onMobileNumberChange = (value, country, e, formattedValue) => {
    setMobileNumber({
      value: value,
      country: country,
      formattedValue: formattedValue,
    });
    if (value.length < 10) {
      setShowMDNLengthError(true);
    } else {
      setShowMDNLengthError(false);
    }
  };

  textHeader.args = { ...textHeader.args, content: homeplusscript.titleHeader };

  inputMobileNumber.args = {
    ...inputMobileNumber.args,
    label: homeplusscript.inputMobile_label,
    placeHolder: homeplusscript.inputMobile_placeHolder,
    value: mobileNumber.value,
    onChange: onMobileNumberChange,
  };

  const onContinueClick = () => {
    saveAlternateNumber(mobileNumber)
    open(isExpanded);
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    enabled: true,
    onClick: () => open(isExpanded),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: mobileNumber ? checkContactLength() : false,
    onClick: (e) => onContinueClick(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvideContactNumber;
