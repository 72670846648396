import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceTechnology() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;

  const metadata = workflowState['config']?.metadata['SelectDisplayTechnology']
  const homeplusscript = workflowState['config']?.scripts[isPrinter ? "SelectPrinterTechnology" : "SelectDisplayTechnology"];

  const [otherTech, setOtherTech] = useState("");
  const [displayTechnologyList, setDisplayTechnologyList] = useState([]);
  const [selectedTech, setSelectedTech] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let displayTechnologies = findById(metadata, "displayTechnologies");
  let inputOtherTech = findById(metadata, "inputOtherTech");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDisplayTechInfo = findById(metadata, "txtDisplayTechInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  const onContinueClick = (tech) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedTech: tech?.value || otherTech?.trim(),
        isOtherTechSelected: isEmpty(tech),
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectScreenSize: !isPrinter,
      selectDeviceFunction: isPrinter,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDisplayTechnologies(
        isPrinter
          ? regDeviceDetails.selectedBrandDetails.Printing_Techonolgy
          : regDeviceDetails.selectedBrandDetails.Display_tech
      );
    // now get distinct list of all brand
    else
      showDisplayTechnologies(
        isPrinter
          ? regDeviceDetails?.distinctPrinterTechList
          : regDeviceDetails?.distinctTechList
      );
  }, []);

  const showDisplayTechnologies = (displayTech = []) => {
    let technologyList = [];
    displayTech.forEach((tech) => {
      if (!isEmpty(tech)) {
        let deviceBrandTech = {
          value: tech,
          label: tech,
        };
        technologyList.push(deviceBrandTech);
      }
    });

    // add others at last
    technologyList.push({ value: "Others", label: "Others" });

    setDisplayTechnologyList(technologyList);
  };

  const onTechnologySelect = (tech) => {
    hideShowInputOtherTech(tech);
    setSelectedTech({ ...selectedTech, ...tech });

    if (tech.value !== "Others") onContinueClick(tech);
    else setDisplayProp("block")
  };

  const hideShowInputOtherTech = (tech) => {
    // clear input value
    setOtherTech("");
    // inputOtherTech.args = {
    //   ...inputOtherTech.args,
    //   other: tech.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: tech.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: tech.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  displayTechnologies.args = {
    ...displayTechnologies.args,
    deviceItems: displayTechnologyList,
    value: selectedTech?.value,
    onClick: onTechnologySelect,
  };

  inputOtherTech.args = {
    ...inputOtherTech.args,
    label: homeplusscript["labelOtherTech"],
    placeHolder: homeplusscript["labelOtherTech"],
    value: otherTech,
    other:displayProp,
    onChange: setOtherTech,
  };

  txtDisplayTechInfo.args = {
    ...txtDisplayTechInfo.args,
    content: homeplusscript.otherTechInfo.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherTech?.trim(),
    displayClasses: selectedTech?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceTechnology;
