import { Box, Button, makeStyles, Slider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Like from "./assets/Like";
import { Colors } from "./entities/Colors";
import { range } from "lodash";

const NPSTexts = {
  feedbackHeader: "Your feedback is important to us",
};

export const NPSWidget = ({
  surveyQuestionData,
  surveyQuestion,
  onSubmitHandler,
}) => {
  const classes = NPSStyles();
  const [rating, setRating] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleNPS = (value) => {
    switch (true) {
      case value < 7:
        return "Unlikely";
      case value >= 7 && value <= 8:
        return "Likely";
      case value >= 9 && value <= 10:
        return "Extremely Likely";
      default:
    }
  };

  return (
    <Box className={classes.root}>
      <Like />
      <Typography align="center">
        <Box component="span" fontWeight={700}>
          {NPSTexts.feedbackHeader}
        </Box>
      </Typography>

      <Typography align="center">{surveyQuestion}</Typography>
      <Typography
        align="center"
        style={{ color: Colors.PRIMARY_CONTRAST_TEXT, fontSize: "18px" }}
      >
        <Box component="span" fontWeight={500}>
          {handleNPS(rating)}
        </Box>
      </Typography>
      <Slider
        id="testing"
        value={rating}
        step={1}
        marks={range(0, 11).map((value) => ({ value, label: value }))}
        min={0}
        max={10}
        onChange={(e, value) => {
          setRating(value);
          setIsDisabled(false);
        }}
        className={classes.slider}
      />
      <Button
        variant="contained"
        color={"primary"}
        disabled={isDisabled}
        onClick={() => onSubmitHandler(rating)}
      >
        Submit
      </Button>
    </Box>
  );
};

const NPSStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95vw",
    width: "95%",
    margin: "0 auto",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    padding: "15px 10px",
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
    border: `1px solid ${Colors.DARK_GRAY}`,
    "& p": {
      lineHeight: "22px",
    },
    "& button": {
      borderRadius: "4px",
      minWidth: "88px",
      height: "44px",
      fontWeight: 500,
    },
  },
  slider: {
    marginTop: "30px",
    width: "92%",

    "& .MuiSlider-markLabelActive:last-of-type": {
      //     '&:[data-index="1"]': {
      //         color: 'blue'
      //     },
      color: "red",
    },

    "& .MuiSlider-markLabel": {
      top: "-20px",
      left: "2px",
      fontSize: "16px",

      [theme.breakpoints.down("xs")]: {
        top: "-10px",
      },
    },
    "& .MuiSlider-mark": {
      visibility: "hidden",
    },
    "& .MuiSlider-rail": {
      height: "8px",
      borderRadius: "40px",
      // margin: '0 20px'
    },
    "& .MuiSlider-track": {
      height: "8px",
      borderRadius: "40px",
    },
    "& .MuiSlider-thumb": {
      width: "20px",
      height: "20px",
      marginTop: "-6px",
    },
  },
}));
