import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function ProvidePurchaseDate() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvidePurchaseDate']
  const homeplusscript = workflowState['config']?.scripts["ProvidePurchaseDate"];

  const [purchasedDate, setPurchasedDate] = useState("");

  let inputPurchasedDate = findById(metadata, "inputPurchasedDate");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let txtPurchasedDateInfo = findById(metadata, "txtPurchasedDateInfo");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        purchasedDate,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      providePurchasePrice: true,
    });
  };

  const onGoBackClick = () => {
    const hasReceipt = regDeviceDetails?.hasReceipt;
    return completeCurrentStep(currentStep, {
      GoBack: hasReceipt,
      GoBackAskReceipt: !hasReceipt,
    });
  };

  inputPurchasedDate.args = {
    ...inputPurchasedDate.args,
    label: homeplusscript.labelPurchasedDate,
    placeHolder: homeplusscript.labelPurchasedDate,
    value: purchasedDate,
    onChange: setPurchasedDate,
  };

  txtPurchasedDateInfo.args = {
    ...txtPurchasedDateInfo.args,
    content : homeplusscript.labelPurchaseDateInfo,
  }

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: purchasedDate ? true : false,
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"]
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvidePurchaseDate;
