import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

function SelectDeviceProcessor() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceProcessor']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceProcessor"];

  const [otherProcessor, setOtherProcessor] = useState("");
  const [deviceProcessorList, setDeviceProcessorList] = useState([]);
  const [selectedProcessor, setSelectedProcessor] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let deviceProcessors = findById(metadata, "deviceProcessors");
  let inputOtherProcessor = findById(metadata, "inputOtherProcessor");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDeviceProcessorInfo = findById(metadata, "txtDeviceProcessorInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;

  const getInfoTitle = () => {
    if (isDesktop) return homeplusscript.processorDekstopInfo;
    else return homeplusscript.processorLaptopInfo;}

  const onContinueClick = (processor) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedProcessor: processor?.value || otherProcessor?.trim(),
        isOtherProcessorSelected: isEmpty(processor)
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectRAM: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: !isDesktop,
      GoBackModelNumber: isDesktop
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceProcessors(
        regDeviceDetails.selectedBrandDetails.Processor
      );
    // now get distinct list of all brand
    else showDeviceProcessors(regDeviceDetails?.distinctProcessorList);
  }, []);

  const showDeviceProcessors = (deviceProcessors = []) => {
    let processorList = [];
    deviceProcessors.forEach((processor) => {
      if (!isEmpty(processor)) {
        let deviceProcessor = {
          value: processor,
          label: processor,
        };
        processorList.push(deviceProcessor);
      }
    });

    // add others at last
    processorList.push({ value: "Others", label: "Others" });

    setDeviceProcessorList(processorList);
  };

  const onProcessorSelect = (processor) => {
    hideShowInputOtherProcessor(processor);
    setSelectedProcessor({ ...selectedProcessor, ...processor });

    if (processor.value !== "Others") onContinueClick(processor);
    else setDisplayProp("block")
  };

  const hideShowInputOtherProcessor = (processor) => {
    // clear input value
    setOtherProcessor("");
    // inputOtherProcessor.args = {
    //   ...inputOtherProcessor.args,
    //   other: processor.value === "Others" ? "block" : "hidden",
    // };

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: processor.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: processor.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  deviceProcessors.args = {
    ...deviceProcessors.args,
    deviceItems: deviceProcessorList,
    value: selectedProcessor?.value,
    onClick: onProcessorSelect,
  };

  inputOtherProcessor.args = {
    ...inputOtherProcessor.args,
    label: homeplusscript.labelOtherProcessor,
    placeHolder: homeplusscript.placeholderOtherProcessor,
    value: otherProcessor,
    other: displayProp,
    onChange: setOtherProcessor,
  };

  txtDeviceProcessorInfo.args = {
    ...txtDeviceProcessorInfo.args,
    content: getInfoTitle()
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherProcessor?.trim(),
    displayClasses: selectedProcessor?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectDeviceProcessor;
