import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

const ProvideIssuePlace = () => {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideIssuePlace']
  const homeplusscript = workflowState['config']?.scripts["ProvideIssuePlace"];

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let issuePlaces = findById(metadata, "issuePlaces");
  let inputAddress1 = findById(metadata, "inputAddress1");
  let inputAddress2 = findById(metadata, "inputAddress2");
  let inputPostalCode = findById(metadata, "inputPostalCode");
  let menuLabel = findById(metadata, "menu");
  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtIssuePlaceInfo = findById(metadata, "txtIssuePlaceInfo");
  let inputOtherAddressContainer = findById(
    metadata,
    "inputOtherAddressContainer"
  );
  let buttonsContainer = findById(metadata, "buttonsContainer");

  const issuePlaceList = [
    {
      value: homeplusscript.labelRegisteredAddress,
      label: homeplusscript.labelRegisteredAddress,
    },
    { value: homeplusscript.labelOthers, label: homeplusscript.labelOthers },
  ];

  const [selectedPlace, setSelectedPlaces] = useState({});

  let fileClaimDetails = workflowState["FileClaimDetails"];
  let FindAgreementResponse = workflowState["FindAgreementResponse"];
  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];

  const onContinueClick = (Place) => {
    const isOtherPlaceSelected = isEmpty(Place);
    let otherAddress = {};
    // now set other address
    if (isOtherPlaceSelected) {
      otherAddress = {
        ...agreement?.Address,
        Address1: addressLine1?.trim(),
        Address2: addressLine2?.trim(),
        Address3: "",
        AddressId: "",
        PostalCode: postalCode,
        LocationType: "Other",
      };
    }

    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        selectedIssueAddress: isOtherPlaceSelected
          ? otherAddress
          : agreement?.Address,
        isOtherPlaceSelected,
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      provideIssueObservedBy: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const onPlaceSelect = (Place) => {
    setSelectedPlaces({
      ...selectedPlace,
      ...Place,
    });

    inputOtherAddressContainer.args = {
      ...inputOtherAddressContainer.args,
      other: Place.value === homeplusscript.labelOthers ? "block" : "hidden",
    };

    if (Place.value !== homeplusscript.labelOthers) onContinueClick(Place);
  };
  
   const handlePostalCode=(code)=>{
     if(code.length <= 6) setPostalCode(code)
   }

  inputAddress1.args = {
    ...inputAddress1.args,
    label: homeplusscript.labelAddress1,
    placeHolder: homeplusscript.labelAddress1,
    value: addressLine1,
    onChange: setAddressLine1,
  };

  inputAddress2.args = {
    ...inputAddress2.args,
    label: homeplusscript.labelAddress2,
    placeHolder: homeplusscript.labelAddress2,
    value: addressLine2,
    onChange: setAddressLine2,
  };

  inputPostalCode.args = {
    ...inputPostalCode.args,
    label: homeplusscript.labelPostalCode,
    placeHolder: homeplusscript.labelPostalCode,
    value: postalCode,
    onChange: handlePostalCode,
  };

  menuLabel.args = {
    ...menuLabel.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  issuePlaces.args = {
    ...issuePlaces.args,
    deviceItems: issuePlaceList,
    value: selectedPlace?.value,
    onClick: onPlaceSelect,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: addressLine1?.trim() && addressLine2?.trim() && postalCode,
    displayClasses:
      selectedPlace?.value === homeplusscript.labelOthers ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtIssuePlaceInfo.args = {
    ...txtIssuePlaceInfo.args,
    content: homeplusscript.labelIssuePlaceInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }
  inputOtherAddressContainer.args = {
    ...inputOtherAddressContainer.args,
  };

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedPlace?.value === homeplusscript.labelOthers ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default ProvideIssuePlace;
