/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick ,fill }) => {
 return ( <svg
    onClick={onClick}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={`${fill}`}
    />
    <path
      d="M9.95004 11.54V13H6.04004V11.54H7.00004V8.15H6.04004V6.67H9.01004V11.54H9.95004ZM9.14004 4.11C9.14004 4.43 9.03004 4.7 8.81004 4.91C8.59004 5.12 8.33004 5.23 8.03004 5.23C7.73004 5.23 7.46004 5.12 7.24004 4.9C7.02004 4.68 6.91004 4.42 6.91004 4.11C6.91004 3.8 7.02004 3.55 7.24004 3.33C7.46004 3.11 7.72004 3 8.03004 3C8.34004 3 8.59004 3.11 8.81004 3.33C9.03004 3.55 9.14004 3.81 9.14004 4.11Z"
      fill="white"
    />
  </svg>)


};
icon.defaultProps={
  fill:"#03F182"
}

export default icon;
