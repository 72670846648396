import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

export const Otp = ({
  otpLength = 6,
  label,
  onChange = (e) => {},
  enabled,
  isAlphaNumeric,
  marginClasses,
  fontClasses,
  other,
  dimensionClasses,
  paddingClasses,
  inputDivClasses,
  displayLabelClasses,
  id
}) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(new Array(otpLength).fill(""));
  const inputsRef = useRef([...Array(6)].map(() => React.createRef()));

  const re = isAlphaNumeric ? /^[a-zA-Z0-9]+$/ : /^[0-9\b]+$/;

  const setValue = (value, index) => {
    if (value === "" || re.test(value)) {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[index] = value || "";
      setOTPValues(updatedOTPValues);
      onChange(updatedOTPValues);
    }
  };

  const onFocus = (e) => {
    setActiveInput(e.target.tabIndex - 1);
    if (otpValues[e.target.tabIndex - 1]) {
      e.target.select();
    }
  };

  const onKeyUp = (e) => {
    if (
      e.key !== "Tab" &&
      otpValues[activeInput] &&
      otpValues[activeInput] === e.target.value &&
      re.test(e.key)
    ) {
      // inputsRef?.current[activeInput + 1]?.current?.focus();
      setFocus(activeInput + 1);
    } else if (e.keyCode === 8 && activeInput !== 0) {
      setFocus(activeInput - 1);
    }
  };

  const setFocus = (index) => {
    // document.getElementById(`input_${index}`).focus();
    // document.getElementById(`input_${index}`).click();
    inputsRef?.current[index]?.current?.focus();
    inputsRef?.current[index]?.current?.click();
  };

  return (
    <div id={id} className={`flex flex-col mb-4 mt-4 ${marginClasses} ${other} ${paddingClasses} ${dimensionClasses}`}>
      <label className={`text-center ${fontClasses} ${displayLabelClasses}`}>{label}</label>

      <div className={`flex justify-center mt-4 ${inputDivClasses}`}>
        {otpValues.map((v, i) => {
          return (
            <div key={i}>
              <input
                type={"text"}
                inputMode={isAlphaNumeric ? "text" : "numeric"}
                id={`input_${i}`}
                ref={inputsRef.current[i]}
                className="w-12 h-12 border border-neutralN8 mr-2 text-center rounded focus:border-primary"
                value={v}
                readOnly={!enabled}
                maxLength="1"
                tabIndex={i + 1}
                onChange={(e) => setValue(e?.target?.value, i)}
                onFocus={onFocus}
                onKeyUp={onKeyUp}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

Otp.propTypes = {
  onChange: PropTypes.func,
  otpLength: PropTypes.number,
  enabled: PropTypes.bool,
};

Otp.defaultProps = {
  onChange: () => {},
  otpLength: 6,
  enabled: true,
  isAlphaNumeric: false,
  displayLabelClasses:"block"
};
