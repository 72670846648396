import React from "react";
import PropTypes from "prop-types";
import { Text } from "../text/text";
import PDF from "../preview-pdf/preview-pdf";

const Table = ({
  id,
  title = "",
  tableDivClasses,
  tableTitleClasses,
  tableClasses,
  trClasses,
  thClasses,
  tdClasses,
  tableHeaders,
  tableData,
  previewFile,
  previewImgClasses,
  onRowDataClick,
  tableDisplayClasses,
  tableTextClasses,
  isPdf,
  pdfClasses,
  seperatorClasses,
  dataType,
  ...restProps
}) => {
  let onTextClickEvent = (key, data) => {
    if (onRowDataClick) {
      onRowDataClick(key, data);
    }
  };
  return (
    <div className={`${tableDivClasses} ${tableDisplayClasses}`}>
      {title && <div className={tableTitleClasses}>{title}</div>}
      <table className={tableClasses} {...restProps}>
        <tbody>
          {tableHeaders.map((header) => {
            if (header?.hideForNoAction) {
              return;
            }
            return (
              <>
             {dataType === "claimHistory" && <hr className={seperatorClasses}/>}
              <tr className={`flex flex-row`} key={header.key}>
                <th className={`${thClasses}`}>
                  <Text other={tableTextClasses} content={header.text}></Text>
                </th>
                {tableData.map((data, i) => (
                  <td className={tdClasses}>
                    {header.onRowDataClick ? (
                      <Text
                        other={`${tableTextClasses}`}
                        content={data[header.key]}
                        onClick={() =>
                          onTextClickEvent(header.key, data[header.key])
                        }
                      ></Text>
                    ) : (
                      <Text
                        other={`${tableTextClasses} ${header.statusTextColorClasses}`}
                        content={data[header.key]}
                      ></Text>
                    )}
                  </td>
                ))}
              </tr>
              {dataType === "myClaimForRepair" && <hr className={seperatorClasses}/>}
              </>
            );
          })}
        </tbody>
      </table>
      {previewFile &&
        (isPdf ? (
          <PDF pdfClasses={pdfClasses} base64String={previewFile} />
        ) : (
          <div className={previewImgClasses}>
            <img
              src={URL.createObjectURL(previewFile)}
              alt="receipt"
            />
          </div>
        ))}
    </div>
  );
};

Table.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  tableDisplayClasses: PropTypes.string,
  tableDivClasses: PropTypes.string,
  tableTitleClasses: PropTypes.string,
  tableClasses: PropTypes.string,
  trClasses: PropTypes.string,
  thClasses: PropTypes.string,
  tdClasses: PropTypes.string,
  previewImgClasses: PropTypes.string,
};

Table.defaultProps = {
  tableDisplayClasses: "block",
  tableDivClasses: "my-4 overflow-auto w-full",
  tableTitleClasses: "text-default text-primary w-full",
  tableClasses: "text-xl w-full",
  trClasses: "",
  thClasses: "text-xl py-3 text-left font-Lato",
  tdClasses: "text-xl py-3 text-left font-LatoBold",
  seperatorClasses: "border-neutralN8 w-full",
  tableHeaders: [
    {
      key: "srNo",
      text: "srNo",
      hideForNoAction: false,
    },
    {
      key: "device",
      text: "device",
      hideForNoAction: false,
    },
    {
      key: "requestType",
      text: "requestType",
      hideForNoAction: false,
    },
    {
      key: "pickupAddress",
      text: "pickupAddress",
      hideForNoAction: false,
    },
    {
      key: "srFee",
      text: "srFee",
      hideForNoAction: false,
    },
  ],
  tableData: [
    {
      srNo: "23542365474",
      pickupAddress: "ksjdgnkds eljfkdsjlkf jdfijds",
      srFee: `200$`,
      device: "Apple",
      requestType: "hkejr jeroiewjt",
    },
  ],
  previewImgClasses: "w-full h-full rounded",
  tableTextClasses: "text-sm",
  isPdf: false,
};

export default Table;
