export const Colors = {
    PRIMARY: '#0F6B30',
    SECONDARY: '#03F182',
    SECONDARY_DARK_GREEN: '#09411D',
    PRIMARY_CONTRAST_TEXT: '#1ED760',
    PRIMARY_LIGHT: '#E8FBEF',
    BLACK: '#000000',
    BLACK_NEUTRAL: '#3D3D3B',
    WHITE: '#FFFFFF',
    GRAY: '#EDEDED',
    LIGHT_GRAY: '#F5F5F5',
    LIGHT_GRAY_2: '#C7C7C5',
    DARK_GRAY: '#C7C7C4',
    GRAY_NEUTRAL: '#9C9C9C',
    LIGHT_BLUE: '#CBDEFD',
    DARK_BLUE: '#21526B',
    DARK_BLUE_1: '#250E62',
    ACCENT_BLUE: '#8CBDFA',
    ACCENT_MAROON: '#8A2252'
}