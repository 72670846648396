import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function SelectGoogleTv() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectGoogleTv']
  const homeplusscript = workflowState['config']?.scripts["SelectGoogleTv"];

  const [selectedGoogleTvOption, setSelectedGoogleTvOption] = useState({});

  let googleTvOptions = findById(metadata, "googleTvOptions");
  let btnGoBack = findById(metadata, "btnGoBack");
  let titleText = findById(metadata, "txtTitle");
  let txtGoogleTvInfo = findById(metadata, "txtGoogleTvInfo");
  let menu = findById(metadata,"menu");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const googleTvOptionList = [
    {
      value: homeplusscript.labelGoogleTvYes,
      label: homeplusscript.labelGoogleTvYes,
    },
    {
      value: homeplusscript.labelGoogleTvNo,
      label: homeplusscript.labelGoogleTvNo,
    },
  ];

  const onContinueClick = (modelOption) => {

    const isGoogleTv = modelOption?.value === homeplusscript.labelGoogleTvYes;

    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        isGoogleTv,
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      askPuchaseReceipt: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

  const goToDashboard = () => {
     // clear all device reg data
     updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  const onGoogleTvOptionSelect = (modelOption) => {
    setSelectedGoogleTvOption({ ...selectedGoogleTvOption, ...modelOption });

    onContinueClick(modelOption);
  };

  googleTvOptions.args = {
    ...googleTvOptions.args,
    deviceItems: googleTvOptionList,
    value: selectedGoogleTvOption?.value,
    onClick: onGoogleTvOptionSelect,
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title,
  };

  txtGoogleTvInfo.args = {
    ...txtGoogleTvInfo.args,
    content: homeplusscript.otherInfo,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default SelectGoogleTv;
