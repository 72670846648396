import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById } from "../enrollment-workflow/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { Config } from "./config";
import { callCreateInteractionApi, logGTMEvent } from "./api-helper";
import { GTM_EVENT } from "../../utils/constants";
import logger from "../../logger.service";

function Login() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["Login"];
  let homeplusscript = workflowState["config"]?.scripts["Login"];

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);

  let inputEmail = findById(metadata, "inputEmail");
  let invalidEmailText = findById(metadata, "invalidEmail");
  let policyText = findById(metadata, "policyText");
  let btnLogin = findById(metadata, "btnLogin");

  useEffect(() => {
    callCreateInteraction();
  }, []);

  const showForgotEmail = () => {
    return completeCurrentStep(currentStep, {
      forgotEmail: true,
    });
  };
  let vaildEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onEmailSubmit = () => {
    // showLoader(true);

    if (vaildEmailRegex.test(email)) {
      console.log("Valid Email Address");
      setIsValidEmail(true);
      buyNowSignIn();

      return completeCurrentStep(currentStep, {
        loggedIn: true,
        OTPError: false,
        email: email,
      });
    } else {
      // showLoader(false);
      setIsValidEmail(false);
      console.log("Invalid Email Address");
    }

    setShowEmailError(false);
  };
  let buyNowSignIn = () => {
    API[ActionTypes.OTP_REQUEST](email)
      .then((data) => {
        // showLoader(false);
        updateState(
          {
            ...workflowState,
            OtpResponse: data,
          },
          ["OtpResponse"]
        );
        if (!data.challengeParam["email"]) {
          // setNotRegisteresEmail(true);
          // setShowEmailError(true);
          return;
        }

        // log login event
        logGTMEvent({
          event: GTM_EVENT.LOGIN_EVENT,
          loginEmail: email,
          loginType: "Family Sign In",
        });

        // return completeCurrentStep(currentStep, {
        //   loggedIn: true,
        //   OTPError: false,
        //   email: email,
        // });
      })
      .catch((error) => {
        // showLoader(false);
        // if(error?.message === 'Incorrect username or password.')
        //   setShowEmailError(true);

        updateState(
          {
            ...workflowState,
            OTPError: error,
            [currentStep]: {
              ...workflowState[currentStep],
              OTPError: true,
              loggedIn: false,
            },
          },
          ["OTPError", currentStep]
        );
      });
  };
  const handleError = (input) => {
    setIsValidEmail(true);
    setEmail(input);
    // if(vaildEmailRegex.test(email)){
    //   console.log("Valid Email Address")
    //   setIsValidEmail(true);
    // }else if(input ==""){
    //   setIsValidEmail(true);
    // }
    // else{
    //   showLoader(false);
    //   setIsValidEmail(false);
    //   console.log("Invalid Email Address")
    // }
  };

  const callCreateInteraction = () => {
    // showLoader(true);
    const CreateInteraction = {
      CreateInteraction: {
        Interaction: {
          ChannelName: "SH_DEVICE_CARE-WEB",
          ClientId: workflowState["config"]?.ClientId,
          ClientChannelId: workflowState["config"]?.ClientChannelId,
          InteractionStatus: "INPRGRS",
          InteractionLine: {
            InteractionLineReason: "STRTSR",
            Language: "en-US",
          },
        },
      },
    };

    callCreateInteractionApi(CreateInteraction, onApiSuccess, onApiFailure);
  };

  const onApiSuccess = (type, data) => {
    // showLoader(false);
    updateState({
      ...workflowState,
      CreateInteractionResponse: data?.Interaction,
    });
  };

  const onApiFailure = (type, error) => {
    // showLoader(false);
    logger({ type, error, state: workflowState });
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  let proSupText = findById(metadata, "protectionSupportText");
  proSupText.args = {
    ...proSupText.args,
    content: homeplusscript.protectionSupportText,
  };

  let logInText = findById(metadata, "loginText");
  logInText.args = { ...logInText.args, content: homeplusscript.loginText };

  let emailError = findById(metadata, "emailError");
  emailError.args = {
    ...emailError.args,
    content: homeplusscript.emailError,
    other: showEmailError ? "block" : "hidden",
    onLinkClick: (e) => showForgotEmail(),
  };

  invalidEmailText.args = {
    ...invalidEmailText.args,
    displayClasses: isValidEmail ? "hidden" : "block",
  };
  policyText.args = {
    ...policyText.args,
    content: homeplusscript.policyText,
    marginClasses:
      isValidEmail && !showEmailError
        ? "mt-24"
        : !isValidEmail
        ? "mt-20"
        : "mt-0",
  };

  let emailErrorForFamilyUser = findById(metadata, "emailErrorForFamilyUser");
  emailErrorForFamilyUser.args = {
    ...emailErrorForFamilyUser.args,
    content: homeplusscript.loginFamilyUser.replace(
      "{url}",
      Config.service.securityAdvisor
    ),
    other: showEmailError ? "block" : "hidden",
  };

  // let termsCondition = findById(metadata, "termsCondition");
  // termsCondition.args = {
  //   ...termsCondition.args,
  //   label: homeplusscript.labelTermsCondition,
  // };

  // let privacyPolicy = findById(metadata, "privacyPolicy");
  // privacyPolicy.args = {
  //   ...privacyPolicy.args,
  //   label: homeplusscript.labelPrivacyPolicy,
  // };

  // let footerText = findById(metadata, "textFooter");
  // footerText.args = { ...footerText.args, content: homeplusscript.footerText };

  inputEmail.args = {
    ...inputEmail.args,
    label: homeplusscript.inputEmail_label,
    placeHolder: homeplusscript.inputEmail_placeHolder,
    borderClasses: showEmailError
      ? "border border-accentA5 focus:outline-none rounded-md focus:border-primary focus:shadow-sm"
      : inputEmail.args.borderClasses,
    value: email,
    onChange: handleError,
  };

  btnLogin.args = {
    ...btnLogin.args,
    label: homeplusscript.btnLogin_label,
    enabled: email,
    onClick: (e) => onEmailSubmit(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default Login;
