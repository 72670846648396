import * as React from "react";

function CloseIconSvg(
  props
) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7579 9.5885L14.7563 5.59453C15.0812 5.26935 15.0812 4.74404 14.7563 4.41885C14.4315 4.09367 13.9067 4.09367 13.5818 4.41885L9.58332 8.41283L5.59319 4.41052C5.26832 4.08533 4.73519 4.08533 4.41031 4.41052C4.08544 4.7357 4.08544 5.26935 4.41031 5.59453L8.40878 9.5885L4.41031 13.5825C4.08544 13.9077 4.08544 14.433 4.41031 14.7582C4.57692 14.9249 4.78517 15 5.00175 15C5.21834 15 5.42659 14.9166 5.59319 14.7582L9.58332 10.7642L13.5735 14.7582C13.7401 14.9249 13.9483 15 14.1649 15C14.3815 15 14.5897 14.9166 14.7563 14.7582C15.0812 14.433 15.0812 13.9077 14.7563 13.5825L10.7579 9.5885Z" fill="#3D3D3B"/>
      </svg>
    );
  }

export default CloseIconSvg

