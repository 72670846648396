import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect, useLayoutEffect } from "react";
import Timer from "../timer/timer";
import { getMIMEType } from "./video-utils";
import Webcam from "./my-webcam";

export const WebCam = ({
  showWebcam,
  capture,
  setSrc,
  setBlob,
  setCapturing,
  className,
  timerClasses,
  timerFontClasses,
  ...props
}) => {
  const dtt = new Date();
  const [timer, setTimer] = useState({ Timer: dtt });
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [src, setSrc] = useState(null);
  // const [blob, setBlob] = useState(null);
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  const callback = (value) => {
    if (value === "00:00") setCapturing("STOP");
  };

  const handleStartCaptureClick = useCallback(() => {
    console.log("mimeType:",getMIMEType());
    console.log("isMediaSupported:",MediaRecorder.isTypeSupported(getMIMEType()));
    console.log("webcamRef is", webcamRef);
    console.log("webcamRef stream is", webcamRef.current.stream);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: MediaRecorder.isTypeSupported(getMIMEType()) === true ? getMIMEType() : "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.addEventListener(
      "stop",
      handleStop
    );
    mediaRecorderRef.current.start(1000);
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = useCallback(() => {
    if(mediaRecorderRef.current.state === "inactive") return mediaRecorderRef.current.stop()
    setTimer({ Timer: "02:00"});
    // setTimeout(() => {
    //   mediaRecorderRef.current.stop();
    // }, 1000);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDataAvailable = useCallback(
    (event) => {
      console.log("handleDataAvailable", "Called", event);
      console.log("handleDataAvailable data", event?.data);
      console.log("handleDataAvailable data size", event?.data.size);
      if (event?.data.size > 0) {
        setRecordedChunks((prev) => prev.concat(event?.data));
      }
    },
    [setRecordedChunks]
  );

  const onUserMedia = () => {
    // actions.unSetLoader()
    console.log("handleStartCaptureClick");
    handleStartCaptureClick();
    dtt.setMinutes(dtt.getMinutes() + 2);
    setTimer({ Timer: dtt });
  };

  const onUserMediaError = (err) => {
    // actions.unSetLoader()
    console.log("onUserMediaError", err);
  };

  useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: MediaRecorder.isTypeSupported(getMIMEType()) === true ? getMIMEType() : "video/webm",
      });
      console.log("BLOB is", blob);
      setBlob(blob);
      const url = URL.createObjectURL(blob);
      console.log("URL is", url);
      setSrc(url);
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (capture === "STOP") {
      handleStopCaptureClick();
    } else if (capture === "START") {
      setRecordedChunks([]);
    }
  }, [capture]);


  const handleStop = (e) => {
    console.log("data available after MediaRecorder.stop() called.");
    console.log("data available after MediaRecorder.stop() called.", e);
  
    // const audio = document.createElement("audio");
    // audio.controls = true;
    // const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
    // const audioURL = window.URL.createObjectURL(blob);
    // audio.src = audioURL;
    console.log("recorder stopped");
  };

  return (
    <>
      {showWebcam && (
        <div className={`text-center`}>
          <Webcam
            className={className}
            style={{
              height: isDesktop ? "320px" : "auto",
              objectFit: "cover",
              margin: "auto",
              width: "480px"
            }}
            audio={true}
            muted={true}
            autoPlay={true}
            ref={webcamRef}
            onUserMedia={onUserMedia}
            onUserMediaError={onUserMediaError}
            mirrored={true}
          />
          <Timer
            classes={timerClasses}
            subDivClasses={timerFontClasses}
            parentCallback={(value) => callback(value)}
            state={timer}
          />
        </div>
      )}
    </>
  );
};

WebCam.propTypes = {};

WebCam.defaultProps = {};
