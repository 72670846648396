import React, { useEffect } from "react";
import { Text } from "../text/text";
import PropTypes from "prop-types";
import { List } from "../list/list";

export const ClaimHistory = ({
  historyMainDivClasses,
  historyHeaderDivClasses,
  itemTextClasses,
  loadingTextClasses,
  myClaims,
  isLoading,
  type,
  itemMainDivClasses,
  mainIconClasses,
  ...props
}) => {
  return (
    <div className={historyMainDivClasses}>
      <div className={historyHeaderDivClasses}>
        <Text
          content={"Completion Date"}
          style={""}
          other={itemTextClasses}
        ></Text>

        <Text
          content={"Claim Number"}
          style={""}
          other={itemTextClasses}
        ></Text>

        <Text
          content={"Claim Limit Utilised"}
          style={""}
          other={itemTextClasses}
        ></Text>

        {type &&<Text
          content={"Status"}
          style={""}
          other={itemTextClasses}
        ></Text>}

      </div>
      {myClaims && myClaims?.length > 0 ? (
        <List options={myClaims} itemType={"ClaimHistoryItem"} type={type} itemMainDivClasses={itemMainDivClasses} mainIconClasses={mainIconClasses}/>
      ) : (
        <Text
          content={isLoading ? "Loading claim history...." : "You do not have any completed claim"}
          size={"sm"}
          other={loadingTextClasses}
        ></Text>
      )}
    </div>
  );
};

ClaimHistory.propTypes = {
  historyMainDivClasses: PropTypes.string,
  historyHeaderDivClasses: PropTypes.string,
  itemTextClasses: PropTypes.string,
  myClaims: PropTypes.array,
};

ClaimHistory.defaultProps = {
  historyMainDivClasses: "flex flex-col w-full my-4 h-full",
  historyHeaderDivClasses: "flex flex-row bg-secondaryS1 px-2 py-1",
  itemTextClasses: "w-1/3 text-white font-LatoBold text-xs lg:text-sm",
  loadingTextClasses: "w-full h-full text-center mt-8 font-LatoBold",
  myClaims: [
    {
      CreatedDate: "1 August 2022",
      CustomerCaseNumber: "60001232",
      claimLimitUtilised: "$1234.10",
    },
    {
      CreatedDate: "2 September 2022",
      CustomerCaseNumber: "60001233",
      claimLimitUtilised: "$500.00",
    },
    {
      CreatedDate: "1 September 2021",
      CustomerCaseNumber: "60001234",
      claimLimitUtilised: "$1000.00",
    },
  ],
};
