import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "../enrollment-workflow/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { callCreateInteractionApi } from "./api-helper";
import logger from "../../logger.service";

const OTP_EXPIRE_MINUTES = 2;

function EmailOTP() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["EmailOTP"];
  let homeplusscript = workflowState["config"]?.scripts["EmailOTP"];

  let verifyCodeText = findById(metadata, "verifyCode");
  let otVerifyCodeText = findById(metadata, "otVerifyCode");
  let otpErrorText = findById(metadata, "otpError");
  let resendExhausted = findById(metadata, "resendExhausted");
  let otpResendText = findById(metadata, "otpResendText");
  let borderBottom = findById(metadata, "bottomHr");

  const dtt = new Date();
  dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES);

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState({ Timer: dtt });
  const [resendHitCount, setResendHitCount] = useState(0);
  const [showResendExhausted, setShowResendExhausted] = useState(false);
  const [showResendCodeText, setShowResendCodeText] = useState(false);
  const [showCodeSendText, setShowCodeSendText] = useState(true);

  const [disabledResend, setDisabledResend] = useState(true);
  const [showTimeoutError, setShowTimeoutError] = useState(false);
  const [showOtpError, setShowOtpError] = useState(false);

  let loginDetails = workflowState["login"];

  const callback = (value) => {
    // if (resendHitCount < 3) {
    if (value <= "01:00" && resendHitCount < 3) {
      setDisabledResend(false);

      if (value === "00:00") {
        setShowTimeoutError(true);
      }
    }
    // }
  };
  useEffect(() => console.log(resendHitCount), [resendHitCount]);

  const resendOtp = () => {
    showLoader(true);
    setShowResendCodeText(true);
    setShowCodeSendText(false);
    setShowOtpError(false);
    setOtp("");
    setResendHitCount(resendHitCount + 1);

    if (resendHitCount < 2) {
      API[ActionTypes.OTP_REQUEST](loginDetails?.email)
        .then((data) => {
          showLoader(false);
          setDisabledResend(true);
          setShowResendExhausted(false);
          // reset timer
          const dtt = new Date();
          dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES);
          setTimer({ Timer: dtt });
          // otp.length === 0 ?setShowResendCodeText(true):setShowResendCodeText(false);
          updateState({
            ...workflowState,
            OtpResponse: data,
          });
        })
        .catch((error) => {
          showLoader(false);
          // setShowResendCodeText(false);
          updateState({
            ...workflowState,
            OTPError: error,
            [currentStep]: {
              ...workflowState[currentStep],
              OTPError: true,
              loggedIn: false,
            },
          });
        });
    } else {
      console.log("ReSend Code Max Hit");
      const dtt = new Date();
      dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES);
      setTimer({ Timer: dtt });
      setShowResendExhausted(true);
      showLoader(false);
      setDisabledResend(true);
    }
  };

  let verifyOtp = () => {
    showLoader(true);
    setShowOtpError(false);
    setShowCodeSendText(false);
    setShowResendCodeText(false);

    API[ActionTypes.VERIFY_LOGIN_OTP](otpValue)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          otpResponse: data,
        });
        completeCurrentStep(currentStep, {
          otpValue: otpValue,
          OTPVerified: "VERIFIED",
          verifiedOtp: true,
        });
      })
      .catch((error) => {
        showLoader(false);
        setShowOtpError(true);
        setOtp("");
        updateState({
          ...workflowState,
          OTPError: error,
          [currentStep]: {
            ...workflowState[currentStep],
            OTPError: true,
            loggedIn: false,
          },
        });
      });
  };

  otpResendText.args = {
    ...otpResendText.args,
    content: homeplusscript.otpResendText,
    displayClasses: showResendCodeText ? "block" : "hidden",
  };

  resendExhausted.args = {
    ...resendExhausted.args,
    displayClasses: showResendExhausted ? "block" : "hidden",
  };

  verifyCodeText.args = {
    ...verifyCodeText.args,
    content: homeplusscript.verifyCode.replace(
      "{'emailAddress'}",
      loginDetails?.email
    ),
    displayClasses: showCodeSendText && otp.length === 0 ? "block" : "hidden",
  };
  otVerifyCodeText.args = {
    ...otVerifyCodeText.args,
    content: homeplusscript.otVerifyCode,
  };

  otpErrorText.args = {
    ...otpErrorText.args,
    content: homeplusscript.otpError,
    other: showOtpError ? "block" : "hidden",
  };
  borderBottom.args = {
    ...borderBottom.args,
    displayClasses: otp.length > 0 ? "block" : "hidden",
  };

  let otpTimer = findById(metadata, "timer");
  otpTimer.args = {
    ...otpTimer.args,
    parentCallback: (value) => callback(value),
    state: timer,
  };

  let otpInput = findById(metadata, "otpInput");
  let optContinue = findById(metadata, "continue");
  let reSendCode = findById(metadata, "reSendCode");

  const otpValue = otp && otp.join("");
  otpInput.args = {
    ...otpInput.args,
    label: homeplusscript.enterOTP,
    displayLabelClasses: showOtpError ? "hidden" : "block",
    onChange: setOtp,
  };
  optContinue.args = {
    ...optContinue.args,
    label: homeplusscript.continue_label,
    enabled: otpValue && otpValue.length === 6,
    onClick: (e) => verifyOtp(),
  };

  reSendCode.args = {
    ...reSendCode.args,
    label: homeplusscript.reSendCode_label,
    onClick: (e) => resendOtp(),
    enabled: !disabledResend,
  };
  return <Stack orientation="vertical" metadata={metadata} />;
}

export default EmailOTP;
