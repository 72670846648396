import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";
import { DEVICE_CATEGORY } from "../../../utils/constants";

function ProvideDeviceModelNumber() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideDeviceModelNumber']
  const homeplusscript = workflowState['config']?.scripts["ProvideDeviceModelNumber"];

  const [modelNumber, setModelNumber] = useState("");
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModelOption, setSelectedModelOption] = useState({});
  const [selectedTabletModel, setSelectedTabletModel] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden")

  let hasModelNumber = findById(metadata, "hasModelNumber");
  let inputModelNumber = findById(metadata, "inputModelNumber");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtModelNumberInfo = findById(metadata, "txtModelNumberInfo");
  let txtTitle = findById(metadata, "txtTitle");
  let modelSelection = findById(metadata, "modelSelection");
  let menu = findById(metadata, "menu");
  let txtModelNumberInfoNormal = findById(metadata, "txtModelNumberInfoNormal")

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isTV = selectedCategory === DEVICE_CATEGORY.TV;
  const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;
  const isLaptopDesktop =
    selectedCategory === DEVICE_CATEGORY.Laptop || selectedCategory === DEVICE_CATEGORY.Desktop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;
  const isWebcam = selectedCategory === DEVICE_CATEGORY.Webcam;
  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;

  const modelNumberOptions = [
    {
      value: homeplusscript.labelKnowModel,
      label: homeplusscript.labelKnowModel,
    },
    {
      value: homeplusscript.labelDontKnowModel,
      label: homeplusscript.labelDontKnowModel,
    },
  ];
  const getExpandableTitleInfo = () =>{
    if (isTV) return homeplusscript.modelNumberTitleTvInfo;
    else return homeplusscript.modelNumberTitleInfo
  }

  const getInfoTitle = () => {
    if (isSoundbar || isKeyboard || isRouter) return homeplusscript.modelNumberInfoSoundbarKeyboardRouter;
    else if (isMouse) return homeplusscript.modelNumberInfoMouse;
    else if (isTV) return homeplusscript.modelNumberInfoTV;
    else if (isDesktop) return homeplusscript.modelNumberInfoDesktop;
    else if (isLaptop) return homeplusscript.modelNumberInfoLaptop;
    else if (isTablet)
      return homeplusscript.modelNumberInfoTablet.replace(
        "{'key'}",
        getTabletKey()
      );
    else if (isPrinter) return homeplusscript.modelNumberInfoPrinter;
    else if (isMonitor) return homeplusscript.modelNumberInfoMonitor;
    else if (isWebcam) return homeplusscript.modelNumberInfoWebcam;
    else return homeplusscript.modelNumberInfo;
  };

  const getTabletKey = () => {
    return regDeviceDetails?.selectedBrand === "Apple" ? "iPad" : "Tablet"
  }

  useEffect(() => {
    if (isTablet) {
      let modelList = [];

      const models = !isEmpty(regDeviceDetails?.selectedBrandDetails)
        ? regDeviceDetails.selectedBrandDetails.Model
        : regDeviceDetails?.distinctModelList;
      models.forEach((model) => {
        if (!isEmpty(model)) {
          let deviceModel = {
            value: model,
            label: model,
          };
          modelList.push(deviceModel);
        }
      });

      setModelOptions(modelList);
    }
  }, []);

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        modelNumber: modelNumber?.trim()
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectTechnology: isTV || isPrinter,
      isDeviceBuiltIn: isSoundbar,
      provideSerialNumber: isLaptop,
      selectProcessor: isDesktop,
      selectResolution: isMonitor,
      selectWebcamRes: isWebcam,
      isTypeBluetooth : isKeyboard,
      selectConnectivityType : isMouse || isRouter || isTablet,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const onModelOptionSelect = (modelOption) => {
    hideShowInputModelNumber(modelOption);
    setSelectedModelOption({ ...selectedModelOption, ...modelOption });
  };

  const hideShowInputModelNumber = (option) => {
    // clear input value
    setModelNumber("");
    // inputModelNumber.args = {
    //   ...inputModelNumber.args,
    //   other:
    //     option.value === homeplusscript.labelKnowModel ? "block" : "hidden",
    // };
    if(option.value === homeplusscript.labelKnowModel){
       setDisplayProp("block")
    }else{
      setDisplayProp("hidden")
    }

    //  txtModelNumberInfo.args = {
    //    ...txtModelNumberInfo.args,
    //    displayClasses:
    //      option.value === homeplusscript.labelKnowModel ? "block" : "hidden",
    //  };
  };
  inputModelNumber.args = {
    ...inputModelNumber.args,
    other:displayProp
  };

  txtModelNumberInfoNormal.args ={
    ...txtModelNumberInfoNormal.args,
    content: getInfoTitle(),
    displayClasses: (isSoundbar || isMouse || isKeyboard || isRouter || isWebcam ||isMonitor ||isPrinter) ? displayProp : 'hidden',
  }

  const onModelSelect = (model) => {
    setSelectedTabletModel({...selectedTabletModel, ...model});
  }

  txtTitle.args = {
    ...txtTitle.args,
    content: isTV || isTablet 
    ? homeplusscript.title.replace("{'deviceCategory'}", selectedCategory)
    : homeplusscript.titleOther,
  };

  inputModelNumber.args = {
    ...inputModelNumber.args,
    label: homeplusscript["labelModelNumber"],
    placeHolder: homeplusscript["labelModelNumber"],
    value: modelNumber,
    other: isTV || isTablet ? "block" : inputModelNumber.args.other,
    onChange: setModelNumber,
  };

  txtModelNumberInfo.args = {
    ...txtModelNumberInfo.args,
    title:getExpandableTitleInfo(),
    content: getInfoTitle(),
    //  displayClasses:
    //   (isTV || isTablet)  ||
    //    selectedModelOption?.value === homeplusscript.labelKnowModel || (!isSoundbar || !isMouse || !isKeyboard || !isRouter || !isWebcam )
    //      ? "block"
    //      : "hidden",
    displayClasses: (isTV || isTablet)? "block" :(isLaptop || isDesktop)? displayProp : 'hidden'
  };

  hasModelNumber.args = {
    ...hasModelNumber.args,
    deviceItems: modelNumberOptions,
    value: selectedModelOption?.value,
    mainDivDisplayClasses: isTV || isTablet ? "hidden" : "block",
    onClick: onModelOptionSelect,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled:
      isTV || isTablet || selectedModelOption?.value === homeplusscript.labelKnowModel
        ? modelNumber?.trim()
        : selectedModelOption?.value,
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  modelSelection.args = {
    ...modelSelection.args,
    label: homeplusscript.labelModelNumber,
    options: modelOptions,
    value: selectedTabletModel?.value,
    onSelect: onModelSelect,
    // displayClasses: isTablet ? "block" : modelSelection.args.displayClasses,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"]
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvideDeviceModelNumber;
