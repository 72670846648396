import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../enrollment-workflow/helper";

const sizemap = {
  xs: "xs",
  sm: "sm",
  md: "base",
  lg: "lg",
  xl: "xl",
  "3xl": "3xl",
  "2xl": "2xl",
  "5xl": "5xl",
};

const stylemap = {
  highlighted: "font-bold text-primary",
  supressed: "text-white",
  normal: "text-neutral",
  semibold: "font-semibold",
  bold: "font-bold",
  error: "text-xs text-red-600",
};

export const Text = ({
  style,
  size,
  wrap,
  content,
  colorClasses,
  justification,
  marginClasses,
  paddingClasses,
  displayClasses,
  other,
  onClick,
  onLinkClick,
  ...props
}) => {
  let fontSize = `text-${sizemap[size]}`;

  useEffect(() => {
    const element = document.getElementById("link-text");
    element?.addEventListener("click", (e) => onLinkClick());
    return () => {
      element?.removeEventListener("click", (e) => onLinkClick());
    }
  }, []);

  return (
    <span
      className={`${fontSize} ${isEmpty(style) ? '' : stylemap[style]} ${colorClasses} ${justification} ${marginClasses} ${paddingClasses} ${other} ${displayClasses}`}
      dangerouslySetInnerHTML={{ __html: content }}
      onClick={onClick}
    />
  );
};

Text.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf([
    "highlighted",
    "supressed",
    "normal",
    "bold",
    "error",
  ]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "5xl"]),

  /**
   * Text contents
   */
  content: PropTypes.string.isRequired,
};

Text.defaultProps = {
  style: "normal",
  size: "md",
  content: "",
};
