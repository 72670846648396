import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "../tooltip/tooltip";
import Visa from "../../icons/ic_visa";
import Icon from "../icon/icon";

export const CardNumberInput = ({
  enabled,
  label,
  placeHolder,
  value,
  type,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  displayClasses,
  onChange,
  onKeyUp,
  onClick,
  tabIndex,
  showTooltip,
  other,
  maxlength,
  image,
  id,
  ...props
}) => {
 
  return (
    <>
        <div
          className={`flex flex-col ${fontClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses} ${other}`}
        >
          {showTooltip ? (
            <Tooltip showLabel={true} labelText={label} />
          ) : (
            <label>{label}</label>
          )}
          <div
            className="flex h-10 mt-1 border rounded-md border-neutralN8 focus-within:outline-none focus-within:border-primary focus:shadow-sm"
           >
            <input
              id={id}
              type={type}
              className={"border-0 outline-none mx-1 px-2 w-full"}
              value={value}
              enabled={enabled}
              disabled={!enabled}
              maxLength={maxlength}
              tabIndex={tabIndex}
              onChange={(e) => onChange(e.target.value)}
             // onClick={(e) => onClick(e.target.value)}
            />
           {image && <Icon
             className={"w-6 h-6 mx-5 mt-2"}
              icon={image}
            ></Icon>}
          </div>
        </div>
      </>)

    
};

CardNumberInput.propTypes = {
  enabled: PropTypes.bool,

  label: PropTypes.string,

  placeHolder: PropTypes.string,

  value: PropTypes.string,

  type: PropTypes.string,

  fontClasses: PropTypes.string,

  dimensionClasses: PropTypes.string,

  colorClasses: PropTypes.string,

  borderClasses: PropTypes.string,

  paddingClasses: PropTypes.string,

  marginClasses: PropTypes.string,

  displayClasses: PropTypes.string,

  onChange: PropTypes.func,
  
  onFocus: PropTypes.func,

  maxlength: PropTypes.string,

  onClick: PropTypes.func,

  onKeyUp: PropTypes.func,

  showTooltip: PropTypes.bool,

  other: PropTypes.string,
};

CardNumberInput.defaultProps = {
  enabled: true,
  label: "label",
  value: "",
  fontClasses: "text-base",
  dimensionClasses: "",
  colorClasses: "",
  borderClasses:
    "border border-neutralN8 focus-within:outline-none rounded-md focus-within:border-primary focus-within:shadow-sm",
  paddingClasses: "",
  marginClasses: "mb-5",
  displayClasses: "",
  onChange: undefined,
  onClick: undefined,
  onKeyUp: undefined,
  showTooltip: false,
  other: "",
  maxlength: "",
  tabIndex: "",
  type:"text",
  image:""
};
