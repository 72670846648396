import React, { useEffect } from "react";
import { Text } from "../text/text";
import PropTypes from "prop-types";

export const ClaimCoverage = ({
  coverageMainDivClasses,
  coverageHeaderDivClasses,
  coverageItemDivClasses,
  coverageItemClasses,
  itemTextClasses,
  itemLabelClasses,
  ...props
}) => {
  return (
    <div className={coverageMainDivClasses}>
      <div className={coverageItemDivClasses}>
        <div className={coverageHeaderDivClasses}>
          <Text
            content={"Group A"}
            style={""}
            size={"sm"}
            other={"w-1/3 font-LatoBold"}
            colorClasses={"text-accent"}
          ></Text>

          <Text
            content={"Deductible"}
            size={"sm"}
            other={itemLabelClasses}
          ></Text>

          <Text
            content={"Per Claim Limit"}
            size={"sm"}
            other={itemLabelClasses}
          ></Text>
        </div>
        <div className={coverageItemClasses}>
          <Text
            content={"Computer, Laptop, Soundbar, Tablet, Television"}
            style={""}
            size={"sm"}
            other={itemTextClasses}
          ></Text>

          <Text
            content={"$190 </br> $210(Apple)"}
            size={"sm"}
            other={itemTextClasses}
          ></Text>

          <Text content={"$1500"} size={"sm"} other={itemTextClasses}></Text>
        </div>
      </div>

      <div className={coverageItemDivClasses}>
        <div className={coverageHeaderDivClasses}>
          <Text
            content={"Group B"}
            style={""}
            size={"sm"}
            other={"w-1/3 font-LatoBold"}
            colorClasses={"text-accent"}
          ></Text>

          <Text
            content={"Deductible"}
            size={"sm"}
            other={itemLabelClasses}
          ></Text>

          <Text
            content={"Per Claim Limit"}
            size={"sm"}
            other={itemLabelClasses}
          ></Text>
        </div>
        <div className={coverageItemClasses}>
          <Text
            content={"Keyboard, Monitor, Mouse, Printer, Router, Webcam"}
            style={""}
            size={"sm"}
            other={itemTextClasses}
          ></Text>
          <Text content={"$30"} size={"sm"} other={itemTextClasses}></Text>
          <Text content={"$600"} size={"sm"} other={itemTextClasses}></Text>
        </div>
      </div>
    </div>
  );
};

ClaimCoverage.propTypes = {
  coverageMainDivClasses: PropTypes.string,
  coverageHeaderDivClasses: PropTypes.string,
  coverageItemDivClasses: PropTypes.string,
  coverageItemClasses: PropTypes.string,
  itemTextClasses: PropTypes.string,
  itemLabelClasses: PropTypes.string,
};

ClaimCoverage.defaultProps = {
  coverageMainDivClasses: "flex flex-col w-full my-4",
  coverageHeaderDivClasses: "flex flex-row w-full",
  coverageItemDivClasses: "border border-primary mt-2 p-2",
  coverageItemClasses: "flex flex-row w-full py-2",
  itemTextClasses: "w-1/3",
  itemLabelClasses: "w-1/3 font-LatoBold",
};
