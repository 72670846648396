import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";
import { useEffect } from "react";

export const ExpandableInfo = ({
  expanded,
  setIsOpen,
  item,
  title,
  content,
  itemIconClasses,
  mainDivClasses,
  titleClasses,
  btnDisplayClasses,
  buttonDivClasses,
  onDeviceSelect,
  displayClasses,
  marginClasses,
  bgColorClasses,
  expaIconClasses,
  textColorClasses,
  ...props
}) => {
  const [isOpen, setIsOpenn] = useState(expanded);
  const toggleExpanded = () => {
    setIsOpenn(!isOpen);
  };

  useEffect(() => {
    setIsOpenn(expanded);
  }, [expanded]);

  return (
    <div className={`${mainDivClasses} ${displayClasses} ${marginClasses}`}>
      <div
        className={bgColorClasses}
        onClick={toggleExpanded}
      >
        <div className="flex flex-row">
          <Icon className={itemIconClasses} icon={"InfoPurple"}></Icon>
          <span className={titleClasses}>{title}</span>
        </div>
        <span className="inline-block mx-2 cursor-pointer">
          {!isOpen ? (
            <Icon
              className={expaIconClasses}
              icon="IconExpand"
            ></Icon>
          ) : (
            <Icon
              className={expaIconClasses}
              icon="IconCollapse"
            ></Icon>
          )}
        </span>
      </div>
      {isOpen && (
        <div className={textColorClasses}>
          <Text
            {...{
              content: content,
              colorClasses: "text-secondaryS4",
              marginClasses: "",
              paddingClasses: "",
              other: "font-LatoRegular font-normal text-sm",
            }}
          />
        </div>
      )}
    </div>
  );
};
ExpandableInfo.propTypes = {
  /**
   * Is the control expand or collapse
   */
  expanded: PropTypes.bool,
  setIsOpen: PropTypes.func,
  item: PropTypes.object,
};

ExpandableInfo.defaultProps = {
  expanded: false,
  setIsOpen: undefined,
  item: {},
  titleClasses:
    "text-sm line-height-22 pr-2 my-2 font-LatoBold bg-accentA8 text-secondaryS4 text-neutralN2",
  itemIconClasses: "mr-3 my-auto",
  mainDivClasses:
    "flex flex-col mt-5 h-auto w-full bg-white border rounded shadow-sm border-neutralN8",
  bgColorClasses:"flex flex-row items-center justify-between px-1 cursor-pointer bg-accentA8 text-secondaryS4",
  expaIconClasses:"flex-col text-secondaryS4 bg-accentA8",
  textColorClasses:"px-8 py-2 bg-accentA8 text-secondaryS4"
};
