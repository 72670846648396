import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import Popup from "reactjs-popup";
import ProvideContactNumber from "./contactnumberpopup";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import {
  FULFILMENT_OPTION,
  FULFILMENT_OPTION_OVERAGE,
} from "../../../utils/constants";
import {
  callProcessOveragePaymentApi,
  callUpdateRepairRequestApi,
} from "../api-helper";
import logger from "../../../logger.service";

function ClaimSummary() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const [opencontactPopup, setContactPopup] = useState(false);
  const [alternateNumber, setAlternateNumber] = useState();

  const contentStyleDekstop = {
    padding: "15px",
    width: "100%",
    height: "259px",
    margin: "auto",
    background: "white",
    position: "absolute",
    bottom: "0",
    border: " ",
    borderRadius: "16px",
    borderColor: "",
    textColor: "#3D3D3B",
  };

  const contentStyleMobile = {
    padding: "15px",
    width: "405px",
    height: "259px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    border: " ",
    borderRadius: "16px",
    borderColor: "",
    textColor: "#3D3D3B",
  };

  const metadata = workflowState["config"]?.metadata["ClaimSummary"];
  const homeplusscript = workflowState["config"]?.scripts["ClaimSummary"];
  const isBraintree = workflowState["config"]?.isBraintree
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");
  let btnConfirmPayment = findById(metadata, "btnConfirmPayment");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let claimSummary = findById(metadata, "claimSummary");
  let pickupDetails = findById(metadata, "pickupDetails");
  let txtClaimSummaryInfo = findById(metadata, "txtClaimSummaryInfo");
  let txtTotalCharge = findById(metadata, "txtTotalCharge");
  let totalCharge = findById(metadata, "totalCharge");

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceFeeResponse = fileClaimDetails?.ServiceFeeResponse;

  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const replacementOption = fileClaimDetails?.selectedReplacementOption;
  const isOverageFlow = fileClaimDetails?.isOverageFlow;

  const RepairRequestResponse = fileClaimDetails?.RepairRequestResponse;
  const ServiceOrderResponse = fileClaimDetails?.ServiceOrderResponse;
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  const CustomerCaseNumber = ServiceRequestResponse?.CustomerCaseNumber;

  const ServiceOrderId = ServiceOrderResponse?.ServiceOrderId;

  const incidentResponse = fileClaimDetails?.ProcessIncidentResponse;

  const isReplacement = isOverageFlow
    ? replacementOption?.FulfillmentOption?.toUpperCase() ===
      FULFILMENT_OPTION_OVERAGE.REPLACMENT
    : fileClaimDetails?.selectedDevice?.FulfillmentMethodType ===
      FULFILMENT_OPTION.REPLACMENT;

  const claimSummaryTableHeaders = [
    {
      key: "deviceCategory",
      text: homeplusscript.labelDeviceCategory,
      hideForNoAction: false,
    },
    {
      key: "brand",
      text: homeplusscript.labelMakeBrand,
      hideForNoAction: false,
    },
    {
      key: "modelNo",
      text: homeplusscript.labelModelNo,
      hideForNoAction: false,
    },
    {
      key: "serialNo",
      text: homeplusscript.labelSerialNo,
      hideForNoAction: true,
    },
    {
      key: "claimNo",
      text: homeplusscript.labelClaimNo,
      hideForNoAction: false,
    },
    // {
    //   key: "claimType",
    //   text: homeplusscript.labelClaimType,
    //   hideForNoAction: false,
    // },
  ];

  const modelNo = isOverageFlow
    ? replacementOption?.AssetModel
    : fileClaimDetails?.selectedDevice?.ClientAssetSkuNumber;

  const claimSummaryTableData = [
    {
      deviceCategory: isOverageFlow
        ? replacementOption?.AssetCategoryName?.split("_")[0]
        : fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryName,
      brand: isOverageFlow
        ? replacementOption?.AssetModel?.split("_")[0]
        : fileClaimDetails?.selectedDevice?.Make?.Name,
      modelNo: modelNo || "-",
      claimNo: CustomerCaseNumber,
      // claimType: isOverageFlow
      //   ? replacementOption?.FulfillmentOption
      //   : isReplacement
      //   ? "REPLACEMENT"
      //   : fileClaimDetails?.selectedDevice?.FulfillmentMethodType,
    },
  ];

  // Show claim details only if applicable
  const serialNo = fileClaimDetails?.selectedDevice?.IMEI;
  if (serialNo) {
    const [summary] = claimSummaryTableData;
    summary.serialNo = serialNo;
    const header = claimSummaryTableHeaders.find((th) => th.key === "serialNo");
    header.hideForNoAction = false;
  }

  const pickupDetailsTableHeaders = [
    {
      key: "onsiteAddress",
      text: isReplacement
        ? homeplusscript.labelAddress
        : homeplusscript.labelOnsiteAddress,
      hideForNoAction: false,
    },
    {
      key: "contactNumber",
      text: homeplusscript.labelContactNumber,
      hideForNoAction: false,
    },
    {
      key: "alternateNumber",
      text: homeplusscript.labelAlternateNumber,
      hideForNoAction: false,
      onRowDataClick: true,
    },
  ];

  const AlternateContactNumber = alternateNumber?.value?.slice(2);
  const pickupDetailsTableData = [
    {
      onsiteAddress: fileClaimDetails?.onsiteAddress,
      contactNumber: fileClaimDetails?.selectedDevice?.MobileDeviceNumber,
      alternateNumber: AlternateContactNumber
        ? homeplusscript.editAlternateNumber.replace(
            "{'alternateNumber'}",
            AlternateContactNumber
          )
        : homeplusscript.alternateNumber,
    },
  ];

  const showPopup = () => {
    if (homeplusscript.alternateNumber) {
      setContactPopup(!opencontactPopup);
    }
  };

  const saveAlternateNumber = (mobileNumber) => {
    console.log("alternate mobile number is", mobileNumber);
    setAlternateNumber(mobileNumber);
  };

  const onConfirmClick = () => {
    //  if overage amount is 0 then do not move for payment
    if (isOverageFlow) {
      if (isReplacement)
        /*callUpdateRepairRequest();*/ callProcessOveragePayment();
      else if (isBraintree) {
        return completeCurrentStep(currentStep, {
          isBraintree: true,
        });
      } else
        return completeCurrentStep(currentStep, {
          Confirm: true,
        });
    } else callCreateContactApi();
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: !isOverageFlow,
      GoBackReplacementOptions: isOverageFlow,
    });
  };

  const callCreateContactApi = () => {
    showLoader(true);

    const CreateContactPointParameters = {
      CreateContactPointParameters: {
        InteractionLineId:
          workflowState["CreateInteractionResponse"]?.InteractionLine
            ?.InteractionLineId,
        AgreementId: agreement?.AgreementId,
        ContactPoint: {
          ServiceRequestId:
            ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
          ClientAccountId: agreement?.ClientAccount?.ClientAccountId
            ? agreement?.ClientAccount?.ClientAccountId
            : fileClaimDetails?.ClientAccountId,
          EmailAddress: workflowState["login"]?.email,
          PhoneNumber:
            AlternateContactNumber ||
            fileClaimDetails?.selectedDevice?.MobileDeviceNumber ||
            fileClaimDetails?.EnrolledDevice?.MDN,
        },
      },
    };
    API[ActionTypes.CREATE_CONTACT](CreateContactPointParameters)
      .then((data) => {
        showLoader(false);

        updateState(
          {
            ...workflowState,
            FileClaimDetails: {
              //  ...fileClaimDetails,
              AlternateContactNumber,
              ContactResponse: data?.data,
            },
          },
          ["FileClaimDetails"]
        );

        // check for video hold if yes ask for Video declaration
        //  const hasVideoHold = process.env.REACT_APP_IS_VIDEO_DECLARATION_AVAILABLE === "TRUE" && incidentResponse?.Holds?.length > 0 && isVideoHold(incidentResponse?.Holds)
        const hasVideoHold =
          incidentResponse?.Holds?.length > 0 &&
          isVideoHold(incidentResponse?.Holds);
        //  const hasVideoHold = false;

        if (isBraintree) {
          return completeCurrentStep(currentStep, {
            isBraintree: !hasVideoHold,
            askVideoDeclaration: hasVideoHold,
          });
        } else {
          return completeCurrentStep(currentStep, {
            Confirm: !hasVideoHold,
            askVideoDeclaration: hasVideoHold,
          });
        }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({
          type: ActionTypes.CREATE_CONTACT,
          error,
          state: workflowState,
        });
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  //  const callUpdateRepairRequest = () => {
  //    showLoader(true);

  //    const UpdateRepairRequestParameter = {
  //      UpdateRepairRequestParameter: {
  //        RepairRequestId: RepairRequestResponse?.RepairRequestId,
  //        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
  //        ServiceRequestId:
  //          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
  //        ServiceOrderId,
  //        RepairRequestType: "HomePlus",
  //        RepairStatus: "READY FOR PROCUREMENT",
  //        UpdatedBy: agreement?.Customers?.Customer[0]?.FullName,
  //        UpdateAction: "HOMEPLUSNEWUPDATEACTION",
  //        AspDetails: {
  //          StoreRepEmailId: agreement?.Customers?.Customer[0]?.FullName,
  //        },
  //      },
  //    };

  //    callUpdateRepairRequestApi(
  //      UpdateRepairRequestParameter,
  //      onApiSuccess,
  //      onApiFailure
  //    );
  //  };

  const callProcessOveragePayment = () => {
    showLoader(true);

    const ProcessOveragePaymentParameters = {
      ProcessOveragePaymentParameters: {
        CustomerDecision: replacementOption?.FulfillmentOption,
        RepairRequestId: RepairRequestResponse?.RepairRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        ServiceOrderId,
        AssetCatalogId: replacementOption?.AssetCatalogId,
        UpdatedBy: agreement?.Customers?.Customer[0]?.FullName,
        ServiceOrderLines: {
          ServiceOrderLine: [
            {
              ServiceOrderLineType: replacementOption?.AssetCategoryName,
              Quantity: 1,
              AssetCatalogId: replacementOption?.AssetCatalogId,
              VendorItemId: replacementOption?.ItemId,
              Priority: "MNDTRY",
              VenderItemType: replacementOption?.AssetCategoryName,
              VendorData: {
                IsLikeForLike: true,
                IsSimCard: false,
                IsInStock: true,
              },
            },
          ],
        },
      },
    };

    callProcessOveragePaymentApi(
      ProcessOveragePaymentParameters,
      onApiSuccess,
      onApiFailure
    );
  };

  const onApiSuccess = (type, data) => {
    showLoader(false);
    if (type === ActionTypes.UPDATE_REPAIR_REQUEST) {
      callProcessOveragePayment();
    } else if (type === ActionTypes.PROCESS_OVERAGE_PAYMENT) {
      updateState(
        {
          ...workflowState,
          FileClaimDetails: {
            //  ...fileClaimDetails,
            ProcessOveragePaymentResponse: data,
          },
        },
        ["FileClaimDetails"]
      );
      return completeCurrentStep(currentStep, {
        showOverageNotes: true,
      });
    }
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type: type, error, state: workflowState });
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  const onTextClick = () => showPopup();

  const isVideoHold = (holds) => {
    return holds.some(
      (hold) => hold.HoldType === "VIDEO" && hold.HoldStatus === "OPEN"
    );
  };

  claimSummary.args = {
    ...claimSummary.args,
    tableHeaders: claimSummaryTableHeaders,
    tableData: claimSummaryTableData,
  };

  pickupDetails.args = {
    ...pickupDetails.args,
    tableDisplayClasses: isOverageFlow ? "hidden" : "block",
    tableHeaders: pickupDetailsTableHeaders,
    tableData: pickupDetailsTableData,
    onRowDataClick: onTextClick,
  };

  btnConfirmPayment.args = {
    ...btnConfirmPayment.args,
    label: homeplusscript.btnContinue,
    onClick: (e) => onConfirmClick(),
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };
  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtTitle,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup,
  };

  txtClaimSummaryInfo.args = {
    ...txtClaimSummaryInfo.args,
    content: isOverageFlow
      ? homeplusscript.overageInfo
      : homeplusscript.claimSummaryInfo,
  };

  txtTotalCharge.args = {
    ...txtTotalCharge.args,
    content: isOverageFlow
      ? homeplusscript.labelOverage
      : homeplusscript.labelTotalCharge,
  };

  totalCharge.args = {
    ...totalCharge.args,
    content: homeplusscript.totalCharge.replace(
      "{'total'}",
      isOverageFlow
        ? replacementOption?.OverageAmount
        : ServiceFeeResponse?.TotalAmount
    ),
  };
  const screen = window.innerWidth > 767;
  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />
      <Popup
        contentStyle={screen ? contentStyleMobile : contentStyleDekstop}
        open={opencontactPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        repositionOnResize={false}
        modal
      >
        <ProvideContactNumber
          opencontactPopup={opencontactPopup}
          setContactPopup={setContactPopup}
          saveAlternateNumber={saveAlternateNumber}
        />
      </Popup>
    </>
  );
}

export default ClaimSummary;
