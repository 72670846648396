import React from 'react'
import { Stack } from '../stack/stack'
import { findById, getMetadata } from '../enrollment-workflow/helper';
import { WorkflowContext } from "../workflow/workflow-context";
import { useContext, useEffect, useState } from "react";

export const Footer = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['Footer']
  const homeplusscript = workflowState['config']?.scripts["Footer"];

    const termsOfUse = findById(metadata, "termsOfUse");
    const privacyPolicy = findById(metadata, "privacyPolicy");
    const contactUs = findById(metadata, "contactUs");
    const faq = findById(metadata, "txtFaq");
    const dataProPrivacyPolicy = findById(metadata, "dataProPrivacyPolicy");
    const txtLegalNotice = findById(metadata, "txtLegalNotice");
    const txtSmartSupport = findById(metadata, "txtSmartSupport");
    const textFooter = findById(metadata, "textFooter");

    const onContactClick = () => {
      return completeCurrentStep(currentStep, {
          OnContact: true,
        });
      }

    termsOfUse.args = {
        ...termsOfUse.args,
      onClick: (e)=>window.open("https://www.asurion.com.sg/eng/terms-of-use/")
    }
    privacyPolicy.args = {
        ...privacyPolicy.args,
      onClick: (e)=>window.open("https://www.asurion.com.sg/eng/privacy-policy/")
    }
    contactUs.args = {
      ...contactUs.args,
      onClick: onContactClick,
    };
    faq.args = {
      ...faq.args,
      onClick: (e) =>
      window.open("https://starhub.com/smartsupporthome-faq/")
    };
  
    dataProPrivacyPolicy.args = {
      ...dataProPrivacyPolicy.args,
      onClick: (e) =>
      window.open("https://www.libertyinsurance.com.sg/data-protection-policy")
    };
  
    txtLegalNotice.args = {
      ...txtLegalNotice.args,
      onClick: (e) =>
      window.open("https://www.starhub.com/about-us/legal-notices-and-terms.html")
    };

    txtSmartSupport.args = {
      ...txtSmartSupport.args,
      onClick: (e) =>
      window.open("http://starhub.com/smartsupporthome")
    };
    
    textFooter.args = {
      ...textFooter.args,
      content:homeplusscript.textFooter
    };

    return <Stack orientation='vertical' other="h-full" metadata={metadata} />
}

export default Footer