/**
 * all appsync config for chat
 */
 export const appSyncChatConfig = {
    twilioHost: process.env.REACT_APP_TWILIO_HOST,
    
    aws_project_region: process.env.REACT_APP_APPSYNC_REGION_CHAT,
    
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_CHAT,
    
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION_CHAT,
    
    aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE_CHAT,
    
    aws_cognito_identity_pool_id: process.env.REACT_APP_APPSYNC_IDENTITY_POOL_ID_CHAT,
    
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION_CHAT,
    
    aws_user_pools_web_client_id: process.env.REACT_APP_APPSYNC_POOLS_WEB_CLIENT_ID_CHAT,
    
    twilioTaskQueueSID: {
      DP: process.env.REACT_APP_TWILIO_TASKQUEUE_SID_DP,
      SOLUTO: process.env.REACT_APP_TWILIO_TASKQUEUE_SID_SOLUTO
    },

    skillIdSoluto: process.env.REACT_APP_SKILL_ID_SOLUTO,
    
    skillIdDP: process.env.REACT_APP_SKILL_ID_DP,
    
    skillIdSA: process.env.REACT_APP_SKILL_ID_SA,
    
    client: process.env.REACT_APP_CHAT_CLIENT_NAME,
  };
  
  /**
   * all file chat config
   */
//   export const fileChatConfig = {
//     downloadUrl:
//       process.env.REACT_APP_API_CHAT_BASE_URL +
//       process.env.REACT_APP_API_CHAT_FILE_DOWNLOAD_URL,
//     uploadUrl:
//       process.env.REACT_APP_API_CHAT_BASE_URL +
//       process.env.REACT_APP_API_CHAT_FILE_UPLOAD_URL,
//   };
  
//   export const contentfulConfig = {
//     discoveryContentUrl: process.env.REACT_APP_CONTENTFUL_CARD_WITH_IMAGE,
//     dailyTaskUrl: process.env.REACT_APP_CONTENTFUL_DAILY_TASK,
//     securityGuideUrl: process.env.REACT_APP_CONTENTFUL_SECUITY_GUIDE,
//     authToken: process.env.REACT_APP_CONTENTFUL_AUTH_TOKEN,
//   };
  