import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";

export const DeviceIssue = ({
  deviceIssue,
  onClick,
  itemClasses,
  itemBorderClasses,
  itemSelectedClasses,
  itemIconClasses,
  value,
  itemSelectedTextColorClasses,
  itemTextColorClasses,
  ...props
}) => {
  return (
    <div
      className={`${
        value?.issue === deviceIssue.issue
          ? itemSelectedClasses
          : itemBorderClasses
      }`}
      onClick={() => onClick(deviceIssue)}
    >
      <div className={itemClasses}>
        <Icon
          className={itemIconClasses}
          icon={deviceIssue.icon || "TV"}
        ></Icon>
        <Text
          colorClasses={`${
            value?.issue === deviceIssue.issue
              ? itemSelectedTextColorClasses
              : itemTextColorClasses
          }`}
          content={deviceIssue.issue}
        ></Text>
      </div>
    </div>
  );
};

DeviceIssue.propTypes = {
  deviceIssue: PropTypes.object,
  onClick: PropTypes.func,
  itemClasses: PropTypes.string,
  itemBorderClasses: PropTypes.string,
  itemIconClasses: PropTypes.string,
  value: PropTypes.object,
  itemSelectedClasses: PropTypes.string,
};

DeviceIssue.defaultProps = {
  onClick: undefined,
  deviceIssue: { icon: "TV", issue: "My device is malfunctioning" },
  itemClasses: "flex flex-row items-start",
  itemBorderClasses: "cursor-pointer border border-neutralN8 rounded-md p-2",
  itemIconClasses: "mr-2 mb-2",
  itemSelectedClasses:
    "cursor-pointer border border-primary rounded-md p-2 bg-light",
};
