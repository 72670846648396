import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import { DEVICE_CATEGORY } from "../../../utils/constants";

function AskPurchaseReceipt() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['AskPurchaseReceipt']
  const homeplusscript = workflowState['config']?.scripts["AskPurchaseReceipt"];

  const [selectedReceiptOption, setSelectedReceiptOption] = useState({});

  let purchaseReceiptOptions = findById(metadata, "purchaseReceiptOptions");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isTV = selectedCategory === DEVICE_CATEGORY.TV;
  const isGoogleTv = regDeviceDetails?.selectedBrandDetails?.Google_TV?.length >= 2;
  const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;
  const isLaptopDesktop =
    selectedCategory === DEVICE_CATEGORY.Laptop || selectedCategory === DEVICE_CATEGORY.Desktop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;
  const isWebcam = selectedCategory === DEVICE_CATEGORY.Webcam;

  const receiptOptions = [
    {
      value: homeplusscript.labelYes,
      label: homeplusscript.labelYes,
    },
    {
      value: homeplusscript.labelNo,
      label: homeplusscript.labelNo,
    },
  ];

  const onContinueClick = (receiptOption) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        hasReceipt: receiptOption.value === homeplusscript.labelYes,
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      providePurchaseDate: receiptOption.value === homeplusscript.labelNo,
      uploadPuchaseReceipt: receiptOption.value === homeplusscript.labelYes,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: isGoogleTv,
      GoBackScreenSize: (isTV) && !isGoogleTv,
      GoBackStorageType: isLaptopDesktop,
      GoBackConnectivityType: isSoundbar || isPrinter,
      GoBackDeviceModel: isTablet,
      GoBackBluetooth: isKeyboard || isMouse || isRouter,
      GoBackConnectivity:isWebcam || isMonitor
    });
  };

  const onReceiptOptionSelect = (receiptOption) => {
    setSelectedReceiptOption({ ...selectedReceiptOption, ...receiptOption });

    onContinueClick(receiptOption);
  };

  purchaseReceiptOptions.args = {
    ...purchaseReceiptOptions.args,
    deviceItems: receiptOptions,
    value: selectedReceiptOption?.value,
    onClick: onReceiptOptionSelect,
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"]
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default AskPurchaseReceipt;
