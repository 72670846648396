/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="33"
    height="11"
    viewBox="0 0 33 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.29837 0L6.80034 6.82561L6.53335 5.43889C5.92925 3.7826 4.75467 2.27131 3 1.25827L5.28289 10H7.98357L12 0H9.29837Z"
      fill="#0058A0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10L13.5383 0H16L14.4598 10H12Z"
      fill="#0058A0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 0.449445C23.4741 0.233007 22.6411 0 21.6123 0C18.9738 0 17.1201 1.44568 17.104 3.51375C17.0869 5.04952 18.4288 5.90181 19.4395 6.41235C20.4782 6.93429 20.8275 7.26878 20.8235 7.73583C20.8134 8.44624 19.9915 8.77142 19.2257 8.77142C18.1608 8.77142 17.5928 8.61297 16.7196 8.21324L16.3723 8.04547L16 10.4284C16.6233 10.7297 17.7734 10.9834 18.9728 11C21.7709 11 23.5915 9.56882 23.6126 7.35784C23.6256 6.14413 22.9131 5.22246 21.3715 4.46234C20.4431 3.9694 19.866 3.64216 19.8761 3.14197C19.8771 2.69977 20.3588 2.22651 21.4056 2.22651C22.2778 2.20891 22.903 2.41602 23.3998 2.63557L23.6387 2.7588L24 0.449445Z"
      fill="#0058A0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3125 6.45039C27.5452 5.87646 28.4234 3.6537 28.4234 3.6537C28.4045 3.68093 28.6477 3.07296 28.7923 2.69844L28.9778 3.56323C28.9778 3.56323 29.5133 5.95039 29.6233 6.45039H27.3125ZM30.7437 0H28.5942C27.9266 0 27.4278 0.179961 27.1323 0.830739L23 10H25.926C25.926 10 26.4039 8.76848 26.5097 8.49708C26.8315 8.49708 29.6684 8.50195 30.074 8.50195C30.161 8.85214 30.4156 10 30.4156 10H33L30.7437 0Z"
      fill="#0058A0"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="7"
      height="7"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6.15748V0.563477H6.4416V6.15748H0H0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.3086 0.563477H0.0236033L-0.000396729 0.740477C3.3296 1.59048 5.5276 3.68648 6.4416 6.15748L5.5116 1.43148C5.3516 0.777477 4.8846 0.586477 4.3086 0.563477Z"
        fill="#F9A51A"
      />
    </g>
  </svg>
);

export default icon;
