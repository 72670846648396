/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    width="32" 
    height="32"
     viewBox="0 0 32 32" 
     fill="none" 
     xmlns="http://www.w3.org/2000/svg"
  >
<path d="M27.5944 11.51C28.0544 11.51 28.4744 11.51 28.8944 11.51C30.3844 11.53 31.3044 12.72 30.9044 14.15C29.9044 17.72 28.8744 21.28 27.8544 24.84C27.5244 26 26.1444 27.21 24.6844 27.2C17.7444 27.16 10.7944 27.19 3.85444 27.17C3.44444 27.17 3.02444 27.08 2.61444 26.98C1.63444 26.74 1.07444 25.53 1.00444 24.65C0.994444 24.53 1.00444 24.4 1.00444 24.28C1.00444 18.5 1.00444 12.72 1.00444 6.94C1.00444 5.12 2.12444 4 3.94444 4C6.74444 4 9.53444 4 12.3344 4C13.4544 4 14.3144 4.45 14.8944 5.41C15.3544 6.16 15.8344 6.9 16.2744 7.65C16.4044 7.87 16.5544 7.94 16.8044 7.94C19.4444 7.94 22.0744 7.94 24.7144 7.94C26.4544 7.94 27.5944 9.08 27.5944 10.82C27.5944 11.03 27.5944 11.24 27.5944 11.5V11.51ZM18.1044 13C14.6044 13 11.0944 13.02 7.59444 12.98C6.73444 12.98 5.94444 13.64 5.73444 14.37C4.78444 17.8 3.79444 21.22 2.81444 24.64C2.62444 25.31 2.85444 25.62 3.54444 25.62C10.6344 25.62 17.7244 25.62 24.8144 25.62C25.5344 25.62 26.2344 25.12 26.4344 24.43C27.4444 20.92 28.4444 17.4 29.4444 13.88C29.6344 13.23 29.4444 12.99 28.7544 12.99C25.2044 12.99 21.6544 12.99 18.0944 12.99L18.1044 13ZM2.48444 20.2H2.54444C2.57444 20.11 2.60444 20.02 2.62444 19.93C3.19444 17.95 3.75444 15.96 4.32444 13.98C4.77444 12.4 5.96444 11.51 7.60444 11.51C13.6244 11.51 19.6344 11.51 25.6544 11.51H26.1044C26.1044 11.27 26.1044 11.09 26.1044 10.9C26.1044 9.88 25.6544 9.43 24.6344 9.43C21.7544 9.43 18.8644 9.43 15.9844 9.43C15.7144 9.43 15.5644 9.35 15.4244 9.12C14.8344 8.13 14.2144 7.16 13.6144 6.18C13.3244 5.7 12.9244 5.48 12.3544 5.48C9.52444 5.48 6.68444 5.48 3.85444 5.48C2.96444 5.48 2.48444 5.96 2.48444 6.86C2.48444 9 2.48444 11.14 2.48444 13.29C2.48444 15.59 2.48444 17.89 2.48444 20.2Z" fill="#1ED760"/>
<path d="M18.0944 12.99C21.6444 12.99 25.1944 12.99 28.7544 12.99C29.4444 12.99 29.6344 13.23 29.4444 13.88C28.4444 17.4 27.4444 20.91 26.4344 24.43C26.2344 25.12 25.5344 25.62 24.8144 25.62C17.7244 25.62 10.6344 25.62 3.54444 25.62C2.85444 25.62 2.62444 25.31 2.81444 24.64C3.79444 21.22 4.78444 17.8 5.73444 14.37C5.93444 13.63 6.72444 12.97 7.59444 12.98C11.0944 13.02 14.5944 13 18.1044 13L18.0944 12.99Z" fill="white"/>
<path d="M2.48444 20.2C2.48444 17.9 2.48444 15.6 2.48444 13.29C2.48444 11.15 2.48444 9.01001 2.48444 6.86001C2.48444 5.97001 2.96444 5.48001 3.85444 5.48001C6.68444 5.48001 9.52444 5.48001 12.3544 5.48001C12.9144 5.48001 13.3244 5.70001 13.6144 6.18001C14.2144 7.16001 14.8344 8.13001 15.4244 9.12001C15.5644 9.35001 15.7144 9.43001 15.9844 9.43001C18.8644 9.42001 21.7544 9.43001 24.6344 9.43001C25.6544 9.43001 26.0944 9.88001 26.1044 10.9C26.1044 11.08 26.1044 11.27 26.1044 11.51H25.6544C19.6344 11.51 13.6244 11.51 7.60444 11.51C5.96444 11.51 4.77444 12.4 4.32444 13.98C3.75444 15.96 3.18444 17.95 2.62444 19.93C2.59444 20.02 2.56444 20.11 2.54444 20.2H2.48444Z" fill="#E8FBEF"/>

  </svg>
);

export default icon;
