import { useContext, useState } from "react";
import { Stack } from "../../stack/stack";
import { findById } from "./../helper";
import { WorkflowContext } from "../../workflow/workflow-context";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";
import { useEffect } from "react";
import Popup from "reactjs-popup";
import WishNotProceedPopup from "../wishProceedPopup";
import { DEVICE_CATEGORY, FULFILMENT_OPTION_OVERAGE } from "../../../utils/constants";
import logger from "../../../logger.service";

function ReplacementOptions() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const [openWishNotProceedPopup, setWishNotProceedPopup] = useState(false);
  const contentStyle = {
    padding: "15px",
    width: "375px",
    height: "219px",
    margin: "auto",
    background: "white",
    position: "relative",
    bottom: "0",
    border: "2px groove ",
    borderRadius: "5px",
    borderColor: "",
    textColor: "",
  };
  const [replacementOptions, setReplacementOptions] = useState([]);

  const metadata = workflowState['config']?.metadata['ReplacementOptions']
  const homeplusscript = workflowState['config']?.scripts["ReplacementOptions"];

  let txtTitle = findById(metadata, "txtTitle");
  let optionList = findById(metadata, "optionList");
  let btnGoback = findById(metadata, "btnGoback");
  let cancelClaim = findById(metadata, "cancelClaim");
  let menu = findById(metadata, "menu");
  let txtRegisteredDevice = findById(metadata, "txtRegisteredDevice");
  let txtInfo = findById(metadata, "txtInfo");

  const showPopup = () => {
    setWishNotProceedPopup(!openWishNotProceedPopup);
  };

  useEffect(() => {
    getReplacementOptions();
  }, []);

  const getReplacementOptions = () => {
    showLoader(true);
    const ReplacementMatrixParameter = {
      ReplacementMatrixParameter: {
        CustomerCaseNo: String(fileClaimDetails?.ServiceRequestResponse?.CustomerCaseNumber),
      },
    };

    API[ActionTypes.GET_REPLACEMENT_OPTIONS](ReplacementMatrixParameter)
      .then((data) => {
        showLoader(false);
        const ReplacementMatrixResults = data.data.ReplacementMatrixResults;
        updateState({
          ...workflowState,
          ReplacementMatrixResults,
        });

        displayOptions(ReplacementMatrixResults);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_REPLACEMENT_OPTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const displayOptions = (optionList) => {
    let options = [];

    optionList.map((item) => {
      let option = {
        labelDevice: homeplusscript.labelDevice,
        labelModel: homeplusscript.labelModel,
        labelFee: homeplusscript.labelFee,
        labelBtn: homeplusscript.labelBtn,
        ...item,
      };
      options.push(option);
    });

    setReplacementOptions(options);
  };

  const onOptionSelect = (item) => {

    const isTVRepairWithOverage =
      item?.AssetCategoryName === DEVICE_CATEGORY.TV &&
      item?.FulfillmentOption?.toUpperCase() ===
        FULFILMENT_OPTION_OVERAGE.REPAIR_OVERAGE;

    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        selectedReplacementOption: item,
        isOverageFlow : true,
        isTVRepairWithOverage
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      showOverageSummary: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  optionList.args = {
    ...optionList.args,
    options: replacementOptions,
    onOptionSelect: onOptionSelect
  };

  btnGoback.args = {
    ...btnGoback.args,
    label: homeplusscript.btnGoback,
    onClick: (e) => onGoBackClick(),
  };

  cancelClaim.args = {
    ...cancelClaim.args,
    onClick: showPopup, 
    label: homeplusscript.cancelClaim,
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.txtHeader
  };
  
  txtRegisteredDevice.args = {
    ...txtRegisteredDevice.args,
    content: fileClaimDetails?.selectedDevice?.AssetCatalog?.AssetCatalogName
  };

  txtInfo.args = {
    ...txtInfo.args,
    content: homeplusscript.txtInfo
  }

  return <>
  <Stack orientation="vertical" metadata={metadata} />
  <Popup
    contentStyle={contentStyle}
    open={openWishNotProceedPopup}
    closeOnDocumentClick={false}
    closeOnEscape={false}
    repositionOnResize={false}
    modal
  >
    <WishNotProceedPopup
      openWishNotProceedPopup={openWishNotProceedPopup}
      setWishNotProceedPopup={setWishNotProceedPopup}
    />
  </Popup>
</>
}

export default ReplacementOptions;
