import { entity, persistence } from 'simpler-state';
import { ChatManager } from '../ChatManager';

export const chatRequestIdEntity = entity(null, [
  persistence('chatRequestId', { storage: 'session' }),
]);

export const chatConversationIdEntity = entity(null, [
  persistence('conversationId', { storage: 'session' }),
]);

export const chatVisitorIdEntity = entity(null, [
  persistence('visitorId', { storage: 'session' }),
]);

export const hasActiveChatEntity = entity(null, [
  persistence('hasActiveChat', { storage: 'session' }),
]);

export const chatTypeEntity = entity(null, [
  persistence('chatType', { storage: 'session' }),
]);

export const endChatActivity = () => {
  chatRequestIdEntity.set(null);
  chatConversationIdEntity.set(null);
  chatVisitorIdEntity.set(null);
  hasActiveChatEntity.set(null);
  chatTypeEntity.set(null);
};

export const setChatRequestIdEntity = (requestId) => {
  chatRequestIdEntity.set(requestId);
};

export const setChatVisitorIdEntity = (visitorId) => {
  chatVisitorIdEntity.set(visitorId);
};

export const setHasActiveChatEntity = (hasActiveChat) => {
  hasActiveChatEntity.set(hasActiveChat);
};

export const setChatTypeEntity = (chatType) => {
  chatTypeEntity.set(chatType);
}

export const getChatSession = (chatRequestId, appSyncConfig) => {
  let tempChatRequestId = ChatManager.uuidv4().toUpperCase();
  let tempChatVisitorId = ChatManager.uuidv4().toUpperCase();
  let conversationId = null;
  let hasActiveChat = null;
  let setNewIds = () => {
    hasActiveChatEntity.set(false);
    chatRequestIdEntity.set(tempChatRequestId);
    chatConversationIdEntity.set(tempChatVisitorId);
  };

  if (!!chatRequestId) {
    //check if request exists and is accepted
    ChatManager.getChatRequest(chatRequestId).then((data) => {
      //check if conversation exists
      //set all data
      if (
        data?.data?.getEncryptedChatRequest?.requestId &&
        data?.data?.getEncryptedChatRequest &&
        (data.data.getEncryptedChatRequest.requestStatus === 'Interacting' ||
          data.data.getEncryptedChatRequest.requestStatus === 'Accepted')
      ) {
        ChatManager.getConversationByRequestId(
          data.data.getEncryptedChatRequest.requestId
        ).then((result) => {
          if (result?.data?.getConversation?.conversationId) {
            hasActiveChatEntity.set(true);
            chatRequestIdEntity.set(chatRequestId);
            chatConversationIdEntity.set(result.data.getConversation.conversationId);
          } else {
            setNewIds();
          }
        });
      } else {
        setNewIds();
      }
    });
  } else {
    setNewIds();
  }
  return { hasActiveChat, chatRequestId, conversationId };
};
