import "./style.css";
import EnrollmentWorkflow from "./components/enrollment-workflow/enrollment-workflow";
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { MemoryRouter } from "react-router";
import { MetadataProvider } from './metadata-context'
import { ControlLocator } from './control-locator'
import TagManager from "react-gtm-module";
import { Config } from "./components/enrollment-workflow/config";

const tagManagerArgs = {
  gtmId:Config.GTM.gtmId,
};

function App() {

  TagManager.initialize(tagManagerArgs);

  return (
    <MetadataProvider controlLocator={ControlLocator}>
      <MemoryRouter>
        <EnrollmentWorkflow />
      </MemoryRouter>
    </MetadataProvider>
  );
}

export default App;
