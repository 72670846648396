export const Config = {
  cognito: {
    region: process.env.REACT_APP_REGION,    
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  service: {
    baseUrl: process.env.REACT_APP_SLS_BASE_ENDPOINT,
    apiKey: process.env.REACT_APP_SLS_API_KEY,
    securityAdvisor: process.env.REACT_APP_SLS_BASE_ENDPOINT+"securityadvisor",
  },
  GTM: {
    gtmId: process.env.REACT_APP_GTM_ID
  }
}
