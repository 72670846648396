import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconCollapse from "../../icons/ic_collapse";
import IconExpand from "../../icons/ic_expand";
import { Image } from "../image/image";
import Icon from "../icon/icon";
import { Button } from "../button/button";
import Table from "../table/table";
import { Text } from "../text/text";

export const ClaimForRepair = ({
  expanded,
  setIsOpen,
  itemIconClasses,
  borderClasses,
  fontClasses,
  btnDisplayClasses,
  claimDetails,
  subItemClasses,
  itemTextClasses,
  itemSubTextClasses,
  itemClasses,
  btnColorClasses,
  itemTextColorClasses,
  lineItemClasses,
  onClick,
  enabled,
  showDescription,
  claimTitleClasses,
  iconTitleDisplayClasses,
  separatorClasses,
  txtClasses,
  subDivClasses,
  subDisplayClasses,
  subDisplayItem,
  tableClasses,
  statusTextClasses,
  statusClasses,
  statusMainClass,
  ...props
}) => {
  
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);
  
  const [isOpen, setIsOpenn] = useState(claimDetails.expanded || expanded);
  const toggleExpanded = () => {
    setIsOpenn(!isOpen);
  };

  const titleStyle = "text-xl text-neutral";

  return (
   <div className={`${borderClasses}`}>
      <div className={`${subItemClasses}`}>
        <div
          className={subDivClasses}
          onClick={toggleExpanded}
        >
          <div className={`${iconTitleDisplayClasses} ${fontClasses}`}>
            <div>
            <Icon className={itemIconClasses} icon={claimDetails.icon}></Icon>
            </div>
            <div>
            <span className={`text-sm ${claimTitleClasses}`}>
              {claimDetails.title}
            </span>
            </div>
          </div>
          <span
            className="inline-block mx-2 cursor-pointer"
            // onClick={toggleExpanded}
          >
            {claimDetails.showExpandableView &&
              (!isOpen ? (
                <Image image={IconExpand} className="fill-primary" fill={"primary"}/>
              ) : (
                <Image image={IconCollapse} className="fill-primary" fill={"primary"}/>
              ))}
          </span>
        </div>
        <div>
       <hr className={separatorClasses}></hr>
        </div>
        <div className={subDisplayClasses}>
        <div className={subDisplayItem}>
        <Table
          tableDivClasses={tableClasses.tableDivClasses}
          tableClasses={tableClasses.tableClasses}
          trClasses={tableClasses.trClasses}
          thClasses={tableClasses.thClasses}
          tdClasses={tableClasses.tdClasses}
          tableTextClasses={tableClasses.tableTextClasses}
          tableHeaders={isDesktop ? claimDetails.claimDetailsTableHeaders.filter((val)=>val.key !== "claimStatus") : claimDetails.claimDetailsTableHeaders}
          tableData={claimDetails.claimDetailsTableData}
          dataType="myClaimForRepair"
        ></Table>
        </div>

        {isOpen && (
          <>
            {claimDetails.showExpandableView && (
              <div className={`${itemTextClasses}`}> 
              {isDesktop && <div className={statusMainClass}>
              <Text content={"Status"} size={"sm"} other={statusTextClasses}></Text>
              <Text content={claimDetails?.claimDetailsTableData[0]?.claimStatus} size={"sm"} other={statusClasses}></Text>
              </div>}
                <div className={`${itemSubTextClasses}`}>
                  {claimDetails.description}
                </div>
                <Button
                  displayClasses={
                    claimDetails.btnDisplayClasses || btnDisplayClasses
                  }
                  colorClasses={`${btnColorClasses}`}
                  label={claimDetails?.btnLabel}
                  onClick={() => onClick(claimDetails)}
                  enabled={enabled}
                />
              </div>
            )}
          </>
        )}
        </div>
      </div>
    </div>
  );
};

ClaimForRepair.propTypes = {
  expanded: PropTypes.bool,
  setIsOpen: PropTypes.func,
  value: PropTypes.array,
  claimDetails: PropTypes.object,
  subItemClasses: PropTypes.string,
  itemTextClasses: PropTypes.string,
  itemSubTextClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  btnColorClasses: PropTypes.string,
  itemTextColorClasses: PropTypes.string,
  lineItemClasses: PropTypes.string,
  separatorClasses: PropTypes.string,
  showDescription: PropTypes.bool,
  onClick: PropTypes.func
};

ClaimForRepair.defaultProps = {
  expanded: false,
  setIsOpen: undefined,
  fontClasses: " mb-2 text-lg font-semibold text-neutralN2",
  borderClasses:
    "h-auto w-full px-3 py-3 bg-white border rounded shadow-sm border-neutralN8",
  itemIconClasses: "mr-2.5 mt-1.5 w-[31px] h-[21.3] inline",
  itemTextClasses: "mt-2 ml-8",
  itemSubTextClasses: "mb-1 text-sm",
  itemClasses: "flex flex-row ml-9",
  subItemClasses: "flex flex-col",
  btnColorClasses:
    "bg-primary disabled:bg-neutralN9 focus:bg-secondaryS5 hover:bg-secondaryS5 active:bg-additional text-neutral active:shadow-lg transition duration-150 ease-in-out",
  itemTextColorClasses: "text-accentA3",
  lineItemClasses: "font-bold mr-1",
  enabled: true,
  onClick: undefined,
  separatorClasses: "w-full mb-1 px-2",
  subDivClasses:"flex flex-row items-center justify-between",
  subDisplayClasses:"",
  subDisplayItem:"",
  statusMainClass:"pt-1.5 mb-2 pb-0.5",

  claimDetails: {
    icon: "TV",
    title: "LG LCD TV 55",
    keyLabelPolicy: "Policy No.",
    keyLabelClaim: "Claim Type",
    keyLabelStatus: "Status",
    valueLabelPolicy: "46600100000207",
    valueLabelClaim: "Repair",
    valueLabelStatus: "Claim submitted",
    description:
      "You have successfully submitted your claim and we will update you on type of resolution via email within X business day.",
    btnLabel: "Cancel claim",
  },
};
