import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ExcellentRatingIcon from "./assets/ExcellentRatingIcon.svg";
import HappyRatingIcon from "./assets/HappyRatingIcon.svg";
import NeutralRatingIcon from "./assets/NeutralRatingIcon.svg";
import PoorRatingIcon from "./assets/PoorRatingIcon.svg";
import SadRatingIcon from "./assets/SadRatingIcon.svg";
import { Colors } from "./entities/Colors";

export const CSATWidget = ({ surveyQuestion, onSubmitHandler }) => {
  const ratings = [
    {
      icon: PoorRatingIcon,
      label: "Very Dissatisfied",
      score: 1,
    },
    {
      icon: SadRatingIcon,
      label: "Dissatisfied",
      score: 2,
    },
    {
      icon: NeutralRatingIcon,
      label: "Neutral",
      score: 3,
    },
    {
      icon: HappyRatingIcon,
      label: "Satisfied",
      score: 4,
    },
    {
      icon: ExcellentRatingIcon,
      label: "Very Satisfied",
      score: 5,
    },
  ];

  const classes = CSATStyles();
  const [rating, setRating] = useState("");

  return (
    <Box className={classes.root}>
      <Typography align="center">{surveyQuestion}</Typography>
      <img
        src={rating.icon}
        alt={rating.label}
        width={50}
        className={classes.active}
      />
      <Typography
        align="center"
        style={{ color: Colors.PRIMARY_CONTRAST_TEXT, fontSize: "18px" }}
      >
        <Box component="span" fontWeight={700}>
          {rating.label}
        </Box>
      </Typography>
      <div className={classes.ratingContainer}>
        {ratings.map((inputRating, i) => {
          return (
            <div
              className={
                rating && rating.label === inputRating.label
                  ? classes.active
                  : ""
              }
              onClick={() => setRating(inputRating)}
              key={`csat-rating-${i}`}
            >
              <img
                src={inputRating.icon}
                alt={inputRating.label}
                width={41.67}
              />
            </div>
          );
        })}
      </div>

      <Button
        variant="contained"
        color={"primary"}
        disabled={!rating}
        onClick={() => onSubmitHandler(rating.score)}
      >
        Submit
      </Button>
    </Box>
  );
};
const CSATStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95vw",
    width: "95%",
    margin: "0 auto",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    padding: "15px 0px",
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
    border: `1px solid ${Colors.DARK_GRAY}`,
    "& p": {
      lineHeight: "22px",
    },
    "& button": {
      borderRadius: "4px",
      minWidth: "88px",
      height: "44px",
      fontWeight: 500,
    },
  },
  ratingContainer: {
    display: "flex",
    flexFlow: "row wrap",
    gap: "11px",
    paddingBottom: "17px",
    "& div": {
      cursor: "pointer",
    },
  },
  active: {
    filter: `invert(67%) sepia(86%) saturate(4194%) hue-rotate(104deg) brightness(120%) contrast(76%)`,
  },
}));
