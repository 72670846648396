import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
// import { DEVICE_CATEGORY } from "../../../utils/constants";

function CantRegisterDevice() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['CantRegisterDevice']
  const homeplusscript = workflowState['config']?.scripts["CantRegisterDevice"];

  // const agreement =
  //   workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  // const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;

  let titleText = findById(metadata, "txtTitle");
  let btnAddDevice = findById(metadata, "btnAddDevice");
  let menu = findById(metadata, "menu");

  const addAnotherDevice = () => {
    // clear all device reg data
    updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      // for next step
      addAnotherDevice: true,
    });
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title
    // isSoundbar
    //   ? homeplusscript.titleSoundbar.replace(
    //       "[PROGRAM NAME]",
    //       agreement?.ClientOffer?.ClientOfferName
    //     )
    //   : homeplusscript.title
    //       .replaceAll(
    //         "{'deviceCategory'}",
    //         regDeviceDetails?.selectedCategory?.type
    //       )
    //       .replace("[PROGRAM NAME]", agreement?.ClientOffer?.ClientOfferName),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
  };

  btnAddDevice.args = {
    ...btnAddDevice.args,
    label: homeplusscript.addAnotherDevice,
    onClick: (e) => addAnotherDevice(),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default CantRegisterDevice;
