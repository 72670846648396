import React from "react";
import PropTypes from "prop-types";
import { EnrolledDevice } from "../enrolled-device/enrolled-device";
import { Text } from "../text/text";
import { DeviceIssue } from "../device-issue/device-issue";
import { DeviceItems } from "../device-items/device-items";
// import { Image } from '../image/image';
import{ClaimForRepair} from "../claim-for-repair/claim-for-repair";
import {OptionPage} from "../options/option-pages";
import { Note } from "../note/note";
import { Expandable } from "../expandable/expandable";
import { TriageQuestion } from "../triage-question/triage-question";
import { PolicyDocuments } from "../myDoc-policy/policyDocuments";
import { DeviceCategory } from "../device-category/device-category";
import Icon from "../icon/icon";
import { ClaimHistoryItem } from "../claim-history/claim-history-item";
import { PolicyHeader } from "../myDoc-policy/policyHeader";
import {TriageQuestionDesktop} from "../triage-question-desktop/triage-question-desktop";

export const List = ({
  orientation,
  listStyle,
  options,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  itemClasses,
  itemBorderClasses,
  itemSelectedClasses,
  verticalClasses,
  horizontalClasses,
  image,
  onListItemSelect,
  itemType,
  value,
  itemSelectedTextColorClasses,
  itemTextColorClasses,
  itemIconClasses,
  itemColorClasses,
  itemPaddingClasses,
  onClick,
  others,
  itemTextClasses,
  itemSubTextClasses,
  subItemClasses,
  btnColorClasses,
  btnDisplayClasses,
  setOtherAnswer,
  otherAnswer,
  otherListClasses,
  claimTitleClasses,
  labelClasses,
  iconClasses,
  mainDivClasses,
  labelFontClasses,
  subDivClasses,
  iconTitleDisplayClasses,
  leftArrowIconClass,
  rightArrowIconClass,
  type,
  itemMainDivClasses,
  txtClasses,
  isLoading,
  mainIconClasses,
  subDisplayClasses,
  subDisplayItem,
  separatorClasses,
  tableClasses,
  statusTextClasses,
  statusClasses,
  statusMainClass,
  expanded,
  subLabelClasses,
  subIconClasses,
  mainDivClass,
  fontClass,
  ...props
}) => {
  const getClassName = (options, index) => {
    if(options?.length === 1)
      return 'lg:mx-auto'
    else if(index === 0)
      return 'lg:ml-auto'
    else if(index === options?.length - 1)
      return "lg:mr-auto"
    else
      return "";

  }
  const scroll = (direction) => {
    if (direction === "left")
      document.getElementById("options").scrollLeft -= 150;
    else if (direction === "right")
      document.getElementById("options").scrollLeft += 150;
  };

  const _listStyle = listStyle ? `list-${listStyle}` : image ? "" : "list-disc";
  return (
    <div
      className={`${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses} ${others}`}
    >
      <ul
        id="options"
        className={`flex ${_listStyle} ${
          orientation === "vertical" ? verticalClasses : horizontalClasses
        } ${otherListClasses}`}
      >
        {options?.map((item, index) => {
            if (itemType === "EnrolledDevice")
            return (
              <EnrolledDevice
                enrolledDevice={item}
                onDeviceSelect={onListItemSelect}
                itemClasses={itemClasses}
                itemBorderClasses={itemBorderClasses}
                itemSelectedClasses={itemSelectedClasses}
                itemSelectedTextColorClasses={itemSelectedTextColorClasses}
                itemTextColorClasses={itemTextColorClasses}
                itemIconClasses={itemIconClasses}
                value={value}
              ></EnrolledDevice>
            );
          else if (itemType === "DeviceCategory")
          return (
            <div className={getClassName(options, index)}>
              <DeviceCategory
                deviceCategory={item}
                onDeviceCategorySelect={onListItemSelect}
                itemClasses={itemClasses}
                itemBorderClasses={itemBorderClasses}
                itemSelectedClasses={itemSelectedClasses}
                itemSelectedTextColorClasses={itemSelectedTextColorClasses}
                itemTextColorClasses={itemTextColorClasses}
                itemIconClasses={itemIconClasses}
                value={value}
              ></DeviceCategory>
            </div>
          );
          else if (itemType === "DeviceIssue")
            return (
              <DeviceIssue
                deviceIssue={item}
                value= {value}
                onClick={onListItemSelect}
                itemClasses={itemClasses}
                itemBorderClasses={itemBorderClasses}
                itemSelectedClasses={itemSelectedClasses}
                itemSelectedTextColorClasses={itemSelectedTextColorClasses}
                itemTextColorClasses={itemTextColorClasses}
              ></DeviceIssue>
            );
            else if (itemType === "DeviceItems")
            return (
              <DeviceItems
                borderClasses={itemBorderClasses}
                paddingClasses={itemPaddingClasses}
                itemClasses={itemClasses}
                colorClasses={itemColorClasses}
                deviceItems={item.answers}
                label={item.question}
                onClick={() => {
                  onClick(item, item.answers.value)}}
              ></DeviceItems>
            );
            else 
            if (itemType === "ClaimForRepair")
            return (
              <div className="w-full py-3">
              <ClaimForRepair
                claimDetails={item}
                borderClasses={borderClasses}
                itemIconClasses={itemIconClasses}
                itemTextClasses={itemTextClasses}
                itemSubTextClasses={itemSubTextClasses}
                itemClasses={itemClasses}
                subItemClasses={subItemClasses}
                btnColorClasses={btnColorClasses}
                btnDisplayClasses={btnDisplayClasses}
                onClick = {onClick}
                claimTitleClasses = {claimTitleClasses}
                iconTitleDisplayClasses = {iconTitleDisplayClasses}
                subDivClasses = {subDivClasses}
                subDisplayClasses = {subDisplayClasses}
                subDisplayItem = {subDisplayItem}
                separatorClasses = {separatorClasses}
                tableClasses = {tableClasses}
                statusTextClasses = {statusTextClasses}
                statusClasses = {statusClasses}
                statusMainClass = {statusMainClass}
              />
              </div>
            );
            else 
            if (itemType === "OptionPage")
            return (
              <OptionPage
                optionContent={item}
                borderClasses={borderClasses}
                itemTextClasses={itemTextClasses}
                itemSubTextClasses={itemSubTextClasses}
                itemClasses={itemClasses}
              />
            );
          else if (itemType === "Note")
          return (
            <Note
              note={item}
              itemClasses={itemClasses}
              itemTextColorClasses={itemTextColorClasses}
              itemIconClasses={itemIconClasses}
            />
          )
          else if (itemType === "Expandable")
          return (
            <Expandable
              item={item}
              onDeviceSelect={onListItemSelect}
              titleClasses={itemTextClasses}
              mainDivClasses={itemClasses}
              itemIconClasses={itemIconClasses}
              incidentPathType={props?.incidentPathType}
              linkText={props?.linkText}
              notEligibleText={props?.notEligibleText}
              onResumeClick={props?.onResumeClick}
              index={index}
              />
          )
          else if (itemType === "TriageQuestion")
          return (
            <TriageQuestion
              triageQuestion={item}
              dimensionClasses={dimensionClasses}
              paddingClasses={paddingClasses}
              onClick={onClick}
              setOtherAnswer={setOtherAnswer}
            />
          )
          else if (itemType === "PolicyDocuments")
          return (
            <PolicyDocuments
              item={item}
              onClick={onClick}
              labelClasses={labelClasses}
              iconClasses={iconClasses}
              mainDivClasses={mainDivClasses}
              fontClasses={fontClasses}
              labelFontClasses={labelFontClasses}
              subDivClasses={subDivClasses}
              borderClasses={borderClasses}
              mainDivClass={mainDivClass}
              subLabelClasses={subLabelClasses}
              subIconClasses={subIconClasses}
              fontClass={fontClass}
            />
          )
          else if(itemType === "ClaimHistoryItem")
            return(
              <ClaimHistoryItem
                item={item}
                labelClasses={labelClasses}
                mainDivClasses={mainDivClasses}
                fontClasses={fontClasses}
                type={type}
                itemMainDivClasses={itemMainDivClasses}
                mainIconClasses={mainIconClasses}
              />
            )
          else if(itemType === "PolicyHeader")
            return(
              <PolicyHeader
                item={item}
                mainDivClasses={mainDivClasses}
                iconClasses={iconClasses}
                labelClasses={labelClasses}
                fontClasses={fontClasses}
                expanded={expanded}
                borderClasses={borderClasses}
                subLabelClasses={subLabelClasses}
                subIconClasses={subIconClasses}
                mainDivClass={mainDivClass}
                fontClass={fontClass}
                labelFontClasses={labelFontClasses}
                subDivClasses={subDivClasses}
              />
            )
            else if (itemType === "TriageQuestionDesktop")
            {console.log(item);
            return (
              <TriageQuestionDesktop
                triageQuestion={item}
                dimensionClasses={dimensionClasses}
                paddingClasses={paddingClasses}
                onClick={onClick}
                setOtherAnswer={setOtherAnswer}
                itemTitleClasses={"flex justify-center m-4 text-base"}
              />
            )  }
          else return <Text></Text>;
        })}
      </ul>

      <Icon
        className={`${leftArrowIconClass} ${
          itemType === "DeviceCategory" && options?.length > 6 ? "lg:block hidden" : "hidden"
        }`}
        icon="IconExpand"
        fill={"primary"}
        onClick={(e) => scroll("left")}
      ></Icon>
      <Icon
        className={`${rightArrowIconClass} ${
          itemType === "DeviceCategory" && options?.length > 6 ? "lg:block hidden" : "hidden"
        }`}
        icon="IconCollapse"
        fill={"primary"}
        onClick={(e) => scroll("right")}
      ></Icon>
       { itemType === "ClaimForRepair" && options?.length == 0 &&
       <Text
          content={isLoading ? "Loading ongoing claims..." : "No ongoing claims."}
          size={"md"}
          other={txtClasses}
        ></Text>
        }
    

    </div>
  );
};

List.propTypes = {
  /**
   * Orientation describes how list items will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  listStyle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  itemBorderClasses: PropTypes.string,
  itemSelectedClasses: PropTypes.string,
  verticalClasses: PropTypes.string,
  horizontalClasses: PropTypes.string,
  image: PropTypes.func,
  onListItemSelect: PropTypes.func,
  itemType: PropTypes.string,
  itemColorClasses: PropTypes.string,
  itemPaddingClasses: PropTypes.string,
  onClick: PropTypes.func,
  others: PropTypes.string,
};

List.defaultProps = {
  orientation: "vertical",
  marginClasses: "mb-5",
  fontClasses: "text-sm",
  image: undefined,
  onListItemSelect: undefined,
  verticalClasses: "flex-col",
  horizontalClasses: "grid grid-flow-col grid-rows-2 gap-4 mt-4",
  itemType: "EnrolledDevice",
  onClick: undefined,
  txtClasses:"flex justify-center text-neutralN4 lg:mt-12 lg:mb-12 mb-10 mt-5",
};