import { Auth } from 'aws-amplify';

export class AuthService {

    cognitoUser = { challengeParam: { email: "" } };

    async signIn(email) {
        this.cognitoUser = await Auth.signIn(email);
        return this.cognitoUser;
    }

    async signOut() {
        await Auth.signOut();
    }

    async answerCustomChallenge(answer) {
        try {
            this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer);
        } catch (error) {
            if (error.code === 'NotAuthorizedException') {
                throw {
                    code: "NotAuthorizedException",
                    message: "OTP Expired. Please resend code and try again.",
                    name: "NotAuthorizedException"
                }
            }
        }

        if (this.cognitoUser?.signInUserSession) {
            return await Auth.currentSession();
        } else {
            throw {
                code: "IncorrectOTP",
                message: "Entered OTP is incorrect. Please try again.",
                name: "IncorrectOTP"
            }
        }
    }

    async getPublicChallengeParameters() {
        return this.cognitoUser.challengeParam;
    }

    async signUp(email, fullName) {
        const params = {
            username: email,
            password: this.getRandomString(30),
            attributes: {
                name: fullName
            }
        };
        // await Auth.signUp(params);
        return Auth.signUp(params);
    }

    getRandomString(bytes) {
        const randomValues = new Uint8Array(bytes);
        window.crypto.getRandomValues(randomValues);
        return Array.from(randomValues).map(this.intToHex).join('');
    }

    intToHex(nr) {
        return nr.toString(16).padStart(2, '0');
    }

    async isAuthenticated() {
        return await Auth.currentSession();
    }

    async getUserDetails() {
        if (!this.cognitoUser) {
            this.cognitoUser = await Auth.currentAuthenticatedUser();
        }
        return await Auth.userAttributes(this.cognitoUser);
    }

}
