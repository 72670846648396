import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { LoaderStyles } from './LoaderStyles';

// export interface Props {
//   loadingText?: string;
//   rootClass?: string;
//   loaderClass?: string;
//   size?: number;
// }

export const Loader = ({
  rootClass = '',
  loaderClass = '',
  loadingText = '',
  size = 40,
}) => {
  const classes = LoaderStyles();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={[classes.root, rootClass].join(' ')}>
      <CircularProgress
        className={[classes.loader, loaderClass].join(' ')}
        style={{ width: `${size}px`, height: `${size}px` }}
      />

      {loadingText !== '' || loadingText !== null ? (
        <div className={classes.loaderText}>
          <Typography variant="caption">{loadingText}</Typography>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default Loader;
