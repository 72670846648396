/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.40946 6.50625L13.2076 1.71349C13.5975 1.32326 13.5975 0.6929 13.2076 0.302675C12.8178 -0.0875505 12.188 -0.0875505 11.7982 0.302675L7 5.09544L2.21184 0.292669C1.82199 -0.0975563 1.18224 -0.0975563 0.792388 0.292669C0.402537 0.682894 0.402537 1.32326 0.792388 1.71349L5.59054 6.50625L0.792388 11.299C0.402537 11.6892 0.402537 12.3196 0.792388 12.7098C0.992311 12.9099 1.24221 13 1.50211 13C1.76201 13 2.01192 12.8999 2.21184 12.7098L7 7.91707L11.7882 12.7098C11.9881 12.9099 12.238 13 12.4979 13C12.7578 13 13.0077 12.8999 13.2076 12.7098C13.5975 12.3196 13.5975 11.6892 13.2076 11.299L8.40946 6.50625Z"
      fill="#575757"
    />
  </svg>
);

export default icon;
