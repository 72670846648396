import React from "react";
import PropTypes from "prop-types";
import { Text } from "../text/text";
import Icon from "../icon/icon";
import { useState } from "react";
import { List } from "../list/list";
export const PolicyHeader = ({
  label,
  labelClasses,
  mainDivClasses,
  icon,
  iconClasses,
  fontClasses,
  expanded,
  item,
  borderClasses,
  subLabelClasses,
  subIconClasses,
  mainDivClass,
  fontClass,
  labelFontClasses,
  subDivClasses,
  ...props
}) => {
  const [open, setOpen] = useState(expanded);

  const handleExpand = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className={mainDivClasses} onClick={handleExpand}>
        <Icon icon={item.icon} className={iconClasses}></Icon>
        <span className={labelClasses}>
          <Text content={item.label} other={fontClasses}></Text>
          <span className="my-auto">
            {!open ? (
              <Icon
                className="fill-primary"
                icon="IconExpand"
                fill={"primary"}
              ></Icon>
            ) : (
              <Icon
                className="fill-primary"
                icon="IconCollapse"
                fill={"primary"}
              ></Icon>
            )}
          </span>
        </span>
      </div>
      {open && (
        <List
          itemType="PolicyDocuments"
          options={item.subData}
          borderClasses={borderClasses}
          subLabelClasses={subLabelClasses}
          subIconClasses={subIconClasses}
          mainDivClass={mainDivClass}
          fontClass={fontClass}
          labelFontClasses={labelFontClasses}
          subDivClasses={subDivClasses}
        />
      )}
    </>
  );
};

PolicyHeader.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  labelClasses: PropTypes.string,
  iconClasses: PropTypes.string,
};

PolicyHeader.defaultProps = {
  label: "Policy",
  icon: "Folder",
  labelClasses: "my-5",
  iconClasses: "",
  mainDivClasses: "flex flex-row items-start mt-6",
  fontClasses: "",
};
