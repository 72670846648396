import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getContactEmail, getContactNumber, getMetadata, isEmpty } from "../helper";
import { GTM_EVENT, INCEDENT_TYPES } from "../../../utils/constants";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";

import logger from "../../../logger.service";
import { logGTMEvent } from "../api-helper";

const SelectIncidentType = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectIncidentType']
  const homeplusscript = workflowState['config']?.scripts["SelectIncidentType"];

  const [otherIssue, setOtherIssue] = useState("");
  const [showLengthError, setShowLengthError] = useState(true);

  let txtTitle = findById(metadata, "txtTitle");
  let inputOtherIssue = findById(metadata, "inputOtherIssue");
  let incidentTypes = findById(metadata, "incidentTypes");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let menuLabel = findById(metadata, "menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");
  let textLengthError = findById(metadata,"textLengthError");

  const [selectedIncidentTypeList, setSelectedIncidentTypeList] = useState([]);

  let FindAgreementResponse = workflowState["FindAgreementResponse"];
  let fileClaimDetails = workflowState["FileClaimDetails"];
  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
  const contact = getContactNumber(agreement?.ContactPoints?.ContactPoint);
  const contactEmailAddress = getContactEmail(agreement?.ContactPoints?.ContactPoint);

  let ServiceRequestResponse = {};

  let checklists = [
    { value: "Malfunction", label: "Malfunction" },
    { value: "Cosmetic damage", label: "Cosmetic damage" },
    {
      value: "Damage due to 3rd party or unauthorised repair",
      label: "Damage due to 3rd party or unauthorised repair",
    },
    {
      value: "Dead pixel or burned in display",
      label: "Dead pixel or burned in display",
    },
    {
      value: "Damage due to modifications",
      label: "Damage due to modifications",
    },
    {
      value: "Inoperable due to manufacturer defects or product recall",
      label: "Inoperable due to manufacturer defects or product recall",
    },
    {
      value: "Liquid penetration or rust or corrosion",
      label: "Liquid penetration or rust or corrosion",
    },
    { value: "Lost or theft", label: "Lost or theft" },
    {
      value: "Negligence, accidental, deliberate misuse",
      label: "Negligence, accidental, deliberate misuse",
    },
    { value: "Software related issues", label: "Software related issues" },
    { value: "Others", label: "Others" },
  ];

  const onContinueClick = () => {
    // showLoader(true);

    updateState(
      {
        ...workflowState,
        FileClaimDetails: {
          incidentType: getIncidentType(),
          lossDescription: getLossDescription(),
        },
      },
      ["FileClaimDetails"]
    );

    return completeCurrentStep(currentStep, {
      provideIssueDetails: true,
    });

    // const CreateServiceRequestParams = {
    //   CreateServiceRequestParams: {
    //     InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
    //     AgreementId: agreement?.AgreementId,
    //   },
    // };

    // API[ActionTypes.CREATE_SERVICE_REQUEST](CreateServiceRequestParams)
    //   .then((data) => {
    //     console.log("service request Data : ", data);
    //     showLoader(false);
    //     ServiceRequestResponse = data.data.CustomerCase;
    //     // callProcessIncidentApi();

    //     updateState({
    //       ...workflowState,
    //       FileClaimDetails: {
    //         incidentType: getIncidentType(),
    //         lossDescription: getLossDescription(),
    //         fromMyClaimScreentoIncidentDetails: fileClaimDetails.fromMyClaimScreen ? true : false,
    //         ServiceRequestResponse: ServiceRequestResponse,
    //       },
    //     }, ["FileClaimDetails"]);

    //     // log claim number event
    //     logGTMEvent({
    //       'event': GTM_EVENT.CLAIM_NO_EVENT,
    //       'claimNo': ServiceRequestResponse?.CustomerCaseNumber,
    //     })

    //     return completeCurrentStep(currentStep, {
    //       provideIssueDetails: true,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(`error is ${error}`);
    //     showLoader(false);
    //     logger({ type: ActionTypes.CREATE_SERVICE_REQUEST, error, state: workflowState});
    //     refreshStateOnLogout(workflowState, "service-unavailable");
    //     updateState({
    //       ...workflowState,
    //       [currentStep]: {
    //         ...workflowState[currentStep],
    //       },
    //     });
    //   });
  };

  const onGoBackClick = () => {

    if(fileClaimDetails.fromMyClaimScreen){
      updateState({
        ...workflowState,
        FileClaimDetails: {},
      });
      return completeCurrentStep(currentStep, {
        GoBackMyClaim: true,
      });
    }else {
      return completeCurrentStep(currentStep, {
        GoBack: true,
      });
    }
  };

  const onOtherIssueChange = (value) => {
    setOtherIssue(value);
      if (value?.length < 15){
        setShowLengthError(true);
      } else{
        setShowLengthError(false);
      }
  }

  const getIncidentType = () => {
    if (
      selectedIncidentTypeList.find((type) => type.value === "Lost or theft")
    ) {
      return INCEDENT_TYPES.LOSS_STOLEN;
    } else if (
      selectedIncidentTypeList.find(
        (type) => type.value === "Negligence, accidental, deliberate misuse"
      )
    ) {
      return INCEDENT_TYPES.CATASTROPHIC_DAMAGE;
    } else if (
      selectedIncidentTypeList.find(
        (type) => type.value === "Liquid penetration or rust or corrosion"
      )
    ) {
      return INCEDENT_TYPES.LIQUID_EXPOSURE;
    } else if (
      selectedIncidentTypeList.find(
        (type) =>
          type.value === "Damage due to 3rd party or unauthorised repair" ||
          type.value === "Dead pixel or burned in display" ||
          type.value === "Damage due to modifications" ||
          type.value ===
            "Inoperable due to manufacturer defects or product recall"
      )
    ) {
      return INCEDENT_TYPES.NON_TECHNICAL_ISSUE;
    } else if (selectedIncidentTypeList.find((type) => type.value === "Cosmetic damage") && 
      isEmpty(selectedIncidentTypeList.find((type) => type.value === "Malfunction"))
    ) {
      return INCEDENT_TYPES.SCRATCHED_AND_DENTED;
    } else if (selectedIncidentTypeList.find((type) => type.value === "Software related issues") &&
      isEmpty(selectedIncidentTypeList.find((type) => type.value === "Malfunction"))
    ) {
      return INCEDENT_TYPES.NON_TECHNICAL_ISSUE;
    } else {
      return INCEDENT_TYPES.MALFUNCTION;
    }
  };

  const isOtherSelected = () => {
    const incidentTypes = selectedIncidentTypeList.filter((item) => {
      return item.value === "Others";
    });

    return incidentTypes.length > 0;
  };

  const getLossDescription = () => {
    return selectedIncidentTypeList
      .map((item) => {
        return item.value === "Others" ? otherIssue : item.value;
      })
      .join("|");
  };

  const onItemCheck = (incidentTypeList) => {
    hideShowInputOtherIssue(incidentTypeList, "block");
    setSelectedIncidentTypeList([
      ...selectedIncidentTypeList,
      incidentTypeList,
    ]);
  };

  const onItemUnCheck = (incidentTypeList) => {
    hideShowInputOtherIssue(incidentTypeList, "hidden");
    setSelectedIncidentTypeList(
      selectedIncidentTypeList.filter(
        (item) => item.value !== incidentTypeList.value
      )
    );
  };

  const hideShowInputOtherIssue = (incidentTypeList, action) => {
    inputOtherIssue.args = {
      ...inputOtherIssue.args,
      value: otherIssue,
      other:
        incidentTypeList.label === "Others"
          ? action
          : inputOtherIssue.args.other,
      onChange: setOtherIssue,
    };
  };

  const callProcessIncidentApi = () => {
    showLoader(true);

    const ProcessIncidentParameters = {
      ProcessIncidentParameters: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ClientOfferId: agreement?.ClientOffer?.ClientOfferId,
        MobileNumber: contact,
        Incident: {
          AgreementId: agreement?.AgreementId,
          CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
          FailureDescriptiveText: "",
          IncidentType: getIncidentType(),
          IncidentDate: "",
          LossDescription: getLossDescription(),
          Triage: {
            TriageAccepted: true,
            TriageRefusalReason: "Triage refused by customer",
            Notes: "",
          },
        },
        AssetDetails: fileClaimDetails?.AssetDetails,
      },
    };
    API[ActionTypes.PROCESS_INCIDENT](ProcessIncidentParameters)
      .then((data) => {
        showLoader(false);

        const CustomerCase = data.data.CustomerCase;

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            ProcessIncidentResponse: CustomerCase,
          },
        }, ["FileClaimDetails"]);

        // check for eligibility
        if (CustomerCase.Eligibility[0].EligibilityOutcome === "Approved")
          // call get fulfilment api
          callGetFulfilmentApi();
        else {
          createContactUsInquiry();
          return completeCurrentStep(currentStep, {
            // showClaimMessage: true,
            showTriageError: true
          });
        }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.PROCESS_INCIDENT, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const createContactUsInquiry = () => {
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: contact,
        FirstName: agreement?.Customers?.Customer[0]?.FirstName,
        LastName: agreement?.Customers?.Customer[0]?.LastName,
        ContactNumber: contact,
        ClientName: workflowState["config"]?.ClientName,
        AgreementId: agreement?.AgreementId,
        InquiryChannel: "ONLINE",
        InquiryType: "Claims Processing",
        InquirySubType: "Claims Cancellation / Rejection",
        InquiryDescription: getLossDescription(),
        ClientId: workflowState["config"]?.ClientId,
        Email: contactEmailAddress,
        DepartmentType: "Care",
        Priority: "High",
        ProgramName: "SmartSupport Home",
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        PolicyNumber: agreement?.ContractId,
      },
    };

    API[ActionTypes.CREATE_ENQUIRY_V1](CreateInquiryParams)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          enquiryResponse: data,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_ENQUIRY_V1, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callGetFulfilmentApi = () => {
    showLoader(true);

    API[ActionTypes.GET_FULFILMENT_OPTIONS](
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            GetFulfilmentResponse: data,
          }
        }, ["FileClaimDetails"]);

        callSetFulfilmentApi();
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_FULFILMENT_OPTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callSetFulfilmentApi = () => {
    showLoader(true);

    const SetFulfillmentOptionParameters = {
      SetFulfillmentOptionParameters: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        FulfillmentOption: "PUR",
      },
    };
    API[ActionTypes.SET_FULFILMENT_OPTIONS](
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      SetFulfillmentOptionParameters
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            SetFulfilmentResponse: data,
            incidentType: getIncidentType(),
            lossDescription: getLossDescription(),
            fromMyClaimScreentoIncidentDetails: fileClaimDetails.fromMyClaimScreen ? true : false,
            ServiceRequestResponse: ServiceRequestResponse,
          },
        }, ["FileClaimDetails"]);
    
        return completeCurrentStep(currentStep, {
          provideIssueDetails: true,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.SET_FULFILMENT_OPTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  incidentTypes.args = {
    ...incidentTypes.args,
    options: checklists,
    onItemCheck: onItemCheck,
    onItemUnCheck: onItemUnCheck,
    value: selectedIncidentTypeList,
  };

  menuLabel.args = {
    ...menuLabel.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  inputOtherIssue.args = {
    ...inputOtherIssue.args,
    label: homeplusscript["labelOtherIssue"],
    placeholder: homeplusscript["placeholderOtherIssue"],
    value: otherIssue,
    onChange: onOtherIssueChange,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: isOtherSelected() && showLengthError ? false : true,
    displayClasses: selectedIncidentTypeList.length > 0 ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedIncidentTypeList.length > 0 ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  textLengthError.args = {
    ...textLengthError.args,
    content: isOtherSelected() && otherIssue?.trim().length < 15 ? homeplusscript.errorMinLength : "",
    displayClasses: showLengthError ? "block" : "hidden" 
  }

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default SelectIncidentType;
