import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/icon";
import { Text } from "../text/text";


export const ToggleQuestion = ({
  open,
  setHasOpen,
  item,
  question,
  subTitleClasses,
  answer,
  mainDiv,
  contentClass,
  subDiv,
  borderClasses,
  iconClasses,
  mainIconClasses,
  ...props
}) => {
 
  const [quesOpen, setQuesOpen] = useState(props.index === 0 || open);
  const toggleQue = () => {
    setQuesOpen(!quesOpen);
  };
  const Answer = () => { return <>{answer()}</> }

  return (
    <div className={mainDiv}>
      <div
        className={subDiv}
        onClick={toggleQue}
      >
          <span className={subTitleClasses}>
          {/* <Text question={question || item?.type}></Text> */}
          {question}
          </span>
        <span
          className={mainIconClasses}
          onClick={toggleQue}
        >
          {!quesOpen ? (
            <Icon
             className={iconClasses}
             icon="Plus"
            ></Icon>
          ) : (
            <Icon className={iconClasses} icon="Minus"></Icon>
          )}
        </span>
      </div>

      {quesOpen && (
        <>
          <div className={contentClass}>
          {/* <Text answer={answer}></Text> */}
          {<Answer/>}
          </div>
        </>
      )}
      <hr className={borderClasses} style={{border:"solid 1px #C7C7C5"}}/>
    </div>
  );
};

ToggleQuestion.propTypes = {
  /**
   * Is the control expand or collapse
   */
  open: PropTypes.bool,
  setHasOpen: PropTypes.func,
  item: PropTypes.object,
};

ToggleQuestion.defaultProps = {
  open: false,
  setHasOpen: undefined,
  item: {},
  subTitleClasses: "text-base font-LatoBold text-neutralN2",
  mainDiv:"px-4",
  subDiv:"flex flex-row items-center justify-between cursor-pointer",
  contentClass:"mt-2 text-base font-LatoRegular",
  borderClasses:"w-full my-4 border border-neutralN8",
  mainIconClasses:"inline-block cursor-pointer",
  iconClasses:"mt-2",
  // question:"",
  // answer:""
};
