import {
  client,
  dataCollector,
  hostedFields,
  threeDSecure,
  paypalCheckout,
  googlePayment,
} from "braintree-web";

export const callCreateClient = (
  clientTokenResponse,
  onCCSuccess,
  showLoader,
  setValidCardNumber,
  setValidName,
  setValidExpDate,
  setValidCVV,
  setInValidCardNumber,
  setInValidName,
  setInValidExpDate,
  setInValidCVV,
  setBGError
) => {
  let paymentMethod = [];

  let fields = {
    number: {
      container: "#ccardNumber",
    },
    cvv: {
      container: "#inputCvv",
      type: "password",
      // placeholder: "•••",
    },
    expirationDate: {
      container: "#inputExpiry",
    },
    cardholderName: {
      container: "#inputCardHolder",
    },
  };

  client.create({ authorization: clientTokenResponse }).then((res) => {
    dataCollector.create({ client: res }).then(async (deviceData) => {
      paymentMethod.push(await createHostedFields(res, deviceData));
      return paymentMethod;
    });

    const createHostedFields = (authorization, deviceData) => {
      return hostedFields
        .create({
          client: authorization,
          styles: {
            input: {
              "font-size": "16px",
            },
          },
          fields: fields,
        })
        .then((hostedFieldsInstance) => {
          hostedFieldsInstance.on("validityChange", function (event) {
            let field = event.fields[event.emittedBy];

            if (field.isValid) {
              if (event.emittedBy === "number") {
                setValidCardNumber(true);
                setInValidCardNumber(false);
                setBGError("")
              } else if (event.emittedBy === "cardholderName") {
                setValidName(true);
                setInValidName(false);
                setBGError("")
              } else if (event.emittedBy === "expirationDate") {
                setValidExpDate(true);
                setInValidExpDate(false);
                setBGError("")
              } else if (event.emittedBy === "cvv") {
                setValidCVV(true);
                setInValidCVV(false);
                setBGError("")
              }
            } else {
              console.log(event.emittedBy, "is not valid");
              if (event.emittedBy === "number") {
                setValidCardNumber(false);
                setInValidCardNumber(true);
                setBGError("")
              } else if (event.emittedBy === "cardholderName") {
                setValidName(false);
                setInValidName(true);
                setBGError("")
              } else if (event.emittedBy === "expirationDate") {
                setValidExpDate(false);
                setInValidExpDate(true);
                setBGError("")
              } else if (event.emittedBy === "cvv") {
                setValidCVV(false);
                setInValidCVV(true);
                setBGError("")
              }
            }
          });
          threeDSecure
            .create({
              client: authorization,
              version: 2,
            })
            .then((threeDSecureInstance) => {
              onCCSuccess(
                hostedFieldsInstance,
                threeDSecureInstance,
                deviceData
              );
            })
            .catch((err) => {
              return err;
            });
        })
        .catch((err) => {
          showLoader(false);
          console.log(err, "hostedFieldsInstance Error");
          return err;
        });
    };
  });
};

export const callCreatePaypal = (
  clientTokenResponse,
  onPayPalSuccess,
  workflowState
) => {
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];
  const userAddress = agreement?.Address;

  client.create({ authorization: clientTokenResponse }).then((res) => {
    dataCollector.create({ client: res }).then((deviceData) => {
      return createPaypal(res, deviceData);
    });

    const createPaypal = (clientRes, deviceData) => {
      const container = document.getElementById("btnPaypal");
      let customerName = `${agreement.Customers?.Customer[0]?.FirstName} ${agreement.Customers?.Customer[0]?.LastName}`;
      container.style = "width: 295px";
      return paypalCheckout
        .create({
          client: clientRes,
        })
        .then((paypalCheckoutInstance) => {
          paypalCheckoutInstance
            .loadPayPalSDK({
              vault: true,
            })
            .then(() => {
              window.paypal
                .Buttons({
                  fundingSource: window.paypal.FUNDING.PAYPAL,
                  style: {
                    layout: "vertical",
                    color: "white",
                    shape: "rect",
                    label: "pay",
                    height: 40,
                  },

                  createBillingAgreement: function () {
                    return paypalCheckoutInstance.createPayment({
                      flow: "vault", // Required
                      displayName: "Starhub SmartSupportHomePlus",
                      // The following are optional params
                      billingAgreementDescription: "Asurion Starhub SmartHome",
                      enableShippingAddress: true,
                      shippingAddressEditable: false,
                      shippingAddressOverride: {
                        recipientName: customerName,
                        line1: userAddress?.Address1,
                        line2: userAddress?.Address2,
                        city: userAddress?.City,
                        countryCode: "SG",
                        postalCode: userAddress?.PostalCode,
                        state: userAddress?.StateProvinceCode,
                        phone:
                          agreement?.ContactPoints?.ContactPoint[0]
                            ?.PhoneNumber,
                      },
                    });
                  },
                  onApprove: (data, actions) => {
                    return paypalCheckoutInstance
                      .tokenizePayment(data)
                      .then(function (payload) {
                        onPayPalSuccess(payload, deviceData);
                      });
                  },
                  onCancel: function (data) {
                    console.log(
                      "PayPal payment cancelled",
                      JSON.stringify(data, 0, 2)
                    );
                  },
                  onError: function (err) {
                    console.error("PayPal error", err);
                  },
                })
                .render("#btnPaypal");
            });

          // The PayPal script is now loaded on the page and
          // window.paypal.Buttons is now available to use
          // render the PayPal button (see Render the PayPal Button section)
        })
        .catch((err) => {
          console.log(err, "PayPal Error");
        });
    };
  });
};

export const callCreateGpay = (clientTokenResponse, onGpaySuccess) => {
  let paymentMethod = [];

  const paymentsClient = new window.google.payments.api.PaymentsClient({
    environment: "TEST", // or 'PRODUCTION'
  });

  client.create({ authorization: clientTokenResponse }).then((res) => {
    dataCollector.create({ client: res }).then(async () => {
      paymentMethod.push(await createGpay(res));
      return paymentMethod;
    });

    let createGpay = (authorization) => {

      return googlePayment
        .create({
          client: authorization,
          googlePayVersion: 2,
          googleMerchantId: "SamCarePlusOTPWebAUD", // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
        })
        .then((googlePaymentInstance) => {
          return paymentsClient
            .isReadyToPay({
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods:
                googlePaymentInstance.createPaymentDataRequest()
                  .allowedPaymentMethods,
              existingPaymentMethodRequired: true, // Optional
            })
            .then((response) => {
              if (response.result) {
                const container = document.querySelector("#gpay-button");
                const button = paymentsClient.createButton({
                  buttonColor: "white",
                  // buttonType: "plain",
                  buttonType: "pay",
                  // buttonSizeMode: "rect",
                  onClick: () => loadPayment(),
                  allowedPaymentMethods: [], // use the same payment methods as for the loadPaymentData() API call
                });
                container.appendChild(button);
                var paymentDataRequest =
                  googlePaymentInstance.createPaymentDataRequest({
                    transactionInfo: {
                      currencyCode: "SGD",
                      totalPriceStatus: "FINAL",
                      totalPrice: "100.00", // Your amount
                    },
                  });
                var cardPaymentMethod =
                  paymentDataRequest.allowedPaymentMethods[0];
                cardPaymentMethod.parameters.billingAddressRequired = true;
                cardPaymentMethod.parameters.billingAddressParameters = {
                  format: "FULL",
                  phoneNumberRequired: true,
                };
                const loadPayment = () => {
                  paymentsClient
                    .loadPaymentData(paymentDataRequest)
                    .then((paymentData) => {
                      return googlePaymentInstance.parseResponse(paymentData);
                    })
                    .then((result) => {
                      console.log(result, "-------------result");
                    })
                    .catch((err) => {
                      console.log(err, "---------err1");
                    });
                };
              }
            });
        })
        .catch((err) => {
          console.log(err, "----------------err2");
        });
    };
  });
};

export const getDeviceData = (clientTokenResponse, onDeviceData) => {
  client.create({ authorization: clientTokenResponse }).then((res) => {
    dataCollector.create({ client: res }).then(async (deviceData) => {
      onDeviceData(deviceData?.deviceData);
    });
  });
};
