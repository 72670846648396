import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import linkifyHtml from "linkifyjs/html";
import clsx from "clsx";
import {
  Link,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { appSyncChatConfig } from "./config";

import {
  chatConversationIdEntity,
  chatRequestIdEntity,
  chatVisitorIdEntity,
  getChatSession,
  hasActiveChatEntity,
  endChatActivity,
  chatTypeEntity,
} from "./entities/ChatEntities";

import { useMediaQuery } from "@material-ui/core";

import { buildMuiTheme } from "./service/BrandService";
import { appConfig } from "./entities/AppConfig";
import sanitizeHtml from "sanitize-html";
import ChatIconSvg from "./assets/ChatIconSvg";
import MinimizeIconSvg from "./assets/MinimizeIconSvg";
import CloseIconSvg from "./assets/CloseIconSvg";
import MessageIconSvg from "./assets/MessageIconSvg";
import ClaimRelatedIconSvg from "./assets/ClaimRelatedIconSvg";
import TechSupportIconSvg from "./assets/TechSupportIconSvg";
import { ChatType, configure, FetchStatus } from "./service/ChatService";
import { ChatManager } from "./ChatManager";
import ReactDOMServer from "react-dom/server";
import {
  convertToTime,
  isMobileWidth,
  parseAttributeFromString,
} from "./utils";
import SendIconSvg from "./assets/SendIconSvg";
import ConfirmDialog from "./widgets/ConfirmDialog";
import ChatAlertIconSvg from "./assets/ChatAlertIconSvg";
import { Colors } from "./entities/Colors";
import { FaqWidget } from "./FaqWidget";
import { ChatEnquiry } from "./ChatEnquiry";
import { MessageLoader } from "./widgets/MessageLoader";
import { EnquiryOption } from "./EnquiryOption";
import { ThankYouWidget } from "./ThankYouWidget";
import { submitEnquiry } from "./service/EnquiryService";
import { CallbackOption } from "./CallbackOption";
import { NPSWidget } from "./NPSWidget";
import { CSATWidget } from "./CSATWidget";
import { getSurvey, submitSurvey } from "./service/SurveyService";
import { AddtlCommentWidget } from "./AddtlCommentWidget";
import { CloseChatBtn } from "./widgets/CloseChatBtn";
import SurveyRateLikeSvg from "./assets/SurveyRateLikeSvg";
import AgentBusySvg from "./assets/AgentBusySvg";

const defaultThemeName = "default";

const defaultChatTexts = {
  greetingLabel: "How can we help?",
  mdnLabel:
    "To connect with us, please enter your phone number for confirmation text",
  startChatLabel: 'To connect with us, please click "Start Chat" button below',
  privacyInfoLabel: "By logging in, you agree to",
  privacyPolicyLinkLabel: "Asurion Privacy Policy",
  mdnPlaceholderText: "e.g. 541-555-3010",
  chatWindowTitleLabel: "SmartSupport Home Specialist",
  chatWindowSubtitleLabel: "Ask us anything",
  chatInputPlaceholderText: "Type your message",
  chatWindowAgentSubtitle: "Ask me anything",
  expertNamePlaceholder: "An expert is online",
  chatInputButtonLabel: "Start Chat",
  closeChatConfirmation: "Are you sure you want to end chat?",
  downloadConfirm: "Do you want to download this file?",
  downloadWaiting: "Please wait while we download the file.",
  noAvailableAgentNote: (
    <div>
      <p>
        Sorry! All our Security Specialists are busy at the moment. You may try
        again at a later time.
      </p>
      <p>Thank you for contacting SmartSupport Home</p>
    </div>
  ),
};

const chatEngagementOptions = [
  {
    title: "Claim Related Enquiries",
    description: "Need help related to device registration and filing claims?",
    img: <ClaimRelatedIconSvg />,
    value: "DP",
  },
  {
    title: "Technical Support for my home devices",
    description:
      "Need technical assistance for your home office and entertainment products?",
    img: <TechSupportIconSvg />,
    value: "SOLUTO",
  },
];

const getSurveyQuestionsPayload = {
  GetSurveyQuestions: {
    ClientId: process.env.REACT_APP_ENQUIRY_CLIENT_ID,
    TriggerPoint: process.env.REACT_APP_TRIGGERPOINT,
    SurveyType: "NPS",
    ServiceRequestId: process.env.REACT_APP_REQUEST_ID,
    Language: "en-US",
  },
};

export const ChatWidgetv2 = ({
  findAgreementData,
  workflowState,
  updateState,
  refreshStateOnLogout,
  appSyncConfig,
  themeName = defaultThemeName,
  theme = null,
  chatTexts = defaultChatTexts,
  surveyCallback = () => {},
  // privacyLink = "https://www.asurion.com/privacy-policy/",
}) => {
  const classes = ChatStyles();
  const mdn = findAgreementData.ChatMdn;
  const emailAddress = findAgreementData.ChatEmailAddress;

  console.log("Chat MDN", mdn);
  console.log("Chat Email Address", emailAddress);
  console.log("Chat find agreement data", findAgreementData);

  appSyncConfig = appSyncChatConfig;

  configure(appSyncConfig);

  const brandConfig = appConfig.use();

  const chatRequestId = chatRequestIdEntity.use();
  const chatConversationId = chatConversationIdEntity.use();
  const chatVisitorId = chatVisitorIdEntity.use();
  const hasActiveChat = hasActiveChatEntity.use();
  const chatType = chatTypeEntity.use();

  const customerProfile =
    findAgreementData &&
    findAgreementData.Customers &&
    findAgreementData.Customers.Customer &&
    findAgreementData.Customers.Customer.length > 0
      ? findAgreementData.Customers.Customer[0]
      : {};

  console.log("Chat Customer Profile", customerProfile);

  const [fetchChatRequestIdStatus, setFetchChatRequestIdStatus] = useState(
    FetchStatus.INIT
  );
  const [chatHeightMobile, setChatHeightMobile] = useState(
    window.innerHeight - 48
  );
  const [isNoAvailableAgents, setIsNoAvailableAgents] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileWidth() ? true : false);
  const [conversationId, setConversationId] = useState(null);
  const [visitorId, setVisitorId] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const [selectedChatType, setSelectedChatType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleCloseChatDialog, setToggleCloseChatDialog] = useState(false);
  const [surveyTriggerStatus, setSurveyTriggerStatus] = useState(null); // null, show, start
  const [toggleFaq, setToggleFaq] = useState(false);
  const [toggleCallbackDisplay, setToggleCallbackDisplay] = useState(false);
  const [toggleThankYouForCallback, setToggleThankYouForCallback] =
    useState(null);
  const [toggleShowWillContactCallback, setToggleShowWillContactCallback] =
    useState(false);
  const [requestForCallback, setRequestForCallback] = useState(null);
  const [faqDismissAnswer, setFaqDismissAnswer] = useState();
  const [npsSurveyItems, setNPSSurveyItems] = useState(null);
  const [csatSurveyItems, setCSATSurveyItems] = useState(null);
  const [commentSurveyItems, setCommentSurveyItems] = useState(null);
  const [toggleEnquiry, setToggleEnquiry] = useState(false);
  const [toggleEnquiryLoading, setToggleEnquiryLoading] = useState(false);
  const [toggleThankYou, setToggleThankYou] = useState(false);
  const [toggleNPS, setToggleNPS] = useState(false);
  const [toggleCSAT, setToggleCSAT] = useState(false);
  const [toggleAddtlComment, setToggleAddtlComment] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [nps, setNPS] = useState(null);
  const [csat, setCSAT] = useState(null);
  const [comment, setComment] = useState(null);
  const [chatTheme, setChatTheme] = useState(theme);
  const [chatThemeName, setChatThemeName] = useState(themeName);
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [isCustomerTyping, setIsCustomerTyping] = useState(false);
  const [isAgentTyping, setIsAgentTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [currentMessageCount, setCurrentMessageCount] = useState(0);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [isChatExpandedDelayDone, setIsChatExpandedDelayDone] = useState(false);
  const [isChatConnected, setIsChatConnected] = useState(false);

  const refMessageList = useRef(null);
  const refMessageInput = useRef(null);

  const [chatManager, setChatManager] = useState(null);

  const closeChatWindow = (terminate = false) => {
    setIsChatExpanded(false);
    setSurveyTriggerStatus(null);
    setIsNoAvailableAgents(null);
    setSelectedChatType(null);
    setToggleFaq(false);
    setFaqDismissAnswer(null);
    setToggleEnquiry(false);
    setToggleThankYou(false);
    setToggleNPS(false);
    setToggleCSAT(false);
    setToggleAddtlComment(false);
    setToggleLoading(false);
    setToggleCallbackDisplay(false);
    setToggleThankYouForCallback(false);
    setChatManager(null);

    if (terminate) {
      setIsChatConnected(false);
      setMessages([]);
      setActiveChat(false);
      endChatActivity();
    }
  };

  const onFaqClick = (e, redirect) => {
    closeChatWindow(false);
    refreshStateOnLogout(workflowState, redirect);
  };

  const defaultTheme = useMemo(
    () => brandConfig && buildMuiTheme(brandConfig.theme),
    [brandConfig]
  );

  //shows/hides chat window on click
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleShowChatWindow = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (isChatExpanded) {
      setIsChatExpandedDelayDone(false);
      await new Promise((resolve) => setTimeout(resolve, 250));
      setIsChatExpanded(false);
      if (chatManager && chatManager.status === "Connected") {
        triggerEndChat();
      }
    } else {
      setIsChatExpanded(true);
      await new Promise((resolve) => setTimeout(resolve, 125));
      setIsChatExpandedDelayDone(true);
    }
    setCurrentMessageCount(messages.length || 0);
  };

  const terminateChatSession = useCallback(() => {
    if (fetchChatRequestIdStatus === FetchStatus.COMPLETE) {
      if (chatManager) {
        chatManager.end();
      }

      if (surveyTriggerStatus === "start") {
        closeChatWindow(true);
      } else if (
        surveyTriggerStatus === null &&
        chatManager.isResolved &&
        selectedChatType === ChatType.SOLUTO
      ) {
        setSurveyTriggerStatus("show");
      } else if (surveyTriggerStatus === null && !chatManager.isResolved) {
        closeChatWindow(true);
      } else {
        closeChatWindow(true);
      }

      setFetchChatRequestIdStatus(FetchStatus.INIT);
      console.log("Chat is now terminated.");
    }
  }, [
    chatManager,
    selectedChatType,
    fetchChatRequestIdStatus,
    surveyTriggerStatus,
  ]);

  const triggerEndChat = useCallback(() => {
    terminateChatSession();
  }, [terminateChatSession]);

  const handleShowConfirmCloseChatWindow = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      if (isChatExpanded) {
        document.getElementById("root").classList.remove("fixed", "inset-0");

        if (selectedChatType && isChatConnected) {
          // end chat
          setToggleCloseChatDialog(true);
        } else {
          closeChatWindow(false);

          chatTypeEntity.set(null);
        }
      } else {
        if (isMobileWidth()) {
          document.getElementById("root").classList.add("fixed", "inset-0");
        }

        // show chat
        handleShowChatWindow(e);
      }
    },
    [isChatExpanded, selectedChatType, isChatConnected, handleShowChatWindow]
  );

  const handleClose = useCallback(() => {
    console.log(anchorEl);
    setAnchorEl(null);
  }, [anchorEl]);

  const formatMessages = useCallback(
    (messages) => {
      return messages.map((message) => {
        let newMessage = message;

        // eslint-disable-next-line default-case
        switch (newMessage.messageType.toLowerCase()) {
          case "article":
            let article = JSON.parse(message.content);
            newMessage.content = ReactDOMServer.renderToString(
              <Link
                className={classes.articleLink}
                href={article.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className={classes.article}>
                  <div>
                    <img
                      className={classes.articleImg}
                      src={article.thumbnail_size.thumbnail}
                      alt={article.title}
                    />
                  </div>
                  <div className={classes.articleDetails}>{article.title}</div>
                </div>
              </Link>
            );
            break;
          case "html":
            const filename = parseAttributeFromString(
              newMessage.content,
              "data-file"
            )?.toLowerCase();

            newMessage.content =
              `<span style="padding: 0; padding-bottom: 4px; font-size: 0.75rem; border: 0;">${filename}</span>` +
              linkifyHtml(newMessage.content, {
                target: { url: "_blank" },
              });
            break;
          case "plain text":
          case "text":
            //transform URLs and emails to links
            newMessage.content = linkifyHtml(newMessage.content, {
              target: { url: "_blank" },
            });
            break;
        }

        return newMessage;
      });
    },
    [classes]
  );

  //Send message trigger
  const sendMessage = useCallback(
    (e) => {
      // e?.preventDefault();
      e?.stopPropagation();

      let trimmedVal = e.target.value && e.target.value.trim();
      if (trimmedVal.length === 0 || null) {
        setIsCustomerTyping(false);
      } else {
        setIsCustomerTyping(true);
      }

      if (e.charCode === 13) {
        setIsCustomerTyping(false);
        if (trimmedVal.length) {
          if (chatManager) {
            chatManager.sendMessage(trimmedVal);
          }
          setMessage("");
        }
      } else {
        setMessage(e.target.value);
      }
    },
    [chatManager]
  );

  //Starts chat upon submitting MDN
  const startChat = async (e = null) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (chatManager) {
      chatManager.onConnected = chatConnected;
      chatManager.onMessageReceived = messageReceived;
      chatManager.onDisconnected = chatDisconnected;
      chatManager.onAgentTyping = agentTyping;

      await chatManager.create(
        activeChat,
        appSyncConfig,
        mdn,
        requestId,
        conversationId,
        visitorId
      );

      const availableAgents = await chatManager.hasAvailableAgents();
      const messages = await chatManager.getAllMessages();

      setMessages(formatMessages(messages));

      if (!activeChat) {
        setIsNoAvailableAgents(!availableAgents);
      } else {
        setIsNoAvailableAgents(false);
      }
    }
  };

  //callback. called when chat is connected to Agent
  const chatConnected = useCallback(() => {
    console.log("chatConnected");
    setIsChatConnected(true);
    setToggleLoading(false);
    setIsNoAvailableAgents(false);
    refMessageInput?.current?.focus();
  }, []);

  // callback. called when message is recieved
  const messageReceived = useCallback(() => {
    if (chatManager) {
      chatManager.getAllMessages().then((incomingMessages) => {
        setMessages(formatMessages(incomingMessages));
      });
    }
  }, [chatManager, formatMessages]);

  //callback. called when agent/user is disconnected from chat
  const chatDisconnected = useCallback(() => {
    terminateChatSession();
  }, [terminateChatSession]);

  //callback. called when agent is typing
  const agentTyping = useCallback((isAgentTyping) => {
    setIsAgentTyping(isAgentTyping);
  }, []);

  const triggerSendMessage = useCallback(() => {
    if (chatManager) {
      chatManager.sendMessage(message);
      setMessage("");
      setIsCustomerTyping(false);
    }
  }, [chatManager, message]);

  const handleNoChatConfirmDialog = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    setToggleCloseChatDialog(false);
  }, []);

  const handleYesChatConfirmDialog = useCallback(
    (e) => {
      e?.stopPropagation();

      handleClose();
      setToggleCloseChatDialog(false);
      setIsChatExpandedDelayDone(false);
      setIsChatExpanded(false);
      triggerEndChat();
    },
    [handleClose, triggerEndChat]
  );

  const handleChatInitialization = () => {
    const valueType = (selectedChatType || chatType).toUpperCase();

    if (valueType && chatManager) {
      if (valueType === ChatType.DP) {
        chatManager.skillId = appSyncConfig.skillIdDP;
        chatManager.twilioTaskQueueSID =
          appSyncChatConfig.twilioTaskQueueSID.DP;
      } else if (valueType === ChatType.SOLUTO) {
        chatManager.skillId = appSyncConfig.skillIdSoluto;
        chatManager.twilioTaskQueueSID =
          appSyncChatConfig.twilioTaskQueueSID.SOLUTO;
      }
    }
    setToggleLoading(true);
    startChat();
  };

  const handleChatWithUs = () => {
    setFaqDismissAnswer(null);
    setToggleFaq(false);
    handleChatInitialization();
  };

  const handleSubmitEnquiry = () => {
    chatTypeEntity.set(null);
    // setSelectedChatType(null);
    setFaqDismissAnswer(null);
    setToggleFaq(false);
    setToggleEnquiry(true);
  };

  const handleStartFaq = (value) => {
    console.log("Start FAQ", value);

    if (chatManager) {
      if (value === ChatType.SOLUTO) {
        chatManager.skillId = appSyncConfig.skillIdSoluto;
        chatManager.twilioTaskQueueSID =
          appSyncChatConfig.twilioTaskQueueSID.SOLUTO;
      } else {
        chatManager.skillId = appSyncConfig.skillIdDP;
        chatManager.twilioTaskQueueSID =
          appSyncChatConfig.twilioTaskQueueSID.DP;
      }
    }

    chatTypeEntity.set(value);
    setSelectedChatType(value);
    setToggleFaq(true);
  };

  const handleDismissFaq = (answer) => {
    if (answer === "yes") {
      chatTypeEntity.set(null);
      setSelectedChatType(null);
    }
    setToggleFaq(false);
    setFaqDismissAnswer(answer);
  };

  const handleBackToMenu = () => {
    chatTypeEntity.set(null);
    setSelectedChatType(null);
    setToggleFaq(false);
    setFaqDismissAnswer(null);
  };

  const handleSubmitCallbackEnquiry = async (info) => {
    let enquiryParams = {
      CreateInquiryParams: {
        AgreementId: findAgreementData.AgreementId,
        ClientAccountId: findAgreementData.ClientAccountId,
        PolicyNumber: findAgreementData.ContractId,
        FirstName:
          customerProfile && customerProfile.FullName
            ? customerProfile.FullName
            : info.name,
        LastName: "",
        MobileDeviceNumber: info.number,
        InquiryType: "NPS",
        InquiryDescription: `NPS survey callback request; Reason: ${
          comment || ""
        }; Contact number: ${info.number}`,
        DepartmentType: "Care",
        Priority: "High",
        InquiryChannel: process.env.REACT_APP_ENQUIRY_CHANNEL.toLowerCase(),
        ClientName: process.env.REACT_APP_ENQUIRY_CLIENT,
        ClientId: process.env.REACT_APP_ENQUIRY_CLIENT_ID,
        ClientChannelId: process.env.REACT_APP_ENQUIRY_CHANNEL_ID,
      },
    };

    await submitEnquiry(enquiryParams);

    setToggleThankYouForCallback(false);
    setToggleShowWillContactCallback(true);
    setRequestForCallback(null);
  };

  const handleSubmitEnquiryForm = async (formData) => {
    setToggleEnquiry(true);
    setToggleEnquiryLoading(true);

    let enquiryParams = {
      CreateInquiryParams: {
        AgreementId: findAgreementData.AgreementId,
        ClientAccountId: findAgreementData.ClientAccountId,
        PolicyNumber: findAgreementData.ContractId,
        FirstName:
          customerProfile && customerProfile.FullName
            ? customerProfile.FullName
            : formData.fullName.value,
        LastName: "",
        Email: emailAddress || formData.email.value,
        MobileDeviceNumber: mdn,
        InquiryType: formData.enquiryType.value,
        InquiryDescription: `${formData.description.value},${findAgreementData.ContractId}`,
        DepartmentType: "Care",
        Priority: "Low",
        InquiryChannel: process.env.REACT_APP_ENQUIRY_CHANNEL.toLowerCase(),
        ClientName: process.env.REACT_APP_ENQUIRY_CLIENT,
        ClientId: process.env.REACT_APP_ENQUIRY_CLIENT_ID,
        ClientChannelId: process.env.REACT_APP_ENQUIRY_CHANNEL_ID,
      },
    };

    await submitEnquiry(enquiryParams);

    setToggleEnquiry(false);
    setToggleEnquiryLoading(false);
    setToggleThankYou(true);
  };

  const handleCancelEnquiryForm = () => {
    setToggleEnquiry(false);
    setFaqDismissAnswer("no");
  };

  const handleCloseThankYouWidget = () => {
    closeChatWindow(false);
    setMessages([]);
    setActiveChat(false);
    endChatActivity();

    setFetchChatRequestIdStatus(FetchStatus.INIT);
  };

  const handleSubmitSurvey = async (surveyId, questionId, answer) => {
    const surveyParams = {
      SubmitSurveyAnswers: {
        SurveyId: surveyId,
        ClientId: findAgreementData.ClientAccountId,
        Results: [
          {
            QuestionId: questionId,
            Answer: answer.toString(),
          },
        ],
      },
    };
    const response = await submitSurvey(surveyParams);

    if (response) {
    } else {
      console.log("failed to submit survey");
    }
  };

  const validateSurveyResult = () => {
    if (nps <= 6 && csat <= 2) {
      // show call back option
      setToggleCallbackDisplay(true);
      setToggleThankYouForCallback(false);
    } else {
      // show only thank you
      setToggleCallbackDisplay(true);
      setToggleThankYouForCallback(true);
    }

    return;

    // // NPS 0-6, CSAT 1-2 (DETRACTOR)
    // if (nps <= 6 && csat <= 2) {
    //   setToggleCallbackDisplay(true);
    // } else if (nps <=6 && csat === 3) {
    //   setToggleCallbackDisplay(true);
    //   setToggleThankYouForCallback(true);
    // }

    // // NPS 7-8, CSAT 3 (PASSIVE)
    // if (nps >= 7 && nps <= 8 && csat === 3) {
    //   setToggleCallbackDisplay(true);
    //   setToggleThankYouForCallback(true);
    // }

    // // NPS 9-10, CSAT 4-5 (PROMOTER)
    // if (nps >= 9 && nps <= 10 && csat >= 4 && csat <= 5) {
    //   setToggleCallbackDisplay(true);
    //   setToggleThankYouForCallback(true);
    // } else if (nps >= 9 && nps <= 10 && csat === 3) {
    //   setToggleThankYouForCallback(true);
    // }
  };

  console.log("Chat widget initiated");

  const setChatClientHeightMobile = useCallback(() => {
    const offsetHeight = document.body.offsetHeight;
    const innerHeight = window.innerHeight;
    const height =
      offsetHeight > innerHeight ? offsetHeight - 48 : innerHeight - 48;

    if (height !== chatHeightMobile) {
      if (isMobileWidth()) {
        console.log("New Chat Height", height);
        setChatHeightMobile(height);
      }
    }
  }, [chatHeightMobile]);

  useEffect(() => {
    // changing theme
    if (themeName !== chatThemeName) {
      setChatTheme(theme);
      setChatThemeName(themeName);
    }
  }, [chatThemeName, theme, themeName]);

  useEffect(() => {
    const messageCount = messages.length || 0;
    const newCount = messages.length - currentMessageCount;

    if (
      messageCount > 0 &&
      newCount > 0 &&
      messageCount > currentMessageCount
    ) {
      if (isChatExpanded) {
        // update current message count if chat window is expanded
        setCurrentMessageCount(messageCount);
        setNewChatMessage(false);
      }

      if (!isChatExpanded) {
        setNewChatMessage(true);
      }
    }
  }, [messages, isChatExpanded, currentMessageCount]);

  useEffect(() => {
    if (fetchChatRequestIdStatus === FetchStatus.COMPLETE) {
      if (activeChat) {
        setIsChatExpanded(true);
        handleShowChatWindow(null);
        startChat();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatManager, fetchChatRequestIdStatus, activeChat]);

  useEffect(() => {
    refMessageList.current &&
      refMessageList.current.scrollTo(0, refMessageList.current.scrollHeight);
  }, [messages]);

  useEffect(() => {
    if (fetchChatRequestIdStatus === FetchStatus.INIT) {
      setRequestId(null);
      setConversationId(null);
      setVisitorId(null);
      setActiveChat(null);
      setFetchChatRequestIdStatus(FetchStatus.FETCHING);
      getChatSession(chatRequestId, appSyncConfig);
    }

    if (hasActiveChat !== null && chatManager) {
      setFetchChatRequestIdStatus(FetchStatus.COMPLETE);

      setRequestId(chatRequestId);
      setConversationId(chatConversationId);
      setVisitorId(chatVisitorId);
      setActiveChat(hasActiveChat);

      chatManager.requestId = chatRequestId;
      chatManager.conversationId = chatConversationId;
      chatManager.visitorId = chatVisitorId;
      chatManager.activeChat = hasActiveChat;
    }
  }, [
    hasActiveChat,
    activeChat,
    requestId,
    conversationId,
    fetchChatRequestIdStatus,
    chatRequestId,
    chatConversationId,
    chatVisitorId,
    chatManager,
    appSyncConfig,
  ]);

  useEffect(() => {
    if (
      !isChatConnected &&
      chatManager &&
      chatManager.isResolved &&
      surveyTriggerStatus === "start"
    ) {
      // call to start survey
      setIsChatExpanded(true);
      setToggleLoading(true);

      const surveyRequest = async (payload) => {
        const response = await getSurvey(payload);

        if (response.data.SurveyQuestions) {
          let result = response.data.SurveyQuestions;
          let questionSet = result.map((question) => {
            return {
              questionId: question.QuestionId,
              questionDescription: question.QuestionDescription,
              questionType: question.QuestionType,
              surveyId: question.SurveyId,
            };
          });

          const npsSurveyItems = questionSet.filter(
            (x) => x.questionType.toLowerCase() === "nps"
          )[0];
          const csatSurveyItems = questionSet.filter(
            (x) => x.questionType.toLowerCase() === "csat"
          )[0];
          const commentSurveyItems = questionSet.filter(
            (x) => x.questionType.toLowerCase() === "remark"
          )[0];

          setNPSSurveyItems(npsSurveyItems);
          setCSATSurveyItems(csatSurveyItems);
          setCommentSurveyItems(commentSurveyItems);
          setToggleNPS(true);
          setToggleLoading(false);
        }
      };

      surveyRequest(getSurveyQuestionsPayload);
      if (chatManager) {
        chatManager.isResolved = false;
      }
    }
  }, [isChatConnected, chatManager, surveyCallback, surveyTriggerStatus]);

  useEffect(() => {
    if (isChatConnected && chatType) {
      console.log("Selected Chat Type", chatType);
      setSelectedChatType(chatType);
    }
  }, [isChatConnected, chatType]);

  useEffect(() => {
    // setting of chat manager
    if ((selectedChatType || chatType) && !chatManager) {
      setChatManager(
        () =>
          new ChatManager({
            client: appSyncConfig.client,
            twilioHost: appSyncConfig.twilioHost,
            twilioTaskQueueSID:
              selectedChatType === "SOLUTO"
                ? appSyncConfig.twilioTaskQueueSID.SOLUTO
                : appSyncConfig.twilioTaskQueueSID.DP,
            chatType: selectedChatType || chatType,
            skillId:
              selectedChatType === ChatType.SOLUTO
                ? appSyncConfig.skillIdSoluto
                : appSyncConfig.skillIdDP,
            mdn: mdn,
          })
      );
    }
  }, [selectedChatType, chatType, appSyncConfig, chatManager, mdn]);

  useEffect(() => {
    setChatClientHeightMobile();
  }, [setChatClientHeightMobile]);

  setTimeout(() => {
    setChatClientHeightMobile();
  }, 1500);

  useEffect(() => {
    if (workflowState.OpenChatWidget && !isChatExpanded) {
      handleShowConfirmCloseChatWindow();
      updateState({
        ...workflowState,
        OpenChatWidget: false,
      });
    }
  }, [
    workflowState,
    handleShowConfirmCloseChatWindow,
    isChatExpanded,
    updateState,
  ]);

  useEffect(() => {
    if (isNoAvailableAgents === true) {
      console.log("No agents are available as of the moment.");
    }
  }, [isNoAvailableAgents]);

  const isChatTypeSelection =
    !selectedChatType &&
    !toggleCallbackDisplay &&
    !toggleFaq &&
    faqDismissAnswer !== "no" &&
    !toggleEnquiry &&
    !toggleThankYou &&
    !toggleNPS &&
    !toggleCSAT &&
    !toggleAddtlComment &&
    !toggleLoading;

  const chatAddonStyle = {
    //position: isMobile ? "fixed" : "absolute",
    position: "fixed",
    height: isMobile ? `${chatHeightMobile}px` : `unset`,
    width: isMobile ? "100%" : "350px",
    top: isMobile ? "95px" : "unset",
    bottom: isMobile ? "unset" : "10px",
    left: isMobile ? 0 : "unset",
    //right: isMobile ? "unset" : "10px",
    minHeight: isMobile ? "90vh" : "80vh",
    maxHeight: isMobile ? "unset" : "90vh",
    borderTopLeftRadius: isMobile ? 0 : "8px",
    borderTopRightRadius: isMobile ? 0 : "8px",
    boxShadow: isMobile
      ? `unset`
      : `rgb(51 63 72 / 15%) 0px 4px 20px 0px, rgb(51 63 72 / 5%) 0px 2px 6px 0px`,
  };

  const chatHeaderAddonStyle = {
    width: isMobile ? "100%" : "350px",
  };

  window.addEventListener("resize", () => {
    setIsMobile(isMobileWidth() ? true : false);
  });

  let chatContentAddonStyle = {};
  let chatMessageBoxAddonStyle = {};
  let chatBodyAddonStyle = {};
  if (isMobile) {
    chatContentAddonStyle = {
      height:
        isChatConnected && selectedChatType
          ? "calc(100% - 40px)"
          : "calc(100% - 100px)",
    };
    chatMessageBoxAddonStyle = {
      position: "absolute",
      width: "100%",
      bottom: "120px",
    };
    chatBodyAddonStyle = {};
  } else {
    chatContentAddonStyle = {
      height:
        isChatConnected && selectedChatType
          ? "calc(100% - 40px)"
          : "calc(100% - 100px)",
    };
    chatMessageBoxAddonStyle = {
      position: "absolute",
      width: "100%",
      bottom: "15px",
    };
    chatBodyAddonStyle = {
      height: "calc(100% - 40px)",
      overflowY: "auto",
    };
  }

  return (
    <ThemeProvider theme={chatTheme || defaultTheme}>
      {isChatExpanded && (
        <div
          style={chatAddonStyle}
          className={`${classes.chatRoot} md:right-[18%]`}
        >
          {toggleCloseChatDialog && (
            <ConfirmDialog
              title={"Sign Out"}
              show={toggleCloseChatDialog}
              content={chatTexts.closeChatConfirmation}
              onClickNo={handleNoChatConfirmDialog}
              onClickYes={handleYesChatConfirmDialog}
              loaderText={"End chat"}
            />
          )}
          <div className={classes.chatHeader} style={chatHeaderAddonStyle}>
            {!isMobile && <div className={classes.desktopRoundHeader}></div>}
            <div className={classes.chatHeaderTitleBox}>
              <MessageIconSvg />
              <Typography
                variant="subtitle2"
                display="block"
                className={classes.chatHeaderTitle}
              >
                {chatTexts.chatWindowTitleLabel}
              </Typography>
            </div>
            <div className={classes.chatHeaderActionBox}>
              <div
                className={classes.chatButton}
                onClick={handleShowConfirmCloseChatWindow}
                style={{ marginLeft: "10px" }}
              >
                <CloseIconSvg />
              </div>
              <div
                className={classes.chatButton}
                onClick={() => {
                  setIsChatExpanded(false);
                }}
              >
                <MinimizeIconSvg />
              </div>
            </div>
          </div>

          {/* CONTENTS */}

          <div className={classes.chatContent} style={chatContentAddonStyle}>
            {/* FAQ WIDGET */}
            {selectedChatType && toggleFaq && faqDismissAnswer !== "no" && (
              <FaqWidget
                faqType={selectedChatType?.toUpperCase()}
                onDismiss={handleDismissFaq}
                onFaqClick={onFaqClick}
              />
            )}

            {/* CALLBACK */}
            {toggleCallbackDisplay && !toggleFaq && faqDismissAnswer !== "no" && (
              <CallbackOption
                fullName={
                  customerProfile && customerProfile.FullName
                    ? customerProfile.FullName
                    : ""
                }
                onYesNo={(answer) => {
                  if (answer === "no") {
                    setToggleThankYouForCallback(true);
                    setRequestForCallback(null);
                    setToggleShowWillContactCallback(null);
                  } else {
                    setRequestForCallback(true);
                    setToggleThankYouForCallback(null);
                    setToggleShowWillContactCallback(null);
                  }
                }}
                onClose={() => {
                  closeChatWindow(false);
                }}
                onSubmit={handleSubmitCallbackEnquiry}
                showThankYou={toggleThankYouForCallback}
                showForm={requestForCallback}
                showWillContact={toggleShowWillContactCallback}
              />
            )}

            {/* ENQUIRY */}
            {toggleEnquiry && (
              <ChatEnquiry
                emailAddress={emailAddress}
                insuredReferenceNumber={findAgreementData.ContractId}
                phoneNumber={mdn}
                fullName={
                  customerProfile && customerProfile.FullName
                    ? customerProfile.FullName
                    : ""
                }
                loading={toggleEnquiryLoading}
                onSubmitEnquiryForm={handleSubmitEnquiryForm}
                onCancelEnquiryForm={handleCancelEnquiryForm}
              />
            )}

            {/* THANK YOU CHAT */}
            {toggleThankYou && (
              <ThankYouWidget
                onCloseThankYouWidget={handleCloseThankYouWidget}
              />
            )}

            {/* LOADING */}
            {toggleLoading && !isNoAvailableAgents && (
              <div className={classes.loaderContainer}>
                <CircularProgress style={{ margin: "auto" }} />
              </div>
            )}

            {isNoAvailableAgents === true && (
              <div className={classes.noAvailableAgent}>
                <AgentBusySvg />
                <div className={classes.noAvailableAgentNote}>
                  {chatTexts.noAvailableAgentNote}
                </div>
              </div>
            )}

            {/* NPS SURVEY */}
            {toggleNPS && npsSurveyItems && (
              <NPSWidget
                surveyQuestion={npsSurveyItems.questionDescription}
                onSubmitHandler={async (rating) => {
                  setToggleNPS(false);
                  setToggleCSAT(true);
                  setNPS(rating);
                  handleSubmitSurvey(
                    npsSurveyItems.surveyId,
                    npsSurveyItems.questionId,
                    rating
                  );
                }}
              />
            )}
            {/* CSAT SURVEY */}
            {toggleCSAT && csatSurveyItems && (
              <CSATWidget
                surveyQuestion={csatSurveyItems.questionDescription}
                onSubmitHandler={(rating) => {
                  setToggleCSAT(false);
                  setToggleAddtlComment(true);
                  setCSAT(rating);
                  handleSubmitSurvey(
                    csatSurveyItems.surveyId,
                    csatSurveyItems.questionId,
                    rating
                  );
                }}
              />
            )}

            {/* ADDITIONAL COMMENT SURVEY */}
            {toggleAddtlComment && commentSurveyItems && (
              <AddtlCommentWidget
                surveyQuestion={commentSurveyItems.questionDescription}
                onSubmitHandler={(comment) => {
                  setToggleAddtlComment(false);
                  setComment(comment);
                  handleSubmitSurvey(
                    commentSurveyItems.surveyId,
                    commentSurveyItems.questionId,
                    comment
                  );
                  validateSurveyResult();
                }}
              />
            )}

            {/* FAQ DISMISS NO */}
            {faqDismissAnswer === "no" && (
              <EnquiryOption
                chatType={selectedChatType}
                onBackMenu={handleBackToMenu}
                onStartChat={handleChatWithUs}
                onSubmitEnquiry={handleSubmitEnquiry}
              />
            )}

            {isChatTypeSelection && (
              <div className={classes.chatHelpContainer}>
                <Typography
                  variant="h6"
                  display="block"
                  style={{ fontWeight: "lighter" }}
                >
                  {chatTexts.greetingLabel}
                </Typography>
                {chatEngagementOptions.map((item, i) => {
                  return (
                    <Button
                      variant="outlined"
                      className={classes.chatHelp}
                      key={`btn-chat-help-${i}`}
                      value={item.value}
                      onClick={() => {
                        handleStartFaq(item.value);
                      }}
                    >
                      <div className={classes.chatHelpBody}>
                        <div className={classes.chatHelpInfo}>
                          <Typography
                            variant="caption"
                            display="block"
                            className={classes.chatHelpInfoTitle}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="caption" display="block">
                            {item.description}
                          </Typography>
                        </div>
                        {item.img}
                      </div>
                    </Button>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "25px",
                  }}
                >
                  <CloseChatBtn
                    onClick={() => {
                      closeChatWindow();
                    }}
                  />
                </div>
              </div>
            )}

            {/* CONNECTED */}
            {isChatConnected && selectedChatType && (
              <>
                <div
                  className={clsx("message-list", classes.chatBody)}
                  ref={refMessageList}
                  style={chatBodyAddonStyle}
                >
                  <div className={classes.chatBodyInner}>
                    {messages?.map((message, index) => {
                      const sanitizeMessage =
                        message.messageType.toLowerCase() === "plain text"
                          ? sanitizeHtml(message.content)
                          : message.content;

                      return (
                        <div
                          key={index}
                          className={clsx({
                            [classes.speechBubble]: true,
                            [classes.agentSpeechBubble]:
                              message.source === "Agent",
                            [classes.customerSpeechBubble]:
                              message.source !== "Agent",
                          })}
                          style={{
                            textAlign:
                              message.source === "Agent" ? "left" : "right",
                          }}
                        >
                          <small>{convertToTime(message.createdAt)}</small>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizeMessage,
                            }}
                          />
                        </div>
                      );
                    })}
                    {isAgentTyping && (
                      <div>
                        <MessageLoader
                          color={Colors.BLACK_NEUTRAL}
                          backgroundColor={Colors.LIGHT_GRAY}
                          isAgent={true}
                        />
                      </div>
                    )}
                    {surveyTriggerStatus === "show" && (
                      <div>
                        <div className={classes.startSurveyBox}>
                          <SurveyRateLikeSvg />
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "700" }}
                          >
                            Your feedback is important to us
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "400" }}
                          >
                            Please take a few moment to participate in a brief
                            satisfaction survey about your SmartHome Support
                            experience today.
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setSurveyTriggerStatus("start");
                              setIsChatConnected(false);
                            }}
                          >
                            Rate our service
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div style={chatMessageBoxAddonStyle}>
                  <TextField
                    disabled={!isChatConnected}
                    tabIndex={0}
                    className="chat-textfield"
                    variant={"standard"}
                    type={"text"}
                    placeholder={chatTexts.chatInputPlaceholderText}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={sendMessage}
                    onKeyPress={sendMessage}
                    value={message}
                    inputRef={refMessageInput}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{ width: "85%", float: "left", marginLeft: "5px" }}
                  />
                  <IconButton
                    onClick={triggerSendMessage}
                    disabled={!isChatConnected}
                    className={classes.sendMessageBtn}
                    style={{
                      backgroundColor:
                        isCustomerTyping && Colors.PRIMARY_CONTRAST_TEXT,
                      float: "right",
                      marginRight: "5px",
                    }}
                  >
                    <SendIconSvg />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {!isChatExpanded && (
        <div className={`${classes.chatActions} right-[10px] md:right-[17.5%]`}>
          <div
            className={classes.chatFabSimple}
            onClick={handleShowConfirmCloseChatWindow}
          >
            {newChatMessage ? <ChatAlertIconSvg /> : <ChatIconSvg />}
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

const chatHeightVh = "100vh";
const ChatStyles = makeStyles((theme) => ({
  chatRoot: {
    backgroundColor: Colors.WHITE,
    overflowY: "auto",
  },
  chatActions: {
    position: "fixed",
    bottom: "8px",
    marginRight: '-110px',

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  chatFabSimple: {
    color: Colors.WHITE,
    height: "100px",
    width: "100px",
    overflow: "hidden",
    marginTop: "auto",
    marginLeft: theme.spacing(1),
    float: "right",
    cursor: "pointer",
  },
  desktopRoundHeader: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: Colors.PRIMARY_CONTRAST_TEXT,
    width: "100%",
    height: "10px",
    float: "left",
  },
  chatHeader: {
    // boxSizing: "border-box",
    // width: "100%",
    // padding: "10px 15px",
    // backgroundColor: "white",
    // display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "space-between",

    float: "left",
    width: "100%",
    padding: 0,
    marginTop: "-1px",
    position: "fixed",
    height: "50px",
    backgroundColor: Colors.WHITE,
    zIndex: 1200,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  chatHeaderChildren: {
    // display: "flex",
    // gap: "15px",
    // alignItems: "center",
    // justifyContent: "center",
  },
  chatHeaderTitleBox: {
    width: "70%",
    float: "left",
    padding: "10px",

    "& svg": {
      float: "left",
    },

    "& h6": {
      float: "left",
      lineHeight: "10px",
    },
  },
  chatHeaderActionBox: {
    width: "30%",
    float: "right",
    padding: "10px",

    "& div": {
      float: "right",
    },
  },
  chatHeaderTitle: {
    paddingTop: "4px",
    color: "#0F6B30",
    fontWeight: "400",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",

    "& > div": {
      margin: "auto",
    },
  },
  chatContent: {
    width: "100%",
    position: "absolute",
    top: "40px",
  },
  chatButton: {
    cursor: "pointer",
  },
  chatHelpContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    padding: "15px 15px",
    width: "100%",
    // height: "75vh",
  },
  chatHelp: {
    borderRadius: "5px",
    maxWidth: "unset",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: Colors.BLACK,
      boxShadow: `0 0 0 2px ${Colors.PRIMARY_CONTRAST_TEXT}`,
      borderColor: "transparent",
    },

    /*[theme.breakpoints.down("xs")]: {
      maxWidth: "345px",
    },*/
  },
  chatHelpBody: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
  },
  chatHelpInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: "1",
    minHeight: "90px",
    textAlign: "left",
  },
  chatHelpInfoTitle: {
    color: Colors.PRIMARY,
    fontSize: "1rem",
    fontWeight: "700",
    "& ~ span": {
      fontSize: "0.875rem",
    },
  },
  chatBody: {
    boxSizing: "border-box",
    width: "100%",
    height: "calc(100% - 150px)",
    overflowY: "auto",
    padding: "20px 20px 30px",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    scrollBehavior: "smooth",
  },
  chatBodyInner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  speechBubble: {
    display: "inline-block",
    maxWidth: "330px",
    marginBottom: "5px",
    "& small": {
      display: "block",
      fontSize: "12px",
      paddingLeft: "10px",
      paddingBottom: "5px",
      textAlign: "left",
      color: Colors.GRAY_NEUTRAL,
    },
    "& span": {
      display: "inline-block",
      borderRadius: "16px",
      padding: "15px",
      textAlign: "left",
      animationName: `$scaleAnimation`,
      animationDuration: "0.25s",
      animationIterationCount: "linear",
    },
  },
  agentSpeechBubble: {
    marginRight: "auto",
    "& span": {
      color: Colors.BLACK_NEUTRAL,
      backgroundColor: Colors.LIGHT_GRAY,
      borderBottomLeftRadius: 0,
      transformOrigin: "0 100%",
      lineHeight: "22px",
    },
    "& + $agentSpeechBubble small": {
      display: "none",
    },
  },
  customerSpeechBubble: {
    marginLeft: "auto",
    "& span": {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY_CONTRAST_TEXT,
      borderBottomRightRadius: 0,
      textAlign: "right",
      transformOrigin: "100% 100%",
      lineHeight: "22px",
      "& a": {
        color: "white",
      },
    },
  },
  article: {
    backgroundColor: "black",
    paddingTop: "10px",
    borderRadius: "10px",
  },
  articleImg: {
    margin: "10px auto",
    display: "block",
  },
  articleDetails: {
    padding: "10px",
  },
  articleLink: {
    display: "block",
    textAlign: "center",
    color: "white",
  },
  sendMessageBtn: {
    backgroundColor: Colors.DARK_GRAY,
    padding: "8px",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: Colors.DARK_GRAY,
    },
  },

  startSurveyBox: {
    width: "315px",
    margin: "0 auto",
    marginTop: "45px",
    height: "229px",
    border: `1px solid ${Colors.PRIMARY_CONTRAST_TEXT}`,
    borderRadius: "5px",
    padding: "20px 15px",
    textAlign: "center",

    "& svg": {
      margin: `0 auto`,
      marginBottom: "8px",
    },

    "& p": {
      fontSize: "16px",
      fontWeight: 700,
      color: Colors.BLACK_NEUTRAL,
    },

    "& p:last-child": {
      fontWeight: 400,
    },

    "& button": {
      minWidth: "154px",
      width: "154px",
      height: "44px",
      marginTop: "15px",
    },
  },

  noAvailableAgent: {
    top: "150px",
    width: "270px",
    border: `1px solid #C7C7C5`,
    borderRadius: "6px",
    textAlign: "center",
    padding: "15px",
    margin: "40% auto",

    "& p": {
      marginBottom: "10px",
    },

    "& svg": {
      marginTop: "15px",
      marginBottom: "15px",
      margin: "0 auto",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "335px",
    },
  },
}));
