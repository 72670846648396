import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getContactEmail, getContactNumber, getFormmatedDate, getMetadata, isEmpty } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import logger from "../../../logger.service";
import { logGTMEvent } from "../api-helper";
import { GTM_EVENT } from "../../../utils/constants";

const ProvideDeviceInsuranceDetails = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideDeviceInsuranceDetails']
  const homeplusscript = workflowState['config']?.scripts["ProvideDeviceInsuranceDetails"];

  const [companyName, setCompanyName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState({});
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtInsuranceDetailsInfo = findById(metadata, "txtInsuranceDetailsInfo");
  let insuranceOptions = findById(metadata, "insuranceOptions");
  let inputCompanyName = findById(metadata, "inputCompanyName");
  let inputPolicyNumber = findById(metadata, "inputPolicyNumber");
  let menu = findById(metadata, "menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");
  // let txtFooter = findById(metadata, "textFooter")

  const insuranceOptionList = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  let FindAgreementResponse = workflowState["FindAgreementResponse"];
  let fileClaimDetails = workflowState["FileClaimDetails"];
  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
  // const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;
  let ServiceRequestResponse;
  let ProcessIncidentResponse;
  const contact = getContactNumber(agreement?.ContactPoints?.ContactPoint);
  const contactEmailAddress = getContactEmail(agreement?.ContactPoints?.ContactPoint);

  const onContinueClick = (insuranceOption) => {

    // call createServiceRequest Api
    callCreateServiceRequestApi(insuranceOption);
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const callCreateServiceRequestApi = (insuranceOption) => {
    showLoader(true)
    const CreateServiceRequestParams = {
        CreateServiceRequestParams: {
          InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
          AgreementId: agreement?.AgreementId,
        },
      };

      API[ActionTypes.CREATE_SERVICE_REQUEST](CreateServiceRequestParams)
        .then((data) => {
        showLoader(false)
         ServiceRequestResponse = data.data.CustomerCase;
          updateState({
            ...workflowState,
            FileClaimDetails: {
              // fromMyClaimScreentoIncidentDetails: fileClaimDetails.fromMyClaimScreen ? true : false,
              ServiceRequestResponse: ServiceRequestResponse,
            },
          }, ["FileClaimDetails"]);
          
          // log claim number event
          logGTMEvent({
            'event': GTM_EVENT.CLAIM_NO_EVENT,
            'claimNo': ServiceRequestResponse?.CustomerCaseNumber,
          })

          callSubmitTraigeApi(insuranceOption);

          return completeCurrentStep(currentStep, {
            provideIssueDetails: true,
          });
        })
        .catch((error) => {
          showLoader(false)
          console.log(`error is ${error}`);
          logger({ type: ActionTypes.CREATE_SERVICE_REQUEST, error, state: workflowState});
          refreshStateOnLogout(workflowState, "service-unavailable");
          updateState({
            ...workflowState,
            [currentStep]: {
              ...workflowState[currentStep],
            },
          });
        });
  }

  const callSubmitTraigeApi = (insuranceOption) => {
    showLoader(true);
    const SubmitTriageParameters = {
      SubmitTriageParameters: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        Triage: fileClaimDetails?.triageAnswers,
        Address: fileClaimDetails?.selectedIssueAddress,
        IncidentQA: [
          {
            Question: "Device Owner",
            Answer: fileClaimDetails?.isOtherOwnerSelected
              ? fileClaimDetails?.ownerName
              : agreement?.Customers?.Customer[0]?.FullName,
          },
          {
            Question: "Device Owner Relationship",
            Answer: fileClaimDetails?.isOtherOwnerSelected
              ? fileClaimDetails?.ownerRelation
              : "MySelf",
          },
          {
            Question: "Discovered By",
            Answer: fileClaimDetails?.isOtherObservedBySelected
              ? fileClaimDetails?.observedByName
              : agreement?.Customers?.Customer[0]?.FullName,
          },
          {
            Question: "Discovered Owner Relationship",
            Answer: fileClaimDetails?.isOtherObservedBySelected
              ? fileClaimDetails?.observedByRelation
              : "MySelf",
          },
        ],
        LossDescription: fileClaimDetails.lossDescription,
        TravelInsurance: policyNumber,
        InsuranceCompany: companyName,
        FailureDescriptiveText: fileClaimDetails?.issueDetails,
        // IncidentDate: getFormmatedDate(
        //   fileClaimDetails.occurenceDate,
        //   "yyyy-mm-dd",
        //   "-"
        // ),
        AssetFailureDate: getFormmatedDate(
          fileClaimDetails.occurenceDate,
          "yyyy-mm-dd",
          "-"
        ),
      },
    };

    API[ActionTypes.SUBMIT_TRIAGE_QUESTIONS](SubmitTriageParameters)
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          SubmitTriageResponse: data,
        });

        // // call service order api here
        // callCreateServiceOrderApi(insuranceOption);

        // call process incident api here
        callProcessIncidentApi(insuranceOption);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.SUBMIT_TRIAGE_QUESTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callProcessIncidentApi = (insuranceOption) => {
    showLoader(true);

    const ProcessIncidentParameters = {
      ProcessIncidentParameters: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
        ClientOfferId: agreement?.ClientOffer?.ClientOfferId,
        MobileNumber: contact,
        Incident: {
          AgreementId: agreement?.AgreementId,
          CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
          FailureDescriptiveText: "",
          IncidentType: fileClaimDetails?.incidentType,
          IncidentDate: "",
          LossDescription: fileClaimDetails?.lossDescription,
          Triage: {
            TriageAccepted: true,
            TriageRefusalReason: "Triage refused by customer",
            Notes: "",
          },
        },
        AssetDetails: fileClaimDetails?.AssetDetails,
      },
    };
    API[ActionTypes.PROCESS_INCIDENT](ProcessIncidentParameters)
      .then((data) => {
        showLoader(false);

        ProcessIncidentResponse = data.data.CustomerCase;

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            ProcessIncidentResponse: ProcessIncidentResponse,
          },
        }, ["FileClaimDetails"]);

        // check for eligibility
        if (ProcessIncidentResponse.Eligibility[0].EligibilityOutcome === "Approved")
          // call get fulfilment api
          callGetFulfilmentApi(insuranceOption);
        else {
          createContactUsInquiry();
          return completeCurrentStep(currentStep, {
            // showClaimMessage: true,
            showTriageError: true
          });
        }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.PROCESS_INCIDENT, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const createContactUsInquiry = () => {
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: contact,
        FirstName: agreement?.Customers?.Customer[0]?.FirstName,
        LastName: agreement?.Customers?.Customer[0]?.LastName,
        ContactNumber: contact,
        ClientName: workflowState["config"]?.ClientName,
        AgreementId: agreement?.AgreementId,
        InquiryChannel: "ONLINE",
        InquiryType: "Claims Processing",
        InquirySubType: "Claims Cancellation / Rejection",
        InquiryDescription: fileClaimDetails?.lossDescription,
        ClientId: workflowState["config"]?.ClientId,
        Email: contactEmailAddress,
        DepartmentType: "Care",
        Priority: "High",
        ProgramName: "SmartSupport Home",
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        PolicyNumber: agreement?.ContractId,
      },
    };

    API[ActionTypes.CREATE_ENQUIRY_V1](CreateInquiryParams)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          enquiryResponse: data,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_ENQUIRY_V1, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callGetFulfilmentApi = (insuranceOption) => {
    showLoader(true);

    API[ActionTypes.GET_FULFILMENT_OPTIONS](
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            GetFulfilmentResponse: data,
          }
        }, ["FileClaimDetails"]);

        callSetFulfilmentApi(insuranceOption);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_FULFILMENT_OPTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callSetFulfilmentApi = (insuranceOption) => {
    showLoader(true);

    const SetFulfillmentOptionParameters = {
      SetFulfillmentOptionParameters: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        FulfillmentOption: "PUR",
      },
    };
    API[ActionTypes.SET_FULFILMENT_OPTIONS](
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      SetFulfillmentOptionParameters
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            SetFulfilmentResponse: data,
            // incidentType: getIncidentType(),
            // lossDescription: getLossDescription(),
            // fromMyClaimScreentoIncidentDetails: fileClaimDetails.fromMyClaimScreen ? true : false,
            ServiceRequestResponse: ServiceRequestResponse,
          },
        }, ["FileClaimDetails"]);
    
        // call service order api here
        callCreateServiceOrderApi(insuranceOption);
        
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.SET_FULFILMENT_OPTIONS, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const callCreateServiceOrderApi = (insuranceOption) => {
    showLoader(true);

    const CreateServiceOrderParameters = {
      CreateServiceOrderParameters: {
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        ApprovedServiceFeeChange: false,
        ServiceOrder: {
          // FulfillmentMethodType: "REPAIR",
          FulfillmentMethodType: fileClaimDetails?.selectedDevice?.FulfillmentMethodType,
          ServiceRequestId:
            ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
          CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
          ServiceOrderLines: {
            ServiceOrderLine: [
              {
                ServiceOrderLineType:
                  fileClaimDetails?.selectedDevice?.AssetCategory
                    ?.AssetCategoryName,
                Quantity: 1,
                AssetCatalogId: fileClaimDetails?.AssetDetails?.AssetCatalogId,
                VendorItemId: fileClaimDetails?.selectedDevice?.ItemId,
                Priority: "MNDTRY",
                VenderItemType:
                  fileClaimDetails?.selectedDevice?.AssetCategory
                    ?.AssetCategoryName,
                VendorData: {
                  IsLikeForLike: true,
                  IsSimCard: false,
                  IsInStock: true,
                },
              },
            ],
          },
        },
      },
    };
    API[ActionTypes.CREATE_SERVICE_ORDER](CreateServiceOrderParameters)
      .then((data) => {
        showLoader(false);
        const isAUTOREJECTHoldExist = ProcessIncidentResponse?.Holds?.filter(item => item.HoldType === "AUTOREJECT")?.length > 0;
        // const isAUTOREJECTHoldExist = fileClaimDetails?.ProcessIncidentResponse?.Holds?.filter(item => item.HoldType === "AUTOREJECT")?.length > 0;
        // const isAUTOREJECTHoldExist = false;
        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            selectedInsuranceOption: insuranceOption,
            companyName: companyName?.trim(),
            policyNumber: policyNumber?.trim(),
            isOtherInsuranceSelected: isEmpty(insuranceOption),
            ServiceOrderResponse: data.data.CreateServiceOrderResults,
          },
        }, ["FileClaimDetails"]);

        // move to next screen
        return completeCurrentStep(currentStep, {
          Continue: isAUTOREJECTHoldExist ? false: true,
          showClaimMessage: isAUTOREJECTHoldExist ? true : false,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_SERVICE_ORDER, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const onInsuranceOptionSelect = (insuranceOption) => {
    setSelectedInsuranceOption({
      ...selectedInsuranceOption,
      ...insuranceOption,
    });

    const showInsuranceDetailsBox = insuranceOption.value === "Yes";

    inputCompanyName.args = {
      ...inputCompanyName.args,
      other: showInsuranceDetailsBox ? "block" : "hidden",
    };
    inputPolicyNumber.args = {
      ...inputPolicyNumber.args,
      other: showInsuranceDetailsBox ? "block" : "hidden",
    };

    if (!showInsuranceDetailsBox) onContinueClick(insuranceOption);
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  inputCompanyName.args = {
    ...inputCompanyName.args,
    label: homeplusscript.inputCompanyNameLabel,
    placeHolder: homeplusscript.inputCompanyNamePlaceHolder,
    value: companyName,
    onChange: setCompanyName,
  };

  inputPolicyNumber.args = {
    ...inputPolicyNumber.args,
    label: homeplusscript.inputPolicyNumberLabel,
    placeHolder: homeplusscript.inputPolicyNumberPlaceHolder,
    value: policyNumber,
    onChange: setPolicyNumber,
  };

  insuranceOptions.args = {
    ...insuranceOptions.args,
    deviceItems: insuranceOptionList,
    value: selectedInsuranceOption?.value,
    onClick: onInsuranceOptionSelect,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: companyName?.trim() && policyNumber?.trim(),
    displayClasses:
      selectedInsuranceOption?.value === "Yes" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtInsuranceDetailsInfo.args = {
    ...txtInsuranceDetailsInfo.args,
    content: homeplusscript.labelInsuranceDetailsInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedInsuranceOption?.value === "Yes" ? "flex justify-between lg:justify-center" : "flex justify-center"
  };

  // txtFooter.args = {
  //   ...txtFooter.args,
  //   other: selectedInsuranceOption?.value === "Yes" ? "block" : "hidden"
  // }

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default ProvideDeviceInsuranceDetails;
