import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata, isEmpty } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import logger from "../../logger.service";

function ForgotEmail() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ForgotEmail']
  let homeplusscript = workflowState['config']?.scripts["ForgotEmail"];

  let textHeaderHomePlus = findById(metadata, "textHeaderHomePlus");
  let textEmailSent = findById(metadata, "textEmailSent");
  let textUserNotFound = findById(metadata, "textUserNotFound");

  let headerForgotEmail = findById(metadata, "headerForgotEmail");
  let retrivedMailAddress = findById(metadata, "retrivedMailAddress");
  let inputMobileNumber = findById(metadata, "inputMobileNumber");
  let inputPostalCode = findById(metadata, "inputPostalCode");
  // let mobileNumberError = findById(metadata, "mobileNumberError");
  let otpInput = findById(metadata, "otpInput");
  let txtProvideDetails = findById(metadata, "txtProvideDetails");
  let txtForgotEmailInfo = findById(metadata, "txtForgotEmailInfo");

  let btnCancel = findById(metadata, "btnCancel");
  let btnContinue = findById(metadata, "btnContinue");

  const [mobileNumber, setMobileNumber] = useState({});
  const [postalCode, setPostalCode] = useState("");
  const [otp, setOtp] = useState("");
  const [showEmailSendText, setShowEmaiSendText] = useState(false);
  const [showRetrivedEmail, setShowRetrivedEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [showUserNotFound, setShowUserNotFound] = useState(false);
  const [showProvideDetailsInfo, setShowProvideDetailsInfo] = useState("block");
  const [showMDNLengthError, setShowMDNLengthError] = useState(false);

  const onMobileNumberChange = (value, country, e, formattedValue) => {
  
    setMobileNumber({
      value: value,
      country: country,
      formattedValue: formattedValue,
    });
      if(value.length < 10){
        setShowMDNLengthError(true);
      }
      else{
        setShowMDNLengthError(false);
      }
  };

  const onContinueClick = () => {
    setShowEmaiSendText(true);
    setShowUserNotFound(false);
    showLoader(true);
   
    //-------------------PayLoad for API Request----------------------------------//
    const VerifyCustomerParameters = {
      VerifyCustomerParameters: {
        // MobileDeviceNbr: mobileNumber.value.slice(countryCodeDigits - 1),
        // MobileDeviceNbr: mobileNumber.value.slice(mobileNumber.country.dialCode.length),
        // MobileDeviceNbr: mobileNumber.value,
        PostalCode: postalCode,
        NRIC: otp,
      },
    };
    console.log("Payload : ",VerifyCustomerParameters)
    //--------------------------API POST Call-------------------------------------//
    API[ActionTypes.SET_RECOVER_EMAIL](VerifyCustomerParameters)
      .then((data) => {
        console.log("Find User Emai Data : ", data);
        if (data.data.VerifyCustomerResults.isSuccess) {
          setShowRetrivedEmail(true);
          showLoader(false);
          setEmail(data.data.VerifyCustomerResults.result);
          onLoginClick(data.data.VerifyCustomerResults.result);
        } else {
          showLoader(false);
          setShowEmaiSendText(false);
          setShowRetrivedEmail(false);
          setShowUserNotFound(true);
          setShowProvideDetailsInfo("hidden");
        }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        logger({ type: ActionTypes.SET_RECOVER_EMAIL, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
      });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack : true
    })
  };
const onLoginClick = (useremail) => {
    updateState({...workflowState, LoginDetails:{userEmail:useremail}});
    return completeCurrentStep(currentStep, {
      ValidEmail : true
    })
  };
  const checkContactLength = () => {
    if(!isEmpty(postalCode)){
      let checkOutput =  postalCode.length === 6 ?  true : false;
      return checkOutput;
    }
    return false;
  }

  const handlePostalCode=(code)=>{
    if(code.length <= 6) setPostalCode(code)
  }

  textHeaderHomePlus.args = {
    ...textHeaderHomePlus.args,
    content: homeplusscript.textHeaderHomePlus,
  };

  textEmailSent.args = {
    ...textEmailSent.args,
    content: homeplusscript.textEmailSent,
    displayClasses: "hidden",
  };
  textUserNotFound.args = {
    ...textUserNotFound.args,
    content: homeplusscript.textUserNotFound,
    displayClasses: showUserNotFound ? "block" : "hidden",
  };
  headerForgotEmail.args = {
    ...headerForgotEmail.args,
    content: homeplusscript.headerForgotEmail,
  };
  txtProvideDetails.args={
    ...txtProvideDetails.args,
    displayClasses : showProvideDetailsInfo,
  }
  retrivedMailAddress.args = {
    ...retrivedMailAddress.args,
    content: email,
    displayClasses: showRetrivedEmail ? "block" : "hidden",
  };

  inputMobileNumber.args = {
    ...inputMobileNumber.args,
    label: homeplusscript.inputMobile_label,
    placeHolder: homeplusscript.inputMobile_placeHolder,
    value: mobileNumber.value,
    onChange: onMobileNumberChange,
  };

  inputPostalCode.args = {
    ...inputPostalCode.args,
    label: homeplusscript.labelPostalCode,
    value: postalCode,
    onChange: handlePostalCode,
  };

  // mobileNumberError.args = {
  //   ...mobileNumberError.args,
  //   content: homeplusscript.mobileNumberError,
  //   displayClasses : showMDNLengthError ? "block" : "hidden",
  // };

  otpInput.args = {
    ...otpInput.args,
    // label: homeplusscript.enterOTP,
    onChange: (val) => {
      setOtp(val.join(""));
    },
  };

  btnCancel.args = {
    ...btnCancel.args,
    label: homeplusscript.cancel_label,
    enabled: true,
    onClick: (e) => onGoBackClick(),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.continue_label,
    enabled: (postalCode && otp.length === 4) ? checkContactLength() : false,
    onClick: (e) => onContinueClick(),
  };

  txtForgotEmailInfo.args = {
    ...txtForgotEmailInfo.args,
    displayClasses : showProvideDetailsInfo ? "visible" : "invisible",
  }

  return (
    <>
      <Stack orientation="vertical" metadata={metadata} />
    </>
  );
}

export default ForgotEmail;
