import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";

import { FAQ } from "./faq";
import { Colors } from "./entities/Colors";
import { Minimize } from "@material-ui/icons";

const faqTexts = {
  title: "Select your enquiry below",
  dismissTitle: "Did we answer your question?"
};

export const FaqWidget = ({ faqType, onDismiss, onFaqClick }) => {
  const faqs = FAQ[faqType];
  const [activeCategory, setActiveCategory] = useState(null);
  const [questionItems, setQuestionItems] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const classes = FaqStyles();

  const QuestionAccordion = () => {
    return (
      <Grid item xs={12} className={classes.questionRoot}>
        {questionItems &&
          questionItems.length > 0 &&
          questionItems.map((item, x) => {
            const Answer = () => { return <>{item.answer(onFaqClick)}</> }

            return (
              <Accordion
                key={`accordion-${x}`}
                expanded={expandedQuestion === x ? true : false}
                onChange={(event, expanded) => {
                  setExpandedQuestion(expanded ? x : null);
                }}
              >
                <AccordionSummary
                  id={`panel-question-${x}`}
                  key={`accordion-summary-${x}`}
                  expandIcon={
                    expandedQuestion === x ? (
                      <Minimize className={classes.questionIcon} />
                    ) : (
                      <AddIcon className={classes.questionIcon} />
                    )
                  }
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionAnswer}>
                  <Answer />

                  <div className={classes.confirmAnswer}>
                    <Typography paragraph style={{ fontWeight: '700' }}>{faqTexts.dismissTitle}</Typography>
                    <Button variant='contained' color={'primary'} onClick={() => onDismiss('yes')}>Yes</Button>
                    <Button variant='contained' color={'primary'} onClick={() => onDismiss('no')}>No</Button>
                  </div>

                </AccordionDetails>
              </Accordion>
            );
          })}
      </Grid>
    );
  };

  useEffect(() => {
    if (faqs && faqs.length > 0 && faqs[0].question) {
      // no categories only questions
      setActiveCategory(faqs);
      setQuestionItems(faqs);
    }
  }, [faqs]);

  useEffect(() => {
    console.log("Active Category", activeCategory);
  }, [activeCategory]);

  return (
    <Box className={classes.faqRoot}>
      <Typography
        variant="h6"
        display="block"
        style={{ fontWeight: "lighter" }}
      >
        {faqTexts.title}
      </Typography>
      <Grid container className={classes.categoryRoot}>
        {faqs.map((category, index) => {
          const Icon = () => { return <>{category.icon}</> };

          if (category && !category.question) {
            return (
              <Grid
                item
                xs={6}
                className={classes.categoryItem}
                key={`item-${index}`}
              >
                <Box
                  display="flex"
                  justifyContent={`${index === 0 ? "flex-end" : "flex-start"}`}
                  style={{ paddingRight: index === 0 ? '15px' : '0' }}
                >
                  <Button
                    variant="outlined"
                    key={`category-${index}`}
                    style={{ minWidth: index === 0 ? `122px` : `132px` }}
                    className={
                      activeCategory && activeCategory.label === category.label
                        ? classes.activeCategory
                        : ""
                    }
                    onClick={(e) => {
                      setActiveCategory(category);
                      setQuestionItems(category.items);
                    }}
                  >
                    <Icon />
                    {category.label}
                  </Button>
                </Box>
              </Grid>
            );
          } else {
            return <div key={`item-${index}`}></div>
          }
        })}
        <QuestionAccordion />
      </Grid>
    </Box>
  );
};

const FaqStyles = makeStyles((theme) => ({
  faqRoot: {
    float: "left",
    width: "100%",
    height: "100%",
    padding: "20px 10px 10px",
    // maxWidth: "390px",
    overflow: "auto",

    [theme.breakpoints.down('xs')]: {
      padding: "20px 10px 30px",
    },

    "&> h6": {
      textAlign: "center",
    },
  },
  questionRoot: {
    marginTop: "20px",
    textAlign: 'left',

    '& a': {
      color: Colors.PRIMARY,
      fontWeight: 700,
      cursor: 'pointer'
    }
  },
  categoryRoot: {
    marginTop: "20px",
    textAlign: 'center',

    "& button": {
      // minWidth: "122px",
      height: "44px",
      // padding: "10px",
      padding: "10px 8px",
      gap: "10px",
      border: `1px solid ${Colors.PRIMARY_CONTRAST_TEXT}`,
      borderRadius: "5px",
      minWidth: "unset"
    },
  },
  activeCategory: {
    fontWeight: 700,
    color: Colors.PRIMARY,
    backgroundColor: Colors.PRIMARY_LIGHT,
  },
  accordionAnswer: {
    display: "block",
  },
  questionIcon: {
    color: Colors.PRIMARY_CONTRAST_TEXT,
  },
  confirmAnswer: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 700,
    fontSize: '17px',
    marginTop: '20px',

    '& button': {
      color: Colors.BLACK,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      width: '80px',
      minWidth: '50px',
      height: '44px',
      marginTop: '15px'
    },

    '& button + button': {
      marginLeft: '50px'
    }
  }
}));
