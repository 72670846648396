import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, validateFileType } from "../helper";

import { uploadFileToBucket, getSignedRequestForUpload } from '../../../utils/upload-utils'

function UploadPurchaseReceipt() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['UploadPurchaseReceipt']
  const homeplusscript = workflowState['config']?.scripts["UploadPurchaseReceipt"];

  const [selectedFile, setSelectedFile] = useState();
  const [pdfFileContent, setPDFFileContent] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState("");
  const [generatedFileName, setGeneratedFileName] = useState(null)

  let fileUploader = findById(metadata, "fileUploader");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let titleText = findById(metadata, "txtTitle");
  let txtReceiptInfo = findById(metadata, "txtReceiptInfo");
  let menu = findById(metadata,"menu");
  let fileUploadError = findById(metadata, "fileUploadError");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const handleProgress = ({ loaded }, totalSize) => {
      console.log({loaded, totalSize})
      const percentage = Number.parseInt((loaded/totalSize) * 100);
      setProgress(percentage);
  }

  const handleSuccess = () => {
    setProgress(100);
  }

  const saveGeneratedFileName = (url, file) => {
    const fileName = url.split("?")?.[0]?.split("/")?.pop()
    const extension = file?.name?.split(".").pop()
    setGeneratedFileName(`${fileName}.${extension}`)
  }

  async function uploadToBucket(fileToUpload){
    console.log(fileToUpload?.type)
    showLoader(true)
    const signedUrl = await getSignedRequestForUpload(agreement?.AgreementId)
    showLoader(false)
    saveGeneratedFileName(signedUrl, fileToUpload)
    await uploadFileToBucket(fileToUpload, signedUrl, handleProgress, handleSuccess)
  }
  
  let handleChange = async (e) => {

    const [file] = e.target.files;

    if(!file){
      return
    }

    if (!validateFileType(file?.name)) {
      setFileError(homeplusscript.invalidFileType);
    } else if (file.size > 31457280) {
      setFileError(homeplusscript.fileUploadError);
    } else {
      setFileError("");
      if (file?.type === "application/pdf") {
        setSelectedFile(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          console.log(e.target.result);
          setPDFFileContent(e.target.result);
        };
      } else {
        setSelectedFile(file);
      }

      await uploadToBucket(file)
    }
  };

  const removeFile = () => {
    setSelectedFile();
    setPDFFileContent(null);
    setGeneratedFileName(null);
    setProgress(0);
  };

  console.log({ generatedFileName })

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        purchasedReceiptFile : selectedFile,
        purchasedReceiptAdded : true,
        purchasedReceiptPdf: pdfFileContent,
        purchasedReceiptBucketPath: "",
        receiptFileName: generatedFileName
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      providePurchaseDate: true,
    });
  };

  const onGoBackClick = () => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        purchasedReceiptFile : undefined,
        purchasedReceiptAdded : false,
        purchasedReceiptPdf: null,
        purchasedReceiptBucketPath: null,
        receiptFileName: null
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  }

 
  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title,
  };

  txtReceiptInfo.args = {
    ...txtReceiptInfo.args,
    content: homeplusscript.uploadInfo,
    displayClasses: selectedFile ? "hidden" : "block"
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  fileUploader.args = {
    ...fileUploader.args,
    selectedFile: selectedFile,
    pdfFileContent: pdfFileContent,
    handleChange: (e) => handleChange(e),
    removeFile: removeFile,
    progress: progress,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: progress === 100 && selectedFile,
    onClick: (e) => onContinueClick(),
    displayClasses: selectedFile ? "block" : "hidden",
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"]

  };

  fileUploadError.args = {
    ...fileUploadError.args,
    content: fileError,
  };

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedFile ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default UploadPurchaseReceipt;
