import moment from 'moment';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import randomstring from 'randomstring';
import reversestring from 'reverse-string';
import md5 from 'md5';
import sha1 from 'js-sha1';
import jwt from 'jsonwebtoken';
import { API, graphqlOperation } from 'aws-amplify';
import { AppSyncHelper, ChatStatus } from './service/ChatService';
import { reverseString } from './utils';

const uuid = require('uuid');

export class ChatManager {
  subscriptions;

  activeChat = false;

  requestId;
  cognitoId;
  conversationId;
  visitorId;
  skillId;
  language;
  client;
  environment;
  engagementType;
  region;
  chatType;
  mdn;
  status;
  twilioHost;
  twilioTaskQueueSID;
  getTokenApiKey = 'c14c2c592a34e4dbe4c2aa525bd0efe8';
  hua;
  isResolved;

  onConnecting;
  onAnswered;
  onConnected;
  onDisconnected;
  onAgentTyping;
  onMessageReceived;
  onMessageUpdated;
  onStatusUpdated;

  constructor({
    client,
    twilioHost,
    twilioTaskQueueSID,
    skillId = null, 
    chatType = null, 
    language = null, 
    engagementType = null, 
    mdn = '00000000'
  }) {
    this.client = client;
    this.mdn = mdn;
    // this.environment = env;
    this.language = language || 'en-US';
    this.chatType = chatType || 'SOLUTO';
    this.engagementType = engagementType || 'CHAT';
    this.twilioHost = twilioHost;
    this.twilioTaskQueueSID = twilioTaskQueueSID;
    this.skillId = skillId
      ? skillId
      : '';
    //this.activeChat = activeChat;

    this.hua = md5(navigator.userAgent);

    this.subscriptions = [];
    this.updateStatus(ChatStatus.Initialized);

    console.log('Chat Manager initialized', this.chatType, this.mdn, this.skillId, this.engagementType, this.twilioTaskQueueSID, this.twilioHost)
  }

  updateStatus(status) {
    this.status = status;
    this.onStatusUpdated?.();
  }

  createVisitor(visitorId) {
    var m = `mutation createVisitor($input:CreateVisitorInput!){
               createVisitor(input:$input){
                  visitorCognitoId
                  visitorId
                  userName
                  clientId
                  languageCode
                  browserAgent
                  ipAddress
                  journeyStatus
                  mdn
              }
            }`;

    var data = {
      startTimestamp: new Date().toISOString(),
      visitorId: visitorId,
      languageCode: this.language,
      visitorCognitoId: this.region + ChatManager.uuidv4(),
      userName: ChatManager.uuidv4(),
      clientId: process.env.REACT_APP_CHAT_CLIENT_ID,
      browserAgent: navigator.userAgent,
      ipAddress: '127.0.0.1',
      journeyStatus: 'Started',
      mdn: this.mdn
    };

    return new Promise(resolve => {
      resolve(API.graphql(graphqlOperation(m, { input: data })));
    });
  }

  hasActiveChat = () => {
    return this.activeChat;
  };

  listenForConversation = (requestId, appSyncConfig) => {
    let observable = null;
    let subscription = null;

    try {
      observable = AppSyncHelper.subscribe(
        this.createConversationGraphQL,
        '{requestId}',
        requestId
      );
      subscription = observable.subscribe((data) => {
        let onCreateConversation = data.value.data.onCreateConversation;
        let conversationId = onCreateConversation.conversationId;
        let visitorId = onCreateConversation.visitorId;

        this.conversationId = conversationId;
        this.visitorId = visitorId;

        /*chatRequestIdEntity.set(requestId);
                chatConversationIdEntity.set(conversationId);
                chatVisitorIdEntity.set(visitorId);
                ongoingChatEntity.set(true);*/

        console.log('Chat Accepted: ' + conversationId);

        this.createChatAudit('Chat Acknowledged', requestId, visitorId);

        //connect to created conversation
        this.connect(null, null, appSyncConfig);

        this.onAnswered?.();
      });
    } catch (e) {
      console.error(e);
    }
    this.subscriptions.push(subscription);
  };

  create = (
    activeChat,
    appSyncConfig,
    mdn,
    requestId,
    conversationId,
    visitorId
  ) => {
    let _requestId = null;
    if (requestId) {
      _requestId = requestId;
      console.log('Request Id was provided', _requestId);
    }

    let _conversationId = null;
    if (conversationId) {
      _conversationId = conversationId;
      console.log('Conversation Id was provided', _conversationId);
    }

    let _visitorId = null;
    if (visitorId) {
      _visitorId = visitorId;
      console.log('Visitor Id was provided', _visitorId);
    }

    if (activeChat && !!_requestId && !!_conversationId) {
      return this.connect(_requestId, _conversationId, appSyncConfig);
    } else {
      return new Promise(resolve => {
        if (!_requestId) {
          _requestId = ChatManager.uuidv4().toUpperCase();
          console.warn('Request Id is null, created new Request Id', _requestId);
        }

        if (!_visitorId) {
          _visitorId = ChatManager.uuidv4().toUpperCase();
          console.warn('Visitor Id is null, created new Visitor Id:', _visitorId);
        }

        this.mdn = mdn || '';

        //create a chat request
        this.createVisitor(_visitorId).then((result) => {
          if (result) {
            const visitor = result.data.createVisitor;

            this.visitorId = visitor.visitorId;

            this.getAuthToken().then(tokens => {
              if (tokens && tokens.authToken && tokens.jwtToken) {
                console.log('Tokens', tokens);

                this.createTask(_requestId, visitor.visitorId, tokens).then(() => {
                  this.updateStatus(ChatStatus.Connecting);
                  this.onConnecting && this.onConnecting();

                  this.createChatRequest(
                    _requestId,
                    visitor.visitorId,
                    ChatManager.uuidv4()
                  ).then(createChatRequestResult => {
                    this.requestId = _requestId;
                    this.visitorId = visitor.visitorId;

                    //add listeners before creating the chat request
                    this.listenForConversation(_requestId, appSyncConfig);
                    resolve(createChatRequestResult);
                  });
                });
              } else {
                console.log('No tokens defined.');
              }
            });
          }
        });
      });
    }
  };

  getAuthToken = () => {
    return new Promise((resolve, reject) => {
      let url = `${this.twilioHost}/v1/authtoken`;

      let date = new Date().getTime();

      let headers = `x-asurion-date=${date},user-agent=${this.hua},x-asurion-apikey=${this.getTokenApiKey}`;

      let request =
        'GET\n' +
        'content-type=application/json\n' +
        'x-asurion-date=' +
        date +
        '\n' +
        headers +
        '\n' +
        '/authtoken\n';

      let sha1request = sha1(request);
      let md5request = md5(sha1request);

      axios({
        url: url,
        method: 'get',
        headers: {
          'x-asurion-date': date,
          'user-agent': navigator.userAgent,
          'x-asurion-apikey': this.getTokenApiKey,
          'x-asurion-authz-token': md5request,
        },
      })
        .then(response => {
          let tokenJWT = response.data;

          let rfToken = reverseString(tokenJWT['X-Ts-Refresh-Token']);
          let mainToken = reverseString(tokenJWT['token']);
          let sToken = tokenJWT['X-Ts-Token-Id'];

          let jwtToken = `eyJ${rfToken}.eyJ${mainToken}.${sToken}`;
          let decodedJWTToken = jwt.decode(jwtToken);
          let credentials = decodedJWTToken.credentials;

          credentials = credentials.replaceAll('\\', '')

          let authToken = JSON.parse(credentials).authtoken;

          console.log('ChatManager.getAuthToken', authToken);

          resolve({ authToken: authToken, jwtToken: jwtToken });
        })
        .catch(e => {
          console.log('Error in Get Auth Token', e);
        });
    });
  };

  createTask = (
    requestId,
    visitorId,
    tokens
  ) => {
    return new Promise((resolve, reject) => {
      let url = `${this.twilioHost}/v1/task/chat/secured`;
      let date = new Date().getTime();

      let headers = `x-asurion-date=${date},user-agent=${this.hua},x-asurion-apikey=${this.getTokenApiKey},x-asurion-authn-token=${tokens.authToken},x-asurion-security-token=${tokens.jwtToken}`;

      let attributes = {
        skillId: this.skillId,
        selected_language: this.language,
        visitor_id: visitorId,
        request_id: requestId,
        task_type: 'AGENT',
        mdn: this.mdn || '',
      };

      let request =
        'POST\n' +
        'content-type=application/json\n' +
        'x-asurion-date=' +
        date +
        '\n' +
        headers +
        '\n' +
        '/task/chat/secured\n' +
        JSON.stringify(attributes) +
        '\n';

      console.log('request', request);

      let sha1request = sha1(request);
      let md5request = md5(sha1request);

      axios({
        method: 'post',
        url: url,
        data: attributes,
        headers: {
          'x-asurion-date': date,
          'user-agent': navigator.userAgent,
          'x-asurion-apikey': this.getTokenApiKey,
          'x-asurion-authz-token': md5request,
          'x-asurion-authn-token': tokens.authToken,
          'x-asurion-security-token': tokens.jwtToken,
        },
      })
        .then(response => {
          var taskSid = response.data.taskSid;
          var requestId = response.data.requestId;

          console.log('Task Created: ' + taskSid + ' Request Id: ' + requestId);

          this.createChatAudit('Chat Initiated', requestId, visitorId);

          resolve(response);
        })
        .catch(e => {
          console.error('Error in Create Task funciton:', e);
        });
    });
  };

  hasAvailableAgents = () => {
    return new Promise((resolve, reject) => {
      var url = `${this.twilioHost}/v1/expert/available/${this.twilioTaskQueueSID}`;

      console.log('has available agents', this.twilioTaskQueueSID, url)

      axios({
        method: 'get',
        url: url,
        headers: {
          'content-type': 'application/json',
          'X-Ts-Security': this.getSecurityHeader(),
        },
      })
        .then(response => {
          if (response.data.availableExperts > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(e => {
          console.error('Error in Create Task funciton:', e);
        });
    });
  };

  createChatAudit = (auditLog, requestId, visitorId) => {
    var m = `mutation createChatAudit($input:CreateChatAudit!){
                createChatAudit(input:$input){
                  visitorId
                  createdAt
                  auditLog
                  chatAuditId
                  expertName
                  requestId
                }
            }`;

    var data = {
      auditLog: auditLog,
      createdAt: new Date().toISOString(),
      requestId: requestId,
      visitorId: visitorId,
    };

    var promise = new Promise((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(m, { input: data })));
    });

    promise.then((result) => {
      console.log(
        'Chat audit Created: ' + result.data.createChatAudit.chatAuditId + auditLog
      );
    });
  };

  getSecurityHeader = () => {
    var string = randomstring.generate(16);
    var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(string));
    var key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));

    var encrypted = CryptoJS.AES.encrypt('false', key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    var encryptedAndKey = encrypted + ';' + reversestring(string);

    return btoa(encryptedAndKey);
  };

  connect(
    requestId = null,
    conversationId = null,
    appSyncConfig
  ) {
    let chatRequestData;
    this.requestId = requestId || this.requestId;
    this.conversationId = conversationId || this.conversationId;

    return new Promise((resolve, reject) => {
      ChatManager.getChatRequest(this.requestId).then((data) => {
        // get the data of the chatRequest
        chatRequestData = data.data.getEncryptedChatRequest;

        // set the visitorId
        this.visitorId = chatRequestData?.visitorId;

        if (!!this.requestId && !!this.conversationId) {
          // create new conversation
          // a conversation id was provided, use it to subscribe to the conversation
          this.updateSubscriptions(this.requestId, this.conversationId);
          this.updateStatus(ChatStatus.Connected);
          this.onConnected?.();

          resolve(this);
        } else {
          console.error('Request Id or Conversation Id is not set.');
        }
      });
    });
  }

  transfer(
    requestId = null,
    conversationId = null
  ) {
    let chatRequestData;
    this.requestId = requestId || this.requestId;
    this.conversationId = conversationId || this.conversationId;

    //reset the subscription on old request id and old conversation id
    this.unsubscribeSubscriptions();

    return new Promise((resolve, reject) => {
      ChatManager.getChatRequest(this.requestId).then((data) => {
        // get the data of the chatRequest
        chatRequestData = data.data.getEncryptedChatRequest;

        // set the visitorId
        this.visitorId = chatRequestData?.visitorId;

        if (!!this.requestId && !!this.conversationId) {
          // create new conversation
          // a conversation id was provided, use it to subscribe to the conversation
          this.updateSubscriptions(this.requestId, this.conversationId);
          this.updateStatus(ChatStatus.Connected);
          this.onConnected?.();

          resolve(this);
        } else {
          console.error('Request Id or Conversation Id is not set.');
        }
      });
    });
  }
  updateSubscriptions(requestId, conversationId) {
    this.subscriptions.push(
      this.onMessageReceived &&
      this.observeCreateMessage(conversationId, this.onMessageReceived.bind(this))
    );
    this.subscriptions.push(
      this.onMessageUpdated &&
      this.observeUpdateMessage(conversationId, this.onMessageUpdated.bind(this))
    );
    this.subscriptions.push(
      this.onChatRequestUpdated &&
      this.observeChatRequest(requestId, this.onChatRequestUpdated.bind(this))
    );
    this.subscriptions.push(
      this.onAgentTyping &&
      this.observeAgentTyping(conversationId, this.onAgentTyping.bind(this))
    );
  }

  sendMessage(message, messageData) {
    return new Promise((resolve, reject) => {
      var data = {
        messageId: ChatManager.uuidv4(),
        visitorId: this.visitorId,
        conversationId: this.conversationId,
        sender: 'Customer',
        source: 'Customer',
        content: message,
        recipient: 'Expert',
        createdAt: new Date().toISOString(),
        interactionType: 'Online',
        isSent: false,
        messageType: (messageData && messageData.messageType) || 'Plain Text',
        isActive: true,
        sourceLang: 'en',
        targetLang: 'en',
        translated: false,
      };

      API.graphql(graphqlOperation(this.createMessageGraphQL, { input: data }));
    });
  }

  onChatRequestUpdated(chatRequest) {
    this.processChatRequestUpdate(chatRequest);
  }

  observeChatRequest(
    requestId,
    callback = null
  ) {
    let observable = null;
    let subscription = null;

    observable = AppSyncHelper.subscribe(this.onEndChatGraphQL, '{requestId}', requestId);
    subscription = observable.subscribe((data) => {
      const chatRequest = data.value.data.onUpdateChatRequest;
      const chatResolved = this.isChatResolved(chatRequest);

      if (this.isChatEnding(chatRequest)) {
        this.unsubscribeSubscriptions();
        this.updateStatus(ChatStatus.Ended);
        this.isResolved = chatResolved;
        this.onDisconnected?.();
      }
      callback?.(chatRequest);
    });

    return subscription;
  }

  unsubscribeSubscriptions = () => {
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  };

  processChatRequestUpdate(chatRequest) {
    // check if the chat is ending based on the updates on the chatRequest

    const chatTransferring = this.isChatTransferring(chatRequest);
    const chatEnding = this.isChatEnding(chatRequest);
    const chatResolved = this.isChatResolved(chatRequest);

    //if status is 'Transferred', process transfer to new request id and conversation id once transfer is complete
    this.processChatTransfer(chatRequest);

    if (chatEnding) {
      this.isResolved = chatResolved;
      this.onDisconnected?.();
    } else {
      this.isResolved = false;
    }
  }

  getAllMessages() {
    return new Promise((resolve, reject) => {
      let allMessages = [];
      let messages;
      let filtered;

      if (this.conversationId != null && this.visitorId != null) {
        this.getMessagesByVisitorAndConversation(
          this.visitorId?.toLowerCase(),
          this.conversationId
        )
          .then((resultLower) => {
            messages = (resultLower.data.getMessages || []);
            filtered = messages.filter((message) => {
              return true;
            });
            allMessages.push(...filtered);
            this.getMessagesByVisitorAndConversation(
              this.visitorId.toUpperCase(),
              this.conversationId
            )
              .then((resultUpper) => {
                messages = (resultUpper.data.getMessages || []);
                filtered = messages.filter((message) => {
                  return true;
                });
                allMessages.push(...filtered);
                if (allMessages.length > 0) {
                  allMessages.sort(this.messageSorter);
                }
                resolve(allMessages);
              })
              .catch((error) => {
                resolve([]);
              });
          })
          .catch((error) => {
            resolve([]);
          });
      } else {
        // return an empty message array
        resolve([]);
      }
    });
  }

  messageSorter(a, b) {
    let result = 0;
    let aMoment = moment(a.createdAt);
    let bMoment = moment(b.createdAt);
    if (aMoment.valueOf() < bMoment.valueOf()) {
      result = -1;
    } else if (aMoment.valueOf() > bMoment.valueOf()) {
      result = 1;
    } else {
      result = 1;
    }
    return result;
  }

  /**
   * check if the chat is ending based on the data in the chatRequest
   * @param statusCode
   */
  isChatEnding(chatRequest) {
    let isEnding = false;
    const statusCode =
      chatRequest.requestStatus;
    let statusCodeString;

    if (statusCode != null) {
      statusCodeString = statusCode.toString().toLowerCase();
      if (statusCodeString.indexOf('ended') > -1) {
        isEnding = true;
      } else if (statusCodeString.indexOf('abandoned') > -1) {
        isEnding = true;
      }
    } else if (chatRequest.rating != null) {
      //isEnding = true;
    } else {
      // unknown status code or not present or something else
      isEnding = false;
    }
    return isEnding;
  }

  /**
   * check if the chat is transferred based on the data in the chatRequest
   * @param statusCode
   */
  processChatTransfer(chatRequest) {
    let isTransferred = false;

    if (chatRequest?.requestStatus?.toString().toLowerCase().indexOf('transferred') > -1) {

      //chat was successfully transferred
      ChatManager.getChatRequest(this.requestId).then((data) => {

        //get the new chat request id from old chatRequest data
        let newRequestId = data.data.getEncryptedChatRequest.transferredRequestId;

        //get new request id and conversation id to subscribe to
        ChatManager.getConversationByRequestId(newRequestId).then((data) => {
          let chatConversation = data.data.getConversation;
          this.transfer(chatConversation.requestId, chatConversation.conversationId, this.requestId);
        });
      });
      isTransferred = true;
    }
    return isTransferred;
  }

  /**
   * check if the chat is transferring based on the data in the chatRequest
   * @param statusCode
   */
  isChatTransferring(chatRequest) {
    let isTransferring = false;
    if (chatRequest?.requestStatus?.toString().toLowerCase().indexOf('transferring') > -1) {

      //if chat transfer is in progress, you may want to do additional UX things  
      //like preventing user from sending messages and
      //letting the customer know that chat is being transferred to an agent 
      isTransferring = true;
    }
    return isTransferring;
  }

  /**
   * check if the chat is resolved based on the warpUpCode in the chatRequest
   * @param chatRequest
   */
  isChatResolved(chatRequest) {
    const wrapUpCode = chatRequest.wrapUpCode;
    return (wrapUpCode && wrapUpCode.toLowerCase() === 'resolved')
  }

  observeCreateMessage(
    conversationId,
    callback = null
  ) {
    let observable = null;
    let chatMessage;
    let subscription = null;

    if (callback != null) {
      observable = AppSyncHelper.subscribe(
        this.onCreateMessageGraphQL,
        '{conversationId}',
        conversationId
      );
      subscription = observable.subscribe((message) => {
        // get the chat message
        chatMessage = message.value.data.onCreateMessage;

        // invoke the callback
        callback(chatMessage);
      });
    }
    return subscription;
  }

  observeUpdateMessage(
    conversationId,
    callback = null
  ) {
    let observable = null;
    let chatMessage;
    let subscription = null;

    observable = AppSyncHelper.subscribe(
      this.onUpdateMessageGraphQL,
      '{conversationId}',
      conversationId
    );
    subscription = observable.subscribe((message) => {
      // get the chat message
      chatMessage = message.value.data.onUpdateMessage;
      if (chatMessage != null) {
        // the message was updated
        // do nothing
      } else {
        console.warn('Empty message received?');
      }
      // invoke the callback
      callback?.(chatMessage);
    });
    return subscription;
  }

  observeAgentTyping(
    conversationId,
    callback = null
  ) {
    let observable = null;
    let isAgentTyping;
    let subscription = null;

    observable = AppSyncHelper.subscribe(
      this.onAgentTypingGraphQL,
      '{conversationId}',
      conversationId
    );
    subscription = observable.subscribe((message) => {
      // get the chat message
      isAgentTyping = message.value.data.onAgentTyping.agentTyping;
      if (isAgentTyping != null) {
        // the message was updated
        // do nothing
      } else {
        console.warn('No agent typing response');
      }
      // invoke the callback
      callback?.(isAgentTyping);
    });
    return subscription;
  }

  //#region graphql

  onUpdateMessageGraphQL = `
        subscription onUpdateMessage {
            onUpdateMessage(conversationId: "{conversationId}") {
                messageId
                visitorId
                conversationId
                messageType
                interactionType
                sender
                source
                content
                isSent
                recipient
                isActive
                createdAt
                sourceMsgId
                translated
                sourceLang
                targetLang
                messageStatus
                userResponseTime
            }
        }`;

  onAgentTypingGraphQL = `
        subscription onAgentTyping {
          onAgentTyping(conversationId: "{conversationId}") {
            conversationId
            agentTyping
          }
        }`;

  onEndChatGraphQL = `
            subscription onUpdateChatRequest {
                onUpdateChatRequest(requestId: "{requestId}") {
                    requestId
                    requestStatus
                    wrapUpCode
                    endTimestamp
                }
            }`;

  //  createMessage = `
  //               subscription onUpdateChatRequest {
  //                   onUpdateChatRequest(requestId: "{requestId}") {
  //                       requestId
  //                       requestStatus
  //                       wrapUpCode
  //                       endTimestamp
  //                   }
  //               }`;

  onCreateMessageGraphQL = `
        subscription onCreateMessage {
            onCreateMessage(conversationId: "{conversationId}") {
                    messageId
                    visitorId
                    conversationId
                    messageType
                    interactionType
                    sender
                    source
                    content
                    isSent
                    recipient
                    isActive
                    createdAt
                    sourceMsgId
                    translated
                    sourceLang
                    targetLang
                    messageStatus
                    userResponseTime
            }
        }`;

  //  updateUserMessageResponseGraphpQL = `
  //       mutation updateUserMessageResponseTime($input: UpdateUserMessageResponseTimeInput!) {
  //           updateUserMessageResponseTime(input:$input){
  //                   messageId
  //                   visitorId
  //                   conversationId
  //                   messageType
  //                   interactionType
  //                   sender
  //                   source
  //                   agentResponseTime
  //                   userResponseTime
  //           }
  //       }`;

  createMessageGraphQL = `
        mutation createMessage($input:CreateMessageInput!) {
            createMessage(input:$input){
                    messageId
                    visitorId
                    conversationId
                    messageType
                    interactionType
                    sender
                    source
                    content
                    isSent
                    recipient
                    isActive
                    createdAt
                    sourceMsgId
                    translated
                    sourceLang
                    targetLang
                    agentResponseTime
                    userResponseTime
                    violated
                    messageStatus
                    skillId
            }
        }`;

  createConversationGraphQL = `
        subscription onCreateConversation {
            onCreateConversation(requestId: "{requestId}"){
                conversationId
                agentCognitoId
                requestId
                visitorId
                createdAt
                endTimestamp
            }
        }`;
  //#endregion

  getMessagesByVisitorAndConversation = (
    visitorId,
    conversationId
  ) => {
    if (!visitorId || !conversationId) {
      console.log('Undefined visitorId or conversationId', visitorId, conversationId);
      return new Promise((resolve, reject) => {
        reject('Undefined visitorId or conversationId');
      });
    }

    var query = `
              query getMessages {
                getMessages(visitorId: "{visitorId}", conversationId: "{conversationId}") {
                  messageId
                  visitorId
                  conversationId
                  messageType
                  messageStatus
                  interactionType
                  sender
                  source
                  content
                  isSent
                  recipient
                  isActive
                  createdAt
                  sourceMsgId
                  translated
                  sourceLang
                  targetLang
                  agentResponseTime
                  userResponseTime
                }
              }
              `;

    query = query
      .replace('{visitorId}', visitorId)
      .replace('{conversationId}', conversationId);

    return new Promise((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(query)));
    });
  };

  static getChatRequest = (requestId) => {
    if (!requestId) {
      console.log('Undefined requestId', requestId);
      return new Promise((resolve, reject) => {
        reject('Undefined requestId');
      });
    }

    var query = `
        query getEncryptedChatRequest {
            getEncryptedChatRequest(requestId: "{requestId}") {
                acwStartTimestamp
                appInBackground
                ARN
                averageResponseTime
                averageUserResponseTime
                browserAgent
                caseNumber
                chatAcceptTimeStamp
                chatReason
                chatType
                chatWaitTime
                claimNumber
                clientName
                comments
                customerId
                deviceId
                endTimestamp
                engagementDuration
                engagementType
                expertId
                expertName
                interactionId
                isTransferred
                languageCode
                mdn
                OSType
                policyNumber
                rating
                requestChannel
                requestId
                requestStatus
                requestType
                skillId
                speedToAnswer
                startTimestamp
                storeCode
                taskId
                transferredRequestId
                userEmail
                userProfileUrl
                violationCount
                visitorId
                visitorName
                wrapUpCode
            }
          }`;

    query = query.replace('{requestId}', requestId);
    return new Promise((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(query)));
    });
  };

  static getConversationByRequestId = (requestId) => {
    if (!requestId) {
      console.log('Undefined requestId', requestId);
      return new Promise((resolve, reject) => {
        reject('Undefined requestId');
      });
    }

    var query = `
        query getConversation {
            getConversation(requestId: "{requestId}") {
                conversationId
                agentCognitoId
                requestId
                visitorId
                createdAt
                endTimestamp
                __typename
            }
          }`;

    query = query.replace('{requestId}', requestId);

    return new Promise((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(query)));
    });
  };

  createChatRequest = (
    requestId,
    visitorId,
    taskId
  ) => {
    if (!requestId || !visitorId || !taskId) {
      console.log('Undefined requestId/visitorId/taskId', requestId, visitorId, taskId);
      return new Promise((resolve, reject) => {
        reject('Undefined requestId/visitorId/taskId');
      });
    }

    var m = `
		 mutation createEncryptedChatRequest($input:CreateChatRequestInput!){
			createEncryptedChatRequest(input:$input){
		    	requestId
				visitorId
				taskId
				visitorName
				interactionId
				mdn
				customerId
				skillId
				languageCode
				clientName
				chatReason
				requestStatus
				requestType
				chatWaitTime
				startTimestamp
				averageResponseTime
				engagementDuration
				violationCount
				requestChannel
				chatType
		  }
		}`;

    var data = {
      chatReason: 'Technical Support',
      clientName: this.client,
      customerId: visitorId, //uuidv4(),
      engagementType: this.chatType,
      chatType: this.chatType,
      interactionId: '1122',
      languageCode: this.language,
      mdn: this.mdn,
      requestId: requestId,
      requestStatus: 'Initiated',
      requestType: 'Other',
      skillId: this.skillId,
      startTimestamp: new Date().toISOString(),
      visitorId: visitorId,
      visitorName: visitorId,
      taskId: taskId,
    };

    return new Promise((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(m, { input: data })));
    });
  };

  end = () => {
    var m = `mutation updateEncryptedChatRequest($input: UpdateChatRequestInput!){
		    updateEncryptedChatRequest(input:$input){
		            requestId
					requestStatus
					speedToAnswer
					wrapUpCode
					endTimestamp
					expertName
					userEmail
					isTransferred
					transferredRequestId
					averageResponseTime
					engagementDuration
					violationCount
					chatAcceptTimeStamp
					expertId
					averageUserResponseTime
		    }
		}`;

    var data = {
      requestId: this.requestId,
      requestStatus: 'Ended',
      endTimestamp: new Date().toISOString(),
      wrapUpCode: 'User Disconnected',
    };

    return new Promise((resolve, reject) => {
      this.unsubscribeSubscriptions();
      this.updateStatus(ChatStatus.Ended);
      resolve(API.graphql(graphqlOperation(m, { input: data })));
    });
  };

  static uuidv4 = () => {
    return uuid.v4().replaceAll('-', '')
  }
}
