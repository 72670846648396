import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { DEVICE_CATEGORY } from "../../../utils/constants";
import logger from "../../../logger.service";

function ConfirmAddress() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  let FindAgreementResponse = workflowState["FindAgreementResponse"];
  let fileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;

  const deviceCategory =
    fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryName;

  const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
  const address1 = agreement?.Address?.Address1;
  const address2 = agreement?.Address?.Address2;
  const city = agreement?.Address.City;
  const postalCode = agreement?.Address.PostalCode;
  const addressjoin = { address1, address2, city, postalCode };
  const fulladdress = Object.values(addressjoin).join(", ");

  const metadata = workflowState['config']?.metadata['ConfirmAddress']
  const homeplusscript = workflowState['config']?.scripts["ConfirmAddress"];

  const [addressDetails, setAddress] = useState(fulladdress);
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let inputAddress = findById(metadata, "inputAddress");
  let btnConfirm = findById(metadata, "btnConfirm");
  let txtAddressInfo = findById(metadata, "txtAddressInfo");
  let menu = findById(metadata, "menu");
  let txtTitle = findById(metadata, "txtTitle");

  const onConfirmClick = () => {
    callServiceFeeApi();
  };

  //  const disableinput = onChangeClick() ? false : true;
  //  console.log(disableinput)

  const callServiceFeeApi = () => {
    showLoader(true);

    API[ActionTypes.GET_SERVICE_FEE](
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryId,
      fileClaimDetails?.selectedDevice?.Make?.MakeId
    )
      .then((data) => {
        showLoader(false);

        updateState({
          ...workflowState,
          FileClaimDetails: {
            // ...fileClaimDetails,
            onsiteAddress: addressDetails,
            ServiceFeeResponse: data.data.ServiceFee,
          },
        }, ["FileClaimDetails"]);

        updateState({
          ...workflowState,
          BraintreeDetails: {
            ServiceFeeResponse: data.data.ServiceFee,
          },
        }, ["BraintreeDetails"]);

        // move to next screen
        return completeCurrentStep(currentStep, {
          ShowClaimSummary: true,
        });
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.GET_SERVICE_FEE, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  inputAddress.args = {
    ...inputAddress.args,
    label: homeplusscript.inputAddress_label,
    value: addressDetails,
    enabled: false,
    onChange: setAddress,
  };

  txtAddressInfo.args = {
    ...txtAddressInfo.args,
    content: 
      deviceCategory === DEVICE_CATEGORY.TV
        ? homeplusscript.labelAddressInfo
        : homeplusscript.labelPickupAddressInfo,
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label: homeplusscript.btnConfirm,
    onClick: (e) => onConfirmClick(),
  };

  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }
  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  txtTitle.args = {
    ...txtTitle.args,
    content:
      deviceCategory === DEVICE_CATEGORY.TV
        ? homeplusscript.txtTitle
        : homeplusscript.txtTitlePickup,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}
export default ConfirmAddress;
