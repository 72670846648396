import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import { callCancelClaimApi } from "../api-helper";

function CancelClaimPopup(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const { setClaimPopup, onMenuItemClick } = props;
  const metadata = workflowState['config']?.metadata['CancelClaimPopup']
  const homeplusscript = workflowState['config']?.scripts["CancelClaimPopup"];

  let FileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = FileClaimDetails?.ServiceRequestResponse;

  const [isExpanded, setIsExpanded] = useState(true);
  const [claimPopup, setClaimPop] = useState(false);
  const [claimReason, setClaimReason] = useState("");

  let textContent = findById(metadata, "contentText");
  let btnCancel = findById(metadata, "btnCancel");
  let btnGoBack = findById(metadata, "btnGoBack");
  let textAreaCancelReason = findById(metadata, "textAreaCancelReason");

  textContent.args = {
    ...textContent.args,
    content: homeplusscript.contentText,
  };

  const open = (isExpanded) => {
    setClaimPopup(false);
    setIsExpanded(!isExpanded);
  };

  const goToDashboard = () => {
    // clear all file claim data
    updateState({
      ...workflowState,
      FileClaimDetails: {},
    });
    open(isExpanded);
    onMenuItemClick("dashboard");
  };

  const onContinueClick = () => {
    cancelExistingSR(ServiceRequestResponse?.ServiceRequest?.ServiceRequestId);
  };

  const cancelExistingSR = (ServiceRequestId) => {
    goToDashboard();

    const CancelServiceRequestParams = {
      CancelServiceRequestParams: {
        ServiceRequestId: ServiceRequestId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        NoteText: "test",
        Operation: "Cancel",
        Reason: claimReason,
        SubReason: "REPAIRREJECTED",
        CancelEntities: "ALL",
        RequestedBy: "Customer",
        ReturnEntity: true,
      },
    };

    callCancelClaimApi(ServiceRequestId, CancelServiceRequestParams)

  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    enabled: true,
    onClick: () => open(isExpanded),
  };

  btnCancel.args = {
    ...btnCancel.args,
    label: homeplusscript.btnCancel,
    enabled: claimReason,
    onClick: (e) => onContinueClick(),
  };

  textAreaCancelReason.args = {
    ...textAreaCancelReason.args,
    label: homeplusscript.textAreaCancelReasonLabel,
    placeHolder: homeplusscript.textAreaCancelReasonPlaceHolder,
    value: claimReason,
    onChange: setClaimReason,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default CancelClaimPopup;
