import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import { DEVICE_CATEGORY } from "../../../utils/constants";

function IsDeviceBuiltIn() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['IsDeviceBuiltIn']
  const homeplusscript = workflowState['config']?.scripts["IsDeviceBuiltIn"];

  const [selectedBuiltInOption, setSelectedBuiltInOption] = useState({});

  let builtInOptions = findById(metadata, "builtInOptions");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtBuiltInInfo = findById(metadata, "txtBuiltInInfo");
  let menu = findById(metadata,"menu");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const modelNumberOptions = [
    {
      value: homeplusscript.labelYesBuiltIn,
      label: homeplusscript.labelYesBuiltIn,
    },
    {
      value: homeplusscript.labelNoBuiltIn,
      label: homeplusscript.labelNoBuiltIn,
    },
  ];

  const onContinueClick = (modelOption) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        isBulitInFurniture : (modelOption?.value) === homeplusscript.labelNoBuiltIn ? "No" :"Yes"
      },
    }, ["RegDeviceDetails"]);
    return completeCurrentStep(currentStep, {
        selectConnectivityType: (modelOption?.value === homeplusscript.labelNoBuiltIn) ? true : false,
        deviceNotRegister: (modelOption?.value === homeplusscript.labelYesBuiltIn) ? true : false,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
        GoBackModelNumber: true,
    });
  }

  const onModelOptionSelect = (modelOption) => {
    setSelectedBuiltInOption({ ...selectedBuiltInOption, ...modelOption });

    onContinueClick(modelOption)
  };

  txtBuiltInInfo.args = {
    ...txtBuiltInInfo.args,
    displayClasses: "block",
    content : homeplusscript.BuiltInInfo,
  };

  builtInOptions.args = {
    ...builtInOptions.args,
    deviceItems: modelNumberOptions,
    value: selectedBuiltInOption?.value,
    mainDivDisplayClasses:
      regDeviceDetails?.selectedCategory?.type?.toUpperCase() === DEVICE_CATEGORY.TV
        ? "hidden"
        : "block",
    onClick: onModelOptionSelect,
  };
  
  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default IsDeviceBuiltIn;
