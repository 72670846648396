export default {
  INTIALIZE_API_REQUEST: 'INTIALIZE_API_REQUEST',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  OTP_REQUEST: 'OTP_REQUEST',
  SIGNOUT_REQUEST: 'SIGNOUT_REQUEST',
  VERIFY_LOGIN_OTP: 'VERIFY_LOGIN_OTP',
  FIND_AGREEMENT_REQUEST: 'FIND_AGREEMENT_REQUEST',
  GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
  GET_INVENTORY: 'GET_INVENTORY',
  DEVICE_REGISTRATION: 'DEVICE_REGISTRATION',
  SET_RECOVER_EMAIL: 'SET_RECOVER_EMAIL',
  CREATE_ENQUIRY: 'CREATE_ENQUIRY',
  CREATE_ENQUIRY_V1: 'CREATE_ENQUIRY_V1',
  CREATE_INTERACTION: 'CREATE_INTERACTION',
  DETERMINE_INCIDENT_PATH: 'DETERMINE_INCIDENT_PATH',
  CREATE_SERVICE_REQUEST: 'CREATE_SERVICE_REQUEST',
  CANCEL_SERVICE_REQUEST: "CANCEL_SERVICE_REQUEST",
  RESUME_SERVICE_REQUEST: "RESUME_SERVICE_REQUEST",
  GET_TRIAGE_QUESTIONS: 'GET_TRIAGE_QUESTIONS',
  SUBMIT_TRIAGE_QUESTIONS: 'SUBMIT_TRIAGE_QUESTIONS',
  PROCESS_INCIDENT: 'PROCESS_INCIDENT',
  GET_FULFILMENT_OPTIONS: "GET_FULFILMENT_OPTIONS",
  SET_FULFILMENT_OPTIONS: "SET_FULFILMENT_OPTIONS",
  CREATE_SERVICE_ORDER: "CREATE_SERVICE_ORDER",
  CREATE_REPAIR_REQUEST: "CREATE_REPAIR_REQUEST",
  UPDATE_REPAIR_REQUEST: "UPDATE_REPAIR_REQUEST",
  GET_SECURITY_TOKEN_REQUEST: 'GET_SECURITY_TOKEN_REQUEST',
  GET_SERVICE_FEE: "GET_SERVICE_FEE",
  GET_PCI_TOKEN: "GET_PCI_TOKEN",
  CREATE_CHARGE_ORDER: "CREATE_CHARGE_ORDER",
  PROCESS_PAYMENT: "PROCESS_PAYMENT",
  GET_SHIPPING_METHODS: "GET_SHIPPING_METHODS",
  UPDATE_SHIPPING_ORDER: "UPDATE_SHIPPING_ORDER",
  SUBMIT_ORDER: "SUBMIT_ORDER",
  GET_SERIAL_NUMBER_EXISTENCE: "GET_SERIAL_NUMBER_EXISTENCE",
  CREATE_CONTACT: "CREATE_CONTACT",
  GET_CLAIM_DETAILS: "GET_CLAIM_DETAILS",
  GET_REPLACEMENT_OPTIONS: "GET_REPLACEMENT_OPTIONS",
  GET_SECURITY_ADVISOR_TOKEN_REQUEST: "GET_SECURITY_ADVISOR_TOKEN_REQUEST",
  PROCESS_OVERAGE_PAYMENT: "PROCESS_OVERAGE_PAYMENT",
  GET_SIGNED_URL_FROM_S3: 'GET_SIGNED_URL_FROM_S3',
  UPLOAD_TO_S3_WITH_SIGNED_URL: 'UPLOAD_TO_S3_WITH_SIGNED_URL',
  GET_DOWNLOAD_URL_FROM_S3: 'GET_DOWNLOAD_URL_FROM_S3',
  LOG_TO_DYNAMO_DB: 'LOG_TO_DYNAMO_DB' ,
  GET_FAQ_CONTENT : 'GET_FAQ_CONTENT' ,
  GET_INSURED_SCHEDULE_DOCUMENT : 'GET_INSURED_SCHEDULE_DOCUMENT',
  GET_CONTACT_POINT_REQUEST : 'GET_CONTACT_POINT_REQUEST',
  UPDATE_CARRIER_TYPE: "UPDATE_CARRIER_TYPE",
  UPLOAD_VIDEO_DOC: "UPLOAD_VIDEO_DOC" ,
  GET_IP_ADD: "GET_IP_ADD",
  GET_CLIENT_TOKEN: "GET_CLIENT_TOKEN",
  BT_CREATE_CHARGE_ORDER: "BT_CREATE_CHARGE_ORDER",
  BT_STORE_VERIFY_CARD: "BT_STORE_VERIFY_CARD",
  BT_CREATE_PAYMENT_METHOD: "BT_CREATE_PAYMENT_METHOD",
  BT_CREATE_NEW_NONCE: "BT_CREATE_NEW_NONCE"
}
