import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "../tooltip/tooltip";
import "../../style.css"

export const Input = (props) => {
  const {
    enabled,
    label,
    placeHolder,
    value,
    type,
    fontClasses,
    inputDivClasses,
    dimensionClasses,
    colorClasses,
    borderClasses,
    paddingClasses,
    marginClasses,
    displayClasses,
    onChange,
    onClick,
    maxlength,
    tabIndex,
    showTooltip,
    other,
    min,
    max,
    showValue,
    color,
    minValue,
    maxValue,
    subDiv,
    marginTop,
    marginBottom,
    labelValue,
    inputMode,
    id
  } = props;

const rangeInputs = document.querySelectorAll('input[type="range"]')
const handleInputChange = (e) => {
  let target = e.target
  if (e.target.type !== 'range') {
    target = document.getElementById('range')
  } 
  const min = target.min
  const max = target.max
  const val = target.value
  
  target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
}

rangeInputs.forEach(input => {
  input.addEventListener('input', handleInputChange)
})

  return (
    <div
      className={`${inputDivClasses} ${fontClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses} ${other}`}
    >
    <div className={`${subDiv} ${marginBottom}`}>
    <div>
      {showTooltip ? (
        <Tooltip showLabel={true} labelText={label} />
      ) : (
        <label dangerouslySetInnerHTML={{ __html: label }}/>
      )}
    </div>
    {showValue &&
      <div className={labelValue}>
       { value || "71"}&#x22;
      </div>
    }
    </div>

      <input
        id={id}
        type={type}
        inputMode={inputMode}
        className={`${borderClasses} ${displayClasses} ${fontClasses} ${color} ${!enabled ? "disabledInput" : ""}`}
        placeholder={placeHolder}
        value={value}
        enabled={enabled}
        disabled={!enabled}
        maxLength={maxlength}
        tabIndex={tabIndex}
        min={min}
        max={max}
        onChange={(e) => onChange(e.target.value)}
        // onClick={(e) => onClick(e.target.value)}
      />
      {showValue && <div className={`${subDiv} ${marginTop}`}>
           <div>
            {minValue}&#x22;
           </div>
           <div>
           {maxValue}&#x22;
          </div>
       </div> }
    </div>
    
  );
};

Input.propTypes = {
  /**
   * enable: Indicates if button is enabled or disabled
   */
  enabled: PropTypes.bool,
  /**
   * label: Text to be displayed on button
   */
  label: PropTypes.string,
  /**
   * label: placeHolder to be displayed on Input
   */
  placeHolder: PropTypes.string,
  /**
   * value: Value of the input control
   */
  value: PropTypes.string,
  /**
   * type: Type of the input control
   */
  type: PropTypes.string,
  /**
   * fontClasses: Classes to manage fonts of the control
   */
  fontClasses: PropTypes.string,
  /**
   * dimensionClasses: Classes to manage dimensions of the control
   */
  dimensionClasses: PropTypes.string,
  /**
   * colorClasses: Classes to manage foreground and background colors of the control
   */
  colorClasses: PropTypes.string,
  /**
   * borderClasses: Classes to manage border of the control
   */
  borderClasses: PropTypes.string,
  /**
   * paddingClasses: Classes to manage padding of the control
   */
  paddingClasses: PropTypes.string,
  /**
   * marginClasses: Classes to manage margin of the control
   */
  marginClasses: PropTypes.string,
  /**
   * displayClasses: Classes to manage display of the control
   */
  displayClasses: PropTypes.string,
  /**
   * onChange: On Click event handler for button
   */
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  /**
   * maxlength: max length for input
   */
  maxlength: PropTypes.string,
  /**
   * tabIndex: tabIndex for input
   */
  tabIndex: PropTypes.string,
  /**
   * onFocus: On focus event handler for input
   */
  onFocus: PropTypes.func,
  /**
   * onKeyUp: On key up event handler for input
   */
  onKeyUp: PropTypes.func,

  showTooltip: PropTypes.bool,

  other: PropTypes.string,
};

Input.defaultProps = {
  enabled: true,
  label: "Label",
  value: "",
  type: "text",
  fontClasses: "text-base",
  inputDivClasses: "flex flex-col",
  dimensionClasses: "",
  colorClasses: "",
  borderClasses:
    "border border-neutralN8 focus:outline-none rounded-md focus:border-primary focus:shadow-sm",
  paddingClasses: "",
  marginClasses: "mb-5",
  displayClasses: "",
  onChange: undefined,
  onFocus: undefined,
  onClick: undefined,
  onKeyUp: undefined,
  maxlength: "",
  tabIndex: "",
  showTooltip: false,
  other: "",
  min:"",
  max:"",
  showValue:false,
  color:"",
  minValue:"",
  maxValue:"",
  subDiv:"flex flex-row justify-between",
  inputMode: "text"
};
