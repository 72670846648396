import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import {
  findById,
  getContactNumber,
  getFileBase64,
  getFormmatedDate,
  getMetadata,
  isEmpty,
} from "../helper";
import ActionTypes from "../../../ActionTypes";
import API from "../../../services";
import { DEVICE_CATEGORY } from "../../../utils/constants";
import logger from "../../../logger.service";
import { getSignedURLForDownload } from "../../../utils/upload-utils"

function DeviceRegSummary() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['DeviceRegSummary']
  const homeplusscript = workflowState['config']?.scripts["DeviceRegSummary"];

  const [title, setTitle] = useState(homeplusscript.title);
  const [showButtonContainer, setShowButtonContainer] = useState(true);
  
  let txtTitle = findById(metadata, "txtTitle");
  let devRegSummary = findById(metadata, "devRegSummary");
  let btnContainer = findById(metadata, "btnContainer");
  let btnYes = findById(metadata, "btnYes");
  let btnEdit = findById(metadata, "btnEdit");
  let btnAddDevice = findById(metadata, "btnAddDevice");
  let menu = findById(metadata, "menu");

  let loginDetails = workflowState["login"];

  const regDeviceDetails = workflowState["RegDeviceDetails"];
  const agreement =
    workflowState.FindAgreementResponse?.Agreements?.Agreement[0];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;

  const isTV = selectedCategory === DEVICE_CATEGORY.TV;
  const isSoundbar = selectedCategory === DEVICE_CATEGORY.Soundbar;
  const isLaptopDesktop =
    selectedCategory === DEVICE_CATEGORY.Laptop ||
    selectedCategory === DEVICE_CATEGORY.Desktop;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;
  const isWebcam = selectedCategory === DEVICE_CATEGORY.Webcam;
  const isKeyboard = selectedCategory === DEVICE_CATEGORY.Keyboard;
  const isPrinter = selectedCategory === DEVICE_CATEGORY.Printer;
  const isMouse = selectedCategory === DEVICE_CATEGORY.Mouse;
  const isRouter = selectedCategory === DEVICE_CATEGORY.Router;
  const isMonitor = selectedCategory === DEVICE_CATEGORY.Monitor;

  let fileName = regDeviceDetails?.purchasedReceiptFile?.name
  if(fileName){
    fileName = fileName?.length > 32 ? `${fileName.substring(0, Math.min(16,fileName?.length))}...`: fileName
  }

  const regDeviceTableHeaders = [
    {
      key: "deviceCategory",
      text: homeplusscript.labelDeviceCategory,
      hideForNoAction: false,
    },
    {
      key: "brand",
      text: homeplusscript.labelBrand,
      hideForNoAction: false,
    },
    {
      key: "modelNo",
      text: homeplusscript.labelModelNo,
      hideForNoAction: isEmpty(regDeviceDetails?.modelNumber) ? true : false,
    },
    {
      key: "isBuiltIn",
      text: homeplusscript.labelIsBuiltIn,
      hideForNoAction: isEmpty(regDeviceDetails?.isBulitInFurniture)
        ? true
        : false,
    },
    {
      key: "keyboardType",
      text: homeplusscript.labelKeyboardType,
      hideForNoAction: isEmpty(regDeviceDetails?.keyboardType) ? true : false,
    },
    {
      key: "hasBluetooth",
      text: homeplusscript.labelHasBluetooth,
      hideForNoAction: isEmpty(regDeviceDetails?.hasBluetooth) ? true : false,
    },
    {
      key: "deviceSpeed",
      text: homeplusscript.labelDeviceSpeed,
      hideForNoAction: isEmpty(regDeviceDetails?.deviceSpeed) ? true : false,
    },
    {
      key: "acquiredFromCarrier",
      text: homeplusscript.labelAcquiredFromCarrier,
      hideForNoAction: isEmpty(regDeviceDetails?.acquiredFromCarrier)
        ? true
        : false,
    },
    {
      key: "serialNo",
      text: regDeviceDetails?.askImeiDetails ? homeplusscript.labelImeiNo :homeplusscript.labelSerialNo,
      hideForNoAction: isEmpty(regDeviceDetails?.serialNumber) ? true : false,
    },
    {
      key: "processor",
      text: homeplusscript.labelProcessor,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedProcessor)
        ? true
        : false,
    },
    {
      key: "RAM",
      text: homeplusscript.labelRAM,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedRAM) ? true : false,
    },
    {
      key: "displayTechnologies",
      text: homeplusscript.labelDisplayTechnologies,
      hideForNoAction:
        !isTV || isEmpty(regDeviceDetails?.selectedTech) ? true : false,
    },
    {
      key: "technology",
      text: homeplusscript.labelTechnology,
      hideForNoAction:
        !isPrinter || isEmpty(regDeviceDetails?.selectedTech) ? true : false,
    },
    {
      key: "function",
      text: homeplusscript.labelFunction,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedFunction)
        ? true
        : false,
    },
    {
      key: "deviceResolution",
      text: homeplusscript.labelResolution,
      hideForNoAction: regDeviceDetails?.selectedResolution ? false : true,
    },
    {
      key: "deviceFrameRate",
      text: homeplusscript.labelFrameRate,
      hideForNoAction: regDeviceDetails?.selectedFrameRate ? false : true,
    },
    {
      key: "screenSize",
      text: homeplusscript.labelScreenSize,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedScreenSize)
        ? true
        : false,
    },
    {
      key: "connectivity",
      text: homeplusscript.labelConnectivity,
      hideForNoAction:
        isPrinter ||
        isMonitor ||
        isEmpty(regDeviceDetails?.selectedConnectivity)
          ? true
          : false,
    },
    {
      key: "storageCapacity",
      text: homeplusscript.labelStorageCapacity,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedCapacity)
        ? true
        : false,
    },
    {
      key: "storageType",
      text: homeplusscript.labelStorageType,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedStorageType)
        ? true
        : false,
    },
    {
      key: "model",
      text: homeplusscript.labelModel,
      hideForNoAction: isEmpty(regDeviceDetails?.selectedDeviceModel)
        ? true
        : false,
    },
    {
      key: "purchaseDate",
      text: homeplusscript.labelPurchaseDate,
      hideForNoAction: isEmpty(regDeviceDetails?.purchasedDate) ? true : false,
    },
    {
      key: "purchasedPrice",
      text: homeplusscript.labelPurchasedPrice,
      hideForNoAction: isEmpty(regDeviceDetails?.purchasedPrice) ? true : false,
    },
    {
      key: "uploadedReceipt",
      text: homeplusscript.labelUploadedReceipt,
      hideForNoAction: regDeviceDetails?.purchasedReceiptAdded ? false : true,
    },
  ];

  const regDeviceTableData = [
    {
      deviceCategory: regDeviceDetails?.selectedCategory.type,
      brand: regDeviceDetails?.selectedBrand,
      modelNo: regDeviceDetails?.modelNumber,
      serialNo: regDeviceDetails?.serialNumber,
      displayTechnologies: regDeviceDetails?.selectedTech,
      screenSize: `${regDeviceDetails?.selectedScreenSize}"`,
      purchaseDate: regDeviceDetails?.purchasedDate,
      connectivity: regDeviceDetails?.selectedConnectivity,
      storageCapacity: regDeviceDetails?.selectedCapacity,
      storageType: regDeviceDetails?.selectedStorageType,
      processor: regDeviceDetails?.selectedProcessor,
      RAM: regDeviceDetails?.selectedRAM,
      purchasedPrice: `$${regDeviceDetails?.purchasedPrice}`,
      uploadedReceipt: fileName,
      isBuiltIn: regDeviceDetails?.isBulitInFurniture,
      deviceResolution: regDeviceDetails?.selectedResolution,
      keyboardType: regDeviceDetails?.keyboardType,
      technology: regDeviceDetails?.selectedTech,
      function: regDeviceDetails?.selectedFunction,
      hasBluetooth: regDeviceDetails?.hasBluetooth,
      deviceSpeed: regDeviceDetails?.deviceSpeed,
      acquiredFromCarrier: regDeviceDetails?.acquiredFromCarrier,
      deviceFrameRate: regDeviceDetails?.selectedFrameRate,
      model: regDeviceDetails?.selectedDeviceModel
    },
  ];

  const onYesClick = () => {
    showLoader(true);
    if (isLaptop || isTablet ||
      isEmpty(regDeviceDetails?.selectedBrandDetails) ||
      regDeviceDetails?.isOtherTechSelected ||
      regDeviceDetails?.isOtherSizeSelected ||
      regDeviceDetails?.isOtherCapacitySelected ||
      regDeviceDetails?.isOtherDeviceModelSelected ||
      regDeviceDetails?.isOtherProcessorSelected ||
      regDeviceDetails?.isOtherRAMSelected ||
      regDeviceDetails?.isOtherStorageTypeSelected ||
      regDeviceDetails?.isOtherFunctionSelected ||
      regDeviceDetails?.isOtherResolutionSelected
    )
      // create enquiry
      createRegDeviceEnquiry();
    // register new device
    else registerNewDevice();
  };

  const editDeviceDetails = () => {

    updateState({
      ...workflowState,
      RegDeviceDetails: {
        selectedCategory: regDeviceDetails?.selectedCategory,
      },
    });

    return completeCurrentStep(currentStep, {
      editDeviceDetails: true,
    });
  };

  const registerNewDevice = async () => {
    let documents;
    if(regDeviceDetails?.purchasedReceiptAdded){
      documents = await getFileData()
    } 

    const DeviceRegistrationParameters = {
      DeviceRegistrationParameters: {
        AssetCategoryId:
          regDeviceDetails?.selectedBrandDetails?.AssetCategoryId,
        AgreementId: agreement?.AgreementId,
        User: agreement?.Customers.Customer[0].FullName,
        DeviceDetails: {
          Category: regDeviceDetails?.selectedCategory?.type,
          Make: regDeviceDetails?.selectedBrand,
          Display_size: regDeviceDetails?.selectedScreenSize,
          Display_tech: regDeviceDetails?.selectedTech,
          Google_TV: isTV
            ? regDeviceDetails?.isGoogleTv
              ? "Yes"
              : "No"
            : undefined,
          Capacity: isTablet ? regDeviceDetails?.selectedCapacity : undefined,
          Connectivity:
            !isPrinter || !isMonitor
              ? regDeviceDetails?.selectedConnectivity
              : undefined,
          Model: regDeviceDetails?.selectedDeviceModel,
          Processor: regDeviceDetails?.selectedProcessor,
          RAM: regDeviceDetails?.selectedRAM,
          Storage_Size: isLaptopDesktop
            ? regDeviceDetails?.selectedCapacity
            : undefined,
          Storage_Type: regDeviceDetails?.selectedStorageType,
          SerialNumber: regDeviceDetails?.serialNumber,
          Resolution: regDeviceDetails?.selectedResolution,
          Wireless: isMonitor
            ? regDeviceDetails?.selectedConnectivity
            : undefined,
          Type: isKeyboard ? regDeviceDetails?.keyboardType : undefined,
          In_built_bluetooth:
            isKeyboard || isMouse ? regDeviceDetails?.hasBluetooth : undefined,
          Frame_Rate: regDeviceDetails?.selectedFrameRate,
          Printing_Techonolgy: isPrinter
            ? regDeviceDetails?.selectedTech
            : undefined,
          Function: regDeviceDetails?.selectedFunction,
          Bandwidth: isRouter ? regDeviceDetails?.deviceSpeed : undefined,
        },
        ClientId: workflowState["config"]?.ClientId,
        ClientAccountId: agreement.ClientAccount.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        // Files: regDeviceDetails?.purchasedReceiptAdded ? [getFileData()] : [],
        Documents: documents,
        ModelNo: regDeviceDetails?.modelNumber,
        SerialNumber: regDeviceDetails?.serialNumber,
        PurchaseDate: regDeviceDetails?.purchasedDate,
        PurchasePrice: regDeviceDetails?.purchasedPrice,
      },
    };

    console.log({ DeviceRegistrationParameters: JSON.stringify(DeviceRegistrationParameters)})
    API[ActionTypes.DEVICE_REGISTRATION](DeviceRegistrationParameters)
      .then((data) => {

        // if(data?.data?.DeviceRegistrationResults?.message?.includes("Please configure the device from Add New Device feature in Horizon Service Portal")){
        if(data?.data?.DeviceRegistrationResults?.hasOwnProperty('IsDeviceConfigured') && !data?.data?.DeviceRegistrationResults?.IsDeviceConfigured){
          // this is a spec error so create enquiry
          createRegDeviceEnquiry();
          return;
        } else {
          showLoader(false);
          updateState({
            ...workflowState,
            RegDeviceResponse: data,
          });

          updateViews();
        }
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        logger({ type: ActionTypes.DEVICE_REGISTRATION, error, state: workflowState});
        showLoader(false);
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const createRegDeviceEnquiry = async () => {
    let documents;
    if(regDeviceDetails?.purchasedReceiptAdded){
      documents = await getFileData()
    } 
    const assets = agreement?.Assets?.Asset[0];
    const contact = getContactNumber(agreement?.ContactPoints?.ContactPoint);
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: contact,
        FirstName: agreement.Customers?.Customer[0]?.FirstName,
        LastName: agreement.Customers?.Customer[0]?.LastName,
        ContactNumber: contact,
        ClientName: workflowState["config"]?.ClientName,
        InquiryChannel: "ONLINE",
        // InquiryType: ( regDeviceDetails.hasOwnProperty("IsImeiExist") && (isLaptop || isTablet) ) ? ( regDeviceDetails.IsImeiExist ? "SCM Request" : "SPEX Check" ) : "SCM Request",
        InquiryType: "Device Registration",
        // InquirySubType: "New Device Addition",
        InquirySubType: ( regDeviceDetails.hasOwnProperty("IsImeiExist") && (isLaptop || isTablet) ) ? ( regDeviceDetails.IsImeiExist ? "Device Exclusion Policy" : "SPEX Check" ) : "Devices Specification Unavailable",
        InquiryDescription: getSelectedAttributes("|", false),
        CallDriverCategory: "Master Data Management",
        CallDriverSubCategory: "Master Data Management",
        ClientId: workflowState["config"]?.ClientId,
        Email: loginDetails?.email,
        // DepartmentType: "Supply Chain",
        DepartmentType: ( regDeviceDetails.hasOwnProperty("IsImeiExist") && (isLaptop || isTablet) ) ? ( regDeviceDetails.IsImeiExist ? "Supply Chain" : "Risk" ) : "Supply Chain",
        Priority: "High",
        ProgramName: agreement.ClientOffer.ClientOfferName,
        AgreementId: agreement.AgreementId,
        ClientAccountId: agreement.ClientAccount.ClientAccountId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
        AssetId: "1C4CF674EBD411ECBEFC06D55EEA719D", //todo
        DeviceDetails: {
          Category: regDeviceDetails?.selectedCategory?.type,
          AssetMake: regDeviceDetails?.selectedBrand,
          AssetModel: getAssetModelData("_"),
          AssetAttributes: getSelectedAttributes("|"),
          Display_size: regDeviceDetails?.selectedScreenSize,
          Display_tech: regDeviceDetails?.selectedTech,
          Google_TV: isTV
            ? regDeviceDetails?.isGoogleTv
              ? "Yes"
              : "No"
            : undefined,
          Capacity: isTablet ? regDeviceDetails?.selectedCapacity : undefined,
          Connectivity: !isPrinter
            ? regDeviceDetails?.selectedConnectivity
            : undefined,
          Model: regDeviceDetails?.selectedDeviceModel,
          PurchaseDate: regDeviceDetails?.purchasedDate,
          PurchasePrice: regDeviceDetails?.purchasedPrice,
          ModelNo: regDeviceDetails?.modelNumber,
          SerialNumber: regDeviceDetails?.serialNumber,
          Processor: regDeviceDetails?.selectedProcessor,
          RAM: regDeviceDetails?.selectedRAM,
          Storage_Size: isLaptopDesktop
            ? regDeviceDetails?.selectedCapacity
            : undefined,
          Storage_Type: regDeviceDetails?.selectedStorageType,
          Resolution: regDeviceDetails?.selectedResolution,
          Wireless: isMonitor
            ? regDeviceDetails?.selectedConnectivity
            : undefined,
          Type: isKeyboard ? regDeviceDetails?.keyboardType : undefined,
          In_built_bluetooth: isKeyboard
            ? regDeviceDetails?.hasBluetooth
            : undefined,
          Frame_Rate: regDeviceDetails?.selectedFrameRate,
          Printing_Techonolgy: isPrinter
            ? regDeviceDetails?.selectedTech
            : undefined,
          Function: regDeviceDetails?.selectedFunction,
          Bandwidth: isRouter ? regDeviceDetails?.deviceSpeed : undefined,
        },
        UpdateDeviceDetails: {
          AssetCatalogId: "",
          IMEI: "",
          ClientAssetSkuNumber: "",
        },
        // Files: regDeviceDetails?.purchasedReceiptAdded ? [getFileData()] : [],
        // Documents: regDeviceDetails?.purchasedReceiptAdded ? [getFileData()]: []
        Documents: documents
      },
    };
    console.log({CreateInquiryParams: JSON.stringify(CreateInquiryParams)})
    API[ActionTypes.CREATE_ENQUIRY](CreateInquiryParams)
      .then((data) => {
        showLoader(false);
        updateState({
          ...workflowState,
          enquiryResponse: data,
        });

        updateViews(true);
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
        logger({ type: ActionTypes.CREATE_ENQUIRY, error, state: workflowState});
        refreshStateOnLogout(workflowState, "service-unavailable");
        updateState({
          ...workflowState,
          [currentStep]: {
            ...workflowState[currentStep],
          },
        });
      });
  };

  const getAssetModelData = (separator) => {
    let assetModelData = `${regDeviceDetails?.selectedBrand}${separator}${
      regDeviceDetails?.selectedCategory?.type
    }${!isMouse ? separator : ""}${
      isTV && !isEmpty(regDeviceDetails?.selectedScreenSize)
        ? regDeviceDetails?.selectedScreenSize + separator
        : ""
    }${
      isTV && !isEmpty(regDeviceDetails?.selectedTech)
        ? regDeviceDetails?.selectedTech + separator
        : ""
    }${isTV && regDeviceDetails?.isGoogleTv ? "Google_TV" : ""}${
      isTablet && !isEmpty(regDeviceDetails?.selectedDeviceModel)
        ? regDeviceDetails?.selectedDeviceModel + separator
        : ""
    }${
      isTablet && !isEmpty(regDeviceDetails?.selectedCapacity)
        ? regDeviceDetails?.selectedCapacity + separator
        : ""
    }${
      isTablet && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? regDeviceDetails?.selectedConnectivity
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedProcessor)
        ? regDeviceDetails?.selectedProcessor + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedRAM)
        ? regDeviceDetails?.selectedRAM + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedCapacity)
        ? regDeviceDetails?.selectedCapacity + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedStorageType)
        ? regDeviceDetails?.selectedStorageType
        : ""
    }${
      isPrinter && !isEmpty(regDeviceDetails?.selectedTech)
        ? regDeviceDetails?.selectedTech + separator
        : ""
    }${
      isPrinter && !isEmpty(regDeviceDetails?.selectedFunction)
        ? regDeviceDetails?.selectedFunction
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedResolution)
        ? regDeviceDetails?.selectedResolution + separator
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedFrameRate)
        ? regDeviceDetails?.selectedFrameRate + separator
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? regDeviceDetails?.selectedConnectivity
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedScreenSize)
        ? regDeviceDetails?.selectedScreenSize + separator
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedResolution)
        ? regDeviceDetails?.selectedResolution
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? regDeviceDetails?.selectedConnectivity === "Yes" ? separator + "Wireless": ""
        : ""
    }${
      isSoundbar && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? regDeviceDetails?.selectedConnectivity
        : ""
    }${
      isKeyboard && !isEmpty(regDeviceDetails?.keyboardType)
      ? regDeviceDetails?.keyboardType
      : ""
    }${
      isKeyboard && !isEmpty(regDeviceDetails?.hasBluetooth)
      ? regDeviceDetails?.hasBluetooth === "Yes" ? separator + "In_built_bluetooth": ""
      : ""
    }${
      isMouse && !isEmpty(regDeviceDetails?.selectedConnectivity)
      ? regDeviceDetails?.hasBluetooth === "Yes" ? separator + "In_built_bluetooth": ""
      : ""
    }${
      isRouter && !isEmpty(regDeviceDetails?.deviceSpeed)
        ? regDeviceDetails?.deviceSpeed
        : ""
    }`;

    return assetModelData.charAt(assetModelData.length - 1) === separator
      ? assetModelData.substring(0, assetModelData.length - 1)
      : assetModelData;
  };

  const getSelectedAttributes = (separator) => {

    let inquiryDescription =  `${!isEmpty(regDeviceDetails?.modelNumber) ? "ModelNo="+regDeviceDetails?.modelNumber + separator: ""}${!isEmpty(regDeviceDetails?.serialNumber) ? "SerialNumber="+regDeviceDetails?.serialNumber + separator : ""
  }${
      isTV && !isEmpty(regDeviceDetails?.selectedScreenSize)
        ? "Display_size=" + regDeviceDetails?.selectedScreenSize + separator
        : ""
    }${
      isTV && !isEmpty(regDeviceDetails?.selectedTech)
        ? "Display_tech=" + regDeviceDetails?.selectedTech + separator
        : ""
    }${
      isTV
        ? regDeviceDetails?.isGoogleTv
          ? "Google_TV=Yes" + separator
          : "Google_TV=No" + separator
        : ""
    }${
      isTablet && !isEmpty(regDeviceDetails?.selectedDeviceModel)
        ? "Model=" + regDeviceDetails?.selectedDeviceModel + separator
        : ""
    }${
      isTablet && !isEmpty(regDeviceDetails?.selectedCapacity)
        ? "Capacity=" + regDeviceDetails?.selectedCapacity + separator
        : ""
    }${
      isTablet && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? "Connectivity=" + regDeviceDetails?.selectedConnectivity + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedProcessor)
        ? "Processor=" + regDeviceDetails?.selectedProcessor + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedRAM)
        ? "RAM=" + regDeviceDetails?.selectedRAM + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedCapacity)
        ? "Storage_Size=" + regDeviceDetails?.selectedCapacity + separator
        : ""
    }${
      isLaptopDesktop && !isEmpty(regDeviceDetails?.selectedStorageType)
        ? "Storage_Type=" + regDeviceDetails?.selectedStorageType + separator
        : ""
    }${
      isPrinter && !isEmpty(regDeviceDetails?.selectedTech)
        ? "Printing_Techonolgy=" + regDeviceDetails?.selectedTech + separator
        : ""
    }${
      isPrinter && !isEmpty(regDeviceDetails?.selectedFunction)
        ? "Function=" + regDeviceDetails?.selectedFunction + separator
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedResolution)
        ? "Resolution=" + regDeviceDetails?.selectedResolution + separator
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedFrameRate)
        ? "Frame_Rate=" + regDeviceDetails?.selectedFrameRate + separator
        : ""
    }${
      isWebcam && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? "Connectivity=" + regDeviceDetails?.selectedConnectivity + separator
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedScreenSize)
        ? "Display_size=" + regDeviceDetails?.selectedScreenSize + separator
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedResolution)
        ? "Resolution=" + regDeviceDetails?.selectedResolution + separator
        : ""
    }${
      isMonitor && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? "Wireless=" + regDeviceDetails?.selectedConnectivity + separator
        : ""
    }${
      isSoundbar && !isEmpty(regDeviceDetails?.selectedConnectivity)
        ? "Connectivity=" + regDeviceDetails?.selectedConnectivity + separator
        : ""
    }${
      isKeyboard && !isEmpty(regDeviceDetails?.keyboardType)
        ? "Type=" + regDeviceDetails?.keyboardType + separator
        : ""
    }${
      isKeyboard && !isEmpty(regDeviceDetails?.hasBluetooth)
        ? "In_built_bluetooth=" + regDeviceDetails?.hasBluetooth + separator
        : ""
    }${
      isMouse && !isEmpty(regDeviceDetails?.hasBluetooth)
        ? "In_built_bluetooth=" + regDeviceDetails?.hasBluetooth + separator
        : ""
    }${
      isRouter && !isEmpty(regDeviceDetails?.deviceSpeed)
        ? "Bandwidth=" + regDeviceDetails?.deviceSpeed + separator
        : ""
    }Category=${regDeviceDetails?.selectedCategory?.type}${separator}Make=${
      regDeviceDetails?.selectedBrand
    }${separator}${
      !isEmpty(regDeviceDetails?.purchasedDate)
        ? "PurchaseDate=" + regDeviceDetails?.purchasedDate + separator
        : ""
    }${
      !isEmpty(regDeviceDetails?.purchasedPrice)
        ? "PurchasePrice=" + regDeviceDetails?.purchasedPrice
        : ""
    }`;

    return inquiryDescription.charAt(inquiryDescription.length - 1) ===
      separator
      ? inquiryDescription.substring(0, inquiryDescription.length - 1)
      : inquiryDescription;
  };

  const updateViews = (isEnquiryCreated = false) => {
    setShowButtonContainer(false);
    setTitle(
      isEnquiryCreated
        ? homeplusscript.titleEnquiryCreated
        : homeplusscript.titleDeviceRegistered
    );
  };

  const getFileData = async () => {
    const fileName = regDeviceDetails?.receiptFileName

    const params = {
      FileDownloadRequest: {
        AgreementId: agreement?.AgreementId,
        FileName: fileName
      }
    }
    const data  = await getSignedURLForDownload(params)
    const { bucketName, url} = data;
    return [{
      BucketName: bucketName,
      Path: fileName,
      Type: regDeviceDetails?.purchasedReceiptFile?.type,
      Status: 'uploaded'
    }];
  };

  const goToRegDevice = () => {
    // clear all device reg data
    updateState({
      ...workflowState,
      RegDeviceDetails: {},
    });
    return completeCurrentStep(currentStep, {
      // for next step
      addAnotherDevice: true,
    });
  };

  const isPdf = regDeviceDetails?.purchasedReceiptFile?.type === "application/pdf";
  const getFile = () => {
    if(isPdf){
      return regDeviceDetails?.purchasedReceiptPdf
    }
    return regDeviceDetails?.purchasedReceiptFile
  }

  devRegSummary.args = {
    ...devRegSummary.args,
    tableHeaders: regDeviceTableHeaders,
    tableData: regDeviceTableData,
    previewFile: getFile(),
    isPdf
  };

  btnContainer.args = {
    ...btnContainer.args,
    displayClasses: showButtonContainer ? "block" : "hidden",
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: title,
  };

  btnYes.args = {
    ...btnYes.args,
    label: homeplusscript.btnYes,
    onClick: (e) => onYesClick(),
  };

  btnEdit.args = {
    ...btnEdit.args,
    label: homeplusscript.btnEdit,
    onClick: (e) => editDeviceDetails(),
  };

  btnAddDevice.args = {
    ...btnAddDevice.args,
    label: homeplusscript.addAnotherDevice,
    onClick: (e) => goToRegDevice(),
    displayClasses: showButtonContainer ? "hidden" : "block",
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"]
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default DeviceRegSummary;
