import * as React from "react";

function CallbackPhoneSvg(props) {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4715 25.1411C20.3893 24.2237 20.905 22.9791 20.905 21.6813C20.905 20.3835 20.3893 19.139 19.4715 18.2215L14.2787 13.0336C12.3684 11.1239 9.27183 11.1239 7.36154 13.0336L4.51688 15.8807C2.0572 18.3475 1.67087 22.2036 3.59231 25.1093C11.9701 37.7187 22.7805 48.529 35.3898 56.9068C38.2971 58.8303 42.1564 58.4429 44.6233 55.9798L47.4704 53.1352C49.3802 51.2249 49.3802 48.1283 47.4704 46.218L42.2801 41.0325C41.3626 40.1147 40.1181 39.599 38.8203 39.599C37.5225 39.599 36.2779 40.1147 35.3605 41.0325L33.6312 42.7618C27.8294 37.9894 22.5049 32.6648 17.7324 26.8631L19.4715 25.1411Z"
        stroke="#1ED760"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.9432 31.2266C52.0485 31.2266 58.619 24.656 58.619 16.5508C58.619 8.44557 52.0485 1.875 43.9432 1.875C35.838 1.875 29.2675 8.44557 29.2675 16.5508C29.2675 24.656 35.838 31.2266 43.9432 31.2266Z"
        stroke="#1ED760"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.7429 11.9541L43.8752 21.126C43.5656 21.5373 43.0931 21.7945 42.5797 21.8313C42.0662 21.8681 41.5619 21.6808 41.1968 21.3179L37.6445 17.7656"
        stroke="#1ED760"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CallbackPhoneSvg;
