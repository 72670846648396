/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.245 18C15.2975 18.2325 15.35 18.4275 15.395 18.6225C15.5075 19.095 15.635 19.56 15.74 20.0325C15.7775 20.1975 15.8375 20.2725 16.0175 20.2575C16.3175 20.2425 16.625 20.25 16.9325 20.2575C17.465 20.265 17.7575 20.565 17.7575 21.09C17.7575 21.3075 17.7575 21.525 17.7575 21.75C17.75 22.1775 17.45 22.485 17.0225 22.5075C16.9625 22.5075 16.895 22.5075 16.835 22.5075C13.955 22.5075 11.0675 22.5075 8.1875 22.5075C7.5275 22.5075 7.2575 22.2375 7.2575 21.585C7.2575 21.405 7.2575 21.225 7.2575 21.045C7.2575 20.5875 7.5575 20.28 8.015 20.2575C8.36 20.2425 8.705 20.2575 9.0425 20.25C9.11 20.25 9.2225 20.1825 9.2375 20.1225C9.4175 19.4625 9.575 18.795 9.74 18.1275C9.74 18.0975 9.74 18.0675 9.74 18.0075H9.4325C7.1225 18.0075 4.805 18.0075 2.495 18.0075C1.8425 18.0075 1.3925 17.6775 1.2725 17.1075C1.25 17.01 1.25 16.905 1.25 16.8075C1.2575 12.0975 1.2575 7.4025 1.2575 2.7075C1.2575 1.9725 1.7225 1.5 2.4575 1.5C9.155 1.5 15.86 1.5 22.565 1.5C23.3 1.5 23.7575 1.9725 23.7575 2.7075C23.7575 7.395 23.7575 12.0825 23.7575 16.77C23.7575 17.5425 23.3075 17.9925 22.535 17.9925C20.2175 17.9925 17.8925 17.9925 15.575 17.9925C15.4775 17.9925 15.38 17.9925 15.245 17.9925V18ZM23.0075 14.9925V14.7225C23.0075 10.755 23.0075 6.7875 23.0075 2.82C23.0075 2.3475 22.9175 2.2575 22.4525 2.2575C15.8225 2.2575 9.1925 2.2575 2.5625 2.2575C2.105 2.2575 2.015 2.3475 2.015 2.805C2.015 6.78 2.015 10.755 2.015 14.73V14.9925H23.0075ZM2.015 15.765C2.015 16.1625 2.0075 16.545 2.015 16.92C2.015 17.0925 2.135 17.205 2.315 17.2275C2.4125 17.2425 2.5175 17.2425 2.6225 17.2425C9.215 17.2425 15.8075 17.2425 22.4 17.2425C22.49 17.2425 22.5875 17.2425 22.6775 17.235C22.865 17.22 22.9925 17.1 23 16.92C23.015 16.5375 23 16.155 23 15.765H2.015ZM14.465 17.9925C13.3925 17.9925 12.3425 17.9925 11.3 17.9925C11.0525 17.9925 10.7375 17.925 10.58 18.045C10.43 18.1575 10.43 18.48 10.37 18.72C10.2425 19.215 10.1225 19.71 9.995 20.2275H15.02L14.465 17.9925ZM17 21.015H8.0225V21.7275H17V21.015Z"
      fill="#1ED760"
    />
  </svg>
);

export default icon;
