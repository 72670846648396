import {
  Button,
  makeStyles,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import ClaimRelatedIconSvg from "./assets/ClaimRelatedIconSvg";
import clsx from "clsx";
import { Colors } from "./entities/Colors";
import { isMobileWidth } from "./utils";

const chatEnquiryTexts = {
  enquiryNotice:
    "A member of our team will contact you to help with your request. Please provide us your contact details and we will get in touch soon.",
  formFullName: "Full name",
  formRefNumber: "Insured Reference Number",
  formPhoneNumber: "Phone Number",
  formEmail: "Email address",
  formEnquiryType: "Select enquiry type",
  formDescription: "Description",
  formRequiredMessage: "This is required *",
  formInvalidEmailMessage: "Invalid Email Address *",
  btnSubmit: "Submit",
  btnSubmitLoading: "Submitting...",
  btnCancel: "Cancel",
};

const inquiryTypeOptions = [
  { value: "", label: "Select" },
  { value: "Policy Termination", label: "Policy Termination" },
  { value: "Product related", label: "Product related" },
  { value: "Policy related", label: "Policy related" },
  {
    value: "Device registration related",
    label: "Device registration related",
  },
  { value: "Claims processing", label: "Claims processing" },
  { value: "Claims Fulfillment", label: "Claims Fulfillment" },
  {
    value: "Premiums / Deductible / Overage",
    label: "Premiums / Deductible / Overage",
  },
  {
    value: "Tech Support / Security Advisor",
    label: "Tech Support / Security Advisor",
  },
  { value: "VOC / Complaint", label: "Feedback" },
  { value: "Others", label: "Others" },
];

export const ChatEnquiry = ({
  emailAddress,
  fullName,
  insuredReferenceNumber,
  phoneNumber,
  loading,
  descriptionLength = 1000,
  onSubmitEnquiryForm,
  onCancelEnquiryForm,
}) => {
  console.log('fullName', fullName);
  console.log('emailAddress', emailAddress)
  console.log('insuredReferenceNumber', insuredReferenceNumber)
  const classes = ChatEnquiryStyles();
  const [textAreaCount, setTextAreaCount] = useState(descriptionLength);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [state, setState] = useState({
    fullName: {
      value: fullName || "",
      error: true,
    },
    email: {
      value: emailAddress || "",
      error: true,
    },
    refNumber: {
      value: insuredReferenceNumber || "",
      error: true,
    },
    phoneNumber: {
      value: phoneNumber || "",
      error: true,
    },
    enquiryType: {
      value: "",
      error: true,
    },
    description: {
      value: "",
      error: true,
    },
  });

  const validateHasValue = useCallback((value) => {
    return value ? false : true;
  }, []);

  const validateEmail = useCallback((email) => {
    if (!email) {
      setIsEmailValid(true);
      return true;
    } else {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = re.test(String(email).toLocaleLowerCase());
      if (!result) {
        setIsEmailValid(false);
        return true;
      }
    }
  }, []);

  const validateDescription = useCallback((value) => {
    setTextAreaCount(descriptionLength - value.length);
    return value ? false : true;
  }, [descriptionLength]);

  const validateField = useCallback((fieldName, value) => {
    switch (fieldName) {
      case "fullName":
        return validateHasValue(value);
      case "email":
        return validateEmail(value);
      case "enquiryType":
        return validateHasValue(value);
      case "description":
        return validateDescription(value);
      default:
    }
  }, [validateHasValue, validateEmail, validateDescription]);

  const validateForm = () => {
    return (
      !state.fullName.error &&
      !state.email.error &&
      !state.enquiryType.error &&
      !state.description.error
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: {
        value: value,
        error: validateField(name, value),
      },
      email: {
        error: validateField('email', state.email.value),
      },
      fullName: {
        error: validateField('fullName', state.fullName.value),
      },
    }));
  };

  let chatEnquiryRootStyle = {};
  if (isMobileWidth()) {
    chatEnquiryRootStyle = {
      maxWidth: "unset",
      // height: '100vh',
      overflowY: "auto",
    };
  }

  useEffect(() => {
    if (state.email.value !== emailAddress) {
      setState((state) => ({
        ...state,
        email: {
          value: emailAddress,
          error: false,
        },
        
      }));
    }

    if (state.fullName.value !== fullName) {
      setState((state) => ({
        ...state,
        fullName: {
          value: fullName,
          error: false,
        }
      }));
    }

    if (state.refNumber.value !== insuredReferenceNumber) {
      setState((state) => ({
        ...state,
        refNumber: {
          value: insuredReferenceNumber,
          error: false,
        }
      }));
    }

    if (state.phoneNumber.value !== phoneNumber) {
      setState((state) => ({
        ...state,
        phoneNumber: {
          value: phoneNumber,
          error: false,
        }
      }));
    }
  }, [emailAddress, state, phoneNumber, fullName, insuredReferenceNumber, validateField, validateEmail])

  return (
    <div className={classes.chatEnquiryRoot} style={chatEnquiryRootStyle}>
      <div className={classes.enquiryNotice}>
        <ClaimRelatedIconSvg />
        <p>{chatEnquiryTexts.enquiryNotice}</p>
      </div>
      <form autoComplete="off" className={classes.chatEnquiryForm}>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formFullName}
          </Typography>
          <TextField
            variant="filled"
            required
            name="fullName"
            defaultValue={state.fullName.value}
            inputProps={
              { readOnly: true, }
            }
            onChange={handleChange}
            // error={state.fullName.error}
            // helperText={state.fullName.error && chatEnquiryTexts.formRequiredMessage}
            fullWidth
            className={clsx({
              [classes.fieldInput]: true,
              [classes.readOnly]: true,
              // [classes.fieldWarning]: state.fullName.error
            })}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formEmail}
          </Typography>
          <TextField
            variant="filled"
            required
            name="email"
            inputProps={
              { readOnly: true, }
            }
            defaultValue={state.email.value}
            onChange={handleChange}
            // error={state.email.error}
            // helperText={
            //   (state.email.error && !isEmailValid) ?
            //   (chatEnquiryTexts.formInvalidEmailMessage) : (
            //     (state.email.error && isEmailValid && chatEnquiryTexts.formRequiredMessage)
            //   )
            // }
            fullWidth
            className={clsx({
              [classes.fieldInput]: true,
              [classes.readOnly]: true,
              // [classes.fieldWarning]: state.email.error
            })}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formPhoneNumber}
          </Typography>
          <TextField
            variant="filled"
            required
            name="phoneNumber"
            defaultValue={state.phoneNumber.value}
            inputProps={
              { readOnly: true, }
            }
            fullWidth
            className={clsx({
              [classes.fieldInput]: true,
              [classes.readOnly]: true,
              // [classes.fieldWarning]: state.fullName.error
            })}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
        {/* <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formRefNumber}
          </Typography>
          <TextField
            variant="filled"
            required
            name="refNumber"
            defaultValue={state.refNumber.value}
            inputProps={
              { readOnly: true, }
            }
            fullWidth
            className={clsx({
              [classes.fieldInput]: true,
              [classes.readOnly]: true,
              // [classes.fieldWarning]: state.fullName.error
            })}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div> */}
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formEnquiryType}
          </Typography>
          <TextField
            className={clsx({
              [classes.fieldSelect]: true,
              // [classes.fieldWarning]: state.enquiryType.error
            })}
            select
            variant="outlined"
            name="enquiryType"
            value={state.enquiryType.value}
            onChange={handleChange}
            fullWidth
            // error={state.enquiryType.error}
            // helperText={state.enquiryType.error && chatEnquiryTexts.formRequiredMessage}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
              IconComponent: (props) => <ExpandMore {...props} />,
            }}
            InputProps={{
              disableUnderline: true,
            }}
          >
            {inquiryTypeOptions.map((option) => (
              <MenuItem
                key={`inquiry-option-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            {chatEnquiryTexts.formDescription}
          </Typography>
          <TextareaAutosize
            className={clsx({
              [classes.fieldTextArea]: true,
              // [classes.fieldTextAreaWarning]: state.description.error
            })}
            style={{ outline: "none" }}
            onChange={handleChange}
            name="description"
            maxLength={descriptionLength}
          />
          <div className={classes.fieldTextAreaDetails}>
            {/* {<FormHelperText 
                error={state.description.error}
                style={{ visibility: !state.description.error && 'hidden' }}
                >
                  {chatEnquiryTexts.formRequiredMessage}
               </FormHelperText>} */}
            <Typography
              variant="subtitle2"
              className={classes.fieldTextAreaCount}
            >
              {textAreaCount}/{descriptionLength}
            </Typography>
          </div>
        </div>
      </form>
      <div className={classes.enquiryButtonsContainer}>
        <Button
          variant="outlined"
          disabled={loading}
          // width={88}
          onClick={() => onCancelEnquiryForm()}
        >
          {chatEnquiryTexts.btnCancel}
        </Button>
        <Button
          variant="contained"
          disabled={
            loading ||
            state.fullName.error ||
            state.email.error ||
            state.enquiryType.error ||
            state.description.error
          }
          // className={classes.enquirySubmitBtn}
          color={"primary"}
          onClick={() => {
            if (validateForm()) onSubmitEnquiryForm(state);
          }}
        >
          {loading
            ? chatEnquiryTexts.btnSubmitLoading
            : chatEnquiryTexts.btnSubmit}
        </Button>
      </div>
    </div>
  );
};

const ChatEnquiryStyles = makeStyles((theme) => ({
  chatEnquiryRoot: {
    padding: "0 15px 15px",
    width: "100%",
    //  maxWidth: "330px",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: "0 15px 130px",
    },
  },
  enquiryNotice: {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    padding: "5px",
    "& p": {
      flex: 1,
      color: Colors.BLACK_NEUTRAL,
    },
  },
  chatEnquiryForm: {
    display: "flex",
    flexFlow: "column wrap",
    gap: "15px",
  },
  fieldContainer: {
    "& textarea": {
      "&:hover": {
        boxShadow: `0px 0px 0px 3px ${Colors.LIGHT_BLUE}`,
        borderColor: Colors.ACCENT_BLUE,
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "4px 0 0 0",
      color: Colors.ACCENT_MAROON,
      fontSize: "14px",
    },
  },
  fieldTitle: {
    color: Colors.BLACK_NEUTRAL,
    paddingBottom: "5px",
  },
  fieldInput: {
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      background: "transparent",
      border: `1px solid ${Colors.DARK_GRAY}`,

      padding: "16px 12px",
      "&:hover": {
        borderColor: Colors.ACCENT_BLUE,
      },
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
  },
  fieldSelect: {
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
    },
    //   border: `1px solid ${Colors.DARK_GRAY}`,
    //   borderRadius: '4px',
    //   padding: '8px 4px',

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.PRIMARY_CONTRAST_TEXT,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
    },

    "&:hover .MuiSvgIcon-root": {
      color: Colors.PRIMARY_CONTRAST_TEXT,
    },

    "& .MuiSelect-iconOpen": {
      color: Colors.PRIMARY_CONTRAST_TEXT,
    },

    "& .MuiSelect-icon": {
      right: "10px",
    },
  },
  fieldTextArea: {
    height: "100px",
    minHeight: "100px",
    width: "100%",
    borderRadius: "4px",
    padding: "8px 12px 4px 12px",

    border: `1px solid ${Colors.DARK_GRAY}`,
    "&: focus": {
      outline: "none",
    },
  },
  fieldWarning: {
    "& .MuiInputBase-root": {
      border: "1px solid #f44336",
    },
  },
  fieldTextAreaWarning: {
    border: "1px solid #f44336",
  },
  fieldTextAreaDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldTextAreaCount: {
    color: Colors.GRAY_NEUTRAL,
    textAlign: "right",
    marginTop: "-4px",
    fontWeight: 400,
    float: "right",
    width: "100%",
  },
  enquiryButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "30px",
    marginBottom: "3px",

    "& button": {
      borderRadius: "4px",
      minWidth: "88px",
      height: "44px",
      fontWeight: 500,
    },
  },
  readOnly: {
  
    '& > div': {
      color: Colors.BLACK_NEUTRAL,
      backgroundColor: `${Colors.GRAY} !important`
    },
    '& input': {
      color: Colors.BLACK_NEUTRAL,
      backgroundColor: Colors.GRAY
    }
    
  }
  // enquirySubmitBtn: {
  //     background: Colors.PRIMARY_CONTRAST_TEXT,
  //     border: 'none',
  // }
}));
