import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById } from "../enrollment-workflow/helper";

function DeviceAdditionalInfo() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['DeviceAdditionalInfo']
  const homeplusscript = workflowState['config']?.scripts["DeviceAdditionalInfo"];

  const [deviceDate, setDevicePurchaseDate] = useState("");

  let inputPurchasedDate = findById(metadata, "inputPurchasedDate");
  inputPurchasedDate.args = {
    ...inputPurchasedDate.args,
    label:homeplusscript.inputPurchasedDate_label,
    value: deviceDate,
    onChange: setDevicePurchaseDate,
  };

  let selctedDeviceNoteText = findById(metadata,"selctedDeviceNote");
  let deviceInfoNoteText = findById(metadata,"deviceInfoNote");
  
  let deviceAddMeun = findById(metadata,"menu");
  let inputSerialNumber = findById(metadata,"inputSerialNumber");
  let infoLink = findById(metadata,"infoLink");
  let inputDevicePrice = findById(metadata,"inputDevicePrice");
  let fileUpload = findById(metadata,"fileUpload");
  let btnContinue = findById(metadata,"btnContinue");


  selctedDeviceNoteText.args = {
    ...selctedDeviceNoteText.args, 
    content:homeplusscript.selctedDeviceNote
  };
  deviceInfoNoteText.args = {
    ...deviceInfoNoteText.args, 
    content:homeplusscript.deviceInfoNote
  };
  deviceAddMeun.args = {
    ...deviceAddMeun.args,
    iconSubHeader: homeplusscript.menu_iconSubHeader,
    labelSubHeader: homeplusscript.menu_labelSubHeader
  };
  inputSerialNumber.args = {
    ...inputSerialNumber.args, 
    label: homeplusscript.inputSerialNumber_label
  }
  infoLink.args = {
    ...infoLink.args, 
    label:homeplusscript.infoLink_label
  };
  inputDevicePrice.args = {
    ...inputDevicePrice.args, 
    label:homeplusscript.inputDevicePrice_label
  }
  fileUpload.args = {
    ...fileUpload.args, 
    label:homeplusscript.fileUpload_label
  }
  btnContinue.args = {
    ...btnContinue.args, 
    label : homeplusscript.btnContinue_label
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default DeviceAdditionalInfo;
