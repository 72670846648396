const findById = (metadata, id) => {
  let control = metadata.find((item) => item.id === id);
  if (!control) {
    let stacks = metadata.filter((item) => item.type === "Stack");
    for (let stack of stacks) {
      if (stack?.args?.metadata) {
        let internalControl = findById(stack?.args?.metadata, id);
        if (internalControl) {
          return internalControl;
        }
      }
    }
  }
  return control;
};

function filterDeviceSpecs(deviceList, model, spec = null, flow = null) {
  let deviceModels = uniqFilter(deviceList, "Family"); // uniq filter deviceList by family
  let selectedModel = find(deviceList, "Family", model); // find the selected model from deviceList

  let deviceColors,
    deviceSizes = [];

  if (flow === "coolade") {
    let color = spec; //

    deviceColors = uniqFilter(selectedModel, "ClientColor"); // then use it to filter colors from size
    let selectedColor = find(selectedModel, "ClientColor", deviceColors[0]); // and also use it to find selected size
    deviceSizes = uniqFilter(selectedColor, "Capacity"); // then use it to filter colors from size
  } else {
    let capacity = spec;

    deviceSizes = uniqFilter(selectedModel, "Capacity"); // use selectedModel to find it's capacities
    let selectedSize = find(selectedModel, "Capacity", capacity); // and also use it to find selected size

    deviceColors = uniqFilter(selectedSize, "ClientColor"); // then use it to filter colors from size
  }

  return {
    deviceModels,
    deviceSizes,
    deviceColors,
  };
}

let returnDAXSupportedDevices = (modelsByMake, devices) => {
  return modelsByMake.filter((model) => {
    return devices.some((device) => {
      return model.Family?.toUpperCase() === device.name.toUpperCase();
    });
  });
};

function toPascalCase() {
  return this.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
  });
}

let formatAppleModel = (model) => {
  let modelArr = model.split(" ");
  let iphone = modelArr[0];
  let name = model.split("IPHONE");
  iphone =
    iphone.charAt(0).toLowerCase() +
    iphone.charAt(1).toUpperCase() +
    iphone.substr(2).toLowerCase();
  return `${iphone} ${name.join(" ").toUpperCase()}`;
};

let formatModelName = (make = "", model = "") => {
  let uppercaseMake = make.toUpperCase();
  let formattedModel = model.toUpperCase().replace(`${uppercaseMake} `, "");
  if (uppercaseMake === "APPLE")
    formattedModel = formatAppleModel(formattedModel);
  else formattedModel = formattedModel.toPascalCase();
  return formattedModel;
};

let formatCapacity = (capacity = "") => {
  return capacity?.toUpperCase().indexOf("GB") > -1
    ? capacity
    : `${capacity}GB`;
};
let createKeyValuePair = (targetArray) => {
  return targetArray.map((currentValue) => {
    return { label: currentValue, value: currentValue };
  });
};
let createModelKeyValuePair = (targetArray) => {
  return targetArray.map((currentValue) => {
    return {
      label: currentValue.replace(/samsung/gi, "").replace(/apple/gi, ""),
      value: currentValue,
    };
  });
};
let uniqFilter = (targetArray, compareKey) => {
  let filterdData = targetArray.map((currentObj) => currentObj[compareKey]);
  return uniq(filterdData);
};

let find = (targetArray = [], compareKey, compareValue) => {
  let filterdData = targetArray.filter(
    (currentObj) => currentObj[compareKey] === compareValue
  );
  return uniq(filterdData);
};

let uniq = (targetArray = []) => {
  return [...new Set(targetArray)];
};

const getPrice = (
  PaymentTenure,
  planDetailsOfSelectedPlan = {},
  isNewPhone,
  otherDiscount,
  getAllQuotePromotionOffers
) => {
  let price =
    PaymentTenure == "YEARLY"
      ? planDetailsOfSelectedPlan?.yearlyEnrollmentFee ||
        planDetailsOfSelectedPlan?.YearlyEnrollmentFee
      : planDetailsOfSelectedPlan?.enrollmentFee ||
        planDetailsOfSelectedPlan?.EnrollmentFee;
  let offers =
    planDetailsOfSelectedPlan?.PromotionalOffer || getAllQuotePromotionOffers;
  let AnnualDiscount = offers?.filter(
    (v) => v.promotionalOfferCode === "DIGITAL PROMOTIONAL DISCOUNT"
  )[0];
  let NewPhnDiscount = offers?.filter(
    (v) => v.promotionalOfferCode === "NEWPH_DISC"
  )[0];
  let OtherDiscount = offers?.filter(
    (v) => v.promotionalOfferCode === otherDiscount
  )[0];
  // let OtherDiscount = offers?.filter((v) => v.promotionalOfferCode === 'dgpromo5')[0]
  let discountValue = 0;
  if (PaymentTenure == "YEARLY") {
    if (isNewPhone && NewPhnDiscount) {
      discountValue = discountValue + NewPhnDiscount?.discountValue;
    }
    if (AnnualDiscount) {
      discountValue = discountValue + AnnualDiscount?.discountValue;
    }
    if (OtherDiscount) {
      discountValue = discountValue + OtherDiscount?.discountValue;
    }
  } else if (PaymentTenure == "MONTHLY") {
    if (isNewPhone && NewPhnDiscount) {
      discountValue = discountValue + NewPhnDiscount?.discountValue;
    }
    if (OtherDiscount) {
      discountValue = discountValue + OtherDiscount?.discountValue;
    }
  }
  price = price - (price * discountValue) / 100;
  return price?.toFixed(2);
};
const CARD_IMG = {
  /*  VISA: require('../../assets/img-visa2.png'),
   MASTER: require('../../assets/img-master-2.png'),
   AMEX: require('../../assets/img-amex.png'), */
};
const checkCardType = (cardNumber) => {
  const cleanCardNumber = cardNumber && cardNumber.split(" ").join("");
  const CARD_TYPES = {
    VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MASTER: /^5[1-5][0-9]{14}$/,
    AMEX: /^3[47][0-9]{13}$/,
    DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  };
  let cardType = Object.keys(CARD_TYPES).find((cardType) =>
    CARD_TYPES[cardType].test(cleanCardNumber)
  );
  return {
    CardType: cardType,
    CardImg: CARD_IMG[cardType],
  };
};
const validateFileType = (file) => {
  let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
  return allowedExtensions.exec(file);
};
const formatMobile = (phoneNo) => {
  if (!phoneNo) {
    return "";
  }
  switch (phoneNo.length) {
    case 10:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    case 11:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
    case 9:
      return phoneNo.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    case 8:
      return phoneNo.replace(/(\d{4})(\d{4})/, "$1 $2");
    default:
      return phoneNo;
  }
};

const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

const containsObject = (obj, list, matchParam = "value") => {
  // const object = list.find((elem) => elem.value === obj.value);
  const object = list.find((elem) => elem[matchParam] === obj[matchParam]);
  return object !== undefined;
};

const getFileBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const covertToUTCDate = (date = new Date()) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ).toString();
};

const getFormmatedDate = (strDate, format = "d m yy", split = " ") => {
  const formats = format.split(split);
  const dateArray = [];
  const dateObj = new Date(strDate);
  const day = dateObj.getDay();
  const dayText = Days[day];
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const monthText = Months[month];
  const year = dateObj.getFullYear();
  formats.map((f) => {
    switch (f) {
      case "d":
        dateArray.push(date);
        return date;
      case "dd":
        const dd = ("0" + date).slice(-2);
        dateArray.push(dd);
        return dd;
      case "D":
        dateArray.push(dayText);
        return dayText;
      case "DDD":
        const dtext = dayText.substr(0, 3);
        dateArray.push(dtext);
        return dtext;
      case "m":
        dateArray.push(month + 1);
        return month + 1;
      case "mm":
        const mm = ("0" + (month + 1)).slice(-2);
        dateArray.push(mm);
        return mm;
      case "M":
        dateArray.push(monthText);
        return monthText;
      case "MMM":
        const mtext = monthText.substr(0, 3);
        dateArray.push(mtext);
        return mtext;
      case "yyyy":
        dateArray.push(year);
        return year;
      case "yy":
        const ytext = year.substr(2, 2);
        dateArray.push(ytext);
        return ytext;
      default:
        return "";
    }
  });
  return dateArray.join(split);
};

const getFormatedTime = (dateTime, isUTCDate, format = "hh mm A", split = " ") => {
  const formats = format.split(split);
  const dateArray = [];
  const dateObj = isUTCDate ? new Date(dateTime) : new Date(covertToUTCDate(new Date(dateTime)));
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  formats.map((f) => {
    switch (f) {
      case "A":
        const timeFormat = hour >= 12 ? "PM" : "AM" 
        dateArray.push(timeFormat);
        return timeFormat;
      case "hh":
        const dd = ("0" + hour).slice(-2);
        dateArray.push(dd);
        return dd;
      case "mm":
        const mm = ("0" + (minutes)).slice(-2);
        dateArray.push(mm);
        return mm;
      default:
        return "";
    }
  });
  
  if(split === ":")
    return customJoin(dateArray)

  return dateArray.join(split);
}

const getDateFromUtcTimeZone = (offset, date = new Date()) => {
  const offsetMultiplier = 3600000;
  const utcMs = new Date(covertToUTCDate(date)).getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

const isValidExpiryDate = (month, year) => {
  const givenMonth = parseInt(month);
  const givenYear = parseInt(year);
  const today = getDateFromUtcTimeZone(8);
  const currentMonth = parseInt(today.getMonth()) + 1;
  const currentYear = parseInt(today.getFullYear());
  const restrictionYear = currentYear + 20;
  return (
    givenMonth > 0 &&
    givenMonth < 13 &&
    (givenYear > currentYear ||
      (givenMonth > currentMonth && givenYear === currentYear)) && (givenYear <= restrictionYear)
  );
};

const getMetadata = (metadataa, key) => {
  return JSON.parse(JSON.stringify(metadataa[key]));
}

const getMaskedCCNum = (str) => {
  if (str !== "") {
    str = new Array(str.length - 3).join("*") + str.substr(str.length - 4, 4);
  }
  return str;
}

function dynamicJoin(fn) {
  return function(array) {
    let result = array[0]
    for (let i = 1; i < array.length; i++) {
      const joiner = fn(array[i - 1], array[i], i, array)
      result += joiner + array[i]
    }
    return result
  }
}

const customJoin = dynamicJoin(function(a, b, i, arr) {
  return (i === arr.length - 1) ? ' ' : ':'
})

const updateScrollAction = (action) => {
  document.getElementById("body").style.overflow = action;
}

const getContactNumber = (contactPointsData) => {
  let PhoneNumber;
  for (let i = 0; i < contactPointsData.length; i++) {
    const data = contactPointsData[i];
    if (data && data.ContactPointType.toUpperCase() === "MOBILE") {
      PhoneNumber = data.PhoneNumber;
    }
  }
  return PhoneNumber
}

const getContactEmail = (contactPointsData) => {
  let EmailAddress;
  for (let i = 0; i < contactPointsData.length; i++) {
    const data = contactPointsData[i];
    if (data && data.ContactPointType.toUpperCase() === "EMAIL") {
      EmailAddress = data.EmailAddress;
    }
  }
  return EmailAddress;
}

export {
  find,
  findById,
  formatModelName,
  formatCapacity,
  getPrice,
  filterDeviceSpecs,
  returnDAXSupportedDevices,
  toPascalCase,
  createKeyValuePair,
  uniqFilter,
  createModelKeyValuePair,
  checkCardType,
  validateFileType,
  formatMobile,
  containsObject,
  covertToUTCDate,
  getFormmatedDate,
  getFormatedTime,
  isEmpty,
  getFileBase64,
  isValidExpiryDate,
  getMetadata,
  getMaskedCCNum,
  updateScrollAction,
  getContactNumber,
  getContactEmail
};
