import React, { useContext, useEffect, useState } from "react";
import { Stack } from "../../stack/stack";
import { WorkflowContext } from "../../workflow/workflow-context";
import { findById, getMetadata, isEmpty } from "../helper";

import { DEVICE_CATEGORY } from "../../../utils/constants";

const SelectDeviceResolution = () => {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['SelectDeviceResolution']
  const homeplusscript = workflowState['config']?.scripts["SelectDeviceResolution"];
  
  const [otherResolution, setOtherResolution] = useState("");
  const [deviceResolutionList, setDeviceResolutionList] = useState([]);
  const [selectedResolution, setSelectedResolution] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");

  let displayTechnologies = findById(metadata, "displayTechnologies");
  let inputOtherTech = findById(metadata, "inputOtherTech");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtDisplayTechInfo = findById(metadata, "txtDisplayTechInfo");
  let titleText = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");
  let buttonsContainer = findById(metadata,"buttonsContainer");

  let regDeviceDetails = workflowState["RegDeviceDetails"];
  const isWebcam = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.Webcam;
  const isMonitor = regDeviceDetails?.selectedCategory?.type === DEVICE_CATEGORY.Monitor;

  const onContinueClick = (resolution) => {
    updateState({
      ...workflowState,
      RegDeviceDetails: {
        // ...regDeviceDetails,
        selectedResolution: resolution?.value || otherResolution?.trim(),
        isOtherResolutionSelected: isEmpty(resolution),
      },
    }, ["RegDeviceDetails"]);

    return completeCurrentStep(currentStep, {
      selectFrameRate: isWebcam,
      selectScreenSize: isMonitor,
      selectConnectivity: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const goToDashboard = () => {
    // clear all device reg data
    updateState({
     ...workflowState,
     RegDeviceDetails: {},
   });
   return completeCurrentStep(currentStep, {
     goToDashboard: true,
   });
 };

  useEffect(() => {
    if (!isEmpty(regDeviceDetails?.selectedBrandDetails))
      showDeviceResolutions(regDeviceDetails.selectedBrandDetails.Resolution);
    // now get distinct list of all brand
    else showDeviceResolutions(regDeviceDetails?.distinctResolutionList);
  }, []);

  const showDeviceResolutions = (deviceResolution) => {
    let resolutionList = [];
    deviceResolution?.forEach((resolution) => {
      if (!isEmpty(resolution)) {
        let deviceBrandResolution = {
          value: resolution,
          label: resolution,
          // using below properties for custom sorting
          unit: resolution.match(/[a-zA-Z]+|[0-9]+/g)[1],
          resolution: resolution.match(/[a-zA-Z]+|[0-9]+/g)[0]
        };
        resolutionList.push(deviceBrandResolution);
      }
    });

    const resolutions = {
      p: 100,
      MP: 200,
    };

    const sortedResolutionList = resolutionList.sort((a, b) =>
      a.resolution - b.resolution
    ).sort((a, b) =>
      resolutions[a.unit] - resolutions[b.unit]
    );

    // add others at last
   !isWebcam && sortedResolutionList.push({ value: "Others", label: "Others" });

    setDeviceResolutionList(sortedResolutionList);
  };

  const onTechnologySelect = (resolution) => {
    hideShowInputOtherTech(resolution);
    setSelectedResolution({ ...selectedResolution, ...resolution });

    if (resolution.value !== "Others") onContinueClick(resolution);
    else setDisplayProp("block")
  };

  const getInfoTitle = () => {
    if (isWebcam) return homeplusscript.webCamInfo;
    else if(isMonitor) return homeplusscript.monitorInfo;
    else return homeplusscript.otherTechInfo.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type)
    }
  const hideShowInputOtherTech = (resolution) => {
    // clear input value
    setOtherResolution("");

    btnContinue.args = {
      ...btnContinue.args,
      displayClasses: resolution.value === "Others" ? "block" : "hidden",
    };

    buttonsContainer.args = {
      ...buttonsContainer.args,
      other: resolution.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
    }
  };

  titleText.args = {
    ...titleText.args,
    content: homeplusscript.title.replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type
    ),
  };

  displayTechnologies.args = {
    ...displayTechnologies.args,
    deviceItems: deviceResolutionList,
    value: selectedResolution?.value,
    onClick: onTechnologySelect,
  };

  inputOtherTech.args = {
    ...inputOtherTech.args,
    label: homeplusscript["labelOtherTech"],
    placeHolder: homeplusscript["labelOtherTech"],
    value: otherResolution,
    other: displayProp,
    onChange: setOtherResolution,
  };

  txtDisplayTechInfo.args = {
    ...txtDisplayTechInfo.args,
    content: getInfoTitle()
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: otherResolution?.trim(),
    displayClasses: selectedResolution?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
    onClose : (e) => goToDashboard()
  }

  buttonsContainer.args = {
    ...buttonsContainer.args
  }

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default SelectDeviceResolution;
