import { Observable } from "apollo-client/util/Observable";
import { Amplify } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

export const configure = (config) => {
  const param = {
    aws_project_region: config.aws_project_region,
    aws_appsync_graphqlEndpoint: config.aws_appsync_graphqlEndpoint,
    aws_appsync_region: config.aws_appsync_region,
    aws_appsync_authenticationType: config.aws_appsync_authenticationType,
    aws_cognito_identity_pool_id: config.aws_cognito_identity_pool_id,
    aws_cognito_region: config.aws_cognito_region,
    aws_user_pools_web_client_id: config.aws_user_pools_web_client_id,

    twilioHost: config.twilioHost,
    twilioTaskQueueSID: config.twilioTaskQueueSID.DP,
  };
  // console.log('aws configure', param);
  Amplify.configure(param);
  API.configure(param);
};

export const RequestStatus = {
  INIT: "init",
  FETCHING: "fetching",
  SUCCESS: "success",
  FAILED: "failed",
};

export const FetchStatus = {
  INIT: "init",
  FETCHING: "fetching",
  COMPLETE: "complete",
};

export const ChatStatus = {
  INITIALIZED: "Initialized",
  CONNECTING: "Connecting",
  CONNECTED: "Connected",
  ENDED: "Ended",
};

export const ChatType = {
  SOLUTO: "SOLUTO",
  DP: "DP",
  SECURITY: "SECURITY"
}

export const EngagementStatusCode = {
  ACCEPTED: "Accepted",
  INITIATED: "Initiated",
  CANCELED: "Canceled",
  INTERACTING: "Interacting",
  AGENT_ENDED: "Agent Ended",
  CUSTOMER_ENDED: "Customer Ended",
  CUSTOMER_ENDED2: "Ended by Customer",
  ABANDONED: "Abandoned",
  ENDED: "Ended", // legacy status code (customer ended)
  TRANSFERRED: "Transferred",
};

// export class ChatMessage {
//   messageId;
//   visitorId;
//   conversationId;
//   messageType;
//   interactionType;
//   sender;
//   source;
//   content;
//   isSent;
//   recipient;
//   isActive;
//   createdAt: Date;
//   sourceMsgId;
//   translated;
//   sourceLang;
//   targetLang;
//   agentResponseTime;
//   userResponseTime;
//   violated;
//   messageStatus;
//   skillId;
// }

// // export type UUID = string;

// export class ChatRequest {
//   requestId: UUID;
//   visitorId: UUID;
//   taskId: UUID;
//   visitorName;
//   interactionId: UUID;
//   customerId;
//   mdn;
//   skillId;
//   languageCode;
//   clientName;
//   requestStatus;
//   chatReason;
//   requestType;
//   engagementType;
//   startTimestamp: Date;
//   requestChannel;
//   speedToAnswer;
//   wrapUpCode;
//   endTimestamp: Date;
//   expertName;
//   userEmail;
//   isTransferred;
//   transferredRequestId;
//   averageResponseTime;
//   engagementDuration;
//   violationCount;
//   chatAcceptTimeStamp: Date;
//   chatWaitTime;
//   expertId;
//   averageUserResponseTime;
//   acwStartTimestamp: Date;
//   rating?;
//   endedBy?;
//   isSurveyRequested;
// }
export class AppSyncHelper {
  static subscribe(query, replace, value) {
    let observable = null;
    let subscription = query;
    subscription = subscription.replace(replace, value);
    //@ts-ignore
    observable = API.graphql(graphqlOperation(subscription));
    return observable;
  }
}
