import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";

const ProvideIssueObservedBy = () => {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideIssueObservedBy']
  const homeplusscript = workflowState['config']?.scripts["ProvideIssueObservedBy"];

  const [observedByName, setObservedByName] = useState("");
  const [observedByRelation, setObservedByRelation] = useState("");
  const [selectedObservedBy, setSelectedObservedBy] = useState({});
  const [displayProp, setDisplayProp] = useState("hidden");
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtObservedByInfo = findById(metadata, "txtIssueObservedByInfo");
  let observedBy = findById(metadata, "observedBy");
  let inputDiscoverName = findById(metadata, "inputDiscoverName");
  let inputDiscoverRelation = findById(metadata, "inputDiscoverRelation");
  let menuLabel = findById(metadata, "menu");
  let buttonsContainer = findById(metadata, "buttonsContainer");

  const observedByList = [
    { value: "Myself, the insured", label: "Myself, the insured" },
    { value: "Others", label: "Others, please describe" },
  ];

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const onContinueClick = (observedBy) => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        selectedObservedBy: observedBy,
        observedByName: observedByName?.trim(),
        observedByRelation: observedByRelation?.trim(),
        isOtherObservedBySelected: isEmpty(observedBy),
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      provideDeviceOwner: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  const onObservedBySelect = (observedBy) => {
    setSelectedObservedBy({
      ...selectedObservedBy,
      ...observedBy,
    });

    const showObservedByOtherBox = observedBy.value === "Others";

    if(showObservedByOtherBox) setDisplayProp("block")
    else setDisplayProp("hidden")

    // inputDiscoverName.args = {
    //   ...inputDiscoverName.args,
    //   other: showObservedByOtherBox ? "hidden" : "hidden",
    // };
    // inputDiscoverRelation.args = {
    //   ...inputDiscoverRelation.args,
    //   other: showObservedByOtherBox ? "block" : "hidden",
    // };

    if (!showObservedByOtherBox) onContinueClick(observedBy);
  };

  menuLabel.args = {
    ...menuLabel.args,
    labelSubHeader: homeplusscript.menuLabelSubHeader,
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  inputDiscoverName.args = {
    ...inputDiscoverName.args,
    label: homeplusscript.inputDiscoverNameLabel,
    placeHolder: homeplusscript.inputDiscoverNamePlaceHolder,
    value: observedByName,
    other: displayProp,
    onChange: setObservedByName,
  };

  inputDiscoverRelation.args = {
    ...inputDiscoverRelation.args,
    label: homeplusscript.inputDiscoverRelationLabel,
    placeHolder: homeplusscript.inputDiscoverRelationPlaceHolder,
    value: observedByRelation,
    other: displayProp,
    onChange: setObservedByRelation,
  };

  observedBy.args = {
    ...observedBy.args,
    label: homeplusscript["observedBy_label"],
    deviceItems: observedByList,
    value: selectedObservedBy?.value,
    onClick: onObservedBySelect,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: observedByName?.trim() && observedByRelation?.trim(),
    // enabled: observedByRelation?.trim(),
    displayClasses: selectedObservedBy?.value === "Others" ? "block" : "hidden",
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtObservedByInfo.args = {
    ...txtObservedByInfo.args,
    content: homeplusscript.labelObservedByInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };
  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }

  buttonsContainer.args = {
    ...buttonsContainer.args,
    other: selectedObservedBy?.value === "Others" ? "flex justify-between lg:justify-center" : "flex justify-center"
  }

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default ProvideIssueObservedBy;
