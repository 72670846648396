import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata, isEmpty } from "../helper";
import { callCancelClaimApi, callSubmitTraigeApi } from "../api-helper";
import logger from "../../../logger.service";

const Triage = () => {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [triageAnswers, setTriageAnswers] = useState([]);
  const [otherAnswer, setOtherAnswer] = useState();
  const [isEnable, setIsEnable] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1024px)").matches);

  const metadata = workflowState['config']?.metadata['Triage']
  const homeplusscript = workflowState['config']?.scripts["Triage"];

  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let quesList = findById(metadata, "quesList");
  let quesListDesktop = findById(metadata, "quesListDesktop");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let btnContinue = findById(metadata, "btnContinue");
  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata, "menu");

  let fileClaimDetails = workflowState["FileClaimDetails"];
  const ServiceRequestResponse = fileClaimDetails?.ServiceRequestResponse;

  useEffect(() => {
    console.log("Screen Size Changed");
    window.matchMedia("(min-width: 1024px)").addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  useEffect(() => {
    // setting answer for other sysmtoms question
    const answerList = triageAnswers.map((ans) => {
      if (ans.QuestionType === "Symptoms_Others") ans.Ans = otherAnswer;

      return ans;
    });

    isAllQuestionAnswered(answerList);
  }, [otherAnswer]);

  useEffect(() => {
    const triageQuestionsList = getMetadata(
      workflowState["config"]?.TriageQuestions,
      fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryName
    );

    let questionList = triageQuestionsList?.Triage;
    questionList.push(...triageQuestionsList?.Symptoms);
    displayTriageQuestions(questionList);
  }, []);

  const displayTriageQuestions = (questionList) => {
    let triageQuestionList = [];
    let triageAnswerList = [];
    questionList.map((item) => {
      let triageQuestion = {
        answers:
          item.QuestionType !== "Symptoms_Others"
            ? [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]
            : [],
        ...item,
      };
      let triageAnswer = {
        Que: item.Question,
        Ans: "",
        key: item.FaultDetection,
        QuestionType: item.QuestionType,
      };
      triageQuestionList.push(triageQuestion);
      triageAnswerList.push(triageAnswer);
    });

    setTriageAnswers(triageAnswerList);

    loadQuestion(triageQuestionList);
    // setCurrentQuestion(triageQuestionList);
  };

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        triageAnswers,
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      provideIssueOccurenceDate: true,
    });
  };

  const isAllQuestionAnswered = (answerList) => {
    const answers = answerList.filter((ans) => {
      return isEmpty(String(ans.Ans)?.trim());
    });

    setIsEnable(answerList.length !== 0 && answers.length === 0);
  };

  // const onGoBackClick = () => {
  //   if (currentQuestionIndex === 0)
  //     return completeCurrentStep(currentStep, {
  //       GoBack: true,
  //     });
  //   else {
  //     setCurrentQuestionIndex(currentQuestionIndex - 1);
  //   }
  // };

  const onGoBackClick = () => {
    if (currentQuestionIndex === 0)
    if(fileClaimDetails.fromMyClaimScreen && !(fileClaimDetails.fromMyClaimScreentoIncidentDetails)){
      updateState({
        ...workflowState,
        FileClaimDetails: {},
      });
      return completeCurrentStep(currentStep, {
        GoBackMyClaim: true,
      });
    }else {
      return completeCurrentStep(currentStep, {
        GoBack: true,
      });
    }
    else {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };


  const loadQuestion = (triageQuestionList) => {
    let triageQues = [];

    for (let i = 0; i < triageQuestionList.length; i += 3) {
      const firstThree = triageQuestionList.slice(i, i + 3);
      triageQues.push(firstThree);
    }
    setCurrentQuestion(triageQues);
  };

  const onAnswerSelect = (triageQuestion, answer) => {
    triageQuestion?.answers.map((item) =>
      item.value === answer.value
        ? (item.isClick = true)
        : (item.isClick = false)
    );

    // setting answer for question
    const answerList = triageAnswers.map((ans) => {
      if (ans.Que === triageQuestion.Question) {
        if (ans.QuestionType === "Symptoms_Others") ans.Ans = answer;
        else ans.Ans = answer.value === "Yes" ? true : false;
      }
      return ans;
    });

    setTriageAnswers(answerList);

    // enable button if all questions are answered
    isAllQuestionAnswered(answerList);

    // for triage type question if answer is Yes then show triage error page
    // if (triageQuestion.QuestionType === "Triage" && answer.value === "Yes") {
    //   // call submit triage api, cancel sr api and show triage error
    //   showLoader(true);
    //   const SubmitTriageParameters = {
    //     SubmitTriageParameters: {
    //       ServiceRequestId:
    //         ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
    //       CustomerCaseId: ServiceRequestResponse?.CustomerCaseId,
    //       Triage: [
    //         {
    //           Que: triageQuestion.Question,
    //           Ans: "Yes",
    //           key: triageQuestion.FaultDetection,
    //           QuestionType: triageQuestion.QuestionType,
    //         },
    //       ],
    //     },
    //   };
    //   callSubmitTraigeApi(
    //     SubmitTriageParameters,
    //     onSubmitTriageApiSuccess,
    //     onApiFailure
    //   );
    // }
  };

  const onSubmitTriageApiSuccess = (reason) => {
    showLoader(false);
    const CancelServiceRequestParams = {
      CancelServiceRequestParams: {
        ServiceRequestId:
          ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
        InteractionLineId: workflowState["CreateInteractionResponse"]?.InteractionLine?.InteractionLineId,
        NoteText: "test",
        Operation: "Cancel",
        Reason: reason,
        SubReason: "REPAIRREJECTED",
        CancelEntities: "ALL",
        RequestedBy: "System",
        ReturnEntity: true,
      },
    };

    callCancelClaimApi(
      ServiceRequestResponse?.ServiceRequest?.ServiceRequestId,
      CancelServiceRequestParams
    );
    return completeCurrentStep(currentStep, {
      showTriageError: true,
    });
  };

  const onApiFailure = (type, error) => {
    showLoader(false);
    logger({ type, error, state: workflowState});
    refreshStateOnLogout(workflowState, "service-unavailable");
    updateState({
      ...workflowState,
      [currentStep]: {
        ...workflowState[currentStep],
      },
    });
  };

  quesList.args = {
    ...quesList.args,
    options: currentQuestion,
    displayClasses: isDesktop ? "hidden" : "block",
    onClick: onAnswerSelect,
    setOtherAnswer : (value) => {setOtherAnswer(value)},
  };
  quesListDesktop.args = {
    ...quesListDesktop.args,
    options: currentQuestion,
    others: isDesktop ? "block" : "hidden",
    onClick: onAnswerSelect,
    setOtherAnswer : (value) => {setOtherAnswer(value)},
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };

  
 btnCancel.args = {
  ...btnCancel.args,
  enabled: true,
  onClick: (e) => setCancelClaimPopup(true)
}

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    onClick: (e) => onContinueClick(),
    enabled: isEnable,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
};

export default Triage;
