import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, isEmpty } from "../enrollment-workflow/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";

const MyDocuments = () => {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const [myDocumentsData, setMyDocumentsData] = useState([]);

  const metadata = workflowState["config"]?.metadata["MyDocuments"];
  const homeplusscript = workflowState["config"]?.scripts["MyDocuments"];

  const myClaimResponse = workflowState["MyClaimResponse"];

  let txtRefNoValue = findById(metadata, "txtRefNoValue");
  let txtRefNoTitle = findById(metadata, "txtRefNoTitle");
  let policyDoc = findById(metadata, "policyDoc");
  let menu = findById(metadata, "menu");

  let formattedContractId =
    workflowState.FindAgreementResponse.Agreements.Agreement[0].ContractId.replace(
      /^(.{3})(.{3})(.*)$/,
      "$1 $2 $3"
    );

  useEffect(() => {
    showLoader(true);
    let contractId =
      workflowState.FindAgreementResponse.Agreements.Agreement[0].ContractId;
    let AgreementId =
      workflowState.FindAgreementResponse.Agreements.Agreement[0].AgreementId;
    API[ActionTypes.GET_INSURED_SCHEDULE_DOCUMENT](contractId, AgreementId)
      .then((response) => {
        showLoader(false);
        if (response?.data?.GetTaskDetailResponse?.Documents)
          setMyDocumentsData(
            getMyDocuments(response?.data?.GetTaskDetailResponse?.Documents)
          );
      })
      .catch((err) => {
        showLoader(false);
        console.log("InsuredScheduleDocError:", err);
      });
  }, []);

  const getMyDocuments = (documents) => {
    let myDocuments = [
      {
        icon: "Folder",
        label: "Policy Documents",
        subData: [
          {
            label: "Coverage Summary",
            documentType: "groupPolicy",
          },
        ],
      },
    ];
    const insuredScheduleDoc = documents.find((doc) => {
      return doc?.SignedUrl && doc?.DocumentDescription === "INSURED SCHEDULE";
    });

    if (insuredScheduleDoc)
      myDocuments[0].subData.push(getDocument(insuredScheduleDoc, true));

    documents.forEach((document) => {
      if (
        document?.DocumentDescription !== "INSURED SCHEDULE" &&
        document?.SignedUrl
      ) {
        // for cancelled claim do not show tax invoice
        if (
          !isEmpty(myClaimResponse) &&
          document.DocumentDescription === "TAX INVOICE" &&
          isCancelledClaim(document?.Filename?.split("_")?.[0])
        )
          return;
        else if (isUniqueClaim(document, myDocuments)) {
          myDocuments.push({
            icon: "Folder",
            label: document?.DocumentPath?.split("/")?.[1],
            subData: [getDocument(document)],
          });
        } else {
          let doc = myDocuments.find(
            (doc) => doc.label === document?.DocumentPath?.split("/")?.[1]
          );

          doc.subData.push(getDocument(document));
        }
      }
    });

    return myDocuments;
  };

  const isUniqueClaim = (document, myDocuments) => {
    const object = myDocuments.find(
      (doc) => doc.label === document?.DocumentPath?.split("/")?.[1]
    );
    return object === undefined;
  };

  const isCancelledClaim = (claimNumber) => {
    // const myCancelledClaims = CustomerCases?.filter(
    //   (claim) => claim?.CustomerCaseStatus === "CNCLCUS" || claim?.CustomerCaseStatus === "CNCLSYS"
    // );

    const claimDetail = Object.entries(myClaimResponse)?.find((claim) => {
      return (
        claim[1].CustomerCaseNumber.toString() === claimNumber &&
        (claim[1].CustomerCaseStatus === "CNCLCUS" ||
          claim[1].CustomerCaseStatus === "CNCLSYS")
      );
    });

    return !isEmpty(claimDetail);
  };

  const getDocument = (docuemnt, isInsuredSchedule = false) => {
    return {
      label: isInsuredSchedule
        ? docuemnt?.DocumentDescription.toLowerCase()
        : docuemnt?.Filename?.replace(".pdf", ""),
      fileDetails: ".pdf / " + docuemnt?.FileSize,
      date: new Date(docuemnt?.ReceivedDate).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      documentType: "InsSchedule",
      url: docuemnt?.SignedUrl,
    };
  };

  const goToHomePage = () => {
    return completeCurrentStep(currentStep, {
      goToHomePage: true,
    });
  };

  const onMenuItemClick = (redirect) => {
    refreshStateOnLogout(workflowState, redirect);
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menu_labelSubHeader"],
    onClose: (e) => goToHomePage(),
    onMenuItemClick: (e) => onMenuItemClick(e),
  };

  txtRefNoTitle.args = {
    ...txtRefNoTitle.args,
    content: homeplusscript.title,
  };

  txtRefNoValue.args = {
    ...txtRefNoValue.args,
    content: formattedContractId,
  };

  policyDoc.args = {
    ...policyDoc.args,
    options: myDocumentsData,
    // options: [
    //   {icon:"Folder",label:"Policy",subData: DocumentData},
    //   {icon:"Folder",label:"Policy2",subData: []},
    // ],
  };

  return <Stack orientation="vertical" metadata={metadata} />;
};
export default MyDocuments;
