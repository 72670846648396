import React from "react";
import PropTypes from "prop-types";
import { Image } from "../image/image";
import { containsObject } from "../enrollment-workflow/helper";
import { Text } from "../text/text";

export const Checkbox = ({
  options,
  name,
  value,
  orientation,
  onSelect,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  displayClasses,
  itemClasses,
  labelClasses,
  checkboxDivClasses,
  hasImage,
  imageName,
  isMultiSelect,
  onItemCheck,
  onItemUnCheck,
  other,
  ...props
}) => {
  const onItemClick = (item, event) => {
    if (isMultiSelect) {
      // now check if item is there already
      if (containsObject(item, value)) {
        onItemUnCheck(item);
      } else onItemCheck(item);
    } else onSelect(item, event);
  };

  return (
    <div
      className={`${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses} ${displayClasses}`}
    >
      {options.map((item) => {
        return (
          <div className={itemClasses} key={item.value}>
            <div className="mt-2">
              <label className={labelClasses}>
                <div className={checkboxDivClasses}>
                  <input
                    type="checkbox"
                    id={item.value}
                    name={name}
                    className="w-5 h-5 rounded accent-primary focus:ring-0"
                    value={item.value}
                    // placeholder={label}
                    // checked={
                    //   (isMultiSelect && containsObject(item, value)) ||
                    //   (!isMultiSelect && value === item.value)
                    //     ? true
                    //     : false
                    // }
                    onChange={(e) => {
                      onItemClick(item, e);
                    }}
                  />
                </div>
                <Text other={other} content={item.label}></Text>
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * Array of Options to display Checkbox options
   */
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Name of the Checkbox Group
   */
  name: PropTypes.string,
  /**
   * Value of the selected Checkbox option
   */
  value: PropTypes.string,
  /**
   * Orientation describes how Checkbox button will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  /**
   * onSelect event handler for on Selection of Option
   */
  onSelect: PropTypes.func,
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  hasImage: PropTypes.bool,
  imageName: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  onItemCheck: PropTypes.func,
  onItemUnCheck: PropTypes.func,
};

Checkbox.defaultProps = {
  options: [],
  type: "options",
  orientation: "vertical",
  hasImage: false,
  isMultiSelect: false,
  onItemCheck: undefined,
  onItemUnCheck: undefined,
  labelClasses: "inline-flex",
  checkboxDivClasses: "w-6 h-6 mt-1 ml-1",
  other:"ml-2"
};
