import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById, getMetadata } from "../enrollment-workflow/helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { ConsoleLogger } from "aws-amplify";

function CarrierContractType(props) {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
  ] = useContext(WorkflowContext);

  const { setOpenPopup, showCampaignPopUp } = props;
  const metadata =
    workflowState["config"]?.metadata["CarrierContractTypePopup"];
  const homeplusscript =
    workflowState["config"]?.scripts["CarrierContractTypePopup"];

  let FindAgreementResponse = workflowState["FindAgreementResponse"];

  const [isExpanded, setIsExpanded] = useState(true);
  const [carrierType, setCarrierType] = useState("");

  let textContent = findById(metadata, "contentText");
  let btnConfirm = findById(metadata, "btnConfirm");
  let radioButtonMobile = findById(metadata, "radioBtnMobile");
  let radioButtonBroadband = findById(metadata, "radioBtnBroadband");
  let radioButtonMobileAndBroadband = findById(
    metadata,
    "radioBtnMobileAndBroadband"
  );

  const carrierTypeChangeHandler = (e) => {
    setCarrierType(e.target.value);
  };

  textContent.args = {
    ...textContent.args,
    content: homeplusscript.contentText,
  };

  const open = (isExpanded) => {
    setOpenPopup(false);
    setIsExpanded(!isExpanded);
  };

  const goToDashboard = () => {
    updateState({
      ...workflowState,
    });
    open(isExpanded);
  };

  const onContinueClick = () => {
    // showLoader(true);
    updateCarrierContractType();
  };

  const updateCarrierContractType = () => {
    showCampaignPopUp();
    const agreement = FindAgreementResponse?.Agreements?.Agreement[0];
    const UpdateCarrierContractTypeParameters = {
      UpdateAgreementContractRequest: {
        AgreementId: agreement?.AgreementId,
        CarrierContractType: carrierType,
        CustomerId: agreement?.Customers?.Customer[0]?.CustomerId,
        ClientChannelId: workflowState["config"]?.ClientChannelId,
        InteractionLineId:
          workflowState["CreateInteractionResponse"]?.InteractionLine
            ?.InteractionLineId,
      },
    };
    API[ActionTypes.UPDATE_CARRIER_TYPE](UpdateCarrierContractTypeParameters)
      .then((data) => {
        showLoader(false);
        const agreement =
          workflowState["FindAgreementResponse"].Agreements.Agreement[0];
        agreement.CarrierContractType = carrierType;
        updateState(
          {
            ...workflowState,
            FindAgreementResponse: {
              Agreements: {
                Agreement: {
                  0: agreement,
                },
              },
            },
          },
          ["FindAgreementResponse"]
        );
      })
      .catch((error) => {
        console.log(`error is ${error}`);
        showLoader(false);
      });
    goToDashboard();
  };

  btnConfirm.args = {
    ...btnConfirm.args,
    label: homeplusscript.btnConfirm,
    enabled: carrierType,
    onClick: (e) => onContinueClick(),
  };

  radioButtonMobile.args = {
    ...radioButtonMobile.args,
    label: homeplusscript.labelMobile,
    value: homeplusscript.labelMobile,
    onChange: carrierTypeChangeHandler,
  };
  radioButtonBroadband.args = {
    ...radioButtonBroadband.args,
    label: homeplusscript.labelBroadband,
    value: homeplusscript.labelBroadband,
    onChange: carrierTypeChangeHandler,
  };
  radioButtonMobileAndBroadband.args = {
    ...radioButtonMobileAndBroadband.args,
    label: homeplusscript.labelMobileBroadband,
    value: homeplusscript.labelMobileBroadband,
    onChange: carrierTypeChangeHandler,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default CarrierContractType;
