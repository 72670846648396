import React from 'react'
import PropTypes from 'prop-types'

export const ToggleSwitch = ({ checked, onChange = () => {}, enabled, ...props }) => {
  return (
    <>
      <p className={`text-sm ${!checked ? 'text-white' : 'text-gray-200'}`}>
        annual
      </p>{' '}
      <div
        className={`mx-1 w-10 bg-purple-600 rounded-full h-6 flex flex-row ${
          checked ? 'justify-end' : 'justify-start'
        } ${enabled ? 'cursor-pointer' : 'cursor-default opacity-50'}`}
        onClick={e => onChange(!checked)}
      >
        <div className='w-4 h-4 rounded-full bg-white m-1' />
      </div>{' '}
      <p className={`text-sm ${checked ? 'text-white' : 'text-gray-200'}`}>
        monthly
      </p>
    </>
  )
}

ToggleSwitch.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

ToggleSwitch.defaultProps = {
  enabled: true,
  checked: true,
  onChange: undefined
}
