/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick ,fill}) => (
  <svg
    onClick={onClick}
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6364 4C16.6364 3.44772 16.1886 3 15.6364 3C15.0841 3 14.6364 3.44772 14.6364 4V4.63636H10.0909V4C10.0909 3.44772 9.64319 3 9.09091 3C8.53862 3 8.09091 3.44772 8.09091 4V4.63636H6.63636C5.18034 4.63636 4 5.8167 4 7.27273V10.5455V18.7273C4 20.1833 5.18034 21.3636 6.63636 21.3636H18.0909C19.5469 21.3636 20.7273 20.1833 20.7273 18.7273V10.5455V7.27273C20.7273 5.8167 19.5469 4.63636 18.0909 4.63636H16.6364V4ZM18.7273 9.54545V7.27273C18.7273 6.92127 18.4424 6.63636 18.0909 6.63636H16.6364V7.27273C16.6364 7.82501 16.1886 8.27273 15.6364 8.27273C15.0841 8.27273 14.6364 7.82501 14.6364 7.27273V6.63636H10.0909V7.27273C10.0909 7.82501 9.64319 8.27273 9.09091 8.27273C8.53862 8.27273 8.09091 7.82501 8.09091 7.27273V6.63636H6.63636C6.28491 6.63636 6 6.92127 6 7.27273V9.54545H18.7273ZM6 11.5455H18.7273V18.7273C18.7273 19.0787 18.4424 19.3636 18.0909 19.3636H6.63636C6.28491 19.3636 6 19.0787 6 18.7273V11.5455Z"
      fill={`${fill}`}
    />
  </svg>
);
icon.defaultProps={
  fill:"#70706E"
}


export default icon;
