import React from "react";
import { Typography, makeStyles, Button, Grid } from "@material-ui/core";
import InfoIconSvg from "../assets/InfoIconSvg";
import { Colors } from "../entities/Colors";

export const InfoBoxWidget = ({ message }) => {
  const classes = InfoBoxStyles();

  return (
    <div className={classes.infoBoxRoot}>
      <Grid container>
        <Grid item xs={1}>
          <InfoIconSvg />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="caption">{message}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const InfoBoxStyles = makeStyles((theme) => ({
  infoBoxRoot: {
    backgroundColor: Colors.LIGHT_BLUE,
    width: "100%",
    padding: "8px 10px",
    textAlign: 'left',

    "& span": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: '22px',
      textAlign: 'left',
      color: Colors.DARK_BLUE_1
    },
  },
}));
