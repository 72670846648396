import * as React from "react";

function CallbackThankYouSvg(props) {
  return (
    <svg
      width="220"
      height="220"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.962 98.4721V121.176C16.962 125.29 20.284 128.612 24.398 128.612H105.82C109.934 128.612 113.256 125.29 113.256 121.176V101.992L123.398 91.3441L24.42 91.0361C20.306 91.0361 16.962 94.3581 16.962 98.4721Z"
        fill="#EDEDED"
      />
      <path
        d="M101.266 104.786L27.368 104.434V102.168L101.266 102.52V104.786Z"
        fill="#C7C7C5"
      />
      <path
        d="M101.266 112.244L27.368 111.914V109.648L101.266 109.978V112.244Z"
        fill="#C7C7C5"
      />
      <path
        d="M72.0377 117.13L72.0266 119.396L101.264 119.539L101.275 117.273L72.0377 117.13Z"
        fill="#E0E0E0"
      />
      <path
        d="M92.4 176.946V199.65C92.4 203.764 95.722 207.086 99.836 207.086H181.258C185.372 207.086 188.694 203.764 188.694 199.65V180.466L198.836 169.818L99.858 169.51C95.744 169.51 92.4 172.832 92.4 176.946Z"
        fill="#EDEDED"
      />
      <path
        d="M177.078 182.666L103.18 182.314V180.048L177.078 180.4V182.666Z"
        fill="#C7C7C5"
      />
      <path
        d="M177.078 190.146L103.18 189.794V187.528L177.078 187.858V190.146Z"
        fill="#C7C7C5"
      />
      <path
        d="M147.823 194.995L147.812 197.261L177.05 197.404L177.061 195.138L147.823 194.995Z"
        fill="#C7C7C5"
      />
      <path
        d="M60.478 12.9141H108.504C110.88 12.9141 112.816 14.8501 112.816 17.2261V40.7221C112.816 43.0981 110.88 45.0341 108.504 45.0341H67.76C65.384 45.0341 63.448 43.0981 63.448 40.7221V15.8841L60.478 12.9141Z"
        fill="#EDEDED"
      />
      <path
        d="M88.33 35.75V50.82C88.33 53.548 86.13 55.748 83.402 55.748H29.37C26.642 55.748 24.442 53.548 24.442 50.82V38.104L17.71 31.042L83.358 30.844C86.086 30.844 88.308 33.044 88.308 35.772L88.33 35.75Z"
        fill="#D9D9D9"
      />
      <path
        d="M32.4059 39.9521L81.4219 39.7101L81.3999 38.2141L32.4059 38.4341V39.9521Z"
        fill="white"
      />
      <path
        d="M32.4059 44.9021L81.4219 44.6821L81.3999 43.1641L32.4059 43.3841V44.9021Z"
        fill="white"
      />
      <path
        d="M51.7808 48.1243L32.399 48.219L32.4064 49.715L51.7881 49.6203L51.7808 48.1243Z"
        fill="white"
      />
      <path
        d="M190.63 105.6V120.67C190.63 123.398 188.43 125.598 185.702 125.598H131.692C128.964 125.598 126.764 123.398 126.764 120.67V107.954L120.032 100.892L185.68 100.694C188.408 100.694 190.63 102.894 190.63 105.622V105.6Z"
        fill="#EDEDED"
      />
      <path
        d="M134.728 109.802L183.722 109.56V108.064L134.706 108.284L134.728 109.802Z"
        fill="#D9D9D9"
      />
      <path
        d="M134.728 114.752L183.722 114.532V113.014L134.706 113.234L134.728 114.752Z"
        fill="#D9D9D9"
      />
      <path
        d="M154.113 117.98L134.731 118.074L134.739 119.57L154.12 119.475L154.113 117.98Z"
        fill="#D9D9D9"
      />
      <path
        d="M66.5946 48.1832L65.9681 186.232C65.9381 192.854 71.2817 198.246 77.9036 198.276L132.969 198.526C139.591 198.556 144.983 193.212 145.013 186.591L145.64 48.5419C145.67 41.9201 140.326 36.5277 133.704 36.4977L78.6388 36.2478C72.017 36.2177 66.6246 41.5614 66.5946 48.1832Z"
        fill="#3D3D3B"
      />
      <path
        d="M133.606 41.2501L122.936 41.2061C121.506 41.2061 120.34 42.4161 120.34 43.9121V45.8481C120.34 47.3441 119.152 48.5541 117.722 48.5541L96.91 48.4661C95.48 48.4661 94.314 47.2341 94.336 45.7381V43.8021C94.336 42.3061 93.192 41.0961 91.762 41.0741H88L78.65 41.0081C74.008 41.0081 70.224 44.7261 70.202 49.3681L69.63 184.118C69.63 188.76 73.348 192.522 77.99 192.544L132.946 192.808C137.588 192.808 141.372 189.09 141.394 184.448L141.966 49.6981C141.966 45.0561 138.248 41.2941 133.606 41.2721V41.2501Z"
        fill="white"
      />
      <path
        d="M102.52 179.916C102.52 180.444 102.014 180.884 101.376 180.884C100.76 180.884 100.254 180.444 100.254 179.894C100.254 179.366 100.76 178.926 101.398 178.926C102.014 178.926 102.52 179.366 102.52 179.916Z"
        fill="#3D3D3B"
      />
      <path
        d="M106.018 179.916C106.018 180.444 105.512 180.884 104.874 180.884C104.258 180.884 103.752 180.444 103.752 179.894C103.752 179.366 104.258 178.926 104.896 178.926C105.534 178.926 106.018 179.366 106.018 179.916Z"
        fill="#3D3D3B"
      />
      <path
        d="M109.538 179.938C109.538 180.466 109.032 180.906 108.394 180.906C107.778 180.906 107.272 180.466 107.272 179.916C107.272 179.388 107.778 178.948 108.416 178.948C109.032 178.948 109.538 179.388 109.538 179.938Z"
        fill="#3D3D3B"
      />
      <path
        d="M193.776 65.208H86.306C83.974 65.208 82.104 67.1 82.104 69.41V102.74C82.104 105.072 83.996 106.942 86.306 106.942H94.314C94.952 110.418 95.722 114.818 95.722 114.818L102.96 106.942H193.776C196.108 106.942 197.978 105.05 197.978 102.74V69.41C197.978 67.078 196.086 65.208 193.776 65.208Z"
        fill="#1ED760"
      />
      <path
        d="M149.424 83.2919C149.424 83.2919 136.642 83.4239 120.846 83.4239C105.05 83.4239 92.268 83.3579 92.268 83.2919C92.268 83.2259 105.05 83.1599 120.846 83.1599C136.642 83.1599 149.424 83.2259 149.424 83.2919Z"
        fill="#F5F5F5"
      />
      <path
        d="M149.424 78.1659C149.424 78.1659 136.642 78.2979 120.846 78.2979C105.05 78.2979 92.268 78.2319 92.268 78.1659C92.268 78.0999 105.05 78.0339 120.846 78.0339C136.642 78.0339 149.424 78.0999 149.424 78.1659Z"
        fill="#F5F5F5"
      />
      <path
        d="M149.424 88.3739C149.424 88.3739 136.642 88.5059 120.846 88.5059C105.05 88.5059 92.268 88.4399 92.268 88.3739C92.268 88.3079 105.05 88.2419 120.846 88.2419C136.642 88.2419 149.424 88.3079 149.424 88.3739Z"
        fill="#F5F5F5"
      />
      <path
        d="M129.712 93.61C129.712 93.61 121.33 93.742 110.99 93.742C100.65 93.742 92.268 93.676 92.268 93.61C92.268 93.544 100.65 93.478 110.99 93.478C121.33 93.478 129.712 93.544 129.712 93.61Z"
        fill="#F5F5F5"
      />
      <path
        d="M145.222 125.158H36.85C35.222 125.158 33.902 126.478 33.902 128.106V163.372C33.902 165 35.222 166.32 36.85 166.32H115.544L122.716 174.108C122.716 174.108 123.464 169.73 124.102 166.32H145.222C146.85 166.32 148.17 165 148.17 163.372V128.106C148.17 126.478 146.85 125.158 145.222 125.158Z"
        fill="#685BC7"
      />
      <path
        d="M140.954 143.352C140.954 143.352 128.172 143.484 112.376 143.484C96.58 143.484 83.798 143.418 83.798 143.352C83.798 143.286 96.58 143.22 112.376 143.22C128.172 143.22 140.954 143.286 140.954 143.352Z"
        fill="#F5F5F5"
      />
      <path
        d="M140.954 138.226C140.954 138.226 128.172 138.358 112.376 138.358C96.58 138.358 83.798 138.292 83.798 138.226C83.798 138.16 96.58 138.094 112.376 138.094C128.172 138.094 140.954 138.16 140.954 138.226Z"
        fill="#F5F5F5"
      />
      <path
        d="M140.954 148.412C140.954 148.412 128.172 148.544 112.376 148.544C96.58 148.544 83.798 148.478 83.798 148.412C83.798 148.346 96.58 148.28 112.376 148.28C128.172 148.28 140.954 148.346 140.954 148.412Z"
        fill="#F5F5F5"
      />
      <path
        d="M121.242 153.67C121.242 153.67 112.86 153.802 102.52 153.802C92.18 153.802 83.798 153.736 83.798 153.67C83.798 153.604 92.18 153.538 102.52 153.538C112.86 153.538 121.242 153.604 121.242 153.67Z"
        fill="#F5F5F5"
      />
      <path
        d="M166.76 51.084C166.826 50.204 166.034 48.994 165.308 48.312C164.582 47.608 164.142 46.684 164.516 45.276C164.802 44.198 165.484 44.264 165.77 43.648C165.968 43.186 165.88 42.636 165.924 42.13C166.056 40.37 167.992 39.006 169.708 39.446C170.192 37.774 171.732 36.476 173.47 36.3C175.186 36.124 176.99 37.07 177.804 38.61C178.794 38.104 180.026 38.126 180.994 38.654C181.962 39.182 182.622 40.238 182.71 41.338C183.678 40.634 185.152 40.92 185.9 41.822C186.648 42.724 186.714 44.11 186.12 45.166C185.548 46.2 184.426 46.882 183.26 47.058C182.094 47.234 180.884 46.97 179.828 46.442C178.772 45.914 177.848 45.144 177.012 44.308"
        fill="black"
      />
      <path
        d="M174.548 105.864C185.945 105.864 195.184 96.8417 195.184 85.7121C195.184 74.5824 185.945 65.5601 174.548 65.5601C163.151 65.5601 153.912 74.5824 153.912 85.7121C153.912 96.8417 163.151 105.864 174.548 105.864Z"
        fill="white"
      />
      <path
        d="M195.272 85.7121L192.984 79.8601L184.8 85.0081L192.104 96.2501C192.104 96.2501 195.074 92.4001 195.272 85.7121Z"
        fill="#EB996E"
      />
      <path
        d="M172.678 73.0402C175.626 73.0402 178.046 70.7522 178.2 67.8482C178.332 65.4282 178.42 63.0962 178.42 63.0962C178.42 63.0962 182.93 62.7662 183.348 58.3662C183.766 53.9662 183.81 43.7802 183.81 43.7802C178.838 41.1622 172.832 41.4262 168.124 44.4622L167.706 44.7482L167.178 67.5402C167.134 70.5762 169.62 73.0622 172.678 73.0622V73.0402Z"
        fill="#FFBE9D"
      />
      <path
        d="M175.252 50.534C175.582 50.644 175.78 51.018 175.67 51.348C175.56 51.678 175.186 51.876 174.856 51.766C174.526 51.656 174.328 51.282 174.438 50.952C174.548 50.622 174.922 50.424 175.252 50.534Z"
        fill="#263238"
      />
      <path
        d="M176.484 50.7761C176.484 50.7761 176 50.4901 175.384 50.4461C174.768 50.4021 174.306 50.6881 174.24 50.6221C174.218 50.5781 174.284 50.4461 174.482 50.3141C174.68 50.1821 175.01 50.0501 175.406 50.0941C175.78 50.1161 176.11 50.2921 176.286 50.4461C176.462 50.6001 176.528 50.7541 176.484 50.7761Z"
        fill="#263238"
      />
      <path
        d="M181.984 50.534C182.314 50.644 182.512 51.018 182.402 51.348C182.292 51.678 181.918 51.876 181.588 51.766C181.258 51.656 181.06 51.282 181.17 50.952C181.28 50.622 181.654 50.424 181.984 50.534Z"
        fill="#263238"
      />
      <path
        d="M183.304 50.7761C183.304 50.7761 182.82 50.4901 182.204 50.4461C181.588 50.4021 181.126 50.6881 181.06 50.6221C181.038 50.5781 181.104 50.4461 181.302 50.3141C181.5 50.1821 181.83 50.0501 182.226 50.0941C182.6 50.1161 182.93 50.2921 183.106 50.4461C183.282 50.6001 183.348 50.7541 183.304 50.7761Z"
        fill="#263238"
      />
      <path
        d="M178.662 55.5501C178.662 55.5501 179.036 55.4621 179.674 55.4181C179.828 55.4181 179.96 55.396 179.982 55.308C180.026 55.22 179.982 55.0441 179.894 54.8461L179.52 53.5701C178.992 51.7661 178.64 50.27 178.728 50.248C178.816 50.226 179.322 51.678 179.85 53.482L180.202 54.758C180.246 54.956 180.334 55.1761 180.246 55.4181C180.202 55.5501 180.048 55.638 179.96 55.66C179.85 55.682 179.762 55.66 179.674 55.66C179.058 55.638 178.662 55.5941 178.662 55.5501Z"
        fill="#263238"
      />
      <path
        d="M178.42 63.0302C178.42 63.0302 175.582 63.0302 172.766 60.9402C172.766 60.9402 173.866 64.2182 178.354 64.1742L178.442 63.0302H178.42Z"
        fill="#EB996E"
      />
      <path
        d="M167.574 51.1941C167.574 50.8421 167.31 50.5341 166.936 50.5121C166.1 50.4681 164.736 50.7761 164.56 53.0421C164.318 56.2761 167.486 55.5281 167.486 55.4401C167.486 55.3741 167.53 52.4481 167.552 51.1941H167.574Z"
        fill="#FFBE9D"
      />
      <path
        d="M166.826 54.1641C166.826 54.1641 166.76 54.2081 166.672 54.2301C166.584 54.2741 166.408 54.2741 166.254 54.2081C165.924 54.0541 165.66 53.4821 165.704 52.9101C165.704 52.6241 165.792 52.3381 165.924 52.1181C166.034 51.8981 166.21 51.7441 166.386 51.7221C166.562 51.7001 166.672 51.8101 166.716 51.8981C166.76 51.9861 166.716 52.0521 166.738 52.0521C166.738 52.0521 166.804 52.0081 166.804 51.8761C166.804 51.8101 166.76 51.7221 166.694 51.6561C166.628 51.5901 166.518 51.5461 166.386 51.5461C166.122 51.5461 165.88 51.7661 165.748 52.0081C165.594 52.2501 165.506 52.5581 165.484 52.8881C165.462 53.5481 165.748 54.1861 166.21 54.3401C166.43 54.4061 166.628 54.3621 166.738 54.2961C166.848 54.2301 166.87 54.1421 166.848 54.1421L166.826 54.1641Z"
        fill="#EB996E"
      />
      <path
        d="M173.58 49.0383C173.734 49.2363 174.306 49.1483 175.032 49.1263C175.758 49.0603 176.33 49.1042 176.462 48.8622C176.616 48.6202 175.912 48.0922 174.966 48.1582C174.02 48.2022 173.382 48.8183 173.58 49.0383Z"
        fill="#263238"
      />
      <path
        d="M180.73 48.8181C180.818 49.1041 181.346 49.1921 181.962 49.1921C182.556 49.1921 183.084 49.0821 183.172 48.7961C183.26 48.5101 182.71 48.1141 181.94 48.1361C181.17 48.1361 180.642 48.5541 180.73 48.8401V48.8181Z"
        fill="#263238"
      />
      <path
        d="M184.426 45.1661C184.426 45.1661 181.764 46.4641 178.288 44.7261C176 43.5821 173.338 43.0321 172.766 43.4941C172.172 43.9561 172.062 46.3101 169.796 46.9921C169.796 46.9921 169.95 51.4801 168.168 51.3261C166.386 51.1721 167.508 44.2201 167.508 44.2201L171.886 42.0861L176.66 41.1841L181.148 41.8221L184.624 42.7681L184.448 45.1881L184.426 45.1661Z"
        fill="black"
      />
      <path
        d="M154.484 71.1262C159.17 66.3082 167.398 66.8362 167.398 66.8362L174.218 71.7202L178.09 67.2102C178.09 67.2102 182.16 67.6502 185.966 69.6522L186.23 69.7402V69.7842C189.222 71.4122 193.446 75.3062 197.494 79.0902L192.654 83.5782L187.308 88.3302C187.308 88.3302 187.308 88.3742 187.308 88.4182C187.242 88.7042 188.056 95.2382 188.87 100.188C184.008 104.83 177.804 106.832 170.896 105.622C168.718 105.248 167.662 104.698 165.616 104.17C162.624 103.378 160.446 100.782 160.182 97.7022C159.808 93.5442 159.544 88.3962 159.544 88.3962"
        fill="#685BC7"
      />
      <path
        d="M182.358 68.0461C182.358 68.0461 182.468 68.3101 182.644 68.8161C182.82 69.3221 183.062 70.0481 183.348 70.9501C183.92 72.7541 184.624 75.2621 185.306 78.0781C185.988 80.8721 186.494 83.4461 186.802 85.3161C186.956 86.2401 187.088 87.0101 187.154 87.5161C187.22 88.0441 187.264 88.3301 187.242 88.3301C187.22 88.3301 187.154 88.0441 187.066 87.5381L186.626 85.3381C186.252 83.4901 185.724 80.9381 185.042 78.1441C184.36 75.3501 183.678 72.8421 183.15 71.0161L182.534 68.8601C182.38 68.3541 182.314 68.0681 182.336 68.0681L182.358 68.0461Z"
        fill="#263238"
      />
      <path
        d="M186.758 85.2501C186.758 85.2501 188.76 83.4681 191.312 81.3781C193.864 79.2881 195.976 77.6381 196.02 77.7041C196.064 77.7481 194.018 79.4861 191.466 81.5761C188.914 83.6661 186.802 85.3161 186.758 85.2501Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M160.666 77.7261L156.486 91.4981L158.048 92.3121L159.588 88.4181C159.588 88.4181 161.172 80.2341 160.688 77.7261H160.666Z"
        fill="black"
      />
      <path
        d="M166.716 67.1442V63.4922C166.716 63.4922 173.844 65.3182 174.086 69.5862C174.086 69.5862 174.988 64.8782 178.662 64.6582L179.19 71.2802L174.922 74.9982H170.588L167.156 72.0502L166.716 67.4522"
        fill="#685BC7"
      />
      <path
        d="M174.548 73.1061L174.174 71.0161C174.086 70.5101 174.042 70.2461 174.064 70.2461C174.064 70.2461 174.152 70.5101 174.284 70.9941C174.416 71.4781 174.57 72.2041 174.746 73.0841C175.098 74.8441 175.472 77.3301 175.714 80.0801C175.934 82.8301 175.978 85.3161 175.912 87.1201C175.89 88.0221 175.846 88.7481 175.802 89.2541C175.758 89.7601 175.736 90.0241 175.714 90.0241C175.714 90.0241 175.714 89.7381 175.714 89.2321C175.714 88.7261 175.714 88.0001 175.736 87.0981C175.736 85.2941 175.692 82.8081 175.45 80.0801"
        fill="#263238"
      />
      <path
        d="M173.778 68.3542C173.778 68.3542 173.624 68.2882 173.36 68.3982C173.096 68.4862 172.854 68.8162 172.656 69.3002C172.216 70.2462 171.688 71.6102 171.292 73.2382L171.248 73.3922L171.094 73.3042C170.676 73.0402 170.236 72.7762 169.796 72.4682C168.938 71.8962 168.168 71.3022 167.486 70.7082C167.134 70.4222 166.87 70.0482 166.694 69.6742C166.54 69.3002 166.474 68.9262 166.452 68.5742C166.43 67.8922 166.54 67.3422 166.606 66.9902C166.694 66.6162 166.738 66.4182 166.76 66.4182C166.76 66.4182 166.76 66.6382 166.694 66.9902C166.65 67.3642 166.562 67.8922 166.606 68.5522C166.628 68.8822 166.694 69.2342 166.848 69.5862C167.002 69.9382 167.266 70.2462 167.596 70.5322C168.278 71.1042 169.048 71.6762 169.884 72.2482C170.324 72.5342 170.742 72.8202 171.182 73.0842L171.006 73.1502C171.402 71.5002 171.974 70.1362 172.458 69.1902C172.568 68.9482 172.7 68.7502 172.832 68.5742C172.964 68.3982 173.14 68.3102 173.294 68.2662C173.602 68.1782 173.756 68.3102 173.734 68.3102L173.778 68.3542Z"
        fill="#263238"
      />
      <path
        d="M178.662 64.6582C178.662 64.6582 178.706 64.8562 178.728 65.2302L178.838 66.7922C178.882 67.4522 178.97 68.2222 179.102 69.0802C179.146 69.4982 179.3 69.9602 179.19 70.4882C179.036 70.9942 178.662 71.3682 178.244 71.6762C177.672 72.0942 177.078 72.4242 176.682 72.8642L176.484 73.0842V72.7982C176.374 71.3022 175.868 70.0482 175.406 69.2562C174.944 68.4422 174.57 68.0242 174.614 68.0022C174.614 68.0022 174.724 68.0902 174.9 68.2882C175.076 68.4862 175.296 68.7722 175.538 69.1902C176.022 69.9822 176.572 71.2582 176.704 72.7982L176.506 72.7322C176.946 72.2482 177.562 71.9182 178.112 71.5002C178.508 71.2142 178.838 70.8622 178.97 70.4442C179.08 70.0262 178.948 69.5642 178.904 69.1462C178.772 68.2882 178.728 67.4962 178.684 66.8362C178.662 66.1762 178.64 65.6482 178.64 65.2742C178.64 64.9002 178.64 64.7022 178.662 64.7022V64.6582Z"
        fill="#263238"
      />
      <path
        d="M201.586 65.5601C201.3 65.9121 190.806 79.9261 190.806 79.9261L192.28 80.7621L203.038 66.9241L201.586 65.5601Z"
        fill="#263238"
      />
      <path
        d="M159.478 76.648C159.478 76.648 170.5 92.532 171.16 92.84C172.458 93.456 187.55 80.41 189.002 79.178C189.2 78.914 189.618 78.364 190.41 77.242C190.41 77.242 191.598 71.786 192.874 72.204C194.15 72.622 192.17 74.976 193.006 77.044C193.842 79.112 197.626 74.184 197.78 73.744L198.748 72.468C198.748 72.468 199.782 75.372 199.694 76.582C199.474 80.476 195.8 85.096 195.8 85.096C195.8 85.096 179.3 105.556 167.992 106.172C164.736 106.348 158.95 97.108 155.078 90.178C152.746 85.998 153.912 80.718 157.784 77.902L159.478 76.67V76.648Z"
        fill="#FFBE9D"
      />
      <path
        d="M197.846 77.2641C197.846 77.2641 198.154 76.6261 198.55 75.8341C198.946 75.0421 199.232 74.3821 199.254 74.4041C199.298 74.4041 199.078 75.1081 198.682 75.9001C198.286 76.6921 197.868 77.2861 197.846 77.2641Z"
        fill="#EB996E"
      />
      <path
        d="M157.608 68.9041C157.608 68.9041 145.772 73.1061 154.572 93.0161L168.234 84.7661L166.298 80.1021L157.608 68.8821V68.9041Z"
        fill="#685BC7"
      />
      <path
        d="M156.706 69.2562C156.706 69.2562 157.212 69.1682 158.136 69.3662C158.576 69.4762 159.126 69.6742 159.698 70.0262C160.27 70.3562 160.842 70.8402 161.414 71.4342C162.558 72.6222 163.636 74.2942 164.626 76.2302C165.638 78.1662 166.562 80.3662 167.53 82.6762L168.366 84.7002L168.41 84.8102L168.322 84.8542C164.362 87.0102 160.93 89.0342 158.488 90.5302C157.278 91.2782 156.31 91.8942 155.628 92.3342L154.858 92.8182C154.682 92.9282 154.594 92.9942 154.594 92.9722C154.594 92.9722 154.682 92.9062 154.836 92.7742C155.012 92.6422 155.254 92.4662 155.584 92.2462C156.244 91.7842 157.19 91.1462 158.4 90.3762C160.82 88.8362 164.252 86.7902 168.19 84.6122L168.146 84.7662L167.31 82.7422C166.342 80.4322 165.418 78.2322 164.45 76.2962C163.482 74.3602 162.426 72.7102 161.304 71.5002C160.754 70.9062 160.182 70.4222 159.632 70.0922C159.082 69.7402 158.554 69.5422 158.136 69.4102C157.256 69.1902 156.728 69.2342 156.728 69.1902L156.706 69.2562Z"
        fill="#263238"
      />
      <path
        d="M182.16 39.732C182.16 39.732 182.182 39.798 182.226 39.93C182.248 40.062 182.27 40.282 182.27 40.546C182.248 41.074 182.05 41.888 181.368 42.614C180.708 43.34 179.454 43.78 178.178 43.428C177.562 43.252 176.924 42.878 176.55 42.24C176.374 41.932 176.242 41.558 176.308 41.162C176.352 40.788 176.55 40.414 176.902 40.172C177.254 39.93 177.694 39.952 178.046 40.106C178.398 40.26 178.684 40.546 178.838 40.898C179.124 41.602 178.904 42.35 178.53 42.878C177.716 43.956 176.22 44.044 175.406 43.56C174.966 43.362 174.592 43.164 174.218 43.098C173.866 43.032 173.536 43.098 173.316 43.186C172.854 43.406 172.656 43.67 172.634 43.626C172.634 43.626 172.678 43.56 172.766 43.472C172.81 43.428 172.876 43.362 172.964 43.296C173.052 43.23 173.14 43.164 173.272 43.098C173.514 42.966 173.866 42.878 174.24 42.944C174.636 43.01 175.032 43.186 175.472 43.362C175.692 43.45 175.912 43.538 176.154 43.582C176.396 43.626 176.66 43.626 176.924 43.582C177.452 43.494 177.98 43.208 178.332 42.724C178.684 42.24 178.838 41.558 178.596 40.964C178.376 40.392 177.584 39.996 177.034 40.37C176.77 40.546 176.594 40.854 176.55 41.184C176.506 41.514 176.594 41.822 176.748 42.108C177.078 42.658 177.65 43.032 178.244 43.186C179.432 43.538 180.598 43.142 181.236 42.482C181.896 41.822 182.116 41.052 182.16 40.524C182.226 39.996 182.138 39.71 182.16 39.71V39.732Z"
        fill="#455A64"
      />
      <path
        d="M179.586 57.7281C179.586 57.7281 179.432 57.8161 179.168 57.8381C178.904 57.8381 178.53 57.7941 178.178 57.6181C177.826 57.4421 177.562 57.1781 177.408 56.9581C177.254 56.7381 177.232 56.5841 177.254 56.5621C177.32 56.5181 177.628 57.0681 178.288 57.3981C178.948 57.7281 179.564 57.6621 179.564 57.7501L179.586 57.7281Z"
        fill="#263238"
      />
      <path
        d="M182.952 41.3381C182.952 41.3381 183.15 41.6461 183.172 42.2181C183.216 42.7681 183.04 43.6041 182.468 44.2861C181.874 44.9461 181.082 45.2321 180.532 45.2761C179.96 45.3201 179.63 45.1881 179.63 45.1661C179.63 45.1221 179.982 45.1661 180.51 45.1001C181.038 45.0121 181.764 44.7261 182.292 44.1101C182.82 43.4941 182.996 42.7461 183.018 42.2181C183.018 41.6901 182.93 41.3601 182.974 41.3381H182.952Z"
        fill="#455A64"
      />
      <path
        d="M59.84 164.736C71.2369 164.736 80.476 155.921 80.476 145.046C80.476 134.172 71.2369 125.356 59.84 125.356C48.443 125.356 39.204 134.172 39.204 145.046C39.204 155.921 48.443 164.736 59.84 164.736Z"
        fill="white"
      />
      <path
        d="M55.638 97.5701C52.36 97.7461 51.084 101.156 50.688 101.926C50.292 102.696 50.226 103.576 50.16 104.434C50.094 105.292 49.984 106.172 49.522 106.898C48.796 108.042 47.278 108.636 46.882 109.934C46.42 111.386 47.608 112.97 47.3 114.466C47.124 115.28 46.53 115.94 46.332 116.732C46.112 117.568 46.332 118.47 46.772 119.218C47.85 121.066 50.072 121.99 52.206 122.078C54.34 122.144 56.43 121.506 58.476 120.89"
        fill="black"
      />
      <path
        d="M70.048 101.816C69.718 102.52 70.048 103.356 70.532 103.928C71.038 104.5 71.72 104.896 72.314 105.38C72.908 105.864 73.458 106.48 73.524 107.25C73.59 107.954 73.238 108.636 73.326 109.34C73.568 111.122 76.406 111.65 76.56 113.454C76.67 114.642 75.438 115.742 75.768 116.908C75.9 117.348 76.23 117.7 76.472 118.074C77.286 119.416 76.736 121.264 75.57 122.298C74.404 123.332 72.776 123.706 71.214 123.772C69.894 123.838 68.398 123.618 67.628 122.562C66.924 121.594 67.056 120.252 67.21 119.064C67.87 113.476 67.76 107.822 67.65 102.212"
        fill="black"
      />
      <path
        d="M56.496 127.688C56.518 124.762 56.496 121.66 56.496 121.66C56.496 121.66 52.712 120.978 51.986 116.05C51.304 111.342 52.47 101.2 52.47 101.2C57.948 99.044 64.086 99.88 67.914 104.28L67.32 128.106C67.254 130.878 64.812 133.012 61.864 132.924C58.872 132.836 56.474 130.482 56.496 127.688Z"
        fill="#FFBE9D"
      />
      <path
        d="M53.504 108.966C53.504 109.296 53.768 109.582 54.098 109.604C54.45 109.626 54.736 109.384 54.758 109.032C54.758 108.702 54.494 108.416 54.164 108.394C53.812 108.372 53.526 108.614 53.504 108.966Z"
        fill="#263238"
      />
      <path
        d="M52.492 108.482C52.492 108.482 53.042 108.218 53.724 108.218C55 108.394 54.978 108.592 54.978 108.482C55.022 108.438 54.934 108.306 54.714 108.13C54.494 107.976 54.142 107.822 53.724 107.8C53.306 107.8 52.932 107.954 52.734 108.108C52.514 108.262 52.448 108.416 52.492 108.46V108.482Z"
        fill="#263238"
      />
      <path
        d="M60.478 109.164C60.478 109.494 60.742 109.78 61.072 109.802C61.424 109.824 61.71 109.582 61.732 109.23C61.732 108.9 61.468 108.614 61.138 108.592C60.786 108.57 60.5 108.812 60.478 109.164Z"
        fill="#263238"
      />
      <path
        d="M59.598 108.724C59.598 108.724 60.17 108.46 60.83 108.46C61.512 108.46 61.996 108.812 62.084 108.724C62.128 108.68 62.04 108.548 61.82 108.372C61.6 108.218 61.248 108.064 60.83 108.042C60.412 108.042 60.038 108.196 59.84 108.35C59.62 108.504 59.554 108.658 59.598 108.702V108.724Z"
        fill="#263238"
      />
      <path
        d="M57.068 113.608C57.068 113.608 56.65 113.498 55.968 113.388C55.792 113.388 55.638 113.322 55.594 113.212C55.55 113.08 55.638 112.904 55.726 112.706C55.902 112.288 56.078 111.848 56.254 111.386C56.98 109.516 57.53 107.976 57.442 107.932C57.376 107.91 56.716 109.406 55.968 111.276C55.792 111.738 55.616 112.178 55.462 112.596C55.396 112.794 55.286 113.014 55.374 113.278C55.418 113.41 55.55 113.498 55.66 113.542C55.77 113.586 55.858 113.586 55.946 113.586C56.628 113.63 57.068 113.63 57.068 113.586V113.608Z"
        fill="#263238"
      />
      <path
        d="M56.496 121.66C56.496 121.66 59.818 121.902 63.118 119.966C63.118 119.966 61.468 123.332 56.54 122.826L56.496 121.66Z"
        fill="#EB996E"
      />
      <path
        d="M57.332 115.236C57.574 114.906 58.014 114.752 58.432 114.796C58.718 114.84 59.026 114.972 59.202 115.214C59.378 115.456 59.422 115.808 59.246 116.028C59.07 116.292 58.674 116.336 58.344 116.248C58.014 116.16 57.75 115.94 57.486 115.72C57.42 115.654 57.332 115.588 57.288 115.522C57.244 115.434 57.244 115.324 57.288 115.258"
        fill="#EB996E"
      />
      <path
        d="M59.048 114.092C58.938 114.092 58.938 114.818 58.278 115.346C57.64 115.874 56.848 115.764 56.848 115.874C56.848 115.918 57.024 116.028 57.354 116.028C57.684 116.028 58.168 115.94 58.542 115.632C58.938 115.324 59.092 114.884 59.136 114.576C59.158 114.268 59.092 114.092 59.026 114.092H59.048Z"
        fill="#263238"
      />
      <path
        d="M65.78 100.738C63.976 103.312 59.202 105.952 55.77 106.568C56.166 106.04 58.278 104.742 58.674 104.214C57.112 105.38 54.912 106.92 52.844 107.03C52.602 107.03 52.338 107.03 52.14 106.92C51.876 106.744 51.81 106.436 51.788 106.15C51.656 104.94 51.744 102.872 51.92 101.684C52.118 100.496 52.668 99.2861 53.702 98.4941C55.022 97.4821 56.914 97.2181 58.696 97.1741C60.192 97.1301 61.732 97.2181 63.096 97.7681C64.46 98.2961 65.626 99.3741 65.824 100.65"
        fill="black"
      />
      <path
        d="M70.972 112.288C71.214 111.012 71.412 109.802 71.456 108.988C71.61 106.436 70.862 97.0421 62.084 97.8121L61.732 100.496C61.732 100.496 60.368 109.648 66.044 109.714C66.044 109.714 66.352 112.442 68.926 115.082C68.926 115.082 70.422 115.038 70.95 112.288"
        fill="black"
      />
      <path
        d="M66.066 109.692C66.594 109.604 67.144 109.626 67.65 109.802C68.156 110 68.596 110.374 68.794 110.88C69.014 111.386 68.97 111.958 68.794 112.464C68.596 112.97 68.266 113.41 67.87 113.784C67.584 114.048 67.276 114.29 66.902 114.4C66.528 114.51 66.088 114.466 65.802 114.224"
        fill="#FFBE9D"
      />
      <path
        d="M66.308 112.882C66.308 112.882 66.484 113.212 66.946 113.102C67.188 113.036 67.408 112.838 67.54 112.596C67.672 112.332 67.782 112.046 67.804 111.716C67.848 111.386 67.76 111.034 67.584 110.792C67.408 110.528 67.122 110.374 66.902 110.374C66.396 110.374 66.242 110.704 66.308 110.704C66.352 110.748 66.55 110.572 66.858 110.66C67.166 110.726 67.474 111.144 67.386 111.694C67.32 112.244 67.1 112.728 66.814 112.86C66.506 113.014 66.286 112.86 66.264 112.904L66.308 112.882Z"
        fill="#EB996E"
      />
      <path
        d="M67.012 99.7701C67.694 100.144 68.508 100.254 69.256 100.056C70.004 99.8581 70.664 99.3521 71.06 98.6921C71.324 98.2741 71.456 97.7461 71.346 97.2621C71.17 96.6021 70.532 96.1621 69.872 95.9861C68.904 95.7441 67.826 95.9861 67.1 96.6461C66.374 97.3281 66.066 98.4281 66.396 99.3521C66.726 100.298 67.694 100.98 68.662 100.914"
        fill="#263238"
      />
      <path
        d="M67.804 114.004C67.32 114.356 66.814 114.708 66.44 115.17C66.066 115.632 65.802 116.226 65.846 116.82C65.868 117.15 66 117.48 65.956 117.81C65.89 118.272 65.516 118.602 65.274 118.998C64.636 120.01 64.9 121.44 65.714 122.32C66.528 123.2 67.76 123.596 68.948 123.64C69.652 123.662 70.378 123.574 70.95 123.156C71.764 122.584 72.094 121.506 72.116 120.494"
        fill="black"
      />
      <path
        d="M69.0359 100.408C69.0359 100.408 68.64 99.7041 67.87 99.1541C67.122 98.5821 66.33 98.4501 66.33 98.3841C66.33 98.3181 67.188 98.3401 68.002 98.9561C68.816 99.5721 69.08 100.408 69.014 100.408H69.0359Z"
        fill="#03F182"
      />
      <path
        opacity="0.3"
        d="M45.518 154.484C45.518 154.484 57.354 165.726 64.394 163.702C71.434 161.656 73.524 156.376 73.524 156.376L73.7 154.484L72.996 154.022L45.892 151.822L45.518 154.484Z"
        fill="black"
      />
      <path
        d="M38.522 143.946L40.81 138.094L48.994 143.242L41.69 154.484C41.69 154.484 38.72 150.634 38.522 143.946Z"
        fill="#EB996E"
      />
      <path
        d="M47.762 127.776C51.568 125.796 56.496 125.202 56.496 125.202C57.926 128.062 62.436 128.436 65.164 126.478L67.474 124.916C67.474 124.916 67.826 124.916 68.442 124.96C73.832 125.444 78.254 129.47 79.332 134.75C79.728 136.686 80.036 138.886 79.904 140.954C79.596 146.168 74.206 146.63 74.206 146.63C74.206 146.63 73.92 151.778 73.568 155.936C73.304 159.038 71.676 161.37 68.86 162.668C66.748 163.636 65.582 164.076 62.854 164.516C55.946 165.616 49.302 162.998 44.946 158.312C45.76 153.362 46.552 146.938 46.486 146.63C46.486 146.564 46.486 146.542 46.486 146.542L41.14 141.79L38.104 139.238C38.104 139.238 44.748 129.36 47.74 127.754"
        fill="#9CDBD9"
      />
      <path
        d="M32.208 123.794C32.494 124.146 42.988 138.16 42.988 138.16L41.514 138.996L30.756 125.158L32.208 123.794Z"
        fill="#263238"
      />
      <path
        d="M73.106 133.804C73.106 133.804 63.272 150.766 62.612 151.074C61.314 151.69 46.222 138.644 44.77 137.412C44.572 137.148 44.154 136.598 43.362 135.476C43.362 135.476 42.174 130.02 40.898 130.438C39.622 130.856 41.602 133.21 40.766 135.278C39.93 137.346 36.146 132.418 35.992 131.978L35.024 130.702C35.024 130.702 33.99 133.606 34.078 134.816C34.298 138.71 37.972 143.33 37.972 143.33C37.972 143.33 52.91 162.052 64.218 162.69C67.474 162.866 73.7 153.494 77.572 146.564C79.904 142.384 78.826 138.138 74.954 135.3L73.106 133.826V133.804Z"
        fill="#FFBE9D"
      />
      <path
        d="M35.948 135.498C35.948 135.498 35.508 134.926 35.112 134.134C34.716 133.342 34.496 132.638 34.54 132.638C34.584 132.638 34.87 133.276 35.244 134.068C35.64 134.86 35.992 135.476 35.948 135.498Z"
        fill="#EB996E"
      />
      <path
        d="M72.644 129.074C72.248 129.316 63.118 146.278 63.118 146.278L74.888 155.496C74.888 155.496 77.946 147.95 79.574 141.834C80.014 140.162 79.794 135.718 79.178 134.002C78.298 131.56 76.978 132.176 74.998 130.746L72.688 129.074H72.644Z"
        fill="#9CDBD9"
      />
    </svg>
  );
}

export default CallbackThankYouSvg;
