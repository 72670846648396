import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";

function ProvideIssueOccurenceDate() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideIssueOccurenceDate']
  const homeplusscript = workflowState['config']?.scripts["ProvideIssueOccurenceDate"];

  const [occurenceDate, setOccurenceDate] = useState("");
  const [cancelClaimPopup, setCancelClaimPopup] = useState(false);

  let btnCancel = findById(metadata, "btnCancel");

  let inputOccurenceDate = findById(metadata, "inputOccurenceDate");
  let btnContinue = findById(metadata, "btnContinue");
  // let btnGoBack = findById(metadata, "btnGoBack");
  let txtTitle = findById(metadata, "txtTitle");
  let txtOccurenecDateInfo = findById(metadata, "txtOccurenecDateInfo");
  let menu = findById(metadata, "menu");

  let fileClaimDetails = workflowState["FileClaimDetails"];

  const onContinueClick = () => {
    updateState({
      ...workflowState,
      FileClaimDetails: {
        // ...fileClaimDetails,
        occurenceDate,
      },
    }, ["FileClaimDetails"]);
    return completeCurrentStep(currentStep, {
      provideIssuePlace: true,
    });
  };

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: true,
    });
  };

  inputOccurenceDate.args = {
    ...inputOccurenceDate.args,
    label: homeplusscript.labelIssueOccurenceDate,
    placeHolder: homeplusscript.labelIssueOccurenceDate,
    value: occurenceDate,
    onChange: setOccurenceDate,
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: occurenceDate,
    onClick: (e) => onContinueClick(),
  };

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript.title,
  };

  txtOccurenecDateInfo.args = {
    ...txtOccurenecDateInfo.args,
    content: homeplusscript.labelIssueOccurenceDateInfo,
  };

  // btnGoBack.args = {
  //   ...btnGoBack.args,
  //   label: homeplusscript.btnGoBack,
  //   onClick: (e) => onGoBackClick(),
  // };
  btnCancel.args = {
    ...btnCancel.args,
    enabled: true,
    onClick: (e) => setCancelClaimPopup(true)
  }

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
    showCancelClaimPopUp: cancelClaimPopup,
    onPopUpClose: setCancelClaimPopup
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvideIssueOccurenceDate;
