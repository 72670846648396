import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "../tooltip/tooltip";

export const Select = ({
  label,
  options,
  value,
  marginClasses,
  paddingClasses,
  borderClasses,
  showTooltip,
  onSelect,
  disabled,
  displayClasses,
  selectDivClasses,
  subDiv,
  placeHolder,
  selectClasses,
  ...props
}) => {
  return (
    <div className={`${selectDivClasses} ${marginClasses} ${paddingClasses} ${displayClasses}`}>
      <div className={`${subDiv}`}>
        {showTooltip ? (
          <div className={`mb-2 flex flex-row`}>
            <Tooltip showLabel={true} labelText={label} />
          </div>
        ) : (
          <label dangerouslySetInnerHTML={{ __html: label }}/>
      )}
    </div>

      <select
        onChange={(e) =>
          onSelect(options.find((option) => option.value === e.target.value))
        }
        value={value}
        disabled={disabled}
        placeholder={placeHolder}
        className={` ${selectClasses} ${borderClasses} ${
          disabled ? "bg-gray-100 cursor-not-allowed" : ""
        } ${value === '' || value === 'Select' ? 'text-neutralN4' : ''}`}
      >
        {options.map((option) => (
          <option className={`${option.value === "Select" ? "text-neutralN4" : "text-neutral"}`} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),

  /**
   * borderClasses: Classes to manage border of the control
   */
  borderClasses: PropTypes.string,

  showTooltip: PropTypes.bool,

  displayClasses: PropTypes.string
};

Select.defaultProps = {
  onSelect: (e) => {},
  borderClasses:
    "border border-neutralN8 focus:outline-none rounded focus:border-primary focus:shadow-sm",
  showTooltip: false,
  selectDivClasses: "flex flex-col",
  placeholder: "",
  selectClasses: "peer bg-transparent peer focus:outline-none h-10 px-2 w-full"
};