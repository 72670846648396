import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import ContactUsIconSvg from './assets/ContactUsIconSvg'
import { Colors } from './entities/Colors'
import { CloseChatBtn } from './widgets/CloseChatBtn'

const thankYouWidgetTexts = {
  thankYouMessage: 'Thank you. Our service support will reach out to you within 2 business days to assist you with your enquiry.',
}

export const ThankYouWidget = ({onCloseThankYouWidget}) => {
  const classes = ThankYouStyles();

  return (
    <Box className={classes.root}>
        <ContactUsIconSvg />
        <p>{thankYouWidgetTexts.thankYouMessage}</p>
        <CloseChatBtn onClick={() => {onCloseThankYouWidget()}} />
    </Box>
  )
}

const ThankYouStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 15px',
        width: '100%',
        // maxWidth: '330px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
        '& p': {
            textAlign: 'center'
        },
        '& button': {
            color: Colors.BLACK,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            width: "160px",
            minWidth: "50px",
            height: "44px",
            marginTop: "15px",
            borderRadius: "4px",
            '&:hover': {
                backgroundColor: '#03F182',
                boxShadow: 'none'
            }
        }
    }
}));