/* eslint-disable max-len */
import React from "react";

const icon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.8625 3.8625L0.75 3.975V18.045L0.8625 18.1575C0.9675 18.27 0.9975 18.27 1.59 18.27H2.205V18.9975H1.9575C1.7475 18.9975 1.6875 19.0125 1.5975 19.11C1.4475 19.2525 1.4475 19.4625 1.5975 19.6125L1.71 19.725H4.53L4.6425 19.6125C4.7925 19.47 4.7925 19.26 4.6425 19.11C4.5525 19.0125 4.485 18.9975 4.2825 18.9975H4.035V18.27H19.9875V18.9975H19.74C19.53 18.9975 19.47 19.0125 19.38 19.11C19.23 19.2525 19.23 19.4625 19.38 19.6125L19.4925 19.725H22.3125L22.425 19.6125C22.575 19.47 22.575 19.26 22.425 19.11C22.335 19.0125 22.2675 18.9975 22.065 18.9975H21.8175V18.27H22.4325C23.025 18.27 23.055 18.27 23.16 18.1575L23.2725 18.045V3.975L23.16 3.8625L23.0475 3.75H0.975L0.8625 3.8625ZM22.5225 10.2825V16.0875H1.4775V4.4775H22.5225V10.2825ZM22.5225 17.175V17.535H1.4775V16.8075H22.5225V17.1675V17.175ZM3.285 18.63V18.99H2.925V18.2625H3.285V18.6225V18.63ZM21.0675 18.63V18.99H20.7075V18.2625H21.0675V18.6225V18.63Z"
      fill="#1ED760"
    />
  </svg>
);

export default icon;
