import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import moment from "moment-timezone";

function ClaimMessage() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
    updateState,
    showLoader,
    refreshStateOnLogout,
  ] = useContext(WorkflowContext);

  const metadata = workflowState["config"]?.metadata["ClaimMessage"];
  const homeplusscript = workflowState["config"]?.scripts["ClaimMessage"];

  let FileClaimDetails = workflowState["FileClaimDetails"];
  let BraintreeDetails = workflowState["BraintreeDetails"]
  const ServiceRequestResponse = FileClaimDetails?.ServiceRequestResponse;
  const CustomerCaseNumber = ServiceRequestResponse?.CustomerCaseNumber;
  const transactionId = FileClaimDetails?.ProcessPaymentResponse?.ProcessPaymentResults?.ChargeOrder?.TransactionId
  const chargeOrderResponse = FileClaimDetails?.ChargeOrderResponse

  let claimSuccessNoteText = findById(metadata, "claimSuccessNote");
  let claimNumberText = findById(metadata, "txtClaimNumber");
  let claimNumber = findById(metadata, "claimNumber");
  let claimTrackNoteText = findById(metadata, "claimTrackNote");
  let menu = findById(metadata, "menu");
  let btnGoBack = findById(metadata, "btnGoBack");
  let claimDate = findById(metadata, "claimDate");
  let claimAmount = findById(metadata, "claimAmount");
  let claimTransactionId = findById(metadata, "claimTransactionId");
  let claimPaymentMethod = findById(metadata, "claimPaymentMethod");
  let paymentReceipt = findById(metadata, "paymentReceipt");

  const goToDashboard = () => {
    // clear all file claim data
    updateState({
      ...workflowState,
      FileClaimDetails: {},
      BraintreeDetails: {},
    });
    return completeCurrentStep(currentStep, {
      goToDashboard: true,
    });
  };

  const onContactUsClick = () => {
    // clear all file claim data
    updateState({
      ...workflowState,
      FileClaimDetails: {},
      BraintreeDetails: {},
    });

    return completeCurrentStep(currentStep, {
      contactUs: true,
    });
  };

  claimSuccessNoteText.args = {
    ...claimSuccessNoteText.args,
    content: homeplusscript.txtTitle,
  };

  claimNumberText.args = {
    ...claimNumberText.args,
    content: homeplusscript.claimNumber,
  };

  claimNumber.args = {
    ...claimNumber.args,
    content: CustomerCaseNumber?.toString(),
  };

  claimTrackNoteText.args = {
    ...claimTrackNoteText.args,
    content: homeplusscript.claimTrackNote,
    onLinkClick: (e) => onContactUsClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => goToDashboard(),
  };

  menu.args = {
    ...menu.args,
    labelSubHeader: homeplusscript["menuLabelSubHeader"],
  };

  paymentReceipt.args = {
    ...paymentReceipt.args,
    displayClasses: transactionId ? "block" : "hidden",
  };

  claimDate.args = {
    ...claimDate.args,
    displayClasses: transactionId ? "block" : "hidden",
    content: homeplusscript.claimDate.replace(
      "{'Date'}",
      moment().format("DD MMMM YYYY")
    ),
  };

  claimAmount.args = {
    ...claimAmount.args,
    displayClasses: transactionId ? "block" : "hidden",
    content: homeplusscript.claimAmount.replace(
      "{'amount'}",
      chargeOrderResponse?.TotalAmount
    ),
  };

  claimTransactionId.args = {
    ...claimTransactionId.args,
    displayClasses: transactionId ? "block" : "hidden",
    content: homeplusscript.claimTransactionId.replace(
      "{'transactionId'}",
      transactionId
    ),
  };

  claimPaymentMethod.args = {
    ...claimPaymentMethod.args,
    displayClasses: transactionId ? "block" : "hidden",
    content: homeplusscript.claimPaymentMethod.replace(
      "{'method'}",
      (chargeOrderResponse?.CardBrand === 'PaypalAccount' || chargeOrderResponse?.PaymentMethodType === 'PYPL') ? "PayPal" : "Credit Card"
    ),
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ClaimMessage;
