/* eslint-disable max-len */
import React from "react";

const icon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="181"
    height="180"
    viewBox="0 0 181 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.789 84.186H46.8689C44.5725 84.186 42.7109 86.0476 42.7109 88.344V164.448C42.7109 166.744 44.5725 168.606 46.8689 168.606H108.789C111.085 168.606 112.947 166.744 112.947 164.448V88.344C112.947 86.0476 111.085 84.186 108.789 84.186Z"
      fill="#B0B0B0"
    />
    <path
      d="M108.771 84.186H66.0389C63.7425 84.186 61.8809 86.0476 61.8809 88.344V164.448C61.8809 166.744 63.7425 168.606 66.0389 168.606H108.771C111.067 168.606 112.929 166.744 112.929 164.448V88.344C112.929 86.0476 111.067 84.186 108.771 84.186Z"
      fill="#D9D9D9"
    />
    <path
      d="M106.97 94.1577H67.856C67.1999 94.1577 66.668 94.6896 66.668 95.3457V108.702C66.668 109.358 67.1999 109.89 67.856 109.89H106.97C107.626 109.89 108.158 109.358 108.158 108.702V95.3457C108.158 94.6896 107.626 94.1577 106.97 94.1577Z"
      fill="#EDEDED"
    />
    <path
      d="M73.7979 30.2578H65.3379V84.1858H73.7979V30.2578Z"
      fill="#D9D9D9"
    />
    <path
      d="M73.7979 55.1338H65.3379V71.4778L73.7979 70.4338V55.1338Z"
      fill="#9C9C9C"
    />
    <path
      d="M47.4638 66.7618L45.1058 32.5078C45.0338 31.3378 45.8978 30.3298 47.0678 30.2578C47.1218 30.2578 47.1578 30.2578 47.2118 30.2578H99.8078C100.924 30.2578 101.842 31.1218 101.914 32.2198L104.272 66.4738C104.362 67.6258 103.48 68.6338 102.328 68.7238C102.274 68.7238 102.22 68.7238 102.166 68.7238H49.5698C48.4538 68.7238 47.5358 67.8598 47.4638 66.7618Z"
      fill="#3D3D3B"
    />
    <path
      d="M99.2126 31.8237H47.9126C47.2466 31.8237 46.7246 32.3637 46.7246 33.0117C46.7246 33.0297 46.7246 33.0657 46.7246 33.0837L48.9926 66.0417C49.0286 66.6717 49.5506 67.1577 50.1806 67.1577H101.481C102.147 67.1577 102.669 66.6357 102.669 65.9697C102.669 65.9337 102.669 65.9157 102.669 65.8797L100.401 32.9217C100.347 32.2917 99.8426 31.8057 99.2126 31.8237Z"
      fill="#1ED760"
    />
    <path
      d="M99.2126 31.8237H47.9126C47.2466 31.8237 46.7246 32.3637 46.7246 33.0117C46.7246 33.0297 46.7246 33.0657 46.7246 33.0837L48.9926 66.0417C49.0286 66.6717 49.5506 67.1577 50.1806 67.1577H101.481C102.147 67.1577 102.669 66.6357 102.669 65.9697C102.669 65.9337 102.669 65.9157 102.669 65.8797L100.401 32.9217C100.347 32.2917 99.8426 31.8057 99.2126 31.8237Z"
      fill="white"
    />
    <path
      d="M99.7172 83.0337H54.6992V84.1857H99.7172V83.0337Z"
      fill="#3D3D3B"
    />
    <path
      d="M87.1898 63.3056H53.3138L51.4238 36.0176H85.3178L87.1898 63.3056Z"
      fill="#1ED760"
    />
    <path
      d="M96.872 38.4656H87.746L87.584 36.0176H96.71L96.872 38.4656Z"
      fill="#1ED760"
    />
    <path
      d="M97.5573 48.2937H88.4313L87.9453 41.3457H97.0713L97.5573 48.2937Z"
      fill="#1ED760"
    />
    <path
      d="M114.549 66.4921L115.485 65.2861C115.845 64.8361 116.385 64.5841 116.961 64.6201L119.625 64.7461C120.687 64.8001 121.677 65.3581 122.253 66.2581L122.613 66.8161C121.173 68.9041 118.113 70.1101 118.113 70.1101L117.285 70.5601C116.583 70.9561 115.683 70.7041 115.287 70.0021L114.387 68.3281C114.081 67.7341 114.153 67.0141 114.567 66.4921H114.549Z"
      fill="#FF8B7B"
    />
    <path
      d="M123.854 42.5159C124.124 42.8579 124.34 43.0919 124.538 43.3799C124.736 43.6679 124.934 43.9379 125.132 44.2079C125.528 44.7659 125.888 45.3239 126.248 45.8999C126.95 47.0519 127.598 48.2399 128.174 49.4459C128.48 50.0579 128.75 50.6699 129.02 51.2999C129.29 51.9299 129.524 52.5599 129.74 53.2259C130.208 54.5399 130.568 55.8719 130.856 57.2219L130.91 57.4379C130.946 57.5999 130.964 57.7619 130.964 57.9239C130.964 58.7879 130.802 59.6339 130.478 60.4259C130.208 61.0919 129.866 61.7219 129.452 62.2979C128.75 63.2519 127.904 64.0979 126.968 64.8179C126.122 65.4659 125.222 66.0419 124.286 66.5459C123.368 67.0319 122.396 67.4459 121.406 67.7879C120.452 68.1119 119.426 67.6079 119.102 66.6539C118.868 65.9879 119.048 65.2679 119.552 64.7819L119.678 64.6739C120.272 64.0799 120.956 63.4499 121.55 62.8199C122.144 62.2259 122.684 61.5779 123.206 60.9299C123.656 60.3719 124.034 59.7779 124.34 59.1299C124.574 58.5899 124.556 58.1759 124.484 58.1759L124.592 58.8599C124.232 57.8159 123.854 56.7899 123.386 55.7639C122.918 54.7379 122.432 53.7299 121.874 52.7399C121.316 51.7499 120.74 50.7599 120.128 49.8059C119.822 49.3199 119.498 48.8519 119.174 48.3659L118.688 47.6819L118.238 47.0519L118.13 46.8899C117.014 45.2519 117.428 43.0019 119.066 41.8859C120.596 40.8419 122.666 41.1299 123.872 42.5519H123.926L123.854 42.5159Z"
      fill="#FF8B7B"
    />
    <path
      d="M117.267 38.4297C115.917 41.5077 115.989 45.3417 120.147 51.2277C122.901 51.5337 128.013 47.9517 128.013 47.9517C128.013 47.9517 124.503 38.1237 117.267 38.4477V38.4297Z"
      fill="#8CBDFA"
    />
    <path
      d="M114.944 27.7377C114.134 30.7257 113.144 36.1797 115.088 38.1597C115.088 38.1597 112.262 37.7277 106.268 41.4357C104.54 38.9517 105.818 38.1597 105.818 38.1597C109.184 37.3677 109.292 34.9017 108.896 32.5797L114.944 27.7197V27.7377Z"
      fill="#FF8B7B"
    />
    <path
      d="M102.76 40.3919C103.642 38.4659 105.748 36.6299 107.188 36.9179C108.628 37.2059 109.51 37.6379 110.374 36.9179C111.238 36.1979 113.344 35.1359 114.424 36.3239C115.936 37.9979 118.24 36.8099 117.358 38.6279C116.422 40.6259 102.76 40.3739 102.76 40.3739V40.3919Z"
      fill="#685BC7"
    />
    <path
      d="M104.128 22.266C104.074 22.68 103.804 22.986 103.552 22.968C103.3 22.95 103.12 22.608 103.192 22.194C103.264 21.78 103.516 21.474 103.768 21.492C104.02 21.51 104.2 21.87 104.128 22.266Z"
      fill="#263238"
    />
    <path
      d="M103.857 21.528L102.939 21.186C102.939 21.186 103.317 21.978 103.857 21.528Z"
      fill="#263238"
    />
    <path
      d="M104.036 23.04C103.55 23.94 102.938 24.786 102.236 25.542C102.686 26.082 103.622 25.92 103.622 25.92L104.036 23.058V23.04Z"
      fill="#FF5652"
    />
    <path
      d="M103.442 19.0261C103.442 19.0261 103.406 18.9181 103.406 18.8461C103.442 18.7201 103.586 18.6481 103.712 18.6841C104.486 18.8461 105.116 19.3681 105.44 20.0881C105.494 20.1961 105.44 20.3221 105.332 20.3581H105.296C105.17 20.4121 105.026 20.3581 104.99 20.2141C104.72 19.6561 104.216 19.2421 103.604 19.1161C103.532 19.0981 103.478 19.0621 103.442 19.0081V19.0261Z"
      fill="#263238"
    />
    <path
      d="M117.501 21.7619C116.601 26.5679 116.313 28.6199 113.577 30.9239C109.455 34.3979 103.767 32.5619 103.227 27.6479C102.741 23.2379 104.307 16.1099 109.257 14.5259C112.497 13.3379 116.079 15.0119 117.267 18.2519C117.681 19.3679 117.753 20.5919 117.501 21.7619Z"
      fill="#FF8B7B"
    />
    <path
      d="M106.719 23.3098C104.973 20.5918 103.101 17.1718 104.271 14.8138C105.441 12.4558 108.141 13.0138 108.141 13.0138C108.141 13.0138 113.343 10.0978 118.005 13.8958C124.179 18.9538 117.555 24.5338 115.917 29.3578C114.099 34.6678 104.757 28.2418 106.701 23.3098H106.719Z"
      fill="black"
    />
    <path
      d="M133.359 14.4719C130.839 5.12987 119.823 9.41387 116.619 14.9579L117.933 15.2999C131.091 6.80387 124.611 19.0799 124.719 24.0839C124.827 29.0879 129.795 32.1659 129.795 32.1659C129.795 32.1659 135.879 23.8139 133.377 14.4719H133.359Z"
      fill="black"
    />
    <path
      d="M130.03 31.0138C128.626 31.0138 126.664 30.7438 125.386 29.4478C124.234 28.2778 123.874 26.4958 124.324 24.1558C124.468 23.4178 124.702 22.5538 124.936 21.6358C125.692 18.7558 126.646 15.2098 125.152 13.9498C124.108 13.0678 121.894 13.3738 118.582 14.8678C118.528 14.9038 118.456 14.8678 118.42 14.8138C118.42 14.8138 118.42 14.6698 118.474 14.6518C121.912 13.0858 124.144 12.7978 125.278 13.7698C126.88 15.1198 125.962 18.6118 125.152 21.7078C124.9 22.6258 124.666 23.4898 124.54 24.2278C124.108 26.4958 124.45 28.2058 125.548 29.3218C127.6 31.3918 131.65 30.6898 131.686 30.6718C131.758 30.6718 131.812 30.7078 131.83 30.7798C131.83 30.8518 131.794 30.9058 131.722 30.9238C131.164 31.0138 130.588 31.0498 130.012 31.0318L130.03 31.0138Z"
      fill="#263238"
    />
    <path
      d="M118.202 12.9061C118.328 12.2941 116.78 12.5461 116.708 13.0861C116.636 13.6261 116.438 13.3201 115.826 14.1121C115.214 14.9041 115.142 15.8401 117.122 16.4521C118.364 16.8481 118.562 15.8761 119.21 15.4081C119.552 15.1561 119.948 15.0121 120.218 14.8141C120.398 14.6701 120.416 14.4361 120.29 14.2561C120.218 14.1661 120.128 14.1121 120.02 14.1121C119.21 14.0401 118.094 13.5001 118.22 12.9061H118.202Z"
      fill="#1ED760"
    />
    <path
      d="M107.6 25.0199C107.168 25.8659 106.394 26.4779 105.476 26.7299C104.342 26.9819 103.91 25.6859 104.342 24.4079C104.72 23.2739 105.782 21.7619 106.862 22.1039C107.942 22.4459 108.212 23.9399 107.6 25.0379V25.0199Z"
      fill="#FF8B7B"
    />
    <path
      d="M128.175 170.982C131.919 170.424 131.991 169.812 133.989 169.11C135.699 168.498 136.185 168.588 137.571 168.12C138.723 167.724 138.327 166.626 137.571 165.456C137.193 164.862 137.139 164.304 136.707 163.26L131.037 164.106C130.497 165.69 130.299 167.724 127.059 168.876C125.133 169.56 126.681 171.198 128.175 170.982Z"
      fill="black"
    />
    <path
      d="M93.5615 171C97.2695 171 97.9895 170.748 100.077 170.676C101.859 170.622 102.165 171 103.965 171C105.171 171 105.333 169.65 104.901 168.624C104.559 167.832 104.505 166.896 104.253 165.87H99.1415C98.2235 167.49 95.7035 168.696 92.8955 168.894C90.8795 169.038 92.0675 171 93.5615 171Z"
      fill="black"
    />
    <path
      d="M97.8983 69.7681C97.8983 69.7681 97.1603 96.4801 98.7443 113.454C98.6363 129.384 99.1403 165.852 99.1403 165.852C99.2663 166.662 103.532 166.338 104.252 165.852C104.252 165.852 112.262 139.284 108.698 113.454C117.59 86.5081 115.664 69.7681 115.664 69.7681H97.8803H97.8983Z"
      fill="#250E62"
    />
    <path
      d="M103.227 69.768C103.227 69.768 111.903 99.918 115.431 114.732C119.139 130.284 130.137 153.792 131.037 164.106C131.991 164.484 135.069 164.07 136.707 163.26C136.707 163.26 135.195 130.392 126.267 115.416C128.283 78.57 118.293 69.75 118.293 69.75H103.227V69.768Z"
      fill="#250E62"
    />
    <path
      d="M130.299 162.036C130.389 162.738 130.425 163.458 130.407 164.16C130.389 164.412 130.569 164.628 130.821 164.646C130.821 164.646 130.839 164.646 130.857 164.646C132.981 164.646 135.915 163.746 136.851 163.422C137.031 163.368 137.139 163.206 137.157 163.008V160.992C137.157 160.74 136.959 160.542 136.707 160.542C136.689 160.542 136.653 160.542 136.635 160.542L130.659 161.55C130.443 161.586 130.299 161.802 130.317 162.018L130.299 162.036Z"
      fill="#1ED760"
    />
    <path
      d="M98.5475 163.62C98.5475 164.178 98.4935 165.15 98.4395 165.888C98.4395 166.122 98.6195 166.338 98.8535 166.374C100.725 166.536 102.633 166.5 104.505 166.32C104.685 166.32 104.847 166.158 104.883 165.978L105.261 163.998C105.297 163.728 105.117 163.494 104.847 163.458H104.829L98.9975 163.224C98.7635 163.224 98.5655 163.386 98.5655 163.62C98.5655 163.62 98.5655 163.62 98.5655 163.638L98.5475 163.62Z"
      fill="#1ED760"
    />
    <path
      d="M96.7473 45.7556C96.6393 48.8696 96.6753 51.9836 96.8733 55.0796C97.0353 59.1296 97.3053 63.1256 97.8993 69.7676H118.293C117.879 64.0256 118.545 57.4736 122.217 44.5496C123.027 42.0476 121.641 39.3656 119.139 38.5736C118.815 38.4656 118.491 38.4116 118.149 38.3756C117.339 38.2856 116.475 38.2316 115.593 38.1776C112.515 37.9616 109.419 37.9616 106.341 38.1776C105.333 38.2676 104.289 38.3936 103.299 38.5376C99.6633 39.0776 96.9273 42.1016 96.7473 45.7556Z"
      fill="#8CBDFA"
    />
    <path
      d="M118.185 67.248C114.099 68.292 107.889 68.598 97.7006 67.5C97.9526 72.9 95.5586 109.278 95.5586 109.278C95.5586 109.278 116.223 113.814 127.923 107.658C128.679 80.172 118.185 67.248 118.185 67.248Z"
      fill="#685BC7"
    />
    <path
      opacity="0.2"
      d="M110.391 89.1717C110.391 89.1717 116.007 83.9517 118.779 80.3877C118.563 84.1317 115.215 87.4797 110.391 89.1717Z"
      fill="black"
    />
    <path
      d="M79.4311 45.7201L80.7811 46.4041C81.3031 46.6561 81.6631 47.1601 81.7351 47.7181L82.1311 50.3641C82.2931 51.4261 81.9331 52.5061 81.1771 53.2441L80.6911 53.7301C78.3691 52.7401 76.5691 49.9501 76.5691 49.9501L75.9751 49.2121C75.4351 48.6001 75.5071 47.6641 76.1191 47.1421H76.1371L77.5771 45.9181C78.0991 45.4861 78.8011 45.4141 79.3951 45.7021L79.4311 45.7201Z"
      fill="#FF8B7B"
    />
    <path
      d="M103.784 46.2058C103.64 46.6198 103.532 46.9078 103.388 47.2318C103.244 47.5558 103.118 47.8618 102.992 48.1858C102.722 48.8158 102.416 49.4098 102.128 50.0038C101.516 51.2098 100.832 52.3798 100.094 53.4958C99.7343 54.0718 99.3383 54.6118 98.9423 55.1698C98.5463 55.7278 98.1323 56.2678 97.6823 56.7898C96.8003 57.8698 95.8463 58.8778 94.8383 59.8138L94.7123 59.9398C94.5863 60.0478 94.4603 60.1558 94.3163 60.2458C93.7223 60.6058 93.0743 60.8578 92.3903 60.9838C91.8143 61.0918 91.2383 61.1098 90.6623 61.0558C89.7803 60.9478 88.9343 60.7138 88.1423 60.3538C86.9003 59.7778 85.7303 59.0398 84.6683 58.1578C83.6963 57.3658 82.7963 56.5198 81.9503 55.6018C81.1043 54.7198 80.3303 53.7658 79.6103 52.7758C79.0163 51.9658 79.1963 50.8318 80.0063 50.2558C80.6183 49.8058 81.4283 49.7878 82.0583 50.2018L82.1483 50.2558C83.0483 50.8318 83.9843 51.4798 84.9203 52.0378C85.8563 52.5958 86.7743 53.1538 87.6923 53.6218C88.5023 54.0718 89.3663 54.4138 90.2663 54.6658C90.5183 54.7558 90.8063 54.7738 91.0763 54.7378C91.1303 54.7378 91.1303 54.6838 91.0763 54.6838C90.9683 54.6838 90.8423 54.7198 90.7523 54.7738L90.2303 55.2238C90.9323 54.3778 91.6163 53.5138 92.2463 52.5778C92.8763 51.6418 93.4883 50.7058 94.0463 49.6978C94.6043 48.6898 95.1623 47.6998 95.6663 46.6738C95.9183 46.1698 96.1523 45.6478 96.4043 45.1258L96.7463 44.3518L97.0523 43.6498L97.1423 43.4698C97.9523 41.6518 100.076 40.8238 101.894 41.6338C103.604 42.3898 104.45 44.3518 103.838 46.1158L103.784 46.2058Z"
      fill="#FF8B7B"
    />
    <path
      d="M103.856 38.4478C100.526 38.8798 97.2862 40.9498 94.4062 47.5558C95.5762 50.0758 101.282 52.5958 101.282 52.5958C101.282 52.5958 107.87 44.4778 103.838 38.4658L103.856 38.4478Z"
      fill="#8CBDFA"
    />
  </svg>
);

export default icon;
