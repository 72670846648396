import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { OptionPage } from "../options/option-pages";
import { Text } from "../text/text";
import { List } from "../list/list";
import Icon from "../icon/icon";

const Stepper = ({ activeIndex, onSelect, options, onClick }) => {
  return (
    <>
      <ol className="relative flex flex-row list-none">
        {options.map((dot, index) => (
          <li
            key={index}
            onClick={() => onSelect(index)}
            className={`inline-block rounded-full mr-1 ${
              activeIndex === index
                ? "active bg-primary w-[30px] h-2"
                : "bg-neutralN8 w-2 h-2"
            }`}
          />
        ))}
      </ol>
    </>
  );
};

export const Carousel = ({
  itemType,
  options,
  onClick,
  onOptionSelect,
  activeOptionIndex,
  setOtherAnswer,
  mainDivClasses,
  swipeClasses,
  displayClasses
}) => {
  let [activeStep, setActiveStep] = useState(0);

  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1024px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));
  }, []);

  const getClassName = (options, index) => {
    if(options?.length === 1)
      return 'lg:mx-auto lg:min-w-fit'
    else if(index === 0)
      return 'lg:ml-auto lg:min-w-fit'
    else if(index === options?.length - 1)
      return "lg:mr-auto lg:min-w-fit"
    else
      return "min-w-fit";

  }

  const carouselItem = options?.map((item, index) => (
    <div className={itemType === "optionContent" ? getClassName(options, index) : '' }>
      {itemType === "optionContent" && (
        <OptionPage isDesktop={isDesktop} option={item} onClick={onOptionSelect} />
      )}
      {itemType === "TriageQuestions" && (
        <List
          options={item}
          itemType={"TriageQuestion"}
          onClick={onClick}
          setOtherAnswer={setOtherAnswer}
        />
      )}
    </div>
  ));

  const scroll = (direction) => {
    if(direction === 'left')
      document.getElementById('options').scrollLeft-=300
    else if(direction === 'right')
      document.getElementById('options').scrollLeft+=300
  }

  return (
    <>
      {isDesktop && itemType === "optionContent" ? (
        <div className="relative">
          <div id="options" className={"flex flex-row gap-4 overflow-x-auto mx-5"}>{carouselItem}</div>
            <Icon
              className={`fill-primary absolute left-0 top-1/2 rotate-90 cursor-pointer ${options?.length > 2 ? "block" : "hidden"}`}
              icon="IconExpand" fill={"primary"}
              onClick={(e) => scroll('left')}
            ></Icon>
            <Icon
              className={`fill-primary absolute right-0 top-1/2 rotate-90 cursor-pointer ${options?.length > 2 ? "block" : "hidden"}`}
              icon="IconCollapse" fill={"primary"}
              onClick={(e) => scroll('right')}
            ></Icon>
        </div>
      ) : (
        <div className={`${mainDivClasses} ${displayClasses}`}>
          <SwipeableViews
            className={swipeClasses}
            index={activeStep}
            onChangeIndex={setActiveStep}
            enableMouseEvents
            autoPlay={true}
          >
            {carouselItem}
          </SwipeableViews>
          <Stepper
            options={options}
            activeIndex={activeStep}
            onSelect={setActiveStep}
          />
        </div>
      )}
    </>
  );
};

Carousel.propTypes = {};

Carousel.defaultProps = {
  mainDivClasses: "flex flex-col items-center justify-center overflow-x-hidden",
  swipeClasses: "w-full lg:w-auto"
};
