import { useContext, useState } from "react";
import { WorkflowContext } from "../../workflow/workflow-context";
import { Stack } from "../../stack/stack";
import { findById, getMetadata } from "../helper";
import { DEVICE_CATEGORY } from "../../../utils/constants";
import API from "../../../services";
import ActionTypes from "../../../ActionTypes";
import logger from "../../../logger.service";

function ProvideDeviceSerialNumber() {
  const [currentStep, completeCurrentStep, workflowState, updateState, showLoader, refreshStateOnLogout] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['ProvideDeviceSerialNumber']
  const homeplusscript = workflowState['config']?.scripts["ProvideDeviceSerialNumber"];

  const [serialNumber, setSerialNumber] = useState("");

  let inputSerialNumber = findById(metadata, "inputSerialNumber");
  let btnContinue = findById(metadata, "btnContinue");
  let btnGoBack = findById(metadata, "btnGoBack");
  let txtSerialNumberInfo = findById(metadata, "txtSerialNumberInfo");
  let txtTitle = findById(metadata, "txtTitle");
  let menu = findById(metadata,"menu");

  let regDeviceDetails = workflowState["RegDeviceDetails"];

  const selectedCategory = regDeviceDetails?.selectedCategory?.type;
  const isLaptop = selectedCategory === DEVICE_CATEGORY.Laptop;
  const isDesktop = selectedCategory === DEVICE_CATEGORY.Desktop;
  const isTablet = selectedCategory === DEVICE_CATEGORY.Tablet;

  const askImeiDetails = isTablet && regDeviceDetails?.selectedConnectivity === "Wifi + Cellular";

  const getInfoTitle = () => {
    if (isDesktop) return homeplusscript.desktopSerialNumberInfo;
    else if (isLaptop) return homeplusscript.laptopSerialNumberInfo;
    else if (askImeiDetails) return homeplusscript.tabletImeiNumberInfo;
    else if (isTablet) return homeplusscript.tabletSerialNumberInfo;
    else return homeplusscript.laptopSerialNumberInfo;
  };

  const onContinueClick = () => {

    checkSerialNumberExistence(serialNumber?.trim());
    
    return completeCurrentStep(currentStep, {
      selectProcessor: isLaptop || isDesktop,
      selectStorageCapacity: isTablet
    });
  };

  const checkSerialNumberExistence = (serialNumber) => {
    API[ActionTypes.GET_SERIAL_NUMBER_EXISTENCE](serialNumber)
    .then((data) => {
        let doesSerialNumberExist = data.data.VerifyImeiResponse.IsImeiExist;

        updateState({
          ...workflowState,
          RegDeviceDetails: {
            // ...regDeviceDetails,
            serialNumber,
            askImeiDetails,
            IsImeiExist: doesSerialNumberExist
          },
        }, ["RegDeviceDetails"]);

      })
    .catch((error)=>{
      console.log(`error is ${error}`);
      logger({ type: ActionTypes.GET_SERIAL_NUMBER_EXISTENCE, error, state: workflowState})
      refreshStateOnLogout(workflowState, "service-unavailable");
      updateState({
        ...workflowState,
        [currentStep]: {
          ...workflowState[currentStep],
        },
      });
    })
  }

  const onGoBackClick = () => {
    return completeCurrentStep(currentStep, {
      GoBack: !isTablet,
      GoBackToConnectivity: isTablet,
    });
  }

  const onSerialNumberChange = (number) => {
    
    if(askImeiDetails){
      const re = /^[0-9\b]+$/;
      if(number === '' || re.test(number))
        setSerialNumber(number)
    }
    else
      setSerialNumber(number)
  }

  txtTitle.args = {
    ...txtTitle.args,
    content: homeplusscript[askImeiDetails ? "titleImei" : "title"].replace(
      "{'deviceCategory'}",
      regDeviceDetails?.selectedCategory?.type,
    ),
  };

  inputSerialNumber.args = {
    ...inputSerialNumber.args,
    label: askImeiDetails ? homeplusscript.labelImeiNumber : homeplusscript.labelSerialNumber,
    placeHolder: askImeiDetails ? homeplusscript.placeHolderImeiNumber : homeplusscript.placeHolderSerialNumber,
    value: serialNumber,
    onChange: onSerialNumberChange,
    maxlength: askImeiDetails ? 15 : 30,
    inputMode: askImeiDetails ? "numeric" : "text"
  };

  txtSerialNumberInfo.args = {
    ...txtSerialNumberInfo.args,
    title: askImeiDetails ? homeplusscript.labelImeiNoInfo : homeplusscript.labelSerialNoInfo,
    content: getInfoTitle(),
  };

  btnContinue.args = {
    ...btnContinue.args,
    label: homeplusscript.btnContinue,
    enabled: serialNumber?.trim(),
    onClick: (e) => onContinueClick(),
  };

  btnGoBack.args = {
    ...btnGoBack.args,
    label: homeplusscript.btnGoBack,
    onClick: (e) => onGoBackClick(),
  }

  menu.args = {
    ...menu.args,
    labelSubHeader : homeplusscript["menuLabelSubHeader"],
  }

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default ProvideDeviceSerialNumber;
