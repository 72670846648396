import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context";
import { Stack } from "../stack/stack";
import { findById } from "./helper";

function DeviceRegistration() {
  const [currentStep, completeCurrentStep, workflowState, updateState] =
    useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['DeviceRegistration']
  const homeplusscript = workflowState['config']?.scripts["DeviceRegistration"];
  
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({
    value: "Keyboard",
    label: "Keyboard",
  });

  const setSelectedDevice = (device) => {
    setSelectedDeviceDetails({
      ...selectedDeviceDetails,
      ...device,
    });
  };

  useEffect(() => {
    console.log(
      "selected device details",
      selectedDeviceDetails.value,
      selectedDeviceDetails.label
    );
  }, [selectedDeviceDetails]);

  const onClick = () => {};

  let deviceSelection = findById(metadata, "deviceSelection");
  let btnContinue = findById(metadata, "btnContinue");
  let pdtIdentifyText = findById(metadata,"pdtIdentify");
  let deviceRegMenu = findById(metadata,"menu");

  deviceRegMenu.args = {
    ...deviceRegMenu.args,
    iconSubHeader: homeplusscript.menu_iconSubHeader,
    labelSubHeader: homeplusscript.menu_labelSubHeader
  };

  pdtIdentifyText.args = {
    ...pdtIdentifyText.args, 
    content:homeplusscript.pdtIdentify
  };

  btnContinue.args = {
    ...btnContinue.args,
    label:homeplusscript.btnContinue_label,
    onClick: (e) => console.log("Next", e),
    enabled: selectedDeviceDetails.value,
    onClick: (e) => onClick(),
  };

  const devices = [
    { value: "Soundbar", label: "Soundbar" },
    { value: "Tablet", label: "Tablet" },
    { value: "Television", label: "Television" },
    { value: "Webcam", label: "Webcam" },
    { value: "Laptop", label: "Laptop" },
    { value: "Printer", label: "Printer" },
    { value: "Router", label: "Router" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "Mouse", label: "Mouse" },
    { value: "Monitors", label: "Monitors" },
    { value: "Desktop", label: "Desktop" },
  ];

  deviceSelection.args = {
    ...deviceSelection.args,
    label : homeplusscript.deviceSelection_label,
    options: devices,
    value: selectedDeviceDetails.value,
    onSelect: setSelectedDevice,
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}

export default DeviceRegistration;
