import { useContext, useEffect, useState } from "react";
import { Stack } from "../stack/stack";
import { findById } from "../enrollment-workflow/helper";
import { WorkflowContext } from "../workflow/workflow-context";
import { Config } from "../enrollment-workflow/config";

function HomePage() {
  const [
    currentStep,
    completeCurrentStep,
    workflowState,
  ] = useContext(WorkflowContext);

  const metadata = workflowState['config']?.metadata['HomePage']
  const homeplusscript = workflowState['config']?.scripts["HomePage"];

  const btnSignIn = findById(metadata, "btnSignIn");
  const btnFamilyUserLogin = findById(metadata, "btnFamilyUserLogin");

  const contactUs = findById(metadata, "contactUs");
  const termsOfUse = findById(metadata, "termsOfUse");
  const privacyPolicy = findById(metadata, "privacyPolicy");
  const faq = findById(metadata, "txtFaq");
  const dataProPrivacyPolicy = findById(metadata, "dataProPrivacyPolicy");
  const txtLegalNotice = findById(metadata, "txtLegalNotice");
  const txtSmartSupport = findById(metadata, "txtSmartSupport");
  const textFooter = findById(metadata, "textFooter");
  const txtSubPara1 = findById(metadata, "txtSubPara1")
  const txtSubPara2 = findById(metadata, "txtSubPara2")
  const txtSubPara3 = findById(metadata, "txtSubPara3")

  const onSingnInClick = () => {
    return completeCurrentStep(currentStep, {
      Login: true,
    });
  };

  const onContactClick = () => {
    return completeCurrentStep(currentStep, {
      OnContact: true,
    });
  };

  btnSignIn.args = {
    ...btnSignIn.args,
    enabled: true,
    onClick: onSingnInClick,
  };
  
  btnFamilyUserLogin.args = {
    ...btnFamilyUserLogin.args,
    enabled: true,
    label: homeplusscript.btnFamilyUserLogin,
    onClick: (e) => window.open(Config.service.securityAdvisor),
  };

  termsOfUse.args = {
    ...termsOfUse.args,
    onClick: (e) => window.open("https://www.asurion.com.sg/eng/terms-of-use/"),
  };

  privacyPolicy.args = {
    ...privacyPolicy.args,
    onClick: (e) =>
      window.open("https://www.asurion.com.sg/eng/privacy-policy/"),
  };

  contactUs.args = {
    ...contactUs.args,
    onClick: onContactClick,
  };

  faq.args = {
    ...faq.args,
    onClick: (e) =>
    window.open("https://starhub.com/smartsupporthome-faq/")
  };

  dataProPrivacyPolicy.args = {
    ...dataProPrivacyPolicy.args,
    onClick: (e) =>
    window.open("https://www.libertyinsurance.com.sg/data-protection-policy")
  };

  txtLegalNotice.args = {
    ...txtLegalNotice.args,
    onClick: (e) =>
    window.open("https://www.starhub.com/about-us/legal-notices-and-terms.html")
  };

  txtSmartSupport.args = {
    ...txtSmartSupport.args,
    onClick: (e) =>
    window.open("http://starhub.com/smartsupporthome")
  };

  textFooter.args = {
    ...textFooter.args,
    content:homeplusscript.textFooter
  };

  txtSubPara1.args = {
    ...txtSubPara1.args,
    content:homeplusscript.txtSubPara1
  };

  txtSubPara2.args = {
    ...txtSubPara2.args,
    content:homeplusscript.txtSubPara2
  };
  
  txtSubPara3.args = {
    ...txtSubPara3.args,
    content:homeplusscript.txtSubPara3
  };

  return <Stack orientation="vertical" metadata={metadata} />;
}
export default HomePage;
